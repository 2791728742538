import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from '@material-ui/core/TextField';
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { appProperties } from "../../../components/LdUtils";



const styles = theme => ({
  // main: {
  //   width: "400px",
  //   padding: "15px",
  //   display: "block", // Fix IE 11 issue.
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
  //     width: 450,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   }
  // },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
 
  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "135px",
    padding: "0px !important",
  },
  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  }
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight:'600',
    fontSize:'15px',
    padding:'4px 0 4px 15px;'
  },
  body: {
    fontSize: 14,
    padding:'4px 0 4px 15px;'
  }
}))(TableCell);



class ItemEtablissement extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      options: [],
      redirect: false,
      dialogOpened: false,
      position: this.props.position,
      solde:0,
      inProgress:false,
      controlled:true,
    };
    this.handleAdd = this.handleAdd.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);

  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };
  
 
  handleChangeInput = name => event => {
    var val = {};

      var value = event.target.value;
     
      if(value<0){
        value = 0;
        event.target.value = value;
      }
      if(event.target.value==="")value=0;
      val[name]= parseFloat(value);
      this.setState(val);
    
  
  };
  
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };
  
  handleAdd(event) {
    if(this.state.solde === 0){
      this.setState({controlled:false});
      return;
    }else{
      this.setState({controlled:true});
    }
    this.setState({inProgress:true});
    var self = this;
    fetch(appProperties.basepathServer + "/rest-etablissement/add-recharge?etablissement="+this.props.row.id+'&nb_sms='+this.state.solde, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x- www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        this.setState({inProgress:false});
        if(data.result_code === 1){
          self.handleClose();
          var etablissement  = this.props.row;
          etablissement['soldeSms'] = (etablissement.soldeSms ||0) + data.row.nbSms;
          etablissement['countAlertSoldeSms'] = 0;
          self.props.onUpdateItem("update", {
            position: self.props.position,
            row: etablissement
          });
          self.props.enqueueSnackbar("Votre demande a bien été transmise a l'équipe Learndia", { variant:"success", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else if(data.result_code === -100){
          self.props.enqueueSnackbar("Vous n'avez pas d'autorisation", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else if(data.result_code === -2){
          self.props.enqueueSnackbar("Le nombre que vous avez saisi est invalide", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else{
          self.props.enqueueSnackbar("Les données ne sont pas valides", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({inProgress:false});
        alert("There was an error added.");
      });
  }
  

  render() {
    const { classes, row } = this.props;


    return (
      <React.Fragment>
        <TableRow className={classes.row} key={row.id}>
          <CustomTableCell component="th" scope="row">
            {row.code}
          </CustomTableCell>
          <CustomTableCell align="left">{row.name}</CustomTableCell>
          <CustomTableCell align="left">{row.soldeSms}</CustomTableCell>
          <CustomTableCell align="left">
            {row.countAlertSoldeSms}
          </CustomTableCell>
          <CustomTableCell align="left">{row.created}</CustomTableCell>
          <CustomTableCell align="left">
            <div className={classes.actions}>
            <Button
              style={{textTransform:'capitalize'}}
              variant="outlined"
              color="primary"
              onClick={this.handleClickOpen}>
              Recharge sms
            </Button> 
            </div>
          </CustomTableCell>
        </TableRow>

        {this.state.dialogOpened && (
          <Dialog
            open={this.state.dialogOpened}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle  className={classes.pageTitle}  id="form-dialog-title">
              Recharge le solde d'SMS: # {row.name}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Saisissez le nombre d'sms à créditer
              </DialogContentText>
              { this.state.inProgress
                ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
                : null
              }
             { !this.state.controlled
                  ? <div className={classes.error}>Vérifier tous les champs du formulaire</div> 
                  : null
              }

              <CssBaseline />
               
                  
                <TextField
                  id="value"
                  label="Nombre de SMS à créditer"
                  value={this.state.solde}
                  placeholder="12500"
                  onChange={this.handleChangeInput('solde')}
                  margin="normal"
                  type="number"
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0 },
                    
                  }}
                />
                  
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} 
              variant="contained"
              size="medium"
              className = {classes.buttonGreyWhite}>
                Annuler
              </Button>
              <Button onClick={this.handleAdd} 
                variant="contained"
                size="medium"
                className = {classes.buttonPrincipale}>
                Valider
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}
ItemEtablissement.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(ItemEtablissement));
