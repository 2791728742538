import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, injectIntl, intlShape } from "react-intl";
import { appProperties, userProfileUrl } from "../../../components/LdUtils";
// import ListTrimestreView from '../trimestre/ListTrimestreView';

import { isObjectValueEquals } from "../../../components/LdUtils";
import { withSnackbar } from "notistack";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: "10px"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },

  contentImage: {
    width: "40px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonSimple: {
    textTransform: "none"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none"
  },
  date: {
    fontSize: "12px",
    fontWeight: "400"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  }
});

// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
  </span>
));

class ItemEmploiTemps extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,
      name: this.props.row.name,
      controlled: true,
      etablissement: this.props.etablissement
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleSetCurrent = this.handleSetCurrent.bind(this);
  }

  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          name: self.props.row.name || ""
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }
  handleEdit = () => {
    this.setState({ modeEdit: true });
  };
  reset = () => {
    this.setState({ modeEdit: false, controlled: true });
  };

  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }
  edit = () => {
    if (this.state.name == null || this.state.name.trim() === "") {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("name", this.state.name);
    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/manager/emploi-temps/edit?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.reset();
          let datas = { position: self.state.position, row: data.item };
          self.props.onUpdateItemEmploiTemps("update", datas);
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEmploiTemps_1" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEmploiTemps_2" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
        this.setState({ inProgress: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/emploi-temps/delete?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEmploiTemps_3" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
          self.props.onUpdateItemEmploiTemps("delete", {
            position: self.state.position
          });
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEmploiTemps_2" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/emploi-temps/set-status?status=1&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.props.onUpdateItemEmploiTemps("enabled", {
            position: self.state.position,
            row: this.props.row
          });
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEmploiTemps_4" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEmploiTemps_2" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/emploi-temps/set-status?status=0&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.props.onUpdateItemEmploiTemps("disabled", {
            position: self.state.position,
            row: this.props.row
          });
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEmploiTemps_5" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEmploiTemps_2" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleSetCurrent = () => {
    //TODO: recharger l'object  etablissement en définissant le nouveau currentAnnee
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/emploi-temps/select-current?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.props.onUpdateItemEmploiTemps("current-emploi-temps", {
            id: data.item ? data.item.id : ""
          });
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEmploiTemps_6" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEmploiTemps_2" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };

  render() {
    const { classes, row, isCurrent } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>
              {this.state.modeEdit ? (
                <main className={classes.main}>
                  {this.state.inProgress ? (
                    <div className={classes.progress}>
                      <LinearProgress variant="query" />
                    </div>
                  ) : null}
                  {!this.state.controlled ? (
                    <div className={classes.error}>
                      {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
                    </div>
                  ) : null}
                  <div className="layout horizontal">
                    <div style={{ paddingRight: "10px" }} className="flex">
                      <TextField
                        id="name"
                        label={formatMessage({ id: "ItemEmploiTemps_12" })}
                        style={{ width: "100%", marginTop: "0 !important" }}
                        value={this.state.name}
                        placeholder="Ex: 2018-2019"
                        onChange={this.handleChangeName}
                        margin="normal"
                      />
                    </div>
                    <div className="layout vertical">
                      <span className="flex"> </span>
                      <div className="layout horizontal center wrap">
                        <Button
                          onClick={this.edit}
                          variant="contained"
                          size="medium"
                          style={{ margin: "5px" }}
                          className={classes.buttonPrincipale}
                        >
                          {formatMessage({ id: "ItemAnneeScolaire_Up" })}
                        </Button>
                        <Button
                          onClick={this.reset}
                          variant="contained"
                          size="medium"
                          style={{ margin: "5px" }}
                          className={classes.buttonGreyWhite}
                        >
                          {formatMessage({ id: "ItemAnneeScolaire_Ccl" })}
                        </Button>
                      </div>
                    </div>
                  </div>
                </main>
              ) : (
                  <div>
                    <div className="layout horizontal center">
                      <span className={classes.name}>{row.name}</span>
                      <span
                        style={{
                          color: "green",
                          fontSize: "15px",
                          paddingLeft: "10px"
                        }}
                      >
                        <span
                          title={
                            formatMessage({ id: "ItemEmploiTemps_10" }) +
                            (this.props.annee != null
                              ? this.props.annee.year
                              : "")
                          }
                        >
                          {isCurrent
                            ? formatMessage({ id: "ItemEmploiTemps_11" })
                            : ""}
                        </span>
                      </span>
                    </div>
                    <div style={{ fontWeight: "400" }} className={classes.date}>
                      {formatMessage({ id: "ItemAlert_msg13" })}{" "}
                      <PostDate date={row.created} />{" "}
                      {formatMessage({ id: "ItemAlert_msg14" })}
                      <a
                        style={{
                          paddingLeft: "5px",
                          fontWeight: "500",
                          fontSize: "11px"
                        }}
                        className="no-underline capitalize"
                        title={row.creator.name}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={userProfileUrl(row.creator)}
                      >
                        {row.creator.name}
                      </a>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className={classes.actions}>
            {!isCurrent ? (
              <Button
                onClick={this.handleSetCurrent}
                size="medium"
                color="primary"
                title={formatMessage({ id: "ItemEmploiTemps_9" })}
                className={classes.buttonSimple}
              >
                {formatMessage({ id: "ItemAnneeScolaire_Current" })}
              </Button>
            ) : null}

            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
              aria-label="Editer"
              onClick={this.handleEdit}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              title={formatMessage({ id: "ItemRole_del" })}
              aria-label="Delete"
              onClick={this.handleDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
            {!!this.props.row.status ? (
              <IconButton
                title={formatMessage({ id: "ItemRole_desact" })}
                classes={{ root: classes.disable }}
                aria-label="desactiver"
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            ) : (
                <IconButton
                  title={formatMessage({ id: "ItemRole_activ" })}
                  classes={{ root: classes.enable }}
                  aria-label="activer"
                  onClick={this.handleEnabled}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            {/* <Button
              title={formatMessage({ id: "ItemEmploiTemps_8" })}
              style={{ textTransform: "none", borderRadius: "0 !important" }}
              variant="outlined"
              size="small"
              className={classes.margin}
            >
              {formatMessage({ id: "ItemEmploiTemps_7" })}
            </Button> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ItemEmploiTemps.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà.
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(ItemEmploiTemps))
);
