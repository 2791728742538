import { Button } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";

import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React, { Component } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Redirect } from "react-router-dom";
import { appProperties } from "./LdUtils";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { injectIntl } from "react-intl";

// import draftToMarkdown from 'draftjs-to-markdown';

const styles = theme => ({
  main: {
    width: "400px",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      width: 450,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "10px"
  },
  avatar: {
    margin: theme.spacing.unit,
    display: "inline-block",
    width: "64px",
    height: "64px",
    background: "#ddd"
    // backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    fontSize: "18px",
    borderRadius: "0 !important",
    textTransform: "lowercase",
    backgroundColor: theme.color.primaryDark,
    "&:hover": {
      backgroundColor: "red !important"
    }
  },
  progress: {
    width: "100%",
    flexGrow: 1
  },
  close: {
    padding: theme.spacing.unit / 2
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    },
    margin: "0 10px"
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  title: {
    padding: "10px",
    fontSize: "15px",
    fontWeight: "500"
  }
});

function uploadImageCallBack(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader(); // eslint-disable-line no-undef
    reader.onload = e => resolve({ data: { link: e.target.result } });
    reader.onerror = e => reject(e);
    reader.readAsDataURL(file);
  });
}
class AutorisationEtablissement extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem(appProperties.key_user) || null;
    let currentUser = JSON.parse(user);
    this.state = {
      phone: "",
      to: "contact@learndia.com",
      subject: "",
      // content: {},
      content: "",
      email: currentUser != null ? currentUser.email : "",
      currentUser: currentUser,
      etablissement: "",
      fonction: "",
      controlled: true,
      openSnack: false,
      editorState: undefined

      // editorState: EditorState.createEmpty()
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handlePhoneValue = this.handlePhoneValue.bind(this);
    this.addAuto = this.addAuto.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    this.reset = this.reset.bind(this);
  }
  reset(event) {
    this.setState({
      email: "",
      phone: "",
      fonction: "",
      etablissement: "",
      editorState: undefined,
      content: ""
    });
  }
  // doOpenDialog() {
  //   this.setState({
  //     email: this.state.currentUser != null ? this.state.currentUser.email : ""
  //   });
  // }

  onEditorStateChange = editorState => {
    // console.log(editorState);
    // console.log(convertToRaw(editorState.getCurrentContent()));
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    this.setState({
      editorState: editorState,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };
  handlePhoneValue = e => {
    // console.log(e);
    this.setState({ phone: e });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };
  handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnack: false });
  };

  handleEditorChange = e => {
    console.log("Content was updated:", e.target.getContent());
    this.setState({ content: e.target.getContent() });
  };
  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(null);
  }
  addAuto() {
    //const { formatMessage } = this.props.intl;
    if (
      this.state.phone === "" ||
      this.state.email === "" ||
      this.state.etablissement === "" ||
      this.state.fonction === ""
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("to", this.state.to);
    var subject = "Demande d'autorisation d'administration d'un établissement";
    var content = "<br/>";
    content += " Etablissement : " + this.state.etablissement + "<br/>";
    content += " fonction : " + this.state.fonction + "<br/>";
    content += " email: " + this.state.email + "<br/>";
    content += " phone: " + this.state.phone + "<br/> <br/>";
    content += this.state.content + "<br/>";
    formData.append("subject", subject);
    formData.append("content", content);

    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer + "/manager/etablissement/send-message",
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.setState({ openSnack: true });

          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          // fireWithObject(document,'new-autorisation',{value:data.row});
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        self.setState({ openSnack: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { editorState } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      //  <p>bonjour tout le monde</p>
      <div>
        <div className={classes.title} id="form-dialog-title">
          {formatMessage({ id: "AutorisationEtablissement_Form" })}
        </div>
        <div>
          {this.state.inProgress ? (
            <div className={classes.progress}>
              <LinearProgress variant="query" />
            </div>
          ) : null}
          {/* <div  className={classes.pageSubtitle} >Renseignez toutes les informations sur l'enseignat d'un etablissement </div> */}
          <CssBaseline />
          <Paper square elevation={0} className={classes.paper}>
            {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}
            {this.state.redirect ? (
              <Redirect
                to={{
                  pathname: "/",
                  search: "",
                  state: {}
                }}
              />
            ) : null}

            {this.state.result != null ? (
              <div className="Error-message">{this.state.result.message}</div>
            ) : null}

            <div className={classes.root}>
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="email">
                  {formatMessage({
                    id: "AutorisationEtablissement_mail"
                  })}
                </InputLabel>
                <Input
                  id="email"
                  required
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.handleChangeInput("email")}
                  autoFocus
                  placeholder={formatMessage({
                    id: "AutorisationEtablissement_Ph_mail"
                  })}
                />
              </FormControl>
              <br />
              <br />
              <br />
              <div>
                <PhoneInput
                  placeholder={formatMessage({
                    id: "AutorisationEtablissement_Ph_tel"
                  })}
                  value={this.state.phone}
                  onChange={this.handlePhoneValue}
                />
              </div>
              <br />
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="etablissement">
                  {formatMessage({ id: "AutorisationEtablissement_NomSch" })}
                </InputLabel>
                <Input
                  name="etablissement"
                  type="etablissement"
                  id="etablissement"
                  value={this.state.etablissement}
                  required
                  onChange={this.handleChangeInput("etablissement")}
                  autoComplete="etablissement"
                />
              </FormControl>
              <br />
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="fonction">
                  {formatMessage({ id: "AutorisationEtablissement_Fonct" })}
                </InputLabel>
                <Input
                  name="fonction"
                  type="text"
                  id="fonction"
                  value={this.state.fonction}
                  required
                  onChange={this.handleChangeInput("fonction")}
                  placeholder={formatMessage({
                    id: "AutorisationEtablissement_Fonct_Ph"
                  })}
                  autoComplete="fonction"
                />
              </FormControl>
              <br />
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="content">
                  {formatMessage({ id: "AutorisationEtablissement_Descrip" })}
                </InputLabel>
                <Editor
                  id="content"
                  editorState={editorState}
                  placeholder={formatMessage({
                    id: "AutorisationEtablissement_Descrip_Ph"
                  })}
                  toolbarClassName="rdw-storybook-toolbar"
                  wrapperClassName="rdw-storybook-wrapper"
                  editorClassName="rdw-storybook-editor"
                  wrapperStyle={{ marginTop: "50px" }}
                  editorStyle={{
                    minHeight: "150px",
                    border: "1px solid #EEEEEE",
                    borerRadius: "50%",
                    marginTop: "10px",
                    padding: "10px"
                  }}
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{
                    image: {
                      uploadCallback: uploadImageCallBack,
                      previewImage: true
                    }
                  }}
                />

                {/* <Input
                    id="content"
                    required
                    name="content"
                    autoComplete="content"
                    value={this.state.content}
                    onChange={this.handleChangeInput("content")}
                    multiline
                    rows="6"
                    variant="filled"
                  /> */}
              </FormControl>
              <br />
            </div>
          </Paper>
        </div>

        <div
          style={{ padding: "15px 0 0 0" }}
          className="layout horizontal  center end-justified"
        >
          <Button
            onClick={this.reset}
            variant="contained"
            size="medium"
            className={classes.buttonGreyWhite}
          >
            {formatMessage({ id: "AutorisationEtablissement_Ccl" })}
          </Button>
          <Button
            onClick={this.addAuto}
            variant="contained"
            size="medium"
            className={classes.buttonPrincipale}
          >
            {formatMessage({ id: "AutorisationEtablissement_Ok" })}
          </Button>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={
            <span id="message-id">
              {formatMessage({ id: "AutorisationEtablissement_Alert" })}
            </span>
          }
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarClose}
            >
              OK!
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}
AutorisationEtablissement.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(injectIntl(AutorisationEtablissement));
