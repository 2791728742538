import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  FormattedDate,
  injectIntl,
  intlShape,
  FormattedMessage
} from "react-intl";
import {
  isObjectValueEquals,
  appProperties,
  userProfileUrl
} from "../../../components/LdUtils";
import { withSnackbar } from "notistack";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: "10px",
    marginBottom: "10px",
    borderBottom: "1px solid #EAEAEA"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonSimple: {
    textTransform: "none"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none"
  },
  date: {
    fontSize: "12px",
    fontWeight: "400"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  }
});

// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
  </span>
));

class ItemTypeGroupeEnseignement extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,
      code: this.props.row.code,
      name: this.props.row.name,
      nameEn: this.props.row.nameEn,
      description: this.props.row.description,
      controlled: true,
      etablissement: this.props.etablissement
    };

    // this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }
  componentDidUpdate(prevProps) {
    var self = this;

    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          code: self.props.row.code || "",
          name: self.props.row.name || "",
          description: self.props.row.description || "",
          nameEn: self.props.row.nameEn || ""
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }
  handleEdit = () => {
    this.setState({ modeEdit: true });
  };
  reset = () => {
    this.setState({ modeEdit: false, controlled: true });
  };
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };
  edit = () => {
    if (
      this.state.code == null ||
      this.state.code.trim() === "" ||
      this.state.name == null ||
      this.state.name.trim() === "" ||
      this.state.nameEn == null ||
      this.state.nameEn.trim() === ""
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("name_en", this.state.nameEn);
    formData.append("description", this.state.description);
    formData.append("id", this.props.row.id);
    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/manager/groupe-enseignement/edit-type?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.reset();
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItem("update", datas);

          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({
              id: "ItemTypeGroupeEnseignement_1"
            }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({
              id: "ItemTypeGroupeEnseignement_2"
            }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
        this.setState({ inProgress: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/groupe-enseignement/delete-type?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.props.onUpdateItem("delete", { position: self.state.position });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/groupe-enseignement/enable-type?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/groupe-enseignement/disable-type?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>
              {this.state.modeEdit ? (
                <main className={classes.main}>
                  {this.state.inProgress ? (
                    <div className={classes.progress}>
                      <LinearProgress variant="query" />
                    </div>
                  ) : null}
                  {!this.state.controlled ? (
                    <div className={classes.error}>
                      {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
                    </div>
                  ) : null}
                  <div className="layout horizontal">
                    <div style={{ paddingRight: "10px" }} className="flex">
                      <Grid
                        container
                        className={classes.grid}
                        justify="flex-start"
                        alignItems="center"
                      >
                        <TextField
                          id="code"
                          label={formatMessage({
                            id: "ItemClasseEtablissement_3"
                          })}
                          style={{
                            width: "30%",
                            marginTop: "0 !important",
                            marginRight: "5px"
                          }}
                          value={this.state.code}
                          placeholder="Ex: 2018-2019"
                          onChange={this.handleChangeInput("code")}
                          margin="normal"
                        />
                        <TextField
                          id="nom"
                          label={formatMessage({
                            id: "AjouterTypeGroupeEnseignementView_3"
                          })}
                          style={{
                            width: "30%",
                            marginTop: "0 !important",
                            marginRight: "5px"
                          }}
                          value={this.state.name}
                          placeholder="Ex: 2018-2019"
                          onChange={this.handleChangeInput("name")}
                          margin="normal"
                        />

                        <TextField
                          id="nom"
                          label={formatMessage({
                            id: "AjouterTypeGroupeEnseignementView_4"
                          })}
                          style={{ width: "30%", marginTop: "0 !important" }}
                          value={this.state.nameEn}
                          placeholder="Ex: 2018-2019"
                          onChange={this.handleChangeInput("nameEn")}
                          margin="normal"
                        />
                      </Grid>
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="description">
                          {formatMessage({ id: "ItemTypeDecaissement_5" })}
                        </InputLabel>
                        <Input
                          id="description"
                          required
                          name="description"
                          autoComplete="description"
                          value={this.state.description}
                          onChange={this.handleChangeInput("description")}
                          multiline
                          rows="4"
                          variant="filled"
                        />
                      </FormControl>
                    </div>
                    <div className="layout vertical">
                      <span className="flex"> </span>
                      <div className="layout horizontal center wrap">
                        <Button
                          onClick={this.edit}
                          variant="contained"
                          size="medium"
                          style={{ margin: "5px" }}
                          className={classes.buttonPrincipale}
                        >
                          {formatMessage({ id: "ItemAnneeScolaire_Up" })}
                        </Button>
                        <Button
                          onClick={this.reset}
                          variant="contained"
                          size="medium"
                          style={{ margin: "5px" }}
                          className={classes.buttonGreyWhite}
                        >
                          {formatMessage({ id: "ItemAnneeScolaire_Ccl" })}
                        </Button>
                      </div>
                    </div>
                  </div>
                </main>
              ) : (
                  <div>
                    <div className="layout horizontal center">
                      <span style={{ paddingRight: "10px", fontSize: "13px" }}>
                        {formatMessage({ id: "ItemTypeDecaissement_3" })}{" "}
                        <b>{row.code}</b>
                      </span>
                      <span style={{ paddingRight: "10px", fontSize: "13px" }}>
                        {formatMessage({ id: "ItemTypeDecaissement_4" })}{" "}
                        <b>{row.name}</b>
                      </span>
                      <span style={{ paddingRight: "10px", fontSize: "13px" }}>
                        {formatMessage({ id: "ItemTypeGroupeEnseignement_3" })}{" "}
                        <b>{row.nameEn}</b>
                      </span>
                    </div>
                    <div>
                      <span style={{ fontSize: "13px" }}>
                        {formatMessage({ id: "ItemTypeDecaissement_5" })}&nbsp;
                      {row.description}
                      </span>
                    </div>
                    <div style={{ fontWeight: "400" }} className={classes.date}>
                      {/*<FormattedMessage id="date_user" value={{date=<PostDate date={row.created} />,user=}}/>
                    Ajouté le <PostDate date={row.created} /> par
                    <a
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "500",
                        fontSize: "11px"
                      }}
                      className="no-underline capitalize"
                      title={row.creator.name}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={userProfileUrl(row.creator)}
                    >
                      {row.creator.name}
                    </a>{" "}*/}
                      <FormattedMessage
                        id="date_user"
                        values={{
                          date: <PostDate date={row.created} />,
                          user: (
                            <a
                              style={{
                                paddingLeft: "5px",
                                fontWeight: "500",
                                fontSize: "11px"
                              }}
                              className="no-underline capitalize"
                              title={row.creator.name}
                              rel="noopener noreferrer"
                              target="_blank"
                              href={userProfileUrl(row.creator)}
                            >
                              {row.creator.name}
                            </a>
                          )
                        }}
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className={classes.actions}>
            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
              aria-label="Editer"
              onClick={this.handleEdit}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            {/* <IconButton
                title={formatMessage({id:"ItemAnneeScolaire_del"})}
                aria-label="Delete"
                onClick={this.handleDelete}>
                <DeleteIcon fontSize="small" />
              </IconButton> */}
            {!!this.props.row.status ? (
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                classes={{ root: classes.disable }}
                aria-label="desactiver"
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            ) : (
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                  classes={{ root: classes.enable }}
                  aria-label="activer"
                  onClick={this.handleEnabled}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ItemTypeGroupeEnseignement.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà.
export default withSnackbar(
  withStyles(styles, { withTheme: true })(
    injectIntl(ItemTypeGroupeEnseignement)
  )
);
