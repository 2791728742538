import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import { appProperties, getAccessLabel } from "../../../components/LdUtils";
import AjouterRoleView from "./AjouterRoleView";
import ItemRole from "./ItemRole";
import CircularProgress from "@material-ui/core/CircularProgress";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { deleteClassInProps } from "../../../components/LdUtils";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Avatar from "@material-ui/core/Avatar";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    // marginTop: theme.spacing.unit * 3,
    // overflowX: "auto",
    padding: "10px",
    marginTop: "10px"
  },
  pageTitle1: {
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: 600
  },
  pageSubtitle1: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },
  scolariteGreen: {
    margin: 10,
    color: "#fff",
    backgroundColor: "var(--app-primary-color)"
  },
  panelRoot: {
    borderRadius: "0 !important"
  },
  panelSummary: {
    alignItems: "center",
    margin: "0 !important",
    borderBottom: "1px dashed #EAEAEA"
  },
  summaryRoot: {
    padding: "0"
  },
  summaryExpanded: {
    margin: "0 !important"
  }
});

class ListRoleView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      currentPage: 1,
      access: this.props.access,
      etablissement: this.props.etablissement,
      progress: 1,
      expanded: this.props.access //Not change this.
    };
    this.setNewAdmin = this.setNewAdmin.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };
  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listData(page);
  };
  setNewAdmin = value => {
    this.setState(state => {
      state.datas = [value, ...state.datas];
      return state;
    });
  };
  onUpdateItem = (mode, item) => {
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  componentDidMount() {
    this.listData(this.state.currentPage);
  }

  listData(page) {
    let self = this;
    this.setState({ datas: [], progress: 1 });

    //TODO: revoir cette url pour modifier access
    fetch(
      appProperties.basepathServer +
      "/rest-etablissement/list-admins?limit=5&page=" +
      page +
      "&id=" +
      this.state.etablissement.id +
      "&access=" +
      this.state.access,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });
        console.log("There was an error listed bulletin.");
      });
  }

  getSubtitle(name) {
    switch (name) {
      case "admin":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_1" });
      case "manager":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_2" });
      case "inscription":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_3" });
      case "scolarite":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_4" });
      case "discipline":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_5" });
      case "note":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_6" });
      case "planning":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_7" });
      case "cahiertexte":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_8" });
      case "stock":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_9" });
      case "stockR":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_10" });
      case "comptable":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_11" });
      case "chefComptable":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_12" });
      case "coordo":
        return this.props.intl.formatMessage({ id: "ListRoleView_Subtitle_13" });
      default:
        return "";
    }
  }

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div style={{ marginTop: "15px" }}>
        <React.Fragment>
          <ExpansionPanel
            classes={{ root: classes.panelRoot }}
            expanded={expanded === this.state.access}
            onChange={this.handleChange(this.state.access)}
          >
            <ExpansionPanelSummary
              classes={{
                content: classes.panelSummary,
                root: classes.summaryRoot,
                expanded: classes.summaryExpanded
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <div style={{ position: "relative" }}>
                <Avatar className={classes.scolariteGreen}>
                  <SupervisorAccountIcon />
                </Avatar>
                {this.state.progress === 1 && (
                  <CircularProgress
                    style={{ position: "absolute", left: "8px", top: "8px" }}
                    thickness={1}
                    size={44}
                    className={classes.fabProgress}
                  />
                )}
              </div>
              <div>
                <div className={classes.pageTitle1}>
                  {getAccessLabel(this.state.access, formatMessage)}
                </div>
                <div className={classes.pageSubtitle1}>
                  {this.getSubtitle(this.state.access)}
                </div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ padding: "10px 10px 10px 10px" }}
              className="layout vertical"
            >
              <Paper elevation={0}>
                <div className={classes.root}>
                  <div>
                    <AjouterRoleView
                      onNewAdmin={this.setNewAdmin}
                      etablissement={this.state.etablissement}
                      access={this.state.access}
                      {...deleteClassInProps(this.props)}
                    />
                  </div>
                  {this.state.progress === 0 && (
                    <div>
                      {this.state.datas.map((row, index) => (
                        <ItemRole
                          key={index}
                          access={this.state.access}
                          onUpdateItem={this.onUpdateItem}
                          position={index}
                          row={row}
                          {...deleteClassInProps(this.props)}
                        />
                      ))}
                    </div>
                  )}
                  {this.state.progress === 1 && (
                    <div
                      style={{
                        backgroundColor: "white",
                        margin: "50px 50px 0 50px",
                        padding: "50px"
                      }}
                      className="layout vertical center-center"
                    >
                      <div style={{ padding: "15px" }}>
                        <CircularProgress
                          thickness={1}
                          size={75}
                          className={classes.fabProgress}
                        />
                      </div>
                      <div
                        style={{
                          fontSize: "17px",
                          fontWeight: "600",
                          width: "75%",
                          color: "var(--paper-grey-900)"
                        }}
                        className="layout horizontal center-center"
                      >
                        {formatMessage({ id: "ListRoleView_Load" })}
                      </div>
                    </div>
                  )}
                  {(this.state.progress === 2 ||
                    (this.state.progress === 0 &&
                      this.state.datas.length === 0)) && (
                      <div
                        style={{
                          backgroundColor: "white",
                          margin: "50px 50px 0 50px",
                          padding: "50px"
                        }}
                        className="layout vertical center-center"
                      >
                        {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                        <div
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            width: "75%",
                            color: "var(--paper-grey-900)"
                          }}
                          className="layout horizontal center-center"
                        >
                          {formatMessage({ id: "ListRoleView_Any_User" })}
                        </div>
                      </div>
                    )}
                  {this.state.progress === 3 && (
                    <div
                      style={{
                        backgroundColor: "white",
                        margin: "50px 50px 0 50px",
                        padding: "50px"
                      }}
                      className="layout vertical center-center"
                    >
                      <div style={{ padding: "15px" }}>Erreur!!</div>
                      <div
                        style={{
                          fontSize: "17px",
                          fontWeight: "600",
                          width: "75%",
                          color: "var(--paper-grey-900)"
                        }}
                        className="layout horizontal center-center"
                      >
                        {formatMessage({ id: "ListRoleView_LoadError" })}
                      </div>
                    </div>
                  )}
                </div>
              </Paper>
              {this.state.progress === 0 && (
                <LdPagination
                  onShowPage={this.setCurrentPage}
                  pages={this.state.pages}
                />
              )}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </React.Fragment>
      </div>
    );
  }
}
ListRoleView.propTypes = {
  classes: PropTypes.object.isRequired,
  etablissement: PropTypes.object.isRequired,
  access: PropTypes.string.isRequired
};
export default withStyles(styles)(injectIntl(ListRoleView));
