import Avatar from "@material-ui/core/Avatar";
import Grid from '@material-ui/core/Grid';

import withStyles from "@material-ui/core/styles/withStyles";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate,FormattedTime, injectIntl, intlShape } from 'react-intl';
import { userProfileUrl } from "../../../components/LdUtils";

import ShowMore from 'react-show-more';


const styles = theme => ({
  root:{
    display: "flex",
    justifyContent : "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content:{
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage:{
    width:"40px",
  },
  contentFlex:{
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent : "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
  
  progress: {
    width: "100%",
    flexGrow: 1
  },
 

  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  }, 
  buttonSimple:{
 
    textTransform:'none',
 
  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  name:{
    fontSize:"15px",
    fontWeight:'600',
    textDecoration:'none',
  },
  date:{
    fontSize:"12px",
    fontWeight:'400',
   
  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "100px !important",
    height:"100px !important",
    borderRadius:'0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding:2,
    // borderRadius:"50%",
    backgroundColor:theme.color.primaryDark,
    border:"2px solid #FFF",
    cursor:"pointer"
  },
  
  datePicker:{
    height:'40px',
    '& .react-date-picker__wrapper':{
      border:'1px dashed #AAAAAA',
      borderRadius:'5px'
    }
  },
  label:{
    fontSize:'15px',
    fontWeight:'500',
    padding:'0 10px 0 0'
  },
  error:{
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop:"15px"

  },
  avatar1: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-orange-500)',
  },
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({date, intl}) => (
  <span  title={intl.formatDate(date)}>
      {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
      <FormattedDate 
       day="numeric"
       month="long"
       year="numeric"
       value={date}/>
         &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
       <FormattedTime 
       hour='numeric'
       minute= 'numeric'
       value={date}/>
  </span>
));

class ItemDossierIndiscipline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      position: this.props.position,
      row:this.props.row
    };
    
    
  } 
  componentDidUpdate(prevProps) {
    //var self = this;
    // if(!isObjectValueEquals(this.props.etablissement,prevProps.etablissement)){
    //   this.setState({etablissement:this.props.etablissement},()=>{
    //   });
    // }
    // if(!isObjectValueEquals(this.props.row,prevProps.row)){
    //   this.setState({row:this.props.row},()=>{
    //     self.setState({motif:this.props.row.motif,justificatif:this.props.row.justificationComment});
    //   });
    // } 
    // if(!isObjectValueEquals(this.props.position,prevProps.position)){
    //   this.setState({position:this.props.position},()=>{
    //   });
    // }
  }
 


  render() {
    const { classes, row } = this.props;
    const {formatMessage} = this.props.intl;
    
    return (
      <React.Fragment>



        <div className={classes.root}>  
            <div className={classes.content}>
              <div className={classes.contentFlex}>
             
                
                <div>
                    <Grid container className={classes.grid} wrap="nowrap" justify="flex-start" >
                        <div>
                          {/* <ProgressiveImage  src={row.eleve.user.photo} placeholder={ defaultAvatar }>
                            {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                          </ProgressiveImage>  */}
                          {/* <Avatar src="/images/icons/indiscipline1.png" className={classes.avatar1}/> */}
                          <Avatar className={classes.avatar1}>
                            {this.props.position+1}
                          </Avatar>   
                        </div>
                        <div style={{paddingLeft:"15px"}}>    
                          {/* <div style={{fontSize:"15px",fontWeight:"600"}}>  
                              <a className="no-underline capitalize" title={row.eleve.user.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.eleve.user)}>
                                {row.eleve.user.name}
                              </a> 
                          </div>                                                                                                                                                                                  
                          <div  style={{fontSize:"15px",fontWeight:"600"}} className='layout horizontal center'>
                            <span  className={classes.name}> {row.eleve.classeEtablissement.name}</span>                                                                             
                            <div>   
                              <span  title="Matricule de l'élève"  style={{fontSize:"small",backgroundColor:'var(--app-primary-color)',padding:'0 5px',color:'white'}}>  {row.eleve.matricule}</span>
                            </div>
                          </div> */}
                          
                          <div className="layout horizontal center">   
                              <span tiytle={row.type.description} style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}> {row.type.name} </span>
                             <span style={{fontWeight:"400",paddingRight:'10px',fontSize:'12px'}}>   {formatMessage({id:"ItemDossierIndiscipline_1"})} <PostDate date={row.date}/></span>
                          </div>  
                          <div  className="layout horizontal">   
                              <span style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}> {formatMessage({id:"ListDemande_6"})} </span>
                              <span style={{fontWeight:"500",paddingRight:'10px',fontSize:'12px'}}> 
                                <ShowMore
                                      
                                      lines={1}
                                      more={formatMessage({id:"ItemAlert_msg11"})}
                                      less={formatMessage({id:"ItemAlert_msg12"})}
                                      anchorClass=''
                                  >
                                     {row.motif}
                                  </ShowMore></span>
                          </div>                              
                          <div  style={{fontWeight:"400"}} className={classes.date}>{formatMessage({id:"ItemAlert_msg13"})}  <PostDate date={row.created}/>
                            {row.creator!=null && (<span>
                              &nbsp;{formatMessage({id:"ItemAlert_msg14"})}
                               <a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'11px'}} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.creator)}>
                                {row.creator.name}
                              </a>  
                            </span>)}
                           </div>
                           {!row.justified&&(
                           <div  className="layout horizontal center">   
                              <span style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}>{formatMessage({id:"ItemDossierIndiscipline_2"})} </span>
                             
                                <div className="layout horizontal center">
                                  <span style={{fontWeight:"500",paddingRight:'10px',fontSize:'12px',color:'var(--paper-orange-500)'}}>Aucune</span>
                                </div>
                           </div>  
                           )} 
                           {row.justified&&(
                            <div style={{border:'1px dashed green',padding:"5px",marginTop:'5px'}}>
                              <div className="layout horizontal">   
                                  <span style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}>{formatMessage({id:"ItemDossierIndiscipline_2"})} </span>
                                  <span style={{fontWeight:"500",paddingRight:'10px',fontSize:'12px',color:'var(--paper-green-500)'}}>
                                  <ShowMore
                                      
                                      lines={1}
                                      more={formatMessage({id:"ItemAlert_msg11"})}
                                      less={formatMessage({id:"ItemAlert_msg12"})}
                                      anchorClass=''
                                  >
                                     {row.justificationComment}
                                  </ShowMore>
                                  
                                  </span>
                              </div>                              
                              <div  style={{fontWeight:"400"}} className={classes.date}>{formatMessage({id:"ItemDossierIndiscipline_3"})} <PostDate date={row.justificationDate}/>
                                {row.justifiedBy!=null && (<span>
                                  &nbsp;{formatMessage({id:"ItemAlert_msg14"})}
                                  <a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'11px'}} className="no-underline capitalize" title={row.justifiedBy.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.justifiedBy)}>
                                    {row.justifiedBy.name}
                                  </a>  
                                </span>)}
                              </div>
                            </div>
                           )} 
                        </div>
                        
                    </Grid>
                 
                </div>
              
              </div>
            </div>
           
        </div>
      
     </React.Fragment>      
    );
  }
}
ItemDossierIndiscipline.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
  export default withStyles(styles, { withTheme: true })(injectIntl(ItemDossierIndiscipline));
