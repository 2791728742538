import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AjouterPersonnelView from "../view/personnel/AjouterPersonnelView";
import ListPersonnelView from "../view/personnel/ListPersonnelView";
import { deleteClassInProps } from "../../components/LdUtils";
import { injectIntl } from "react-intl";

const useStyles = theme => ({
  root: {
    display: "block", // Fix IE 11 issue.
    paddingRight: "10px"
  },
  titleBlock: {
    textAlign: "left"
    // paddingLeft:"10px",
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  }
});

class PersonnelController extends Component {
  constructor(props) {
    super(props);
    //this.ajouterReseauEtablissViewRef = React.createRef();
    //this.ListReseauViewRef = React.createRef();
    this.state = {
      etablissement: this.props.etablissement
    };
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.root}>
        <div className={classes.titleBlock}>
          <div className={classes.pageTitle} variant="div" component="div">
            {formatMessage({ id: "PersonnelController_Title" })}
          </div>
          <div className={classes.pageSubtitle} variant="div" component="div">
            {formatMessage({ id: "PersonnelController_SubTitle" })}
          </div>
          <AjouterPersonnelView
            etablissementId={this.state.etablissement.id}
            {...deleteClassInProps(this.props)}
          />
        </div>
        <ListPersonnelView
          etablissement={this.state.etablissement}
          {...deleteClassInProps(this.props)}
        />
      </div>
    );
  }
}

PersonnelController.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(injectIntl(PersonnelController));
