import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import AjouterSaisieController from "./controller/AjouterSaisieController";
import ListBulletinController from "./controller/ListBulletinController";
import ListSaisieController from "./controller/ListSaisieController";
import ListNoteController from "./controller/ListNoteController";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Page404 from "../components/Page404";
import { appProperties, hasAccessKey } from "../components/LdUtils";
import CircularProgress from "@material-ui/core/CircularProgress";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { injectIntl } from "react-intl";

const useStyles = theme => ({
  rootLogged: {
    flexGrow: 1,
    padding: "15px"
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },
  toolbar: {
    backgroundColor: "white",
    padding: "15px",
    marginTop: "-15px",
    marginBottom: "15px",
    height: "65px"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 130,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  selectMenu: {
    padding: "7.5px 14px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */
});

class NoteIndex extends Component {
  constructor(props) {
    super(props);
    var currentAnnee = this.props.etablissement
      ? this.props.etablissement.anneeScolaire || null
      : null;
    var anneeId = currentAnnee != null ? currentAnnee.id : "";
    this.state = {
      annee: currentAnnee,
      anneeId: anneeId,
      annees: [],
      etablissement: this.props.etablissement,
      accesses: this.props.accesses || null,
      inProgress: true,
      inProgessAccess: false,
      progress: 1,
      labelWidth: 0
    };
    this.handleChangeAnnee = this.handleChangeAnnee.bind(this);
  }

  componentDidMount() {
    //TODO:Continuer ici: Charger les droits d'acces aussi.

    this.listAnneeScolaires();
  }

  handleChangeAnnee = event => {
    console.log("change annéee");
    console.log(event.target.value);
    this.setState({ annee: event.target.value });
  };

  listAnneeScolaires() {
    let self = this;
    this.setState({ annee: null, progress: 1 });
    fetch(
      appProperties.basepathServer +
        "/rest-etablissement/list-enabled-annee-scolaires?id=" +
        this.state.etablissement.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          var anneeEtab = this.state.etablissement.anneeScolaire || null;
          if (anneeEtab != null)
            for (var i in data.datas) {
              if (data.datas[i] != null && data.datas[i].id === anneeEtab.id) {
                self.setState({ annee: data.datas[i] });
                break;
              }
            }
          self.setState({ annees: data.datas, progress: 0 }, state => {
            self.setState({
              //labelWidth: ReactDOM.findDOMNode(self.InputLabelRef).offsetWidth,
            });
            setTimeout(() => {}, 1000);
          });
        } else if (data.result_code === 0) {
          self.setState({ annees: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ annees: [], progress: 3 });
        console.log("There was an error.");
      });
  }

  // componentDidMount() {
  //   // const values = queryString.parse(this.props.location.search);
  //   // console.log(values.filter); // "top"
  //   // console.log(values.origin); // "im"
  // }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    // const values = queryString.parse(this.props.location.search);
    // console.log(values.email); // "im"
    // var emailUser = values.email;

    if (this.state.progress === 0) {
      return (
        <Grid className={classes.rootLogged}>
          <Grid
            container
            className={classes.toolbar}
            justify="flex-end"
            alignItems="center"
          >
            <span>{formatMessage({ id: "DashboardComponent_SchYear" })}</span>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({ id: "DashboardComponent_ChxYear" })}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.annee}
                onChange={this.handleChangeAnnee}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Année scolaire"
                    id="outlined-annee-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {this.state.annees.map((row, index) => (
                  <MenuItem key={index} value={row}>
                    {row.year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <div>
            <Switch>
              <Route
                exact
                path="/page/:code/note/add-saisie"
                render={props => {
                  // return (<p>Page des enseignants</p>)
                  if (hasAccessKey(this.state.accesses, "note")) {
                    return (
                      <AjouterSaisieController
                        etablissement={this.state.etablissement}
                        annee={this.state.annee}
                        {...props}
                      />
                    );
                  } else {
                    //TODO: Afficher une page de  droit d'access
                    return <Page404 {...props} />;
                  }
                }}
              />
              <Route
                exact
                path="/page/:code/note/list-saisie"
                render={props => {
                  // return (<p>Page des enseignants</p>)
                  if (hasAccessKey(this.state.accesses, "note")) {
                    return (
                      <ListSaisieController
                        etablissement={this.state.etablissement}
                        annee={this.state.annee}
                        {...props}
                      />
                    );
                  } else {
                    //TODO: Afficher une page de  droit d'access
                    return <Page404 {...props} />;
                  }
                }}
              />
              <Route
                exact
                path="/page/:code/note/sequences"
                render={props => {
                  // return (<p>Page des enseignants</p>)
                  if (hasAccessKey(this.state.accesses, "note")) {
                    return (
                      <ListNoteController
                        mode="sequence"
                        etablissement={this.state.etablissement}
                        annee={this.state.annee}
                        {...props}
                      />
                    );
                  } else {
                    //TODO: Afficher une page de  droit d'access
                    return <Page404 {...props} />;
                  }
                }}
              />
              <Route
                exact
                path="/page/:code/note/trimestres"
                render={props => {
                  // return (<p>Page des enseignants</p>)
                  if (hasAccessKey(this.state.accesses, "note")) {
                    return (
                      <ListNoteController
                        mode="trimestre"
                        etablissement={this.state.etablissement}
                        annee={this.state.annee}
                        {...props}
                      />
                    );
                  } else {
                    //TODO: Afficher une page de  droit d'access
                    return <Page404 {...props} />;
                  }
                }}
              />

              <Route
                exact
                path="/page/:code/note/annuelles"
                render={props => {
                  // return (<p>Page des enseignants</p>)
                  if (hasAccessKey(this.state.accesses, "note")) {
                    return (
                      <ListNoteController
                        mode="annuelle"
                        etablissement={this.state.etablissement}
                        annee={this.state.annee}
                        {...props}
                      />
                    );
                  } else {
                    //TODO: Afficher une page de  droit d'access
                    return <Page404 {...props} />;
                  }
                }}
              />
              <Route
                exact
                path="/page/:code/note/bulletin-sequenciel"
                render={props => {
                  // return (<p>Page des enseignants</p>)
                  if (hasAccessKey(this.state.accesses, "note")) {
                    return (
                      <ListBulletinController
                        mode="sequence"
                        etablissement={this.state.etablissement}
                        annee={this.state.annee}
                        {...props}
                      />
                    );
                  } else {
                    //TODO: Afficher une page de  droit d'access
                    return <Page404 {...props} />;
                  }
                }}
              />

              <Route
                exact
                path="/page/:code/note/bulletin-trimestriel"
                render={props => {
                  // return (<p>Page des enseignants</p>)
                  if (hasAccessKey(this.state.accesses, "note")) {
                    return (
                      <ListBulletinController
                        mode="trimestre"
                        etablissement={this.state.etablissement}
                        annee={this.state.annee}
                        {...props}
                      />
                    );
                  } else {
                    //TODO: Afficher une page de  droit d'access
                    return <Page404 {...props} />;
                  }
                }}
              />

              <Route
                exact
                path="/page/:code/note/bulletin-annuel"
                render={props => {
                  // return (<p>Page des enseignants</p>)
                  if (hasAccessKey(this.state.accesses, "note")) {
                    return (
                      <ListBulletinController
                        mode="annuelle"
                        etablissement={this.state.etablissement}
                        annee={this.state.annee}
                        {...props}
                      />
                    );
                  } else {
                    //TODO: Afficher une page de  droit d'access
                    return <Page404 {...props} />;
                  }
                }}
              />
              <Route
                render={props => {
                  return <Page404 {...props} />;
                }}
              />
            </Switch>
          </div>
        </Grid>
      );
    } else if (this.state.progress === 1)
      return (
        <div
          style={{
            backgroundColor: "white",
            margin: "50px 50px 0 50px",
            padding: "50px"
          }}
          className="layout vertical center-center"
        >
          <div style={{ padding: "15px" }}>
            <CircularProgress
              thickness={1}
              size={75}
              className={classes.fabProgress}
            />
          </div>
          <div
            style={{
              fontSize: "17px",
              fontWeight: "600",
              width: "75%",
              color: "var(--paper-grey-900)"
            }}
            className="layout horizontal center-center"
          >
            {formatMessage({ id: "DashboardComponent_Wait" })}
          </div>
        </div>
      );
    else if (this.state.progress === 2)
      return (
        <div
          style={{
            backgroundColor: "white",
            margin: "50px 50px 0 50px",
            padding: "50px"
          }}
          className="layout vertical center-center"
        >
          <div style={{ padding: "15px" }}>Oups!!</div>
          <div
            style={{
              fontSize: "17px",
              fontWeight: "600",
              width: "75%",
              color: "var(--paper-grey-900)"
            }}
            className="layout horizontal center-center"
          >
            {formatMessage({ id: "DashboardComponent_Schl_noYear" })}
          </div>
        </div>
      );
    else if (this.state.progress === 3)
      return (
        <div
          style={{
            backgroundColor: "white",
            margin: "50px 50px 0 50px",
            padding: "50px"
          }}
          className="layout vertical center-center"
        >
          <div style={{ padding: "15px" }}>
            {formatMessage({ id: "ListClasseAnneeView_4" })}
          </div>
          <div
            style={{
              fontSize: "17px",
              fontWeight: "600",
              width: "75%",
              color: "var(--paper-grey-900)"
            }}
            className="layout horizontal center-center"
          >
            {formatMessage({ id: "DashboardComponent_NetworkError" })}
          </div>
        </div>
      );
  }
}
NoteIndex.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(injectIntl(NoteIndex));
