import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import LdSelectStatic from "../../../components/LdSelectStatic";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import { deleteClassInProps } from '../../../components/LdUtils';
import { withSnackbar } from 'notistack';
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: '0px'
  },

  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },
  label: {
    padding: "5px"
  }
});



class AjouterTypeIndisciplineView extends Component {
  constructor(props) {
    super(props);


    this.state = {
      code: "",
      name: "",
      description: "",
      // allowedDeclaration: !!0,
      alert: !!0,
      alertMessage: "",
      // allowedJustification: !!0,
      etablissementId: this.props.etablissementId,

      dialogOpened: false,
      inProgress: false,
      controlled: true,
      declarations: '',
      justifications: '',
      counter: -1,
    };
    this.add = this.add.bind(this);


    // this.handleChangeInput = this.handleClickOpen.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.handleAllowedJustification = this.handleAllowedJustification.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
    // this.handleAllowedDeclaration = this.handleAllowedDeclaration.bind(this);
    this.handleValueDeclarations = this.handleValueDeclarations.bind(this);
    this.handleValueJustifications = this.handleValueJustifications.bind(this);
    this.handleValueCounter = this.handleValueCounter.bind(this);


  }

  handleValueDeclarations(selectedOptions) {
    var ids = selectedOptions.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + ";" + e.value : e.value;
    }, "");
    this.setState({ declarations: ids });
  }
  handleValueJustifications(selectedOptions) {
    var ids = selectedOptions.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + ";" + e.value : e.value;
    }, "");
    this.setState({ justifications: ids });
  }

  handleValueCounter(e) {
    this.setState({ counter: e != null ? e.value : -1 });
  }

  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };


  // handleAllowedDeclaration(event){
  //   console.log(event.target);
  //   this.setState({
  //     allowedDeclaration: !this.state.allowedDeclaration,

  //   });
  // }
  handleAlert() {
    this.setState({
      alert: !this.state.alert,

    });
  }
  // handleAllowedJustification(){
  //   console.log(this.state.allowedJustification);  
  //   this.setState({
  //     allowedJustification: !this.state.allowedJustification

  //   });
  // }
  add() {
    if (this.state.code == null
      || this.state.code.trim() === "" || this.state.name == null
      || this.state.name.trim() === "" || this.state.description == null
      || this.state.description.trim() === ""

    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    var formData = new FormData(); // Currently empty
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    // formData.append("allowed_declaration", this.state.allowedDeclaration ? 1 : 0);
    formData.append("allowed_declaration", this.state.declarations || "");
    formData.append("alert", this.state.alert ? 1 : 0);
    if (this.state.alert) formData.append("alert_message", this.state.alertMessage);

    // formData.append("allowed_justification", this.state.allowedJustification ? 1 : 0);
    formData.append("allowed_justification", this.state.justifications || "");
    formData.append("counter", this.state.counter);

    this.setState({ inProgress: true });
    var self = this;
    fetch(appProperties.basepathServer + "/manager/indiscipline/add-type?etablissement=" + this.state.etablissementId, {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          fireWithObject(document, 'new-type-indiscipline', { value: data.row });
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AjouterTypeIndisciplineView_14" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AjouterTypeIndisciplineView_15" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className={classes.buttonPrincipale}
          onClick={this.handleClickOpen}

        >
          {formatMessage({ id: "AjouterTypeIndisciplineView_10" })}
        </Button>
        <Dialog
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">{formatMessage({ id: "AjouterTypeIndisciplineView_9" })}</DialogTitle>
          <DialogContent>
            {this.state.inProgress
              ? <div className={classes.progress}><LinearProgress variant="query" /></div>
              : null
            }
            {!this.state.controlled
              ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
              : null
            }
            <div className={classes.pageSubtitle} > {formatMessage({ id: "AjouterTypeAlertView_form" })}  </div>
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>
              {this.state.redirect ? (
                <Redirect
                  to={{
                    pathname: "/",
                    search: "",
                    state: {}
                  }}
                />
              ) : null}

              {/* {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null} */}

              <div className={classes.root}>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="code">{formatMessage({ id: "ItemTypeDecaissement_3" })}</InputLabel>
                  <Input
                    id="code"
                    required
                    name="code"
                    autoComplete="code"
                    onChange={this.handleChangeInput("code")}
                    autoFocus
                    placeholde={formatMessage({ id: "AjouterTypeAlertView_1" })}
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="name">{formatMessage({ id: "ItemTypeDecaissement_4" })}</InputLabel>
                  <Input
                    name="name"
                    type="text"
                    id="name"
                    required
                    onChange={this.handleChangeInput("name")}
                    autoComplete="current-name"
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="description">{formatMessage({ id: "ItemTypeDecaissement_5" })}</InputLabel>
                  <Input
                    id="description"
                    required
                    name="description"
                    autoComplete="description"
                    onChange={this.handleChangeInput("description")}
                    multiline
                    rows="4"
                    variant="filled"
                  />
                </FormControl>

                <div>
                  <LdSelectStatic
                    options={[
                      { value: 'chefclasse', label: formatMessage({ id: "AjouterTypeIndisciplineView_5" }) },
                      { value: 'enseignant', label: formatMessage({ id: "AjouterTypeIndisciplineView_6" }) },
                      { value: 'gestionnaire', label: formatMessage({ id: "AjouterTypeIndisciplineView_7" }) },
                    ]}
                    label={formatMessage({ id: "AjouterTypeIndisciplineView_8" })}
                    handleValue={this.handleValueDeclarations}
                    isMulti
                    isSearchable
                    {...deleteClassInProps(this.props)}
                  />
                </div>
                <p><br /></p>
                <div>
                  <LdSelectStatic
                    options={[
                      { value: 'chefclasse', label: formatMessage({ id: "AjouterTypeIndisciplineView_5" }) },
                      { value: 'enseignant', label: formatMessage({ id: "AjouterTypeIndisciplineView_6" }) },
                      { value: 'gestionnaire', label: formatMessage({ id: "AjouterTypeIndisciplineView_7" }) },
                    ]}
                    label={formatMessage({ id: "AjouterTypeIndisciplineView_4" })}
                    handleValue={this.handleValueJustifications}
                    isMulti
                    isSearchable
                    {...deleteClassInProps(this.props)}
                  />
                </div>
                <p><br /></p>
                <div>
                  <LdSelectStatic
                    options={[
                      { value: 0, label: formatMessage({ id: "AjouterTypeIndisciplineView_12" }) },
                      { value: 1, label: formatMessage({ id: "AjouterTypeIndisciplineView_13" }) },
                      { value: 2, label: formatMessage({ id: "AjouterTypeIndisciplineView_16" }) },
                    ]}
                    label={formatMessage({ id: "AjouterTypeIndisciplineView_11" })}
                    handleValue={this.handleValueCounter}
                    {...deleteClassInProps(this.props)}
                  />
                </div>

                {/* <FormControlLabel
                control={<Checkbox value={this.state.allowedDeclaration} ckecked={this.state.allowedDeclaration}  color="primary" onChange={this.handleAllowedDeclaration}/>}
                label="Peut etre déclaré"
              />       */}
                {/* <FormControlLabel
                control={<Checkbox value={this.state.allowedJustification}  ckecked={this.state.allowedJustification}  color="primary" onChange={this.handleAllowedJustification} />}
                label="Peut etre justifié"
              /> */}

                <FormControlLabel
                  control={<Checkbox value={this.state.alert} ckecked={this.state.alert} color="primary" onChange={this.handleAlert} />}
                  label={formatMessage({ id: "AjouterTypeIndisciplineView_3" })}
                />
                {
                  this.state.alert ?
                    <FormControl margin="dense" required fullWidth>
                      <InputLabel htmlFor="alertMessage">{formatMessage({ id: "AjouterTypeIndisciplineView_2" })}</InputLabel>
                      <Input
                        id="alertMessage"
                        required
                        name="alertMessage"
                        autoComplete="alertMessage"
                        placeholder={formatMessage({ id: "AjouterTypeIndisciplineView_1" })}
                        onChange={this.handleChangeInput("alertMessage")}
                        multiline
                        rows="4"
                        variant="filled"
                      />
                    </FormControl>
                    :
                    null
                }
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}>
              {formatMessage({ id: "AjouterTypeAlertView_13" })}
            </Button>
            <Button
              onClick={this.add}
              variant="contained"
              size="medium"
              disabled={this.state.inProgress}
              className={classes.buttonPrincipale}>
              {this.state.inProgress ? formatMessage({ id: "AjouterTypeAlertView_14" }) : formatMessage({ id: "AjouterTypeAlertView_15" })}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterTypeIndisciplineView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(
  injectIntl(AjouterTypeIndisciplineView)
));
