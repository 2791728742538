import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import {
  appProperties,
  isObjectValueEquals
} from "../../../components/LdUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl, FormattedNumber } from "react-intl";
import moment from "moment";
import printJS from 'print-js'
import { withSnackbar } from "notistack";

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import PrintIcon from "@material-ui/icons/Print";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableFooter } from "@material-ui/core";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import LdSelectTypeFrais from "../../../components/LdSelectTypeFrais";

const styles = theme => ({
  root: {
    width: "100%"
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: "15px",
    marginTop: "15px",
    backgroundColor: "white"
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: "17px",
    lineHeight: "22px",
    fontWeight: 400,
    paddingBottom: "15px"
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: "12px",
    fontWeight: "500",
    transform: "translate(14px, -6px) scale(1)"
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: "7.5px 23px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  }, /** Filter end */
  datePicker: {
    height: "35px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },

});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: '600',
    //fontSize: '15px',
    padding: '10px 0 10px 15px;'
  },
  body: {
    //fontSize: 14,
    padding: '10px 0 10px 15px;'
  },

  footer: {
    //fontSize: 14,
    padding: '10px 0 10px 15px;',
    fontWeight: '600',
    fontSize: '14px',

  }
}))(TableCell);

class EtatFraisView extends Component {
  constructor(props) {
    super(props);
    var minDate = new Date(); minDate.setHours(0, 0, 0, 0);
    var maxDate = new Date(); maxDate.setHours(23, 59, 59, 999);

    // const minDate = null;
    // const maxDate = null;
    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,


      labelWidth: 0,
      period: 1,
      minDate: minDate,
      maxDate: maxDate,
      totals: null,
      head: null,
      typeFraisIds: ""

    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleChangePeriod = this.handleChangePeriod.bind(this);

    this.handleChangeTypeFrais = this.handleChangeTypeFrais.bind(this);

    // this.handlePrint = this.handlePrint.bind(this);

  }

  handleChangeTypeFrais(selectedOptions) {
    var self = this;
    var ids = selectedOptions.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + "-" + e.value : e.value;
    }, "");
    this.setState({ typeFraisIds: ids }, state => {
      self.loadDatas();
    });
  }

  componentDidUpdate(prevProps) {
    var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.loadDatas();
      });
    }
  }

  handleChangePeriod = event => {
    let self = this;
    var period = event.target.value;
    var minDate = null;
    var maxDate = null;
    var date = new Date();
    if (period === 3) {//mois
      minDate = new Date(date.getFullYear(), date.getMonth(), 1); minDate.setHours(0, 0, 0, 0);
      maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0); maxDate.setHours(23, 59, 59, 999);

    } else if (period === 2) {//semaine
      minDate = new Date(date.setDate(date.getDate() - date.getDay())); minDate.setHours(0, 0, 0, 0);
      maxDate = new Date(date.setDate(date.getDate() - date.getDay() + 6)); maxDate.setHours(23, 59, 59, 999);
    } else if (period === 1) {//semaine
      var start = new Date(); start.setHours(0, 0, 0, 0);
      var end = new Date(); end.setHours(23, 59, 59, 999);
      minDate = start;
      maxDate = end;
    } else if (period === 0) {//semaine
      self.setState({ period: period, minDate: null, maxDate: null }, state => {
      });
    }
    if (period !== 0) {
      self.setState({ period: period, minDate: minDate, maxDate: maxDate }, state => {
        self.loadDatas();
      });
    }
  };

  handleStartDateChange(date) {
    var self = this;
    //if (date != null) date.setHours(0, 0, 0, 0);
    this.setState(
      {
        minDate: date,
        period: 0,
      },
      state => {
        self.loadDatas();
      }
    );
  }

  handleEndDateChange(date) {
    var self = this;
    //if (date != null) date.setHours(23, 59, 59, 999);
    this.setState(
      {
        maxDate: date,
        period: 0,
      },
      state => {
        self.loadDatas();
      }
    );
  }


  componentDidMount() {

    this.loadDatas();
  }
  componentWillUnmount() { }




  loadDatas() {
    let self = this;

    this.setState({ datas: [], progress: 1 });
    var url =
      appProperties.basepathServer +
      "/manager/scolarite/list-payment-stats?etablissement=" +
      this.state.etablissement.id;
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;


    if (this.state.minDate && this.state.minDate != null)
      url +=
        "&from=" + moment(this.state.minDate).format("YYYY-MM-DD HH:mm:ss");
    if (this.state.maxDate && this.state.maxDate != null)
      url += "&to=" + moment(this.state.maxDate).format("YYYY-MM-DD HH:mm:ss");
    if (this.state.typeFraisIds != null && this.state.typeFraisIds !== "") url += "&type_ids=" + this.state.typeFraisIds;

    // if(this.state.eleve && this.state.eleve !== "---" && this.state.eleve!=null) url+=("&eleve="+this.state.eleve.id);

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            totals: data.totals,
            head: data.head

          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log("There was an error listed enseignant.");
      });
  }


  handlePrint = () => {

    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    var url =
      appProperties.basepathServer +
      "/manager/scolarite/print-payment-stats?etablissement=" +
      this.state.etablissement.id;
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;


    if (this.state.minDate && this.state.minDate != null)
      url +=
        "&from=" + moment(this.state.minDate).format("YYYY-MM-DD HH:mm:ss");
    if (this.state.maxDate && this.state.maxDate != null)
      url += "&to=" + moment(this.state.maxDate).format("YYYY-MM-DD HH:mm:ss");
    if (this.state.typeFraisIds != null && this.state.typeFraisIds !== "") url += "&type_ids=" + this.state.typeFraisIds;

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -3) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemVersement_11" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -4) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_5" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -2) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }
  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon /> {/*{self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      persist: true,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,

      scanStyles: false,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }



  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <Grid
          container
          className={classes.grid}
          justify="space-between"
          alignItems="center"
        >
          <div className={classes.pageTitle} variant="div" component="div">
            {formatMessage({ id: "EtatFraisView_2" })}
          </div>


        </Grid>
        <Grid
          container
          className={classes.grid}
          justify="flex-end"
          alignItems="center"
        >


          <FormControl
            style={{ height: "35px" }}
            variant="outlined"
            className={classes.formControl}
          >
            <Select
              title={formatMessage({ id: "ListVersementView_5" })}
              classes={{
                selectMenu: classes.selectMenu,
                root: classes.selectRoot
              }}
              value={this.state.period}
              onChange={this.handleChangePeriod}
              input={
                <OutlinedInput
                  labelWidth={this.state.labelWidth}
                  name={formatMessage({ id: "ListVersementView_5" })}
                  id="outlined-typefrais-simple"
                  classes={{ input: classes.outlinedInput }}
                />
              }
            >
              <MenuItem value={1}>
                {formatMessage({ id: "ListVersementView_6" })}
              </MenuItem>
              <MenuItem value={2}>
                {formatMessage({ id: "ListVersementView_7" })}
              </MenuItem>
              <MenuItem value={3}>
                {formatMessage({ id: "ListVersementView_8" })}
              </MenuItem>
              <MenuItem value={0}>
                {formatMessage({ id: "ListVersementView_9" })}
              </MenuItem>
            </Select>
          </FormControl>
          {this.state.period === 0 &&
            <React.Fragment>

              <span style={{ paddingRight: "5px" }} className={classes.label}>
                {formatMessage({ id: "ListAlertHistoryView_deb" })}
              </span>
              <DateTimePicker
                // maxDate={this.state.maxDate}
                locale="fr-FR"
                className={classes.datePicker}
                value={this.state.minDate}
                onChange={this.handleStartDateChange}
              />

              <span
                style={{ marginLeft: "20px", paddingRight: "5px" }}
                className={classes.label}
              >
                {formatMessage({ id: "ListAlertHistoryView_fin" })}
              </span>
              <DateTimePicker
                label={formatMessage({ id: "ListAlertHistoryView_1" })}
                className={classes.datePicker}
                locale="fr-FR"
                value={this.state.maxDate}
                minDate={this.state.minDate}
                onChange={this.handleEndDateChange}
              />

            </React.Fragment>
          }

          <Button disabled={this.state.minDate == null || this.state.maxDate == null} onClick={() => this.handlePrint()} title={formatMessage({ id: "ItemVersement_10" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
            <PrintIcon />
          </Button>
        </Grid>
        <Grid>

          {this.props.annee != null && <LdSelectTypeFrais
            label={formatMessage({ id: "EtatFraisView_3" })}
            handleValue={this.handleChangeTypeFrais}
            firstLoad isSearchable
            isMulti
            baseUrl={appProperties.basepathServer + "/manager/type-frais/list?limit=-1&only_published=1&etablissement=" + this.props.etablissement.id + "&annee=" + this.props.annee.id}  {...deleteClassInProps(this.props)} />}
        </Grid>
        <Table className={classes.table}>
          <TableHead>

            <TableRow>
              <CustomTableCell>{formatMessage({ id: "EtatFraisView_4" })}</CustomTableCell>
              <CustomTableCell align="left">{formatMessage({ id: "EtatFraisView_5" })}</CustomTableCell>
              <CustomTableCell align="left">{formatMessage({ id: "EtatFraisView_6" })}</CustomTableCell>
              <CustomTableCell align="left">{formatMessage({ id: "EtatFraisView_7" })}</CustomTableCell>
              <CustomTableCell align="left">{formatMessage({ id: "EtatFraisView_8" })}</CustomTableCell>
              <CustomTableCell align="left">{formatMessage({ id: "EtatFraisView_9" })}</CustomTableCell>
              <CustomTableCell align="left">{formatMessage({ id: "EtatFraisView_10" })}</CustomTableCell>
            </TableRow>
          </TableHead>
          {this.state.progress === 0 && (
            <React.Fragment>
              <TableBody>
                {this.state.datas.map((row, index) => (
                  <TableRow>
                    <CustomTableCell>{row.type.name}<br /> <span style={{ fontSize: "10px" }}><FormattedNumber value={row.type.amount} style="currency" currency={"XAF"} /></span></CustomTableCell>
                    <CustomTableCell align="left"><FormattedNumber value={row.total} style="currency" currency={"XAF"} /></CustomTableCell>
                    <CustomTableCell align="left"><FormattedNumber value={row.paid} style="currency" currency={"XAF"} /></CustomTableCell>
                    <CustomTableCell align="left"><FormattedNumber value={row.reduction} style="currency" currency={"XAF"} /></CustomTableCell>
                    <CustomTableCell align="left"><FormattedNumber value={row.paidOn.paid} style="currency" currency={"XAF"} /></CustomTableCell>
                    <CustomTableCell align="left"><FormattedNumber value={row.paidOn.reduction} style="currency" currency={"XAF"} /></CustomTableCell>
                    <CustomTableCell align="left"><FormattedNumber value={row.rest} style="currency" currency={"XAF"} /></CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>

                <TableRow>
                  <CustomTableCell>{formatMessage({ id: "EtatFraisView_11" })}</CustomTableCell>
                  <CustomTableCell align="left"><FormattedNumber value={this.state.totals.total} style="currency" currency={"XAF"} /></CustomTableCell>
                  <CustomTableCell align="left"><FormattedNumber value={this.state.totals.paid} style="currency" currency={"XAF"} /></CustomTableCell>
                  <CustomTableCell align="left"><FormattedNumber value={this.state.totals.reduction} style="currency" currency={"XAF"} /></CustomTableCell>
                  <CustomTableCell align="left"><FormattedNumber value={this.state.totals.paidOn.paid} style="currency" currency={"XAF"} /></CustomTableCell>
                  <CustomTableCell align="left"><FormattedNumber value={this.state.totals.paidOn.reduction} style="currency" currency={"XAF"} /></CustomTableCell>
                  <CustomTableCell align="left"><FormattedNumber value={this.state.totals.rest} style="currency" currency={"XAF"} /></CustomTableCell>
                </TableRow>
              </TableFooter>
            </React.Fragment>
          )}
        </Table>


        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 ||
          (this.state.progress === 0 && this.state.datas.length === 0)) && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "EtatFraisView_1" })}
              </div>
            </div>
          )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              {formatMessage({ id: "ListClasseAnneeView_4" })}
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withSnackbar(withStyles(styles)(injectIntl(EtatFraisView)));
