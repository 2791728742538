import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import LdEtablissementMenu from "../components/LdEtablissementMenu";
import PersonnelController from "./controller/PersonnelController";
import RoleController from "./controller/RoleController";
import AnneeScolaireController from "./controller/AnneeScolaireController";
import MatiereController from "./controller/MatiereController";
import ClasseEtablissementController from "./controller/ClasseEtablissementController";
import TypeFraisController from "./controller/TypeFraisController";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Page404 from "../components/Page404";
import { appProperties, hasAccessKey } from '../components/LdUtils';
import CircularProgress from '@material-ui/core/CircularProgress';
import AnneeIndex from '../annee/AnneeIndex';
import InscriptionIndex from '../inscription/InscriptionIndex';
import ScolariteIndex from '../scolarite/ScolariteIndex';
import DisciplineIndex from '../discipline/DisciplineIndex';
import EnseignantIndex from '../enseignant/EnseignantIndex';
import NoteIndex from '../note/NoteIndex';
import CahierIndex from '../cahier/CahierIndex';
import ChefIndex from '../chef/ChefIndex';
import PlanningIndex from '../planning/PlanningIndex';
import AlertIndex from '../alert/AlertIndex';
import StockIndex from '../stock/StockIndex';
import ComptabiliteIndex from '../comptabilite/ComptabiliteIndex';
import SignatureIndex from '../signature/SignatureIndex';
import TypeGroupeEnseignementController from './controller/TypeGroupeEnseignementController';
import DesignController from './controller/DesignController';
import CampusController from './controller/CampusController';
import CategorieEtablissementController from './controller/CategorieEtablissementController';
// import PageConstruction from '../components/PageConstruction';
import DashboardComponent from '../dashboard/DashboardComponent';
import StickyBox from "react-sticky-box";
import { deleteClassInProps } from '../components/LdUtils';
import { injectIntl } from "react-intl";
import AppFooter from '../components/AppFooter';

const useStyles = theme => ({
  rootLoggedd: {
    flexGrow: 1,
    padding: '15px'
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },
});

class EtablissementIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: this.getCode(),
      etablissement: null,
      accesses: null,
      inProgress: true,
      inProgessAccess: false,
      progress: 1
    };


  }

  componentDidMount() {
    this.getEtablissementByCode(this.state.code);
    document.addEventListener('update-etablissement', this._handleUpdateEtablissement.bind(this));
  }
  componentWillUnmount() {
    document.addEventListener('update-etablissement', this._handleUpdateEtablissement.bind(this));
  }
  _handleUpdateEtablissement(event) {
    if (event && event.detail) this.setState({ etablissement: event.detail.etablissement });
  }
  getCode() {
    return this.props.match.params ? (this.props.match.params.code || "") : "";
  }
  getEtablissementByCode(code) {
    let self = this;
    this.setState({ etablissement: null, progress: 1 });
    fetch(appProperties.basepathServer + '/rest-etablissement/load?code=' + code, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result_code === 1) {
          self.setState({ etablissement: data.item, progress: 2 });
          console.log(data.item);
          self.getAccesses(data.item.id);
        } else if (data.result_code === 0) {
          self.setState({ etablissement: null, progress: 3 });
        } else if (data.result_code === -1000) {
          console.log("redirect");
          console.log(this.props.location);
          if (this.props && this.props.history && this.props.history != null) this.props.history.push({ pathname: '/user/login', search: '?redirect_url=' + encodeURIComponent(this.props.location.pathname + "" + this.props.location.search), state: {} });
        }
      })
      .catch((err) => {
        console.log(err);
        self.setState({ etablissement: null, progress: 4 });
        console.log('There was an error.');
      });
  }
  getAccesses(etablissementId) {
    let self = this;
    this.setState({ accesses: null, progress: 2 });
    fetch(appProperties.basepathServer + '/manager/etablissement/load-accesses-etablissement?id=' + etablissementId, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result_code === 1) {
          self.setState({ accesses: data.accesses, progress: 0 });
        } else if (data.result_code === -1) {
          self.setState({ accesses: null, progress: 5 });
        } else if (data.result_code === -1000) {
          if (this.props && this.props.history && this.props.history != null) this.props.history.push({ pathname: '/user/login', search: '?redirect_url=' + encodeURIComponent(this.props.location.pathname + "" + this.props.location.search), state: {} });
        }

      })
      .catch((err) => {
        console.log(err);
        self.setState({ accesses: null, progress: 6 });
        console.log('There was an error.');
      });
  }

  // componentDidMount() {
  //   // const values = queryString.parse(this.props.location.search);
  //   // console.log(values.filter); // "top"
  //   // console.log(values.origin); // "im"
  // }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    // const values = queryString.parse(this.props.location.search);
    // console.log(values.email); // "im"
    // var emailUser = values.email;

    if (this.state.progress === 0) {
      if (this.state.etablissement == null) {
        return (<span>{formatMessage({ id: "ListClasseEtablissementView_1" })}</span>)
      } else {
        if (this.state.accesses == null) {
          return (<span>{formatMessage({ id: "EnseignementIndex_1" })}</span>)
        } else {
          return (
            <div className={classes.rootLoggedd}>
              <Grid container spacing={0}>
                <Grid item xs={3}>
                  <StickyBox offsetTop={15} offsetBottom={20}>

                    <LdEtablissementMenu accesses={this.state.accesses} etablissement={this.state.etablissement}  {...deleteClassInProps(this.props)} />
                  </StickyBox>
                </Grid>
                <Grid item xs={9}>
                  <Switch>
                    <Route
                      path="/page/:code/config"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <AnneeIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      path="/page/:code/inscription"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'inscription')) {
                          return <InscriptionIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      path="/page/:code/scolarite"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'scolarite')) {
                          return <ScolariteIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      path="/page/:code/note"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'note')) {
                          return <NoteIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      path="/page/:code/discipline"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'discipline')) {
                          return <DisciplineIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />

                    <Route
                      path="/page/:code/enseignant"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'enseignant')) {
                          return <EnseignantIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      path="/page/:code/cahier"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'cahiertexte')) {
                          return <CahierIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      path="/page/:code/chef"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'chef')) {
                          return <ChefIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      path="/page/:code/planning"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'planning')) {
                          return <PlanningIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      path="/page/:code/alert"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <AlertIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />


                    <Route
                      path="/page/:code/stock"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'stock') || hasAccessKey(this.state.accesses, 'stockR')) {
                          return <StockIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />


                    <Route
                      path="/page/:code/comptabilite"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'comptable') || hasAccessKey(this.state.accesses, 'chefComptable')) {
                          return <ComptabiliteIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      path="/page/:code/signature"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'coordo')) {
                          return <SignatureIndex accesses={this.state.accesses} etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    {/* <Route
                      exact
                      path="/page/:code/comptabilite"
                      render={props => {
                            //return (<p>PAge des roles</p>)
                          if(hasAccessKey(this.state.accesses,'admin')){
                            return <PageConstruction etablissement={this.state.etablissement} {...props} />;
                          }else{
                            //TODO: Afficher une page de  droit d'access
                            return <Page404 {...props} />;
                          }
                      }}
                    /> */}









                    <Route
                      exact
                      path="/page/:code/personnels"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <PersonnelController etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      exact
                      path="/page/:code/matieres"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <MatiereController etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    {/* <Route
                      exact
                      path="/page/:code/type-frais"
                      render={props => {
                        // return (<p>Page des enseignants</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <TypeFraisController etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    /> */}
                    <Route
                      exact
                      path="/page/:code/roles"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <RoleController etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />

                    <Route
                      exact
                      path="/page/:code/annees"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <AnneeScolaireController etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      exact
                      path="/page/:code/classes"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <ClasseEtablissementController etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />

                    <Route
                      exact
                      path="/page/:code/type-groupe-enseignements"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <TypeGroupeEnseignementController etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      exact
                      path="/page/:code/dashboard"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <DashboardComponent etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      exact
                      path="/page/:code/designs"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <DesignController etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      exact
                      path="/page/:code/categorie-etablisssement"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <CategorieEtablissementController etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />

                    <Route
                      exact
                      path="/page/:code/campus"
                      render={props => {
                        //return (<p>PAge des roles</p>)
                        if (hasAccessKey(this.state.accesses, 'admin')) {
                          return <CampusController etablissement={this.state.etablissement} {...props} />;
                        } else {
                          //TODO: Afficher une page de  droit d'access
                          return <Page404 {...props} />;
                        }
                      }}
                    />
                    <Route
                      render={props => {
                        return <Page404 {...props} />;
                      }}
                    />
                  </Switch>
                  <AppFooter fontSize="12px" />
                </Grid>
              </Grid>
            </div>
          );
        }
      }

    } else if (this.state.progress === 1)
      return (
        <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
          <div style={{ padding: '15px' }}>
            <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
          </div>
          <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
            {formatMessage({ id: "DashboardComponent_Wait" })}
          </div>
        </div>
      )
    else if (this.state.progress === 2)
      return (
        <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
          <div style={{ padding: '15px' }}>
            <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
          </div>
          <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
            {formatMessage({ id: "EnseignementIndex_2" })}
          </div>
        </div>
      )
    else if (this.state.progress === 3)
      return (
        <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
          <div style={{ padding: '15px' }}>Oups!!</div>
          <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
            {formatMessage({ id: "EnseignementIndex_3" })}
          </div>
        </div>
      )
    else if (this.state.progress === 4) return (
      <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
        <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
        <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
          {formatMessage({ id: "EnseignementIndex_4" })}
        </div>
      </div>
    )
    else if (this.state.progress === 5) return (
      <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
        <div style={{ padding: '15px' }}>{formatMessage({ id: "EnseignementIndex_5" })}</div>
        <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
          {formatMessage({ id: "EnseignementIndex_6" })}
        </div>
      </div>
    )
    else if (this.state.progress === 6) return (
      <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
        <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
        <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
          {formatMessage({ id: "EnseignementIndex_7" })}
        </div>
      </div>
    )



  }
}
EtablissementIndex.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(injectIntl(EtablissementIndex));
