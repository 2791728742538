import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { appProperties, deleteClassInProps, isObjectValueEquals } from '../../components/LdUtils';
import ListMoyenne from '../view/bulletin/ListMoyenne';
import { injectIntl } from "react-intl";
import Button from "@material-ui/core/Button";

import printJS from 'print-js'

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import { withSnackbar } from 'notistack';







const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: '15px',
    marginTop: '15px',
    backgroundColor: 'white',
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },





  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  }  /** Filter end */
});

class ListBulletinController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      classe: '---',
      classes: [],
      progressClass: 1,
      trimestre: '---',
      trimestres: [],
      sequence: '---',
      sequences: [],
      progressTrimestre: 0,
      labelWidth: 0,

      categories: [],
      categorie: "---",

    };
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeTrimestre = this.handleChangeTrimestre.bind(this);
    this.handleChangeSequence = this.handleChangeSequence.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);
    this.handlePrintBordereauComplete = this.handlePrintBordereauComplete.bind(this);
    this.handlePrintLivretScolaire = this.handlePrintLivretScolaire.bind(this);

  }

  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value) }, (state) => {
      self.handleReset();
      // self.setCurrentPage(1);
    });
  };
  handleChangeTrimestre = event => {
    this.setState({ trimestre: (event.target.value) }, (state) => {
      // self.setCurrentPage(1);
    });
  };

  handleChangeSequence = event => {
    this.setState({ sequence: (event.target.value) }, (state) => {
      // self.setCurrentPage(1);
    });
  };
  handleChangeClass = event => {
    this.setState({ classe: (event.target.value) }, (state) => {
      // self.setCurrentPage(1);
    });
  };
  componentDidMount() {
    //this.listClasses();
    this.listCategorie();
    // if (this.props.mode === "trimestre") this.listTrimestres(0);
    // if (this.props.mode === "sequence") this.listTrimestres(1);
  }
  handleReset = () => {
    var self = this;
    this.setState({
      classe: '---',
      classes: [],
      trimestre: '---',
      trimestres: [],
      sequence: '---',
      sequences: [],
    }, () => {
      self.listClasses();
      if (self.props.mode === "trimestre") self.listTrimestres(0);
      else if (self.props.mode === "sequence") self.listTrimestres(1);
      else self.setState({ progressTrimestre: 0 });
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();

      });
    }

    if (!isObjectValueEquals(this.props.mode, prevProps.mode)) {
      this.setState({ mode: this.props.mode, categorie: '---' }, () => {
        self.handleReset();
        // if (self.props.mode === "trimestre") self.listTrimestres(0);
        // else if (self.props.mode === "sequence") self.listTrimestres(1);
        // else self.setState({ progressTrimestre: 0 });
      });
    }


  }

  listClasses() {

    let self = this;
    this.setState({ classes: [], progressClass: 1 });
    var url = appProperties.basepathServer + "/rest-enseignant/list-grouped?etablissement_id=" + this.props.etablissement.id + "&limit=-1";
    if (this.props.annee != null) url += ("&annee_id=" + this.props.annee.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [], progressClass: 0 });
      return;
    }

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          var enseignants = [];
          (data.datas || []).forEach(e => {
            enseignants = enseignants.concat(e.enseignants);
          });
          self.setState({ progressClass: 0, classes: self.formatData({ enseignants: enseignants }) });

        } else {
          self.setState({ classes: [], progressClass: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ classes: [], progressClass: 3 });

        console.log('There was an error listed type de frais.');
      });
  }
  listTrimestres(load_sequences) {
    let self = this;
    this.setState({ trimestres: [], sequences: [], progressTrimestre: 1 });
    var url = appProperties.basepathServer + '/manager/annee-scolaire/trimestres?limit=-1&load_sequences=' + load_sequences;
    if (this.props.annee != null) url += ("&id=" + this.props.annee.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ trimestres: [], sequences: [], progressTrimestre: 0 });
      return;
    }
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }

          if (load_sequences === 1) {
            var sequences = [];
            var trimestre = null;
            (data.datas || []).forEach(e => {
              trimestre = Object.assign({}, e);
              delete trimestre.sequences;
              (e.sequences || []).forEach(seq => {
                //seq['trimestre'] = trimestre
                sequences.push(seq);
              });
            });
            self.setState({ progressTrimestre: 0, sequences: sequences, trimestres: [] });
          } else self.setState({ progressTrimestre: 0, sequences: [], trimestres: data.datas });

        } else {
          self.setState({ trimestres: [], progressTrimestre: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ trimestres: [], sequences: [], progressTrimestre: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  formatData(input) {
    if (null == input) return [];
    if (input.enseignants && Array.isArray(input.enseignants) && 0 < input.enseignants.length) {
      var enseignants = input.enseignants;
      var output = Object.assign({}, input);
      delete output.enseignants;
      var exist = false;
      var myReduce = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
        // console.log(accumulator);
        exist = accumulator.reduce((acc, cur, ind, arr) => {
          return acc ? acc : null != cur && null != currentValue.classeEtablissement && cur.id === currentValue.classeEtablissement.id;
        }, false);
        return exist ? accumulator : accumulator.concat(currentValue.classeEtablissement ? currentValue.classeEtablissement : null);
      }, []);
      var contents = [];
      var mat = null;
      console.log("myReduce ", myReduce);
      var myMap = myReduce.map((classe, index, array) => {
        contents = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
          if (null != classe && null != currentValue.classeEtablissement && classe.id === currentValue.classeEtablissement.id) {
            mat = Object.assign({}, currentValue);
            delete mat.classeEtablissement;
            return accumulator.concat(mat);
          } else return accumulator;
        }, []);
        classe["enseignants"] = contents;
        return classe;
      });
      //output["classes"] = myMap;

      return myMap;
    }
    //input["classes"] = [];
    return [];
  }


  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }

  handlePrintBordereauComplete = (url, isArchive) => {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    //var url = this.state.classe == null || this.state.classe === '---' &&  ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {

        try {
          var pdfUrl = URL.createObjectURL(blob);
          if (isArchive) {
            const a = document.createElement("a");
            a.href = url;
            var now = new Date();
            a.download = 'Impression_' + now.getTime() + '_pdf.zip';
            a.click();
          } else self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }



      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }

  handlePrintLivretScolaire = (url) => {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    //var url = this.state.classe == null || this.state.classe === '---' &&  ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {

        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }



      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }
  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <Grid container className={classes.grid} justify="space-between" alignItems='center'>
          <div>
            <div className={classes.pageTitle} variant="div" component="div">
              {this.props.mode === "sequence" && (formatMessage({ id: 'LdEtablissementMenu_35' }))}
              <span style={{ fontWeight: '600' }}>{this.props.mode === "trimestre" && (formatMessage({ id: 'LdEtablissementMenu_36' }))}</span>
              <span style={{ fontWeight: '600' }}>{this.props.mode === "annuelle" && this.props.annee != null && (formatMessage({ id: 'LdEtablissementMenu_37' }) + " " + this.props.annee.year)}</span>
            </div>
            <div className={classes.pageSubtitle} variant="div" component="div">
              {formatMessage({ id: "ListBulletinController_1" })}
            </div>
          </div>
          <div style={{ width: '100%' }} className="layout horizontal center end-justified">
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "ListClasseEtablissementView_4"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.categorie}
                onChange={this.handleChangeCategorie}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListClasseEtablissementView_3" })}
                </MenuItem>
                {this.state.categories.map((row, index) => (
                  <MenuItem title={row.description} key={index} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
              <Select
                title={formatMessage({ id: "AjouterClasseEtablissementView_6" })}
                displayEmpty
                classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                value={this.state.classe}
                onChange={this.handleChangeClass}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              > <MenuItem value={'---'}>{formatMessage({ id: "ListEleveView_1" })} </MenuItem>
                {this.state.classes.map((row, index) => (
                  <MenuItem key={index} value={row}>{row.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {this.props.mode === "sequence" && (
              <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
                <Select
                  title={formatMessage({ id: "DashboardContainer_2" })}
                  displayEmpty
                  classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                  value={this.state.sequence}
                  onChange={this.handleChangeSequence}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="Choisir une séquence"
                      id="outlined-classe-simple"
                      classes={{ input: classes.outlinedInput }}
                    />
                  }
                > <MenuItem value={'---'}>{formatMessage({ id: "DashboardContainer_3" })} </MenuItem>
                  {this.state.sequences.map((row, index) => (
                    <MenuItem key={index} value={row}>{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>)}

            {this.props.mode === "trimestre" && (
              <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
                <Select
                  title={formatMessage({ id: "DashboardContainer_1" })}
                  displayEmpty
                  classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                  value={this.state.trimestre}
                  onChange={this.handleChangeTrimestre}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="Choisir un trimestre"
                      id="outlined-classe-simple"
                      classes={{ input: classes.outlinedInput }}
                    />
                  }
                > <MenuItem value={'---'}>{formatMessage({ id: "DashboardContainer_4" })} </MenuItem>
                  {this.state.trimestres.map((row, index) => (
                    <MenuItem key={index} value={row}>{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>)}
          </div>
        </Grid>
        <div style={{ padding: '5px 5px 5px 5px', border: '1px dashed #EAEAEA', margin: '15px 0' }} className="layout horizontal center justified">
          <div className="flex">
            {this.props.mode === "sequence" && <span style={{ fontSize: '13px' }}>{formatMessage({ id: "ListMoyenne_34" })}</span>}
            {this.props.mode === "trimestre" && <span style={{ fontSize: '13px' }}>{formatMessage({ id: "ListMoyenne_35" })}</span>}
            {this.props.mode === "annuelle" && <span style={{ fontSize: '13px' }}>{formatMessage({ id: "ListMoyenne_36" })}</span>}
          </div>
          {this.props.mode === "sequence" && <React.Fragment> <Button
            onClick={() => this.handlePrintBordereauComplete((appProperties.basepathServer + '/manager/note/print-bordereau-complete?etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.id + '&sequence=' + this.state.sequence.id), false)}
            title={formatMessage({ id: "ListMoyenne_31" })}
            disabled={this.state.classe === "---" || (this.props.mode === "sequence" && this.state.sequence === "---")}
            style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small"
          >
            {formatMessage({ id: "ListMoyenne_30" })}
          </Button></React.Fragment>}
          {this.props.mode === "trimestre" && <React.Fragment> <Button
            onClick={() => this.handlePrintBordereauComplete((appProperties.basepathServer + '/manager/note/print-bordereau-complete?etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.id + '&trimestre=' + this.state.trimestre.id), false)}
            title={formatMessage({ id: "ListMoyenne_32" })}
            disabled={this.state.classe === "---" || (this.props.mode === "trimestre" && this.state.trimestre === "---")}
            style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small"
          >
            {formatMessage({ id: "ListMoyenne_30" })}
          </Button></React.Fragment>}
          {this.props.mode === "annuelle" && <React.Fragment>

            <Button
              onClick={() => this.handlePrintLivretScolaire((appProperties.basepathServer + '/manager/note/print-livret?etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe_etablissement=' + this.state.classe.id))}
              title={formatMessage({ id: "ListBulletinController_7" })}
              disabled={this.state.classe === "---" || (this.props.mode === "annuelle" && this.state.annee === "---")}
              style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small"
            >
              {formatMessage({ id: "ListBulletinController_6" })}
            </Button>

            <Button
              onClick={() => this.handlePrintBordereauComplete((appProperties.basepathServer + '/manager/note/print-bordereau-complete?etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.id), false)}
              title={formatMessage({ id: "ListMoyenne_33" })}
              disabled={this.state.classe === "---" || (this.props.mode === "annuelle" && this.state.annee === "---")}
              style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small"
            >
              {formatMessage({ id: "ListMoyenne_30" })}
            </Button>

          </React.Fragment>}
        </div>

        {this.state.categorie !== "---" && (<React.Fragment>
          {(this.state.progressTrimestre === 0) &&
            ((this.state.classe !== "---" && (this.props.mode === "sequence" && this.state.sequence !== "---")) ||
              (this.state.classe !== "---" && (this.props.mode === "trimestre" && this.state.trimestre !== "---")) ||
              (this.state.classe !== "---" && (this.props.mode === "annuelle"))) && (

              <ListMoyenne
                etablissement={this.state.etablissement}
                annee={this.state.annee}
                classe={this.state.classe}
                expanded={this.state.expanded}
                mode={this.props.mode}
                sequence={this.state.sequence}
                trimestre={this.state.trimestre}
                {...deleteClassInProps(this.props)} />
            )}


          {(this.state.progressTrimestre === 0) &&
            (this.state.classe === "---" || (this.state.sequence === "---" && this.props.mode === "sequence") || (this.state.trimestre === "---" && this.props.mode === "trimestre")) && (
              <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                {/* <div style={{padding:'15px'}}>
                  <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                </div> */}
                <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)', textAlign: 'center' }} className="layout horizontal center-center">

                  {this.props.mode === "sequence" && <span> {formatMessage({ id: "ListBulletinController_3" })}</span>}
                  {this.props.mode === "trimestre" && <span>{formatMessage({ id: "ListBulletinController_4" })}</span>}
                  {this.props.mode === "annuelle" && <span> {formatMessage({ id: "ListBulletinController_5" })}</span>}
                </div>
              </div>
            )}
          {(this.state.progressTrimestre === 1) && (this.state.classe !== "---" && this.state.classe !== null) &&
            ((this.props.mode === "sequence" && (this.state.sequence !== "---" || this.state.sequence !== null)) ||
              (this.props.mode === "trimestre" && (this.state.trimestre !== "---" || this.state.trimestre !== null)) ||
              (this.props.mode === "annuelle")) && (
              <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                <div style={{ padding: '15px' }}>
                  <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                </div>
                <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                  {formatMessage({ id: "ListRoleView_Load" })}
                </div>
              </div>
            )}

          {(this.state.progressTrimestre === 2) && (
            <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                {formatMessage({ id: "ListBulletinController_2" })}
              </div>
            </div>
          )}
          {this.state.progressTrimestre === 3 && (
            <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
              <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
              <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                {formatMessage({ id: "ListRoleView_LoadError" })}
              </div>
            </div>
          )}
        </React.Fragment>)}


        {this.state.categorie === "---" && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListCahierView_14" })}
            </div>
          </div>
        )}

      </div>
    );
  }

}

ListBulletinController.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(injectIntl(ListBulletinController)));