import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { appProperties, fireWithObject, deleteClassInProps } from "../../../components/LdUtils";
// import { deleteClassInProps } from "../../../components/LdUtils";
// import LdSelect from "../../../components/LdSelect";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";
import LdImageUploader from "../../../components/LdImageUploader";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  root: {
    width: '100%'
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },
  label: {
    padding: "5px"
  }
});

class AjouterSignatureView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)


    this.state = {
      name: "",
      responsable: "",
      fonction: "",
      photo: "",
      pathPhoto: "",


      etablissementId: this.props.etablissementId,
      annee: this.props.annee,

      dialogOpened: false,
      inProgress: false,
      controlled: true,
      errors: {}
    };
    this.add = this.add.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.newImageUploaded = this.newImageUploaded.bind(this);

  }
  newImageUploaded(name, path) {
    this.setState({ photo: name, pathPhoto: path });
  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };


  add() {
    var errors = {};
    this.setState({ errors: errors });

    if (this.state.name == null || this.state.name === "") errors["name"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.responsable == null || this.state.responsable === "") errors["responsable"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.fonction == null || this.state.fonction === "") errors["fonction"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.photo == null || this.state.photo === "") errors["photo"] = this.props.intl.formatMessage({ id: "InputError_1" });


    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    var formData = new FormData(); // Currently empty
    formData.append("etablissement", this.props.etablissementId);
    formData.append("responsable", this.state.responsable);
    formData.append("name", this.state.name);
    formData.append("fonction", this.state.fonction);
    formData.append("photo", this.state.photo);

    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/manager/signature/add",
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, "new-signature", { value: data.row });

          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSignatureView_7" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSignatureView_8" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSignatureView_9" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className={classes.buttonPrincipale}
          onClick={this.handleClickOpen}
        >
          {formatMessage({ id: "AjouterSignatureView_1" })}
        </Button>
        <Dialog
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"md"}
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">
            {formatMessage({ id: "AjouterSignatureView_1" })}
          </DialogTitle>
          <DialogContent>
            {this.state.inProgress ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {!this.state.controlled ? (
              <div className={classes.error}>
                {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
              </div>
            ) : null}
            <div className={classes.pageSubtitle}>
              {formatMessage({ id: "AjouterSignatureView_2" })}
            </div>
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>


              {/* {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null} */}

              <div className={classes.root}>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="name">
                    {formatMessage({ id: "AjouterSignatureView_3" })}
                  </InputLabel>
                  <Input
                    error={this.state.errors.name}
                    name="name"
                    type="text"
                    id="name"
                    required
                    onChange={this.handleChangeInput("name")}
                    autoComplete="current-name"
                  />
                  <FormHelperText>{this.state.errors.name ? <span className="error_field">{this.state.errors.name}</span> : ""}</FormHelperText>

                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="responsable">
                    {formatMessage({ id: "AjouterSignatureView_4" })}
                  </InputLabel>
                  <Input
                    error={this.state.errors.responsable}
                    id="responsable"
                    required
                    name="responsable"
                    autoComplete="responsable"
                    onChange={this.handleChangeInput("responsable")}
                    autoFocus
                  />
                  <FormHelperText>{this.state.errors.responsable ? <span className="error_field">{this.state.errors.responsable}</span> : ""}</FormHelperText>
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="fonction">
                    {formatMessage({ id: "AjouterSignatureView_5" })}
                  </InputLabel>
                  <Input
                    error={this.state.errors.fonction}
                    id="fonction"
                    required
                    name="fonction"
                    autoComplete="fonction"
                    onChange={this.handleChangeInput("fonction")}
                    autoFocus
                  />
                  <FormHelperText>{this.state.errors.fonction ? <span className="error_field">{this.state.errors.fonction}</span> : ""}</FormHelperText>
                </FormControl>
                <FormControl margin="dense" required fullWidth>

                  <LdImageUploader maxSize={500000} name={this.state.photo}
                    hasParent={this.state.photo != null && this.state.photo !== ""}
                    path={this.state.pathPhoto}
                    label={formatMessage({ id: "AjouterSignatureView_6" })}
                    onImageUploaded={this.newImageUploaded}
                    {...deleteClassInProps(this.props)} />

                  <FormHelperText>{this.state.errors.photo ? <span className="error_field">{this.state.errors.photo}</span> : ""}</FormHelperText>
                </FormControl>

              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}
            >
              {formatMessage({ id: "AjouterTypeAlertView_13" })}
            </Button>
            <Button
              onClick={this.add}
              variant="contained"
              size="medium"
              disabled={this.state.inProgress}

              className={classes.buttonPrincipale}
            >
              {formatMessage({ id: "AjouterTypeAlertView_15" })}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterSignatureView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AjouterSignatureView))
);
