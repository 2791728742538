import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import { appProperties } from "../../../components/LdUtils";
import ItemClasseEtablissement from "./ItemClasseEtablissement";
import CircularProgress from "@material-ui/core/CircularProgress";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";


import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";


const styles = theme => ({
  root: {
    width: "100%"
    // marginTop: theme.spacing.unit * 3,
  },
  main: {
    padding: "15px",
    marginTop: "15px",
    backgroundColor: "white"
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400,
    paddingBottom: "15px"
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */

});

class ListClasseEtablissementView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      currentPage: 1,
      progress: 1,
      categories: [],
      categorie: "---",

      campuses: [],
      campus: "---",
    };
    this._handleNewClasse = this._handleNewClasse.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);
    this.handleChangeCampus = this.handleChangeCampus.bind(this);
  }

  handleChangeCampus = event => {
    let self = this;
    this.setState({ campus: (event.target.value) }, (state) => {
      self.setCurrentPage(1);
    });
  };
  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value) }, (state) => {
      self.setCurrentPage(1);
    });
  };
  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listClasse(page);
  };
  _handleNewClasse = event => {
    this.setState(state => {
      state.datas = [event.detail.value, ...state.datas];
      return state;
    });
  };
  onUpdateItem = (mode, item) => {
    console.log("intercept", mode);
    console.log(item);
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  componentDidMount() {
    this.listCategorie();
    this.listCampus();
    this.listClasse(this.state.currentPage);
    document.addEventListener("new-classe", this._handleNewClasse.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener(
      "new-classe",
      this._handleNewClasse.bind(this)
    );
  }
  listClasse(page) {
    let self = this;

    this.setState({ datas: [], progress: 1 });
    var url = appProperties.basepathServer +
      "/rest-etablissement/list-classes?id=" +
      this.state.etablissement.id +
      "&limit=10&page=" +
      page;
    if (this.state.campus && this.state.campus !== "---" && this.state.campus != null) url += ("&campus_id=" + this.state.campus.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);

    // fetch(appProperties.basepathServer+ '/manager/modele-bulletin/list?limit=5&page='+page, {
    fetch(
      url,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log("There was an error listed enseignant.");
      });
  }

  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }


  listCampus() {
    let self = this;

    this.setState({ campuses: [] });

    fetch(
      appProperties.basepathServer +
      "/manager/campus/list?etablissement=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            campuses: data.datas,
          });
        } else {
          self.setState({ campuses: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ campuses: [] });

        console.log("There was an error listed enseignant.");
      });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <div className={classes.pageTitle} variant="div" component="div">
          {formatMessage({ id: "ListClasseEtablissementView_2" })}
        </div>
        <div className="layout horizontal end-justified" variant="div" component="div">
          <FormControl
            style={{ height: "35px" }}
            variant="outlined"
            className={classes.formControl}
          >
            <Select
              title={formatMessage({
                id: "ListClasseEtablissementView_4"
              })}
              displayEmpty
              classes={{
                selectMenu: classes.selectMenu,
                root: classes.selectRoot
              }}
              value={this.state.categorie}
              onChange={this.handleChangeCategorie}
              input={
                <OutlinedInput
                  labelWidth={this.state.labelWidth}
                  name="Choisir une classe"
                  id="outlined-classe-simple"
                  classes={{ input: classes.outlinedInput }}
                />
              }
            >
              {" "}
              <MenuItem value={"---"}>
                {formatMessage({ id: "ListClasseEtablissementView_3" })}
              </MenuItem>
              {this.state.categories.map((row, index) => (
                <MenuItem title={row.description} key={index} value={row}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{ height: "35px" }}
            variant="outlined"
            className={classes.formControl}
          >
            <Select
              title={formatMessage({
                id: "ListClasseEtablissementView_6"
              })}
              displayEmpty
              classes={{
                selectMenu: classes.selectMenu,
                root: classes.selectRoot
              }}
              value={this.state.campus}
              onChange={this.handleChangeCampus}
              input={
                <OutlinedInput
                  labelWidth={this.state.labelWidth}
                  name="Choisir une classe"
                  id="outlined-classe-simple"
                  classes={{ input: classes.outlinedInput }}
                />
              }
            >
              {" "}
              <MenuItem value={"---"}>
                {formatMessage({ id: "ListClasseEtablissementView_5" })}
              </MenuItem>
              {this.state.campuses.map((row, index) => (
                <MenuItem title={row.address} key={index} value={row}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {this.state.progress === 0 && (
          <React.Fragment>
            <Paper elevation={0} className={classes.root}>
              <div>
                {this.state.datas.map((row, index) => (
                  <ItemClasseEtablissement
                    key={index}
                    onUpdateItem={this.onUpdateItem}
                    position={index}
                    row={row}
                    {...deleteClassInProps(this.props)}
                  />
                ))}
              </div>
            </Paper>
            <LdPagination
              onShowPage={this.setCurrentPage}
              pages={this.state.pages}
            />
          </React.Fragment>
        )}

        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 ||
          (this.state.progress === 0 && this.state.datas.length === 0)) && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "ListClasseEtablissementView_1" })}
              </div>
            </div>
          )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              {formatMessage({ id: "ListClasseAnneeView_4" })}
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(ListClasseEtablissementView));
