import Avatar from "@material-ui/core/Avatar";
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import LinearProgress from "@material-ui/core/LinearProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import moment from 'moment';
import 'moment/locale/fr';
import PropTypes from "prop-types";
import React, { Component } from "react";
import DateTimePicker from 'react-datetime-picker';
import { FormattedDate, FormattedTime, injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { appProperties, userProfileUrl, deleteClassInProps } from "../../../components/LdUtils";
import LdSelect from "../../../components/LdSelect";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import printJS from 'print-js'
import { withSnackbar } from "notistack";

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1';




const styles = theme => ({
  root: {
    // display: "flex",
    // justifyContent : "flex-start",
    // alignItems: "center",
    // paddingBottom: '10px',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    width: "60px !important",
    height: "60px !important",
    borderRadius: '0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 10px'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    // marginTop:"15px"

  },
  grid: {
    borderTop: '1px solid #EEEEEE',
    padding: '5px 5px 5px 0',
    // borderBottom:'1px solid #EEEEEE'
  },

  main: {
    margin: '10px',
    border: '1px solid #EAEAEA',
    padding: '10px'
  },
  paymentAvatar: {
    margin: 5,
    color: '#fff',
    backgroundColor: 'var(--paper-amber-a700)',
  },
  reductionAvatar: {
    margin: 5,
    color: '#fff',
    backgroundColor: 'var(--paper-lime-700)',
  },
  listItemRoot: {
    padding: '0 !important'
  }
});



const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
    <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));

class ItemDossierTypeFrais extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    const date = new Date();

    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      showForm: false,
      position: this.props.position,
      controlled: true,
      amount: 0,
      resultCode: 0,
      paymentDate: date,
      openConfirm: false,
      progressDelete: false,
      payment: null,

      showFormReduction: false,
      controlledReduction: true,
      inProgressReduction: false,
      resultCodeReduction: 0,
      typeReduction: null,
      percent: 0,
      value: 0,
      errorReductions: {},
      openConfirmReduction: false,
      progressDeleteReduction: false,
      reduction: null

    };


    this.resetForm = this.resetForm.bind(this);
    this.doShowForm = this.doShowForm.bind(this);
    this.doAddPayment = this.doAddPayment.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleClickDeletePaiement = this.handleClickDeletePaiement.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePrint = this.handlePrint.bind(this);

    this.doShowFormReduction = this.doShowFormReduction.bind(this);
    this.resetFormReduction = this.resetFormReduction.bind(this);
    this.doAddReduction = this.doAddReduction.bind(this);
    this.handleTypeReductionChange = this.handleTypeReductionChange.bind(this);
    this.handleClickReductionOpen = this.handleClickReductionOpen.bind(this);
    this.handleClickDeleteReduction = this.handleClickDeleteReduction.bind(this);

  }

  handleTypeReductionChange(type) {
    this.setState({
      typeReduction: type
    });
  }

  doShowFormReduction = () => {
    this.setState({ showFormReduction: true, showForm: false });
  }
  resetFormReduction = () => {
    this.setState({ showFormReduction: false, controlledReduction: true });
  }

  handleClickReductionOpen = reduction => {
    this.setState({ openConfirmReduction: true, reduction: reduction });
  };

  handleCloseReduction = () => {
    this.setState({ openConfirmReduction: false, reduction: null });
  };


  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  doAddReduction = () => {
    this.setState({ resultCodeReduction: 1 });
    var formData = new FormData(); // Currently empty
    if (this.state.typeReduction == null) {
      this.setState({ controlledReduction: false });
      return;
    } else {
      formData.append("type_reduction", this.state.typeReduction.id);
    }
    if (this.state.typeReduction.option.mode === 0) {
      if (parseFloat(this.state.value) < 0) {
        this.setState({ controlledReduction: false });
        return;
      } else {
        formData.append("value", parseFloat(this.state.value));
      }
    }

    if (this.state.typeReduction.option.mode === 1) {
      if (parseFloat(this.state.percent) < 0) {
        this.setState({ controlledReduction: false });
        return;
      } else {
        formData.append("percent", parseFloat(this.state.percent));
      }
    }
    if (this.state.motif == null || this.state.motif === "") {
      this.setState({ controlledReduction: false });
      return;
    } else {
      formData.append("motif", this.state.motif);
    }
    this.setState({ controlledReduction: true });
    formData.append("eleve", this.props.eleve.id);
    let self = this;
    this.setState({ inProgressReduction: true, resultCodeReduction: 0 });
    fetch(appProperties.basepathServer + "/manager/scolarite/add-reduction-frais", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.props.onAddReduction(data.row);

        } else {
          this.setState({ inProgressReduction: false, resultCodeReduction: data.result_code });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressReduction: false });
        alert("There was an error added.");
      });
  }
  handleClickDeleteReduction = () => {
    var self = this;
    this.setState({ progressDeleteReduction: true });
    var formData = new FormData(); // Currently empty
    formData.append("id", this.state.reduction.id);

    fetch(
      appProperties.basepathServer +
      "/manager/scolarite/delete-reduction",
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.props.onRemoveReduction(self.state.reduction);
          self.setState({ progressDeleteReduction: false });
          self.handleCloseReduction();
          //self.props.onUpdateItem("delete", { position: self.state.position });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ progressDeleteReduction: false });
        alert("There was an error added.");
      });

  };













  handleStartDateChange(date) {
    this.setState({
      paymentDate: date,
    });
  }
  doShowForm = () => {
    this.setState({ showForm: true, showFormReduction: false });
  }

  resetForm = () => {
    this.setState({ showForm: false, controlled: true });
  }

  handleChangeAmount(event) {
    this.setState({ amount: event.target.value });
  }

  handleClickOpen = payment => {
    this.setState({ openConfirm: true, payment: payment });
  };

  handleClose = () => {
    this.setState({ openConfirm: false, payment: null });
  };
  handleClickDeletePaiement = () => {
    var self = this;
    this.setState({ progressDelete: true });
    fetch(
      appProperties.basepathServer +
      "/manager/scolarite/delete-paiement?id=" + this.state.payment.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.props.onRemovePayment(self.state.paiement);
          self.setState({ progressDelete: false });
          self.handleClose();
          //self.props.onUpdateItem("delete", { position: self.state.position });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ progressDelete: false });
        alert("There was an error added.");
      });
  };

  doAddPayment = () => {
    var amount = parseInt(this.state.amount);
    if (amount <= 0 || this.state.paymentDate == null) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("typefrais", this.props.typeFrais.id);
    formData.append("date", moment(this.state.paymentDate).locale('fr').format('YYYY-MM-DD HH:mm:ss'));
    formData.append("eleve", this.props.eleve.id);
    formData.append("amount", amount);
    let self = this;
    this.setState({ inProgress: true, resultCode: 0 });
    fetch(appProperties.basepathServer + "/manager/scolarite/add-paiement", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.props.onAddPayment(data.row);

        } else {
          this.setState({ inProgress: false, resultCode: data.result_code });
        }


      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }



  handlePrint = url => {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    // var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        var pdfUrl = URL.createObjectURL(blob);
        self.props.closeSnackbar(key);
        self.printFile(pdfUrl);
      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }
  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      persist: true,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }


  render() {
    const { classes, typeFrais, eleve } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>

        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formatMessage({ id: "ItemDossierTypeFrais_17" }) + (this.state.payment != null ? this.state.payment.amount : 0 || 0) + " F CFA ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemEleve_1" })}
            </DialogContentText>
            <div style={{ padding: '10px 5px' }}>
              {this.state.progressDelete
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none" }} onClick={this.handleClose} color="primary">
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            {!this.props.readOnly && <Button style={{ textTransform: "none" }} onClick={this.handleClickDeletePaiement} color="primary" autoFocus>
              {formatMessage({ id: "ItemEleve_3" })}
            </Button>}
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openConfirmReduction}
          onClose={this.handleCloseReduction}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formatMessage({ id: "ItemDossierTypeFrais_19" }, { value: (this.state.reduction != null ? this.state.reduction.amount : 0 || 0) })}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemDossierTypeFrais_20" })}
            </DialogContentText>
            <div style={{ padding: '10px 5px' }}>
              {this.state.progressDeleteReduction
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none" }} onClick={this.handleCloseReduction} color="primary">
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            {!this.props.readOnly && <Button style={{ textTransform: "none" }} onClick={this.handleClickDeleteReduction} color="primary" autoFocus>
              {formatMessage({ id: "ItemEleve_3" })}
            </Button>}
          </DialogActions>
        </Dialog>







        <div className={classes.root}>
          <Grid container className={classes.grid} justify="space-between" wrap="nowrap" alignItems='center'>
            <div>
              <div className='layout horizontal center' style={{ paddingLeft: "10px" }}>
                <span style={{ color: 'var(--app-primary-color)' }} className={classes.name}>{typeFrais.name}</span>
                <div style={{ paddingLeft: '5px' }} className={classes.date}>{formatMessage({ id: "ItemClasseAnneeScol_1" })}<b><i>{typeFrais.delay && typeFrais.delay != null ? <PostDate date={typeFrais.delay} /> : null}</i></b> </div>
              </div>
              <div style={{ fontSize: "13px", paddingLeft: "10px" }}>
                <span>{formatMessage({ id: "ItemDossierTypeFrais_16" })}<b style={{ paddingLeft: '5px' }}>{typeFrais.amount} F CFA</b></span>
                <span style={{ paddingLeft: '5px' }}>{formatMessage({ id: "ItemDossierTypeFrais_15" })}<b style={{ paddingLeft: '5px' }}>{typeFrais.sumPaid || 0} F CFA</b></span>
                <span style={{ paddingLeft: '5px' }}>{formatMessage({ id: "ItemDossierTypeFrais_36" })}<b style={{ paddingLeft: '5px' }}>{typeFrais.sumReduction || 0} F CFA</b></span>
                <span style={{ paddingLeft: '5px' }}>{formatMessage({ id: "ItemDossierTypeFrais_14" })}<b style={{ paddingLeft: '5px' }}>{parseFloat(typeFrais.amount) - parseFloat(typeFrais.sumPaid || 0) - parseFloat(typeFrais.sumReduction || 0)} F CFA</b></span>
              </div>
            </div>
            <div className={classes.actions}>
              {!this.props.finished && !this.props.readOnly && <IconButton
                title={formatMessage({ id: "ItemDossierTypeFrais_13" })}
                aria-label="versement"
                onClick={this.doShowForm}>
                <AddIcon fontSize="small" />
              </IconButton>}

              {!this.props.finished && !this.props.readOnly && <IconButton
                title="Ajouter une reduction"
                aria-label="reduction"
                onClick={this.doShowFormReduction}>
                <ExposureNeg1Icon fontSize="small" />
              </IconButton>}

            </div>
          </Grid>











          <div style={{ marginLeft: "25px" }}>
            {this.state.showForm && !this.props.readOnly &&
              <main className={classes.main}>
                <div>
                  <span style={{ fontSize: '13px', padding: '2px 5px', backgroundColor: 'var(--paper-amber-a700)' }}>{formatMessage({ id: "ItemDossierTypeFrais_28" })}</span>
                </div>
                {this.state.inProgress
                  ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                  : null
                }
                {!this.state.controlled
                  ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
                  : null
                }
                {(!this.state.inProgress && this.state.resultCode === -5) &&
                  <div className={classes.error}></div>
                }
                {(!this.state.inProgress && this.state.resultCode === -6) &&
                  <div className={classes.error}>{formatMessage({ id: "ItemDossierTypeFrais_11" })}</div>
                }
                <div className="layout horizontal">
                  <div style={{ paddingRight: '10px' }} className="flex layout horizontal center">
                    <TextField
                      id="amount"
                      label={formatMessage({ id: "ItemDossierTypeFrais_10" })}
                      style={{ width: '25%', marginTop: '0 !important' }}
                      value={this.state.amount}
                      placeholder="Ex: 22000"
                      onChange={this.handleChangeAmount}
                      margin="normal"
                      type="number"
                      min="0"
                    />
                    <span className={classes.label}>
                      {formatMessage({ id: "ItemDossierTypeFrais_9" })}
                    </span>
                    <DateTimePicker
                      locale='fr-FR'
                      className={classes.datePicker}
                      value={this.state.paymentDate}
                      onChange={this.handleStartDateChange}
                    />
                  </div>
                  <div className="layout horizontal center wrap">
                    <Button
                      onClick={this.doAddPayment}
                      variant="contained"
                      size="medium"
                      style={{ margin: '5px' }}
                      title={formatMessage({ id: "ItemDossierTypeFrais_8" })}
                      className={classes.buttonPrincipale}>
                      {formatMessage({ id: "ItemDossierTypeFrais_7" })}
                    </Button>
                    <Button
                      onClick={this.resetForm}
                      variant="contained"
                      size="medium"
                      style={{ margin: '5px' }}
                      className={classes.buttonGreyWhite}>
                      {formatMessage({ id: "AjouterIndisciplineView_10" })}
                    </Button>
                  </div>
                </div>
              </main>}








            {this.state.showFormReduction && !this.props.readOnly &&
              <main className={classes.main}>
                <div>
                  <span style={{ fontSize: '13px', padding: '2px 5px', backgroundColor: 'var(--paper-lime-700)' }}>{formatMessage({ id: "ItemDossierTypeFrais_27" })}</span>
                </div>
                {this.state.inProgressReduction
                  ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                  : null
                }
                {!this.state.controlledReduction
                  ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
                  : null
                }
                {(!this.state.inProgressReduction && this.state.resultCodeReduction === -1) &&
                  <div className={classes.error}>{formatMessage({ id: "ItemDossierTypeFrais_21" })}</div>
                }
                {(!this.state.inProgressReduction && this.state.resultCodeReduction === -2) &&
                  <div className={classes.error}>{formatMessage({ id: "ItemDossierTypeFrais_22" })}</div>
                }
                {(!this.state.inProgressReduction && this.state.resultCodeReduction === -3) &&
                  <div className={classes.error}>{formatMessage({ id: "ItemDossierTypeFrais_23" })}</div>
                }
                {(!this.state.inProgressReduction && this.state.resultCodeReduction === -4) &&
                  <div className={classes.error}>{formatMessage({ id: "ItemDossierTypeFrais_24" })}</div>
                }
                {(!this.state.inProgressReduction && this.state.resultCodeReduction === -5) &&
                  <div className={classes.error}>{formatMessage({ id: "ItemDossierTypeFrais_25" })}</div>
                }
                {(!this.state.inProgressReduction && this.state.resultCodeReduction === -6) &&
                  <div className={classes.error}>{formatMessage({ id: "ItemDossierTypeFrais_26" })}</div>
                }
                <div className="layout horizontal">
                  <div className="flex" style={{ paddingRight: '10px' }}>

                    <div className="layout horizontal center  justified">
                      <div style={{ width: "45%" }}>
                        <LdSelect
                          label={formatMessage({ id: "ItemDossierTypeFrais_29" })}
                          handleValue={this.handleTypeReductionChange}
                          firstLoad
                          isSearchable
                          baseUrl={
                            appProperties.basepathServer +
                            "/manager/type-reduction/list?etablissement=" + eleve.etablissementId +
                            "&annee=" + eleve.anneeScolaireEtablissement.id +
                            "&type_id=" + typeFrais.id +
                            "&limit=-1&published=1"
                          }
                          {...deleteClassInProps(this.props)}
                        />
                      </div>
                      {this.state.typeReduction != null && (
                        <React.Fragment>
                          {this.state.typeReduction.option.mode === 1 && (
                            <TextField
                              id="percent"
                              label={formatMessage({ id: "ItemDossierTypeFrais_30" }, { value: this.state.typeReduction.option.maxPercent })}
                              style={{ width: '45%', marginTop: '0 !important' }}
                              value={this.state.percent}
                              placeholder="EX: 45"
                              onChange={this.handleChangeInput('percent')}
                              margin="normal"
                              type="number"
                              InputProps={{
                                inputProps: { min: 0, max: this.state.typeReduction.option.maxPercent },

                              }}
                            />
                          )}
                          {this.state.typeReduction.option.mode === 0 && (
                            <TextField
                              id="value"
                              label={formatMessage({ id: "ItemDossierTypeFrais_31" }, { value: this.state.typeReduction.option.maxValue })}
                              style={{ width: '45%', marginTop: '0 !important' }}
                              value={this.state.value}
                              placeholder="Ex: 25000"
                              onChange={this.handleChangeInput("value")}
                              margin="normal"
                              type="number"
                              InputProps={{
                                inputProps: { min: 0, max: this.state.typeReduction.option.maxValue },

                              }}
                            />
                          )}
                        </React.Fragment>
                      )}

                    </div>

                    <TextField
                      id="motif"
                      value={this.state.motif}
                      label={formatMessage({ id: "ItemDossierTypeFrais_33" })}
                      placeholder={formatMessage({ id: "ItemDossierTypeFrais_32" })}
                      onChange={this.handleChangeInput('motif')}
                      margin="normal"
                      multiline
                      rows="2"
                      fullWidth
                    />

                  </div>
                  <div className="layout vertical end-justified">
                    <Button
                      onClick={this.doAddReduction}
                      variant="contained"
                      size="medium"
                      style={{ margin: '5px' }}
                      disabled={this.state.inProgressReduction}
                      title={formatMessage({ id: "ItemDossierTypeFrais_34" })}
                      className={classes.buttonPrincipale}>
                      {formatMessage({ id: "ItemDossierTypeFrais_7" })}
                    </Button>
                    <Button
                      onClick={this.resetFormReduction}
                      variant="contained"
                      size="medium"
                      style={{ margin: '5px' }}
                      className={classes.buttonGreyWhite}>

                      {formatMessage({ id: "AjouterIndisciplineView_10" })}
                    </Button>
                  </div>
                </div>
              </main>}



            {((typeFrais.reductions && typeFrais.reductions.length > 0) &&
              <div>
                <div style={{ color: 'var(--paper-lime-700)', marginTop: '10px', padding: '5px', borderBottom: '1px solid #EAEAEA', fontSize: '14px', fontWeight: '500' }}>Les réductions accordé à cet élève pour ce frais</div>
                <List style={{ padding: '0 !important' }} className={classes.root}>
                  {typeFrais.reductions.map((reduction, index) => (
                    <ListItem classes={{ root: classes.listItemRoot }} key={index}>
                      <Avatar className={classes.reductionAvatar}>
                        R{index + 1}
                      </Avatar>
                      <ListItemText primary={
                        <div className="layout vertical">
                          <div style={{ fontSize: '13px', fontWeight: '500' }}>{formatMessage({ id: "ItemDossierTypeFrais_36" })}: <span title={reduction.typeReduction.description}>{reduction.typeReduction.name}</span> -&nbsp;
                          {reduction.typeReduction.mode === 1 && <span> {formatMessage({ id: "ItemDossierTypeFrais_37" })}
                              <span style={{ fontSize: '13px', fontWeight: '600' }}>: {reduction.percent}% </span>
                            </span>
                            }
                            <span style={{ fontSize: '13px', fontWeight: '600' }}> {formatMessage({ id: "ItemDossierTypeFrais_38" }, { value: reduction.amount })}</span>
                          </div>
                          <div style={{ fontSize: '12px', fontWeight: '500' }}>
                            {formatMessage({ id: "ItemDossierTypeFrais_33" })}: <span>{reduction.motif}</span>
                          </div>

                        </div>
                      }
                        secondary={<span style={{ fontSize: '12px', fontWeight: '400' }}>{formatMessage({ id: "ItemAlert_msg13" })} <PostDate date={reduction.created} />
                          {reduction.creator != null && (<span>
                            &nbsp; {formatMessage({ id: "ItemAlert_msg14" })}
                            <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={reduction.creator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(reduction.creator)}>
                              {reduction.creator.name}
                            </a>
                          </span>)}

                        </span>} />
                      {/* <Button onClick={() => this.handlePrint(appProperties.basepathServer + '/manager/scolarite/print-paiement?id=' + payment.id)} title={formatMessage({ id: "ItemDossierTypeFrais_4" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginRight: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
                      {formatMessage({ id: "ItemDossierTypeFrais_3" })}
                    </Button> */}

                      {/* <Button component="a" download target="_blank" href={appProperties.basepathServer + '/manager/scolarite/print-paiement?id=' + payment.id} title={formatMessage({ id: "ItemDossierTypeFrais_4" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginRight: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
                      {formatMessage({ id: "ItemDossierTypeFrais_3" })}
                    </Button> */}
                      {(!this.props.readOnly) && <Button onClick={() => this.handleClickReductionOpen(reduction)} title={formatMessage({ id: "ItemDossierTypeFrais_2" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginRight: '10px' }} variant="outlined" size="small" className={classes.margin}>
                        {formatMessage({ id: "ItemDossierTypeFrais_35" })}
                      </Button>}
                    </ListItem>
                  ))}
                </List>
              </div>
            )}


            {((typeFrais.paiements && typeFrais.paiements.length > 0) &&
              <div>
                <div style={{ color: 'var(--paper-amber-a700)', marginTop: '10px', padding: '5px', borderBottom: '1px solid #EAEAEA', fontSize: '14px', fontWeight: '500' }}>Les paiements de l'élève pour ce frais</div>

                <List style={{ padding: '0 !important' }} className={classes.root}>
                  {typeFrais.paiements.map((payment, index) => (
                    <ListItem classes={{ root: classes.listItemRoot }} key={index}>
                      <Avatar className={classes.paymentAvatar}>
                        P{index + 1}
                      </Avatar>
                      <ListItemText primary={<span style={{ fontSize: '15px', fontWeight: '500' }}>{formatMessage({ id: "ItemDossierTypeFrais_6" })}
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>{payment.amount}</span> F CFA</span>}
                        secondary={<span style={{ fontSize: '12px', fontWeight: '400' }}>

                          <FormattedMessage id="ItemDossierTypeFrais_39" values={{ date: <PostDate date={payment.date} />, created: <PostDate date={payment.created} /> }} />
                          {payment.creator != null && (<span>
                           
                            <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={payment.creator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(payment.creator)}>
                              {payment.creator.name}
                            </a>
                          </span>)}

                        </span>} />
                      <Button onClick={() => this.handlePrint(appProperties.basepathServer + '/manager/scolarite/print-paiement?id=' + payment.id)} title={formatMessage({ id: "ItemDossierTypeFrais_4" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginRight: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
                        {formatMessage({ id: "ItemDossierTypeFrais_3" })}
                      </Button>

                      {/* <Button component="a" download target="_blank" href={appProperties.basepathServer + '/manager/scolarite/print-paiement?id=' + payment.id} title={formatMessage({ id: "ItemDossierTypeFrais_4" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginRight: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
                      {formatMessage({ id: "ItemDossierTypeFrais_3" })}
                    </Button> */}
                      {(!this.props.readOnly) && (payment.versementId == null || payment.versementId === "") && <Button onClick={() => this.handleClickOpen(payment)} title={formatMessage({ id: "ItemDossierTypeFrais_2" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginRight: '10px' }} variant="outlined" size="small" className={classes.margin}>
                        {formatMessage({ id: "ItemDossierTypeFrais_1" })}
                      </Button>}
                    </ListItem>
                  ))}
                </List>
              </div>
            )}


          </div>

        </div>
      </React.Fragment>
    );
  }
}
ItemDossierTypeFrais.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  typeFrais: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemDossierTypeFrais)));
