import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EditIcon from "@material-ui/icons/Edit";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, injectIntl, intlShape } from 'react-intl';
import ProgressiveImage from 'react-progressive-image';
import { isObjectValueEquals, deleteClassInProps, appProperties, copyClipboard, defaultClassImage } from "../../../components/LdUtils";
import LdSelect from "../../../components/LdSelect";
import { Link } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';






const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  name2: {
    fontSize: "13px",
    fontWeight: '500',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    width: "100px !important",
    height: "100px !important",
    borderRadius: '0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },
  mainEdit: {
    padding: "10px",
    border: "1px dashed #EAEAEA",
    width: '100%',
  }
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
  </span>
));

class ItemClasseEtablissement extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    var self = this;
    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,
      name: this.props.row.name,
      nameEn: this.props.row.nameEn,
      codeMatricule: this.props.row.codeMatricule || '',


      controlled: true,
      copy: false,
      showGroup: false,
      groups: [],
      progress: 1,
      typeGroupeId: "",
      ordre: 1,
      code: '',
      inProgressGroup: false,
      controlledGroup: true,
      controlledEdit: true,

      modeEditGroup: false,
      group: null,
      ordreEdit: 1,
      codeEdit: '',
      positionEdit: 0,
      inProgressEditGroup: false,
      controlledEditGroup: true,


      classe: self.props.row.classe != null ? Object.assign({
        id: self.props.row.classe.id,
        value: self.props.row.classe.id,
        label: self.props.row.classe.name,
        option: self.props.row.classe

      }, self.props.row.classe) : null,

      campus: self.props.row.campus != null ? Object.assign({
        id: self.props.row.campus.id,
        value: self.props.row.campus.id,
        label: self.props.row.campus.name,
        option: self.props.row.campus

      }, self.props.row.campus) : null,

      categorieEtablissement: self.props.row.categorieEtablissement != null ? Object.assign({
        id: self.props.row.categorieEtablissement.id,
        value: self.props.row.categorieEtablissement.id,
        label: self.props.row.categorieEtablissement.name,
        option: self.props.row.categorieEtablissement

      }, self.props.row.categorieEtablissement) : null,

      errors: {},


      openConfirm: false,
      progressDelete: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleShowGroup = this.handleShowGroup.bind(this);
    this.handleChangeOrdre = this.handleChangeOrdre.bind(this);
    this.handleChangeTypeGroup = this.handleChangeTypeGroup.bind(this);
    this.addGroup = this.addGroup.bind(this);
    //this.handleShowEditGroup = this.handleShowEditGroup.bind(this);
    this.handleCloseEditGroup = this.handleCloseEditGroup.bind(this);
    this.handleChangeOrdreEdit = this.handleChangeOrdreEdit.bind(this);
    this.doEditGroup = this.doEditGroup.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    //this.handleShowDeleteGroup = this.handleShowDeleteGroup.bind(this);



    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleCampus = this.handleCampus.bind(this);
    this.handleCategorieEtablissement = this.handleCategorieEtablissement.bind(this);

    this.handleClickDelete = this.handleClickDelete.bind(this);


  }

  handleChangeClass(item) {
    this.setState({ classe: item });
  };

  handleCampus(item) {
    this.setState({ campus: item });
  }
  handleCategorieEtablissement(item) {
    this.setState({ categorieEtablissement: item });
  }

  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          name: self.props.row.name,
          nameEn: self.props.row.nameEn,
          codeMatricule: self.props.row.codeMatricule,


          classe: self.props.row.classe != null ? Object.assign({
            id: self.props.row.classe.id,
            value: self.props.row.classe.id,
            label: self.props.row.classe.name,
            option: self.props.row.classe

          }, self.props.row.classe) : null,

          campus: self.props.row.campus != null ? Object.assign({
            id: self.props.row.campus.id,
            value: self.props.row.campus.id,
            label: self.props.row.campus.name,
            option: self.props.row.campus

          }, self.props.row.campus) : null,

          categorieEtablissement: self.props.row.categorieEtablissement != null ? Object.assign({
            id: self.props.row.categorieEtablissement.id,
            value: self.props.row.categorieEtablissement.id,
            label: self.props.row.categorieEtablissement.name,
            option: self.props.row.categorieEtablissement

          }, self.props.row.categorieEtablissement) : null,
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {

      });
    }
  }

  handleChangeInput = name => e => {
    var val = {};

    val[name] = e.target.value;
    this.setState(val);
  };


  handleDelete = () => {
    this.setState({ openConfirm: true });
  };

  handleClose = () => {
    this.setState({ openConfirm: false });
  };
  handleChangeTypeGroup(item) {
    this.setState({ typeGroupeId: item != null ? item.id : '' });
  };
  handleShowGroup = () => {
    if (!this.state.showGroup) this.listGroupe(1);
    this.setState({ showGroup: !this.state.showGroup });
  }
  handleEdit = () => {
    this.setState({ modeEdit: true });
  }
  handleShowEditGroup(group, position) {
    this.setState({ modeEditGroup: true, group: group, positionEdit: position, codeEdit: group.code, ordreEdit: group.ordre });
  }
  handleCloseEditGroup = event => {
    this.setState({ modeEditGroup: false, group: null, ordreEdit: 0 });
  }
  handleCopy = () => {
    this.setState({ copy: true });
    copyClipboard(this.props.row.id);
  }
  reset = () => {
    this.setState({ modeEdit: false, controlled: true });
  }

  handleChangeOrdre(event) {
    var value = event.target.value;

    if (parseInt(value) < 0) {
      value = 0;
      event.target.value = value;
    }
    if (event.target.value === "") value = 0;
    this.setState({ ordre: parseInt(value) });
  }

  handleChangeOrdreEdit(event) {
    var value = event.target.value;

    if (parseInt(value) < 0) {
      value = 0;
      event.target.value = value;
    }
    if (event.target.value === "") value = 0;
    this.setState({ ordreEdit: parseInt(value) });
  }
  handleChangeIndex(event) {
    this.setState({ name: event.target.value });
  }

  handleShowDeleteGroup(groupe, index) {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/groupe-enseignement/delete?id=" + groupe.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.onUpdateItem("delete", { position: index });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }
  edit = () => {
    var errors = {};
    this.setState({ errors: errors });

    if (this.state.name == null || this.state.name === "") errors["name"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.classe == null || this.state.classe === "") errors["classe"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.campus == null) errors["campus"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.categorieEtablissement == null) errors["categorieEtablissement"] = this.props.intl.formatMessage({ id: "InputError_1" });


    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    // if (this.state.name == null || this.state.name.trim() === "") {
    //   this.setState({ controlled: false });
    //   return;
    // } else {
    //   this.setState({ controlled: true });
    // }
    var formData = new FormData(); // Currently empty
    formData.append("name", this.state.name);
    formData.append("name_en", this.state.nameEn);
    formData.append("code_matricule", this.state.codeMatricule);

    formData.append("idClasse", this.state.classe ? this.state.classe.id : '');

    formData.append("campus", this.state.campus.id);
    formData.append("categorie_etablissement", this.state.categorieEtablissement.id);

    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/rest-etablissement/edit-classe?id=" + this.props.row.id, {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.reset();
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItem('update', datas);

        }
        this.setState({ inProgress: false });

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }
  handleClickDelete = () => {
    var self = this;
    this.setState({ progressDelete: true });

    fetch(
      appProperties.basepathServer +
      "/rest-etablissement/delete-classe?id=" + this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ progressDelete: false, openConfirm: false });

        if (data.result_code === 1) {
          self.props.onUpdateItem("delete", { position: self.state.position });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ progressDelete: false });
        alert("There was an error added.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/rest-etablissement/set-status-classe?status=1&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", { position: self.state.position, row: this.props.row });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer + "/rest-etablissement/set-status-classe?status=0&id=" + this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    ).then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });  
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", { position: self.state.position, row: this.props.row });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  componentDidMount() {
    this.listGroupe(1);
  }
  listGroupe(page) {

    let self = this;

    this.setState({ groups: [], progress: 1 });

    // fetch(appProperties.basepathServer+ '/manager/modele-bulletin/list?limit=5&page='+page, {
    fetch(appProperties.basepathServer + '/manager/groupe-enseignement/list?classe=' + this.props.row.id + '&limit=-1&only_published=1&page=' + page, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ progress: 0, groups: data.datas });

        } else {
          self.setState({ groups: [], progress: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({groups: []});
        self.setState({ groups: [], progress: 3 });

        console.log('There was an error listed enseignant.');
      });
  }


  addGroup() {
    if (this.state.ordre <= 0 || this.state.typeGroupeId === "" || this.state.typeGroupeId.trim() === "") {
      this.setState({ controlledGroup: false });
      return;
    } else {
      this.setState({ controlledGroup: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("ordre", this.state.ordre);
    formData.append("code", this.state.code);
    formData.append("type", this.state.typeGroupeId);
    formData.append("classe", this.props.row.id);
    let self = this;
    this.setState({ inProgressGroup: true });
    fetch(appProperties.basepathServer + "/manager/groupe-enseignement/add", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgressGroup: false });
        if (data.result_code === 1) {

          self.setState(state => {
            state.groups = [data.row, ...state.groups];
            return state;
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressGroup: false });
        alert("There was an error added.");
      });
  }

  onUpdateItem = (mode, item) => {
    console.log("intercept", mode);
    console.log(item);
    var array = [...this.state.groups];
    if (mode === 'update') {
      array.splice(item.position, 1, item.row);
    } else if (mode === 'delete') {
      array.splice(item.position, 1);

    } else if (mode === 'enabled') {
      item.row.status = true;
      console.log('enabled', item.row.status);
      array.splice(item.position, 1, item.row);

    } else if (mode === 'disabled') {
      item.row.status = false;
      array.splice(item.position, 1, item.row);

    }

    this.setState(state => {
      state.groups = array;
      return state;
    });
  };
  doEditGroup = () => {
    if (this.state.ordreEdit <= 0 || this.state.group == null) {
      this.setState({ controlledEditGroup: false });
      return;
    } else {
      this.setState({ controlledEditGroup: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("id", this.state.group.id);
    formData.append("ordre", this.state.ordreEdit);
    formData.append("code", this.state.codeEdit);
    let self = this;
    this.setState({ inProgressEditGroup: true });
    fetch(appProperties.basepathServer + "/manager/groupe-enseignement/edit", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.reset();
          let datas = { position: self.state.positionEdit, row: data.row };
          self.onUpdateItem('update', datas);

        }
        self.handleCloseEditGroup();
        self.setState({ inProgressEditGroup: false });

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressEditGroup: false });
        alert("There was an error added.");
      });
  }

  computeUrlClasse(categorieEtablissementent) {
    if (categorieEtablissementent == null) return;
    return appProperties.basepathServer + "/rest-classe/list-by-categorie?limit=-1&categorie_id=" + categorieEtablissementent.id;
  }
  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>


        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formatMessage({ id: "ItemClasseEtablissement_20" }, { periode: this.props.row != null ? this.props.row.name : '' })}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemTrimestre_8" })}
            </DialogContentText>
            <div style={{ padding: '10px 5px' }}>
              {this.state.progressDelete
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none" }} onClick={this.handleClose} color="primary">
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button style={{ textTransform: "none" }} onClick={this.handleClickDelete} color="primary" autoFocus>
              {formatMessage({ id: "ItemEleve_3" })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.modeEditGroup} onClose={this.handleCloseEditGroup} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{formatMessage({ id: "ItemClasseEtablissement_1" })}<br /> #{this.state.group != null && this.state.group.type != null ? this.state.group.type.name : ''}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {formatMessage({ id: "ItemClasseEtablissement_2" })}
            </DialogContentText>
            {this.state.inProgressEditGroup
              ? <div className={classes.progress}><LinearProgress variant="query" /></div>
              : null
            }
            {!this.state.controlledEditGroup
              ? <div className={classes.error}>{formatMessage({ id: "ItemAnneeScolaire_Check" })}</div>
              : null
            }

            <TextField
              id="code"
              autoFocus
              label={formatMessage({ id: "ItemClasseEtablissement_3" })}
              value={this.state.codeEdit}
              placeholder="Ex: A"
              onChange={this.handleChangeInput('codeEdit')}
              margin="dense"
              type="text"
              fullWidth
            />

            <TextField
              id="ordre"
              autoFocus
              label={formatMessage({ id: "ItemClasseEtablissement_4" })}
              value={this.state.ordreEdit}
              placeholder="Ex: 1"
              onChange={this.handleChangeOrdreEdit}
              margin="dense"
              type="number"
              fullWidth
              InputProps={{
                inputProps: { min: 0 },

              }}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseEditGroup} color="primary">
              {formatMessage({ id: "AutorisationEtablissement_Ccl" })}
            </Button>
            <Button onClick={this.doEditGroup} color="primary">
              {formatMessage({ id: "AjouterTypeAlertView_15" })}
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>

              {this.state.modeEdit ?
                <main className={classes.mainEdit}>
                  {this.state.inProgress
                    ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                    : null
                  }
                  {!this.state.controlled
                    ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
                    : null
                  }
                  <div className="layout horizontal">
                    <div>

                      <Grid container className={classes.grid} justify="flex-start" alignItems='center'>

                        <div style={{ width: '32%' }}>
                          <LdSelect
                            label={formatMessage({ id: "AjouterClasseEtablissementView_18" })}
                            handleValue={this.handleCategorieEtablissement}
                            firstLoad isSearchable

                            defaultValue={{
                              id: this.state.categorieEtablissement && this.state.categorieEtablissement != null ? this.state.categorieEtablissement.id : '',
                              value: this.state.categorieEtablissement && this.state.categorieEtablissement != null ? this.state.categorieEtablissement.id : '',
                              label: this.state.categorieEtablissement && this.state.categorieEtablissement != null ? this.state.categorieEtablissement.name : '',
                              option: this.state.categorieEtablissement
                            }}
                            baseUrl={appProperties.basepathServer + "/rest-categorie-etablissement/list?etablissement_id=" + this.props.row.etablissementId + "&limit=-1&only_published=1"}  {...deleteClassInProps(this.props)} />
                        </div>
                        <div style={{ paddingLeft: '1%', width: '32%' }}>

                          <LdSelect
                            label={formatMessage({ id: "AjouterClasseEtablissementView_6" })}
                            handleValue={this.handleChangeClass}
                            firstLoad isSearchable
                            defaultValue={{
                              id: this.state.classe && this.state.classe != null ? this.state.classe.id : '',
                              value: this.state.classe && this.state.classe != null ? this.state.classe.id : '',
                              label: this.state.classe && this.state.classe != null ? this.state.classe.name : '',
                              option: this.state.classe
                            }}
                            baseUrl={this.computeUrlClasse(this.state.categorieEtablissement)}  {...deleteClassInProps(this.props)} />
                        </div>
                        <div style={{ paddingLeft: '1%', width: '32%' }}>
                          <LdSelect
                            label={formatMessage({ id: "AjouterClasseEtablissementView_19" })}
                            handleValue={this.handleCampus}
                            firstLoad isSearchable
                            defaultValue={{
                              id: this.state.campus && this.state.campus != null ? this.state.campus.id : '',
                              value: this.state.campus && this.state.campus != null ? this.state.campus.id : '',
                              label: this.state.campus && this.state.campus != null ? this.state.campus.name : '',
                              option: this.state.campus
                            }}
                            baseUrl={appProperties.basepathServer + "/manager/campus/list?etablissement=" + this.props.row.etablissementId + "&limit=-1&only_published=1"}  {...deleteClassInProps(this.props)} />
                        </div>
                      </Grid>






                      <div style={{ paddingRight: '10px' }} className="flex layout horizontal" >

                        <TextField
                          id="name"
                          label={formatMessage({ id: "AjouterClasseEtablissementView_4" })}
                          style={{ width: '30%', marginTop: '0 !important' }}
                          value={this.state.name}
                          placeholder={formatMessage({ id: "AjouterClasseEtablissementView_10" })}
                          onChange={this.handleChangeInput('name')}
                          margin="normal"
                        />
                        <TextField
                          id="nameEn"
                          label={formatMessage({ id: "AjouterClasseEtablissementView_9" })}
                          style={{ width: '30%', marginTop: '0 !important', marginLeft: '10px' }}
                          value={this.state.nameEn}
                          placeholder={formatMessage({ id: "AjouterClasseEtablissementView_8" })}
                          onChange={this.handleChangeInput('nameEn')}
                          margin="normal"
                        />

                        <TextField
                          id="codeMatricule"
                          label={formatMessage({ id: "AjouterClasseEtablissementView_11" })}
                          helperText={formatMessage({ id: "AjouterClasseEtablissementView_12" })}

                          style={{ width: '30%', marginTop: '0 !important', marginLeft: '10px' }}
                          value={this.state.codeMatricule}
                          placeholder=""
                          onChange={this.handleChangeInput('codeMatricule')}
                          margin="normal"
                        />
                      </div>
                    </div>
                    <div className="layout vertical">
                      <Button
                        onClick={this.edit}
                        variant="contained"
                        size="medium"
                        style={{ margin: '5px' }}
                        className={classes.buttonPrincipale}>
                        {formatMessage({ id: "ItemAnneeScolaire_Up" })}
                      </Button>
                      <Button
                        onClick={this.reset}
                        variant="contained"
                        size="medium"
                        style={{ margin: '5px' }}
                        className={classes.buttonGreyWhite}>
                        {formatMessage({ id: "ItemAnneeScolaire_Ccl" })}
                      </Button>
                    </div>
                  </div>
                </main>
                :
                <div className="flex">
                  <Grid container className={classes.grid} justify="flex-start" wrap="nowrap" alignItems='center'>
                    <div>
                      <ProgressiveImage placeholder={defaultClassImage}>
                        {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                      </ProgressiveImage>
                    </div>
                    <div style={{ paddingLeft: "10px", width: '340px' }}>
                      <div className='layout horizontal center'>
                        <IconButton
                          title={!this.state.copy ? formatMessage({ id: "ItemClasseEtablissement_5" }) : formatMessage({ id: "ItemClasseEtablissement_6" })}
                          aria-label="copy"
                          style={{ color: !this.state.copy ? 'var(--paper-grey-700)' : 'var(--paper-green-500)' }}
                          onClick={this.handleCopy}>
                          {!this.state.copy ?
                            <FilterNoneIcon fontSize="small" />
                            : <DoneAllIcon fontSize="small" />}
                        </IconButton>
                        <span className={classes.name}>{row.name} </span>
                      </div>
                      <div title={formatMessage({ id: "AjouterClasseEtablissementView_16" })} className={classes.name2}>{formatMessage({ id: "AjouterClasseEtablissementView_15" })}  <span style={{ color: 'var(--app-primary-color)' }}>#{row.classe.name}</span></div>
                      <div title={formatMessage({ id: "ItemClasseEtablissement_18" })} className={classes.name2}>{formatMessage({ id: "ItemClasseEtablissement_18" })}  <b>{row.campus != null ? row.campus.name : "##########"}</b></div>
                      <div title={formatMessage({ id: "ItemClasseEtablissement_19" })} className={classes.name2}>{formatMessage({ id: "ItemClasseEtablissement_19" })}  <b>{row.categorieEtablissement != null ? row.categorieEtablissement.name : "----------"}</b></div>
                      <div className={classes.date}>{formatMessage({ id: "ItemAlert_msg13" })}  <PostDate date={row.created} /></div>
                    </div>
                    <div title={formatMessage({ id: "AjouterClasseEtablissementView_9" })} style={{ fontSize: "15px", width: '150px', paddingLeft: "10px", fontWeight: "600" }}>
                      {row.nameEn !== null && row.nameEn !== "" ? row.nameEn : formatMessage({ id: "ItemClasseEtablissement_7" })}
                    </div>
                    <div title={formatMessage({ id: "AjouterClasseEtablissementView_11" })} style={{ fontSize: "15px", width: '150px', paddingLeft: "10px", color: 'var(--app-primary-color)', fontWeight: "600" }}>
                      {row.codeMatricule !== null && row.codeMatricule !== "" ? row.codeMatricule : formatMessage({ id: "AjouterClasseEtablissementView_13" })}
                    </div>
                  </Grid>
                  <div style={{ margin: '5px 0 0 70px' }}>
                    <div className={classes.date}>
                      {!this.state.showGroup ?
                        <Link onClick={this.handleShowGroup} to="#">{formatMessage({ id: "ItemClasseEtablissement_9" })}</Link>
                        :
                        <Link onClick={this.handleShowGroup} to="#">{formatMessage({ id: "ItemClasseEtablissement_8" })}</Link>
                      }
                    </div>
                    {this.state.showGroup &&
                      <div style={{ border: '1px solid #EAEAEA', padding: '5px', marginTop: '5px' }}>
                        <div style={{ padding: '10px 0' }}>
                          {this.state.inProgressGroup
                            ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                            : null
                          }
                          {!this.state.controlledGroup
                            ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
                            : null
                          }
                          <div className="layout horizontal center">
                            <div style={{ width: '25%', marginright: '5px' }}>
                              <LdSelect
                                label={formatMessage({ id: "ListAlertView_1" })}
                                handleValue={this.handleChangeTypeGroup}
                                firstLoad isSearchable
                                baseUrl={appProperties.basepathServer + "/manager/groupe-enseignement/list-types?only_published=1&limit=-1&etablissement=" + row.etablissementId}  {...deleteClassInProps(this.props)} />
                            </div>
                            <TextField
                              id="code"
                              label={formatMessage({ id: "ItemClasseEtablissement_3" })}
                              style={{ width: '20%', marginTop: '0 !important' }}
                              value={this.state.code}
                              placeholder="Ex: A"
                              onChange={this.handleChangeInput('code')}
                              margin="normal"
                              type="text"
                            />

                            <TextField
                              id="ordre"
                              label={formatMessage({ id: "ItemClasseEtablissement_10" })}
                              style={{ width: '20%', marginTop: '0 !important' }}
                              value={this.state.ordre}
                              placeholder="Ex: 1"
                              onChange={this.handleChangeOrdre}
                              margin="normal"
                              type="number"
                              InputProps={{
                                inputProps: { min: 0 },

                              }}
                            />
                            <Button
                              onClick={this.addGroup}
                              variant="contained"
                              size="medium"
                              style={{ margin: '5px' }}
                              className={classes.buttonPrincipale}>
                              {formatMessage({ id: "ItemClasseEtablissement_11" })}
                            </Button>
                          </div>
                        </div>
                        {this.state.progress === 0 && (
                          <div class="layout horizontal center wrap">
                            {this.state.groups.map((group, index) => (
                              <span className="layout horizontal center" style={{ border: '1px dashed #EAEAEA', margin: '5px' }} key={index}>
                                <span style={{ fontSize: '13px', padding: '5px' }}> {formatMessage({ id: "ItemClasseEtablissement_12" })} <b>{group.type.name} </b></span>
                                <span style={{ fontSize: '13px', padding: '5px' }}> {formatMessage({ id: "ItemTypeDecaissement_3" })} <b>{group.code} </b></span>
                                <span style={{ fontSize: '13px', padding: '5px' }}> {formatMessage({ id: "ItemClasseEtablissement_13" })} <b>{group.ordre} </b></span>
                                <IconButton
                                  style={{ zoom: "0.60", '-moz-transform': 'scale(0.60)' }}
                                  title={formatMessage({ id: "ItemClasseEtablissement_14" })}
                                  aria-label="Editer"
                                  onClick={() => this.handleShowEditGroup(group, index)}>
                                  <EditIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  style={{ zoom: "0.60", '-moz-transform': 'scale(0.60)' }}
                                  title={formatMessage({ id: "ItemClasseEtablissement_17" })}
                                  aria-label="Delete"
                                  onClick={() => this.handleShowDeleteGroup(group, index)}>
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </span>
                            ))}
                          </div>
                        )}
                        {this.state.progress === 1 && (
                          <div style={{ backgroundColor: 'white', margin: '10px 10px 0 10px', padding: '10px' }} className="layout vertical center-center">
                            <div style={{ padding: '15px' }}>
                              <CircularProgress thickness={1} size={30} className={classes.fabProgress} />
                            </div>
                            <div style={{ fontSize: '13px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                              {formatMessage({ id: "ItemClasseEtablissement_15" })}
                            </div>
                          </div>
                        )}

                        {(this.state.progress === 2 || (this.state.progress === 0 && this.state.groups.length === 0)) && (
                          <div style={{ backgroundColor: 'white', margin: '10px 10px 0 10px', padding: '10px' }} className="layout vertical center-center">
                            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                            <div style={{ fontSize: '13px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                              {formatMessage({ id: "ItemClasseEtablissement_16" })}
                            </div>
                          </div>
                        )}
                        {this.state.progress === 3 && (
                          <div style={{ backgroundColor: 'white', margin: '10px 10px 0 10px', padding: '10px' }} className="layout vertical center-center">
                            <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                            <div style={{ fontSize: '13px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                              {formatMessage({ id: "ListRoleView_LoadError" })}
                            </div>
                          </div>
                        )}
                      </div>
                    }


                  </div>

                </div>
              }
            </div>
          </div>
          {!this.state.modeEdit ?
            <div className={classes.actions}>

              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
                aria-label="Editer"
                onClick={this.handleEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_del" })}
                aria-label="Delete"
                onClick={this.handleDelete}>
                <DeleteIcon fontSize="small" />
              </IconButton>
              {!!this.props.row.status ?
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                  classes={{ root: classes.disable }}
                  aria-label="desactiver"
                  onClick={this.handleDisabled}
                >
                  <CheckIcon fontSize="small" />
                </IconButton> :
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                  classes={{ root: classes.enable }}

                  aria-label="activer"
                  onClick={this.handleEnabled}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>}
            </div> : null}
        </div>

      </React.Fragment>
    );
  }
}
ItemClasseEtablissement.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withStyles(styles, { withTheme: true })(injectIntl(ItemClasseEtablissement));
