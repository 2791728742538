import React, { Component } from "react";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

import LinearProgress from "@material-ui/core/LinearProgress";

import { withSnackbar } from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: "white"
  },
  root: {
    width: "100%",
    marginTop: "30px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  }
});

class AjouterEmploiTempsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      name: "",
      inProgress: false,
      controlled: true,
      open: false
    };
    this.add = this.add.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }
  handleClickOpen(event) {
    this.setState({ open: true });
  }
  handleClose(event) {
    this.setState({ open: false });
  }

  add() {
    if (this.state.name == null || this.state.name.trim() === "") {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("name", this.state.name);
    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
        "/manager/emploi-temps/add?annee=" +
        this.props.annee.id,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false });
        self.handleClose();
        if (data.result_code === 1) {
          fireWithObject(document, "new-emploi-temps", { value: data.item });
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterEmploiTempsView_3" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterEmploiTempsView_4" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className={classes.buttonPrincipale}
          onClick={this.handleClickOpen}
        >
          {formatMessage({ id: "AjouterRoleView_add" })}
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {formatMessage({ id: "AjouterEmploiTempsView_2" })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {formatMessage({ id: "AjouterEmploiTempsView_1" })}
            </DialogContentText>
            {this.state.inProgress ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {!this.state.controlled ? (
              <div className={classes.error}>
                {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
              </div>
            ) : null}
            <TextField
              id="name"
              label="Titre de l'emploi de temps"
              style={{ width: "100%", marginTop: "0 !important" }}
              value={this.state.name}
              placeholder="Ex: 2018-2019"
              onChange={this.handleChangeName}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}
              onClick={this.handleClose}
            >
              {formatMessage({ id: "AutorisationEtablissement_Ccl" })}
            </Button>
            <Button
              onClick={this.add}
              variant="contained"
              size="medium"
              className={classes.buttonPrincipale}
            >
              {formatMessage({ id: "AjouterRoleView_add" })}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterEmploiTempsView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AjouterEmploiTempsView))
);
