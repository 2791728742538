import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AjouterIndisciplineView from '../view/indiscipline/AjouterIndisciplineView';
import ListIndisciplineView from '../view/indiscipline/ListIndisciplineView';
import { isObjectValueEquals } from '../../components/LdUtils';
import { deleteClassInProps} from '../../components/LdUtils';


const useStyles = theme => ({
    root: {
      display: "block", // Fix IE 11 issue.
      // paddingRight:'10px'
    },
    titleBlock:{
      textAlign:'left',
      // paddingLeft:"10px",
    },
    pageTitle:{
      fontSize: '21px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    pageSubtitle:{
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 400,
      color:theme.color.primaryColor
    }
    
  });

class IndisciplineController extends Component{

    constructor(props){
        super(props);
        //this.ajouterReseauEtablissViewRef = React.createRef();
        //this.ListReseauViewRef = React.createRef();
        this.state={
          etablissement: this.props.etablissement,
          annee: this.props.annee 
        }
    }
    componentDidUpdate(prevProps) {
      //var self = this;
      if(!isObjectValueEquals(this.props.etablissement,prevProps.etablissement)){
        this.setState({etablissement:this.props.etablissement},()=>{
          //self.refreshList("");
        });
      }
      if(!isObjectValueEquals(this.props.annee,prevProps.annee)){
        this.setState({annee:this.props.annee},()=>{
          //self.refreshList("");
        });
      }
    }
    render(){
        const {classes} = this.props;
        return (
            <div className = {classes.root}>
               <div  className={classes.titleBlock}>
                  {/* TODO: Afficher le dashboard ici.  */}
                  {/* <div className={classes.pageTitle} variant="div" component="div">
                    Les élèves
                  </div>
                  <div className={classes.pageSubtitle} variant="div" component="div">
                    Tous les élèves inscrits au cours de l'année scolaire
                  </div>  */}
                   <AjouterIndisciplineView   annee={this.state.annee} etablissement={this.state.etablissement} etablissementId = {this.state.etablissement.id}  {...deleteClassInProps(this.props)} />  
               </div>
               <ListIndisciplineView   annee={this.state.annee} etablissement = {this.state.etablissement}  {...deleteClassInProps(this.props)} />
             
            </div>
        );
    }
    
}

IndisciplineController.propTypes = {
    classes: PropTypes.object.isRequired
 };
  
export default withStyles(useStyles)(IndisciplineController);