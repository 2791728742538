import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ListNoteView from '../view/list-note/ListNoteView';
import { deleteClassInProps} from '../../components/LdUtils';

const useStyles = theme => ({
    root: {
      display: "block", // Fix IE 11 issue.
    },
    titleBlock:{
      textAlign:'left',
      // paddingLeft:"10px",
    },
    pageTitle:{
      fontSize: '21px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    pageSubtitle:{
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 400,
      color:theme.color.primaryColor
    }
    
  });

class ListNoteController extends Component{

    constructor(props){
        super(props);
        this.state={
          etablissement: this.props.etablissement

        }

        //this.ajouterReseauEtablissViewRef = React.createRef();
        //this.ListReseauViewRef = React.createRef();

    }
    
    render(){
        const {classes} = this.props;
        return (
            <div className = {classes.root}>
               <div  className={classes.titleBlock}>
                  {/* <div className={classes.pageTitle} variant="div" component="div">
                    Gestion des saisies de notes
                  </div>
                  <div className={classes.pageSubtitle} variant="div" component="div">
                    Vous pouvez créer des types d'indisciplines. Ces types ne dépendent  pas de l'année scolaire.
                  </div> */}
                  {/* <AjouterTypeIndisciplineView etablissementId={this.state.etablissement.id}/> */}
               </div>
               {this.props.mode ==="sequence" && <ListNoteView mode={this.props.mode} etablissement={this.props.etablissement} annee={this.props.annee}  {...deleteClassInProps(this.props)} />} 
               {this.props.mode ==="trimestre" && <ListNoteView mode={this.props.mode} etablissement={this.props.etablissement} annee={this.props.annee}  {...deleteClassInProps(this.props)} />} 
               {this.props.mode ==="annuelle" && <ListNoteView mode={this.props.mode} etablissement={this.props.etablissement} annee={this.props.annee}  {...deleteClassInProps(this.props)} />} 
           
            </div>
        );
    }
    
}

ListNoteController.propTypes = {
    classes: PropTypes.object.isRequired
};
  
export default withStyles(useStyles)(ListNoteController);