import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { injectIntl } from "react-intl";



const styles = theme => ({
    root:{
        display : 'flex',
        flexWrap: 'wrap',
        padding: '10px 5px'
    },
    paper:{
        display:'flex',
        borderRadius: "50%",
        // width: "45px",
        // height: "45px",
        // margin:'0 5px',
        // padding: "5px 12px",
        minWidth:'35px',
        minHeight:'35px',
        margin: "2px",
        // fontWeight:"bold",
        backgroundColor:'#FFFFFF',
        cursor:'pointer',
        border:'1px solid var(--app-primary-color)',
        '&:hover':{
            backgroundColor:'var(--app-accent-color)',
            color:'white'
        }

    },
    paperCurrent:{
        display:'flex',
        borderRadius: "50%",
        // width: "45px",
        // height: "45px",
        //padding: "5px 12px",
        minWidth:'37px',
        minHeight:'37px',
         margin: "2px",
         fontWeight:600,
        // margin:'0 5px',
        color:'#FFF',
        backgroundColor:'var(--app-primary-dark-color)',
        // fontWeight:"bold",
        cursor:'pointer'
        // '&>*':{
        //     color:"#FF1744 !important"
        // }
    },
    children:{
        margin:"auto"
    }
});


class LdPagination extends Component{
    constructor(props){
        super(props);
        this.showPage = this.showPage.bind(this);
    }
    showPage(row,index){
        //var page=event.target.getAttribute('data-index');
        this.props.onShowPage(row.val);
    };
    render(){
        const { classes ,pages }  = this.props;
        const { formatMessage } = this.props.intl;
        return(
            <div className={classes.root}> 
             {pages.map((row,index) => (
                <div size='medium' title={(row.current?formatMessage({ id: "LdPagination_cur" })+row.val:formatMessage({ id: "LdPagination_goTo" })+row.val)} key={index+1} onClick={() => this.showPage(row,index+1)} data-index={index+1}  className={row.current?classes.paperCurrent:classes.paper} elevation={0}>
                    <span data-index={index+1}  className ={classes.children}>
                       {row.val}
                    </span>
                </div>
            ))}
            </div>
        );
    }
} 
LdPagination.propTypes={
    classes: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired,
}
export default withStyles(styles)(injectIntl(LdPagination)); 