import Avatar from "@material-ui/core/Avatar";
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { appProperties, computeNbMinutes, defaultAvatar, isObjectValueEquals } from "../../../components/LdUtils";
import { injectIntl, FormattedMessage } from "react-intl";







const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "0 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: 'white',

  },
  root: {
    width: "100%",
    // marginTop: '30px',

  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: '0px'
  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "32px !important",
    height: "32px !important",
    borderRadius: "50% !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,

    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale: {
    margin: '2px 5px',
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },



  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  fabAddIcon: {
    zoom: "0.60",
    "-moz-transform": "scale(0.60)"
  },
  fabEditIcon: {
    zoom: "0.50",
    "-moz-transform": "scale(0.50)",
    // display: 'none',
  },
  // buttonCardEdit:{
  //   '&:hover': {
  //     '${Fab}':{
  //       display: 'block !important',
  //     }

  //   },
  // }
});

class PlanningDayEnseignant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      user: this.props.user,
      periodes: [],
      progressPeriode: 1,
      jour: this.props.jour,
      currentPeriode: null,
      currentPosition: 0,
      openAddDialog: false,
      inProgressAdd: false,
      horaireId: false,
      hoverId: null,
      selectedEnseignantIds: [],
      selectedHoraireByEnseignantIds: {},
      clickedEnseignantId: null,

    };
    this.clickOpenAddDialog = this.clickOpenAddDialog.bind(this);
    this.handleCloseAddDialog = this.handleCloseAddDialog.bind(this);
    this.handleListItemClickEnseignant = this.handleListItemClickEnseignant.bind(this);

  }
  updateSelectedArray(periode) {
    var selectedEnseignantIds = [];
    var selectedHoraireByEnseignantIds = {};
    (periode.horairesCours || []).forEach(element => {
      if (element != null) {
        selectedEnseignantIds.push(element.enseignant != null ? element.enseignant.id : "");
        selectedHoraireByEnseignantIds[element.enseignant.id] = element.id;
      }
    });
    this.setState({ selectedEnseignantIds: selectedEnseignantIds, selectedHoraireByEnseignantIds: selectedHoraireByEnseignantIds });
  }
  clickOpenAddDialog(index, periode/*, horairesCours*/) {
    var self = this;

    this.setState({ currentPeriode: periode, currentPosition: index, openAddDialog: true }, () => {
      self.updateSelectedArray(periode);
      //if (periode.horairesCours && periode.horairesCours !== []) {
      //self.setState({ horairesCours: periode.horairesCours, currentEnseignant: periode.horaireCours.enseignant });
      //}

    });
  };

  handleCloseAddDialog(event) {
    this.setState({ currentPeriode: null, currentPosition: 0, openAddDialog: false, selectedEnseignantIds: [] });
  };
  updateItem(mode, item) {
    console.log("intercept", mode);
    console.log(item);
    var array = [...this.state.periodes];
    if (mode === 'update') {
      array.splice(item.position, 1, item.row);
    } else if (mode === 'delete') {
      array.splice(item.position, 1);

    } else if (mode === 'enabled') {
      item.row.status = true;
      console.log('enabled', item.row.status);
      array.splice(item.position, 1, item.row);

    } else if (mode === 'disabled') {
      item.row.status = false;
      array.splice(item.position, 1, item.row);

    }

    this.setState(state => {
      state.periodes = array;
      return state;
    });
  }
  handleListItemClickEnseignant(enseignant) {
    //this.setState({currentEnseignant:enseignant});
    if (this.state.selectedEnseignantIds != null && this.state.selectedEnseignantIds.length > 0 && this.state.selectedEnseignantIds.indexOf(enseignant.id) > -1) {
      // il faut enlever la plannification
      this.remove(enseignant);
    } else {
      this.add(enseignant);
    }
    // if (this.state.horaireId == null) this.add(enseignant);
    // else {
    //   if (enseignant != null && this.state.selectedEnseignantIds != null && this.state.selectedEnseignantIds.length>0 &&  this.state.selectedEnseignantIds.indexOf(enseignant.id)>-1) {
    //     // il faut enlever la plannification
    //     this.remove(enseignant);
    //   } //else this.edit(enseignant);
    // }
    //Todo, générer ici le cas ou on veux annuler l'enseignant
  }
  add(enseignant) {
    //this.setState({ currentEnseignant: enseignant });
    if (this.state.currentPeriode == null || enseignant == null) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    let self = this;
    this.setState({ inProgressAdd: true, clickedEnseignantId: enseignant.id });
    var url = appProperties.basepathServer + "/manager/emploi-temps/add-item?id=" + this.props.emploiTempsId;
    if (enseignant != null) url += ("&enseignant=" + enseignant.id);
    if (this.state.currentPeriode != null) url += ("&periode=" + this.state.currentPeriode.id);

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressAdd: false });
        if (data.result_code === 1) {
          //fireWithObject(document,'new-bande',{value:data.item}); 
          self.setState(state => {
            state.datas = [enseignant.id, ...state.selectedEnseignantIds];
            return state;
          });
          //self.setState({ selectedEnseignantIds: enseignant });
          var periode = this.state.currentPeriode;
          if (periode != null) {
            self.setState(state => {
              state.datas = [enseignant.id, ...state.selectedEnseignantIds];
              return state;
            });
            periode['horairesCours'] = [...periode.horairesCours, data.item];
            // var array = [...this.state.periodes];
            // array.splice(self.state.currentPosition,1,periode);
            // self.setState(state => {
            //   state.periodes =  array ;
            //   return state;
            // });
            self.updateItem('update', { row: periode, position: self.state.currentPosition });
            self.updateSelectedArray(periode);

          }
          //self.handleCloseAddDialog();

          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_1" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_2" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_3" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_4" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -4) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_5" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -5) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_6" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_7" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }
        self.setState({ clickedEnseignantId: null });


      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressAdd: false, clickedEnseignantId: null });
        alert("There was an error added.");
      });
  }

  /**
   * N'est plus utilisé
   * @param {*} enseignant 
   */
  edit(enseignant) {
    this.setState({ currentEnseignant: enseignant });
    if (this.state.currentPeriode == null || enseignant == null) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    let self = this;
    this.setState({ inProgressAdd: true });
    var url = appProperties.basepathServer + "/manager/emploi-temps/edit-item?id=" + this.state.horaireId;
    if (enseignant != null) url += ("&enseignant=" + enseignant.id);
    if (this.state.currentPeriode != null) url += ("&periode=" + this.state.currentPeriode.id);

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressAdd: false });
        if (data.result_code === 1) {
          //fireWithObject(document,'new-bande',{value:data.item}); 
          self.setState({ currentEnseignant: enseignant });
          var periode = this.state.currentPeriode;
          if (periode != null) {
            periode['horaireCours'] = data.item;
            // var array = [...this.state.periodes];
            // array.splice(self.state.currentPosition,1,periode);
            // self.setState(state => {
            //   state.periodes =  array ;
            //   return state;
            // });
            self.updateItem('update', { row: periode, position: self.state.currentPosition });
          }
          self.handleCloseAddDialog();

          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_8" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_2" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_3" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_4" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -4) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_5" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -5) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_6" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_7" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressAdd: false });
        alert("There was an error added.");
      });
  }

  getHoraireIdByEnseignantId(enseignantId) {
    for (var i in this.state.selectedHoraireByEnseignantIds) {
      if (i === enseignantId) return this.state.selectedHoraireByEnseignantIds[i];
    }
    return null;

  }
  remove(enseignant) {
    //this.setState({currentEnseignant:enseignant});
    if (this.state.currentPeriode == null || enseignant == null) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    const horaireId = this.getHoraireIdByEnseignantId(enseignant.id);
    let self = this;
    this.setState({ inProgressAdd: true, clickedEnseignantId: enseignant.id });
    var url = appProperties.basepathServer + "/manager/emploi-temps/delete-item?id=" + horaireId;
    //if(enseignant!=null && enseignant.classeEtablissement!=null) url+=("&classe="+enseignant.classeEtablissement.id);
    //if(this.state.currentPeriode!=null) url+=("&periode="+this.state.currentPeriode.id);

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressAdd: false });
        if (data.result_code === 1) {
          //fireWithObject(document,'new-bande',{value:data.item}); 
          //self.setState({ currentEnseignant: null, horaireId: null });
          var horairesCours = (this.state.currentPeriode.horairesCours || []).filter(function (value, index, arr) {
            return value.id !== horaireId;
          });
          var periode = this.state.currentPeriode;
          if (periode != null) {
            //periode['horaireCours'] = data.item;
            periode['horairesCours'] = horairesCours;
            // var array = [...this.state.periodes];
            // array.splice(self.state.currentPosition,1,periode);
            // self.setState(state => {
            //   state.periodes =  array ;
            //   return state;
            // });
            self.updateItem('update', { row: periode, position: self.state.currentPosition });
            self.updateSelectedArray(periode);

          }

          //self.handleCloseAddDialog();

          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_9" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemEmploiTemps_2" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "PlanningDayClasse_7" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }
        self.setState({ clickedEnseignantId: null });

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressAdd: false, clickedEnseignantId: null });
        alert("There was an error added.");
      });
  }



  handleReset = () => {
    this.setState({
      periodes: [],
      periode: null,
      clickedEnseignantId: null
    });
  };




  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();
        self.listPeriodes();
        //self.listPeriodes();
      });
    }
    if (!isObjectValueEquals(this.props.user, prevProps.user)) {
      this.setState({ user: this.props.user }, () => {
        self.handleReset();
        self.listPeriodes();
        //self.listPeriodes();
      });
    }
    if (!isObjectValueEquals(this.props.jour, prevProps.jour)) {
      this.setState({ jour: this.props.jour }, () => {
        self.handleReset();
        self.listPeriodes();
        //self.listPeriodes();
      });
    }
  }
  componentDidMount() {
    this.listPeriodes();

  }

  sortPeriode = (periodes) => {
    var array = periodes || [];
    return array.sort((item1, item2) => {
      return (item1.heureDebut === item2.heureDebut) ? 0 :
        (item1.heureDebut < item2.heureDebut ? -1 : 1);
    });

  };


  listPeriodes() {
    let self = this;
    this.setState({ periodes: [], progressPeriode: 1 });
    var url = appProperties.basepathServer + '/manager/emploi-temps/list-periodes?limit=-1&jour=' + this.props.jour;
    if (this.props.user != null && this.props.user !== "---") url += ("&user=" + this.props.user.id);
    if (this.props.emploiTempsId != null) url += ("&id=" + this.props.emploiTempsId);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          var periodes = self.sortPeriode(data.datas);
          if (periodes.length > 0) {
            var minPeriode = periodes.reduce((acc, cur, ind, arr) => {
              return acc == null ? cur : (acc.duree < cur.duree ? acc : cur);
            }, null);
            self.props.onMaxGradual(periodes[periodes.length - 1].heureFin, minPeriode != null ? minPeriode.duree : 0, this.props.jour);
          }
          self.setState({ progressPeriode: 0, periodes: periodes });

        } else {
          self.setState({ periodes: [], progressPeriode: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ periodes: [], progressPeriode: 3 });

        console.log('There was an error listed enseignant.');
      });
  }

  getJoursLabel(jour, formatMessage) {
    var array = [
      formatMessage({ id: "AjouterBandeView_10" }),
      formatMessage({ id: "AjouterBandeView_11" }),
      formatMessage({ id: "AjouterBandeView_12" }),
      formatMessage({ id: "AjouterBandeView_13" }),
      formatMessage({ id: "AjouterBandeView_14" }),
      formatMessage({ id: "AjouterBandeView_15" }),
      formatMessage({ id: "AjouterBandeView_16" })];
    if (jour >= 1 && jour <= 7) {
      return array[parseInt(jour) - 1];
    } else return "";

  }
  computedMarginTop(index) {
    if (this.state.periodes && this.state.periodes.length > index) {
      var periode;
      if (index === 0) {
        periode = this.state.periodes[index];
        return (computeNbMinutes(periode.heureDebut) - computeNbMinutes("07:30")) * 6 / 5;
      } else if (index > 0) {
        var periodeLast = this.state.periodes[index - 1];
        periode = this.state.periodes[index];
        return (computeNbMinutes(periode.heureDebut) - computeNbMinutes(periodeLast.heureFin)) * 6 / 5;
      }
    }
    return 0;
  }

  render() {
    const { user, classes } = this.props;
    const enseignants = user != null ? (user.enseignants || []) : [];
    const { selectedEnseignantIds } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        <Dialog open={this.state.openAddDialog} onClose={this.handleCloseAddDialog} aria-labelledby="simple-dialog-title" >
          <DialogTitle id="simple-dialog-title">{formatMessage({ id: "PlanningDayClasse_10" })}</DialogTitle>
          <DialogContent>
            <List>
              {enseignants.map((enseignant, index) => (
                <ListItem title={(selectedEnseignantIds.length > 0 && selectedEnseignantIds.indexOf(enseignant.id) > -1) ? formatMessage({ id: "PlanningDayClasse_11" }) : formatMessage({ id: "PlanningDayClasse_12" })} button onClick={() => this.handleListItemClickEnseignant(enseignant)} key={index}>
                  <ListItemAvatar title={formatMessage({ id: "ItemClasseAnnee_23" }) + enseignant.user.email}>
                    <Avatar alt='' src={enseignant.user.photo || defaultAvatar} className={classes.avatar} />
                  </ListItemAvatar>
                  <ListItemText primary={enseignant.classeEtablissement.name} secondary={formatMessage({ id: "ItemClasseAnnee_12" }) + (enseignant.matiereName || '#' + enseignant.matiere.name)} />

                  {(selectedEnseignantIds.length > 0 && selectedEnseignantIds.indexOf(enseignant.id) > -1) ?
                    <React.Fragment>
                      {(!this.state.inProgressAdd || this.state.clickedEnseignantId !== enseignant.id) && (
                        <ListItemAvatar title={formatMessage({ id: "PlanningDayClasse_13" })}>
                          <Avatar style={{ color: 'var(--paper-green-500)' }}>
                            <DoneAllIcon />
                          </Avatar>
                        </ListItemAvatar>
                      )}
                      {this.state.inProgressAdd && this.state.clickedEnseignantId === enseignant.id && (
                        <ListItemAvatar title={formatMessage({ id: "AjouterTypeAlertView_14" })}>
                          <CircularProgress thickness={1} size={32} className={classes.fabProgress} />
                        </ListItemAvatar>
                      )}
                    </React.Fragment>
                    :

                    <React.Fragment>
                      {(!this.state.inProgressAdd || this.state.clickedEnseignantId !== enseignant.id) && (
                        <ListItemAvatar title={formatMessage({ id: "AjouterSaisieEnseignantView_11" })}>
                          <Avatar>
                            <AddIcon />
                          </Avatar>
                        </ListItemAvatar>

                      )}
                      {this.state.inProgressAdd && this.state.clickedEnseignantId === enseignant.id && (
                        <ListItemAvatar title={formatMessage({ id: "AjouterTypeAlertView_14" })}>
                          <CircularProgress thickness={1} size={32} className={classes.fabProgress} />
                        </ListItemAvatar>
                      )}
                    </React.Fragment>


                  }

                </ListItem>
              ))}
              {/* <ListItem button onClick={() => this.handleListItemClickTitulaire(null)}>
                <ListItemAvatar>
                  <Avatar>
                    <RemoveCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Enlever le titulaire de classe actuel" />
              </ListItem> */}
            </List>
          </DialogContent>
        </Dialog>
        <div>
          <div className="layout horizontal center-center" style={{ height: '35px', borderBottom: '1px solid #EAEAEA', textAlign: 'center', }}>
            <span style={{ fontWeight: '100', color: '#b1b1b1' }}>{this.getJoursLabel(this.props.jour, formatMessage)}</span>
          </div>
        </div>
        <div style={{ position: 'relative' }} className="layout vertical">
          {this.state.periodes.map((periode, index) => (
            <React.Fragment key={periode.id}>

              {(!periode.horairesCours || periode.horairesCours == null || periode.horairesCours.length === 0) && (
                <ButtonBase onMouseEnter={() => this.setState({ hoverId: periode.id })}
                  onMouseLeave={() => this.setState({ hoverId: null })} key={periode.id} component="div" style={{
                    marginBottom: '-2px', fontSize: '9px', borderBottom: '1px solid #EAEAEA', borderTop: '1px solid #EAEAEA',
                    marginTop: this.computedMarginTop(index),
                    height: periode.duree * 6 / 5
                  }}>
                  <div className="layout vertical center-center">
                    <span>
                      <FormattedMessage id="PlanningDayClasse_15" values={{ debut: periode.heureDebut, fin: periode.heureFin }} /></span>
                    {this.state.hoverId != null && this.state.hoverId === periode.id && (
                      <Fab className={classes.fabAddIcon} onClick={() => this.clickOpenAddDialog(index, periode/*, []*/)} size="small" title={formatMessage({ id: "PlanningDayClasse_14" })} color="primary" aria-label="Add" >
                        <AddIcon />
                      </Fab>
                    )}
                  </div>
                </ButtonBase>
              )}
              {periode.horairesCours && periode.horairesCours != null && periode.horairesCours.length > 0 && (
                <ButtonBase onMouseEnter={() => this.setState({ hoverId: periode.id })}
                  onMouseLeave={() => this.setState({ hoverId: null })} className={classes.buttonCardEdit} component="div"
                  style={{
                    marginBottom: '-2px', fontSize: '9px', borderBottom: '1px solid #EAEAEA', borderTop: '1px solid #EAEAEA',
                    marginTop: this.computedMarginTop(index),
                    alignItems: 'start',
                    justifyContent: 'right',
                    backgroundColor: '#f1f1f1',
                    position: 'relative',
                    overflowY: 'auto',
                    height: periode.duree * 6 / 5
                  }}>
                  <div style={{ width: '100%', height: '100%' }} className="layout vertical flex">
                    {periode.horairesCours.map((horaireCours, index2) => (
                      <div key={horaireCours.id} style={{ textAlign: 'center', padding: ' 0 3px 0', border: '1px dashed #CCCCCC' }} className="layout horizontal center wrap">
                        <div style={{ textAlign: 'center', padding: ' 0 3px 0' }}>{(horaireCours.enseignant.matiereName || '#' + horaireCours.enseignant.matiere.name)}</div>
                        <div style={{ fontWeight: 600, textAlign: 'center' }}> {horaireCours.enseignant.classeEtablissement.name}</div>
                      </div>
                    ))}
                    <div className="flex"></div>
                    <div className="layout horizontal center justified" style={{ fontWeight: 600, padding: '0 3px 5px 3px', height: "20px", position: "absolute", zIndex: '2', right: 0, bottom: 0 }}>
                      {/* <span>
                            {horaireCours.status === 0 && <span style={{ padding: '3px', color: 'white', backgroundColor: 'var(--paper-yellow-500)' }}>{formatMessage({ id: "PlanningDayClasse_16" })}</span>}
                            {horaireCours.status === 1 && <span style={{ padding: '3px', color: 'white', backgroundColor: 'var(--paper-blue-200)' }}>{formatMessage({ id: "PlanningDayClasse_17" })}</span>}
                            {horaireCours.status === 10 && <span style={{ padding: '3px', color: 'white', backgroundColor: 'var(--paper-grey-400)' }}>{formatMessage({ id: "PlanningDayClasse_18" })}</span>}
                          </span> */}
                      {this.state.hoverId != null && this.state.hoverId === periode.id && (
                        <Fab className={classes.fabEditIcon} onClick={() => this.clickOpenAddDialog(index, periode/*, periode.horairesCours*/)} size="small" title={formatMessage({ id: "PlanningDayClasse_19" })} color="primary" aria-label="Edit" >
                          <EditIcon />
                        </Fab>
                      )}
                    </div>
                  </div>
                </ButtonBase>
              )}
            </React.Fragment>
          ))}
        </div>
      </main>
    );
  }
}
PlanningDayEnseignant.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(
  injectIntl(PlanningDayEnseignant)
));
