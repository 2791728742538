import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withStyles from "@material-ui/core/styles/withStyles";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DomainIcon from "@material-ui/icons/Domain";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import ProgressiveImage from "react-progressive-image";
import { Link, withRouter } from "react-router-dom";
import { appProperties, defaultAvatar } from "./LdUtils";
import { injectIntl } from "react-intl";

const useStyles = theme => ({
  root: {
    padding: "0 " + theme.spacing.unit + "px"
  },
  list: {
    // padding: theme.spacing.unit,
    padding: "0 !important",
    backgroundColor: "white !important"
  },
  paper: {
    padding: "0 " + theme.spacing.unit + "px",
    textAlign: "center",
    color: theme.palette.text.secondary,
    background: "none !important"
  },
  nested: {
    // paddingLeft: theme.spacing.unit * 4,
    padding: "5px !important",
    border: "0.5px solid #e4e1e1",
    marginBottom: "-1px"
  },
  listItem: {
    padding: "5px !important",
    border: "0.5px solid #e4e1e1",
    marginBottom: "-1px"
  },
  listItemText: {
    // fontSize: '13px !important',
    fontSize: "small !important",
    color: "#424242"
  },
  listItemTextRoot: {
    fontSize: "13px !important",
    padding: "0 !important"
  },

  listIcon: {
    color: "var(--app-primary-color)"
  },
  subHeader: {
    color: "black",
    fontSize: "13px",
    lineHeight: "16px",
    textAlign: "left",
    fontWeight: "600",
    marginLeft: "10px"
  },
  subTitle: {
    fontSize: "11px",
    lineHeight: "10px",
    textAlign: "left",
    fontWeight: "600",
    marginLeft: "10px",
    marginTop: "5px"
  },
  contentImage: {
    width: "40px"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  grid: {
    backgroundColor: "white !important",
    padding: "10px",
    marginBottom: "10px"
  }
});

class LdHomeMenu extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem(appProperties.key_user) || null;
    let currentUser = JSON.parse(user);
    this.state = {
      email: currentUser != null ? currentUser.email : "",
      currentUser: currentUser
    };
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.root}>
        <Paper elevation={0} className={classes.paper}>
          <Grid
            wrap="nowrap"
            container
            className={classes.grid}
            justify="flex-start"
            alignItems="center"
          >
            <div className={classes.contentImage}>
              <ProgressiveImage
                src={this.state.currentUser.photo}
                placeholder={defaultAvatar}
              >
                {(src, loading) => (
                  <Avatar alt="" src={src} className={classes.bigAvatar} />
                )}
              </ProgressiveImage>
            </div>
            <Grid direction="column" container>
              <div className={classes.subHeader}>
                {this.state.currentUser.name}
              </div>
              <div className={classes.subTitle}>
                {formatMessage({ id: "LdHomeMenu_Title" })}
              </div>
            </Grid>
          </Grid>
          <List component="nav" className={classes.list}>
            <ListItem
              selected={
                this.props.location.pathname === "/administration/dashboard"
              }
              component={Link}
              to="/administration/dashboard"
              classes={{ root: classes.listItem }}
              button
            >
              <ListItemIcon className={classes.listIcon}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemText
                }}
                inset
                primary={formatMessage({ id: "LdHomeMenu_1" })}
              />
            </ListItem>
            <ListItem
              selected={
                this.props.location.pathname === "/administration/bulletins"
              }
              component={Link}
              to="/administration/bulletins"
              classes={{ root: classes.listItem }}
              button
            >
              <ListItemIcon className={classes.listIcon}>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemText
                }}
                inset
                primary={formatMessage({ id: "LdHomeMenu_2" })}
              />
            </ListItem>
            <ListItem
              selected={
                this.props.location.pathname === "/administration/reseaux"
              }
              component={Link}
              to="/administration/reseaux"
              classes={{ root: classes.listItem }}
              button
            >
              <ListItemIcon className={classes.listIcon}>
                <DomainIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemText
                }}
                inset
                primary={formatMessage({ id: "LdHomeMenu_3" })}
              />
            </ListItem>

            <ListItem
              selected={
                this.props.location.pathname ===
                "/administration/etablissements"
              }
              component={Link}
              to="/administration/etablissements"
              classes={{ root: classes.listItem }}
              button
            >
              <ListItemIcon className={classes.listIcon}>
                <DomainIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemText
                }}
                inset
                primary="Etablissements"
              />
            </ListItem>
            <ListItem
              selected={
                this.props.location.pathname === "/administration/recharges-sms"
              }
              component={Link}
              to="/administration/recharges-sms"
              classes={{ root: classes.listItem }}
              button
            >
              <ListItemIcon className={classes.listIcon}>
                <DomainIcon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemText
                }}
                inset
                primary={formatMessage({ id: "LdHomeMenu_4" })}
              />
            </ListItem>
          </List>
        </Paper>
      </div>
    );
  }
}
LdHomeMenu.propTypes = {
  classes: PropTypes.object.isRequired
  /*theme: PropTypes.object.isRequired,*/
};

export default withRouter(withStyles(useStyles)(injectIntl(LdHomeMenu)));
