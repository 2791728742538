import Avatar from "@material-ui/core/Avatar";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from "react";
// import { Bar } from 'react-chartjs-2';
import { Chart } from 'react-google-charts';
import { appProperties, cancelEventPropagation, isObjectValueEquals, defaultStudents } from '../components/LdUtils';
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    width: "100%",
  },
  main: {
    width: '100%',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },
  /** Filter start */
  formControl: {
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
    maxWidth: '200px',
  },
  cssLabel: {
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */
  paperCard: {
    width: '400px',
    padding: '5px',
    margin: '5px',
  },
  scolariteGreen: {
    margin: 10,
    color: '#fff',
    // backgroundColor: 'var(--paper-purple-200)',
  },
  panelRoot: {
    borderRadius: '0 !important',
  },
  panelSummary: {
    alignItems: 'center',
    margin: '0 !important',
    borderBottom: '1px dashed #EAEAEA'
  },
  summaryRoot: {
    padding: '0',
  },
  summaryExpanded: {
    margin: '0 !important',
  },
});
class HistoRegisteredEleves extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,
      labelWidth: 0,
      total: "",
      openRapport: false,
      expanded: 'filled',
      divWidth: 0,
      categorie: this.props.categorie || null,
    };
    this.rapportRef = React.createRef();
    this.handleReset = this.handleReset.bind(this);
    this.stopPropagation = this.stopPropagation.bind(this);
  }
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  handleReset = () => {
    var self = this;
    self.loadHistoEleves();
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();
      });
    }
    if (!isObjectValueEquals(this.props.categorie, prevProps.categorie)) {
      this.setState({ categorie: this.props.categorie }, () => {
        self.handleReset();
      });
    }

  }
  stopPropagation = event => {
    cancelEventPropagation(event);
  };
  componentDidMount() {
    const width = this.divElement != null ? this.divElement.clientWidth : "";
    // console.log("Element width  "+width);
    this.setState({ divWidth: width });
    this.loadHistoEleves();
  }
  componentWillUnmount() {
  }
  // computedDepenseBar(datas) {
  //   var bar = {};
  //   var labels = [];
  //   var codes = [];
  //   var dataset1 = {
  //     label: this.props.intl.formatMessage({ id: "HistoRegisteredEleves_2" }),
  //     type: 'bar',
  //     backgroundColor: '#039be5',
  //     borderColor: '#03a9f4',
  //     borderWidth: 1,
  //     hoverBackgroundColor: '#29b6f6',
  //     hoverBorderColor: '#03a9f4',
  //     fill: false,
  //     data: [],
  //     yAxisID: 'y-axis-1'
  //   };
  //   var dataset2 = {
  //     label: this.props.intl.formatMessage({ id: "HistoRegisteredEleves_5" }),
  //     type: 'line',
  //     backgroundColor: '#ff9800',
  //     borderColor: '#ffa726',
  //     pointBackgroundColor: '#EC932F',
  //     pointHoverBackgroundColor: '#ffb74d',
  //     pointHoverBorderColor: '#ffa726',
  //     data: [],
  //     yAxisID: 'y-axis-2'
  //   };
  //   var total = 0;
  //   if (datas != null && datas.length > 0) {
  //     datas.forEach((item) => {
  //       codes.push(item.classeEtablissement.classe.name);
  //       labels.push(item.classeEtablissement.name);
  //       dataset1.data.push(item.countEleve);
  //       dataset2.data.push(item.effectifMax);
  //       total += item.countEleve;
  //     });
  //     this.setState({ total: total });
  //   }
  //   bar['codes'] = codes;
  //   bar['labels'] = labels;
  //   bar['total'] = total;
  //   bar['datasets'] = [dataset1, dataset2];
  //   return bar;
  // }
  computedDepenseBar2(datas) {
    var data = [];
    var total = 0;
    data.push(["", this.props.intl.formatMessage({ id: "HistoRegisteredEleves_2" }), this.props.intl.formatMessage({ id: "HistoRegisteredEleves_5" })]);
    datas.forEach((item) => {
      data.push([item.classeEtablissement.name, item.countEleve, item.effectifMax]);
      total += item.countEleve;
    });
    //data.splice(5, 50);
    this.setState({ data: data, total: total });
  }
  loadHistoEleves() {
    let self = this;
    this.setState({ data: null, progress: 1 });
    var url = appProperties.basepathServer + "/manager/statistics/registered-eleves?etablissement=" + this.props.etablissement.id + "&limit=-1";
    if (this.props.annee != null && this.props.annee !== '---') url += ("&annee=" + this.props.annee.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        Accept: 'application/json'
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result_code === 1) {
          self.computedDepenseBar2(data.datas);
          self.setState({ progress: 0 });
        } else {
          self.setState({ data: null, progress: 2 });
        }
      })
      .catch((err) => {
        console.log(err);
        self.setState({ data: null, progress: 3 });
        console.log('There was an error listed type de frais.');
      });
  }
  getTextAngle(count) {
    if (count > 50) return 90;
    if (count > 40) return 75;
    if (count > 30) return 60;
    if (count > 20) return 45;
    if (count > 10) return 30;
    if (count > 7) return 10
    return 0;
  }
  getFontSize(count) {
    //return 10;
    if (count > 50) return 10;
    if (count > 40) return 8;
    if (count > 30) return 9;
    if (count > 20) return 10;
    if (count > 10) return 11;
    if (count > 5) return 12
    return 13;
  }
  render() {
    const { classes } = this.props;
    const { expanded, data } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <div ref={(divElement) => this.divElement = divElement} className={classes.main}>
        <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'filled'} onChange={this.handleChange('filled')}>
          <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
            <div style={{ position: 'relative' }}>
              <Avatar src={defaultStudents} className={classes.scolariteGreen}>
              </Avatar>
              {this.state.progress === 1 &&
                <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
              }
            </div>
            <Grid container className={classes.grid} justify="space-between" alignItems='center'>
              <div className={classes.titleBlock}>
                <div className={classes.pageSubTitle} variant="div" component="div">
                  {formatMessage({ id: "HistoRegisteredEleves_3" }, { value: this.state.data != null ? this.state.total : '' })}</div>
              </div>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ padding: '10px 10px 10px 10px' }} className="layout vertical">
            {this.state.progress === 0 && (
              <div className="layout vertical">
                <div className="layout horizontal center-justified">

                  {(data != null && data.length>1) && (<Chart
                    width={'100%'}
                    height={'400px'}
                    chartType="ComboChart"
                    loader={<div style={{ padding: '15px', fontSize: '12px' }}>{formatMessage({ id: "TauxIndisciplineEleve_3" })}</div>}
                    data={data}
                    options={{
                      chartArea: {
                        left: 45,
                        // right: 10, // !!! works !!!
                        bottom: 100,  // !!! works !!!
                        top: 20,
                        width: "100%",
                        // height: "80%"
                      },
                      isStacked: 'true',
                      vAxis: {
                        title: this.props.intl.formatMessage({ id: "HistoRegisteredEleves_2" }),
                        minValue: 0,
                        titleTextStyle: { color: 'black' },
                        count: -1,
                        textPosition: 'out'
                      },
                      hAxis: {
                        title: '',
                        titleTextStyle: { color: 'black', fontSize: '14px' },
                        textStyle: {
                          fontSize: this.getFontSize(data.length - 1) // or the number you want
                        },
                        count: -1,
                        viewWindowMode: 'pretty',
                        slantedText: this.getTextAngle(data.length - 1) > 0,
                        slantedTextAngle: this.getTextAngle(data.length - 1),
                        textPosition: 'out'
                      },
                      seriesType: 'bars',
                      series: { 1: { type: 'line' } },
                      title: formatMessage({ id: "HistoRegisteredEleves_1" }),
                      // Just add this option
                      is3D: false,
                      // pieHole: 0.4,
                      colors: ['#2196f3', '#ff9800'],
                      //colors: ['#2196f3', '#547896'],
                      legend: { position: 'bottom', alignment: 'center' },

                    }}
                    rootProps={{ 'data-testid': this.props.dataTestId }}
                  />)}
                  {data != null && (
                    <Typography component="p">
                      {/* Montant total: <b>{this.state.total} FCFA</b> */}
                    </Typography>
                  )}
                  {(data == null || data.length <= 1) && (
                    <div className="layout vertical center-center">
                      <Typography variant="h5" component="h3">
                        {formatMessage({ id: "HistoRegisteredEleves_4" })}
                      </Typography>
                      <Typography component="p">
                        {formatMessage({ id: "HistoDepenseType_4" })}
                      </Typography>
                    </div>
                  )}
                </div>
                <div className="flex"></div>
                {/* { this.state.total >0 && this.props.classe!=null && this.props.classe!=="---" ? (
                    <div className="layout horizontal center end-justified">
                      <Button onClick={this.handleClickOpenRapport}  title="Afficher le rapport de paiement par l'élève"  style={{textTransform:'none',borderRadius:'0 !important'}} variant="outlined" size="small" className={classes.margin}>
                          Voir le rapport
                      </Button>       
                    </div>
                  ):null} */}
              </div>
            )}
            {this.state.progress === 1 && (
              <div className="layout horizontal center-center" style={{ padding: '100px 50px' }}>
                <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
              </div>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(HistoRegisteredEleves));
