import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import { appProperties } from './components/LdUtils';
import  enTranslation from "./translations/en.json";
import  frTranslation from "./translations/fr.json";
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';

//https://stackoverflow.com/questions/44635584/react-intl-how-to-switch-locale-and-messages-from-variable
addLocaleData([...en, ...fr]);

const messageKeys = {fr:frTranslation,en:enTranslation};

class IntlProviderWrapper extends React.Component {
  constructor(...args) {
    super(...args);

    // this.switchToEnglish = () =>
    //   this.setState({ locale: "en", messages: enTranslation });

    // this.switchToDeutsch = () =>
    //   this.setState({ locale: "de", messages: deTranslation });

    const defaultLocale = navigator.language.split(/[-_]/)[0];  // language without region code
    var userLocal = localStorage.getItem(appProperties.userLocale) || defaultLocale;
    localStorage.setItem(appProperties.userLocale,userLocal);
    const messages = (!messageKeys.hasOwnProperty(userLocal)) ? frTranslation : messageKeys[userLocal];
    this.state = {
      locale: userLocal,
      messages: messages,
    };
    this.manageLanguage = this.manageLanguage.bind(this);
  }
  manageLanguage(event){
      var  userLocal = event.detail.value || this.state.locale;
      if(!messageKeys.hasOwnProperty(userLocal)) userLocal = "fr";
      this.setState({locale:userLocal,messages:messageKeys[userLocal]});
      localStorage.setItem(appProperties.userLocale,userLocal);
  }
  componentDidMount(){
    document.addEventListener('language-change', this.manageLanguage.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener('language-change', this.manageLanguage.bind(this));
  }

  render() {
    const { children } = this.props;
    const { locale, messages } = this.state;
    return (
        <IntlProvider
          key={locale}
          locale={locale}
          messages={messages}
          defaultLocale="fr">
          {children}
        </IntlProvider>
    );
  }
}

export  default  IntlProviderWrapper;