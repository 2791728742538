import AppBar from '@material-ui/core/AppBar';
import Avatar from "@material-ui/core/Avatar";
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide from '@material-ui/core/Slide';
import Snackbar from "@material-ui/core/Snackbar";
import withStyles from "@material-ui/core/styles/withStyles";
// import ItemDossierTypeFrais from './ItemDossierTypeFrais';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotesIcon from '@material-ui/icons/Notes';
import SortIcon from '@material-ui/icons/Sort';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import classNames from 'classnames';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl, intlShape } from 'react-intl';
import { appProperties, cancelEventPropagation, deleteClassInProps, isObjectValueEquals, userProfileUrl } from "../../../components/LdUtils";
import FicheNoteInput from "./FicheNoteInput";

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from "notistack";


const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    backgroundColor: theme.color.primaryDark,
    border: '2px solid white',
    width: "100px !important",
    height: "100px !important",
    borderRadius: '0 !important',
    color: 'white'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },


  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },





  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  toolbar: {
    height: '64px',
    backgroundColor: 'var(--app-primary-color);',
    padding: ' 0 10px',

  },
  toolbarTitle: {
    fontSize: '16px',
    color: 'white',
    fontWeight: '600',
    display: 'inline'
  },
  toolbarSubTitle: {
    fontSize: '12px',
    // fontWeight:'600',
    color: '#FEFEFE'
  },
  avatarTitle: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "40px !important",
    height: "40px !important",
    borderRadius: '50% !important'
  },
  avatarDivtitle: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: '50% !important',
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  dossierZone: {
    padding: '10px 0',
    margin: '2px 0',
  },
  dossierTexte: {

    color: 'white',
    padding: '5px',
    backgroundColor: 'var(--app-primary-color)',
    fontsize: '17px',
    fontWeight: '600',
  },
  dialogRoot: {
    top: '64px !important',
    left: '25% !important',
    right: '0 !important',
    bottom: '0 !important',
    // z-index: 1300;
    // position: fixed;
    paddingTop: "15px !important",
    paddingLeft: '18px !important',
    paddingRight: '45px !important'
  },
  backdrop: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 600
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  indisciplineAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-orange-500)',
  },
  scolariteAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-orange-500)',
  },
  scolariteGreen: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-green-500)',
  },
  scolariteYellow: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-yellow-500)',
  },
  panelRoot: {
    borderRadius: '0 !important',
  },
  panelSummary: {
    alignItems: 'center',
    margin: '0 !important',
    borderBottom: '1px dashed #EAEAEA'
  },
  summaryRoot: {
    padding: '0',

  },
  summaryExpanded: {
    margin: '0 !important',

  },
  fraisBadge: {
    top: '15px',
    right: '15px'
  },

});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

// const PostDate = injectIntl(({date, intl}) => (
//   <span  title={intl.formatDate(date)}>
//       {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
//       <FormattedDate 
//        day="numeric"
//        month="long"
//        year="numeric"
//        value={date}/>
//   </span>
// ));
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: '600',
    fontSize: '15px',
    padding: '4px 7px 4px 7px'
  },
  body: {
    fontSize: 14,
    padding: '4px 7px 4px 7px'
  }
}))(TableCell);
class FicheSaisie extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);

    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      position: this.props.position,
      etablissement: this.props.etablissement,
      controlled: true,
      openDialog: false,
      progressEleve: 1,
      expanded: this.props.expanded || 'filled',
      elevesWithoutNote: [],
      elevesWithNote: [],
      countEleve: 0,
      hasMoreEleve: false,
      pagesEleve: [],
      currentPageEleve: 1,
      saisie: this.props.saisie,
      data: [],
      openSnack: false,
      openConfirmDelete: false,
      progressDelete: false,
      inProgressBonus: false,
      deletedEleveIndex: null,
      result_add: 0,
      bonus: 0
    };//          


    this.handleClickOpenDialog = this.handleClickOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.setCurrentPageEleve = this.setCurrentPageEleve.bind(this);
    this.doOrderByName = this.doOrderByName.bind(this);
    this.doOrderByMatricule = this.doOrderByMatricule.bind(this);

    this.doOrderByName1 = this.doOrderByName1.bind(this);
    this.doOrderByMatricule1 = this.doOrderByMatricule1.bind(this);
    this.doOrderByNote1 = this.doOrderByNote1.bind(this);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleChangeBonus = this.handleChangeBonus.bind(this);
    this.handleAddBonus = this.handleAddBonus.bind(this);
  }
  handleDelete = (index) => {
    this.setState({ openConfirmDelete: true, deletedEleveIndex: index });
  };

  handleCloseDelete = () => {
    this.setState({ openConfirmDelete: false, deletedEleveIndex: -1 });
  };

  onUpdateItem = (mode, item) => {
    var array = [...this.state.elevesWithNote];
    if (mode === 'update') {
      array.splice(item.position, 1, item.row);
    } else if (mode === 'delete') {
      array.splice(item.position, 1);
    }
    this.setState(state => {
      state.elevesWithNote = array;
      return state;
    });
  };
  handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnack: false });
  };

  doOrderByName1 = (event) => {
    var array = this.state.elevesWithNote;
    array.sort((item1, item2) => {
      return (item1.user != null && (item1.fullname || '@' + item1.user.name) != null &&
        item2.user != null && (item2.fullname || '@' + item2.user.name) != null) ?
        (item1.fullname || '@' + item1.user.name).localeCompare(item2.fullname || '@' + item2.user.name) : 0;
    });
    this.setState({ elevesWithNote: array });

  };
  // doOrderByName1 = (event) => {
  //   var array = this.state.elevesWithNote;
  //   array.sort((item1,item2) => {
  //     return (item1.user!=null  && item1.user.name!=null && 
  //             item2.user!=null  && item2.user.name!=null) ?
  //             item1.user.name.localeCompare(item2.user.name):0;
  //   });
  //   this.setState({ elevesWithNote: array });

  // };

  doOrderByMatricule1 = (event) => {
    var array = this.state.elevesWithNote;
    array.sort((item1, item2) => {
      return (item1.matricule != null && item2.matricule != null) ?
        item1.matricule.localeCompare(item2.matricule) : 0;
    });
    this.setState({ elevesWithNote: array });

  };
  doOrderByNote1 = (event) => {
    var array = this.state.elevesWithNote;
    array.sort((item1, item2) => {
      return (item1.note != null && item2.note != null) ?
        (parseFloat(item1.note.exactValue) < parseFloat(item2.note.exactValue) ? 1 : -1) : 0;
    });
    this.setState({ elevesWithNote: array });

  };
  // doOrderByName = (event) => {
  //   var array = this.state.elevesWithoutNote;
  //   array.sort((item1,item2) => {
  //     return (item1.user!=null  && item1.user.name!=null && 
  //             item2.user!=null  && item2.user.name!=null) ?
  //             item1.user.name.localeCompare(item2.user.name):0;
  //   });
  //   this.setState({ elevesWithoutNote: array });

  // };
  doOrderByName = (event) => {
    var array = this.state.elevesWithoutNote;
    array.sort((item1, item2) => {
      return (item1.user != null && (item1.fullname || '@' + item1.user.name) != null &&
        item2.user != null && (item2.fullname || '@' + item2.user.name) != null) ?
        (item1.fullname || '@' + item1.user.name).localeCompare(item2.fullname || '@' + item2.user.name) : 0;
    });
    this.setState({ elevesWithoutNote: array });

  };

  doOrderByMatricule = (event) => {
    var array = this.state.elevesWithoutNote;
    array.sort((item1, item2) => {
      return (item1.matricule != null && item2.matricule != null) ?
        item1.matricule.localeCompare(item2.matricule) : 0;
    });
    this.setState({ elevesWithoutNote: array });

  };
  handleChangeFor = (event) => {
    const name = event.target.name;
    var value = event.target.value;
    if (value > this.state.saisie.bareme) {
      value = this.state.saisie.bareme;
      event.target.value = value;
    }
    if (value < 0) {
      value = 0;
      event.target.value = value;
    }
    if (event.target.value === "") value = -1;
    const { data } = this.state;
    const newData = {
      ...data,
      [name]: parseFloat(value)
    };
    this.setState({ data: newData });
  }

  handleChangeBonus = (event) => {
    const name = event.target.name;
    var value = event.target.value;
    this.setState({ bonus: parseFloat(value) });
  }

  setCurrentPageEleve = page => {
    this.setState({ currentPageEleve: page });
    this.listelevesWithoutNote(page);
  };


  handleClickOpenDialog = () => {
    this.setState({ openDialog: true });
  };
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  handleCloseDialog = () => {
    var self = this;
    this.setState({ openDialog: false }, () => {
      self.props.onClose(this);
    });

  };
  componentDidMount() {
    this.props.onRef(this);
    this.listelevesWithoutNote(this.state.currentPageEleve);
  }
  componentWillUnmount() {
    this.props.onRef(null);
  }
  componentDidUpdate(prevProps) {

    if (!isObjectValueEquals(this.props.saisie, prevProps.saisie)) {
      this.setState({ saisie: this.props.saisie }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }


  listelevesWithoutNote(page) {

    let self = this;

    this.setState({ elevesWithoutNote: [], elevesWithNote: [], progressEleve: 1 });
    var url = appProperties.basepathServer + '/manager/note/eleves?saisie=' + this.props.saisie.id;
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          var w = [];
          var wn = [];
          data.datas.forEach((eleve) => {
            if (eleve.note && eleve.note != null) w.push(eleve);
            else wn.push(eleve);
          });

          wn.sort((item1, item2) => {
            return (item1.user != null && item1.user.name != null &&
              item2.user != null && item2.user.name != null) ?
              item1.user.name.localeCompare(item2.user.name) : 0;
          });

          w.sort((item1, item2) => {
            return (item1.user != null && item1.user.name != null &&
              item2.user != null && item2.user.name != null) ?
              item1.user.name.localeCompare(item2.user.name) : 0;
          });
          self.setState({ elevesWithNote: w });
          self.setState({ progressEleve: 0, elevesWithoutNote: wn, elevesWithNote: w, countEleve: data.count, hasMoreEleve: data.hasMore, pagesEleve: data.pages });
        } else {
          self.setState({ elevesWithoutNote: [], elevesWithNote: [], progressEleve: 2 });
        }
      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ elevesWithoutNote: [], elevesWithNote: [], progressEleve: 3 });

        console.log('There was an error listed enseignant.');
      });
  }

  handleAdd(event) {
    if (!!this.props.saisie.status) return;
    cancelEventPropagation(event);
    var formData = new FormData(); // Currently empty
    formData.append("count", Object.entries(this.state.data).length);
    formData.append("saisie", this.props.saisie.id);
    Object.entries(this.state.data).forEach((e, index) => {
      console.log(e);
      formData.append("eleve_" + index, e[0]); //TODO: 
      formData.append("valeur_" + index, e[1]); //TODO: 
    });
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/note/adds", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false, result_add: data.result_code });
        self.setState({ openSnack: true, });
        if (data.result_code === 1) {
          self.listelevesWithoutNote(1)
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  handleAddBonus(event) {
    if (!!this.props.saisie.status) return;
    var formData = new FormData(); // Currently empty
    formData.append("bonus", this.state.bonus);
    formData.append("id", this.props.saisie.id);

    let self = this;
    this.setState({ inProgressBonus: true });
    fetch(appProperties.basepathServer + "/manager/note/add-bonus", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressBonus: false });
        if (data.result_code === 1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "FicheSaisie_28" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });

          self.listelevesWithoutNote(1)
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgressBonus: false });
        alert("There was an error added.");
      });
  }
  handleClickDelete = () => {
    var self = this;
    var eleve = this.state.elevesWithNote[this.state.deletedEleveIndex];
    this.setState({ progressDelete: true });
    var formData = new FormData(); // Currently empty
    formData.append("id", eleve.note.id);
    fetch(
      appProperties.basepathServer +
      "/manager/note/delete-note",

      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ progressDelete: false, openConfirmDelete: false });
        if (data.result_code === 1) {
          if (eleve != null && eleve.note != null) {
            var w = [...this.state.elevesWithNote];
            w.splice(this.state.deletedEleveIndex, 1);
            //eleve.note = null;
            var wn = this.state.elevesWithoutNote;
            wn.push(eleve)
            wn.sort((item1, item2) => {
              return (item1.user != null && item1.user.name != null &&
                item2.user != null && item2.user.name != null) ?
                item1.user.name.localeCompare(item2.user.name) : 0;
            });

            //self.setState({ elevesWithoutNote: wn, elevesWithNote: w, });
            this.setState(state => {
              state.elevesWithoutNote = wn;
              state.elevesWithNote = w;
              return state;
            });
            self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "FicheSaisie_22" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });

          } else {
            self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_25" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });

          }

        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ progressDelete: false });
        alert("There was an error added.");
      });
  };

  render() {
    const { classes, saisie } = this.props;
    const { formatMessage } = this.props.intl;
    const { expanded, elevesWithoutNote, elevesWithNote } = this.state;

    return (
      <React.Fragment>
        <div>
          <Dialog
            open={this.state.openConfirmDelete}
            onClose={this.handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{formatMessage({ id: "FicheSaisie_21" })}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {formatMessage({ id: "FicheSaisie_17" })}
              </DialogContentText>
              <div style={{ padding: '10px 5px' }}>
                {this.state.progressDelete
                  ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                  : null
                }
              </div>
            </DialogContent>
            <DialogActions>
              <Button style={{ textTransform: "none" }} onClick={this.handleCloseDelete} color="primary">
                {formatMessage({ id: "ItemEleve_2" })}
              </Button>
              <Button style={{ textTransform: "none" }} onClick={this.handleClickDelete} color="primary" autoFocus>
                {formatMessage({ id: "FicheSaisie_19" })}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullScreen
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
            TransitionComponent={Transition}
            classes={{ root: classes.dialogRoot }}
            BackdropProps={{
              classes: {
                root: classes.backdrop
              }
            }}
          >
            <AppBar className={classes.appBar}>
              <Grid container className={classes.toolbar} justify="space-between" alignItems='center'>
                <div title={saisie.id} className={classes.avatarDivtitle}>
                  <Avatar className={classes.avatarTitle}>
                    <NotesIcon />
                  </Avatar>
                </div>
                <div className="layout vertical flex">
                  <div>
                    <Typography rel="noopener noreferrer" target="_blank" color="inherit" className={classes.toolbarTitle}>
                      {saisie.enseignant.matiere.name || '#' + saisie.enseignant.matiere.name}  {formatMessage({ id: "ItemEnseignant_6" })} <b>{saisie.enseignant.classeEtablissement.name}</b>
                    </Typography>
                  </div>

                  <Typography color="inherit" className={classes.toolbarSubTitle}>
                    {formatMessage({ id: "FicheSaisie_13" })} <b>{saisie.sequence.name}</b> {formatMessage({ id: "FicheSaisie_14" })} <b>{saisie.bareme}</b>
                  </Typography>
                </div>

                <Button title="Fermer le dossier et quitter" style={{ color: 'white', textTransform: "none" }} onClick={this.handleCloseDialog}>
                  <CloseIcon />
                  {formatMessage({ id: "BonDemande_12" })}
                </Button>
              </Grid>

            </AppBar>
            <div style={{ padding: '15px', overflowY: 'auto' }}>
              <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'not-filled'} onChange={this.handleChange('not-filled')}>
                <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
                  <div style={{ position: 'relative' }}>
                    <Badge classes={{ badge: classes.fraisBadge }} badgeContent={elevesWithoutNote ? elevesWithoutNote.length : 0} color="primary">
                      <Avatar className={classes.scolariteYellow}>
                        <ThumbDownAltIcon />
                      </Avatar>
                    </Badge>
                    {this.state.progressScolarite === 1 &&
                      <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
                    }
                  </div>

                  <div>
                    <Typography className={classes.heading}>{formatMessage({ id: "FicheSaisie_12" })}</Typography>
                    <Typography className={classes.secondaryHeading}>{formatMessage({ id: "FicheSaisie_11" })}</Typography>
                  </div>
                  {(expanded === 'not-filled') && elevesWithoutNote.length > 0 &&
                    (
                      <div style={{ marginRight: '60px' }} className="layout horizontal center flex">
                        <div className="flex">
                          {this.state.inProgress
                            ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                            : null
                          }
                        </div>
                        <Button
                          disabled={this.state.inProgress || !!saisie.status}
                          variant="contained"
                          onClick={this.handleAdd}
                          className={classes.buttonPrincipale}>
                          {formatMessage({ id: "FicheSaisie_8" })}
                        </Button>
                      </div>
                    )
                  }
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: '10px 0 10px 20px' }} className="layout vertical">
                  {(this.state.progressEleve === 0 && elevesWithoutNote.length > 0 &&
                    <React.Fragment>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <CustomTableCell onClick={this.doOrderByName} title={formatMessage({ id: "ListCahierMatiere_13" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "RapportIndisciplineEleve_2" })}</span></div></CustomTableCell>
                            <CustomTableCell onClick={this.doOrderByMatricule} title={formatMessage({ id: "RapportIndisciplineEleve_6" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "RapportIndisciplineEleve_3" })}</span></div></CustomTableCell>
                            <CustomTableCell align="left">{formatMessage({ id: "FicheSaisie_9" })}<span style={{ fontSize: '13px', fontWeight: '400' }}>{formatMessage({ id: "FicheSaisie_10" })}</span></CustomTableCell>

                          </TableRow>
                        </TableHead>
                        {((elevesWithoutNote && elevesWithoutNote.length > 0) &&
                          <TableBody>
                            {elevesWithoutNote.map((row, index) => (
                              <TableRow className={classes.row} key={row.id}>
                                <CustomTableCell component="th" scope="row">
                                  <div>
                                    <div>
                                      <a tabIndex="-1" style={{ fontWeight: "600", fontSize: '12px' }} className="no-underline capitalize" title={row.user.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.user)}>
                                        {row.fullname || '@' + row.user.name}
                                      </a>
                                    </div>
                                    <div>{row.user.email}</div>
                                  </div>

                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  {row.matricule}
                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  <TextField
                                    name={row.id}
                                    id={"eleve_" + row.id}
                                    className={classNames(classes.margin, classes.textField)}
                                    variant="outlined"
                                    label={formatMessage({ id: "FicheNoteInput_4" })}
                                    fullWidth
                                    type="number"
                                    margin="dense"
                                    disabled={!!saisie.status}
                                    readOnly={!!saisie.status}
                                    onChange={this.handleChangeFor}
                                    InputProps={{
                                      inputProps: { min: 0, max: saisie.bareme },
                                      endAdornment: <InputAdornment position="end">/{saisie.bareme}</InputAdornment>,
                                    }}
                                  />
                                </CustomTableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                      <div style={{ padding: '10px' }} className="layout horizontal center">
                        <div className="flex">
                          {this.state.inProgress
                            ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                            : null
                          }
                        </div>
                        <Button
                          disabled={this.state.inProgress || !!saisie.status}
                          variant="contained"
                          onClick={this.handleAdd}
                          className={classes.buttonPrincipale}>
                          {formatMessage({ id: "FicheSaisie_8" })}
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.progressEleve === 1 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>
                        <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                      </div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_Load" })}
                      </div>
                    </div>
                  )}

                  {((this.state.progressEleve === 2 || (this.state.progressEleve === 0 && elevesWithoutNote.length === 0)) &&
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "RapportIndisciplineEleve_1" })}
                      </div>
                    </div>
                  )}
                  {this.state.progressEleve === 3 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_LoadError" })}
                      </div>
                    </div>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <br />
              <br />

              <div style={{ padding: '10px 10px', border: '1px dashed grey' }}>
                <div>
                  <Typography className={classes.heading}>{formatMessage({ id: "FicheSaisie_23" })}</Typography>
                  <Typography className={classes.secondaryHeading}>{formatMessage({ id: "FicheSaisie_24" })}</Typography>
                </div>

                <div className="layout horizontal">

                  <TextField
                    name={"bonus"}
                    id={"bonus"}
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label={formatMessage({ id: "FicheSaisie_25" })}
                    helperText={this.state.inProgressBonus ? <span>{formatMessage({ id: "FicheSaisie_29" })}</span> : <span>{formatMessage({ id: "FicheSaisie_26" }, { bareme: saisie.bareme })}</span>}
                    fullWidth
                    type="number"
                    margin="dense"
                    disabled={!!saisie.status}
                    readOnly={!!saisie.status || this.state.inProgressBonus}
                    onChange={this.handleChangeBonus}
                    InputProps={{
                      inputProps: { min: (-1 * saisie.bareme), max: saisie.bareme },
                      endAdornment: <InputAdornment position="end">/{saisie.bareme}</InputAdornment>,
                    }}
                  />

                  <span style={{ marginLeft: "20px" }}>
                    <Button
                      disabled={this.state.inProgressBonus || !!saisie.status}
                      variant="contained"
                      onClick={this.handleAddBonus}
                      className={classes.buttonPrincipale}>
                      {formatMessage({ id: "FicheSaisie_27" })}
                    </Button>
                  </span>
                </div>

              </div>

              <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'filled'} onChange={this.handleChange('filled')}>
                <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
                  <div style={{ position: 'relative' }}>
                    <Badge classes={{ badge: classes.fraisBadge }} badgeContent={elevesWithNote ? elevesWithNote.length : 0} color="primary">
                      <Avatar className={classes.scolariteGreen}>
                        <ThumbUpAltIcon />
                      </Avatar>
                    </Badge>
                    {this.state.progressScolarite === 1 &&
                      <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
                    }
                  </div>

                  <div>
                    <Typography className={classes.heading}>{formatMessage({ id: "FicheSaisie_7" })}</Typography>
                    <Typography className={classes.secondaryHeading}>{formatMessage({ id: "FicheSaisie_6" })}</Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: '10px 0 10px 20px' }} className="layout vertical">
                  {(this.state.progressEleve === 0 && elevesWithNote.length > 0 &&
                    <React.Fragment>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <CustomTableCell onClick={this.doOrderByName1} title={formatMessage({ id: "ListNoteEnseignantView_10" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "RapportIndisciplineEleve_2" })}</span></div></CustomTableCell>
                            <CustomTableCell onClick={this.doOrderByMatricule1} title={formatMessage({ id: "ListNoteEnseignantView_11" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "RapportIndisciplineEleve_3" })}</span></div></CustomTableCell>
                            <CustomTableCell onClick={this.doOrderByNote1} title={formatMessage({ id: "ListNoteEnseignantView_12" })} style={{ cursor: 'pointer' }} align="left"><div className="layout horizontal center" ><SortIcon /><span>{formatMessage({ id: "ListNoteEnseignantView_15" })}</span></div></CustomTableCell>
                            <CustomTableCell style={{ cursor: 'pointer' }} align="left"><div className="layout horizontal center" ><span>{formatMessage({ id: "FicheSaisie_20" })}</span></div></CustomTableCell>

                          </TableRow>
                        </TableHead>
                        {((elevesWithNote && elevesWithNote.length > 0) &&
                          <TableBody>
                            {elevesWithNote.map((row, index) => (
                              <TableRow className={classes.row} key={row.id}>
                                <CustomTableCell component="th" scope="row">
                                  <div>
                                    <div>
                                      <a style={{ fontWeight: "600", fontSize: '12px' }} className="no-underline capitalize" title={row.user.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.user)}>
                                        {row.fullname || '@' + row.user.name}
                                      </a>
                                    </div>
                                    <div>{row.user.email}</div>
                                  </div>

                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  {row.matricule}
                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  <FicheNoteInput key={index} onUpdateItem={this.onUpdateItem} saisie={this.props.saisie} etablissement={this.state.etablissement} position={index} row={row}  {...deleteClassInProps(this.props)} />
                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  <Button
                                    disabled={this.state.inProgress || !!saisie.status}
                                    variant="contained"
                                    title={formatMessage({ id: "FicheSaisie_15" })}
                                    onClick={() => this.handleDelete(index)}
                                    className={classes.buttonPrincipale}>
                                    {formatMessage({ id: "FicheSaisie_15" })}
                                  </Button>
                                </CustomTableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                    </React.Fragment>
                  )}
                  {this.state.progressEleve === 1 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>
                        <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                      </div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_Load" })}
                      </div>
                    </div>
                  )}

                  {((this.state.progressEleve === 2 || (this.state.progressEleve === 0 && elevesWithNote.length === 0)) &&
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "RapportIndisciplineEleve_1" })}
                      </div>
                    </div>
                  )}
                  {this.state.progressEleve === 3 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_LoadError" })}
                      </div>
                    </div>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </Dialog>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">
            {this.state.result_add === 1 && (<span>{formatMessage({ id: "FicheSaisie_5" })}</span>)}
            {this.state.result_add === 0 && (<span>{formatMessage({ id: "FicheSaisie_4" })}</span>)}
            {this.state.result_add === -1 && (<span>{formatMessage({ id: "FicheSaisie_3" })}</span>)}
            {this.state.result_add === -2 && (<span>{formatMessage({ id: "FicheSaisie_2" })}</span>)}
            {this.state.result_add === -100 && (<span>{formatMessage({ id: "ListMoyenne_25" })}</span>)}

          </span>}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarClose}
            >
              {formatMessage({ id: "FicheSaisie_1" })}
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </React.Fragment>
    );
  }
}
FicheSaisie.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  saisie: PropTypes.object.isRequired,
  etablissement: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(FicheSaisie)));
