import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import './App.css';
import HomeConnected from './components/HomeConnected';
import HomeOffline from './components/HomeOffline';
import { appProperties, isLogged, isUserAdmin } from './components/LdUtils';
import Page404 from './components/Page404';
import ToolBar from './components/ToolBar';
import AppFooter from './components/AppFooter';
import EtablissementIndex from './etablissement/EtablissementIndex';
import ManagerIndex from './manager/ManagerIndex';
import UserIndex from './user/UserIndex';
import CircularProgress from '@material-ui/core/CircularProgress';

//https://blueprintjs.com/docs/#datetime/dateinput
const theme = createMuiTheme({
  typography: { useNextVariants: true },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1200
    }
  },
  color: {
    primaryColor: "#2196f3",
    primaryDark: "#1E88E5",
    primaryAccent: "#00bcda",

  },
  palette: {
    primary: { main: '#2196f3' }, // Purple and green play nicely together.
  },

});

const useStyles = theme => ({
  rootLogged: {
    flexGrow: 1,
  },

  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },
});

class App extends Component {
  state = {
    currentUser: null,
  }
  constructor(props) {
    super(props);
    let user = localStorage.getItem(appProperties.key_user) || null;

    this.state = {
      storeUser: JSON.parse(user),
      currentUser: null,
      progressLoad: true,
    };
    //console.log("User view",this.props)

  }

  componentDidMount() {
    this.getUserBackend();
    document.addEventListener('storage-change', this._handleStorageChange.bind(this));
    let self = this;
    var newValue = '';
    window.addEventListener('storage', function (e) {
      console.log("Storage change");
      console.log(e);
      if (e != null) {
        if (e.key === appProperties.key_user) {
          newValue = e.newValue || null;
          self.setState({ currentUser: JSON.parse(newValue) });
        }
      }
    });
  }
  componentWillUnmount() {
    document.removeEventListener('storage-change', this._handleStorageChange.bind(this));
  }
  _handleStorageChange(event) {
    if (event && event.detail) {
      if (event.detail.key === appProperties.key_user) this.setState({ currentUser: event.detail.value });
    }
  }

  getUserBackend() {
    let self = this;
    this.setState({ progressLoad: 1 });
    var url = appProperties.basepathServer + "/rest-user/get";
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          localStorage.setItem(appProperties.sessionId, data.sid);
          localStorage.setItem(appProperties.key_user, JSON.stringify(data.user));
          // fireWithObject(document,'storage-change',{key:appProperties.key_user,value:data.user});
          self.setState({ progressLoad: 0, currentUser: data.user });
        } else {
          self.setState({ progressLoad: 0 });
        }

      })
      .catch((err) => {
        self.setState({ progressLoad: 0, currentUser: this.state.storeUser });
      });
  }


  render() {
    const { classes } = this.props;
    const redirectUrl = window.location.pathname + "" + window.location.search;
    return (
      <MuiThemeProvider theme={theme}>
        {this.state.progressLoad === 0 && <div className="App">
          <Router>
            <div>
              <ToolBar currentUser={this.state.currentUser} />
              <div className="App-content">
                <div className="layout vertical">
                  <div className="layout vertical" style={{ minHeight: "calc(100vh - 128px)" }}>
                    <Switch style={{ minHeight: "calc(100vh - 128px)" }} className="layout vertical">
                      {/* <Route  path="/user" component={UserIndex}/> */}
                      <Route path="/user" render={(props) => {
                        return (
                          <React.Fragment>
                            <UserIndex {...props} />
                            <AppFooter />
                          </React.Fragment>
                        ); //Dans ceci on genere la page d'acceuil  site
                      }} />

                      <Route path="/administration" render={(props) => {
                        if (isUserAdmin(this.state.currentUser)) {
                          return (<ManagerIndex {...props} />); //Dans ceci on genere la page d'acceuil  site
                        } else {
                          return (<Page404 {...props} />);
                        }
                      }} />

                      <Route path="/page/:code" render={(props) => {
                        if (isLogged(this.state.currentUser)) {
                          return (<EtablissementIndex  {...props} />); //Dans ceci on genere la page d'acceuil  site
                        } else {
                          return (<Redirect to={{ pathname: '/user/login', search: '?redirect_url=' + encodeURIComponent(redirectUrl), state: {} }} />)
                        }
                      }} />
                      <Route exact path="/page/:code" render={(props) => {
                        /* TODO: voir l'utilisation de redirect */
                        return (<Redirect to={"/page/" + props.match.params.code + "/dashboard"} />)
                      }} />


                      <Route exact path="/" render={(props) => {

                        if (this.state.currentUser == null) {
                          return (
                            <React.Fragment>
                              <HomeOffline {...props} />
                              <AppFooter />
                            </React.Fragment>
                          ); //Dans ceci on genere la page d'acceuil  site
                        } else {
                          return (
                            <React.Fragment>
                              <HomeConnected {...props} />
                              <AppFooter />
                            </React.Fragment>
                          );
                        }
                      }} />

                    </Switch>
                  </div>

                  {/* <AppFooter /> */}
                </div>
              </div>

            </div>
          </Router>
        </div>}
        {this.state.progressLoad === 1 && (
          <div style={{ margin: '150px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
            <div style={{ padding: '15px' }}>
              <CircularProgress thickness={1} size={125} className={classes.fabProgress} />
            </div>
            {/* <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
              Chargement en cours...
            </div> */}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}
App.propTypes = {
  classes: PropTypes.object.isRequired,
  /*theme: PropTypes.object.isRequired,*/

};

export default withStyles(useStyles)(App);

//export default App;

/*^16.7.0 */
