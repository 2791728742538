import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import {
  appProperties,
  computeNbMinutes,
  deleteClassInProps,
  gmDate,
  isObjectValueEquals
} from "../../../components/LdUtils";
import BandeView from "./BandeView";

// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ItemBande from "./ItemBande";
import Paper from "@material-ui/core/Paper";
import LdPagination from "../../../components/LdPagination";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    width: "100%"
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: "15px 0",
    marginTop: "15px"
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400,
    paddingBottom: "15px"
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: "12px",
    fontWeight: "500",
    transform: "translate(14px, -6px) scale(1)"
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: "7.5px 23px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */
});

class ListBandeView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,
      labelWidth: 0,
      jours: [],

      horaires: [],
      lastHour: "",
      minDuree: -1,
      selectedView: 1
    };
    this.doUpdateGradual = this.doUpdateGradual.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);

    this._handleNewBande = this._handleNewBande.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }
  handleChangeView(value) {
    this.setState({ selectedView: value });
  }

  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listBande(page);
  };
  _handleNewBande = event => {
    this.setState(state => {
      state.datas = [event.detail.value, ...state.datas];
      return state;
    });
    if (this.state.selectedView === 1) {
      var self = this;
      this.setState({ selectedView: 2 }, () => {
        self.setState({ selectedView: 1 });
      });
    }
  };
  onUpdateItem = (mode, item) => {
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  doUpdateGradual(lastHour, minDuree, jour) {
    var self = this;
    if (lastHour >= this.state.lastHour) {
      self.setState({ lastHour: lastHour, minDuree: minDuree }, () => {
        var lasHourMin = computeNbMinutes(lastHour);
        var hourInit = computeNbMinutes("07:30");
        var array = [];
        var newDate = 0;
        do {
          newDate = hourInit + minDuree;
          array.push([gmDate(hourInit * 60), gmDate(newDate * 60)]);
          hourInit = newDate;
        } while (newDate < lasHourMin);
        self.setState({ horaires: array });
      });
    }
  }

  componentDidUpdate(prevProps) {
    var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.setCurrentPage(1);
      });
    }
  }

  componentDidMount() {
    this.listBande(this.state.currentPage);
    document.addEventListener("new-bande", this._handleNewBande.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener("new-bande", this._handleNewBande.bind(this));
  }

  listBande(page) {
    let self = this;

    this.setState({ datas: [], progress: 1 });
    var url =
      appProperties.basepathServer +
      "/manager/config-emploi-temps/list-bandes?limit=10&page=" +
      page;
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log("There was an error listed enseignant.");
      });
  }
  render() {
    const { classes } = this.props;
    const { selectedView } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <div style={{ backgroundColor: "white", padding: "15px" }}>
          <Grid
            container
            className={classes.grid}
            justify="space-between"
            alignItems="center"
          >
            <div className={classes.pageTitle} variant="div" component="div">
              {formatMessage({ id: "ListBandeView_5" })}
            </div>
          </Grid>

          <div className="layout horizontal end-justified">
            <ButtonGroup aria-label="Outlined primary button group">
              <Button
                onClick={() => this.handleChangeView(1)}
                style={{
                  textTransform: "none",
                  color: selectedView === 1 ? "white" : "",
                  backgroundColor:
                    selectedView === 1 ? "var(--app-primary-color)" : "white"
                }}
              >
                <ViewModuleIcon />
                {formatMessage({ id: "ListBandeView_4" })}
              </Button>
              <Button
                onClick={() => this.handleChangeView(0)}
                style={{
                  textTransform: "none",
                  color: selectedView === 0 ? "white" : "",
                  backgroundColor:
                    selectedView === 0 ? "var(--app-primary-color)" : "white"
                }}
              >
                <ViewListIcon />
                {formatMessage({ id: "ListBandeView_3" })}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <br />
        {this.state.progress === 0 && (
          <React.Fragment>
            {selectedView === 1 && (
              <div
                style={{
                  borderTop: "1px solid #EAEAEA",
                  borderRight: "1px solid #EAEAEA",
                  backgroundColor: "white"
                }}
                className="layout horizontal"
              >
                <div
                  style={{ borderLeft: "1px solid #EAEAEA" }}
                  className="layout vertical"
                >
                  <div
                    style={{
                      fontWeight: "400",
                      padding: "10px",
                      fontSize: "12px",
                      borderBottom: "1px solid #EAEAEA"
                    }}
                  >
                    {formatMessage({ id: "ListBandeView_2" })}
                  </div>
                  {this.state.horaires.map((row, index) => (
                    <div
                      key={"horaire_" + row.id}
                      className="layout vertical center-center"
                      style={{
                        fontWeight: "400",
                        fontSize: "10px",
                        marginBottom: "-1px",
                        textAlign: "center",
                        height: (this.state.minDuree * 4) / 5,
                        borderBottom: "1px solid #EAEAEA"
                      }}
                    >
                      <span>{row[0] + " - " + row[1]}</span>
                    </div>
                  ))}
                </div>
                {[1, 2, 3, 4, 5, 6, 7].map((i, index) => (
                  <div
                    key={"jour_" + i}
                    className="flex"
                    style={{ borderLeft: "1px solid #EAEAEA" }}
                  >
                    {/* <div className="layout horizontal center-center" style={{height:'35px',borderBottom:'1px solid #EAEAEA',textAlign:'center',}}>
                        <span style={{fontWeight:'100',color:'#b1b1b1'}}>{this.getJoursLabel(i)}</span>
                      </div> */}
                    <div>
                      <BandeView
                        onMaxGradual={this.doUpdateGradual}
                        jour={i}
                        annee={this.props.annee}
                        etablissement={this.props.etablissement}
                        emploiTemps={this.props.emploiTemps}
                        {...deleteClassInProps(this.props)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
            {selectedView === 0 && (
              <div>
                <Paper elevation={0} className={classes.root}>
                  <div style={{ marginTop: "15px" }}>
                    {this.state.datas.map((row, index) => (
                      <ItemBande
                        key={index}
                        onUpdateItem={this.onUpdateItem}
                        annee={this.state.annee}
                        etablissement={this.state.etablissement}
                        position={index}
                        row={row}
                        {...deleteClassInProps(this.props)}
                      />
                    ))}
                  </div>
                </Paper>
                <LdPagination
                  onShowPage={this.setCurrentPage}
                  pages={this.state.pages}
                />
              </div>
            )}
          </React.Fragment>
        )}

        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 0 0 0",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 ||
          (this.state.progress === 0 && this.state.datas.length === 0)) && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 0 0 0",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListBandeView_1" })}
            </div>
          </div>
        )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 0 0 0",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              {formatMessage({ id: "ListClasseAnneeView_4" })}
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(ListBandeView));
