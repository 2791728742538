import Avatar from "@material-ui/core/Avatar";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  FormattedDate,
  FormattedTime,
  injectIntl,
  intlShape
} from "react-intl";
import ProgressiveImage from "react-progressive-image";
import {
  appProperties,
  defaultAvatar,
  isObjectValueEquals,
  userProfileUrl
} from "../../../components/LdUtils";
import Button from "@material-ui/core/Button";


import LinearProgress from "@material-ui/core/LinearProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import { Printd } from 'printd'
import printJS from 'print-js'
import { withSnackbar } from "notistack";
import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";

const styles = theme => ({
  root: {
    padding: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  content: {
    display: "flex"
    // "-ms-flex": "1 1 0.000000001px",
    // "-webkit-flex": "1",
    // "flex": "1",
    // "-webkit-flex-basis": "0.000000001px",
    // "flex-basis": "0.000000001px"
  },
  contentImage: {
    marginRight: "10px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none"
    // padding: "0 10px 0 10px"
  },
  email: {
    fontSize: "13px",
    fontWeight: "400"
    // padding: "0 10px 0 10px"
  },
  bigAvatar: {
    backgroundColor: "lightgray",
    border: "2px solid white",
    width: "70px !important",
    height: "70px !important",
    borderRadius: "0 !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  grid: {},
  matiereContainer: {
    border: "1px dashed grey",
    padding: "0 5px",
    margin: "5px 5px 0 0",
    borderRadius: "10px"
  },
  chip: {
    borderRadius: "0 !important"
  }
});

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
    <FormattedTime hour="numeric" minute="numeric" value={date} />
  </span>
));

class ItemPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      position: this.props.position,
      row: this.props.row,
      openConfirm: false,
      progressDelete: false,
      payment: null
    };

    this.handleClickDeletePaiement = this.handleClickDeletePaiement.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
  }

  componentDidUpdate(prevProps) {
    //var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => { });
    }
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      //if(JSON.stringify(this.props.row) !==  JSON.stringify(prevProps.row)){
      this.setState({ row: this.props.row }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }

  handleClickOpen = () => {
    this.setState({ openConfirm: true });
  };

  handleClose = () => {
    this.setState({ openConfirm: false });
  };
  handleClickDeletePaiement = () => {
    var self = this;
    this.setState({ progressDelete: true });
    fetch(
      appProperties.basepathServer +
      "/manager/scolarite/delete-paiement?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.setState({ progressDelete: false });
          self.handleClose();
          self.props.onUpdateItem("delete", { position: self.state.position });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ progressDelete: false });
        alert("There was an error added.");
      });
  };

  handlePrint = url => {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    // var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        }finally{
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }
  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/*self.props.intl.formatMessage({ id: "ListEleveView_8" })*/}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon /> {/*self.props.intl.formatMessage({ id: "ListEleveView_10" })*/}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      persist: true,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {formatMessage({ id: "ItemDossierTypeFrais_17" }) +
              (this.props.row != null ? this.props.row.amount : 0 || 0) +
              " F CFA ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemEleve_1" })}
            </DialogContentText>
            <div style={{ padding: "10px 5px" }}>
              {this.state.progressDelete ? (
                <div className={classes.progress}>
                  <LinearProgress variant="query" />
                </div>
              ) : null}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "none" }}
              onClick={this.handleClose}
              color="primary"
            >
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button
              style={{ textTransform: "none" }}
              onClick={this.handleClickDeletePaiement}
              color="primary"
              autoFocus
            >
              {formatMessage({ id: "ItemEleve_3" })}
            </Button>
          </DialogActions>
        </Dialog>
        <div>
          <div
            style={{ padding: "10px 10px 10px 10px" }}
            className="layout horizontal"
          >
            <div style={{ marginRight: "10px" }}>
              <ProgressiveImage
                src={this.props.row.eleve.pathPhoto}
                placeholder={defaultAvatar}
              >
                {(src, loading) => (
                  <Avatar alt="" src={src} className={classes.bigAvatar} />
                )}
              </ProgressiveImage>
            </div>
            <div className="flex layout vertical">
              <div style={{ fontSize: "15px", fontWeight: "600" }}>
                <a
                  className="no-underline capitalize"
                  title={'@' + this.props.row.eleve.user.name}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={userProfileUrl(this.props.row.eleve.user)}
                >
                  {this.props.row.eleve.fullname || '@' + this.props.row.eleve.user.name}
                </a>
              </div>
              <div style={{ fontSize: "13px", fontWeight: "400" }}>
                {formatMessage({ id: "ItemEnseignant_6" })}{" "}
                <b>
                  {this.props.row.eleve.classeEtablissement.name}
                </b>{" "}
                {formatMessage({ id: "ItemClasseAnnee_15" })}
                <b>{this.props.row.eleve.matricule}</b>
              </div>
              <div style={{ fontSize: "13px", fontWeight: "400" }}>
                <b
                  style={{
                    color: "var(--paper-green-500)",
                    marginRight: "5px"
                  }}
                >
                  {this.props.row.type.name}
                </b>
                (<b>{this.props.row.type.amount} F CFA</b>)
              </div>
              <div style={{ fontSize: "13px", fontWeight: "400" }}>
                {formatMessage({ id: "ItemPayment_1" })}{" "}
                <b style={{ color: "var(--paper-orange-500)" }}>
                  {this.props.row.amount} F CFA
                </b>{" "}
                {formatMessage({ id: "ItemPayment_2" })}{" "}
                <i>
                  <PostDate date={this.props.row.created} />
                </i>
                {this.props.row.creator != null && (
                  <span>
                    &nbsp; {formatMessage({ id: "ItemAlert_msg14" })}
                    <a
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "500",
                        fontSize: "11px"
                      }}
                      className="no-underline capitalize"
                      title={this.props.row.creator.name}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={userProfileUrl(this.props.row.creator)}
                    >
                      {this.props.row.creator.name}
                    </a>
                  </span>
                )}
              </div>
            </div>
            <div className="layout horizontal center">
              {/* <Button  onClick={()=>this.printPdf(appProperties.basepathServer+'/manager/scolarite/print-paiement?id='+this.props.row.id)} title="Imprimer le reçu de versement"  style={{textTransform:'none',borderRadius:'0 !important',marginRight:'10px'}} variant="outlined" size="small" className={classes.margin}>
                   Imprimer
              </Button> */}
              <Button

                onClick={() => this.handlePrint(appProperties.basepathServer + "/manager/scolarite/print-paiement?id=" + this.props.row.id)}
                title={formatMessage({ id: "ItemDossierTypeFrais_4" })}
                style={{
                  textTransform: "none",
                  borderRadius: "0 !important",
                  marginRight: "10px",
                  color: "var(--app-primary-color)"
                }}
                variant="outlined"
                size="small"
                className={classes.margin}
              >
                {formatMessage({ id: "ItemDossierTypeFrais_3" })}
              </Button>
              <Button
                onClick={this.handleClickOpen}
                title={formatMessage({ id: "ItemDossierTypeFrais_1" })}
                style={{
                  textTransform: "none",
                  borderRadius: "0 !important",
                  marginRight: "10px"
                }}
                variant="outlined"
                size="small"
                className={classes.margin}
              >
                {formatMessage({ id: "ItemDossierTypeFrais_1" })}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ItemPayment.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemPayment)));
