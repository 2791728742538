import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ListRoleView from "../view/role/ListRoleView";
import { deleteClassInProps } from "../../components/LdUtils";
import { injectIntl } from "react-intl";

const useStyles = theme => ({
  root: {
    display: "block", // Fix IE 11 issue.
    paddingRight: "10px"
  },
  titleBlock: {
    textAlign: "left"
    // paddingLeft:"10px",
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  }
});

class RoleController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etablissement: this.props.etablissement
    };
    //this.ajouterReseauEtablissViewRef = React.createRef();
    //this.ListReseauViewRef = React.createRef();
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.root}>
        <div className={classes.titleBlock}>
          <div className={classes.pageTitle} variant="div" component="div">
            {formatMessage({ id: "RoleController_Title" })}
          </div>
          <div className={classes.pageSubtitle} variant="div" component="div">
            {formatMessage({ id: "RoleController_SubTitle" })}
          </div>
        </div>
        {/* {this.state.etablissement.id} */}
        <ListRoleView
          etablissement={this.state.etablissement}
          access="admin"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="coordo"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="manager"
          {...deleteClassInProps(this.props)}
        />

        <ListRoleView
          etablissement={this.state.etablissement}
          access="inscription"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="scolarite"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="note"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="discipline"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="cahiertexte"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="planning"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="stock"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="stockR"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="comptable"
          {...deleteClassInProps(this.props)}
        />
        <ListRoleView
          etablissement={this.state.etablissement}
          access="chefComptable"
          {...deleteClassInProps(this.props)}
        />
      </div>
    );
  }
}

RoleController.propTypes = {
  classes: PropTypes.object.isRequired,
  etablissement: PropTypes.object.isRequired
};

export default withStyles(useStyles)(injectIntl(RoleController));
