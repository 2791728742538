import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import { appProperties, randomColor } from "../../../components/LdUtils";
import AjouterSequenceView from "./AjouterSequenceView";
import ItemSequence from "./ItemSequence";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    width: "100%",
    background: "none"
    // marginTop: theme.spacing.unit * 3,
  },
  main: {
    padding: "0 15px",
    // marginTop:'15px',
    backgroundColor: "#FAFAFA",
    marginLeft: "45px"
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 500,
    marginBottom: "15px"
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  }
});

class listSequenceView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      trimestre: this.props.trimestre,
      currentPage: 1
    };
    this._handleNewSequence = this._handleNewSequence.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }

  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listSequence(page);
  };
  _handleNewSequence = value => {
    this.setState(state => {
      state.datas = [value, ...state.datas];
      return state;
    });
  };
  onUpdateItem = (mode, item) => {
    console.log("intercept sequence", mode);
    console.log(item);
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  componentDidMount() {
    this.listSequence(this.state.currentPage);
  }

  listSequence(page) {
    let self = this;

    this.setState({ datas: [] });

    // fetch(appProperties.basepathServer+ '/manager/modele-bulletin/list?limit=5&page='+page, {
    fetch(
      appProperties.basepathServer +
        "/manager/annee-scolaire/sequences?id=" +
        this.state.trimestre.id +
        "&limit=5&page=" +
        page,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        console.log("There was an error listed.");
      });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    //var self = this;
    return (
      <div className={classes.main}>
        <div>
          <AjouterSequenceView
            addNewTrimestre={this._handleNewSequence}
            trimestreId={this.state.trimestre.id}
            {...deleteClassInProps(this.props)}
          />
        </div>

        <Paper elevation={0} className={classes.root}>
          <div className={classes.pageTitle} variant="div" component="div">
            {formatMessage({ id: "ListSequenceView_1" })}
          </div>
          <div>
            {this.state.datas.map((row, index) => (
              <ItemSequence
                color={randomColor()}
                key={index}
                onUpdateItemSequence={this.onUpdateItem}
                {...deleteClassInProps(this.props)}
                position={index}
                row={row}
              />
            ))}
          </div>
        </Paper>
        <LdPagination
          onShowPage={this.setCurrentPage}
          pages={this.state.pages}
        />
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(listSequenceView));
