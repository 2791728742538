import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import {
  appProperties,
  isObjectValueEquals
} from "../../../components/LdUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ItemIndiscipline from "./ItemIndiscipline";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import PrintIcon from "@material-ui/icons/Print";

import DatePicker from "react-date-picker";
import moment from "moment";
import printJS from 'print-js'
import { withSnackbar } from "notistack";


const styles = theme => ({
  root: {
    width: "100%"
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: "15px",
    marginTop: "15px",
    backgroundColor: "white"
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: "12px",
    fontWeight: "500",
    transform: "translate(14px, -6px) scale(1)"
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: "7.5px 23px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */
});

class ListIndisciplineView extends Component {
  constructor(props) {
    super(props);
    var minDate = new Date(); minDate.setHours(0, 0, 0, 0);
    var maxDate = new Date(); maxDate.setHours(23, 59, 59, 999);

    this.state = {
      datas: [],
      classes: [],
      classe: "---", //No change to null. it is used to fix bug in select.
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,

      labelWidth: 0,
      period: 1,
      minDate: minDate,
      maxDate: maxDate,

      categories: [],
      categorie: "---",
      searchValue: "",
    };
    this._handleNewIndiscipline = this._handleNewIndiscipline.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleChangePeriod = this.handleChangePeriod.bind(this);

  }

  handleChangePeriod = event => {
    let self = this;
    var period = event.target.value;
    var minDate = null;
    var maxDate = null;
    var date = new Date();
    if (period === 3) {//mois
      minDate = new Date(date.getFullYear(), date.getMonth(), 1); minDate.setHours(0, 0, 0, 0);
      maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0); maxDate.setHours(23, 59, 59, 999);

    } else if (period === 2) {//semaine
      minDate = new Date(date.setDate(date.getDate() - date.getDay())); minDate.setHours(0, 0, 0, 0);
      maxDate = new Date(date.setDate(date.getDate() - date.getDay() + 6)); maxDate.setHours(23, 59, 59, 999);
    } else if (period === 1) {//semaine
      var start = new Date(); start.setHours(0, 0, 0, 0);
      var end = new Date(); end.setHours(23, 59, 59, 999);
      minDate = start;
      maxDate = end;
    } else if (period === 0) {//semaine
      self.setState({ period: period, minDate: null, maxDate: null }, state => {
      });
    }
    if (period !== 0) {
      self.setState({ period: period, minDate: minDate, maxDate: maxDate }, state => {
        self.setCurrentPage(1);
      });
    }
  };

  handleStartDateChange(date) {
    var self = this;
    if (date != null) date.setHours(0, 0, 0, 0);
    this.setState(
      {
        minDate: date,
        period: 0,
      },
      state => {
        self.setCurrentPage(1);
      }
    );
  }

  handleEndDateChange(date) {
    var self = this;
    if (date != null) date.setHours(23, 59, 59, 999);
    this.setState(
      {
        maxDate: date,
        period: 0,
      },
      state => {
        self.setCurrentPage(1);
      }
    );
  }


  handleSearchInputChange = event => {
    if (event.keyCode === 13) {
      //this.refreshList(event.target.value);
      this.setCurrentPage(1);
    }
  };
  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value), classe: "---", classes: [] }, (state) => {
      self.listClasses();
      self.setCurrentPage(1);
    });
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.setCurrentPage(1);
      });
    }
  }
  handleChangeClass = event => {
    console.log("change classe");
    console.log(event.target.value);
    let self = this;
    this.setState({ classe: event.target.value }, state => {
      self.setCurrentPage(1);
    });
  };
  // handleChangeClass(item) {
  //   let self = this;
  //   this.setState({ classe: (item?item:null) },(state)=>{
  //     self.setCurrentPage(1);
  //   });

  // };
  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listIndiscipline(page);
  };
  _handleNewIndiscipline = event => {
    this.setState(state => {
      state.datas = [event.detail.value, ...state.datas];
      return state;
    });
  };
  onUpdateItem = (mode, item) => {
    console.log("intercept", mode);
    console.log(item);
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  componentDidMount() {
    this.listCategorie();
    //this.listClasses();
    this.listIndiscipline(this.state.currentPage);
    document.addEventListener(
      "new-indiscipline",
      this._handleNewIndiscipline.bind(this)
    );
  }
  componentWillUnmount() {
    document.removeEventListener(
      "new-indiscipline",
      this._handleNewIndiscipline.bind(this)
    );
  }
  listClasses() {
    let self = this;
    this.setState({ classes: [] });
    // var url =
    //   appProperties.basepathServer +
    //   "/rest-etablissement/list-classes?id=" +
    //   this.state.etablissement.id +
    //   "&limit=1000";
    var url =
      appProperties.basepathServer +
      "/manager/annee-scolaire/classes?id=" +
      this.props.annee.id +
      "&limit=100";
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [] });
      return;
    }
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ classes: data.datas });
        } else {
          self.setState({ classes: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ classes: [] });
      });
  }

  listIndiscipline(page) {
    let self = this;

    this.setState({ datas: [], progress: 1 });
    var url =
      appProperties.basepathServer +
      "/manager/indiscipline/list?etablissement=" +
      this.state.etablissement.id +
      "&limit=10&page=" +
      page;
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;
    if (
      this.state.classe &&
      this.state.classe !== "---" &&
      this.state.classe != null
    )
      url += "&classe=" + this.state.classe.classeEtablissement.id;
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    if (this.state.searchValue != null && this.state.searchValue.trim() !== "") url += "&search=" + this.state.searchValue.trim();

    if (this.state.minDate && this.state.minDate != null)
      url +=
        "&from=" + moment(this.state.minDate).format("YYYY-MM-DD HH:mm:ss");
    if (this.state.maxDate && this.state.maxDate != null)
      url += "&to=" + moment(this.state.maxDate).format("YYYY-MM-DD HH:mm:ss");

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log("There was an error listed enseignant.");
      });
  }



  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }

  handlePrint = () => {

    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    var url =
      appProperties.basepathServer +
      "/manager/indiscipline/print-etat-indiscipline?etablissement=" + this.state.etablissement.id;
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;
    if (
      this.state.classe &&
      this.state.classe !== "---" &&
      this.state.classe != null
    )
      url += "&classe=" + this.state.classe.classeEtablissement.id;
    else return;
    // if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    // if (this.state.searchValue != null && this.state.searchValue.trim() !== "") url += "&search=" + this.state.searchValue.trim();

    if (this.state.minDate && this.state.minDate != null)
      url +=
        "&from=" + moment(this.state.minDate).format("YYYY-MM-DD HH:mm:ss");
    if (this.state.maxDate && this.state.maxDate != null)
      url += "&to=" + moment(this.state.maxDate).format("YYYY-MM-DD HH:mm:ss");

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -3) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemVersement_11" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -4) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_5" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }
  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon /> {/*{self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      persist: true,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,

      scanStyles: false,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }


  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <div>
          <div>
            <div className={classes.pageTitle} variant="div" component="div">
              {formatMessage({ id: "LdEtablissementMenu_8" })}
            </div>
            <div className={classes.pageSubtitle} variant="div" component="div">
              {formatMessage({ id: "ListIndisciplineView_1" })}
            </div>
          </div>
          <div className="layout horizontal center">

            <div className="flex">
              <TextField
                id="searchValue"
                label={formatMessage({ id: "_search_student_label" })}
                style={{
                  width: "98%",
                  marginTop: "0 !important",
                  marginRight: "5px"
                }}
                value={this.state.searchValue}
                placeholder={formatMessage({ id: "_search_student_placeholder" })}
                onChange={(event) => this.setState({ searchValue: event.target.value })}
                onKeyDown={this.handleSearchInputChange}

                margin="normal"
              />
            </div>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "ListClasseEtablissementView_4"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.categorie}
                onChange={this.handleChangeCategorie}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListClasseEtablissementView_3" })}
                </MenuItem>
                {this.state.categories.map((row, index) => (
                  <MenuItem title={row.description} key={index} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "AjouterClasseEtablissementView_6"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.classe}
                onChange={this.handleChangeClass}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name={formatMessage({
                      id: "AjouterClasseEtablissementView_6"
                    })}
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListEleveView_1" })}
                </MenuItem>
                {this.state.classes.map((row, index) => (
                  <MenuItem key={index} value={row}>
                    {row.classeEtablissement.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Grid
            container
            className={classes.grid}
            justify="flex-end"
            alignItems="center"
          >


            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({ id: "ListVersementView_5" })}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.period}
                onChange={this.handleChangePeriod}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name={formatMessage({ id: "ListVersementView_5" })}
                    id="outlined-typefrais-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                <MenuItem value={1}>
                  {formatMessage({ id: "ListVersementView_6" })}
                </MenuItem>
                <MenuItem value={2}>
                  {formatMessage({ id: "ListVersementView_7" })}
                </MenuItem>
                <MenuItem value={3}>
                  {formatMessage({ id: "ListVersementView_8" })}
                </MenuItem>
                <MenuItem value={0}>
                  {formatMessage({ id: "ListVersementView_9" })}
                </MenuItem>
              </Select>
            </FormControl>
            {this.state.period === 0 &&
              <React.Fragment>

                <span style={{ paddingRight: "5px" }} className={classes.label}>
                  {formatMessage({ id: "ListAlertHistoryView_deb" })}
                </span>
                <DatePicker
                  // maxDate={this.state.maxDate}
                  locale="fr-FR"
                  className={classes.datePicker}
                  value={this.state.minDate}
                  onChange={this.handleStartDateChange}
                />

                <span
                  style={{ marginLeft: "20px", paddingRight: "5px" }}
                  className={classes.label}
                >
                  {formatMessage({ id: "ListAlertHistoryView_fin" })}
                </span>
                <DatePicker
                  label={formatMessage({ id: "ListAlertHistoryView_1" })}
                  className={classes.datePicker}
                  locale="fr-FR"
                  value={this.state.maxDate}
                  minDate={this.state.minDate}
                  onChange={this.handleEndDateChange}
                />

              </React.Fragment>
            }


            <Button disabled={this.state.minDate == null || this.state.maxDate == null || !(this.state.classe && this.state.classe !== "---" && this.state.classe != null)} onClick={() => this.handlePrint()} title={formatMessage({ id: "ListIndisciplineView_2" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
              <PrintIcon />
            </Button>
          </Grid>
        </div>



        {this.state.progress === 0 && (
          <React.Fragment>
            <Paper elevation={0} className={classes.root}>
              <div style={{ marginTop: "15px" }}>
                {this.state.datas.map((row, index) => (
                  <ItemIndiscipline
                    key={index}
                    onUpdateItem={this.onUpdateItem}
                    etablissement={this.state.etablissement}
                    position={index}
                    row={row}
                    {...deleteClassInProps(this.props)}
                  />
                ))}
              </div>
            </Paper>
            <LdPagination
              onShowPage={this.setCurrentPage}
              pages={this.state.pages}
            />
          </React.Fragment>
        )}

        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 ||
          (this.state.progress === 0 && this.state.datas.length === 0)) && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "RapportIndisciplineType_1" })}
              </div>
            </div>
          )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              {formatMessage({ id: "ListClasseAnneeView_4" })}
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withSnackbar(withStyles(styles)(injectIntl(ListIndisciplineView)));
