import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
// import DateTimePicker from 'react-datetime-picker';
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  FormattedDate,
  FormattedTime,
  injectIntl,
  intlShape
} from "react-intl";
import {
  appProperties,
  isObjectValueEquals,
  userProfileUrl
} from "../../../components/LdUtils";

const styles = theme => ({
  root: {
    // display: "flex",
    // justifyContent : "flex-start",
    // alignItems: "center",
    padding: "6px",
    width: "260px",
    margin: "6px"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonSimple: {
    textTransform: "none"
  },
  buttonPrincipale: {
    margin: "2px 5px",
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    margin: "2px 5px",
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none"
  },
  date: {
    fontSize: "11px",
    fontWeight: "400"
  },
  bigAvatar: {
    backgroundColor: "lightgray",
    border: "2px solid white",
    width: "100px !important",
    height: "100px !important",
    borderRadius: "0 !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },
  marginIcon: {
    margin: "0 3px",
    backgroundColor: "#F1F1F1",
    zoom: "0.70",
    "-moz-transform": "scale(0.70)"
  }
});

// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
    <FormattedTime hour="numeric" minute="numeric" value={date} />
  </span>
));

class FicheNoteInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      position: this.props.position,
      valeur: this.props.row.note.valeur, //TODO: continuer ici
      saisie: this.props.saisie,
      controlled: true,

      controlledEdit: true,
      inProgressEdit: false,
      row: this.props.row,
      modeEdit: false,
      openSnack: false
    };
    this.doCloseEdit = this.doCloseEdit.bind(this);
    this.doShowEdit = this.doShowEdit.bind(this);

    this.handleChangeValue = this.handleChangeValue.bind(this);

    this.edit = this.edit.bind(this);
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
  }
  handleChangeValue = name => event => {
    console.log("event");
    console.log(event);
    console.log(event.target);
    var value = event.target.value;
    if (value > this.props.saisie.bareme) {
      value = this.props.saisie.bareme;
      event.target.value = value;
    }
    if (value < 0) {
      value = 0;
      event.target.value = value;
    }
    if (event.target.value === "") value = 0;
    this.setState({ valeur: parseFloat(value) });
  };

  doCloseEdit(event) {
    this.setState({ modeEdit: false });
  }

  doShowEdit(event) {
    this.setState({ modeEdit: true });
  }

  handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnack: false });
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => { });
    }
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        //TODO: remplacer la ligne en bas
        self.setState({ valeur: this.props.row.note.valeur });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }

  edit = () => {
    var formData = new FormData(); // Currently empty

    this.setState({ controlledEdit: true, inProgressEdit: true });

    formData.append("valeur", this.state.valeur);
    formData.append("id", this.props.row.note ? this.props.row.note.id : "");
    let self = this;
    fetch(appProperties.basepathServer + "/manager/note/edit", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          var eleve = this.props.row;
          eleve["note"] = data.row;
          let datas = { position: self.state.position, row: eleve };
          self.props.onUpdateItem("update", datas);
          self.setState({ openSnack: true });
        }
        self.doCloseEdit();
        self.setState({ inProgressEdit: false });
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgressEdit: false });
        alert("There was an error added.");
      });
  };

  render() {
    const { classes, row, saisie } = this.props;
    const { formatMessage } = this.props.intl;
    const validValue =
      parseFloat(this.state.value) >= 0 &&
      parseFloat(this.state.value) <= saisie.bareme;
    return (
      <React.Fragment>
        <Grid
          container
          className={classes.grid}
          justify="space-between"
          alignItems="center"
        >
          {!this.state.modeEdit && (
            <div className="layout vertical flex">
              <div>

                <span style={{ fontSize: "15px", fontWeight: "600" }}>
                  <var>
                    {row.note.valeur}

                    {(row.note.bonus != 0) && <React.Fragment>
                      <sup> {formatMessage({ id: "FicheSaisie_30" })}: {row.note.bonus > 0 && "+"} {row.note.bonus}</sup> = {row.note.exactValue}
                    </React.Fragment>}

                  </var>
                </span>
              </div>

              <div style={{ fontWeight: "400" }} className={classes.date}>
                {formatMessage({ id: "ItemAlert_msg13" })}{" "}
                <PostDate date={row.note.created} />
                {row.note.creator != null && (
                  <span>
                    &nbsp;{formatMessage({ id: "ItemAlert_msg14" })}
                    <a
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "500",
                        fontSize: "12px"
                      }}
                      className="no-underline capitalize"
                      title={row.note.creator.name}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={userProfileUrl(row.note.creator)}
                    >
                      {row.note.creator.name}
                    </a>
                  </span>
                )}
              </div>
              {row.note.modified != null && (
                <div style={{ fontWeight: "400" }} className={classes.date}>
                  {formatMessage({ id: "ListCahierMatiere_22" })}{" "}
                  <PostDate date={row.note.modified} />
                  {row.note.modificator != null && (
                    <span>
                      &nbsp;{formatMessage({ id: "ItemAlert_msg14" })}
                      <a
                        style={{
                          paddingLeft: "5px",
                          fontWeight: "500",
                          fontSize: "12px"
                        }}
                        className="no-underline capitalize"
                        title={row.note.modificator.name}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={userProfileUrl(row.note.modificator)}
                      >
                        {row.note.modificator.name}
                      </a>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {this.state.modeEdit && (
            <div className="layout horizontal center flex">
              <div className="flex">
                <TextField
                  className={classNames(classes.margin, classes.textField)}
                  variant="outlined"
                  label={formatMessage({ id: "FicheNoteInput_4" })}
                  type="number"
                  style={{ width: "100%" }}
                  margin="dense"
                  defaultValue={this.state.valeur}
                  onChange={this.handleChangeValue("valeur")}
                  InputProps={{
                    inputProps: { min: 0, max: saisie.bareme },
                    endAdornment: (
                      <InputAdornment position="end">
                        /{saisie.bareme}
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              {this.state.inProgressEdit && (
                <span style={{ padding: "0 3px" }}>
                  <CircularProgress
                    thickness={2}
                    size={32}
                    className={classes.fabProgress}
                  />
                </span>
              )}
              <IconButton
                title={formatMessage({ id: "FicheNoteInput_3" })}
                aria-label="reset"
                style={{ backgroundColor: "#F1F1F1", margin: "0 2px" }}
                onClick={this.doCloseEdit}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
              <IconButton
                title={formatMessage({ id: "ListCahierMatiere_8" })}
                aria-label="save"
                disabled={validValue}
                style={{
                  backgroundColor: "var(--app-primary-color)",
                  color: "white",
                  margin: "0 2px"
                }}
                onClick={this.edit}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            </div>
          )}
          <div>
            {!saisie.status && !this.state.modeEdit && (
              <IconButton
                title={formatMessage({ id: "FicheNoteInput_2" })}
                aria-label="editer"
                onClick={this.doShowEdit}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </Grid>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={
            <span id="message-id">
              {formatMessage({ id: "FicheNoteInput_1" })}{" "}
            </span>
          }
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarClose}
            >
              {formatMessage({ id: "FicheSaisie_1" })}
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </React.Fragment>
    );
  }
}
FicheNoteInput.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà.
export default withStyles(styles, { withTheme: true })(
  injectIntl(FicheNoteInput)
);
