import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PropTypes from "prop-types";
import React,{ Component } from "react";
import { Link } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ReactPlayer from 'react-player'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import SettingsIcon from '@material-ui/icons/Settings';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ClassIcon from '@material-ui/icons/Class';
import PersonIcon from '@material-ui/icons/Person';
import MoneyIcon from '@material-ui/icons/Money';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import NotesIcon from '@material-ui/icons/Notes';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TextsmsIcon from '@material-ui/icons/Textsms';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import FolderIcon from '@material-ui/icons/Folder';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PieChartIcon from '@material-ui/icons/PieChart';
import FunctionsIcon from '@material-ui/icons/Functions';
import OpacityIcon from '@material-ui/icons/Opacity';
import { injectIntl } from 'react-intl';


const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  pageTop: {
    backgroundColor: "white",
    backgroundImage:
      "url(//www.gstatic.com/mobilesdk/171006_mobilesdk/project_list_header.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0",
    // height: "448px",
    // height: 'calc( 100vh - 64px)',
    // marginBottom: "-90px",
    textAlign: "left"
  },
  pageFunction:{
    borderRadius:"20px",
    width:'80%',
    marginTop:'40px',
    padding:'15px',
  },

  topo: {
    paddingTop: "100px",
    paddingLeft: "200px",
    // width: "530px"
  },
  topoTitle: {
    fontStyle: 'normal',
    fontVariantLigatures: 'normal',
    fontVariantCaps: 'normal',
    fontVariantNumeric: 'normal',
    fontWeight: "500",
    fontStretch: "normal",
    fontSize: '41px',
    lineHeight: '60px',                                                                                                                                                                                                                                                                                                         
    fontFamily: '"Google Sans", sans-serif !important',

    color: "rgba(0,0,0,0.87)",
    // fontSize: "30px",
    // fontWeight: "400",
    // lineHeight: "40px",
    margin: " 0 0 8px 0",
    // paddingLeft: "20px"
    
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: '0px'
  },

  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonOutlined:{
    textTransform: 'none',
    margin:'0 5px '
  },
  dialogRoot:{
    padding:'15px 0 !important',
    
  },

});

class HomeOffline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      open: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);

  }
  handleOpen(event){
    this.setState({open:true});
  }
  
  handleClose(event){
    this.setState({open:false});
  }
 



  render() {
    const { classes } = this.props;
    const {formatMessage} = this.props.intl;

    return (
       <div style={{backgroundColor:'white'}} className="flex">

        <Dialog
          fullWidth
          maxWidth={"lg"}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="max-width-dialog-title"
          classes={{paper:classes.dialogRoot}}>
          <DialogContent>
            
            <ReactPlayer width="100%" height="460px" 
            config={{
              youtube: {
                playerVars: { showinfo: 1 }
              },
            }}
            controls={true}	
            url='' playing={this.state.open} />
            {/* https://www.youtube.com/watch?v=66mzO0AAsD8&feature=youtu.be */}
          </DialogContent>
         
        </Dialog>
        
        <Paper className={classes.pageTop} elevation={0}>
          <div style={{width:'53%'}}>
            <div className={classes.topo}>
              <Typography
                className={classes.topoTitle}
                variant="h2"
                component="h1">
               <div>Learndia Manager</div>
               <ul style={{marginTop:'0px'}}>
                <li style={{marginLeft:'5px',marginTop:'10px',fontSize:'30px',lineHeight:'35px',fontWeight:'400'}}> {formatMessage({ id: "HomeOffline_1"})} <br/></li>
                <li style={{marginLeft:'5px',marginTop:'10px',fontSize:'30px',lineHeight:'35px',fontWeight:'400'}}> {formatMessage({ id: "HomeOffline_2"})} <br/></li>
               </ul>
              </Typography>
              <div style={{paddingTop:'10px'}} className="layout horizontal center">
                <Button component={Link} to="/user/login" variant="contained" size="large" color="primary" className={classes.buttonPrincipale}>
                  {formatMessage({ id: "app.start"})}
                </Button>
                <Button size="large" onClick={this.handleOpen} color="primary" className={classes.buttonOutlined}>
                  <PlayCircleFilledIcon style={{marginRight:'5px'}} fontSize="inherit" />  {formatMessage({ id: "Look_vid"})}
                </Button>
              </div>  
            </div>
          </div>
        </Paper>
        <div style={{padding:'0 0 30px 0', margin: '110px 0 0',backgroundColor: 'var(--app-primary-color)' }} className="layout horizontal around-justified">
          <Paper className={classes.pageFunction} elevation={1}>
              <div className="layout horizontal center-justified">
                 <h2 style={{textAlign:'center',font:'500 44px/54px "Google Sans",sans-serif'}} className="" >{formatMessage({ id: "HomeOffline_Title1"})} <br/> {formatMessage({ id: "HomeOffline_Title2"})} </h2>
              </div>
              <div className="layout horizontal around-justified no-wrap">


                <div className="layout vertical">
                  <div className="layout horizontal center-center">
                    <img style={{width:'200px',filter:'invert(100%) hue-rotate(8deg) sepia(28%) brightness(200%)'}} srcset="https://firebase.google.com/images/homepage/build_2x.png 2x,https://firebase.google.com/images/homepage/build_1x.png 1x" src="/images/homepage/build_1x.png" alt="Build Illustration"/>
                  </div>
                  <div>
                    <h2 style={{textAlign:'center',font:'500 25px/30px "Google Sans",sans-serif'}} className="" >{formatMessage({ id: "HomeOffline_layout1"})} </h2>
                  </div>
                  <List  style={{marginTop:'0', padding: '0px 30px'}} className={classes.root}>
                    <ListItem style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <SettingsIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout1_1"})}  secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <CalendarTodayIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout1_2"})}  secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <ClassIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout1_3"})}  secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout1_4"})}  secondary="" />
                    </ListItem>
                  </List>
                </div>

                
                <div className="layout vertical">
                  <div className="layout horizontal center-center">
                    <img style={{width:'200px',filter:'invert(100%) hue-rotate(8deg) sepia(28%) brightness(200%)'}} srcset="https://firebase.google.com/images/homepage/quality_2x.png 2x,https://firebase.google.com/images/homepage/quality_1x.png 1x" src="/images/homepage/quality_1x.png" alt="Quality Illustration"/>
                  </div>
                  <div>
                    <h2 style={{textAlign:'center',font:'500 25px/30px "Google Sans",sans-serif'}} className="" >{formatMessage({ id: "HomeOffline_layout2"})} </h2>
                  </div>
                  <List  style={{marginTop:'0', padding: '0px 30px'}} className={classes.root}>
                    <ListItem style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <PersonAddIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout2_1"})} secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <MoneyIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout2_2"})} secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <NotesIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout2_3"})} secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <ReceiptIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout2_4"})} secondary="" />
                    </ListItem>
                    
                    <ListItem style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <DateRangeIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout2_5"})} secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <TextsmsIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout2_6"})} secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <BookmarksIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout2_7"})} secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <AccessibilityIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout2_8"})} secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <OpacityIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout2_9"})} secondary="" />
                    </ListItem>
                  </List>
                </div>
                
                
                <div className="layout vertical">
                  <div className="layout horizontal center-center">
                    <img  style={{width:'200px',filter:'invert(100%) hue-rotate(8deg) sepia(28%) brightness(200%)'}} srcset="https://firebase.google.com/images/homepage/grow_2x.png 2x,https://firebase.google.com/images/homepage/grow_1x.png 1x" src="/images/homepage/grow_1x.png" alt="Grow Illustration"/>
                  </div>
                  <div>
                    <h2 style={{textAlign:'center',font:'500 25px/30px "Google Sans",sans-serif'}} className="" >{formatMessage({ id: "HomeOffline_layout3"})}  </h2>
                  </div>
                  <List  style={{marginTop:'0', padding: '0px 30px'}} className={classes.root}>
                    <ListItem style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout3_1"})} secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <DashboardIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout3_2"})} secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <PieChartIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout3_3"})} secondary="" />
                    </ListItem>
                    <ListItem  style={{paddingLeft:'0'}}>
                      <ListItemAvatar>
                        <Avatar style={{backgroundColor:'var(--app-primary-color)'}}>
                          <FunctionsIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={formatMessage({ id: "HomeOffline_layout3_4"})} secondary="" />
                    </ListItem>
                  </List>
                </div>

                
               
             
              </div>
          </Paper>
        </div>
      </div>

    )
  }
}
HomeOffline.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(injectIntl(HomeOffline));
