import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, FormattedTime, injectIntl, intlShape } from 'react-intl';
import ProgressiveImage from 'react-progressive-image';
import ShowMore from 'react-show-more';
import { appProperties, defaultAvatar, defaultClassImage, isObjectValueEquals, userProfileUrl } from "../../../components/LdUtils";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';



const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',
    paddingTop: '10px',
    borderBottom: '1px solid #EAEAEA',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',
  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "52px !important",
    height: "52px !important",
    borderRadius: '0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  }
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
       <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));

class ItemSms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      etablissement: this.props.etablissement,
      controlled: true,
      row: this.props.row,

      progressEleve: false,
      openDialogEleve: false,
      openConfirmRessent: false,
      eleves: [],
      eleve: "---",
    };

    this.doSendSMS = this.doSendSMS.bind(this);
    this.handleCloseDialogEleve = this.handleCloseDialogEleve.bind(this);
    this.handleOpenDialogEleve = this.handleOpenDialogEleve.bind(this);
    this.handleConfirmRessent = this.handleConfirmRessent.bind(this);
    this.handleCloseRessent = this.handleCloseRessent.bind(this);
  }
  
  handleConfirmRessent = () => {
    this.setState({ openConfirmRessent: true });
  };

  handleCloseRessent = () => {
    this.setState({ openConfirmRessent: false });
  };
  handleOpenDialogEleve = () => {
    //this.props.onClose(this.props.selectedValue);
    //if (this.state.eleves == null || this.state.eleves.length === 0) return;
    this.listEleve();
    this.setState({ openDialogEleve: true });
  };

  handleCloseDialogEleve = () => {
    //this.props.onClose(this.props.selectedValue);
    this.setState({ openDialogEleve: false });
  };
  componentDidUpdate(prevProps) {
    //var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        // console.log("mise à jours de row");
        // console.log(this.props.row);
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }


  doSendSMS = () => {
    var self = this;
    this.setState({ inProgressSend: true });
    fetch(
      appProperties.basepathServer +
      "/manager/alert-sms/ressent-sms-etablissement?id=" +
      this.props.row.id + '&status=0',
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgressSend: false,openConfirmRessent:false });
        self.props.onUpdateItem("update", { position: self.props.position, row: data.row });

        if (data.result_code === 1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSmsView_9" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSmsView_10" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSmsView_11" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -4) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSmsView_13" }),
            {
              variant: "warning",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressSend: false });
        alert("There was an error added.");
      });
  }
  listEleve() {
    let self = this;
    this.setState({ eleves: [], progressEleve: true });
    if (this.props.row.type !== 2 || this.props.row.classeEtablissement == null || this.props.row.anneeScolaire == null) return;
    var url =
      appProperties.basepathServer + "/rest-eleve/list?etablissement_id=" + this.props.row.etablissement.id + "&limit=-1&mini=1&only_published=1&annee_id=" + this.props.row.anneeScolaire.id + "&classe_id=" + this.state.row.classeEtablissement.id
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ eleves: data.datas, progressEleve: false });
        } else {
          self.setState({ eleves: [], progressEleve: false });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ eleves: [], progressEleve: false });
      });
  }


  render() {
    const { classes, row, ...other } = this.props;
    const { formatMessage } = this.props.intl;

    var numeroStr = row.type === 0 ? row.phones.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + ", " + e : e;
    }, "") : "";
    const nameClasse = row.classeEtablissement != null ? row.classeEtablissement.name : ""

    return (
      <React.Fragment>

        <Dialog onUpdateItem={null} open={this.state.openDialogEleve} onClose={this.handleCloseDialogEleve} aria-labelledby="simple-dialog-title" {...other}>
          <DialogTitle id="simple-dialog-title">{formatMessage({ id: "AjouterSmsView_17" }, { classe: nameClasse })}</DialogTitle>
          <DialogContent style={{ width: '400px' }}>

            {this.state.progressEleve && <span style={{ color: 'var(--paper-amber-500)' }}>{formatMessage({ id: "AjouterSmsView_16" })}</span>}
            {!this.state.progressEleve &&
              <List>
                {this.state.eleves.map((eleve, index) => (
                  <ListItem button key={index}>
                    <ListItemAvatar title={eleve.matricule}>
                      <Avatar alt='' src={eleve.pathPhoto || defaultAvatar} className={classes.avatar} />
                    </ListItemAvatar>
                    <ListItemText primary={(eleve.fullname || '@' + eleve.user.name)} secondary={eleve.phone} />
                  </ListItem>
                ))}

              </List>
            }

          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.openConfirmRessent}
          onClose={this.handleCloseRessent}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formatMessage({ id: "ItemSms_1" })}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemSms_3" })}
            </DialogContentText>
            <div style={{ padding: '10px 5px' }}>
              {this.state.inProgressSend
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none" }} onClick={this.handleCloseRessent} color="primary">
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button style={{ textTransform: "none" }} onClick={this.doSendSMS} color="primary" autoFocus>
              {formatMessage({ id: "ItemSms_2" })}
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>


              <div>
                <Grid container className={classes.grid} wrap="nowrap" justify="flex-start" >
                  <div>

                    {row.type === 0 && (
                      <ProgressiveImage placeholder={defaultAvatar}>
                        {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                      </ProgressiveImage>
                    )}
                    {row.type === 1 && row.eleve != null && (

                      <ProgressiveImage src={row.eleve.pathPhoto} placeholder={defaultAvatar}>
                        {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                      </ProgressiveImage>
                    )}
                    {row.type === 2 && (
                      <ProgressiveImage placeholder={defaultClassImage}>
                        {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                      </ProgressiveImage>
                    )}


                  </div>
                  <div style={{ paddingLeft: "15px" }}>
                    <div className="layout horizontal">
                      <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px', whiteSpace: 'nowrap' }}>{formatMessage({ id: "ListSmsView_3" })} </span>
                      <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px', color: 'var(--app-primary-color)' }}>
                        {row.type === 0 && (
                          <span>
                            {numeroStr}
                          </span>
                        )}
                        {row.type === 2 && row.classeEtablissement != null && (
                          <span onClick={this.handleOpenDialogEleve} style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px', cursor: 'pointer', textDecoration: 'underline', color: 'var(--app-primary-color)' }}  >
                            {row.classeEtablissement.name} - ({row.anneeScolaire != null ? row.anneeScolaire.year : ""}) -
                            {formatMessage({ id: "AjouterSmsView_15" }, { count: this.props.row.phones != null ? this.props.row.phones.length : 0 })}
                          </span>
                        )}
                        {row.type === 1 && row.eleve != null && (
                          <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={row.eleve.user.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.eleve.user)}>
                            {row.eleve.fullname || '@' + row.eleve.user.name} ({row.eleve.phone}) - {row.anneeScolaire != null ? row.anneeScolaire.year : ""}
                          </a>
                        )}
                      </span>
                    </div>
                    <div className="layout horizontal">
                      <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px', whiteSpace: 'nowrap' }}>{formatMessage({ id: "ListSmsView_4" })} </span>
                      <span style={{ fontWeight: "400", paddingRight: '10px', fontSize: '12px' }}>
                        <ShowMore
                          lines={1}
                          more={formatMessage({ id: "ItemAlert_msg11" })}
                          less={formatMessage({ id: "ItemAlert_msg12" })}
                          anchorClass=''>
                          {row.message}
                        </ShowMore></span>
                    </div>
                    <div style={{ fontWeight: "400" }} className={classes.date}>{formatMessage({ id: "ItemAlert_msg13" })}
                      <PostDate date={row.created} />
                      {row.creator != null && (<span>
                        &nbsp;{formatMessage({ id: "ItemAlert_msg14" })}
                        <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.creator)}>
                          {row.creator.name}
                        </a>
                      </span>)}
                    </div>
                    <div style={{ fontSize: '12px', marginTop: '5px' }} class="layout horizontal">
                      <div style={{ marginRight: '15px' }}>
                        {row.finished && <span style={{ padding: '3px', backgroundColor: 'var(--paper-green-500)', border: '1px solid #EAEAEA', color: "white" }}>{formatMessage({ id: "ItemAlert_1" })}</span>}
                        {!row.finished && <span style={{ padding: '3px', backgroundColor: '#EAEAEA', border: '1px solid #EAEAEA' }}>{formatMessage({ id: "ItemAlert_2" })}</span>}
                      </div>

                      <div style={{ marginRight: '15px' }}>
                        {row.failed && <span style={{ padding: '3px', backgroundColor: 'var(--paper-red-500)', border: '1px solid #EAEAEA', color: "white" }}>{formatMessage({ id: "ItemAlert_3" })}</span>}
                      </div>
                      <div style={{ marginRight: '15px' }}>
                        <span style={{ padding: '3px', fontWeight: '600' }}>{formatMessage({ id: "ItemAlert_4" })}</span>
                        <span style={{ padding: '3px', color: 'var(--app-primary-color)', fontWeight: '600', fontSize: '13px' }}>{row.nbSent}</span>
                      </div>
                      <div style={{ marginRight: '15px' }}>
                        <span style={{ padding: '3px', fontWeight: '600' }}>{formatMessage({ id: "ListSmsView_5" })}</span>
                        <span style={{ padding: '3px', color: 'var(--app-primary-color)', fontWeight: '600', fontSize: '13px' }}>{row.nbPage}</span>
                      </div>

                    </div>


                  </div>

                </Grid>

              </div>

            </div>
          </div>
          <div className={classes.actions}>
            <Button
              onClick={this.handleConfirmRessent}
              variant="contained"
              size="medium"
              disabled={this.state.inProgressSend}
              className={classes.buttonPrincipale}>

              {this.state.inProgressSend ? formatMessage({ id: "ItemAlert_8" }) : (row.nbSent > 0 ? formatMessage({ id: "ItemAlert_9" }) : formatMessage({ id: "ItemAlert_10" }))}
            </Button>
            {/* {!!this.props.row.status ?
              <IconButton
                title={formatMessage({ id: "ItemAlert_11" })}
                classes={{ root: classes.disable }}
                aria-label={formatMessage({ id: "ItemAlert_11" })}
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton> :
              <IconButton
                title={formatMessage({ id: "ItemAlert_12" })}
                classes={{ root: classes.enable }}

                aria-label={formatMessage({ id: "ItemAlert_12" })}
                onClick={this.handleEnabled}
              >
                <ClearIcon fontSize="small" />
              </IconButton>} */}
          </div>
        </div>

      </React.Fragment>
    );
  }
}
ItemSms.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemSms)));
