import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import LinearProgress from "@material-ui/core/LinearProgress";
import { injectIntl } from "react-intl";
import Avatar from '@material-ui/core/Avatar';
import ProgressiveImage from "react-progressive-image";
import { defaultAvatar, userProfileUrl } from "./LdUtils";
const styles = theme => ({
  root: {},
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto  !important"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },

  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paperMenu: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  },

  progress: {
    width: "100%",
    flexGrow: 1,
    padding: "10px 0"
  },
  bigAvatar: {
    backgroundColor: "lightgray",
    border: "2px solid white",
    width: "35px !important",
    height: "35px !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  menuTitle: {
    fontSize: '15px',
    fontWeight: '500',
  },
  menuSubTitle: {

    fontSize: '13px',
    fontWeight: '400',
    lineHeight: 1.2

  }
});
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}
function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        backgroundColor: props.isSelected ? '#EAEAEA' : "#FFFFFF"
      }}
      {...props.innerProps}>
      {/* <button onClick={() => { console.log(props) }}>Voir</button>
      {props.children} */}
      <div className="layout horizontal center">
        <ProgressiveImage className={props.selectProps.classes.bigAvatarDiv} src={props.data.photo} placeholder={defaultAvatar}>
          {(src, loading) => (
            <Avatar
              alt=""
              src={src}
              className={props.selectProps.classes.bigAvatar}
            />
          )}
        </ProgressiveImage>
        <div style={{ marginLeft: '10px' }} className="layout vertical">
          <div className={props.selectProps.classes.menuTitle}>{props.data.name}</div>
          <div className={props.selectProps.classes.menuSubTitle}>{props.data.email}</div>
        </div>
      </div>
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
function ValueContainer(props) {

  return (
    <div className={props.selectProps.classes.valueContainer}>
      {/* <button onClick={() => { console.log(props); console.log(props.getValue()) }}>{props.children}</button> */}
      {/* {props.children} */}
      {/* <button onClick={() => { console.log(props) }}>Voir</button> */}

      {props.options && props.getValue().length > 0 ?
        <div className="layout horizontal center">
          <ProgressiveImage className={props.selectProps.classes.bigAvatarDiv} src={props.getValue()[0].photo} placeholder={defaultAvatar}>
            {(src, loading) => (
              <Avatar
                alt=""
                src={src}
                className={props.selectProps.classes.bigAvatar}
              />
            )}
          </ProgressiveImage>
          <div style={{ marginLeft: '10px' }} className="layout vertical">
            <a rel="noopener noreferrer" href={userProfileUrl(props.getValue()[0])} target="_blank" className={props.selectProps.classes.menuTitle}>{props.getValue()[0].name}</a>
            <div className={props.selectProps.classes.menuSubTitle}>{props.getValue()[0].email}</div>
          </div>
        </div>

        : props.children}

    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paperMenu}
      {...props.innerProps}
    >

      {props.children}
    </Paper>
  );
}
function MultiValue(props) {
  //TODO: Use this in select multiple
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}
const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer
};
class LdSelectUser extends Component {
  constructor(props) {
    super(props);
    const { formatMessage } = props.intl;
    this.state = {
      search: "",
      baseUrl: props.baseUrl,
      options: [],
      selectedOption: null,
      result: null,
      datas: [],
      inProgress: false,
      firstLoad: false,
      isSearchable: props.isSearchable || false,
      isMulti: props.isMulti || false,
      label: props.label || formatMessage({ id: "LdSelectUser_Placeholder" }),
      defaultValue: props.defaultValue || (props.isMulti ? [] : null)
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
    this.selectRef = React.createRef();
  }

  componentDidMount() {
    this.refreshList("");
  }
  componentDidUpdate(prevProps) {
    var self = this;
    if (this.props.baseUrl !== prevProps.baseUrl) {
      this.setState({ baseUrl: this.props.baseUrl }, () => {
        self.refreshList("");
      });
    }
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({
        defaultValue: this.props.defaultValue || (this.props.isMulti ? [] : null),
      }, () => {
        self.refreshList("");
      });
    }
  }
  handleSelectInputChange = event => {
    this.setState({ search: event.target.value });
    if (event.keyCode === 13) {
      this.refreshList(event.target.value);
    }
  };
  handleChange(item) {
    this.props.handleValue(item);
    this.setState({ defaultValue: this.props.isMulti ? [] : null });
  }
  refreshList(query) {
    if (query === "") return;
    let self = this;

    this.setState({ options: [], inProgress: true });
    // if(query.trim() == "") return;
    fetch(this.state.baseUrl + query, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        // if (data.result_code == 1) {
        //   if (data.datas && data.datas.length > 0) {
        //     data.datas.forEach(e => {
        //       array.push({ value: e.id, label: e.name });
        //     });
        //   }
        // }
        self.setState({
          options: data.datas ? data.datas : [],
          firstLoad: true,
          inProgress: false
        });
      })
      .catch(err => {
        console.log(err);
        self.setState({ options: [], firstLoad: true, inProgress: false });
        console.log("There was an error.");
      });
  }
  render() {
    const { classes, theme } = this.props;
    const { formatMessage } = this.props.intl;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      })
    };

    return (
      <div className={classes.root}>
        {/* see  formatCreateLabel  to customise label*/}
        <div className={classes.progress}>
          {this.state.inProgress ? <LinearProgress variant="query" /> : null}
        </div>
        <Select
          ref={this.selectRef}
          placeholder={this.state.label}
          defaultValue={this.state.defaultValue}
          onChange={this.handleChange}
          onKeyDown={this.handleSelectInputChange}
          options={this.state.options.map(option => {
            return Object.assign(
              {
                label: option.name + ' (' + option.email + ') ',
                value: option.id ? option.id : ""
              },
              option
            );
          })}
          isSearchable={this.state.isSearchable}
          isMulti={this.props.isMulti}
          components={components}
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            label: formatMessage({ id: "LdSelectUser_Search" }),
            InputLabelProps: {
              shrink: true
            }
          }}
          isClearable
        />
      </div>
    );
  }
}
LdSelectUser.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(
  injectIntl(LdSelectUser)
);
