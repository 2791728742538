import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ProgressiveImage from "react-progressive-image";
import { appProperties, defaultAvatar, isObjectValueEquals, deleteClassInProps, userProfileUrl } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LdSelect from "../../../components/LdSelect";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withSnackbar } from "notistack";
import LdImageUploader from "../../../components/LdImageUploader";

const styles = theme => ({
  root: {
    padding: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "100px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none",
    padding: "0 10px 0 10px"
  },
  email: {
    fontSize: "13px",
    fontWeight: "400",
    padding: "0 10px 0 10px"
  },
  bigAvatar: {
    width: "100px !important",
    height: "100px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "0%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  }
});

class ItemPersonnel extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      // etablissements: null,
      options: [],
      selectedOption: null,
      result: null,
      redirect: false,
      // ids: "",
      datas: [],
      dialogOpened: false,
      inProgress: false,
      position: this.props.position,
      openConfirm: false,
      progressEdit: false,
      openDelete: false,
      progressDelete: false,
      fonction: this.props.row.fonctionObject != null ? Object.assign({
        label: this.props.row.fonctionObject[this.props.intl.locale],
        value: this.props.row.fonctionObject.id,
        // id: option.id?option.id:'',
      }, this.props.row.fonctionObject) : null, //this.getDefaultType(this.props.row.fonction, false),
      controlled: true,
      isEnseignant: this.props.row.isEnseignant,
      isPermanent: this.props.row.isPermanent,
      title: this.props.row.title,
      photo: this.props.row.photo,
      pathPhoto: this.props.row.pathPhoto,
      matricule: this.props.row.matricule,
      errors: {},
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleShowEdit = this.handleShowEdit.bind(this);

    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.handleValue = this.handleValue.bind(this);
    this.handleValueFonction = this.handleValueFonction.bind(this);
    this.handeCheck = this.handeCheck.bind(this);
    this.newImageUploaded = this.newImageUploaded.bind(this);
    this.edit = this.edit.bind(this);
  }


  handleConfirmDelete = () => {
    this.setState({ openDelete: true });
  };

  handleCloseDelete = () => {
    this.setState({ openDelete: false });
  };

  handleShowEdit = () => {
    this.setState({ openConfirm: true });
  };

  handleClose = () => {
    this.setState({ openConfirm: false });
  };

  newImageUploaded(name, path) {
    // console.log(" new image uplaoded in Ajouter");
    // console.log(name);
    // console.log(path);
    this.setState({ photo: name, pathPhoto: path });
  }
  handleValue(item) {
    this.setState({ user: item });
    if (item && item != null) {
      var pathAvatar = item.photoId != null && item.photoId !== "" ? item.photo : "";
      var avatar = item.photoId;
      this.newImageUploaded(avatar, pathAvatar)
    } else {
      this.newImageUploaded("", "");
    }
  }
  handleValueFonction(fonction) {
    this.setState({ fonction: fonction ? fonction : null });
  }
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };
  handeCheck = (name, value) => e => {
    var val = {};
    val[name] = !!value ? false : true;
    this.setState(val);
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          isPermanent: self.props.row.isPermanent,
          isEnseignant: self.props.row.isEnseignant,
          title: self.props.row.title,
          photo: self.props.row.photo,
          matricule: self.props.row.matricule,
          pathPhoto: self.props.row.pathPhoto,
          fonction: self.props.row.fonctionObject != null ? Object.assign({
            label: self.props.row.fonctionObject[self.props.intl.locale],
            value: self.props.row.fonctionObject.id,
            // id: option.id?option.id:'',
          }, self.props.row.fonctionObject) : null,
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {

      });
    }
  }
  handleDelete = () => {
    this.setState({ progressDelete: true });

    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/etablissement/remove-enseignant?id=" +
      this.props.row.user.id +
      "&etablissement=" +
      this.props.row.etablissementId,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ openDelete: false, progressDelete: false });

        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ progressDelete: false });
        alert("There was an error deleted.");
      });
  };
  edit() {
    var errors = {};
    this.setState({ errors: errors });

    if (this.state.title == null || this.state.title === "") errors["title"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.fonction == null) errors["fonction"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.matricule == null) errors["matricule"] = this.props.intl.formatMessage({ id: "InputError_1" });


    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("id", this.props.row.id);
    formData.append("fonction", this.state.fonction != null ? this.state.fonction.value : "");
    formData.append("title", this.state.title);
    formData.append("matricule", this.state.matricule);
    formData.append("photo", this.state.photo);
    formData.append("is_enseignant", !!this.state.isEnseignant ? 1 : 0);
    formData.append("is_permanent", !!this.state.isEnseignant && !!this.state.isPermanent ? 1 : 0);
    let self = this;
    this.setState({ progressEdit: true });
    fetch(
      appProperties.basepathServer +
      "/manager/etablissement/edit-enseignant?etablissement=" +
      this.props.row.etablissementId,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ progressEdit: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          self.props.onUpdateItem("update", { position: self.state.position, row: data.row });

          self.handleClose();

          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemPersonnel_7" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemPersonnel_8" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ progressEdit: false });
        alert("There was an error added.");
      });
  }
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/etablissement/set-status-enseignant?status=1&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/etablissement/set-status-enseignant?status=0&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };

  getDefaultType(type, onlyLabel) {

    var array = [
      { value: 0, label: this.props.intl.formatMessage({ id: "AjouterPersonnelView_4_1" }) },
      { value: 1, label: this.props.intl.formatMessage({ id: "AjouterPersonnelView_4_2" }) },

    ];
    if (type >= 0 && type < array.length) return onlyLabel ? array[type].label : array[type];
    return null;
  }


  render() {
    const { classes, row } = this.props;
    const { formatMessage, locale } = this.props.intl;

    return (
      <React.Fragment>









        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {formatMessage({ id: "ItemPersonnel_1" }, { name: row.user.name })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemPersonnel_2" })}
            </DialogContentText>
            <div style={{ padding: '10px 5px' }}>
              {this.state.progressEdit
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="matricule">
                  {formatMessage({ id: "ItemPersonnel_12" })}
                </InputLabel>
                <Input
                  error={this.state.errors.matricule}
                  id="matricule"
                  required
                  name="matricule"
                  autoComplete="matricule"
                  defaultValue={this.state.matricule}
                  onChange={this.handleChangeInput("matricule")}
                  autoFocus
                />
                <FormHelperText>{this.state.errors.matricule ? <span className="error_field">{this.state.errors.matricule}</span> : ""}</FormHelperText>
              </FormControl>
              <FormControl margin="dense" required fullWidth>
                <div>

                  <LdSelect
                    defaultValue={this.state.fonction}
                    baseUrl={
                      appProperties.basepathServer +
                      "/manager/etablissement/load-fonction-etablissement?language=fr"
                    }
                    label={formatMessage({
                      id: "AjouterPersonnelView_4"
                    })}
                    handleValue={this.handleValueFonction}
                    firstLoad isSearchable
                    {...deleteClassInProps(this.props)}
                  />
                  {/* <LdSelectStatic
                    defaultValue={this.getDefaultType(
                      row.fonction, false
                    )}
                    options={[
                      {
                        value: 0,
                        label: formatMessage({
                          id: "AjouterPersonnelView_4_1"
                        })
                      },
                      {
                        value: 1,
                        label: formatMessage({
                          id: "AjouterPersonnelView_4_2"
                        })
                      }
                    ]}
                    label={formatMessage({
                      id: "AjouterPersonnelView_4"
                    })}
                    handleValue={this.handleValueFonction}
                    isSearchable
                    {...deleteClassInProps(this.props)}
                  /> */}
                </div>
                <FormHelperText>{this.state.errors.fonction ? <span className="error_field">{this.state.errors.fonction}</span> : ""}</FormHelperText>
              </FormControl>
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="title">
                  {formatMessage({ id: "AjouterPersonnelView_5" })}
                </InputLabel>
                <Input
                  error={this.state.errors.title}
                  id="title"
                  required
                  name="title"
                  autoComplete="title"
                  defaultValue={this.state.title}
                  onChange={this.handleChangeInput("title")}
                  autoFocus
                />
                <FormHelperText>{this.state.errors.title ? <span className="error_field">{this.state.errors.title}</span> : ""}</FormHelperText>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    value={this.state.isEnseignant}
                    defaultChecked={this.state.isEnseignant}
                    ckecked={this.state.isEnseignant}
                    color="primary"
                    onChange={this.handeCheck('isEnseignant', this.state.isEnseignant)}
                  />
                }
                label={formatMessage({ id: "AjouterPersonnelView_6" })}
              />
              {this.state.isEnseignant &&
                <FormControlLabel
                  control={
                    <Checkbox
                      value={this.state.isPermanent}
                      ckecked={this.state.isPermanent}
                      defaultChecked={this.state.isPermanent}
                      color="primary"
                      onChange={this.handeCheck('isPermanent', this.state.isPermanent)}
                    />
                  }
                  label={formatMessage({ id: "AjouterPersonnelView_7" })}
                />}
              <div style={{ marginTop: '30px' }}>
                <InputLabel htmlFor="title">
                  {formatMessage({ id: "AjouterPersonnelView_10" })}
                </InputLabel>
                <div>
                  <LdImageUploader maxSize={500000} name={this.state.photo} hasParent={this.state.photo != null && this.state.photo !== ""} path={this.state.pathPhoto}
                    label={formatMessage({ id: "AjouterEleveView_84" })} onImageUploaded={this.newImageUploaded}  {...deleteClassInProps(this.props)} />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none" }} onClick={this.handleClose} color="primary">
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button style={{ textTransform: "none" }} onClick={this.edit} color="primary" autoFocus>
              {formatMessage({ id: "ItemEleve_4" })}
            </Button>
          </DialogActions>
        </Dialog>





        <Dialog
          open={this.state.openDelete}
          onClose={this.handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-diaalog-title">
            {formatMessage({ id: "ItemPersonnel_9" }, { name: this.props.row != null ? this.props.row.user.name : '' })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemPersonnel_10" })}
            </DialogContentText>
            <div style={{ padding: '10px 5px' }}>
              {this.state.progressDelete
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none" }} onClick={this.handleCloseDelete} color="primary">
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button style={{ textTransform: "none" }} onClick={this.handleDelete} color="primary" autoFocus>
              {formatMessage({ id: "ItemEleve_3" })}
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.root}>
          <div className={classes.content}>
            {/* <div className={classes.contentImage}>
              
              </div> */}
            <div className={classes.contentImage}>
              <ProgressiveImage
                src={row.pathPhoto}
                placeholder={defaultAvatar}
              >
                {(src, loading) => (
                  <Avatar alt="" src={src} className={classes.bigAvatar} />
                )}
              </ProgressiveImage>
            </div>
            <div className={classes.contentFlex}>
              <div>
                <Typography
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={userProfileUrl(row.user)}
                  className={classes.name}
                >
                  {row.user.name}
                </Typography>
                <div className={classes.email}>{row.user.email}</div>
                <div className={classes.email}><b>{formatMessage({ id: "ItemPersonnel_12" })}:</b> <span style={{ color: 'var(--app-primary-color)' }}>{row.matricule != null && row.matricule !== "" ? row.matricule : "###########"}</span></div>
                <div className={classes.email}><b>{formatMessage({ id: "ItemPersonnel_5" })}</b> {row.fonction !== -1 ? row.fonctionObject[locale] : "###########"}</div>
                <div className={classes.email}><b>{formatMessage({ id: "ItemPersonnel_6" })}</b> {row.title != null ? row.title : "###########"}</div>
                <div className={classes.email}><span>{row.isEnseignant ? formatMessage({ id: "ItemPersonnel_3" }) : ""}
                  <b>{row.isEnseignant ? (row.isPermanent ? '(' + formatMessage({ id: "ItemPersonnel_4" }) + ')' : '(' + formatMessage({ id: "ItemPersonnel_11" }) + ')') : ''}</b>
                </span></div>

              </div>
            </div>
          </div>
          <div className={classes.actions}>


            <IconButton
              title={formatMessage({ id: "ItemEleve_4" })}
              aria-label="edit"
              onClick={this.handleShowEdit}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              title={formatMessage({ id: "ItemRole_del" })}
              aria-label="Delete"
              onClick={this.handleConfirmDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
            {!!this.props.row.status ? (
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                classes={{ root: classes.disable }}
                aria-label={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            ) : (
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                  classes={{ root: classes.enable }}
                  aria-label={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                  onClick={this.handleEnabled}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ItemPersonnel.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(
  injectIntl(ItemPersonnel)
));
