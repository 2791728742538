import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ListPaymentView from "../view/payment/ListPaymentView";
import { isObjectValueEquals } from "../../components/LdUtils";
import { deleteClassInProps } from "../../components/LdUtils";
import { injectIntl } from "react-intl";

const useStyles = theme => ({
  root: {
    display: "block" // Fix IE 11 issue.
    // paddingRight:'10px'
  },
  titleBlock: {
    textAlign: "left"
    // paddingLeft:"10px",
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    marginBottom: "15px",
    color: theme.color.primaryColor
  }
});

class PaymentController extends Component {
  constructor(props) {
    super(props);
    //this.ajouterReseauEtablissViewRef = React.createRef();
    //this.ListReseauViewRef = React.createRef();
    this.state = {
      etablissement: this.props.etablissement,
      annee: this.props.annee
    };
  }
  componentDidUpdate(prevProps) {
    //var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        //self.refreshList("");
      });
    }
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.root}>
        <div className={classes.titleBlock}>
          <div className={classes.pageTitle} variant="div" component="div">
            {formatMessage({ id: "LdEtablissementMenu_27" })}
          </div>
          <div className={classes.pageSubtitle} variant="div" component="div">
            {formatMessage({ id: "PaymentController_1" })}
          </div>
        </div>
        <ListPaymentView
          annee={this.state.annee}
          etablissement={this.state.etablissement}
          {...deleteClassInProps(this.props)}
        />
      </div>
    );
  }
}

PaymentController.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(injectIntl(PaymentController));
