import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import LdImageUploader from "../../../components/LdImageUploader";
import LdSelect from '../../../components/LdSelect';
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import { deleteClassInProps} from '../../../components/LdUtils';


const styles = theme => ({
  main: {
    // width: "400px",
    textAlign:"left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding:'0px'
  },
  
  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  }
});



class AjouterReseauEtablissView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      code: "",
      name: "",
      description: "",
      image: "",
      ids: "",
      dialogOpened: false,
      inProgress:false,
    };
    this.add = this.add.bind(this);

    this.newImageUploaded = this.newImageUploaded.bind(this);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleValue = this.handleValue.bind(this);

  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  handleValue(selectedOptions) {
    var ids = selectedOptions.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + "-" + e.value : e.value;
    }, "");
    this.setState({ ids: ids });
  }
  newImageUploaded(name, path) {
    this.setState({ image: name });
  }
  add() {
    var formData = new FormData(); // Currently empty
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("ids", this.state.ids);
    formData.append("image", this.state.image);
    //let self = this;
    this.setState({inProgress:true});
    fetch(appProperties.basepathServer + "/web/reseau-etablissement/add", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({inProgress:false});
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          fireWithObject(document,'new-reseau',{value:data.row});

        }
      })
      .catch(err => {
        console.log(err);
        this.setState({inProgress:false});
        alert("There was an error added.");
      });
  }
 
  render() {
    const { classes } = this.props;


    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className = {classes.buttonPrincipale}
          onClick={this.handleClickOpen}

        >
          Ajouter un reseau
        </Button>
        <Dialog
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">Ajouter un réseau d'établissement</DialogTitle>
          <DialogContent>
            { this.state.inProgress
              ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
              : null
            }
            <div  className={classes.pageSubtitle} >Renseignez toutes les informations sur le réseau </div>
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>
              {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}
              {this.state.redirect ? (
                <Redirect
                  to={{
                    pathname: "/",
                    search: "",
                    state: {}
                  }}
                />
              ) : null}

              {/* {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null} */}

              <div className={classes.root}>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="code">Code :</InputLabel>
                  <Input
                    id="code"
                    required
                    name="code"
                    autoComplete="code"
                    onChange={this.handleChangeInput("code")}
                    autoFocus
                    placeholde="entrez votre code"
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="name">name :</InputLabel>
                  <Input
                    name="name"
                    type="name"
                    id="name"
                    required
                    onChange={this.handleChangeInput("name")}
                    autoComplete="current-name"
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="description">Description :</InputLabel>
                  <Input
                    id="description"
                    required
                    name="description"
                    autoComplete="description"
                    onChange={this.handleChangeInput("description")}
                    multiline
                      rows="4"
                      variant="filled"
                  />
                </FormControl>

                <LdSelect 
                     handleValue = {this.handleValue}
                     isMulti
                     firstLoad isSearchable
                     label="Rechercher par nom"
                     baseUrl={appProperties.basepathServer + "/rest-etablissement/list?published=1&info=0&limit=-1&query="}  {...deleteClassInProps(this.props)} />
                <div>
                  <LdImageUploader maxSize={500000} maxSize={500000} onImageUploaded={this.newImageUploaded}  {...deleteClassInProps(this.props)} />
                </div>
                
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button  onClick={this.handleClose} 
              variant="contained"
              size="medium"
              className = {classes.buttonGreyWhite}>
              Cancel
            </Button>
            <Button 
              onClick={this.add} 
              variant="contained"
              size="medium"
              className = {classes.buttonPrincipale}>
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterReseauEtablissView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(
  AjouterReseauEtablissView
);
