import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import {
  appProperties,
  isObjectValueEquals
} from "../../../components/LdUtils";
import ItemVersement from "./ItemVersement";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";
import DatePicker from "react-date-picker";
import moment from "moment";
import printJS from 'print-js'
import { withSnackbar } from "notistack";

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import PrintIcon from "@material-ui/icons/Print";

const styles = theme => ({
  root: {
    width: "100%"
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: "15px",
    marginTop: "15px",
    backgroundColor: "white"
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: "17px",
    lineHeight: "22px",
    fontWeight: 400,
    paddingBottom: "15px"
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: "12px",
    fontWeight: "500",
    transform: "translate(14px, -6px) scale(1)"
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: "7.5px 23px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  }, /** Filter end */
  datePicker: {
    height: "35px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },

});

class ListVersementView extends Component {
  constructor(props) {
    super(props);
    var minDate = new Date(); minDate.setHours(0, 0, 0, 0);
    var maxDate = new Date(); maxDate.setHours(23, 59, 59, 999);

    // const minDate = null;
    // const maxDate = null;
    this.state = {
      datas: [],
      classes: [],
      classe: "---", //use to fix bug
      // eleves :[],
      // eleve: "---",//use to fix bug
      modesPaiement: [],
      modePaiement: "---", //use to fix bug
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,


      labelWidth: 0,
      period: 1,
      minDate: minDate,
      maxDate: maxDate,


      categories: [],
      categorie: "---",
    };
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeModePaiement = this.handleChangeModePaiement.bind(this);
    // this.handleChangeEleve = this.handleChangeEleve.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.doRealodVersement = this.doRealodVersement.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleChangePeriod = this.handleChangePeriod.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);
    // this.handlePrint = this.handlePrint.bind(this);

  }

  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value), classe: "---", classes: [] }, (state) => {
      self.listClasses();
      self.setCurrentPage(1);
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.setCurrentPage(1);
      });
    }
  }
  handleChangeModePaiement = event => {
    let self = this;
    var modePaiement = event.target.value;
    self.setState({ modePaiement: modePaiement }, state => {
      self.setCurrentPage(1);
    });
  };
  handleChangePeriod = event => {
    let self = this;
    var period = event.target.value;
    var minDate = null;
    var maxDate = null;
    var date = new Date();
    if (period === 3) {//mois
      minDate = new Date(date.getFullYear(), date.getMonth(), 1); minDate.setHours(0, 0, 0, 0);
      maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0); maxDate.setHours(23, 59, 59, 999);

    } else if (period === 2) {//semaine
      minDate = new Date(date.setDate(date.getDate() - date.getDay())); minDate.setHours(0, 0, 0, 0);
      maxDate = new Date(date.setDate(date.getDate() - date.getDay() + 6)); maxDate.setHours(23, 59, 59, 999);
    } else if (period === 1) {//semaine
      var start = new Date(); start.setHours(0, 0, 0, 0);
      var end = new Date(); end.setHours(23, 59, 59, 999);
      minDate = start;
      maxDate = end;
    } else if (period === 0) {//semaine
      self.setState({ period: period, minDate: null, maxDate: null }, state => {
      });
    }
    if (period !== 0) {
      self.setState({ period: period, minDate: minDate, maxDate: maxDate }, state => {
        self.setCurrentPage(1);
      });
    }
  };

  handleStartDateChange(date) {
    var self = this;
    if (date != null) date.setHours(0, 0, 0, 0);
    this.setState(
      {
        minDate: date,
        period: 0,
      },
      state => {
        self.setCurrentPage(1);
      }
    );
  }

  handleEndDateChange(date) {
    var self = this;
    if (date != null) date.setHours(23, 59, 59, 999);
    this.setState(
      {
        maxDate: date,
        period: 0,
      },
      state => {
        self.setCurrentPage(1);
      }
    );
  }

  handleChangeClass = event => {
    console.log("change classe");
    console.log(event.target.value);
    let self = this;
    var classe = event.target.value;

    this.setState({ classe: event.target.value }, state => {
      self.setState({ classe: classe }, state => {
        // var modesPaiement =
        //   classe != null && classe.modesPaiement ? classe.modesPaiement : [];
        // self.setState({ modesPaiement: modesPaiement });
        self.setCurrentPage(1);
        // self.listEleve();
      });
    });
  };
  onUpdateItem = (mode, item) => {
    console.log("intercept", mode);
    console.log(item);
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  doRealodVersement = (versement, position) => {
    this.onUpdateItem('update', { position: position, row: versement });
  }
  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listPayment(page);
  };

  componentDidMount() {
    this.listCategorie();
    //this.listClasses();
    this.listModePaiements();
    this.listPayment(this.state.currentPage);
  }
  componentWillUnmount() { }

  listClasses() {
    let self = this;
    this.setState({ classes: [] });
    var url =
      appProperties.basepathServer +
      "/manager/annee-scolaire/classes?id=" +
      this.state.annee.id +
      "&limit=100";
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [] });
      return;
    }
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          self.setState({ classes: data.datas });
        } else {
          self.setState({ classes: [], classe: "" });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ classes: [], classe: "" });
      });
  }
  listModePaiements() {
    let self = this;
    this.setState({ modesPaiement: [] });
    var url =
      appProperties.basepathServer +
      "/rest-mode-paiement-etablissement/list?limit=-1";
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ modesPaiement: data.datas });
        //var array = [];
        // if (data.result_code === 1) {
        //   self.setState({ modesPaiement: data.datas });
        // } else {
        //   self.setState({ modesPaiement: [], modePaiement: "---" });
        // }
      })
      .catch(err => {
        console.log(err);
        self.setState({ modesPaiement: [], modePaiement: "---" });
      });
  }


  listPayment(page) {
    let self = this;

    this.setState({ datas: [], progress: 1 });
    var url =
      appProperties.basepathServer +
      "/manager/scolarite/list-versements?etablissement=" +
      this.state.etablissement.id +
      "&limit=10&page=" +
      page;
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;
    if (
      this.state.classe &&
      this.state.classe !== "---" &&
      this.state.classe != null &&
      this.state.classe.classeEtablissement != null
    )
      url += "&classe=" + this.state.classe.classeEtablissement.id;
    if (
      this.state.modePaiement &&
      this.state.modePaiement !== "---" &&
      this.state.modePaiement != null
    ) url += "&type=" + this.state.modePaiement.id;

    if (this.state.minDate && this.state.minDate != null)
      url +=
        "&from=" + moment(this.state.minDate).format("YYYY-MM-DD HH:mm:ss");
    if (this.state.maxDate && this.state.maxDate != null)
      url += "&to=" + moment(this.state.maxDate).format("YYYY-MM-DD HH:mm:ss");
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);

    // if(this.state.eleve && this.state.eleve !== "---" && this.state.eleve!=null) url+=("&eleve="+this.state.eleve.id);

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log("There was an error listed enseignant.");
      });
  }


  handlePrint = (fromMe) => {

    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    var url =
      appProperties.basepathServer +
      "/manager/scolarite/print-rapport-versements?etablissement=" +
      this.state.etablissement.id +
      "&limit=-1&page=1";
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;
    if (
      this.state.classe &&
      this.state.classe !== "---" &&
      this.state.classe != null &&
      this.state.classe.classeEtablissement != null
    )
      url += "&classe=" + this.state.classe.classeEtablissement.id;
    if (
      this.state.modePaiement &&
      this.state.modePaiement !== "---" &&
      this.state.modePaiement != null
    ) url += "&type=" + this.state.modePaiement.id;

    if (this.state.minDate && this.state.minDate != null)
      url +=
        "&from=" + moment(this.state.minDate).format("YYYY-MM-DD HH:mm:ss");
    else {
      alert(self.props.intl.formatMessage({ id: "ItemVersement_10" }));
      return;
    }
    if (this.state.maxDate && this.state.maxDate != null)
      url += "&to=" + moment(this.state.maxDate).format("YYYY-MM-DD HH:mm:ss");
    else {
      alert(self.props.intl.formatMessage({ id: "ItemVersement_10" }));
      return;
    }
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    if (!!fromMe) url += "&from_me=1";

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -3) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemVersement_11" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -4) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_5" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }
  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon /> {/*{self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      persist: true,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,

      scanStyles: false,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }

  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }


  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <Grid
          container
          className={classes.grid}
          justify="space-between"
          alignItems="center"
        >
          <div className={classes.pageTitle} variant="div" component="div">
            {formatMessage({ id: "ListVersementView_1" })}
          </div>
          <div className="layout horizontal center">
            {/* <span style={{ fontSize: '15px', fontWeight: 400, paddingRight: '10px' }}>
              {formatMessage({ id: "ItemVersement_10" })}
            </span> */}

            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "ListClasseEtablissementView_4"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.categorie}
                onChange={this.handleChangeCategorie}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListClasseEtablissementView_3" })}
                </MenuItem>
                {this.state.categories.map((row, index) => (
                  <MenuItem title={row.description} key={index} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "AjouterClasseEtablissementView_6"
                })}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.classe}
                onChange={this.handleChangeClass}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListEleveView_1" })}
                </MenuItem>
                {this.state.classes.map((row, index) => (
                  <MenuItem key={index} value={row}>
                    {row.classeEtablissement.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({ id: "ListVersementView_2" })}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.modePaiement}
                onChange={this.handleChangeModePaiement}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name={formatMessage({ id: "ListVersementView_3" })}
                    id="outlined-typefrais-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListVersementView_3" })}
                </MenuItem>
                {this.state.modesPaiement.map((row, index) => (
                  <MenuItem key={index} title={row.code} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </div>

        </Grid>
        <Grid
          container
          className={classes.grid}
          justify="flex-end"
          alignItems="center"
        >


          <FormControl
            style={{ height: "35px" }}
            variant="outlined"
            className={classes.formControl}
          >
            <Select
              title={formatMessage({ id: "ListVersementView_5" })}
              classes={{
                selectMenu: classes.selectMenu,
                root: classes.selectRoot
              }}
              value={this.state.period}
              onChange={this.handleChangePeriod}
              input={
                <OutlinedInput
                  labelWidth={this.state.labelWidth}
                  name={formatMessage({ id: "ListVersementView_5" })}
                  id="outlined-typefrais-simple"
                  classes={{ input: classes.outlinedInput }}
                />
              }
            >
              <MenuItem value={1}>
                {formatMessage({ id: "ListVersementView_6" })}
              </MenuItem>
              <MenuItem value={2}>
                {formatMessage({ id: "ListVersementView_7" })}
              </MenuItem>
              <MenuItem value={3}>
                {formatMessage({ id: "ListVersementView_8" })}
              </MenuItem>
              <MenuItem value={0}>
                {formatMessage({ id: "ListVersementView_9" })}
              </MenuItem>
            </Select>
          </FormControl>
          {this.state.period === 0 &&
            <React.Fragment>

              <span style={{ paddingRight: "5px" }} className={classes.label}>
                {formatMessage({ id: "ListAlertHistoryView_deb" })}
              </span>
              <DatePicker
                // maxDate={this.state.maxDate}
                locale="fr-FR"
                className={classes.datePicker}
                value={this.state.minDate}
                onChange={this.handleStartDateChange}
              />

              <span
                style={{ marginLeft: "20px", paddingRight: "5px" }}
                className={classes.label}
              >
                {formatMessage({ id: "ListAlertHistoryView_fin" })}
              </span>
              <DatePicker
                label={formatMessage({ id: "ListAlertHistoryView_1" })}
                className={classes.datePicker}
                locale="fr-FR"
                value={this.state.maxDate}
                minDate={this.state.minDate}
                onChange={this.handleEndDateChange}
              />

            </React.Fragment>
          }
          {/* <Button disabled={this.state.minDate == null || this.state.maxDate == null} onClick={() => this.handlePrint()} title={formatMessage({ id: "ItemVersement_10" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
            {formatMessage({ id: "ItemVersement_9" })}
          </Button> */}
          <Button disabled={this.state.minDate == null || this.state.maxDate == null} onClick={() => this.handlePrint(true)} title={formatMessage({ id: "ItemVersement_12" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
            <PrintOutlinedIcon />
          </Button>

          <Button disabled={this.state.minDate == null || this.state.maxDate == null} onClick={() => this.handlePrint(false)} title={formatMessage({ id: "ItemVersement_10" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
            <PrintIcon />
          </Button>
        </Grid>
        {this.state.progress === 0 && (
          <React.Fragment>
            <Paper elevation={0} className={classes.root}>
              <div style={{ marginTop: "15px" }}>
                {this.state.datas.map((row, index) => (
                  <ItemVersement
                    key={index}
                    onUpdateItem={this.onUpdateItem}
                    etablissement={this.state.etablissement}
                    position={index}
                    versement={row}
                    readOnly={true}
                    onEditVersement={this.doRealodVersement}
                    {...deleteClassInProps(this.props)}
                  />
                ))}
              </div>

            </Paper>
            {this.state.pages && this.state.pages != null ? (
              <LdPagination
                onShowPage={this.setCurrentPage}
                pages={this.state.pages}
              />
            ) : null}
          </React.Fragment>
        )}

        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 ||
          (this.state.progress === 0 && this.state.datas.length === 0)) && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "ListVersementView_4" })}
              </div>
            </div>
          )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              {formatMessage({ id: "ListClasseAnneeView_4" })}
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withSnackbar(withStyles(styles)(injectIntl(ListVersementView)));
