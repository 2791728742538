import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ListCahierChefView from '../../cahier/view/list-cahier/ListCahierChefView';
import { deleteClassInProps} from '../../components/LdUtils';

const useStyles = theme => ({
    root: {
      display: "block", // Fix IE 11 issue.
    },
    titleBlock:{
      textAlign:'left',
      // paddingLeft:"10px",
    },
    pageTitle:{
      fontSize: '21px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    pageSubtitle:{
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 400,
      color:theme.color.primaryColor
    }
    
  });

class ListCahierChefController extends Component{

    constructor(props){
        super(props);

        this.state={
          etablissement: this.props.etablissement,
          annee: this.props.annee,
          classe: this.props.classe,
          eleve: this.props.eleve,

        }

     

    }
    
    render(){
        const {classes} = this.props;
        return (
            <div className = {classes.root}>
               <div  className={classes.titleBlock}>
                 
               </div>
               <ListCahierChefView  classe={this.props.classe}  etablissement={this.props.etablissement} annee={this.props.annee}  {...deleteClassInProps(this.props)} />
           
            </div>
        );
    }
    
}

ListCahierChefController.propTypes = {
    classes: PropTypes.object.isRequired
};
  
export default withStyles(useStyles)(ListCahierChefController);