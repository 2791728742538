import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import {
  appProperties,
  isObjectValueEquals
} from "./LdUtils";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoneyIcon from '@material-ui/icons/Money';
//import {Link} from 'react-router-dom'
import Avatar from "@material-ui/core/Avatar";
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: "10px",
    paddingTop: "10px"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonSimple: {
    textTransform: "none"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none"
  },
  date: {
    fontSize: "12px",
    fontWeight: "400"
  },
  bigAvatar: {
    backgroundColor: "lightgray",
    border: "2px solid white",
    width: "100px !important",
    height: "100px !important",
    borderRadius: "0 !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  scolariteGreen: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-brown-200)',
  },
  panelRoot: {
    borderRadius: '0 !important',
  },
  panelSummary: {
    alignItems: 'center',
    margin: '0 !important',
    borderBottom: '1px dashed #EAEAEA'
  },
  summaryRoot: {
    padding: '0',
  },
  summaryExpanded: {
    margin: '0 !important',
  },
});

// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: '600',
    fontSize: '15px',
    padding: '4px 0 4px 15px;'
  },
  body: {
    fontSize: 14,
    padding: '4px 0 4px 15px;'
  }
}))(TableCell);

class StatsFraisEleve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      progress: 1,
      modeEdit: false,
      eleveId: this.props.eleveId,
      datas: [],

      expanded: ''

    };

  }
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  componentDidMount() {
    this.loadPaymentStatus();
  }
  loadPaymentStatus = () => {
    var self = this;
    this.setState({ progress: 1 });
    fetch(
      appProperties.basepathServer +
      "/manager/scolarite/stats-frais-eleve?eleve_id=" + this.props.eleveId,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.setState({ progress: 0, datas: data.datas, result: data.result });
          //self.props.onUpdateItem("delete", { position: self.state.position });
        } else {
          self.setState({ progress: 2 });

        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ progress: 3 });
        alert("There was an error added.");
      });
  }

  componentDidUpdate(prevProps) {

    if (!isObjectValueEquals(this.props.eleveId, prevProps.eleveId)) {
      this.setState({ eleveId: this.props.eleveId }, () => { });
    }
  }

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <br />
          <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'filled'} onChange={this.handleChange('filled')}>
            <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
              <div style={{ position: 'relative' }}>
                <Avatar className={classes.scolariteGreen}>
                  <MoneyIcon />
                </Avatar>
                {this.state.progress === 1 &&
                  <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
                }
              </div>
              <Grid container className={classes.grid} justify="space-between" alignItems='center'>
                <div className={classes.titleBlock}>
                  <div className={classes.pageSubTitle} variant="div" component="div">{formatMessage({ id: "StatsFraisEleve_1" })}</div>
                </div>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ padding: '10px 10px 10px 10px' }} className="layout vertical">
              <div className={classes.content}>
                <div>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <CustomTableCell>{formatMessage({ id: "StatsFraisEleve_2" })}</CustomTableCell>
                        <CustomTableCell align="left">{formatMessage({ id: "StatsFraisEleve_3" })}</CustomTableCell>
                        <CustomTableCell align="left">{formatMessage({ id: "StatsFraisEleve_4" })}</CustomTableCell>
                        <CustomTableCell align="left">{formatMessage({ id: "StatsFraisEleve_5" })}</CustomTableCell>
                        <CustomTableCell align="left">{formatMessage({ id: "StatsFraisEleve_6" })}</CustomTableCell>
                      </TableRow>
                    </TableHead>
                    {this.state.progress === 0 && (
                      <TableBody>
                        {this.state.datas.map((row, index) => (
                          <TableRow key={index}>
                            <CustomTableCell>{row.name}</CustomTableCell>
                            <CustomTableCell align="left">{row.amount}</CustomTableCell>
                            <CustomTableCell align="left">{row.sumPaid}</CustomTableCell>
                            <CustomTableCell align="left">{row.sumReduction}</CustomTableCell>
                            <CustomTableCell align="left">{row.amount - row.totalPaid}</CustomTableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <CustomTableCell style={{ fontWeight: '600' }}>{formatMessage({ id: "StatsFraisEleve_7" })}</CustomTableCell>
                          <CustomTableCell style={{ fontWeight: '600' }} align="left">{this.state.result.wait}</CustomTableCell>
                          <CustomTableCell style={{ fontWeight: '600' }} align="left">{this.state.result.paid}</CustomTableCell>
                          <CustomTableCell style={{ fontWeight: '600' }} align="left">{this.state.result.reduction}</CustomTableCell>
                          {this.state.result.unpaid > 0 ? <CustomTableCell style={{ backgroundColor: 'var(--paper-red-500)', color: 'white', fontWeight: '600' }} align="left">{this.state.result.unpaid}</CustomTableCell> : <CustomTableCell style={{ backgroundColor: 'var(--paper-green-500)', color: 'white', fontWeight: '600' }} align="left">{this.state.result.unpaid}</CustomTableCell>
                          }

                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                  {this.state.progress === 1 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>
                        <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                      </div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListAlertHistoryView_4" })}
                      </div>
                    </div>
                  )}
                  {(this.state.progress === 2 || (this.state.progress === 0 && this.state.datas.length === 0)) && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "StatsFraisEleve_8" })}
                      </div>
                    </div>
                  )}
                  {this.state.progress === 3 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '70px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>Erreur!!</div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListAlertHistoryView_6" })}

                      </div>
                    </div>
                  )}
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </React.Fragment>
    );
  }
}
StatsFraisEleve.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  eleveId: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà.
export default withStyles(styles, { withTheme: true })(injectIntl(StatsFraisEleve));
