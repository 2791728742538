import React, { Component } from "react";
import { appProperties } from "../../../components/LdUtils";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import LdSelect from "../../../components/LdSelect";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import DatePicker from "react-date-picker";
import { Redirect } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import moment from "moment";
import { deleteClassInProps } from "../../../components/LdUtils";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 30px 0 30px",
    backgroundColor: "white",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1,
    padding: "10px 0"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    },
    margin: "0 10px"
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },

  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  select: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  margin: {
    margin: theme.spacing.unit
  }
});

class RegisterStudent extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    //console.log("User view",this.props)
    this.state = {
      etablissement: this.props.etablissement || null,
      lastName: "",
      firstName: "",
      email: "",
      confirmEmail: "",
      password: "",
      confirmPassword: "",
      birthday: date,
      city: null,
      language: null,
      result: null,
      errors: [],
      redirect: false,
      controlled: true,
      inProgress: false,
      selectedSexe: "1",
      selectedType: "0",
      country: null,
      showError: false,
      showSucces: false,
      sucessMessage: "",
      errorMessage: "",
    };
    this.add = this.add.bind(this);
    this.handleValueCountry = this.handleValueCountry.bind(this);
    this.handleValueCity = this.handleValueCity.bind(this);
    this.handleValueLanguage = this.handleValueLanguage.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeSexe = this.handleChangeSexe.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
  }

  handleValueCountry(country) {
    this.setState({ country: country });
  }
  handleValueCity(city) {
    this.setState({ city: city });
  }
  handleValueLanguage(language) {
    this.setState({ language: language });
  }

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  handleChangeSexe = name => event => {
    this.setState({ selectedSexe: name });
  };
  handleChangeType = name => event => {
    this.setState({ selectedType: name });
  };
  handleStartDateChange(date) {
    console.log("start Date");
    console.log(date);
    this.setState({
      birthDay: date
    });
  }

  add() {
    this.setState({ showError: false, showSucces: false });
    var formData = new FormData(); // Currently empty
    formData.append("lastname", this.state.lastName);
    formData.append("firstname", this.state.firstName);
    formData.append("email", this.state.email);
    // formData.append("confirmEmail", this.state.confirmEmail);
    formData.append("phone", this.state.phone);
    formData.append("mobile", this.state.phone);
    formData.append("password", this.state.password);
    formData.append("confirm_password", this.state.confirmPassword);
    formData.append(
      "date_de_naissance",
      moment(this.state.birthDay).format("YYYY-MM-DD")
    );
    formData.append("sex", this.state.selectedSexe);
    formData.append("type_user", this.state.selectedType);
    formData.append(
      "country",
      this.state.country != null ? this.state.country.id : ""
    );
    formData.append(
      "language",
      this.state.language != null ? this.state.language.id : ""
    );
    formData.append("city", this.state.city != null ? this.state.city.id : "");
    formData.append("description", this.state.description);
    this.setState({ inProgress: true });
    var self = this;
    fetch(appProperties.basepathServer + "/manager/inscription/add-user", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          //fireWithObject(document, 'new-typeFrais', { value: data.row });
          var messageSucess = self.props.intl.formatMessage({ id: "RegisterStudent_30" }, { email: data.email });
          self.props.enqueueSnackbar(messageSucess, {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
          this.setState({ showSucces: true, sucessMessage: messageSucess });

        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ListMoyenne_25" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -1) {
          var error = ['EMAIL_NOT_FOUND', 'EMAIL_NOT_VALID', 'NAME_NOT_CORRECT', 'PASSWORD_NOT_CORRECT', 'PASSWORD_NOT_MATCH', 'EMAIL_USED']
          var converts = {
            'EMAIL_NOT_FOUND': self.props.intl.formatMessage({ id: "RegisterStudent_33" }),
            'EMAIL_NOT_VALID': self.props.intl.formatMessage({ id: "RegisterStudent_34" }),
            'NAME_NOT_CORRECT': self.props.intl.formatMessage({ id: "RegisterStudent_35" }),
            'PASSWORD_NOT_CORRECT': self.props.intl.formatMessage({ id: "RegisterStudent_36" }),
            'PASSWORD_NOT_MATCH': self.props.intl.formatMessage({ id: "RegisterStudent_37" }),
            'EMAIL_USED': self.props.intl.formatMessage({ id: "RegisterStudent_38" })
          }
          var str = "";
          for (var i in data.errors) {
            if (error.indexOf(data.errors[i]) > -1) str += (str.length > 0 ? ' , ' + converts[data.errors[i]] : converts[data.errors[i]]);
          }
          var messageError = self.props.intl.formatMessage({ id: "RegisterStudent_32" }, { error: str });
          self.props.enqueueSnackbar(messageError,
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
          this.setState({ showError: true, errorMessage: messageError, email: data.email, confirmEmail: data.email });
        } else {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "RegisterStudent_31" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>
        <div className={classes.pageTitle} variant="div" component="div">
          {formatMessage({ id: "RegisterStudent_29" })}
        </div>
        <div className={classes.pageSubtitle} variant="div" component="div">
          {formatMessage({ id: "RegisterStudent_28" })}
        </div>
        <div>
          {this.state.inProgress ? (
            <div className={classes.progress}>
              <LinearProgress variant="query" />
            </div>
          ) : null}
          {!this.state.controlled ? (
            <div className={classes.error}>
              {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
            </div>
          ) : null}
          <CssBaseline />
          <Paper square elevation={0} className={classes.paper}>
            {this.state.redirect ? (
              <Redirect
                to={{
                  pathname: "/",
                  search: "",
                  state: {}
                }}
              />
            ) : null}

            {/* {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null} */}

            <div className={classes.root}>
              <div style={{ marginTop: '15px' }}>
                {this.state.showError && <span style={{ color: 'var(--paper-red-500)', fontSize: '13px', margin: "20px 0 0", fontWeight: 500 }}>{this.state.errorMessage}</span>}
                {this.state.showSucces && <span style={{ color: 'var(--paper-green-500)', fontSize: '13px', margin: "20px 0 0", fontWeight: 600 }}>{this.state.sucessMessage}</span>}
              </div>
              <FormControl margin="dense" required fullWidth>
                {/* <InputLabel htmlFor="code">Code :</InputLabel> */}
                <InputLabel htmlFor="lastName">
                  {formatMessage({ id: "RegisterStudent_27" })}{" "}
                </InputLabel>
                <Input
                  id="lastName"
                  required
                  name="lastName"
                  autoComplete="lastName"
                  onChange={this.handleChangeInput("lastName")}
                  autoFocus
                  placeholder={formatMessage({ id: "RegisterStudent_26" })}
                />
              </FormControl>
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="firstName">
                  {formatMessage({ id: "RegisterStudent_25" })}{" "}
                </InputLabel>
                <Input
                  id="firstName"
                  required
                  name="firstName"
                  autoComplete="firstName"
                  onChange={this.handleChangeInput("firstName")}
                  autoFocus
                  placeholder={formatMessage({ id: "RegisterStudent_24" })}
                />
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="email">
                  {formatMessage({ id: "RegisterStudent_23" })}{" "}
                </InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  onChange={this.handleChangeInput("email")}
                  autoFocus
                  placeholder={formatMessage({ id: "RegisterStudent_22" })}
                />
              </FormControl>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="confirmEmail">
                  {formatMessage({ id: "RegisterStudent_20" })}{" "}
                </InputLabel>
                <Input
                  id="confirmEmail"
                  name="confirmEmail"
                  autoComplete="confirmEmail"
                  value={this.state.confirmEmail}
                  onChange={this.handleChangeInput("confirmEmail")}
                  autoFocus
                  placeholder={formatMessage({ id: "RegisterStudent_21" })}
                />
              </FormControl>
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="phone">
                  {formatMessage({ id: "RegisterStudent_19" })}{" "}
                </InputLabel>
                <Input
                  id="phone"
                  required
                  name="phone"
                  autoComplete="phone"
                  onChange={this.handleChangeInput("phone")}
                  autoFocus
                  placeholder={formatMessage({ id: "RegisterStudent_18" })}
                />
              </FormControl>
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="password">
                  {formatMessage({ id: "RegisterStudent_17" })}{" "}
                </InputLabel>
                <Input
                  id="password"
                  required
                  name="password"
                  type="password"
                  autoComplete="password"
                  onChange={this.handleChangeInput("password")}
                  autoFocus
                  placeholder=""
                />
              </FormControl>
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="confirmPassword">
                  {formatMessage({ id: "RegisterStudent_16" })}{" "}
                </InputLabel>
                <Input
                  id="confirmPassword"
                  required
                  type="password"
                  name="confirmPassword"
                  autoComplete="confirmPassword"
                  onChange={this.handleChangeInput("confirmPassword")}
                  autoFocus
                  placeholder=""
                />
              </FormControl>
              <br />
              <br />
              <div className={classes.grid}>
                <span style={{ paddingRight: "10px" }}>
                  {formatMessage({ id: "RegisterStudent_15" })}
                </span>
                <DatePicker
                  locale="fr-FR"
                  className={classes.datePicker}
                  value={this.state.birthDay}
                  onChange={this.handleStartDateChange}
                />
              </div>
              <br />
              <label>{formatMessage({ id: "RegisterStudent_14" })}</label>
              &nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.selectedSexe === "1"}
                    onChange={this.handleChangeSexe("1")}
                    name="masculin"
                    aria-label="masculin"
                  />
                }
                label={formatMessage({ id: "RegisterStudent_13" })}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.selectedSexe === "0"}
                    onChange={this.handleChangeSexe("0")}
                    name="feminin"
                    aria-label="feminin"
                  />
                }
                label={formatMessage({ id: "RegisterStudent_12" })}
              />
              <br />
              <label>{formatMessage({ id: "RegisterStudent_11" })}</label>
              <br />
              <div style={{ paddingLeft: "20px" }}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.selectedType === "0"}
                      onChange={this.handleChangeType("0")}
                      name="eleve"
                      aria-label="eleve"
                    />
                  }
                  label={formatMessage({ id: "RegisterStudent_10" })}
                />
                <br />
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.selectedType === "1"}
                      onChange={this.handleChangeType("1")}
                      name="enseignant"
                      aria-label="enseignant"
                    />
                  }
                  label={formatMessage({ id: "RegisterStudent_9" })}
                />
                <br />
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.selectedType === "2"}
                      onChange={this.handleChangeType("2")}
                      name="particulier"
                      aria-label="particulier"
                    />
                  }
                  label={formatMessage({ id: "RegisterStudent_8" })}
                />
              </div>
              <br />
              <label>{formatMessage({ id: "RegisterStudent_7" })}</label>
              <br />
              <LdSelect
                handleValue={this.handleValueCountry}
                firstLoad
                isSearchable
                label={formatMessage({ id: "RegisterStudent_6" })}
                onChange={this.state.country}
                baseUrl={
                  appProperties.basepathServer + "/rest-location/list?limit=-1"
                }
                {...deleteClassInProps(this.props)}
              />
              <br />
              <label>{formatMessage({ id: "RegisterStudent_5" })}</label>
              <br />
              <LdSelect
                handleValue={this.handleValueCity}
                label={formatMessage({ id: "RegisterStudent_4" })}
                onChange={this.state.city}
                firstLoad
                isSearchable
                baseUrl={
                  appProperties.basepathServer +
                  "/rest-location/list-cities?limit=-1&id=" +
                  (this.state.country != null ? this.state.country.id : "")
                }
                {...deleteClassInProps(this.props)}
              />
              <br />
              <label>{formatMessage({ id: "RegisterStudent_3" })}</label>
              <br />
              <LdSelect
                handleValue={this.handleValueLanguage}
                firstLoad
                isSearchable
                label={formatMessage({ id: "RegisterStudent_2" })}
                onChange={this.state.language}
                baseUrl={
                  appProperties.basepathServer + "/rest-language/list?limit=-1"
                }
                {...deleteClassInProps(this.props)}
              />
              <br />
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="description">
                  {formatMessage({ id: "RegisterStudent_1" })}
                </InputLabel>
                <Input
                  id="description"
                  name="description"
                  autoComplete="description"
                  onChange={this.handleChangeInput("description")}
                  multiline
                  rows="4"
                  variant="filled"
                />
              </FormControl>
            </div>
          </Paper>
        </div>
        <div
          style={{ padding: "15px 0" }}
          className="layout horizontal  center end-justified"
        >
          <div>
            {this.state.showError && <span style={{ color: 'var(--paper-red-500)', fontSize: '13px', fontWeight: 500 }}>{this.state.errorMessage}</span>}
            {this.state.showSucces && <span style={{ color: 'var(--paper-green-500)', fontSize: '13px', fontWeight: 600 }}>{this.state.sucessMessage}</span>}
          </div>
          <Button
            onClick={this.add}
            variant="contained"
            disabled={this.state.inProgress}
            size="medium"
            className={classes.buttonPrincipale}
          >
            {this.state.inProgress
              ? formatMessage({ id: "AjouterTypeAlertView_14" })
              : formatMessage({ id: "AjouterTypeAlertView_15" })}
          </Button>
        </div>
      </main>
    );
  }
}
RegisterStudent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(RegisterStudent))
);
