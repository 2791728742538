import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";





const styles = theme => ({
  root: {
    // paddingTop: theme.spacing.unit * 2,
    // paddingBottom: theme.spacing.unit * 2

    // backgroundColor: "#EEEEEE",
    borderTop:'1px solid #dcdcdc',
  },
  image:{
      width:'100%',
    
  }
});
class PageConstruction extends Component{

    render(){
        //const { classes } = this.props;

        return(
            <div style={{backgroundColor:'white'}} className="layout vertical"> 
                <div className="layout horizontal center-center" style={{padding:"100px"}}>
                    <h2>{this.props.message?this.props.message:'Page en construction...'}</h2>
                </div>
            </div>
        )
    }
} 
PageConstruction.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(PageConstruction);
