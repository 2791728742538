import Avatar from '@material-ui/core/Avatar';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import { withSnackbar } from 'notistack';
import React, { Component } from "react";
import { Chart } from 'react-google-charts';
import { appProperties, cancelEventPropagation, getRound2, isObjectValueEquals, userProfileUrl, deleteClassInProps } from '../../../components/LdUtils';

import { injectIntl } from "react-intl";
import printJS from 'print-js'

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import ItemMoyenne from './ItemMoyenne';





const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: '15px',
    marginTop: '15px',
    backgroundColor: 'white',
    // marginLeft: '10px',
  },

  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  }  /** Filter end */
  ,
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  buttonPrincipale: {
    margin: "0 3px",
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  primaryAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--app-primary-color)',
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: '#ff9800',
  },
  blueAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-blue-900)',
  },
  progress: {
    width: "100%",
    flexGrow: 1,
  },
});

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: '600',
    fontSize: '15px',
    padding: '4px 7px 4px 7px'
  },
  body: {
    fontSize: 14,
    padding: '4px 7px 4px 7px'
  }
}))(TableCell);

class ListMoyenne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      mode: this.props.mode || "",
      classe: this.props.classe || "---",
      sequence: this.props.sequence || "---",
      trimestre: this.props.trimestre || "---",
      count: 0,
      hasMore: false,
      pages: [],
      currentPage: 1,
      progress: 1,
      inProgressGenerate: false,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      expanded: this.props.expanded || "---",
      panelName: this.props.panelName,

      loadChart: false,
      chart: null,
    };
    this.handleGenerateMoyenne = this.handleGenerateMoyenne.bind(this);
    this.handleGenerateBulletin = this.handleGenerateBulletin.bind(this);
    //this.handlePrintBulletin = this.handlePrintBulletin.bind(this);
    this.doOrderByName = this.doOrderByName.bind(this);
    this.doOrderByRang = this.doOrderByRang.bind(this);

    this.onUpdateItem = this.onUpdateItem.bind(this);

  }

  onUpdateItem = (mode, item) => {
    console.log("intercept", mode);
    console.log(item);
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  doOrderByName = (event) => {
    var array = this.state.datas;
    array.sort((item1, item2) => {
      return (item1.eleve != null && item1.eleve.user != null && (item1.eleve.fullname || item1.eleve.user.name) != null &&
        item2.eleve != null && item2.eleve.user != null && (item2.eleve.fullname || item2.eleve.user.name) != null) ?
        (item1.eleve.fullname || item1.eleve.user.name).localeCompare(item2.eleve.fullname || item2.eleve.user.name) : 0;
    });
    this.setState({ datas: array });

  };

  doOrderByRang = (event) => {
    var array = this.state.datas;
    array.sort((item1, item2) => {
      return (item1.moyenne != null && item2.moyenne != null) ?
        (parseFloat(item1.moyenne) > parseFloat(item2.moyenne) ? -1 : 1) : 0;
    });
    this.setState({ datas: array });

  };
  handleGenerateMoyenne(event) {
    cancelEventPropagation(event);
    this.setState({ inProgressGenerate: true });
    var self = this;
    var url = appProperties.basepathServer + '/manager/note/compute-moyenne?etablissement=' + this.state.etablissement.id;
    if (this.state.annee != null) url += ("&annee=" + this.state.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null) url += ("&classe=" + this.state.classe.id);
    else return;
    if (this.props.mode === "sequence" && this.state.sequence && this.state.sequence !== "---" && this.state.sequence != null) url += ("&sequence=" + this.state.sequence.id);
    if (this.props.mode === "trimestre" && this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) url += ("&trimestre=" + this.state.trimestre.id);
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_29" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });

    fetch(
      url,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        self.props.closeSnackbar(key);
        self.setState({ inProgressGenerate: false });
        if (data.result_code === 1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_28" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
          //self.setState({countAnalysedNote:data.count,openSnackAnalysed:true});
          self.listMoyenne();
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_25" }), { variant: "warning", anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });

        }
      })
      .catch(err => {
        self.props.closeSnackbar(key);

        console.log(err);
        this.setState({ inProgressGenerate: false });
        alert("There was an error disabled.");
      });

  }
  handleGenerateBulletin(event) {
    cancelEventPropagation(event);
    this.setState({ inProgressGenerate: true });
    var self = this;
    var url = appProperties.basepathServer + '/manager/note/generate-bulletin?etablissement=' + this.state.etablissement.id;
    if (this.state.annee != null) url += ("&annee=" + this.state.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null) url += ("&classe=" + this.state.classe.id);
    else return;
    if (this.props.mode === "sequence" && this.props.sequence && this.props.sequence !== "---" && this.props.sequence != null) {
      console.log("handleGenerateBulletin");
      console.log(this.props.sequence);
      url += ("&sequence=" + this.props.sequence.id);
    }
    if (this.props.mode === "trimestre" && this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) url += ("&trimestre=" + this.state.trimestre.id);
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_29" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });

    fetch(
      url,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        self.props.closeSnackbar(key);
        self.setState({ inProgressGenerate: false });
        if (data.result_code === 1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_27" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
          //self.setState({countAnalysedNote:data.count,openSnackAnalysed:true});
          self.listMoyenne();
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_25" }), { variant: "warning", anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });

        }
      })
      .catch(err => {
        self.props.closeSnackbar(key);
        console.log(err);
        self.setState({ inProgressGenerate: false });
        alert("There was an error disabled.");
      });

  }
  /*handlePrintBulletin(event) {
    cancelEventPropagation(event);
    this.setState({ inProgressGenerate: true });
    var self = this;
    var url = appProperties.basepathServer + '/manager/note/print-bulletins?etablissement=' + this.state.etablissement.id;
    if (this.state.annee != null) url += ("&annee=" + this.state.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null) url += ("&classe=" + this.state.classe.id);
    else return;
    if (this.props.mode === "sequence" && this.props.sequence && this.props.sequence !== "---" && this.props.sequence != null) {
      url += ("&sequence=" + this.props.sequence.id);
    }
    if (this.props.mode === "trimestre" && this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) url += ("&trimestre=" + this.state.trimestre.id);

    fetch(
      url,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => {
        console.log("response");
        console.log(response);
        return response.json()
      })
      .then(data => {

        self.setState({ inProgressGenerate: false });
        if (data.result_code === 1) {
          this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_26" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
          //self.setState({countAnalysedNote:data.count,openSnackAnalysed:true});
          self.listMoyenne();
        } else if (data.result_code === -100) {
          this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_25" }), { variant: "warning", anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });

        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressGenerate: false });
        alert("There was an error disabled.");
      });

  }*/

  handleReset = () => {
    var self = this;
    this.setState({
      mode: self.prof.mode || null,
      classe: self.prof.classe || null,
      sequence: self.prof.sequence || null,
      trimestre: self.prof.trimestre || null,
    }, () => {
      self.listMoyenne();
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();

      });
    }

    if (!isObjectValueEquals(this.props.sequence, prevProps.sequence)) {
      this.setState({ sequence: this.props.sequence }, () => {
        self.listMoyenne();

      });
    }
    if (!isObjectValueEquals(this.props.trimestre, prevProps.trimestre)) {
      this.setState({ trimestre: this.props.trimestre }, () => {
        self.listMoyenne();

      });
    }
    if (!isObjectValueEquals(this.props.classe, prevProps.classe)) {
      this.setState({ classe: this.props.classe }, () => {
        self.listMoyenne();

      });
    }

  }
  componentDidMount() {
    this.listMoyenne();
  }


  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  listMoyenne() {
    let self = this;
    this.setState({ datas: [], progress: 1, loadChart: false, chart: null });
    var url = appProperties.basepathServer + '/manager/note/moyennes?etablissement=' + this.state.etablissement.id;
    if (this.state.annee != null) url += ("&annee=" + this.state.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null) url += ("&classe=" + this.state.classe.id);
    else return;
    if (this.props.mode === "sequence" && this.props.sequence && this.props.sequence !== "---" && this.props.sequence != null) {
      console.log("listMoyenne");
      console.log(this.props.sequence);
      url += ("&sequence=" + this.props.sequence.id);
    }
    if (this.props.mode === "trimestre" && this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) url += ("&trimestre=" + this.state.trimestre.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => {
        console.log("response json");
        console.log(response);
        return response.json()
      })
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {

          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ progress: 0, datas: data.datas });
          if (data.datas.length > 0) self.buildChart(data.datas)

        } else {
          self.setState({ datas: [], progress: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log('There was an error listed enseignant.');
      });
  }


  buildChart(datas) {
    var countSucess = 0;
    var array = datas || [];
    array.sort((item1, item2) => {
      return (parseFloat(item1.moyenne) === parseFloat(item2.moyenne)) ? 0 :
        (parseFloat(item1.moyenne) < parseFloat(item2.moyenne) ? 1 : -1);
    });
    var somme = 0;
    array.forEach(e => {
      somme += parseFloat(e.moyenne);
      if (parseFloat(e.moyenne) >= 10) countSucess++; //10 est considéré comme la moyenne 
    });
    var countEchec = array.length - countSucess;
    var moyenne = array.length > 0 ? getRound2(somme / array.length) : 0;
    this.setState({ chart: { moyenne: moyenne, sucess: countSucess, echec: countEchec, major: array.length > 0 ? array[0] : null, minor: array.length > 0 ? array[array.length - 1] : null }, loadChart: true })
  }

  handlePrint = (url, isArchive) => {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    // var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -1000) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });


              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {

        try {
          var pdfUrl = URL.createObjectURL(blob);
          if (isArchive) {
            const a = document.createElement("a");
            a.href = url;
            var now = new Date();
            a.download = 'Impression_' + now.getTime() + '_pdf.zip';
            a.click();
          } else self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }



      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }


  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }
  handlePrintTH = (url) => {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    // var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -1000) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1001) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemMoyenne_9" }), { variant: "warning", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {

        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }



      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }


  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    const { chart } = this.state;
    var printBaseUrl = appProperties.basepathServer + '/manager/note/print-bulletins?etablissement=' + this.state.etablissement.id;
    if (this.state.annee != null) printBaseUrl += ("&annee=" + this.state.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null) printBaseUrl += ("&classe=" + this.state.classe.id);
    //else return;
    if (this.props.mode === "sequence" && this.props.sequence && this.props.sequence !== "---" && this.props.sequence != null) {
      printBaseUrl += ("&sequence=" + this.props.sequence.id);
    }
    if (this.props.mode === "trimestre" && this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) printBaseUrl += ("&trimestre=" + this.state.trimestre.id);



    var printBaseTHUrl = appProperties.basepathServer + '/manager/note/print-tableau-honneur?etablissement=' + this.state.etablissement.id;
    if (this.state.annee != null) printBaseTHUrl += ("&annee=" + this.state.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null) printBaseTHUrl += ("&classe=" + this.state.classe.id);
    //else return;
    if (this.props.mode === "sequence" && this.props.sequence && this.props.sequence !== "---" && this.props.sequence != null) {
      printBaseTHUrl += ("&sequence=" + this.props.sequence.id);
    }
    if (this.props.mode === "trimestre" && this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) printBaseTHUrl += ("&trimestre=" + this.state.trimestre.id);

    return (
      <React.Fragment>

        <ExpansionPanel style={{ marginBottom: '10px' }} expanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              {this.props.mode === "sequence" && this.props.sequence != null && ("Bulletins " + this.props.sequence.name)}
              <span style={{ fontWeight: '600' }}>{this.props.mode === "trimestre" && this.props.trimestre != null && ("Bulletins " + this.props.trimestre.name)}</span>
              <span style={{ fontWeight: '600' }}>{this.props.mode === "annuelle" && this.props.annee != null && ("Bulletins annuelle " + this.props.annee.year)}</span>
            </Typography>
            <Typography className={classes.secondaryHeading}></Typography>
            <div className='layout horizontal center  end-justified flex'>
              <div className="layout horizontal center flex">
                <div className="flex">
                  {this.state.inProgressGenerate
                    ? <div className={classes.progress}><CircularProgress thickness={1} size={30} className={classes.fabProgress} /></div>
                    : null
                  }
                </div>
                <Button
                  title={formatMessage({ id: "ListMoyenne_24" })}
                  disabled={this.state.inProgressGenerate}
                  variant="contained"
                  onClick={this.handleGenerateMoyenne}
                  className={classes.buttonPrincipale}>
                  {formatMessage({ id: "ListMoyenne_23" })}
                </Button>
                <Button
                  title={formatMessage({ id: "ListMoyenne_22" })}
                  disabled={this.state.inProgressGenerate}
                  variant="contained"
                  onClick={this.handleGenerateBulletin}
                  className={classes.buttonPrincipale}>
                  {formatMessage({ id: "ListMoyenne_21" })}
                </Button>

                <Button
                  onClick={() => this.handlePrint(printBaseUrl, false)}
                  title={formatMessage({ id: "ListMoyenne_20" })}
                  disabled={this.state.inProgressGenerate}
                  variant="contained"
                  className={classes.buttonPrincipale}>
                  {formatMessage({ id: "ListMoyenne_19" })}
                </Button>
                {((this.props.mode === "trimestre" && this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) || this.props.mode === "annuelle") &&
                  <Button variant="contained" title={formatMessage({ id: "ListMoyenne_39" })}
                    onClick={() => this.handlePrintTH(printBaseTHUrl)}
                    className={classes.buttonPrincipale}
                  >
                    {formatMessage({ id: "ListMoyenne_38" })}
                  </Button>
                }
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="layout vertical">
            {this.state.progress === 0 && (
              <React.Fragment>

                {this.state.loadChart && (
                  <div className="layout horizontal center center-justified">
                    <div>
                      <Chart
                        width={'450px'}
                        height={'300px'}
                        chartType="PieChart"
                        loader={<div style={{ padding: '15px', fontSize: '12px' }}>{formatMessage({ id: "TauxIndisciplineEleve_3" })}</div>}
                        data={[
                          ['Status', 'Ëffectif'],
                          ['Ils ont réussis', chart.sucess],
                          ["Ont échoués", chart.echec],
                        ]}
                        options={{
                          title: formatMessage({ id: "ListMoyenne_18" }),
                          // Just add this option
                          is3D: true,
                          colors: ['#2196f3', '#ff9800'],
                        }}
                        rootProps={{ 'data-testid': '2' }}
                      />
                    </div>
                    <div style={{ padding: '0 10px' }} className="layout vertical">
                      <div className="layout horizontal center">
                        <Avatar className={classes.primaryAvatar}>P</Avatar>
                        <div style={{ padding: '0 10px' }} className="layout vertical">
                          <div className="layout horizontal center">
                            <span style={{ fontSize: '16px', color: 'var(--app-primary-color)', fontWeight: '500' }} >{formatMessage({ id: "ListMoyenne_17" })}</span>
                            <span style={{ padding: '0 10px', fontSize: '20px', color: 'var(--app-primary-color)', fontWeight: '600' }} >{chart.major != null ? getRound2(chart.major.moyenne) : "---"}</span>
                          </div>
                          <div className="layout horizontal center">
                            <span style={{ fontSize: '12px', fontWeight: '500' }} >{chart.major != null ? (chart.major.eleve.fullname || '@' + chart.major.eleve.user.name) : "---"}</span>
                            <span style={{ padding: '0 10px', fontSize: '12px', fontWeight: '600' }} >({chart.major != null ? chart.major.eleve.matricule : "---"})</span>
                          </div>
                        </div>
                      </div>
                      <div className="layout horizontal center">
                        <Avatar className={classes.orangeAvatar}>D</Avatar>
                        <div style={{ padding: '0 10px' }} className="layout vertical">
                          <div className="layout horizontal center">
                            <span style={{ fontSize: '16px', color: '#ff9800', fontWeight: '500' }} >{formatMessage({ id: "ListMoyenne_16" })}</span>
                            <span style={{ padding: '0 10px', fontSize: '20px', color: '#ff9800', fontWeight: '600' }} >{chart.minor != null ? getRound2(chart.minor.moyenne) : "---"}</span>
                          </div>
                          <div className="layout horizontal center">
                            <span style={{ fontSize: '12px', fontWeight: '500' }} >{chart.minor != null ? (chart.minor.eleve.fullname || '@' + chart.minor.eleve.user.name) : "---"}</span>
                            <span style={{ padding: '0 10px', fontSize: '12px', fontWeight: '600' }} >({chart.minor != null ? chart.minor.eleve.matricule : "---"})</span>
                          </div>
                        </div>
                      </div>
                      <div className="layout horizontal center">
                        <Avatar className={classes.blueAvatar}>M</Avatar>
                        <div style={{ padding: '0 10px' }} className="layout vertical">
                          <div className="layout horizontal center">
                            <span style={{ fontSize: '16px', color: 'var(--paper-blue-900)', fontWeight: '500' }} >{formatMessage({ id: "ListMoyenne_15" })}</span>
                            <span style={{ padding: '0 10px', fontSize: '20px', color: 'var(--paper-blue-900)', fontWeight: '600' }} >{chart.moyenne}</span>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell onClick={this.doOrderByName} title={formatMessage({ id: "ListMoyenne_11" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "RapportIndisciplineEleve_2" })}</span></div></CustomTableCell>
                      <CustomTableCell onClick={this.doOrderByRang} title={formatMessage({ id: "ListMoyenne_10" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "ListMoyenne_14" })}</span></div></CustomTableCell>
                      <CustomTableCell title={formatMessage({ id: "ListMoyenne_7" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><span>{formatMessage({ id: "ListMoyenne_13" })}</span></div></CustomTableCell>
                      <CustomTableCell title={formatMessage({ id: "ListMoyenne_8" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><span>{formatMessage({ id: "ListMoyenne_12" })}</span></div></CustomTableCell>
                      <CustomTableCell title={formatMessage({ id: "ListMoyenne_9" })} style={{ cursor: 'pointer' }} align="left"><div className="layout horizontal center" ><SortIcon /><span>{formatMessage({ id: "ListMoyenne_4" })}</span></div></CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.datas.map((row, index) => (
                      <ItemMoyenne key={row.id + " " + index}
                        onUpdateItem={this.onUpdateItem}
                        etablissement={this.state.etablissement}
                        position={index}
                        sequence={this.state.sequence}
                        trimestre={this.state.trimestre}
                        mode={this.state.mode}
                        annee={this.state.annee}
                        classe={this.state.classe}
                        row={row}
                        {...deleteClassInProps(this.props)} />


                    ))}
                  </TableBody>
                </Table>
                {/* <LdPagination onShowPage={this.setCurrentPage} pages={this.state.pages} /> */}
              </React.Fragment>
            )}

            {(this.state.progress === 1) && (this.state.classe === "---" || (this.state.sequence === "---" && this.props.mode === "sequence") || (this.state.trimestre === "---" && this.props.mode === "trimestre")) && (
              <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                {/* <div style={{padding:'15px'}}>
                  <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                </div> */}
                <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)', textAlign: 'center' }} className="layout horizontal center-center">
                  {<span> {formatMessage({ id: "ListMoyenne_2" })} </span>}
                </div>
              </div>
            )}
            {(this.state.progress === 1) &&
              ((this.state.classe !== "---" && (this.props.mode === "sequence" && this.state.sequence !== "---" && this.state.sequence !== null)) ||
                (this.state.classe !== "---" && (this.props.mode === "trimestre" && this.state.trimestre !== "---" && this.state.trimestre !== null)) ||
                (this.state.classe !== "---" && (this.props.mode === "annuelle"))) && (
                <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                  <div style={{ padding: '15px' }}>
                    <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                  </div>
                  <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                    {formatMessage({ id: "ListRoleView_Load" })}
                  </div>
                </div>
              )}
            {(this.state.progress === 2 || (this.state.progress === 0 && this.state.datas.length === 0)) && (
              <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                  {formatMessage({ id: "ListMoyenne_1" })}
                </div>
              </div>
            )}
            {this.state.progress === 3 && (
              <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })} </div>
                <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                  {formatMessage({ id: "ListRoleView_LoadError" })}
                </div>
              </div>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
  }
}
export default withSnackbar(withStyles(styles)(injectIntl(ListMoyenne)));
