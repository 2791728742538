import AppBar from '@material-ui/core/AppBar';
import Avatar from "@material-ui/core/Avatar";
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import withStyles from "@material-ui/core/styles/withStyles";
// import ItemDossiermatiere from './ItemDossiermatiere';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotesIcon from '@material-ui/icons/Notes';
import SortIcon from '@material-ui/icons/Sort';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl, intlShape } from 'react-intl';
import { appProperties, isObjectValueEquals, userProfileUrl } from "../components/LdUtils";



const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    backgroundColor: theme.color.primaryDark,
    border: '2px solid white',
    width: "100px !important",
    height: "100px !important",
    borderRadius: '0 !important',
    color: 'white'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },


  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },





  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  toolbar: {
    height: '64px',
    backgroundColor: 'var(--app-primary-color);',
    padding: ' 0 10px',

  },
  toolbarTitle: {
    fontSize: '16px',
    color: 'white',
    fontWeight: '600',
    display: 'inline'
  },
  toolbarSubTitle: {
    fontSize: '12px',
    // fontWeight:'600',
    color: '#FEFEFE'
  },
  avatarTitle: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "40px !important",
    height: "40px !important",
    borderRadius: '50% !important'
  },
  avatarDivtitle: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: '50% !important',
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  dossierZone: {
    padding: '10px 0',
    margin: '2px 0',
  },
  dossierTexte: {

    color: 'white',
    padding: '5px',
    backgroundColor: 'var(--app-primary-color)',
    fontsize: '17px',
    fontWeight: '600',
  },
  dialogRoot: {
    top: '64px !important',
    left: '25% !important',
    right: '0 !important',
    bottom: '0 !important',
    // z-index: 1300;
    // position: fixed;
    paddingTop: "15px !important",
    paddingLeft: '18px !important',
    paddingRight: '45px !important'
  },
  backdrop: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 600
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  indisciplineAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-orange-500)',
  },
  scolariteAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-orange-500)',
  },
  scolariteGreen: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-green-500)',
  },
  scolariteYellow: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-yellow-500)',
  },
  panelRoot: {
    borderRadius: '0 !important',
  },
  panelSummary: {
    alignItems: 'center',
    margin: '0 !important',
    borderBottom: '1px dashed #EAEAEA'
  },
  summaryRoot: {
    padding: '0',

  },
  summaryExpanded: {
    margin: '0 !important',

  },
  fraisBadge: {
    top: '15px',
    right: '15px'
  },

});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

// const PostDate = injectIntl(({date, intl}) => (
//   <span  title={intl.formatDate(date)}>
//       {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
//       <FormattedDate 
//        day="numeric"
//        month="long"
//        year="numeric"
//        value={date}/>
//   </span>
// ));
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: '600',
    fontSize: '15px',
    padding: '4px 7px 4px 7px'
  },
  body: {
    fontSize: 14,
    padding: '4px 7px 4px 7px'
  }
}))(TableCell);
class RapportReussite extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);

    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      position: this.props.position,
      etablissement: this.props.etablissement,
      controlled: true,
      openDialog: false,
      progress: 1,
      expanded: this.props.expanded || 'filled',
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      currentPage: 1,
      result_add: 0
    };//          


    this.handleClickOpenDialog = this.handleClickOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);

    this.doOrderByName1 = this.doOrderByName1.bind(this);
    this.doOrderByMatricule1 = this.doOrderByMatricule1.bind(this);
    this.doOrderByNote1 = this.doOrderByNote1.bind(this);


  }

  doOrderByName1 = (event) => {
    var array = this.state.datas;
    array.sort((item1, item2) => {
      return (item1.user != null && item1.user.name != null &&
        item2.user != null && item2.user.name != null) ?
        item1.user.name.localeCompare(item2.user.name) : 0;
    });
    this.setState({ datas: array });

  };

  doOrderByMatricule1 = (event) => {
    var array = this.state.datas;
    array.sort((item1, item2) => {
      return (item1.matricule != null && item2.matricule != null) ?
        item1.matricule.localeCompare(item2.matricule) : 0;
    });
    this.setState({ datas: array });

  };
  doOrderByNote1 = (event) => {
    var array = this.state.datas;
    array.sort((item1, item2) => {
      return parseFloat(item1.moyenne) === parseFloat(item2.moyenne) ? 0 : (parseFloat(item1.moyenne) < parseFloat(item2.moyenne) ? 1 : -1);

    });
    this.setState({ datas: array });

  };

  handleClickOpenDialog = () => {
    this.setState({ openDialog: true });
  };
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  handleCloseDialog = () => {
    var self = this;
    this.setState({ openDialog: false }, () => {
      self.props.onClose(this);
    });

  };
  componentDidMount() {
    this.props.onRef(this);
    this.loadRapport(this.state.currentPageEleve);
  }
  componentWillUnmount() {
    this.props.onRef(null);
  }
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.loadRapport();

      });
    }
    if (!isObjectValueEquals(this.props.classe, prevProps.classe)) {
      this.setState({ classe: this.props.classe }, () => {
        self.loadRapport();

      });
    }
    if (!isObjectValueEquals(this.props.trimestre, prevProps.trimestre)) {
      this.setState({ trimestre: this.props.trimestre }, () => {
        self.loadRapport();

      });
    }

    if (!isObjectValueEquals(this.props.sequence, prevProps.sequence)) {
      this.setState({ sequence: this.props.sequence }, () => {
        self.loadRapport();

      });
    }
    if (!isObjectValueEquals(this.props.matiere, prevProps.matiere)) {
      this.setState({ matiere: this.props.matiere }, () => {
        self.loadRapport();

      });
    }
  }


  loadRapport(page) {

    let self = this;
    var url = appProperties.basepathServer + '/manager/statistics/reussites-by-eleve?etablissement=' + this.props.etablissement.id + "&limit=-1";;
    if (this.props.annee != null && this.props.annee !== '---') url += ("&annee=" + this.props.annee.id);
    if (this.props.trimestre != null && this.props.trimestre !== "---") url += ("&trimestre=" + this.props.trimestre.id);
    if (this.props.sequence != null && this.props.sequence !== "---") url += ("&sequence=" + this.props.sequence.id);
    if (this.props.classe != null && this.props.classe !== "---") url += ("&classe=" + this.props.classe.id);
    else return;
    if (this.props.matiere != null && this.props.matiere !== "---") url += ("&enseignant=" + this.props.matiere.id);
    this.setState({ datas: [], progress: 1 });

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          var w = data.datas || [];

          w.sort((item1, item2) => {
            return (item1.user != null && item1.user.name != null &&
              item2.user != null && item2.user.name != null) ?
              item1.user.name.localeCompare(item2.user.name) : 0;
          });
          self.setState({ datas: w });
          self.setState({ progress: 0, datas: w, count: data.count, hasMore: data.hasMore, pages: data.pages });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log('There was an error listed enseignant.');
      });
  }


  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    const { expanded, datas } = this.state;

    return (
      <React.Fragment>
        <div>

          <Dialog
            fullScreen
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
            TransitionComponent={Transition}
            classes={{ root: classes.dialogRoot }}
            BackdropProps={{
              classes: {
                root: classes.backdrop
              }
            }}
          >
            <AppBar className={classes.appBar}>
              <Grid container className={classes.toolbar} justify="space-between" alignItems='center'>
                <div className={classes.avatarDivtitle}>
                  <Avatar className={classes.avatarTitle}>
                    <NotesIcon />
                  </Avatar>
                </div>
                <div className="layout vertical flex">
                  <div>
                    <Typography rel="noopener noreferrer" target="_blank" color="inherit" className={classes.toolbarTitle}>
                      {formatMessage({ id: "ItemEnseignant_6" })} <b>{(this.props.classe ? this.props.classe.name : '')}</b>
                    </Typography>
                  </div>

                  <Typography color="inherit" className={classes.toolbarSubTitle}>
                    {this.props.matiere != null && this.props.matiere !== "---" ? (
                      <span>{formatMessage({ id: "ItemEnseignant_7" })}<b>{this.props.matiere.matiereName || '#' + this.props.matiere.matiere.name}</b> </span>
                    ) : (
                        <span>{formatMessage({ id: "ListEnseignantView_2" })}</span>
                      )}

                  </Typography>
                </div>

                <Button title={formatMessage({ id: "BonDemande_13" })} style={{ color: 'white', textTransform: "none" }} onClick={this.handleCloseDialog}>
                  <CloseIcon />
                  {formatMessage({ id: "BonDemande_12" })}
                </Button>
              </Grid>

            </AppBar>
            <div style={{ padding: '15px', overflowY: 'auto' }}>

              <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'filled'} onChange={this.handleChange('filled')}>
                <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
                  <div style={{ position: 'relative' }}>
                    <Badge classes={{ badge: classes.fraisBadge }} badgeContent={datas ? datas.length : 0} color="primary">
                      <Avatar className={classes.scolariteGreen}>
                        <ThumbUpAltIcon />
                      </Avatar>
                    </Badge>
                    {this.state.progress === 1 &&
                      <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
                    }
                  </div>

                  <div>
                    <Typography className={classes.heading}>{formatMessage({ id: "RapportReussite_3" })}</Typography>
                    <Typography className={classes.secondaryHeading}>{formatMessage({ id: "RapportReussite_4" })}</Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: '10px 0 10px 20px' }} className="layout vertical">
                  {(this.state.progress === 0 && datas.length > 0 &&
                    <React.Fragment>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <CustomTableCell onClick={this.doOrderByName1} title={formatMessage({ id: "ListCahierMatiere_13" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "RapportIndisciplineEleve_2" })}</span></div></CustomTableCell>
                            <CustomTableCell onClick={this.doOrderByMatricule1} title={formatMessage({ id: "RapportIndisciplineEleve_6" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "RapportIndisciplineEleve_3" })}</span></div></CustomTableCell>
                            <CustomTableCell onClick={this.doOrderByNote1} title={formatMessage({ id: "RapportReussite_1" })} style={{ cursor: 'pointer' }} align="left"><div className="layout horizontal center end-justified" ><SortIcon /><span>{formatMessage({ id: "RapportReussite_2" })}</span></div></CustomTableCell>
                          </TableRow>
                        </TableHead>
                        {((datas && datas.length > 0) &&
                          <TableBody>
                            {datas.map((row, index) => (
                              <TableRow className={classes.row} key={row.id}>
                                <CustomTableCell component="th" scope="row">
                                  <div>
                                    <div>
                                      <a style={{ fontWeight: "600", fontSize: '12px' }} className="no-underline capitalize" title={'@' + row.user.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.user)}>
                                        {row.fullname || '@' + row.user.name}
                                      </a>
                                    </div>
                                    <div>{row.user.email}</div>
                                  </div>

                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  {row.matricule}
                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  <div className="layout horizontal end-justified">
                                    <b>{row.moyenne}</b>
                                    <span style={{ fontSize: '12px' }}>&nbsp;{formatMessage({ id: "RapportPaiement_6" })} &nbsp;</span>
                                    <b>{20}</b>
                                  </div>
                                </CustomTableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                    </React.Fragment>
                  )}
                  {this.state.progress === 1 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>
                        <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                      </div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_Load" })}
                      </div>
                    </div>
                  )}

                  {((this.state.progress === 2 || (this.state.progress === 0 && datas.length === 0)) &&
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "RapportIndisciplineEleve_1" })}
                      </div>
                    </div>
                  )}
                  {this.state.progress === 3 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_LoadError" })}
                      </div>
                    </div>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </Dialog>
        </div>

      </React.Fragment>
    );
  }
}
RapportReussite.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withStyles(styles, { withTheme: true })(injectIntl(RapportReussite));
