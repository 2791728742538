import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { isObjectValueEquals } from "./LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {},
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto  !important"
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },

  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paperMenu: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}
function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paperMenu}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}
function MultiValue(props) {
  //TODO: Use this in select multiple
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}
const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer
};
class LdSelectStatic extends Component {
  constructor(props) {
    super(props);
    const { formatMessage } = this.props.intl;
    this.state = {
      search: "",
      //baseUrl: props.baseUrl,
      options: this.props.options || [],
      selectedOption: null,
      result: null,
      //datas: [],
      inProgress: false,
      isSearchable: props.isSearchable || false,
      isMulti: props.isMulti || false,
      label: props.label || formatMessage({ id: "select_" }),
      defaultValue: props.defaultValue || (props.isMulti ? [] : null)
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
    this.selectRef = React.createRef();
  }

  componentDidMount() {
    //this.refreshList("");
  }
  componentDidUpdate(prevProps) {
    var self = this;
    // if(this.props.baseUrl !==  prevProps.baseUrl){
    //   this.setState({baseUrl:this.props.baseUrl},()=>{
    //      self.refreshList("");
    //   });
    // }
    if (!isObjectValueEquals(this.props.defaultValue, prevProps.defaultValue)) {
      self.setState({ defaultValue: self.props.defaultValue }, () => { });
    }
    if (!isObjectValueEquals(this.props.options, prevProps.options)) {
      self.setState({ options: self.props.options }, () => { });
    }
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({
        defaultValue: this.props.defaultValue || (this.props.isMulti ? [] : null),
      }, () => {
      });
    }
  }
  handleSelectInputChange = event => {
    this.setState({ search: event.target.value });
    //this.refreshList(event.target.value);
  };
  handleChange(item) {
    this.props.handleValue(item);
  }

  render() {
    const { classes, theme } = this.props;
    const { formatMessage } = this.props.intl;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      })
    };

    return (
      <div className={classes.root}>
        <Select
          ref={this.selectRef}
          placeholder={this.state.label}
          defaultValue={this.state.defaultValue}
          onChange={this.handleChange}
          onKeyDown={this.handleSelectInputChange}
          options={this.state.options}
          isSearchable={this.state.isSearchable}
          isMulti={this.props.isMulti}
          components={components}
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            label: formatMessage({ id: "LdSelectUser_1_Search" }),
            InputLabelProps: {
              shrink: true
            }
          }}
          isClearable
        />
      </div>
    );
  }
}
LdSelectStatic.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(
  injectIntl(LdSelectStatic)
);
