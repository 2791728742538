import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import { appProperties, isObjectValueEquals } from '../../../components/LdUtils';
import ItemEleve from "../eleve/ItemEleve";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';



import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { deleteClassInProps } from '../../../components/LdUtils';
import { injectIntl } from "react-intl";
import printJS from 'print-js'
import { withSnackbar } from "notistack";
import Button from "@material-ui/core/Button";

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";

const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: '15px',
    marginTop: '15px',
    backgroundColor: 'white',
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: '17px',
    lineHeight: '22px',
    fontWeight: 400,
    paddingBottom: '15px'
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },





  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },

});

class ListInsolvableView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      classes: [],
      classe: "---",//use to fix bug
      typesFrais: [],
      typeFrais: "---",//use to fix bug
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,

      labelWidth: 0,



      categories: [],
      categorie: "---",
    }
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeTypeFrais = this.handleChangeTypeFrais.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);

  }
  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value), classe: "---", classes: [], typesFrais: [], typeFrais: "---" }, (state) => {
      self.listClasses();
      self.setCurrentPage(1);
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.setCurrentPage(1);
      });
    }
  }
  handleChangeTypeFrais = event => {
    let self = this;
    var typeFrais = event.target.value;
    self.setState({ typeFrais: typeFrais }, (state) => {
      self.setCurrentPage(1);
    });
  };
  handleChangeClass = event => {
    console.log("change classe");
    console.log(event.target.value);
    let self = this;
    var classe = event.target.value;

    this.setState({ classe: (event.target.value) }, (state) => {
      self.setState({ classe: classe }, (state) => {
        var typesFrais = (classe != null && classe.typesFrais) ? classe.typesFrais : [];
        self.setState({ typesFrais: typesFrais, typeFrais: '---' });
        /** if(typesFrais!=null && typesFrais.length > 0){
          var typeFrais = typesFrais[0];
          self.setState({typeFrais: typeFrais},(state)=>{
            self.setCurrentPage(1);
          });
        }else*/
        self.setCurrentPage(1);
      });
    });
  };
  // handleChangeClass(item) {
  //   let self = this;
  //   this.setState({ classe: (item?item:null) },(state)=>{
  //     self.setCurrentPage(1);
  //   });

  // };
  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listInsolvable(page);
  };

  componentDidMount() {
    this.listCategorie();

    //this.listClasses();
    this.listInsolvable(this.state.currentPage);
  }
  componentWillUnmount() {
  }



  listClasses() {
    let self = this;
    this.setState({ classes: [] });
    var url = appProperties.basepathServer + "/manager/annee-scolaire/classes?id=" + this.state.annee.id + "&limit=-1";
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [] });
      return;
    }
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ classes: data.datas });
          // if (data.datas != null && data.datas.length > 0) {
          //   var classe = data.datas[0];

          //   self.setState({ classe: classe }, (state) => {
          //     var typesFrais = classe.typesFrais ? classe.typesFrais : [];
          //     self.setState({ typesFrais: typesFrais });
          //     self.listInsolvable(1);

          //   });
          // }

        } else {
          self.setState({ classes: [], classe: '' });

        }

      })
      .catch((err) => {
        console.log(err);
        self.setState({ classes: [], classe: '' });
      });
  }

  listInsolvable(page) {

    let self = this;

    this.setState({ datas: [], progress: 1 });
    var url = appProperties.basepathServer + '/manager/scolarite/list-unpaid-eleves?etablissement=' + this.state.etablissement.id + '&limit=10&page=' + page;
    if (this.state.annee != null) url += ("&annee=" + this.state.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null && this.state.classe.classeEtablissement != null) url += ("&classe_etablissement=" + this.state.classe.classeEtablissement.id);
    if (this.state.typeFrais && this.state.typeFrais !== "---" && this.state.typeFrais != null) url += ("&type=" + this.state.typeFrais.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ progress: 0, datas: data.datas, count: data.count, hasMore: data.hasMore, pages: data.pages });
        } else {
          self.setState({ datas: [], progress: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log('There was an error listed enseignant.');
      });
  }

  handlePrint = event => {

    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    var url =
      appProperties.basepathServer +
      "/manager/scolarite/print-unpaid-eleves?etablissement=" +
      this.state.etablissement.id +
      "&limit=-1&page=1";
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;
    if (
      this.state.classe &&
      this.state.classe !== "---" &&
      this.state.classe != null &&
      this.state.classe.classeEtablissement != null
    )
      url += "&classe_etablissement=" + this.state.classe.classeEtablissement.id;
    if (
      this.state.typeFrais &&
      this.state.typeFrais !== "---" &&
      this.state.typeFrais != null
    ) url += "&type=" + this.state.typeFrais.id;
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);

    // if (this.state.minDate && this.state.minDate != null)
    //   url +=
    //     "&from=" + moment(this.state.minDate).format("YYYY-MM-DD HH:mm:ss");
    // else {
    //   alert(self.props.intl.formatMessage({ id: "ItemVersement_10" }));
    //   return;
    // }
    // if (this.state.maxDate && this.state.maxDate != null)
    //   url += "&to=" + moment(this.state.maxDate).format("YYYY-MM-DD HH:mm:ss");
    // else {
    //   alert(self.props.intl.formatMessage({ id: "ItemVersement_10" }));
    //   return;
    // }
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -3) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemVersement_11" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -4) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_5" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        }finally{
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }

  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon /> {/*{self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      persist: true,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }

  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <div className={classes.pageTitle} variant="div" component="div">
          {formatMessage({ id: "ItemInsolvableView_1" })}
        </div>
        <Grid container className={classes.grid} justify="flex-end" alignItems='center'>

          <div className="layout horizontal center">

            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "ListClasseEtablissementView_4"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.categorie}
                onChange={this.handleChangeCategorie}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListClasseEtablissementView_3" })}
                </MenuItem>
                {this.state.categories.map((row, index) => (
                  <MenuItem title={row.description} key={index} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>

              <Select
                title={formatMessage({ id: "AjouterClasseEtablissementView_6" })}
                classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                value={this.state.classe}
                onChange={this.handleChangeClass}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                <MenuItem value={'---'}>{formatMessage({ id: "ListEleveView_1" })}</MenuItem>
                {this.state.classes.map((row, index) => (
                  <MenuItem key={index} value={row}>{row.classeEtablissement.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>

              <Select
                title={formatMessage({ id: "ItemInsolvableView_2" })}
                classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                value={this.state.typeFrais}
                onChange={this.handleChangeTypeFrais}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir un type de frais"
                    id="outlined-typefrais-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                <MenuItem value={'---'}>{formatMessage({ id: "ItemInsolvableView_3" })}</MenuItem>
                {this.state.typesFrais.map((row, index) => (
                  <MenuItem key={index} title={row.name} value={row}>{row.code + " " + row.amount + 'FCFA'}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={() => this.handlePrint()} title={formatMessage({ id: "ItemInsolvableView_5" })}
              style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }}
              variant="outlined" size="small" className={classes.margin}>
              {formatMessage({ id: "ItemInsolvableView_4" })}
            </Button>

          </div>
        </Grid>
        {this.state.progress === 0 && (
          <React.Fragment>
            <Paper elevation={0} className={classes.root}>
              <div style={{ marginTop: '15px' }}>
                {this.state.datas.map((row, index) => (
                  <ItemEleve key={index} onUpdateItem={this.onUpdateItem} etablissement={this.state.etablissement} position={index} row={row}  {...deleteClassInProps(this.props)} />
                ))}
              </div>
            </Paper>
            {this.state.pages && this.state.pages != null ? <LdPagination onShowPage={this.setCurrentPage} pages={this.state.pages} /> : null}

          </React.Fragment>)}

        {this.state.progress === 1 && (
          <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
            <div style={{ padding: '15px' }}>
              <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
            </div>
            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 || (this.state.progress === 0 && this.state.datas.length === 0)) && (
          <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
              {formatMessage({ id: "ListEleveView_2" })}
            </div>
          </div>
        )}
        {this.state.progress === 3 && (
          <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
            <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}

      </div>
    );
  }
}
export default withSnackbar(withStyles(styles)(injectIntl(ListInsolvableView)));
