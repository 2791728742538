import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, injectIntl, intlShape,FormattedMessage } from 'react-intl';
import ProgressiveImage from 'react-progressive-image';
import { appProperties, defaultAvatar, isObjectValueEquals, userProfileUrl } from "../../../components/LdUtils";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withSnackbar } from 'notistack';



const styles = theme => ({
  root:{
    display: "flex",
    justifyContent : "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content:{
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage:{
    width:"40px",
  },
  contentFlex:{
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent : "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
  
  progress: {
    width: "100%",
    flexGrow: 1
  },
 

  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  }, 
  buttonSimple:{
 
    textTransform:'none',
 
  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  name:{
    fontSize:"15px",
    fontWeight:'600',
    textDecoration:'none',
  },
  date:{
    fontSize:"12px",
    fontWeight:'400',
   
  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "64px !important",
    height:"64px !important",
    borderRadius:'0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding:2,
    // borderRadius:"50%",
    backgroundColor:theme.color.primaryDark,
    border:"2px solid #FFF",
    cursor:"pointer"
  },
  
  datePicker:{
    height:'40px',
    '& .react-date-picker__wrapper':{
      border:'1px dashed #AAAAAA',
      borderRadius:'5px'
    }
  },
  label:{
    fontSize:'15px',
    fontWeight:'500',
    padding:'0 10px 0 0'
  },
  error:{
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop:"15px"

  },
  
  
  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 130,
    '&  fieldset':{
      borderColor:'transparent !important',
    }
  },
  selectRoot:{
    height:'35px',
    
  },
  selectMenu:{
    padding:'7.5px 14px',
    backgroundColor: 'var(--app-primary-color)',
    color:'white',
    '&:focus':{
      backgroundColor: 'var(--app-primary-color)',
      color:'white',
    },
    borderRadius:'4px !important'
    
  },
  outlinedInput:{
    backgroundColor: 'var(--app-primary-color)',
    color:'white',
    borderRadius:'4px'
  }  /** Filter end */
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({date, intl}) => (
  <span  title={intl.formatDate(date)}>
      {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
      <FormattedDate 
       day="numeric"
       month="long"
       year="numeric"
       value={date}/>
  </span>
));

class ItemPreference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,
      jours: (this.props.row.jours!==null &&this.props.row.jours!=="")?this.props.row.jours.split('-'):[] ,
      priorite: this.props.row.priorite,
      user: this.props.row.user,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      classe: null,
      controlled:true,
      openConfirm:false,
      progressDelete:false,  
      row:this.props.row,
    };
    
    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChangePriorite = this.handleChangePriorite.bind(this);
    this.handleChangeJour = this.handleChangeJour.bind(this);
  } 

  handleChangeJour = value =>  event =>{
    var index = this.state.jours.indexOf(value);
    if(index<0){
      this.setState(state => {
            state.jours =  [value, ...state.jours] ;
            return state;
      });
    }else{
      var array = [...this.state.jours];
      array.splice(index,1);
      this.setState(state => {
        state.jours =  array ;
        return state;
      });
    }
  };
  handleChangePriorite =  event =>{
    this.setState({priorite:event.target.value});
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if(!isObjectValueEquals(this.props.etablissement,prevProps.etablissement)){
      this.setState({etablissement:this.props.etablissement},()=>{
      });
    } 
    if(!isObjectValueEquals(this.props.annee,prevProps.annee)){
      this.setState({annee:this.props.annee},()=>{
      });
    }
    if(!isObjectValueEquals(this.props.row,prevProps.row)){
      this.setState({row:this.props.row},()=>{
        self.setState({
          jours: (self.props.row.jours!==null &&self.props.row.jours!=="")?self.props.row.jours.split('-'):[] ,
          priorite: self.props.row.priorite,
          user: self.props.row.user,
        });
      });
    } 
    if(!isObjectValueEquals(this.props.position,prevProps.position)){
      this.setState({position:this.props.position},()=>{
      });
    }
  }
 
  handleEdit = () => {
    this.setState({modeEdit:true});
  }

  reset = () => {
    this.setState({modeEdit:false,controlled:true});
  }


  
  edit() {
    if(this.state.user == null || this.state.jours.length ===0){
      this.setState({controlled:false});
      return;
    }else{
      this.setState({controlled:true});
    }
   
    let self = this;
    this.setState({inProgress:true});
    var url = appProperties.basepathServer + "/manager/config-emploi-temps/set-config-enseignant?priorite="+this.state.priorite;
    if(this.props.annee!=null) url+=("&annee="+this.props.annee.id);
    if(this.props.row.user!=null) url+=("&user="+this.props.row.user.id);
    if(this.state.jours.length>0) url+=("&jours="+this.state.jours.join('-'));

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    
    })
      .then(response => response.json())
      .then(data => {
        this.setState({inProgress:false});
        if (data.result_code === 1) {
          self.reset();
          let datas = {position:self.state.position,row:data.item};
          self.props.onUpdateItem('update',datas);
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemPreference_1" }), {
            variant:"success",
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            },
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({inProgress:false});
        alert("There was an error added.");
      });
  }
  getJoursLabel(jour,formatMessage){
    var array =[
      formatMessage({ id: "AjouterBandeView_10" }),
      formatMessage({ id: "AjouterBandeView_11" }),
      formatMessage({ id: "AjouterBandeView_12" }),
      formatMessage({ id: "AjouterBandeView_13" }),
      formatMessage({ id: "AjouterBandeView_14" }),
      formatMessage({ id: "AjouterBandeView_15" }),
      formatMessage({ id: "AjouterBandeView_16" })];
    if(jour>=1 && jour<=7){
      return array[parseInt(jour)-1];
    }else return "";
   
  }

  render() {
    const { classes, row } = this.props;
    const {formatMessage} = this.props.intl;
    const jours = ((row && row.jours && row.jours!==null &&row.jours!=="")? row.jours.split('-'):[]).sort();
    return (
      <React.Fragment>


        <div className={classes.root}>  
            <div className={classes.content}>
              <div className={classes.contentFlex}>
             
                {this.state.modeEdit?
                  <main style={{width:"100%"}} className={classes.main}>
                    { this.state.inProgress
                      ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
                      : null
                    }
                    { !this.state.controlled
                      ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div> 
                      : null
                    }
                    <div className="layout vertical">
                      <div className="layout vertical">
                        <FormControl component="fieldset" className={classes.formControl}>
                          <FormLabel component="legend">{formatMessage({ id: "ItemPreference_2" })}</FormLabel>
                          <FormGroup className="layout horizontal center">
                            <FormControlLabel
                              control={<Checkbox checked={this.state.jours.indexOf('1')>-1} onChange={this.handleChangeJour('1')} value="1" />}
                              label={formatMessage({ id: "AjouterBandeView_10" })}
                            />
                            <FormControlLabel
                              control={<Checkbox checked={this.state.jours.indexOf('2')>-1} onChange={this.handleChangeJour('2')} value="2" />}
                              label={formatMessage({ id: "AjouterBandeView_11" })}
                            />
                            <FormControlLabel
                              control={ <Checkbox checked={this.state.jours.indexOf('3')>-1} onChange={this.handleChangeJour('3')} value="3" /> }
                              label={formatMessage({ id: "AjouterBandeView_12" })}
                            />
                            <FormControlLabel
                              control={ <Checkbox checked={this.state.jours.indexOf('4')>-1} onChange={this.handleChangeJour('4')} value="4" /> }
                              label={formatMessage({ id: "AjouterBandeView_13" })}
                            />
                            <FormControlLabel
                              control={ <Checkbox checked={this.state.jours.indexOf('5')>-1} onChange={this.handleChangeJour('5')} value="5" /> }
                              label={formatMessage({ id: "AjouterBandeView_14" })}
                            />
                            <FormControlLabel
                              control={ <Checkbox checked={this.state.jours.indexOf('6')>-1} onChange={this.handleChangeJour('6')} value="6" /> }
                              label={formatMessage({ id: "AjouterBandeView_15" })}
                            />
                            <FormControlLabel
                              control={ <Checkbox checked={this.state.jours.indexOf('7')>-1} onChange={this.handleChangeJour('7')} value="7" /> }
                              label={formatMessage({ id: "AjouterBandeView_16" })}
                            />
                          </FormGroup>
                          {/* <FormHelperText>Be careful</FormHelperText> */}
                        </FormControl>
                        <div className="layout  horizontal start-justified">
                          <FormControl style={{height:'35px'}} variant="outlined" className={classes.formControl}>
                            <Select
                                  title={formatMessage({ id: "ItemPreference_3" })}
                                  classes={{selectMenu:classes.selectMenu,root:classes.selectRoot}}
                                  value={this.state.priorite}
                                  onChange={this.handleChangePriorite}
                                  input={
                                    <OutlinedInput
                                      labelWidth={this.state.labelWidth}
                                      name="priorite"
                                      id="outlined-annee-simple"
                                      classes={{ input: classes.outlinedInput }}
                                    />
                                  }
                                >
                                <MenuItem  value={0}>{formatMessage({ id: "ItemPreference_4" })}</MenuItem>
                                <MenuItem  value={1}>{formatMessage({ id: "ItemPreference_5" })}</MenuItem>
                                <MenuItem  value={2}>{formatMessage({ id: "ItemPreference_6" })}</MenuItem>
                                <MenuItem  value={3}>{formatMessage({ id: "ItemPreference_7" })}</MenuItem>
                                <MenuItem  value={4}>{formatMessage({ id: "ItemPreference_8" })}</MenuItem>
                                <MenuItem  value={5}>{formatMessage({ id: "ItemPreference_9" })}</MenuItem>
                                <MenuItem  value={6}>{formatMessage({ id: "ItemPreference_10" })}</MenuItem>
                                <MenuItem  value={7}>{formatMessage({ id: "ItemPreference_11" })}</MenuItem>
                                  
                            </Select> 
                          </FormControl>
                        </div>
                       

                      </div>
                      
                      <div className="layout horizontal center end-justified">
                        <Button 
                          onClick={this.edit} 
                          variant="contained"
                          size="medium"
                          style={{margin:'5px'}}
                          className = {classes.buttonPrincipale}>
                          {formatMessage({ id: "ItemAnneeScolaire_Up" })}
                        </Button>
                        <Button 
                          onClick={this.reset} 
                          variant="contained"
                          size="medium"
                          style={{margin:'5px'}}
                          className = {classes.buttonGreyWhite}>
                          {formatMessage({ id: "ItemAnneeScolaire_Ccl" })}
                        </Button>
                      </div>
                    </div> 
                  </main>
                :
                <div>
                    <Grid container className={classes.grid} justify="flex-start" alignItems='center'>
                        <div>
                          <ProgressiveImage  src={row.user.photo} placeholder={ defaultAvatar }>
                            {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                          </ProgressiveImage> 
                        </div>
                        <div style={{paddingLeft:"15px"}}>    
                          <div style={{fontSize:"15px",fontWeight:"600"}}>  
                              <a className="no-underline capitalize" title={row.user.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.user)}>
                                {row.user.name}
                              </a> 
                              
                          </div>  
                          <div className="layout horizontal center">   
                              <span style={{fontWeight:"500",paddingRight:'10px',fontSize:'12px'}}>{formatMessage({ id: "ItemPreference_12" })} </span>
                              <span className="layout horizontal center" style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}>
                                {jours.map((jour,index)=> (
                                  <span key={index} style={{marginRight:'5px'}}>{this.getJoursLabel(jour,formatMessage)}</span>
                                ))}
                              </span>
                          </div>                                                                                                                                                                                    
                          <div className="layout horizontal center">   
                              <span style={{fontWeight:"500",paddingRight:'10px',fontSize:'12px'}}>{formatMessage({ id: "ItemPreference_13" })} </span>
                              <span style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}>{row.priorite}</span>
                          </div>    
                         
                                                       
                          <div  style={{fontWeight:"400"}} className={classes.date}>
                          <FormattedMessage id="date_user" values={{date:<PostDate date={row.created}/>,user:<a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'11px'}} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.creator)}>
                                {row.creator.name}
                              </a> }}/>
                            {/*Ajouté le  <PostDate date={row.created}/> par 
                          <a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'11px'}} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.creator)}>
                                {row.creator.name}
                                </a> */}
                          </div>
                        </div>
                        
                    </Grid>
                 
                </div>
                }
              </div>
            </div>
            <div className={classes.actions}>
              
              <IconButton
                title={formatMessage({id:"ItemAnneeScolaire_edit"})}
                aria-label="Editer"
                onClick={this.handleEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
            
            </div>
        </div>
      
     </React.Fragment>      
    );
  }
}
ItemPreference.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
  export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemPreference)));
