import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
// import AddIcon from '@material-ui/icons/Add';
import ListIcon from "@material-ui/icons/List";
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, FormattedTime, injectIntl, intlShape,FormattedMessage } from 'react-intl';
import { appProperties, isObjectValueEquals, userProfileUrl } from "../../../components/LdUtils";
import BonSortie from "./BonSortie";





const styles = theme => ({
  root:{
    display: "flex",
    justifyContent : "flex-start",
    // alignItems: "center",
    padding: '10px',
    borderBottom:'1px solid #EAEAEA',
    '&:hover':{
      backgroundColor:'#F1F1F1'
    }
  },
  
 
  content:{
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage:{
    width:"40px",
  },
  contentFlex:{
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent : "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
  
  progress: {
    width: "100%",
    flexGrow: 1
  },
 

  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  }, 
  buttonSimple:{
 
    textTransform:'none',
 
  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  name:{
    fontSize:"12px",
    fontWeight:'600',
    textDecoration:'none',
  },
  date:{
    fontSize:"12px",
    fontWeight:'400',
   
  },
  bigAvatar: {
    
    width: "40px !important",
    height:"40px !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding:2,
    borderRadius:"50%",
    backgroundColor:theme.color.primaryDark,
    border:"2px solid #FFF",
    cursor:"pointer"
  },
  
  datePicker:{
    height:'40px',
    '& .react-date-picker__wrapper':{
      border:'1px dashed #AAAAAA',
      borderRadius:'5px'
    }
  },
  label:{
    fontSize:'15px',
    fontWeight:'500',
    padding:'0 10px 0 0'
  },
  error:{
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop:"15px"

  },
  main:{
    width:'100%'
  }
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({date, intl}) => (
  <span title={intl.formatDate(date)}>
      {/* <FormattedRelative value={date}/> */}
      <FormattedDate 
       day="numeric"
       month="long"
       year="numeric"
       value={date}/>
         &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
       <FormattedTime 
       hour='numeric'
       minute= 'numeric'
       value={date}/>
  </span>
));

class ItemSortie extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      inProgressValidate: false,
      inProgressDone: false,
      modeEdit: false,
      position: this.props.position,
      notes: this.props.row.notes,
      code: this.props.row.code,
      // fournisseur: this.props.row.fournisseur,
      date: this.props.row.date == null|| this.props.row.date.trim() === ''? new Date(): new Date(this.props.row.date),
      controlled:true,
      etablissement:this.props.etablissement,
      openBonSortie:false,

    };
    this.bonSortieRef = React.createRef();

    this.handleEnabled = this.handleEnabled.bind(this);
    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleCloseBonSortie = this.handleCloseBonSortie.bind(this);
    this.handleClickOpenBonSortie = this.handleClickOpenBonSortie.bind(this);
    this.doUpdateSortie = this.doUpdateSortie.bind(this);
  } 
  
  handleClickOpenBonSortie = () => {
    var self = this;
    this.setState({ openBonSortie: true },()=>{
      setTimeout(()=>{
        self.bonSortieRef.handleClickOpenDialog();
      },0);
    });
  };

  handleCloseBonSortie = () => {
    this.setState({ openBonSortie: false });
    //this.dossierRef.doCloseDialog();
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if(!isObjectValueEquals(this.props.row,prevProps.row)){
      this.setState({row:this.props.row},()=>{
        self.setState({ 
        notes: self.props.row.notes || '',
        // code: self.props.row.code || '',
        // fournisseur: self.props.row.fournisseur || '',
        date: self.props.row.date == null|| self.props.row.date.trim() === ''? new Date(): new Date(self.props.row.date)
        });
      });
    }       
    if(!isObjectValueEquals(this.props.position,prevProps.position)){
      this.setState({position:this.props.position},()=>{
      });
    }
  }
  handleEdit = () => {
    this.setState({modeEdit:true});
  }
  reset = () => {
    this.setState({modeEdit:false,controlled:true});
  }
  handleDateChange(date){
    this.setState({
      date: date,
    });
  }
  // handleChangeNote(event){
  //   this.setState({notes:event.target.value});
  // }
  // handleChangeCode(event){
  //   this.setState({code:event.target.value});
  // }
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  doUpdateSortie(row){
    let datas = {position:this.state.position,row:row};
    this.props.onUpdateItemCommande('update',datas);
  }
  edit = () => {
    if(this.state.notes == null ||this.state.notes.trim() === "" || this.state.date == null){
      this.setState({controlled:false});
      return;
    }else{
      this.setState({controlled:true});
    }
    var formData = new FormData(); // Currently empty 
    formData.append("notes", this.state.notes);
    formData.append("id", this.props.row.id);
    // formData.append("fournisseur", this.state.fournisseur);
    // if(this.props.annee && this.props.annee != null && this.props.annee!=='---')formData.append("annee", this.props.annee.id);
    // formData.append("date", moment(this.state.date).format('YYYY-MM-DD HH:mm:ss'));

    let self = this;
    this.setState({inProgress:true});
    fetch(appProperties.basepathServer + "/manager/sortie-materiel/edit?id="+this.props.row.id, {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.reset();
          let datas = {position:self.state.position,row:data.row};
          self.props.onUpdateItemCommande('update',datas);
          self.props.enqueueSnackbar("La sortie a été modifiée avec succès", { variant:"success", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else{
          self.props.enqueueSnackbar("Les données ne sont pas valides", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }
        this.setState({inProgress:false});

      })
      .catch(err => {
        console.log(err);
        this.setState({inProgress:false});
        alert("There was an error added.");
      });
  }
  
  handleEnabled = () => {
    var self = this;
    this.setState({inProgressValidate:true});
    fetch(
      appProperties.basepathServer +
        "/manager/sortie-materiel/validate?id=" +
        this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        this.setState({inProgressValidate:false});

        if (data.result_code === 1) {
          let datas = {position:self.state.position,row:data.item};
          self.props.onUpdateItemCommande('update',datas);
          self.props.enqueueSnackbar("La sortie a été validée", { variant:"success", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else if(data.result_code ===-100){
          self.props.enqueueSnackbar("Vous n'avez pas d'autorisation ncessaire pour valider cette sortie", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else  if (data.result_code === -2){
          self.props.enqueueSnackbar("Cette sortie ne peut pas etre validée", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({inProgressValidate:false});

        alert("There was an error added.");
      });
  };
  render() {
    const { classes, row } = this.props;
    const {formatMessage} = this.props.intl;
    
    return (
      <React.Fragment>
        <div className={classes.root}>  
            <div className={classes.content}>
              <div className={classes.contentFlex}>
             
                {this.state.modeEdit?
                  <main className={classes.main}>
                  { this.state.inProgress
                    ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
                    : null
                  }
                  { !this.state.controlled
                    ? <div className={classes.error}>{formatMessage({id:'AjouterAnneeScolaire_Check'})}</div> 
                    : null
                  }
                  <div className="layout horizontal">
                    <div style={{paddingRight:'10px'}} className="flex" >
                    <div className="layout horizontal center justified">
                      {/* <TextField
                        id="code"
                        label="Numero de la commande"
                        style={{width:'30%',marginTop:'0 !important'}}
                        value={this.state.code}
                        placeholder="COM11"
                        onChange={this.handleChangeInput('code')}
                        margin="normal"
                      /> */}
                      <TextField
                        id="notes"
                        label={formatMessage({id:'AjouterSortieView_3'})}
                        style={{width:'68%',marginTop:'0 !important'}}
                        value={this.state.notes}
                        placeholder={formatMessage({id:'AjouterSortieView_4'})}
                        onChange={this.handleChangeInput('notes')}
                        margin="normal"
                      />
                    
                    
                    </div>
                    {/* <Grid container className={classes.grid} justify="flex-start" alignItems='center'>
                      
                      <TextField
                        id="fournisseur"
                          label="Info fournisseur"
                          style={{margin:'0 !important'}}
                          className="flex"
                        value={this.state.fournisseur}
                        placeholder="Nom, Teléphone"
                        onChange={this.handleChangeInput('fournisseur')}
                        margin="normal"
                      />
                     
                    </Grid> */}
                    </div>
                    <div className="layout horizontal center">
                        <Button 
                          onClick={this.edit} 
                          variant="contained"
                          size="medium"
                          style={{margin:'5px'}}
                          className = {classes.buttonPrincipale}>
                          {formatMessage({id:'ItemAnneeScolaire_Up'})}
                        </Button>
                        <Button 
                          onClick={this.reset} 
                          variant="contained"
                          size="medium"
                          style={{margin:'5px'}}
                          className = {classes.buttonGreyWhite}>
                           {formatMessage({id:'ItemAnneeScolaire_Ccl'})}
                        </Button>
                    </div>
                </div> 
                  </main>
                :
                <div>
                  <div className='layout horizontal center'>
                    <span style={{fontSize:'12px'}}>Titre: </span> &nbsp; <span  className={classes.name}>{row.notes}</span>
                  </div>
                 
                  <div className='layout horizontal center'>
                    {/* <div>
                      <span style={{fontSize:'12px'}}>Fournisseur: </span> &nbsp; <span  className={classes.name}>{row.fournisseur && row.fournisseur!==""?row.fournisseur: "*******"}</span>
                    </div> */}
                    <div  className={classes.date}>
                      <FormattedMessage
                        id="ItemSortie_1"
                        values={{
                          date:<PostDate date={row.date}/>
                        }}
                       />
                    </div>
                    
                  </div>
                  <div  style={{fontWeight:"400"}} className={classes.date}>
                  <FormattedMessage
                   id="date_user"
                   values={{
                     date:<PostDate date={row.created}/>,
                     user:<a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'11px'}} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.creator)}> {row.creator.name}  </a> 
                   }}
                  />                    
                   </div>
                </div>
                }
              </div>
            </div>
            {!this.state.modeEdit && (
              <div classNamee="layout vertical">
                <div className={classes.actions}>
                  {!this.props.row.status &&
                    <Button 
                      title={formatMessage({id:'ItemSortie_2'})}
                      disabled={this.state.inProgressValidate}
                      onClick={this.handleEnabled} 
                      variant="contained"
                      size="medium"
                      style={{margin:'5px'}}
                      className = {classes.buttonPrincipale}>
                      {this.state.inProgressValidate?formatMessage({id:'ListDemande_12'}):formatMessage({id:'ItemSortie_2'})}
                    </Button>}
                   
                    <IconButton
                    title={formatMessage({id:'ItemAnneeScolaire_edit'})}
                    aria-label="Editer"
                    onClick={this.handleEdit}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    title={formatMessage({id:'ItemCommande_15'})}
                    aria-label="composition"
                    onClick={this.handleClickOpenBonSortie}>
                    <ListIcon fontSize="small" />
                  </IconButton>
                </div>
                {this.props.row.status &&<div>
                  <span style={{color:'var(--app-primary-color)',fontWeight:'600',fontSize:'13px',padding:'3px'}}>{formatMessage({id:'ItemSortie_3'})}</span>
                </div>}
              </div>
            )}
            {this.state.openBonSortie && <BonSortie onUpdateSortie={this.doUpdateSortie}  expanded='filled'  annee={this.props.annee} etablissement={this.props.etablissement} sortie={row} onClose={this.handleCloseBonSortie} onRef={ref => (this.bonSortieRef = ref)}/> }

        </div>
      
     </React.Fragment>      
    );
  }
}
ItemSortie.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemSortie)));
