import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ListCahierChefController from "./controller/ListCahierChefController";
import ListCamaradeController from "./controller/ListCamaradeController";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Page404 from "../components/Page404";
import { appProperties, hasAccessKey } from "../components/LdUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";

const useStyles = theme => ({
  rootLogged: {
    flexGrow: 1,
    padding: "0 15px"
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },
  toolbar: {
    backgroundColor: "white",
    padding: "15px",
    marginTop: "-15px",
    marginBottom: "15px",
    height: "65px"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 130,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  selectMenu: {
    padding: "7.5px 14px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */
});

class ChefIndex extends Component {
  constructor(props) {
    super(props);
    var currentAnnee = this.props.etablissement
      ? this.props.etablissement.anneeScolaire || null
      : null;
    var anneeId = currentAnnee != null ? currentAnnee.id : "";
    this.state = {
      annee: currentAnnee,
      anneeId: anneeId,
      etablissement: this.props.etablissement,
      accesses: this.props.accesses || null,
      inProgress: true,
      inProgessAccess: false,
      progress: 1,
      labelWidth: 0,
      classe: null,
      eleve: null
    };
  }

  componentDidMount() {
    this.loadEleveAccount();
  }

  loadEleveAccount() {
    let self = this;
    this.setState({ annee: null, progress: 1 });
    fetch(appProperties.basepathServer + "/rest-eleve/load-current", {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.setState({
            eleve: data.item,
            classe: data.item.classeEtablissement,
            annee: data.item.anneeScolaireEtablissement,
            progress: 0
          });
        } else if (data.result_code === 0) {
          self.setState({ eleve: null, progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ eleve: null, progress: 3 });
        console.log("There was an error.");
      });
  }

  // componentDidMount() {
  //   // const values = queryString.parse(this.props.location.search);
  //   // console.log(values.filter); // "top"
  //   // console.log(values.origin); // "im"
  // }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    // const values = queryString.parse(this.props.location.search);
    // console.log(values.email); // "im"
    // var emailUser = values.email;

    if (this.state.progress === 0) {
      return (
        <Grid className={classes.rootLogged}>
          <div>
            <Switch>
              <Route
                exact
                path="/page/:code/chef/cahier"
                render={props => {
                  // return (<p>Page des enseignants</p>)
                  if (hasAccessKey(this.state.accesses, "chef")) {
                    return (
                      <ListCahierChefController
                        classe={this.state.classe}
                        eleve={this.state.eleve}
                        etablissement={this.state.etablissement}
                        annee={this.state.annee}
                        {...props}
                      />
                    );
                  } else {
                    //TODO: Afficher une page de  droit d'access
                    return <Page404 {...props} />;
                  }
                }}
              />
              <Route
                exact
                path="/page/:code/chef/camarades"
                render={props => {
                  // return (<p>Page des enseignants</p>)
                  if (hasAccessKey(this.state.accesses, "chef")) {
                    return (
                      <ListCamaradeController
                        classe={this.state.classe}
                        eleve={this.state.eleve}
                        etablissement={this.state.etablissement}
                        annee={this.state.annee}
                        {...props}
                      />
                    );
                  } else {
                    //TODO: Afficher une page de  droit d'access
                    return <Page404 {...props} />;
                  }
                }}
              />

              <Route
                render={props => {
                  return <Page404 {...props} />;
                }}
              />
            </Switch>
          </div>
        </Grid>
      );
    } else if (this.state.progress === 1)
      return (
        <div
          style={{
            backgroundColor: "white",
            margin: "50px 50px 0 50px",
            padding: "50px"
          }}
          className="layout vertical center-center"
        >
          <div style={{ padding: "15px" }}>
            <CircularProgress
              thickness={1}
              size={75}
              className={classes.fabProgress}
            />
          </div>
          <div
            style={{
              fontSize: "17px",
              fontWeight: "600",
              width: "75%",
              color: "var(--paper-grey-900)"
            }}
            className="layout horizontal center-center"
          >
            {formatMessage({ id: "DashboardComponent_Wait" })}
          </div>
        </div>
      );
    else if (this.state.progress === 2)
      return (
        <div
          style={{
            backgroundColor: "white",
            margin: "50px 50px 0 50px",
            padding: "50px"
          }}
          className="layout vertical center-center"
        >
          <div style={{ padding: "15px" }}>Oups!!</div>
          <div
            style={{
              fontSize: "17px",
              fontWeight: "600",
              width: "75%",
              color: "var(--paper-grey-900)"
            }}
            className="layout horizontal center-center"
          >
            {formatMessage({ id: "ChefIndex_any" })}
          </div>
        </div>
      );
    else if (this.state.progress === 3)
      return (
        <div
          style={{
            backgroundColor: "white",
            margin: "50px 50px 0 50px",
            padding: "50px"
          }}
          className="layout vertical center-center"
        >
          <div style={{ padding: "15px" }}>Erreur!!</div>
          <div
            style={{
              fontSize: "17px",
              fontWeight: "600",
              width: "75%",
              color: "var(--paper-grey-900)"
            }}
            className="layout horizontal center-center"
          >
            {formatMessage({ id: "DashboardComponent_NetworkError" })}
          </div>
        </div>
      );
  }
}
ChefIndex.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(injectIntl(ChefIndex));
