import React, { Component } from "react";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

import LinearProgress from "@material-ui/core/LinearProgress";

import Grid from "@material-ui/core/Grid";

import LdSelectUser from "../../../components/LdSelectUser";
import LdSelectClasseEtablissement from "../../../components/LdSelectClasseEtablissement";
import { isObjectValueEquals } from "../../../components/LdUtils";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: "white"
  },
  root: {
    width: "100%",
    marginTop: "30px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  }
});

class AjouterEleveView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      // etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      user: null,
      classe: null,
      matricule: "",
      phone: "",
      inProgress: false,
      controlled: true
    };
    this.add = this.add.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleMatriculeChange = this.handleMatriculeChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleFullnameChange = this.handleFullnameChange.bind(this);
  }
  componentDidUpdate(prevProps) {
    //var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        //self.refreshList("");
      });
    }
  }
  componentDidMount() {}
  handleMatriculeChange(event) {
    this.setState({ matricule: event.target.value });
  }
  handlePhoneChange(event) {
    this.setState({ phone: event.target.value });
  }
  handleFullnameChange(event) {
    this.setState({ fullname: event.target.value });
  }
  handleChangeClass(item) {
    this.setState({ classe: item ? item : null });
  }
  handleChangeUser(item) {
    this.setState({ user: item ? item : null });
  }

  add() {
    if (
      this.state.classe == null ||
      this.state.user == null ||
      this.state.matricule == null ||
      this.state.matricule.trim() === ""
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("phone", this.state.phone);
    formData.append("matricule", this.state.matricule);
    formData.append("etablissement_id", this.state.etablissement.id);
    formData.append("user_id", this.state.user.id);
    formData.append("classe_id", this.state.classe.id);
    formData.append("annee_id", this.state.annee.id);
    //let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/rest-eleve/add", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, "new-eleve", { value: data.row });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        <div className={classes.pageTitle} id="form-dialog-title">
          {formatMessage({ id: "AjouterEleveView_Title" })}{" "}
        </div>

        <div className={classes.pageSubtitle}>
          {formatMessage({ id: "AjouterEleveView_SubTitle" })}
        </div>
        {this.state.inProgress ? (
          <div className={classes.progress}>
            <LinearProgress variant="query" />
          </div>
        ) : null}
        {!this.state.controlled ? (
          <div className={classes.error}>
            {formatMessage({ id: "AjouterClasseEtablissementView_3" })}
          </div>
        ) : null}
        <div className="layout horizontal center">
          <div style={{ paddingRight: "10px" }} className="flex">
            <div>
              <LdSelectUser
                label={formatMessage({ id: "AjouterEleveView_1" })}
                handleValue={this.handleChangeUser}
                isSearchable
                baseUrl={
                  appProperties.basepathServer +
                  "/rest-user/search?type=0&detail=0&limit=100&name="
                }
                {...deleteClassInProps(this.props)}
              />
            </div>
            <TextField
              id="fullname"
              label={formatMessage({ id: "AjouterEleveView_2" })}
              value={this.state.fullname}
              placeholder=""
              onChange={this.handleFullnameChange}
              fullWidth
              helperText={formatMessage({ id: "AjouterEleveView_3" })}
              margin="normal"
            />
            <Grid
              container
              className={classes.grid}
              justify="space-evenly"
              alignItems="center"
            >
              {/* <span style={{marginLeft:'20px'}} className={classes.label}>
                     Choisir une classe
                  </span> */}
              <div style={{ width: "30%" }}>
                <LdSelectClasseEtablissement
                  label={formatMessage({ id: "AjouterEleveView_4" })}
                  handleValue={this.handleChangeClass}
                  firstLoad
                  isSearchable
                  baseUrl={
                    appProperties.basepathServer +
                    "/rest-etablissement/list-classes?id=" +
                    this.state.etablissement.id +
                    "&limit=1000"
                  }
                  {...deleteClassInProps(this.props)}
                />
              </div>
              <TextField
                id="matricule"
                label={formatMessage({ id: "AjouterEleveView_5" })}
                style={{ width: "30%", marginLeft: "5%" }}
                value={this.state.matricule}
                placeholder={formatMessage({ id: "AjouterEleveView_7" })}
                onChange={this.handleMatriculeChange}
                margin="normal"
              />
              <TextField
                id="phone"
                type="tel"
                label={formatMessage({ id: "AjouterEleveView_6" })}
                style={{ width: "30%", marginLeft: "5%" }}
                value={this.state.phone}
                placeholder={formatMessage({ id: "AjouterEleveView_8" })}
                onChange={this.handlePhoneChange}
                margin="normal"
              />
            </Grid>
          </div>
        </div>
        <div
          style={{ padding: "10px" }}
          className="layout horizontal end-justified"
        >
          <Button
            onClick={this.add}
            variant="contained"
            size="medium"
            className={classes.buttonPrincipale}
          >
            {formatMessage({ id: "AjouterEleveView_9" })}
          </Button>
        </div>
      </main>
    );
  }
}
AjouterEleveView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(
  injectIntl(AjouterEleveView)
);
