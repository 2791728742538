import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-date-picker";
import { Redirect } from "react-router-dom";
import LdImageUploader from "../../../components/LdImageUploader";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import { deleteClassInProps } from "../../../components/LdUtils";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";

import LdSelectStatic from "../../../components/LdSelectStatic";
const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },
  label: {
    padding: "5px"
  }
});

class AjouterTypeFraisView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    const minDate = new Date();
    const maxDate = new Date();

    this.state = {
      code: "",
      name: "",
      description: "",
      amount: this.props.amount,
      required: !!0,
      alert: !!0,
      alert_message: "",
      year: "",
      alert_date: minDate,
      delay: maxDate,
      send_msg_when_paid: !!0,
      etablissementId: this.props.etablissementId,
      // image: "",
      icone: "",
      ids: "",
      dialogOpened: false,
      inProgress: false,
      controlled: true,
      applyFor: -1,
      applyInsideFor: -1
    };
    this.add = this.add.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);

    this.newImageUploaded = this.newImageUploaded.bind(this);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
    this.handleRequired = this.handleRequired.bind(this);

    this.handleValue = this.handleValue.bind(this);
    this.handleApplyFor = this.handleApplyFor.bind(this);
    this.handleApplyInsideFor = this.handleApplyInsideFor.bind(this);

  }

  handleApplyFor(option) {
    this.setState({ applyFor: option != null ? option.value : -1 });
  }

  handleApplyInsideFor(option) {
    this.setState({ applyInsideFor: option != null ? option.value : -1 });
  }

  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  handleValue(selectedOptions) {
    var ids = selectedOptions.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + "-" + e.value : e.value;
    }, "");
    this.setState({ ids: ids });
  }
  // newImageUploaded(name, path) {
  //   this.setState({ image: name });
  // }
  newImageUploaded(name, path) {
    this.setState({ icone: name });
  }

  handleStartDateChange(date) {
    console.log("start Date");
    console.log(date);
    this.setState({
      alert_date: date
    });
  }

  handleEndDateChange(date) {
    console.log("end Date");
    console.log(date);
    this.setState({
      delay: date
    });
  }
  handleChangeYear(event) {
    this.setState({ year: event.target.value });
  }
  handleRequired(event) {
    console.log(event.target);
    this.setState({
      required: !this.state.required
    });
  }
  handleAlert() {
    this.setState({
      alert: !this.state.alert
    });
  }
  handleSend() {
    console.log(this.state.send_msg_when_paid);
    this.setState({
      send_msg_when_paid: !this.state.send_msg_when_paid
    });
  }
  add() {
    console.log("ajouter type de frais");
    console.log("alert message", this.state.alert_message);

    var formData = new FormData(); // Currently empty
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("amount", this.state.amount);
    // formData.append("delay", this.state.delay);
    formData.append("required", this.state.required ? 1 : 0);
    formData.append("alert", this.state.alert ? 1 : 0);
    formData.append("apply_for", this.state.applyFor);
    formData.append("apply_inside_for", this.state.applyInsideFor);
    formData.append("annee", this.props.annee != null ? this.props.annee.id : "");
    if (this.state.alert)
      formData.append("alert_message", this.state.alert_message);
    // formData.append("alert_date", this.state.alert_date);
    if (this.state.alert_date == null || this.state.delay == null) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    formData.append(
      "alert_date",
      moment(this.state.alert_date).format("YYYY-MM-DD")
    );
    formData.append("delay", moment(this.state.delay).format("YYYY-MM-DD"));
    formData.append(
      "send_msg_when_paid",
      this.state.send_msg_when_paid ? 1 : 0
    );
    formData.append("icone", this.state.icone);
    // formData.append("ids", this.state.ids);
    // formData.append("image", this.state.image);
    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/manager/type-frais/add?etablissement=" +
      this.state.etablissementId,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          fireWithObject(document, "new-type-frais", { value: data.row });

          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterTypeFraisView_1" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemTypeFrais_16" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterTypeFraisView_2" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className={classes.buttonPrincipale}
          onClick={this.handleClickOpen}
        >
          {formatMessage({ id: "AjouterTypeFraisView_3" })}
        </Button>
        <Dialog
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">
            {formatMessage({ id: "AjouterTypeFraisView_4" })}
          </DialogTitle>
          <DialogContent>
            {this.state.inProgress ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {!this.state.controlled ? (
              <div className={classes.error}>
                {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
              </div>
            ) : null}
            <div className={classes.pageSubtitle}>
              {formatMessage({ id: "AjouterTypeFraisView_5" })}
            </div>
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>
              {this.state.redirect ? (
                <Redirect
                  to={{
                    pathname: "/",
                    search: "",
                    state: {}
                  }}
                />
              ) : null}

              {/* {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null} */}

              <div className={classes.root}>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="code">
                    {formatMessage({ id: "ItemTypeDecaissement_3" })}
                  </InputLabel>
                  <Input
                    id="code"
                    required
                    name="code"
                    autoComplete="code"
                    onChange={this.handleChangeInput("code")}
                    autoFocus
                    placeholde={formatMessage({ id: "AjouterTypeAlertView_1" })}
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="name">
                    {formatMessage({ id: "ItemTypeDecaissement_4" })}
                  </InputLabel>
                  <Input
                    name="name"
                    type="text"
                    id="name"
                    required
                    onChange={this.handleChangeInput("name")}
                    autoComplete="current-name"
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="description">
                    {formatMessage({ id: "ItemTypeDecaissement_5" })}
                  </InputLabel>
                  <Input
                    id="description"
                    required
                    name="description"
                    autoComplete="description"
                    onChange={this.handleChangeInput("description")}
                    multiline
                    rows="4"
                    variant="filled"
                  />
                </FormControl>
                {/* <div>
                  <LdImageUploader maxSize={500000}
                    onImageUploaded={this.newImageUploaded}
                    {...deleteClassInProps(this.props)}
                  />
                </div> */}
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="amount">
                    {" "}
                    {formatMessage({ id: "ListDemande_8" })}
                  </InputLabel>
                  <Input
                    name="amount"
                    type="number"
                    id="amount"
                    placeholder="150000"
                    required
                    onChange={this.handleChangeInput("amount")}
                    autoComplete="amount"
                  />
                </FormControl>
                <br />
                <br />
                <div style={{ padding: "15px 0", width: "100%" }}>
                  <LdSelectStatic
                    options={[
                      {
                        value: -1,
                        label: formatMessage({ id: "AjouterTypeFraisView_7" })
                      },
                      {
                        value: 0,
                        label: formatMessage({ id: "AjouterTypeFraisView_8" })
                      },
                      {
                        value: 1,
                        label: formatMessage({ id: "AjouterTypeFraisView_9" })
                      },

                    ]}
                    label={formatMessage({ id: "AjouterTypeFraisView_6" })}
                    handleValue={this.handleApplyFor}
                    {...deleteClassInProps(this.props)}
                  />
                </div>
                <br />
                <br />
                <div style={{ padding: "15px 0", width: "100%" }}>
                  <LdSelectStatic
                    options={[
                      {
                        value: -1,
                        label: formatMessage({ id: "AjouterTypeFraisView_12" })
                      },
                      {
                        value: 0,
                        label: formatMessage({ id: "AjouterTypeFraisView_13" })
                      },
                      {
                        value: 1,
                        label: formatMessage({ id: "AjouterTypeFraisView_14" })
                      },

                    ]}
                    label={formatMessage({ id: "AjouterTypeFraisView_11" })}
                    handleValue={this.handleApplyInsideFor}
                    {...deleteClassInProps(this.props)}
                  />
                </div>
                <br />
                <br />
                <div container className={classes.grid}>
                  <span className={classes.label}>
                    {formatMessage({ id: "ListTypeFraisView_9" })}
                  </span>
                  <DatePicker
                    locale="fr-FR"
                    className={classes.datePicker}
                    value={this.state.alert_date}
                    onChange={this.handleStartDateChange}
                  />
                  <br />
                  <br />
                  {/* <TimePicker
                    margin="normal"
                    label="Time picker"
                    value={start}
                    onChange={this.handleDateChange}
                  /> */}
                  <span className={classes.label}>
                    {formatMessage({ id: "ItemTypeFrais_11" })}
                  </span>
                  <DatePicker
                    label="Delay"
                    className={classes.datePicker}
                    locale="fr-FR"
                    value={this.state.delay}
                    onChange={this.handleEndDateChange}
                  />
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={this.state.required}
                      color="primary"
                      onChange={this.handleRequired}
                    />
                  }
                  label={formatMessage({ id: "ItemTypeFrais_12" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={this.state.send_msg_when_paid}
                      color="primary"
                      onChange={this.handleSend}
                    />
                  }
                  label={formatMessage({ id: "ItemTypeFrais_13" })}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      value={this.state.alert}
                      color="primary"
                      onChange={this.handleAlert}
                    />
                  }
                  label={formatMessage({ id: "ItemTypeFrais_14" })}
                />
                {this.state.alert ? (
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="alert_message">
                      {formatMessage({ id: "AjouterTypeIndisciplineView_2" })}
                    </InputLabel>
                    <Input
                      id="alert_message"
                      required
                      name="alert_message"
                      autoComplete="alert_message"
                      placeholder={formatMessage({
                        id: "AjouterTypeIndisciplineView_1"
                      })}
                      onChange={this.handleChangeInput("alert_message")}
                      multiline
                      rows="4"
                      variant="filled"
                    />
                  </FormControl>
                ) : null}
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}
            >
              {formatMessage({ id: "AjouterTypeAlertView_13" })}
            </Button>
            <Button
              onClick={this.add}
              variant="contained"
              size="medium"
              className={classes.buttonPrincipale}
            >
              {formatMessage({ id: "AjouterTypeAlertView_15" })}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterTypeFraisView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AjouterTypeFraisView))
);
