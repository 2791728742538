import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, FormattedTime, injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { appProperties, isObjectValueEquals, userProfileUrl } from "../../../components/LdUtils";
import BonAchat from "./BonAchat";





const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    padding: '10px',
    borderBottom: '1px solid #EAEAEA',
    '&:hover': {
      backgroundColor: '#F1F1F1'
    }
  },


  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "12px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    width: "40px !important",
    height: "40px !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },
  main: {
    width: '100%'
  }
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
       <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));

class ItemCommandeValid extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      inProgressValidate: false,
      modeEdit: false,
      position: this.props.position,
      controlled: true,
      etablissement: this.props.etablissement,
      openBonAchat: false,

    };
    this.bonCommandeRef = React.createRef();

    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleCloseBonAchat = this.handleCloseBonAchat.bind(this);
    this.handleClickOpenBonAchat = this.handleClickOpenBonAchat.bind(this);
    this.doUpdateCommande = this.doUpdateCommande.bind(this);
  }

  handleClickOpenBonAchat = () => {
    var self = this;
    this.setState({ openBonAchat: true }, () => {
      setTimeout(() => {
        self.bonCommandeRef.handleClickOpenDialog();
      }, 0);
    });
  };

  handleCloseBonAchat = () => {
    this.setState({ openFiche: false });
    //this.dossierRef.doCloseDialog();
  };
  componentDidUpdate(prevProps) {

    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }
  handleDateChange(date) {
    this.setState({
      date: date,
    });
  }

  doUpdateCommande(row) {
    let datas = { position: this.state.position, row: row };
    this.props.onUpdateItemCommande('update', datas);
  }


  handleEnabled = () => {
    var self = this;
    this.setState({ inProgressValidate: true });
    fetch(
      appProperties.basepathServer +
      "/manager/commande-materiel/validate?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        this.setState({ inProgressValidate: false });

        if (data.result_code === 1) {
          let datas = { position: self.state.position, row: data.item };
          self.props.onUpdateItemCommande('update', datas);
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemCommandValid_1" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemCommandValid_2" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemCommandValid_3" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressValidate: false });

        alert("There was an error added.");
      });
  };

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>


              <div>
                <div className='layout horizontal center'>
                  <span style={{ fontSize: '12px' }}>{formatMessage({ id: 'ItemCommandValid_4' })} </span>  <span className={classes.name}>{row.notes}</span>
                </div>
                <div className='layout horizontal center'>
                  <div>
                    <span style={{ fontSize: '12px' }}>{formatMessage({ id: 'ItemCommandValid_5' })}  </span> <span style={{ backgroundColor: 'var(--app-primary-color)', padding: '3px', color: 'white' }} className={classes.name}>{row.ref && row.ref !== "" ? row.ref : "#######"}</span>
                  </div>
                  <div style={{ marginLeft: '10px' }}>
                    <span style={{ fontSize: '12px' }}>
                      <FormattedMessage
                        id="ItemCommandValid_7"
                        values={{
                          amount: <span className={classes.name}>{row.amount && row.amount !== 0 ? row.amount : "#######"}</span>
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div className='layout horizontal center'>
                  {/* <div>
                      <span style={{fontSize:'12px'}}>Fournisseur: </span> &nbsp; <span  className={classes.name}>{row.fournisseur && row.fournisseur!==""?row.fournisseur: "*******"}</span>
                    </div> */}
                  <div className={classes.date}>
                    <FormattedMessage
                      id="ItemCommandValid_6"
                      values={{
                        date: <PostDate date={row.date} />
                      }}
                    />
                  </div>

                </div>
                <div style={{ fontWeight: "400" }} className={classes.date}>
                  <FormattedMessage
                    id="date_user"
                    values={{
                      date: <PostDate date={row.created} />,
                      user: (
                        <a
                          style={{
                            paddingLeft: "5px",
                            fontWeight: "500",
                            fontSize: "11px"
                          }}
                          className="no-underline capitalize"
                          title={row.creator.name}
                          rel="noopener noreferrer"
                          target="_blank"
                          href={userProfileUrl(row.creator)}
                        >
                          {row.creator.name}
                        </a>
                      )
                    }}
                  />
                </div>
              </div>

            </div>
          </div>
          {!this.state.modeEdit && (
            <div classNamee="layout vertical">
              <div className={classes.actions}>
                {/* {this.props.row.status === 1 &&
                    <Button 
                      title="Marquer la commande comme livrée"
                      disabled={this.state.inProgressValidate}
                      onClick={this.handleEnabled} 
                      variant="contained"
                      size="small"
                      style={{margin:'5px'}}
                      className = {classes.buttonPrincipale}>
                      {this.state.inProgressValidate?"En cours...":"Marquer comme livrée"}
                    </Button>} */}

                {/* <IconButton
                    title="Afficher les chats de la commande"
                    aria-label="composition"
                    onClick={this.handleClickOpenBonAchat}>
                    <ListIcon fontSize="small" />
                  </IconButton> */}
                <Button
                  title={formatMessage({ id: 'ItemCommandValid_9' })}
                  disabled={this.state.inProgressValidate}
                  onClick={this.handleClickOpenBonAchat}
                  variant="outlined"
                  size="small"
                  style={{ margin: '5px', textTransform: 'none' }}>
                  {formatMessage({ id: "ItemCommandValid_8" })}
                </Button>
              </div>

              {this.props.row.status === 2 && <div>
                <span style={{ color: 'var(--paper-green-500)', fontWeight: '600', fontSize: '13px', padding: '3px' }}>{formatMessage({ id: 'ItemCommandValid_10' })}</span>
              </div>}
            </div>
          )}
          {this.state.openBonAchat && <BonAchat onUpdateCommande={this.doUpdateCommande} expanded='filled' annee={this.props.annee} etablissement={this.props.etablissement} commande={row} onClose={this.handleCloseBonAchat} onRef={ref => (this.bonCommandeRef = ref)} />}

        </div>

      </React.Fragment>
    );
  }
}
ItemCommandeValid.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemCommandeValid)));
