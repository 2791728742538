import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from "@material-ui/icons/Close";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EditIcon from "@material-ui/icons/Edit";
import SortIcon from '@material-ui/icons/Sort';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import 'moment/locale/fr';
import React, { Component } from "react";
import DateTimePicker from 'react-datetime-picker';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormattedDate, FormattedTime, injectIntl } from "react-intl";
import Truncate from 'react-truncate-html';
import LdPagination from "../../../components/LdPagination";
    import { appProperties, formatDurationHms, isObjectValueEquals, userProfileUrl } from '../../../components/LdUtils';
import { withSnackbar } from 'notistack';

const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main:{
    padding:'15px 1px',
    // marginTop:'15px',
    backgroundColor:'white',
    // marginLeft: '10px',
    // backgroundImage:'url(/images/icons/paper.jpg)',
    // backgroundRepeat: 'repeat',
    // backgroundSize: 'contain',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },

  date: {
    fontSize: "10px",
    fontWeight: "500",
    textAlign:'center'
  },


  
  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset':{
      borderColor:'transparent !important',
    }
  },
  selectRoot:{
    height:'35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize:'12px',
    fontWeight:'500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu:{
    padding:'7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color:'white',
    '&:focus':{
      backgroundColor: 'var(--app-primary-color)',
      color:'white',
    },
    borderRadius:'4px !important'
  },
  outlinedInput:{
    backgroundColor: 'var(--app-primary-color)',
    color:'white',
    borderRadius:'4px'
  },  /** Filter end */
  
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding:'0px'
  },
  
  progress: {
    width: "100%",
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  datePicker:{
    height:'40px',
    '& .react-date-picker__wrapper':{
      border:'1px dashed #AAAAAA',
      borderRadius:'5px'
    }
  },
  error:{
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop:"15px"

  },
  label: {
    padding: "5px"
  }
});
const CustomTableCell = withStyles(theme => ({
  head: {
    // backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight:'600',
    fontSize:'15px',
    padding: '4px 7px 4px 7px'
  },
  body: {
    fontSize: 14,
    padding: '4px 7px 4px 7px'
  }
}))(TableCell);
const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
    <FormattedTime hour="numeric" minute="numeric" value={date} />
  </span>
));
class ListCahierMatiere extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas :[],  
      count:0,
      hasMore:false,
      pages:[],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      classe: this.props.classe,
      enseignant: this.props.enseignant,
      currentPage:1,
      progress: 1,
      controlled: true,
      inProgress: false,
      dialogOpened: false,
      openSnack: false,
     

      duree: 0,
      comment: '',
      date:new Date(),
      editorState: undefined,



      item: null,
      dureeItem: 0,
      position: 0,
      commentItem: '',
      dateItem:new Date(),
      editorStateItem: undefined,
      openDialogEdit: false,
      controlledEdit: true,
      inProgressEdit: false,
      openSnackEdit: false,





      positionApprob: 0,
      itemApprob: null,
      approbation: '',
      openDialogApprob: false,
      controlledApprob: true,
      inProgressApprob: false,
      openSnackApprob: false,
     

    }
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    this.handleSnackBarCloseEdit = this.handleSnackBarCloseEdit.bind(this);
    this.handleSnackBarCloseApprob = this.handleSnackBarCloseApprob.bind(this);

    this._handleNewCahier = this._handleNewCahier.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateChangeEdit = this.handleDateChangeEdit.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.handleShowEdit = this.handleShowEdit.bind(this);
    this.handleCloseEdit = this.handleCloseEdit.bind(this);

    
    this.handleShowApprob = this.handleShowApprob.bind(this);
    this.handleCloseApprob = this.handleCloseApprob.bind(this);
    this.approb = this.approb.bind(this);


  }
  
  handleShowApprob =  (row,position) => e => {
    var val = {};
    val['itemApprob'] = row;
    val['positionApprob'] = position;
    val['approbation'] = row.approbationComment ==  null? "": row.approbationComment;
    val['openDialogApprob'] = true;
    this.setState(val);
  }; 
  handleCloseApprob = () => {
    this.setState({ openDialogApprob: false });
  };

  handleShowEdit =  (row,position) => e => {
    var val = {};
    val['item'] = row;
    val['position'] = position;
    val['dateItem'] = new Date(row.date);
    val['commentItem'] = row.comment;
    val['dureeItem'] = row.duree;

    
    val['editorStateEdit'] = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(row.comment)
      )
    );

    val['openDialogEdit'] = true;

    this.setState(val);
  }; 
  onEditorStateChangeEdit = (editorStateEdit) => {
    this.setState({
      editorStateEdit:editorStateEdit,
      commentItem: draftToHtml(convertToRaw(editorStateEdit.getCurrentContent()))
    });
  };
  handleCloseEdit = () => {
    this.setState({ openDialogEdit: false });
  };

  handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnack: false });
  };
  handleSnackBarCloseEdit = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnackEdit: false });
  };
  handleSnackBarCloseApprob = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnackApprob: false });
  };
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState:editorState,
      comment: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };
  handleDateChange(date){
  
    this.setState({
      date: date,
    });
  }
  handleDateChangeEdit(date){
  
    this.setState({
      dateItem: date,
    });
  }
  handleReset = () => {
    var self = this;
    this.setState({
      classes:[],
      classe:null,
      enseignant:null,
    },()=>{
      self.setCurrentPage(1);
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if(!isObjectValueEquals(this.props.etablissement,prevProps.etablissement)){
      this.setState({etablissement:this.props.etablissement},()=>{
        //self.refreshList("");
      });
    }
    if(!isObjectValueEquals(this.props.annee,prevProps.annee)){
      this.setState({annee:this.props.annee},()=>{
        self.handleReset();
      
      });
    }
    if(!isObjectValueEquals(this.props.enseignant,prevProps.enseignant)){
      this.setState({enseignant:this.props.enseignant},()=>{
        self.handleReset();
      
      });
    }
  }


  
  setCurrentPage = page=>{
    this.setState({currentPage:page});
    this.listCahier(page);
  };
  _handleNewCahier = event =>{
       this.setState(state => {
             state.datas =  [event.detail.value, ...state.datas] ;
             return state;
        });
  };  
  onUpdateItem = (mode,item) =>{
      console.log("intercept",mode);
      console.log(item);
      var array = [...this.state.datas];
      if(mode === 'update'){
          array.splice(item.position,1,item.row);
      }else if (mode === 'delete'){
        array.splice(item.position,1);

      }else if (mode === 'enabled'){
        item.row.status = true;
        console.log('enabled', item.row.status);
        array.splice(item.position,1,item.row);

      }else if (mode === 'disabled'){
        item.row.status = false;
        array.splice(item.position,1,item.row);

      }
      
      this.setState(state => {
             state.datas =  array ;
             return state;
      });
  };
  componentDidMount() {  
    this.listCahier(this.state.currentPage);
    document.addEventListener('new-cahier',this._handleNewCahier.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener('new-cahier',this._handleNewCahier.bind(this));
  }
  add() {
    
    if (
      this.state.date == null ||
      this.state.duree ==="" ||
      parseInt(this.state.duree) === 0 ||
      this.state.comment === "" ||
      this.state.comment.trim().length === 0
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("enseignant", this.props.enseignant.id);
    formData.append("duree", this.state.duree); 
    formData.append("date", moment(this.state.date).locale('fr').format('YYYY-MM-DD HH:mm:ss'));

    formData.append("comment", this.state.comment);

    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer + "/manager/cahier-texte/add",
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false }); 
        self.handleClose();
        if (data.result_code === 1) {
          //self.setState({ openSnack: true });
          self.setState(state => {
                state.datas =  [data.row, ...state.datas] ;
                return state;
          });
          //window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
            this.props.enqueueSnackbar("Entrée du cahier enregistré avec succès", {
                variant:"success",
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
            });
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          // fireWithObject(document,'new-autorisation',{value:data.row});
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        self.setState({ openSnack: false });
        alert("There was an error added.");
      });
  }
  edit() {
    if (
      this.state.item == null ||
      this.state.dateItem == null ||
      this.state.dureeItem ==="" ||
      parseInt(this.state.dureeItem) === 0 ||
      this.state.commentItem === "" ||
      this.state.commentItem.trim().length === 0
    ) {
      this.setState({ controlledEdit: false });
      return;
    } else {
      this.setState({ controlledEdit: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append('id', this.state.item.id);
    formData.append("duree", this.state.dureeItem);
    formData.append("date", moment(this.state.dateItem).locale('fr').format('YYYY-MM-DD HH:mm:ss'));
    formData.append("comment", this.state.commentItem);

    let self = this;
    this.setState({ inProgressEdit: true });
    fetch(
      appProperties.basepathServer + "/manager/cahier-texte/edit",
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressEdit: false });
        self.handleCloseEdit();
        if (data.result_code === 1) {
          self.setState({ openSnackEdit: true });
          let datas = {position:self.state.position,row:data.row};
          self.onUpdateItem('update',datas);

          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          // fireWithObject(document,'new-autorisation',{value:data.row});
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgressEdit: false });
        self.setState({ openSnackEdit: false });
        alert("There was an error added.");
      });
  }
  
  approb() {
    if (
      this.state.itemApprob == null ||
      this.state.approbation === "" ||
      this.state.approbation.trim().length === 0
    ) {
      this.setState({ controlledApprob: false });
      return;
    } else {
      this.setState({ controlledApprob: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append('id', this.state.itemApprob.id);
    formData.append("comment", this.state.approbation);

    let self = this;
    this.setState({ inProgressApprob: true });
    fetch(
      appProperties.basepathServer + "/manager/cahier-texte/approve",
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
      )
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressApprob: false });
        self.handleCloseApprob();
        if (data.result_code === 1) {
          self.setState({ openSnackApprob: true });
          let datas = {position:self.state.positionApprob,row:data.row};
          self.onUpdateItem('update',datas);

          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          // fireWithObject(document,'new-autorisation',{value:data.row});
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgressApprob: false });
        self.setState({ openSnackApprob: false });
      });
  }

  listCahier(page){
       
    let self = this;

    this.setState({datas: [],progress:1});
    var url = appProperties.basepathServer+ '/manager/cahier-texte/list?etablissement='+this.props.etablissement.id +'&ordered=1&limit=10&page='+page;
    if(this.props.annee!=null) url+=("&annee="+this.props.annee.id);
    if(this.props.classe  && this.props.classe!=null) url+=("&classe="+this.props.classe.id);
    else return;
    if(this.props.enseignant  && this.props.enseignant!=null) url+=("&enseignant="+this.props.enseignant.id);
    else return;
    
    fetch(url, {
        mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    
    })
    .then((response) => response.json())
    .then((data) => { 
        //var array = [];
        if(data.result_code === 1){
          
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({progress:0,datas:data.datas,count:data.count,hasMore:data.hasMore,pages:data.pages});  
        }else{
          self.setState({datas:[],progress:2});   

        }
        
    })
    .catch((err) => {
      console.log(err);
      //self.setState({datas: []});
      self.setState({datas:[],progress:3}); 

      console.log('There was an error listed enseignant.');
    });
  }

  render() {
    const { classes,enseignant,mode } = this.props;
    const { editorState,editorStateEdit } = this.state;
    const{formatMessage}=this.props.intl;
   
    return (
        <div className={classes.main}>
        <Dialog
          maxWidth='lg'
          open={this.state.openDialogApprob}
          onClose={this.handleCloseApprob}
          aria-labelledby="form-dialog-title">
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">{formatMessage({ id: "ListCahierMatiere_1" })}</DialogTitle>
          <DialogContent>

            {this.state.inProgressApprob
              ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
              : null
            }
            {!this.state.controlledApprob
              ? <div className={classes.error}>{formatMessage({ id: "ItemAnneeScolaire_Check" })}</div> 
              : null
            }
            <Paper square elevation={0} className={classes.paper}>
              <div className={classes.root}>
                <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="motif">{formatMessage({ id: "ListCahierMatiere_2" })}</InputLabel>
                    <Input
                      id="approbation"
                      required
                      name="approbation"
                      placeholder={formatMessage({ id: "ListCahierMatiere_3" })}
                      defaultValue={this.state.approbation}
                      autoComplete="approbation"
                      onChange={this.handleChangeInput("approbation")}
                      multiline
                        rows="4"
                        variant="filled"
                    />
                  </FormControl>
              </div>
            </Paper>
            </DialogContent>
          <DialogActions>
            <Button  onClick={this.handleCloseApprob} 
              variant="contained"
              size="medium"
              className = {classes.buttonGreyWhite}>
              {formatMessage({ id: "AutorisationEtablissement_Ccl" })}
            </Button>
            <Button 
              onClick={this.approb} 
              variant="contained"
              size="medium"
              className = {classes.buttonPrincipale}>
              {formatMessage({ id: "ListCahierMatiere_4" })}
            </Button>
          </DialogActions>
        </Dialog>




        <Dialog
          maxWidth='lg'
          open={this.state.openDialogEdit}
          onClose={this.handleCloseEdit}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">{formatMessage({ id: "ListCahierMatiere_5" })}</DialogTitle>
          <DialogContent>

            {this.state.inProgressEdit
              ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
              : null
            }
            {!this.state.controlledEdit
              ? <div className={classes.error}>{formatMessage({ id: "ItemAnneeScolaire_Check" })}</div> 
              : null
            }

            <Paper square elevation={0} className={classes.paper}>
              <div className={classes.root}>
                <div  className="layout horizontal center" >
                  <div className="flex">
                    <span className={classes.label}>
                        {formatMessage({ id: "ItemAnneeScolaire_Deb" })}
                    </span>
                    <DateTimePicker
                      locale='fr-FR'
                      className={classes.datePicker}
                      value={this.state.dateItem}
                      onChange={this.handleDateChangeEdit}
                    />
                  </div>
                  <FormControl className="flex" style={{marginLeft:'5px'}} margin="dense" required>
                    <InputLabel htmlFor="amount">{formatMessage({ id: "ListCahierMatiere_6" })}</InputLabel>
                    <Input
                      name="duree"
                      type="duree"
                      id="duree"
                      placeholder="Ex: 7200"
                      required
                      value={this.state.dureeItem}
                      onChange={this.handleChangeInput("dureeItem")}
                      autoComplete="duree"
                    />
                  </FormControl>
                </div>
              
                
                  <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="comment">{formatMessage({ id: "ListCahierMatiere_7" })}</InputLabel>
                  <Editor
                    id="comment"
                    editorState={editorStateEdit}
                    placeholder={formatMessage({ id: "AutorisationEtablissement_Descrip_Ph" })}
                    toolbarClassName="rdw-storybook-toolbar"
                    wrapperClassName="rdw-storybook-wrapper"
                    editorClassName="rdw-storybook-editor"
                    wrapperStyle={{marginTop:'50px'}}
                    editorStyle={{maxHeight:'225px',overflowY:'auto',border:'1px solid #EEEEEE',borerRadius:'50%',marginTop:'10px',padding:'10px'}}
                    onEditorStateChange={this.onEditorStateChangeEdit}
                  /> 
                </FormControl>
                 
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button  onClick={this.handleCloseEdit} 
              variant="contained"
              size="medium"
              className = {classes.buttonGreyWhite}>
              {formatMessage({ id: "AlertSolde_cancl" })}
            </Button>
            <Button 
              onClick={this.edit} 
              variant="contained"
              size="medium"
              className = {classes.buttonPrincipale}>
              {formatMessage({ id: "ListCahierMatiere_8" })}
            </Button>
          </DialogActions>
        </Dialog>










         <Dialog
          maxWidth='lg'
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">{formatMessage({ id: "ListCahierMatiere_9" })}</DialogTitle>
          <DialogContent>

            {this.state.inProgress
              ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
              : null
            }
            {!this.state.controlled
              ? <div className={classes.error}>{formatMessage({ id: "ItemAnneeScolaire_Check" })}</div> 
              : null
            }

            <Paper square elevation={0} className={classes.paper}>
              <div className={classes.root}>
                <div  className="layout horizontal center" >
                  <div className="flex">
                    <span className={classes.label}>
                        {formatMessage({ id: "ItemAnneeScolaire_Deb" })}
                    </span>
                    <DateTimePicker
                      locale='fr-FR'
                      className={classes.datePicker}
                      value={this.state.date}
                      onChange={this.handleDateChange}
                    />
                  </div>
                  <FormControl className="flex" style={{marginLeft:'5px'}} margin="dense" required>
                    <InputLabel htmlFor="amount">{formatMessage({ id: "ListCahierMatiere_6" })} </InputLabel>
                    <Input
                      name="duree"
                      id="duree"
                      type="number"
                     
                      placeholder="Ex: 7200"
                      required
                      onChange={this.handleChangeInput("duree")}
                      autoComplete="duree"
                      inputprops={{
                        inputProps: { min: 0 },
                      }}
                    />
                  </FormControl>
                </div>
              
                
                  <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="comment">{formatMessage({ id: "ListCahierMatiere_7" })}</InputLabel>
                  <Editor
                    id="comment"
                    editorState={editorState}
                  
                    placeholder={formatMessage({ id: "AutorisationEtablissement_Descrip_Ph" })}
                    toolbarClassName="rdw-storybook-toolbar"
                    wrapperClassName="rdw-storybook-wrapper"
                    editorClassName="rdw-storybook-editor"
                    wrapperStyle={{marginTop:'50px'}}
                    editorStyle={{maxHeight:'225px',overflowY:'auto',border:'1px solid #EEEEEE',borerRadius:'50%',marginTop:'10px',padding:'10px'}}
                    onEditorStateChange={this.onEditorStateChange}
                  /> 
                </FormControl>
                 
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button  onClick={this.handleClose} 
              variant="contained"
              size="medium"
              className = {classes.buttonGreyWhite}>
              {formatMessage({ id: "AutorisationEtablissement_Ccl" })}
            </Button>
            <Button 
              onClick={this.add} 
              variant="contained"
              size="medium"
              className = {classes.buttonPrincipale}>
              {formatMessage({ id: "AjouterTypeAlertView_15" })}
            </Button>
          </DialogActions>
        </Dialog>

          { this.state.progress === 0 && (
            <React.Fragment>
             
              <div style={{padding:'0 10px 10px'}} className="layout horizontal center justified">
                <span  style={{fontWeight:"400"}} >{formatMessage({ id: "ListCahierMatiere_10" })}
                  <a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'13px'}} className="no-underline capitalize" title={enseignant.user.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(enseignant.user)}>
                    {enseignant.user.name}      
                  </a> 
                </span>
                <span  style={{fontWeight:"400"}} >{formatMessage({ id: "ListCahierMatiere_11" })}
                  <span style={{paddingLeft:'5px',fontWeight:"500",fontSize:'13px'}} className="no-underline capitalize" >
                    {enseignant.coefficient}      
                  </span> 
                </span>
                <span  style={{fontWeight:"400"}} > {formatMessage({ id: "ListCahierMatiere_12" })}
                  <span style={{paddingLeft:'5px',fontWeight:"500",fontSize:'13px'}} className="no-underline capitalize" >
                    {enseignant.quotaHoraire}      
                  </span> 
                </span>
                {((mode==="teacher") || (mode==="manager"))&&
                  <span>
                      <IconButton
                        title={formatMessage({ id: "ListCahierMatiere_9" })}
                        key="close"
                        aria-label="Close"
                        color='primary'
                        style={{backgroundColor:'var(--app-primary-color)',color:'white'}}
                        onClick={this.handleClickOpen}
                      >
                        <AddIcon />
                      </IconButton>
                  </span>
                }
               
              </div>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell style={{color:'white',textAlign:'center',width:'20%',cursor:'pointer',backgroundColor:'#7ebeea'}} onClick={this.doOrderByName1} title={formatMessage({ id: "ListCahierMatiere_13" })}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "ListCahierMatiere_14" })}</span></div></CustomTableCell>
                    <CustomTableCell style={{color:'white',textAlign:'center',cursor:'pointer',width:'80%',backgroundColor:'#50adec'}} title={formatMessage({ id: "ListCahierMatiere_18" })} ><span>{formatMessage({ id: "ListCahierMatiere_17" })} </span></CustomTableCell>
                    <CustomTableCell style={{color:'white',textAlign:'center',width:'10%',cursor:'pointer',backgroundColor:'#7ebeea'}}  onClick={this.doOrderByNote1} title={formatMessage({ id: "ListCahierMatiere_16" })}   align="left"><span>{formatMessage({ id: "ListCahierMatiere_15" })}</span></CustomTableCell>
                  
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.datas.map((row,index) => (
                    <TableRow style={{backgroundColor:'none'}}  className={classes.row} key={row.id}>
                    
                      <CustomTableCell  style={{color:'white',width:'20%',cursor:'pointer',backgroundColor:'#7ebeea'}} component="th" scope="row">
                        <div className="layout vertical center flex">
                          <div  style={{fontWeight:"600"}} className={classes.date}>{formatMessage({ id: "ListCahierMatiere_19" })}   <PostDate date={row.date}/>
                           
                           
                          </div>
                          <div className="flex"><br/> <br/></div>
                          <div  style={{fontWeight:"600"}} className={classes.date}>{formatMessage({ id: "ListCahierMatiere_20" })}   <PostDate date={row.created}/>
                            {row.creator!=null && (<span>
                              &nbsp; {formatMessage({ id: "ListCahierMatiere_21" })}
                              <a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'9px'}} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.creator)}>
                                {row.creator.name}
                              </a>  
                            </span>)}
                          </div>
                          <div className="flex"><br/><br/></div>
                          {row.modified!=null && (
                          <div  style={{fontWeight:"600"}} className={classes.date}>{formatMessage({ id: "ListCahierMatiere_22" })} <PostDate date={row.modified}/>
                            {row.modificator!=null && (<span>
                              &nbsp;{formatMessage({ id: "ListCahierMatiere_21" })}
                              <a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'9px'}} className="no-underline capitalize" title={row.modificator.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.modificator)}>
                                {row.modificator.name}
                              </a>  
                            </span>)}
                          </div>)}
                          <div className="flex"><br/><br/></div>
                          {row.approbationDate!=null && (
                          <div  style={{color:"black"}} className={classes.date}>{formatMessage({ id: "ListCahierMatiere_23" })} <PostDate date={row.approbationDate}/>
                            {row.approvedBy!=null && (<span>
                              &nbsp;{formatMessage({ id: "ListCahierMatiere_21" })}
                              <a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'9px'}} className="no-underline capitalize" title={row.approvedBy.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.approvedBy)}>
                                {row.approvedBy.name}
                              </a>  
                            </span>)}
                          </div>)}
                        </div>
                      </CustomTableCell>
                      <CustomTableCell component="th" scope="row">
                        <div className="layout vertical">
                          <div  style={{fontWeight:"500",fontSize:'12px'}}>
                              <Truncate 
                                lines={100} 
                                portrait={4} 
                                breakWord={true} 
                                responsive={true} 
                                dangerouslySetInnerHTML={{
                                  __html: row.comment
                                }}
                              />
                          
                          </div>
                          <div className="layout horizontal center end-justified">
                            {(!row.approved && mode==="teacher")?
                              <span   style={{padding: "0 5px 0",color:'var(--paper-orange-500)'}}>{formatMessage({ id: "ListCahierMatiere_24" })}</span>
                             :null
                            }
                            {row.approved ?
                              (
                                <div className="layout horizontal center" title={formatMessage({ id: "ListCahierMatiere_2" })}>
                                  <span style={{padding: "10px 5px 0",fontSize:'11px',color: 'var(--paper-green-500)'}}>{row.approbationComment}</span>
                                  <IconButton
                                    title="Déja approuvé"
                                    key="close"
                                    aria-label="Close"
                                    style={{color:'var(--paper-green-500)'}}
                                    className={classes.close}
                                  >
                                    <DoneAllIcon />
                                  </IconButton>
                                </div>
                              ):null
                            }
                           
                               { !row.approved && (mode==="manager" || mode==="chef") &&
                                <div>
                                  <Button 
                                    title={formatMessage({ id: "ListCahierMatiere_25" })}
                                    onClick={this.handleShowApprob(row,index)} 
                                    variant="contained"
                                    size="small"
                                    className = {classes.buttonPrincipale}>
                                    Approuver
                                  </Button>
                                </div> 
                              
                            } 
                            {((mode==="teacher" && !row.approved) || (mode==="manager"))?
                            
                                 <IconButton
                                  title={formatMessage({ id: "ListCahierMatiere_26" })}
                                  key="close"
                                  aria-label="Close"
                                  color="inherit"
                                  className={classes.close}
                                  onClick={this.handleShowEdit(row,index)}
                                >
                                  <EditIcon />
                                </IconButton>
                                :null
                                
                              
                            }
                               
                          </div>
                        </div>
                      </CustomTableCell>
                      <CustomTableCell   style={{color:'white',textAlign:'center',width:'10%',cursor:'pointer',backgroundColor:'#7ebeea'}} component="th" scope="row">
                          <span  style={{fontWeight:"600",fontSize:'10px'}}>{formatDurationHms(row.duree)}</span>
                      </CustomTableCell>
                    
                    </TableRow>
                  ))}
                  </TableBody>
                 </Table>
              <LdPagination onShowPage={this.setCurrentPage} pages={this.state.pages} />
            </React.Fragment>)}

            
            { (this.state.progress === 1) && (
              <div style={{margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                <div style={{padding:'15px'}}>
                  <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                </div>
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                  {formatMessage({ id: "ListRoleView_Load" })}
                </div>
              </div>
            )}
            {  (this.state.progress === 2 || (this.state.progress === 0 && this.state.datas.length === 0)) && (
              <div style={{margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                  {formatMessage({ id: "ListCahierMatiere_27" })}
                </div>
              </div>
            )}
            { this.state.progress === 3 && (
              <div style={{margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                <div style={{padding:'15px'}}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                  {formatMessage({ id: "ListRoleView_LoadError" })}
                </div>
              </div>
            )}
            {/* <Fab className={fab.className} onClick={this.handleClickOpen} color={fab.color}>
              {fab.icon}
            </Fab> */}
       

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              open={this.state.openSnack}
              autoHideDuration={6000}
              onClose={this.handleSnackBarClose}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={<span id="message-id">{formatMessage({ id: "ListCahierMatiere_28" })}</span>}
              action={[
                <Button
                  key="undo"
                  color="secondary"
                  size="small"
                  onClick={this.handleSnackBarClose}
                >
                  OK!
                </Button>,
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.handleSnackBarClose}
                >
                  <CloseIcon />
                </IconButton>
              ]}
            />
            
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              open={this.state.openSnackEdit}
              autoHideDuration={6000}
              onClose={this.handleSnackBarCloseEdit}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={<span id="message-id">{formatMessage({ id: "ListCahierMatiere_29" })}</span>}
              action={[
                <Button
                  key="undo"
                  color="secondary"
                  size="small"
                  onClick={this.handleSnackBarCloseEdit}
                >
                  OK!
                </Button>,
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.handleSnackBarCloseEdit}
                >
                  <CloseIcon />
                </IconButton>
              ]}
            />
              
              <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              open={this.state.openSnackApprob}
              autoHideDuration={6000}
              onClose={this.handleSnackBarCloseApprob}
              ContentProps={{
                "aria-describedby": "message-id"
              }}
              message={<span id="message-id">{formatMessage({ id: "ListCahierMatiere_approb" })}</span>}
              action={[
                <Button
                  key="undo"
                  color="secondary"
                  size="small"
                  onClick={this.handleSnackBarCloseApprob}
                >
                  OK!
                </Button>,
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.handleSnackBarCloseApprob}
                >
                  <CloseIcon />
                </IconButton>
              ]}
            />
        </div>



    );
  }
}
export default withSnackbar(withStyles(styles)(injectIntl(ListCahierMatiere)));
