import React, { Component } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { appProperties, defaultAvatar, fireWithObject } from '../../components/LdUtils';
import '../../App.css';
import queryString from 'query-string'

import LinearProgress from '@material-ui/core/LinearProgress';
import { injectIntl } from "react-intl";



const styles = theme => ({
  main: {
    width: "400px",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      width: 450,
      marginLeft: "auto",
      marginRight: "auto"
    },

  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: '8px 15px 15px'
    //   padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //     .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,

    display: "inline-block",
    width: "64px",
    height: "64px",
    background: "#ddd",
    // backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: "7px",
    fontSize: '18px',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    backgroundColor: theme.color.primaryDark,
    "&:hover": {
      backgroundColor: 'var(--app-accent-color)'
    },
  },
  progress: {
    width: "100%",
    flexGrow: 1,
  },
  link: {
    fontSize: '14px',
    fontWeight: "500",
    // color:'var(--app-primary-color)',
    textDecoration: 'none'

  }



});
class LoginView extends Component {

  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      email: null,
      password: null,
      rememberMe: null,
      result: null,
      inProgress: false,
      redirect: false,
      currentUser: null,
    }

    this.doLogin = this.doLogin.bind(this);
    this._updateResultLogin = this._updateResultLogin.bind(this);
    // this._handleStorageUserChange = this._handleStorageUserChange.bind(this);
  }
  componentDidMount() {
    document.addEventListener('storage-change', this._handleStorageChange.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener('storage-change', this._handleStorageChange.bind(this));
  }
  _handleStorageChange(event) {
    if (event && event.detail) {
      if (event.detail.key === appProperties.key_user) this.setState({ currentUser: event.detail.value });
    }
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  handleRememberMeChange(e) {
    this.setState({ rememberMe: e.target.checked });
  }
  convertUrlToRoute(str) {
    var myRoute = {
      pathname: "",
      search: ""
    };
    if (str == null || str.trim() === "") return myRoute;
    try {
      var $split1 = str.split("?");
      if ($split1.length > 0) {
        myRoute.pathname = $split1[0];
        myRoute.search = $split1.length > 1 ? decodeURIComponent($split1[1]) : '';
      }
    } catch (error) {
      console.log("failed to parse url on reason: " + error);
    }
    return myRoute;
  }
  _updateResultLogin(data) {
    this.setState({ inProgress: false });
    var disabled = data.disabled;
    var passwordIncorrect = data.password_incorrect;
    var passwordInfo = data.hasOwnProperty("password_incorrect")/* ('password_incorrect' in data)*/;
    var message = ""

    if (data.result_code === 1) {
      localStorage.setItem(appProperties.sessionId, data.sess);
      localStorage.setItem(appProperties.key_user, JSON.stringify(data.user));
      fireWithObject(document, 'storage-change', { key: appProperties.key_user, value: data.user });
      const values = queryString.parse(this.props.location.search)

      var redirectUrl = values.redirect_url || "";
      var redirectRoute = this.convertUrlToRoute(decodeURIComponent(redirectUrl));
      if (redirectUrl !== "") {
        if (this.props && this.props.history && this.props.history != null) this.props.history.push({ pathname: redirectRoute.pathname, search: redirectRoute.search, state: {} });
      } else if (this.props && this.props.history && this.props.history != null) this.props.history.push({ pathname: "/", search: "", state: {} });

      // navigate('Main', {nom: 'Jane'}) //TODO: save other values here
    } else if (data.result_code === 0) {
      this.setState({ result: { result_code: data.result_code, message: this.props.intl.formatMessage({ id: 'LoginView_8' }) } })
    } else if (data.result_code === -1) {
      if (disabled) {
        message = this.props.intl.formatMessage({ id: 'LoginView_8' });
      } else if (passwordInfo) {
        if (passwordIncorrect) {
          message = this.props.intl.formatMessage({ id: 'LoginView_9' });
        } else {
          message = this.props.intl.formatMessage({ id: 'LoginView_10' });
        }
      } else {
        message = this.props.intl.formatMessage({ id: 'LoginView_11' });
      }
      this.setState({ result: { result_code: data.result_code, message: message } })
    } else if (data.result_code === -10) {
      if (passwordIncorrect) {
        message = this.props.intl.formatMessage({ id: 'LoginView_9' });
      } else {
        message = this.props.intl.formatMessage({ id: 'LoginView_12' });
      }
      this.setState({ result: { result_code: data.result_code, message: message } })

    }
  }
  doLogin() {
    var formData = new FormData(); // Currently empty
    formData.append('email', this.state.email);
    formData.append('password', this.state.password);
    formData.append('rememberMe', this.state.rememberMe);
    formData.append('client', appProperties.client);
    let self = this;

    this.setState({ inProgress: true, result: null });

    fetch(appProperties.basepathServer + '/rest-user/login', {
      mode: 'cors',
      method: 'POST',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {
        self._updateResultLogin(data)
      })
      .catch((err) => {
        console.log(err);
        self.setState({ inProgress: false });
        alert('There was an error logging in.');
      });
  }
  // _handleStorageUserChange(obj){
  //   if(obj && obj!=null){
  //     if(obj.key == "currentUser") this.setState({currentUser: obj.value});
  //   }
  // }
  render() {
    const { classes } = this.props;
    //const user = this.state.currentUser;
    const { formatMessage } = this.props.intl;
    return (

      <main className={classes.main}>
        {/* <AppStorage  key={appProperties.key_user} value={user}  onStorageChange={this._handleStorageUserChange} /> */}
        <CssBaseline />
        <Paper square elevation={0} className={classes.paper}>
          <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />


          {this.state.result != null
            ? <div className="Error-message">{this.state.result.message}</div>
            : null
          }

          {this.state.inProgress
            ? <div className={classes.progress}><LinearProgress variant="query" /></div>
            : null
          }

          <div className={classes.form}>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="email">{formatMessage({ id: 'LoginView_1' })}</InputLabel>
              <Input id="email" required onChange={this.handleEmailChange.bind(this)} name="email" autoComplete="email" autoFocus />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="password">{formatMessage({ id: 'LoginView_2' })}</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                required
                onChange={this.handlePasswordChange.bind(this)}
                autoComplete="current-password"
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
              onClick={this.doLogin}>
              {formatMessage({ id: 'LoginView_3' })}

            </Button>
            <div style={{ marginTop: '10px' }} className="layout horizontal center-center">
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={formatMessage({ id: 'LoginView_4' })}
              />
            </div>
            <div style={{ padding: "10px 5px" }} className="layout horizontal justified">
              <a rel="noopener noreferrer" target="_blank" className={classes.link} href={appProperties.socialUrl + '/user/register'}>{formatMessage({ id: 'LoginView_5' })}</a>
              <a rel="noopener noreferrer" target="_blank" className={classes.link} href={appProperties.socialUrl + '/user/recover'}>{formatMessage({ id: 'LoginView_6' })}</a>
            </div>
          </div>
        </Paper>
      </main>
    );
  }
}

LoginView.propTypes = {
  classes: PropTypes.object.isRequired,
  /*theme: PropTypes.object.isRequired,*/

};
export default withStyles(styles/*,{ withTheme: true }*/)(injectIntl(LoginView));
