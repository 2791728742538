import React, { Component } from "react";
import RegisterView from '../views/RegisterView';
import { deleteClassInProps} from '../../components/LdUtils';

class RegisterController extends Component{
    constructor(props){
        super(props);
        this.state ={};
    }
    
    render(){
        return (
            <div>
                Informations passé:{this.props.location.state? this.props.location.state.nom:''}, infos: {JSON.stringify(this.props.location.state?this.props.location.state.infos:{})};
                <RegisterView  {...deleteClassInProps(this.props)} />
            </div>
        );
    }
}

export default RegisterController ;