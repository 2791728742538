import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { Component } from "react";
import { appProperties } from '../../components/LdUtils';
import AjouterManagerView from '../view/manager/AjouterManagerView';
import ListManagerView from '../view/manager/ListManagerView';
import { deleteClassInProps} from '../../components/LdUtils';

const useStyles = theme => ({
    root: {
      display: "block", // Fix IE 11 issue.
      paddingRight:'10px'
    },
    titleBlock:{
      textAlign:'left',
      // paddingLeft:"10px",
    },
    pageTitle:{
      fontSize: '21px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    pageSubtitle:{
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 400,
      color:theme.color.primaryColor
    }
    
  });

class ManagerController extends Component{

    constructor(props){
        super(props);
        //this.ajouterReseauEtablissViewRef = React.createRef();
        //this.ListReseauViewRef = React.createRef();
        this.state  = {
          reseau:null,
        };
        const query = queryString.parse(this.props.location.search);
        var id = query.id||"";
        this.getReseau(id);
    }
    getReseau(id){
       
      let self = this;
      this.setState({reseau:null});
      fetch(appProperties.basepathServer+ '/web/reseau-etablissement/get?id='+id, {
        mode: 'cors',
        method: 'GET',
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json'
        },
        credentials: 'include',
      
      })
      .then((response) => response.json())
      .then((data) => { 
          //if(data.result_code == 1){
            self.setState({reseau:data.item});   
          //}
          
      })
      .catch((err) => {
        console.log(err);
        self.setState({reseau: null});
        console.log('There was an error.');
      });
    }
   
    render(){
        const {classes} = this.props;
        if(this.state.reseau!=null){
          return (<div className = {classes.root}>
            <div  className={classes.titleBlock}>
               <div className={classes.pageTitle} variant="div" component="div">
                 Les managers reseaux # <b>{this.state.reseau.name}</b>
               </div>
               <div className={classes.pageSubtitle} variant="div" component="div">
                 Vous pouvez créer les managers de cet établissement
               </div>
               <br/>

               <AjouterManagerView reseauId = {this.state.reseau.id} {...deleteClassInProps(this.props)}/>
            </div>
            <ListManagerView reseau = {this.state.reseau} {...deleteClassInProps(this.props)}/>
         </div>);
        }else{  
          return (  <div className = {classes.root}></div>);
        }
       
    }
    
}

ManagerController.propTypes = {
    classes: PropTypes.object.isRequired
 };
  
export default withStyles(useStyles)(ManagerController);