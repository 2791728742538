import { Grid } from "@material-ui/core";
//import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { hasAccessKey } from "../components/LdUtils";
import Page404 from "../components/Page404";
import CreateSignatureController from "./controller/CreateSignatureController";
import DerogationController from "./controller/DerogationController";

import { injectIntl } from "react-intl";

const useStyles = theme => ({
  rootLogged: {
    flexGrow: 1,
    padding: "15px"
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },
  toolbar: {
    backgroundColor: "white",
    padding: "15px",
    marginTop: "-15px",
    marginBottom: "15px",
    height: "65px"
  },


});

class SignatureIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      etablissement: this.props.etablissement,
      accesses: this.props.accesses || null,
      inProgress: true,
      inProgessAccess: false,
      progress: 1,
      labelWidth: 0
    };
  }

  componentDidMount() {
    //TODO:Continuer ici: Charger les droits d'acces aussi.

  }


  // componentDidMount() {
  //   // const values = queryString.parse(this.props.location.search);
  //   // console.log(values.filter); // "top"
  //   // console.log(values.origin); // "im"
  // }

  render() {
    const { classes } = this.props;
    // const { formatMessage } = this.props.intl;
    // const values = queryString.parse(this.props.location.search);
    // console.log(values.email); // "im"
    // var emailUser = values.email;

    return (
      <Grid className={classes.rootLogged}>

        <div>
          <Switch>
            <Route
              exact
              path="/page/:code/signature/create"
              render={props => {
                // return (<p>Page des enseignants</p>)
                if (
                  hasAccessKey(this.state.accesses, "coordo")
                ) {
                  return (
                    <CreateSignatureController
                      etablissement={this.state.etablissement}
                      {...props}
                    />
                  );
                } else {
                  //TODO: Afficher une page de  droit d'access
                  return <Page404 {...props} />;
                }
              }}
            />
            <Route
              exact
              path="/page/:code/signature/derogation"
              render={props => {
                // return (<p>Page des enseignants</p>)
                if (
                  hasAccessKey(this.state.accesses, "coordo")
                ) {
                  return (
                    <DerogationController
                      etablissement={this.state.etablissement}
                      {...props}
                    />
                  );
                } else {
                  //TODO: Afficher une page de  droit d'access
                  return <Page404 {...props} />;
                }
              }}
            />

            <Route
              render={props => {
                return <Page404 {...props} />;
              }}
            />
          </Switch>
        </div>
      </Grid>
    );



  }
}
SignatureIndex.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(injectIntl(SignatureIndex));
