import Grid from '@material-ui/core/Grid';
import withStyles from "@material-ui/core/styles/withStyles";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';

import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from "react";
import { appProperties, isObjectValueEquals } from '../../../components/LdUtils';
import ListCahierMatiere from './ListCahierMatiere';
import { deleteClassInProps} from '../../../components/LdUtils';
import { injectIntl } from "react-intl";



const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main:{
    padding:'15px',
    backgroundColor:'white',
    // marginLeft: '10px',
  },
  main2:{
    padding:'15px',
      backgroundColor:'white',
    // marginLeft: '10px',
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },

 


  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
  
  

});


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ListCahierChefView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classe: this.props.classe, //No change to null. it is used to fix bug in select. 
      enseignant: null,
      enseignants: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      progress: 1,
      
    }
    this.handleChangeEnseignant = this.handleChangeEnseignant.bind(this);
    this.handleReset = this.handleReset.bind(this);

  }


  handleReset = () => {
    var self = this;
    this.setState({
      classes:[],
      step:0,
      classe:null,
      enseignant:null,
    },()=>{
      self.listEnseignants();
    
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if(!isObjectValueEquals(this.props.etablissement,prevProps.etablissement)){
      this.setState({etablissement:this.props.etablissement},()=>{
        //self.refreshList("");
      });
    }
    if(!isObjectValueEquals(this.props.annee,prevProps.annee)){
      this.setState({annee:this.props.annee},()=>{
        self.handleReset();
      
      });
    }
  }



  handleChangeEnseignant = (event, value) => {
    this.setState({ enseignant: value },(state)=>{
         
    });
  };
 
  componentDidMount() {  

    this.listEnseignants();
  }
  componentWillUnmount() {
  }


  listEnseignants(){ 
       
    let self = this;
    this.setState({enseignants: [],progressEnseignant:1});
    var url = appProperties.basepathServer+ "/rest-enseignant/list-grouped?etablissement_id="+this.props.etablissement.id+ "&limit=-1";
    if(this.props.annee!=null) url+=("&annee_id="+this.props.annee.id);
    if(this.props.classe!=null) url+=("&classe_id="+this.props.classe.id);

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    
    })
    .then((response) => response.json())
    .then((data) => { 
        //var array = [];
        if(data.result_code === 1){
          var enseignants = []; 
          (data.datas || []).forEach(e=>{
            enseignants = enseignants.concat(e.enseignants);
          });
          self.setState({progressEnseignant:0,enseignants:enseignants});  
          
        } else{
          self.setState({enseignants:[],progressEnseignant:2});   
        }
        
    })
    .catch((err) => {
      console.log(err);
      //self.setState({datas: []});
      self.setState({enseignants:[],progressEnseignant:3}); 

      console.log('There was an error listed type de frais.');
    });
  }
 
  
 
  render() {
    const { classes } = this.props;
    const { classe,enseignant,enseignants } = this.state;
    const { formatMessage } = this.props.intl;

   
    if(classe!=null ) return (
      <div className={classes.main2}>
        <Grid container className={classes.grid} justify="flex-start"  alignItems='center'>
        
          <div>
            <div className={classes.pageTitle} variant="div" component="div">
              {formatMessage({ id: "ListCahierView_6" })}{classe.name}
            </div>
            <div className={classes.pageSubtitle} variant="div" component="div">
              {formatMessage({ id: "ListEnseignantView_2" })}
            </div> 
          </div>
        </Grid>
        <div>
          
          <div style={{marginTop: '10px'}} className={classes.root}>
            <AppBar elevation={0} position="static" color="default">
              <Tabs
                value={enseignant}
                onChange={this.handleChangeEnseignant}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
               <Tab  label={formatMessage({ id: "ListCahierView_13" })} value={null}/>
               {enseignants.map((row,index) => (
                    <Tab key={index} label={row.matiereName||'#'+row.matiere.name} value={row}/>
               ))}
              </Tabs>
            </AppBar>
            {enseignant === null &&
             <div style={{padding:'50px'}} className="layout horizontal center-justified">
               <div style={{border:'1px solid var(--app-primary-color)',borderRadius:'20px',padding:'50px'}} className="layout vertical center">
                 <span style={{color:'white',padding:'5px 40px',textAlign:'center',fontSize:'20px',fontWeight:'600',backgroundColor:'var(--app-primary-color)',marginTop:'-67px'}}>{formatMessage({ id: "ListCahierView_12" })}</span>  
                <div style={{marginTop:'20px',lineHeight:'2em'}}>
                {formatMessage({ id: "ListCahierView_7" })} 
                    <br/>
                    {formatMessage({ id: "ListCahierView_8" })}  <br/>
                    <ul>
                      <li>{formatMessage({ id: "ListCahierView_9" })}</li>
                      <li>{formatMessage({ id: "ListCahierView_10" })} </li>
                      <li>{formatMessage({ id: "ListCahierView_11" })}</li>
                    </ul>
                 </div>
               </div>
             </div>}
            {enseignants.map((row,index) => (
                  <div  key={index}>{enseignant!=null && enseignant.id === row.id && <ListCahierMatiere  mode="chef" etablissement={this.props.etablissement} annee={this.props.annee} classe={classe} enseignant={row}  {...deleteClassInProps(this.props)} />}</div>
            ))}
          </div>
        </div>
      </div>
    );
    return (<span>{formatMessage({ id: "ListCahierEnseignantView_1" })}</span>)
  }
}
export default withStyles(styles)(injectIntl(ListCahierChefView));
