import AppBar from '@material-ui/core/AppBar';
import Avatar from "@material-ui/core/Avatar";
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoneyIcon from '@material-ui/icons/Money';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Bar } from 'react-chartjs-2';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import ProgressiveImage from 'react-progressive-image';
import { appProperties, defaultAvatar, isObjectValueEquals, userProfileUrl, getRound2, cancelEventPropagation } from "../../../components/LdUtils";
import ItemDossierTypeFrais from './ItemDossierTypeFrais';
import ItemVersement from './ItemVersement';
import ItemDossierIndiscipline from '../../../discipline/view/indiscipline/ItemDossierIndiscipline';

import LdPagination from "../../../components/LdPagination";

//      
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import 'moment/locale/fr';
import DateTimePicker from 'react-datetime-picker';

import { deleteClassInProps } from '../../../components/LdUtils';
import LdSelect from "../../../components/LdSelect";
import { withSnackbar } from 'notistack';
import printJS from 'print-js'

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";





const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "100px !important",
    height: "100px !important",
    borderRadius: '0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },


  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },





  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  toolbar: {
    height: '64px',
    backgroundColor: 'var(--app-primary-color);',
    padding: ' 0 10px',

  },
  toolbarTitle: {
    fontSize: '16px',
    color: 'white',
    fontWeight: '600',
    display: 'inline'
  },
  toolbarSubTitle: {
    fontSize: '12px',
    // fontWeight:'600',
    color: '#FEFEFE'
  },
  avatarTitle: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "40px !important",
    height: "40px !important",
    borderRadius: '50% !important'
  },
  avatarDivtitle: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: '50% !important',
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  dossierZone: {
    padding: '10px 0',
    margin: '2px 0',
  },
  dossierTexte: {

    color: 'white',
    padding: '5px',
    backgroundColor: 'var(--app-primary-color)',
    fontsize: '17px',
    fontWeight: '600',
  },
  dialogRoot: {
    top: '64px !important',
    left: '25% !important',
    right: '0 !important',
    bottom: '0 !important',
    // z-index: 1300;
    // position: fixed;
    paddingTop: "15px !important",
    paddingLeft: '18px !important',
    paddingRight: '45px !important'
  },
  backdrop: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 600
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  indisciplineAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-orange-500)',
  },
  scolariteAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-orange-500)',
  },
  scolariteGreen: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-green-500)',
  },
  scolariteYellow: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-yellow-500)',
  },
  scolariteBlue: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--app-primary-color)',
  },
  panelRoot: {
    borderRadius: '0 !important'
  },
  panelSummary: {
    alignItems: 'center',
    margin: '0 !important',
    borderBottom: '1px dashed #EAEAEA'
  },
  summaryRoot: {
    padding: '0',

  },
  summaryExpanded: {
    margin: '0 !important',

  },
  fraisBadge: {
    top: '15px',
    right: '15px'
  },

  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  dialogContentVersement: {
    overflowY: 'unset !important'
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },

});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

// const PostDate = injectIntl(({date, intl}) => (
//   <span  title={intl.formatDate(date)}>
//       {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
//       <FormattedDate 
//        day="numeric"
//        month="long"
//        year="numeric"
//        value={date}/>
//   </span>
// ));
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: 'var(--app-primary-color)',
    fontWeight: '600',
    fontSize: '12px',
    padding: '4px 4px 4px 4px',
    whiteSpace: 'nowrap'
  },
  body: {
    fontSize: 13,
    padding: '4px 20px 4px 20px'
  },
}))(TableCell);

class DossierEleve extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);

    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      position: this.props.position,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      controlled: true,
      eleve: this.props.eleve,
      openDialog: false,
      progressScolarite: 1,
      expanded: this.props.expanded || null,
      unpaids: [],
      paids: [],
      data: {},
      progressIndiscipline: 1,
      indisciplines: [],
      countIndiscipline: 0,
      hasMoreIndiscipline: false,
      pagesIndiscipline: [],
      currentPageIndiscipline: 1,

      progressNote: 1,
      notes: [],

      progressBulletin: 1,
      bulletins: null,
      openVersement: false,
      progressVersement: false,
      controlledVersement: true,
      modePaiement: null,
      amountPaiement: 0,
      dateVersement: new Date(),

      progressListVersement: 1,
      versements: [],


    };//          


    this.handleClickOpenDialog = this.handleClickOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleAddPayment = this.handleAddPayment.bind(this);
    this.handleRemovePayment = this.handleRemovePayment.bind(this);
    this.handleAddReduction = this.handleAddReduction.bind(this);
    this.handleRemoveReduction = this.handleRemoveReduction.bind(this);
    this.setCurrentPageIndiscipline = this.setCurrentPageIndiscipline.bind(this);

    this.handleCloseVersement = this.handleCloseVersement.bind(this);
    this.handleOpenVersement = this.handleOpenVersement.bind(this);
    this.handleSaveVersement = this.handleSaveVersement.bind(this);
    this.handleChangeModeVersement = this.handleChangeModeVersement.bind(this);
    this.handleVersementDateChange = this.handleVersementDateChange.bind(this);
    this.handleChangeVersementAmount = this.handleChangeVersementAmount.bind(this);
    this.doRealodVersement = this.doRealodVersement.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
  }
  handleCloseVersement(event) {
    this.setState({ openVersement: false });
  }
  handleOpenVersement(event) {
    cancelEventPropagation(event);
    this.setState({ openVersement: true });
  }


  handleChangeModeVersement(mode) {
    this.setState({ modePaiement: mode });
  }


  setCurrentPageIndiscipline = page => {
    this.setState({ currentPageIndiscipline: page });
    this.listIndiscipline(page);
  };
  computedFraisBar(paids, unpaids) {
    var bar = {};
    var labels = [];
    var codes = [];
    var dataset1 = {
      label: this.props.intl.formatMessage({ id: "DossierEleve_1" }),
      backgroundColor: '#039be5',
      borderColor: '#03a9f4',
      borderWidth: 1,
      hoverBackgroundColor: '#29b6f6',
      hoverBorderColor: '#03a9f4',
      data: []
    };

    var dataset2 = {
      label: this.props.intl.formatMessage({ id: "DossierEleve_2" }),
      backgroundColor: '#ff9800',
      borderColor: '#ffa726',
      borderWidth: 1,
      hoverBackgroundColor: '#ffb74d',
      hoverBorderColor: '#ffa726',
      data: []
    };
    if (paids != null && paids.length > 0) {
      paids.forEach((item) => {
        codes.push(item.code);
        labels.push(item.name);
        dataset1.data.push(item.amount);
        dataset2.data.push(item.sumPaid || 0);
      })
    }
    if (unpaids != null && unpaids.length > 0) {
      unpaids.forEach((item) => {
        codes.push(item.code);
        labels.push(item.name);
        dataset1.data.push(item.amount);
        dataset2.data.push(item.sumPaid || 0);
      })
    }
    bar['codes'] = codes;
    bar['labels'] = labels;
    bar['datasets'] = [dataset1, dataset2];
    this.setState({ data: bar });
  }
  handleAddPayment = payment => {
    //We refresh page to list paiement
    this.listTypesFrais();
  };
  handleRemovePayment = payment => {
    //We refresh page to list paiement
    this.listTypesFrais();
  };
  handleRemoveReduction = reduction => {
    //We refresh page to list paiement
    this.listTypesFrais();
  };
  handleAddReduction = reduction => {
    //We refresh page to list paiement
    this.listTypesFrais();
  };
  doRealodVersement = (versement, position) => {
    //We refresh page to list paiement
    this.listVersement()
    this.listTypesFrais();
  };

  handleClickOpenDialog = () => {
    this.setState({ openDialog: true });
  };
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  handleCloseDialog = () => {
    var self = this;
    this.setState({ openDialog: false }, () => {
      self.props.onClose(this);
    });

  };
  componentDidMount() {
    this.props.onRef(this);
    this.listTypesFrais();
    this.listVersement();
    if (this.props.access === "inscription") {
      this.listNote();
      this.listIndiscipline(this.state.currentPageIndiscipline);
      this.listBulletin();
    }
  }
  componentWillUnmount() {
    this.props.onRef(null);
  }
  componentDidUpdate(prevProps) {

    if (!isObjectValueEquals(this.props.eleve, prevProps.eleve)) {
      this.setState({ eleve: this.props.eleve }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }

  listVersement() {

    let self = this;

    this.setState({ versements: [], progressListVersement: 1 });
    var url = appProperties.basepathServer + '/manager/scolarite/list-versements?eleve=' + this.props.eleve.id;
    if (this.props.annee != null) url += ("&annee=" + this.props.annee.id);
    if (this.props.etablissement && this.props.etablissement != null) url += ("&etablissement=" + this.props.etablissement.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({ progressListVersement: 0, versements: data.datas });
        } else {
          self.setState({ versements: [], progressListVersement: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        self.setState({ versements: [], unpaids: [], progressListVersement: 3 });
        console.log('There was an error listed.');
      });
  }


  listTypesFrais() {

    let self = this;

    this.setState({ paids: [], unpaids: [], progressScolarite: 1 });
    var url = appProperties.basepathServer + '/manager/scolarite/list-types-frais?eleve=' + this.props.eleve.id;
    if (this.props.annee != null) url += ("&annee=" + this.props.annee.id);
    if (this.props.eleve && this.props.eleve != null) url += ("&classe=" + this.props.eleve.classeEtablissement.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({ progressScolarite: 0, paids: data.paids, unpaids: data.unpaids });
          self.computedFraisBar(data.paids, data.unpaids);
        } else {
          self.setState({ paids: [], unpaids: [], progressScolarite: 2 });
          self.computedFraisBar([], []);
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ paids: [], unpaids: [], progressScolarite: 3 });
        self.computedFraisBar([], []);
        console.log('There was an error listed.');
      });
  }


  listIndiscipline(page) {

    let self = this;

    this.setState({ indisciplines: [], progressIndiscipline: 1 });
    var url = appProperties.basepathServer + '/manager/indiscipline/list?etablissement=' + this.props.etablissement.id + '&limit=10&page=' + page;
    if (this.props.annee != null) url += ("&annee=" + this.props.annee.id);
    if (this.state.eleve != null) url += ("&eleve=" + this.state.eleve.id);
    if (this.state.eleve != null && this.state.eleve.classeEtablissement != null) url += ("&classe=" + this.state.eleve.classeEtablissement.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ progressIndiscipline: 0, indisciplines: data.datas, countIndiscipline: data.count, hasMoreIndiscipline: data.hasMore, pagesIndiscipline: data.pages });

        } else {
          self.setState({ indisciplines: [], progressIndiscipline: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ indisciplines: [], progressIndiscipline: 3 });

        console.log('There was an error listed enseignant.');
      });
  }

  listNote(page) {

    let self = this;

    this.setState({ notes: [], progressNote: 1 });
    var url = appProperties.basepathServer + '/manager/note/loads-by-eleve?limit=-1';
    if (this.props.annee != null) url += ("&annee=" + this.props.annee.id);
    if (this.state.eleve != null) url += ("&eleve=" + this.state.eleve.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ progressNote: 0, notes: data.datas });

        } else {
          self.setState({ notes: [], progressNote: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ notes: [], progressNote: 3 });

        console.log('There was an error listed enseignant.');
      });
  }



  listBulletin() {

    let self = this;

    this.setState({ bulletins: null, progressBulletin: 1 });
    var url = appProperties.basepathServer + '/manager/note/load-moyennes-eleve?limit=-1';
    if (this.state.eleve != null) url += ("&eleve=" + this.state.eleve.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }

          self.setState({ progressBulletin: 0, bulletins: data.annee });

        } else {
          self.setState({ bulletins: null, progressBulletin: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ bulletins: null, progressBulletin: 3 });

        console.log('There was an error listed enseignant.');
      });
  }

  handleChangeVersementAmount(event) {
    this.setState({ amountVersement: event.target.value });
  }
  handleVersementDateChange(date) {
    this.setState({ dateVersement: date });
  }

  handleSaveVersement(event) {

    var amount = parseInt(this.state.amountVersement);
    if (amount <= 0 || this.state.dateVersement == null || this.state.modePaiement == null) {
      this.setState({ controlledVersement: false });
      return;
    } else {
      this.setState({ controlledVersement: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("mode", this.state.modePaiement.id);
    formData.append("date", moment(this.state.dateVersement).locale('fr').format('YYYY-MM-DD HH:mm:ss'));
    formData.append("eleve", this.props.eleve.id);
    formData.append("amount", amount);
    let self = this;
    this.setState({ progressVersement: true });
    fetch(appProperties.basepathServer + "/manager/scolarite/add-versement", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ progressVersement: false });

        if (data.result_code === 1) {
          //self.props.onAddPayment(data.row);
          self.handleCloseVersement();
          self.listVersement();
          self.listTypesFrais();

          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_21" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_22" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_23" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -5) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_24" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -6) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_34" }, { exceed: data.exceed }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_25" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ progressVersement: false });
        //alert("There was an error added.");
      });
  }


  handlePrint = url => {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    // var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }
  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      persist: true,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }



  render() {
    const { classes, eleve } = this.props;
    const { formatMessage } = this.props.intl;
    const { expanded, unpaids, paids, data } = this.state;

    return (
      <React.Fragment>
        <div>
          <Dialog
            open={this.state.openVersement}
            onClose={this.handleCloseVersement}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              <FormattedMessage
                id="DossierEleve_26"
                values={{
                  name: <b>{eleve.fullname || '@' + eleve.user.name}</b>,
                }}
              />
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContentVersement }}>
              <DialogContentText id="alert-dialog-description">
                {formatMessage({ id: "DossierEleve_27" })}
              </DialogContentText>
              <div style={{ padding: '10px 5px' }}>
                {this.state.progressVersement
                  ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                  : null
                }
                {!this.state.controlledVersement
                  ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
                  : null
                }
              </div>
              <div className="layout vertical">
                <div>
                  <span className={classes.label}>
                    {formatMessage({ id: "ItemDossierTypeFrais_9" })}
                  </span>
                  <DateTimePicker
                    locale='fr-FR'
                    className={classes.datePicker}
                    value={this.state.dateVersement}
                    onChange={this.handleVersementDateChange}
                  />
                </div>
                <div className="flex layout horizontal center">
                  <TextField
                    id="amount"
                    label={formatMessage({ id: "ItemDossierTypeFrais_10" })}
                    value={this.state.amountVersement}
                    placeholder="Ex: 25000"
                    onChange={this.handleChangeVersementAmount}
                    margin="normal"
                    type="number"
                    min="0"
                    fullWidth
                  />

                </div>

                <div className="layout">
                  <LdSelect
                    style={{ width: '100%' }}
                    label={formatMessage({ id: "DossierEleve_28" })}
                    handleValue={this.handleChangeModeVersement}
                    firstLoad isSearchable
                    baseUrl={appProperties.basepathServer + "/rest-mode-paiement-etablissement/list?limit=0"}  {...deleteClassInProps(this.props)} />
                </div>
              </div>

            </DialogContent>
            <DialogActions>
              <Button style={{ textTransform: "none" }} onClick={this.handleCloseVersement} color="primary">
                {formatMessage({ id: "ItemAnneeScolaire_Ccl" })}
              </Button>
              <Button style={{ textTransform: "none" }} disabled={this.state.progressVersement} title={formatMessage({ id: "ItemDossierTypeFrais_8" })} onClick={this.handleSaveVersement} color="primary" autoFocus>
                {formatMessage({ id: "DossierEleve_30" })}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullScreen
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
            TransitionComponent={Transition}
            classes={{ root: classes.dialogRoot }}
            BackdropProps={{
              classes: {
                root: classes.backdrop
              }
            }}
          >
            <AppBar className={classes.appBar}>
              <Grid container className={classes.toolbar} justify="space-between" alignItems='center'>
                <div className={classes.avatarDivtitle}>
                  <ProgressiveImage src={eleve.pathPhoto} placeholder={defaultAvatar}>
                    {(src, loading) => (<Avatar alt='' src={src} className={classes.avatarTitle} />)}
                  </ProgressiveImage>
                </div>
                <div className="layout vertical flex">
                  <div>
                    <Typography component="a" rel="noopener noreferrer" target="_blank" href={userProfileUrl(eleve.user)} color="inherit" className={classes.toolbarTitle}>
                      {eleve.fullname || '@' + eleve.user.name}
                    </Typography>
                  </div>

                  <Typography color="inherit" className={classes.toolbarSubTitle}>
                    {formatMessage({ id: "RapportIndisciplineEleve_12" })} <b>{eleve.classeEtablissement.name}</b> {formatMessage({ id: "ItemClasseAnnee_15" })} <b>{eleve.matricule}</b>
                  </Typography>
                </div>

                <Button title={formatMessage({ id: "BonDemande_13" })} style={{ color: 'white', textTransform: "none" }} onClick={this.handleCloseDialog}>
                  <CloseIcon />
                  {formatMessage({ id: "BonDemande_12" })}
                </Button>
              </Grid>

            </AppBar>
            <div style={{ padding: '15px', overflowY: 'auto' }}>
              <Paper style={{ minHeight: '250px', padding: '15px', margin: '0 0 15px 0px', borderRadius: '0 !important' }}>
                <Grid container justify="space-between" alignItems='center'>
                  <div>
                    <div>{formatMessage({ id: "DossierEleve_3" })}</div>
                    <Bar
                      data={data}
                      width={270}
                      height={180}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        tooltips: {
                          callbacks: {
                            title: function (tooltipItem) {
                              return this._data.labels[tooltipItem[0].index];
                            }
                          }
                        },
                        scales: {
                          xAxes: [{
                            ticks: {
                              // Show all labels
                              autoSkip: false,
                              callback: function (tick) {
                                var characterLimit = 10;
                                if (tick.length >= characterLimit) {
                                  return tick.slice(0, tick.length).substring(0, characterLimit - 1).trim() + '...';;
                                }
                                return tick;
                              }
                            }
                          }]
                        }
                      }}

                    />
                  </div>
                </Grid>
              </Paper>
              <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'scolarite'} onChange={this.handleChange('scolarite')}>
                <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
                  <div style={{ position: 'relative' }}>
                    <Avatar className={classes.scolariteAvatar}>
                      <MoneyIcon />
                    </Avatar>
                    {this.state.progressScolarite === 1 &&
                      <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
                    }
                  </div>
                  <div className="flex">
                    <Typography className={classes.heading}>{formatMessage({ id: "LdEtablissementMenu_26" })}</Typography>
                    <Typography className={classes.secondaryHeading}>{formatMessage({ id: "DossierEleve_4" })}</Typography>
                  </div>
                  {this.props.access === "scolarite" && <div>
                    <Button className={classes.buttonPrincipale} style={{ textTransform: "none" }} onClick={this.handleOpenVersement} color="primary" autoFocus>
                      {formatMessage({ id: "DossierEleve_29" })}
                    </Button>
                  </div>}

                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: '10px 0 10px 20px' }} className="layout vertical">
                  {(this.state.progressListVersement === 0 &&
                    <React.Fragment>
                      <div>
                        <Grid container className={classes.grid} justify="flex-start" alignItems='center'>
                          <Badge classes={{ badge: classes.fraisBadge }} badgeContent={this.state.versements ? this.state.versements.length : 0} color="primary">
                            <Avatar className={classes.scolariteBlue}>
                              <MoneyIcon />

                            </Avatar>
                          </Badge>
                          <div>
                            <Typography className={classes.heading}>{formatMessage({ id: "DossierEleve_31" })}</Typography>
                            <Typography className={classes.secondaryHeading}>{formatMessage({ id: "DossierEleve_32" })}</Typography>
                          </div>
                        </Grid>
                        {((!this.state.versements || this.state.versements.length === 0) &&
                          <div style={{ padding: '15px' }} className="layout vertical center-center">
                            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                              {formatMessage({ id: "DossierEleve_33" })}
                            </div>
                          </div>
                        )}
                        {((this.state.versements && this.state.versements.length > 0) &&
                          <div style={{ paddingLeft: '50px' }}>
                            {this.state.versements.map((item, index) => (
                              <ItemVersement position={index} readOnly={this.props.access !== "scolarite"} onEditVersement={this.doRealodVersement} key={index} eleve={this.props.eleve} versement={item}  {...deleteClassInProps(this.props)} />
                            ))}
                          </div>
                        )}

                      </div>
                    </React.Fragment>
                  )}
                  {this.state.progressListVersement === 1 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>
                        <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                      </div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_Load" })}
                      </div>
                    </div>
                  )}


                  {(this.state.progressScolarite === 0 &&
                    <React.Fragment>
                      <div>
                        <Grid container className={classes.grid} justify="flex-start" alignItems='center'>
                          <Badge classes={{ badge: classes.fraisBadge }} badgeContent={unpaids ? unpaids.length : 0} color="primary">
                            <Avatar className={classes.scolariteYellow}>
                              <ThumbDownAltIcon />

                            </Avatar>
                          </Badge>
                          <div>
                            <Typography className={classes.heading}>{formatMessage({ id: "DossierEleve_5" })}</Typography>
                            <Typography className={classes.secondaryHeading}>{formatMessage({ id: "DossierEleve_6" })}</Typography>
                          </div>
                        </Grid>
                        {((!unpaids || unpaids.length === 0) &&
                          <div style={{ padding: '15px' }} className="layout vertical center-center">
                            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                              {formatMessage({ id: "DossierEleve_7" })}
                            </div>
                          </div>
                        )}
                        {((unpaids && unpaids.length > 0) &&
                          <div style={{ paddingLeft: '50px' }}>
                            {unpaids.map((typeFrais, index) => (
                              <ItemDossierTypeFrais readOnly={this.props.access !== "scolarite"} onRemoveReduction={this.handleRemoveReduction} onAddReduction={this.handleAddReduction} onRemovePayment={this.handleRemovePayment} onAddPayment={this.handleAddPayment} key={index} eleve={this.props.eleve} typeFrais={typeFrais}  {...deleteClassInProps(this.props)} />
                            ))}
                          </div>
                        )}

                      </div>
                      <div>
                        <Grid container className={classes.grid} justify="flex-start" alignItems='center'>
                          <Badge classes={{ badge: classes.fraisBadge }} badgeContent={paids ? paids.length : 0} color="primary">
                            <Avatar className={classes.scolariteGreen}>
                              <ThumbUpAltIcon />
                            </Avatar>
                          </Badge>
                          <div>
                            <Typography className={classes.heading}>{formatMessage({ id: "PlanningElement_3" })}</Typography>
                            <Typography className={classes.secondaryHeading}>{formatMessage({ id: "PlanningElement_2" })}</Typography>
                          </div>
                        </Grid>
                        {((!paids || paids.length === 0) &&
                          <div style={{ padding: '15px' }} className="layout vertical center-center">
                            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                              {formatMessage({ id: "DossierEleve_8" })}
                            </div>
                          </div>
                        )}
                        {((paids && paids.length > 0) &&
                          <div style={{ paddingLeft: '50px' }}>
                            {paids.map((typeFrais, index) => (
                              <ItemDossierTypeFrais readOnly={this.props.access !== "scolarite"} position={index} onRemoveReduction={this.handleRemoveReduction} onRemovePayment={this.handleRemovePayment} finished key={index} eleve={this.props.eleve} typeFrais={typeFrais}  {...deleteClassInProps(this.props)} />
                            ))}
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.progressScolarite === 1 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>
                        <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                      </div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_Load" })}
                      </div>
                    </div>
                  )}

                  {(this.state.progressScolarite === 2 &&
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "PlanningElement_1" })}
                      </div>
                    </div>
                  )}
                  {this.state.progressScolarite === 3 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_LoadError" })}
                      </div>
                    </div>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>

              {this.props.access === "inscription" && <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'discipline'} onChange={this.handleChange('discipline')}>
                <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
                  <div style={{ position: 'relative' }}>

                    <Avatar src="/images/icons/indiscipline1.png" className={classes.indisciplineAvatar} />
                    {this.state.progressIndiscipline === 1 &&
                      <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
                    }
                  </div>
                  <div>
                    <Typography className={classes.heading}>{formatMessage({ id: "ItemAlertHistory_11" })}</Typography>
                    <Typography className={classes.secondaryHeading}>{formatMessage({ id: "DossierEleve_9" })}</Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: '10px 0 10px 20px' }} className="layout vertical">
                  {(this.state.progressIndiscipline === 0 &&
                    <React.Fragment>
                      <div style={{ paddingLeft: '30px' }}>
                        {((this.state.indisciplines && this.state.indisciplines.length > 0) &&
                          <div>
                            {this.state.indisciplines.map((indiscipline, index) => (

                              <ItemDossierIndiscipline position={index} key={index} row={indiscipline}  {...deleteClassInProps(this.props)} />
                            ))}
                          </div>
                        )}
                        <LdPagination onShowPage={this.setCurrentPageIndiscipline} pages={this.state.pagesIndiscipline} />

                      </div>

                    </React.Fragment>
                  )}
                  {this.state.progressIndiscipline === 1 && (
                    <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>
                        <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                      </div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_Load" })}
                      </div>
                    </div>
                  )}

                  {((this.state.progressIndiscipline === 2 || (this.state.progressIndiscipline === 0 && this.state.indisciplines.length === 0)) &&
                    <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                      {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "DossierEleve_10" })}
                      </div>
                    </div>
                  )}
                  {this.state.progressIndiscipline === 3 && (
                    <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_LoadError" })}
                      </div>
                    </div>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>}


              {this.props.access === "inscription" && <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'note'} onChange={this.handleChange('note')}>
                <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
                  <div style={{ position: 'relative' }}>

                    <Avatar src="/images/icons/indiscipline1.png" className={classes.indisciplineAvatar} />
                    {this.state.progressNote === 1 &&
                      <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
                    }
                  </div>
                  <div>
                    <Typography className={classes.heading}>{formatMessage({ id: "DossierEleve_11" })}</Typography>
                    <Typography className={classes.secondaryHeading}>{formatMessage({ id: "DossierEleve_12" })}</Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: '10px 0 10px 0' }} className="layout vertical">
                  {(this.state.progressNote === 0 &&
                    <React.Fragment>
                      <div>
                        {((this.state.notes && this.state.notes.length > 0) &&
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <CustomTableCell><span style={{ paddingLeft: '20px' }}>{formatMessage({ id: "DossierEleve_20" })}</span></CustomTableCell>
                                {this.state.notes[0].trimestres.map((trimestre, index2) => (
                                  <React.Fragment key={trimestre.id}>
                                    {trimestre.sequences.map((sequence, index3) => (
                                      <CustomTableCell key={sequence.id} title={sequence.name} align="right"><span style={{ color: '#77c8e2', fontWeight: '400' }}>{sequence.code}</span></CustomTableCell>
                                    ))}
                                    <CustomTableCell title={trimestre.name} align="right"><span style={{ color: '#66caea' }}>{trimestre.code}</span></CustomTableCell>
                                  </React.Fragment>
                                ))}



                                <CustomTableCell align="right">{formatMessage({ id: "DossierEleve_13" })}</CustomTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.notes.map((annee, index1) => (
                                <TableRow className={classes.row} key={annee.id}>
                                  <CustomTableCell component="th" scope="row">
                                    <div className="layout vertical">
                                      <span>{annee.name}</span>
                                      <span style={{ fontSize: '11px', fontWeight: '600' }}>{formatMessage({ id: "DossierEleve_14" })} {annee.coefficient}</span>
                                    </div>
                                  </CustomTableCell>
                                  {annee.trimestres.map((trimestre, index2) => (
                                    <React.Fragment key={trimestre.id}>
                                      {trimestre.sequences.map((sequence, index3) => (
                                        <CustomTableCell key={sequence.id} align="right">{sequence.note != null ? getRound2(sequence.note) : ''}</CustomTableCell>
                                      ))}
                                      <CustomTableCell align="right"><b>{trimestre.note != null ? getRound2(trimestre.note) : ''}</b></CustomTableCell>
                                    </React.Fragment>
                                  ))}
                                  <CustomTableCell align="right"><b>{annee.note != null ? getRound2(annee.note) : ''}</b></CustomTableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        )}
                      </div>

                    </React.Fragment>
                  )}
                  {this.state.progressNote === 1 && (
                    <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>
                        <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                      </div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_Load" })}
                      </div>
                    </div>
                  )}

                  {((this.state.progressNote === 2 || (this.state.progressNote === 0 && this.state.notes.length === 0)) &&
                    <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                      {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "DossierEleve_15" })}
                      </div>
                    </div>
                  )}
                  {this.state.progressNote === 3 && (
                    <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_LoadError" })}
                      </div>
                    </div>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>}


              {this.props.access === "inscription" && <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'bulletin'} onChange={this.handleChange('bulletin')}>
                <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
                  <div style={{ position: 'relative' }}>

                    <Avatar src="/images/icons/indiscipline1.png" className={classes.indisciplineAvatar} />
                    {this.state.progressBulletin === 1 &&
                      <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
                    }
                  </div>
                  <div>
                    <Typography className={classes.heading}>{formatMessage({ id: "DossierEleve_16" })}</Typography>
                    <Typography className={classes.secondaryHeading}>{formatMessage({ id: "DossierEleve_17" })}</Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: '10px 0 10px 0' }} className="layout vertical">
                  {(this.state.progressBulletin === 0 &&
                    <React.Fragment>
                      <div>
                        {((this.state.bulletins != null) &&
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <CustomTableCell><span style={{ paddingLeft: '20px' }}>{formatMessage({ id: "ListNoteEnseignantView_14" })}</span></CustomTableCell>
                                <CustomTableCell align="right">{formatMessage({ id: "ListMoyenne_14" })}</CustomTableCell>
                                <CustomTableCell align="right">{formatMessage({ id: "ListMoyenne_13" })}</CustomTableCell>
                                <CustomTableCell align="right">{formatMessage({ id: "DossierEleve_16" })}</CustomTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.bulletins && (
                                <React.Fragment>
                                  {this.state.bulletins.trimestres && this.state.bulletins.trimestres.length > 0 && (
                                    <React.Fragment>
                                      {this.state.bulletins.trimestres.map((trimestre, index1) => (
                                        <React.Fragment key={trimestre.id}>
                                          {trimestre.sequences && trimestre.sequences.length > 0 && (
                                            <React.Fragment>
                                              {trimestre.sequences.map((sequence, index2) => (
                                                <React.Fragment key={sequence.id}>
                                                  {sequence.moyenne && sequence.moyenne != null && (
                                                    <ItemMoyenneEleve onPrintBulletin={this.handlePrint} mode="sequence" modeId={sequence.id} eleve={this.state.eleve} classes={classes} name={sequence.name} row={sequence.moyenne} {...deleteClassInProps(this.props)} />
                                                  )}
                                                </React.Fragment>
                                              ))}
                                            </React.Fragment>
                                          )}
                                          {trimestre.moyenne && trimestre.moyenne != null && (
                                            <ItemMoyenneEleve onPrintBulletin={this.handlePrint} mode="trimestre" modeId={trimestre.id} eleve={this.state.eleve} classes={classes} name={trimestre.name} row={trimestre.moyenne} {...deleteClassInProps(this.props)} />
                                          )}
                                        </React.Fragment>
                                      ))}
                                    </React.Fragment>
                                  )}
                                  {this.state.bulletins.moyenne && this.state.bulletins.moyenne != null && (
                                    <ItemMoyenneEleve onPrintBulletin={this.handlePrint} mode="" eleve={this.state.eleve} classes={classes} name="Moyenne Annuelle" row={this.state.bulletins.moyenne} {...deleteClassInProps(this.props)} />
                                  )}
                                </React.Fragment>
                              )}
                            </TableBody>
                          </Table>
                        )}
                      </div>

                    </React.Fragment>
                  )}
                  {this.state.progressBulletin === 1 && (
                    <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>
                        <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                      </div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_Load" })}
                      </div>
                    </div>
                  )}

                  {((this.state.progressBulletin === 2 || (this.state.progressBulletin === 0 && this.state.bulletins.length === 0)) &&
                    <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                      {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "DossierEleve_18" })}
                      </div>
                    </div>
                  )}
                  {this.state.progressBulletin === 3 && (
                    <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: "ListRoleView_LoadError" })}
                      </div>
                    </div>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>}


            </div>

          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}
const ItemMoyenneEleve = (props) => {
  var printBaseUrl = appProperties.basepathServer + '/manager/note/print-bulletins?etablissement=' + props.eleve.etablissementId;
  if (props.eleve != null && props.eleve.anneeScolaireEtablissement != null) printBaseUrl += ("&annee=" + props.eleve.anneeScolaireEtablissement.id);
  if (props.eleve != null && props.eleve.classeEtablissement != null) printBaseUrl += ("&classe=" + props.eleve.classeEtablissement.id);
  if (props.eleve != null) printBaseUrl += ("&eleve=" + props.eleve.id);
  if (props.mode === "sequence") {
    printBaseUrl += ("&sequence=" + props.modeId);
  }
  if (props.mode === "trimestre") {
    printBaseUrl += ("&trimestre=" + props.modeId);
  }

  return (
    <TableRow className={props.classes.row} key={props.row.id}>
      <CustomTableCell component="th" scope="row">
        <div className="layout vertical">
          <span>{props.name}</span>
        </div>
      </CustomTableCell>
      <CustomTableCell align="right">{props.row.valeur != null ? getRound2(props.row.valeur) : ''}</CustomTableCell>
      <CustomTableCell align="right"><b>{props.row.rank}</b></CustomTableCell>
      <CustomTableCell align="right">
        {props.row.bulletin != null &&
          <Button variant="outlined" color="primary" title={props.intl.formatMessage({ id: "DossierEleve_19" })}
            onClick={() => props.onPrintBulletin(printBaseUrl + '&eleve=' + props.eleve.id)}
            style={{ fontWeight: "600", fontSize: '11px', textTransform: 'none' }}>
            {props.intl.formatMessage({ id: "ListMoyenne_4" })}&nbsp; {props.name} &nbsp;-&nbsp;{props.row.bulletinDate}
          </Button>
        }
        {props.row.bulletin == null &&
          <span style={{ fontSize: '11px' }}>{props.intl.formatMessage({ id: "ListMoyenne_3" })}</span>
        }
      </CustomTableCell>
    </TableRow>
  )
};


DossierEleve.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  eleve: PropTypes.object.isRequired,
  annee: PropTypes.object.isRequired,
  etablissement: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(DossierEleve)));
