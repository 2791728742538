import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AjouterTypeMaterielView from '../view/type-materiel/AjouterTypeMaterielView';
import ListTypeMaterielView from '../view/type-materiel/ListTypeMaterielView';
import { deleteClassInProps } from '../../components/LdUtils';
import { injectIntl } from "react-intl";

const useStyles = theme => ({
  root: {
    display: "block", // Fix IE 11 issue.
    paddingRight: '10px'
  },
  titleBlock: {
    textAlign: 'left',
    // paddingLeft:"10px",
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  }

});

class TypeMaterielController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      etablissement: this.props.etablissement

    }

    //this.ajouterReseauEtablissViewRef = React.createRef();
    //this.ListReseauViewRef = React.createRef();

  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={classes.root}>
        <div className={classes.titleBlock}>
          <div className={classes.pageTitle} variant="div" component="div">
            {formatMessage({ id: 'typeMaterielController_1' })}
          </div>
          <div className={classes.pageSubtitle} variant="div" component="div">
            {formatMessage({ id: 'typeMaterielController_2' })}
          </div>
          <AjouterTypeMaterielView etablissementId={this.state.etablissement.id}  {...deleteClassInProps(this.props)} />
        </div>
        <ListTypeMaterielView etablissement={this.state.etablissement}  {...deleteClassInProps(this.props)} />

      </div>
    );
  }

}

TypeMaterielController.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(injectIntl(TypeMaterielController));