import Avatar from "@material-ui/core/Avatar";
import deepOrange from '@material-ui/core/colors/deepOrange';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import LinearProgress from "@material-ui/core/LinearProgress";

import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import moment from 'moment';
import 'moment/locale/fr';
import PropTypes from "prop-types";
import React, { Component } from "react";
import DateTimePicker from 'react-datetime-picker';
import { FormattedDate, FormattedMessage, FormattedTime, injectIntl, intlShape } from 'react-intl';
import { appProperties, userProfileUrl } from "../../../components/LdUtils";

import { deleteClassInProps, isObjectValueEquals } from '../../../components/LdUtils';
import LdSelect from "../../../components/LdSelect";
import printJS from 'print-js'
import { withSnackbar } from "notistack";

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";


import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
  root: {
    // display: "flex",
    // justifyContent : "flex-start",
    // alignItems: "center",
    // paddingBottom: '10px',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    width: "60px !important",
    height: "60px !important",
    borderRadius: '0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 10px'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    // marginTop:"15px"

  },
  grid: {
    borderTop: '1px solid #EEEEEE',
    padding: '5px 5px 5px 0',
    // borderBottom:'1px solid #EEEEEE'
  },

  main: {
    margin: '10px',
    border: '1px solid #EAEAEA',
    padding: '10px'
  },
  paymentAvatar: {
    margin: 5,
    color: '#fff',
    backgroundColor: deepOrange[500],
  },
  listItemRoot: {
    padding: '0 !important'
  }
});



const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
    <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));

class ItemVersement extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)

    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      showEditForm: false,
      position: this.props.position,
      controlledVersement: true,
      progressDelete: false,
      versement: this.props.versement,
      dateVersement: this.props.versement.date == null || this.props.versement.date.trim() === '' ? new Date() : new Date(this.props.versement.date),
      amountVersement: this.props.versement.amount || 0,
      modePaiement: this.props.versement.mode || null,

    };


    this.resetForm = this.resetForm.bind(this);
    this.doEditForm = this.doEditForm.bind(this);
    this.doEditVersement = this.doEditVersement.bind(this);
    this.handleChangeVersementAmount = this.handleChangeVersementAmount.bind(this);
    this.handleVersementDateChange = this.handleVersementDateChange.bind(this);
    this.handleChangeModeVersement = this.handleChangeModeVersement.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }



  handleClickOpen = () => {
    this.setState({ openConfirm: true });
  };

  handleClose = () => {
    this.setState({ openConfirm: false });
  };

  doEditForm = () => {
    this.setState({ showEditForm: true });
  }

  resetForm = () => {
    this.setState({ showEditForm: false, controlledVersement: true });
  }

  handleChangeVersementAmount(event) {
    this.setState({ amountVersement: event.target.value });
  }
  handleVersementDateChange(date) {
    this.setState({ dateVersement: date });
  }

  handleChangeModeVersement(mode) {
    this.setState({ modePaiement: mode });
  }
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.versement, prevProps.versement)) {
      this.setState({ row: this.props.versement }, () => {
        self.setState({
          versement: self.props.versement,
          dateVersement: self.props.versement.date == null || self.props.versement.date.trim() === '' ? new Date() : new Date(self.props.versement.date),
          amountVersement: self.props.versement.amount || 0,
          modePaiement: self.props.versement.mode || null,
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }

  handleClickDeletePaiement = () => {
    var self = this;
    this.setState({ progressDelete: true });
    var formData = new FormData(); // Currently empty
    formData.append("id", this.props.versement.id);
    fetch(
      appProperties.basepathServer +
      "/manager/scolarite/delete-versement",
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.setState({ progressDelete: false });
          self.handleClose();
          //self.props.onUpdateItem("delete", { position: self.state.position });
          self.props.onEditVersement(self.state.position, this.props.versement);
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ progressDelete: false });
        alert("There was an error added.");
      });
  };


  doEditVersement = () => {
    var amount = parseInt(this.state.amountVersement);
    if (amount <= 0 || this.state.dateVersement == null || this.state.modePaiement == null) {
      this.setState({ controlledVersement: false });
      return;
    } else {
      this.setState({ controlledVersement: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("id", this.props.versement.id);
    // formData.append("eleve", this.props.eleve.id);
    formData.append("mode", this.state.modePaiement.id);
    formData.append("date", moment(this.state.dateVersement).locale('fr').format('YYYY-MM-DD HH:mm:ss'));
    formData.append("amount", amount);
    let self = this;
    this.setState({ inProgress: true, resultCode: 0 });
    fetch(appProperties.basepathServer + "/manager/scolarite/edit-versement", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.props.onEditVersement(data.versement, self.props.position);
          //TODO:  translate  with edit
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemVersement_1" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_22" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_23" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -5) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_24" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -6) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_34" }, { exceed: data.exceed }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "DossierEleve_25" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });

        }


      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }




  handlePrint = url => {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    // var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }
  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon /> {/*{self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      persist: true,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }

  render() {
    const { classes, versement } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {formatMessage({ id: "ItemDossierTypeFrais_18" }) +
              (this.props.versement != null ? this.props.versement.amount : 0 || 0) +
              " F CFA ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemEleve_1" })}
            </DialogContentText>
            <div style={{ padding: "10px 5px" }}>
              {this.state.progressDelete ? (
                <div className={classes.progress}>
                  <LinearProgress variant="query" />
                </div>
              ) : null}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "none" }}
              onClick={this.handleClose}
              color="primary"
            >
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button
              style={{ textTransform: "none" }}
              onClick={this.handleClickDeletePaiement}
              color="primary"
              autoFocus
            >
              {formatMessage({ id: "ItemEleve_3" })}
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.root}>
          <Grid container className={classes.grid} justify="space-between" wrap="nowrap" alignItems='center'>


            <div className='layout horizontal center'>
              <Avatar className={classes.paymentAvatar}>
                V{this.props.position + 1}
              </Avatar>
              <div>
                <div className='layout horizontal center' style={{ paddingLeft: "10px" }}>

                  <span className={classes.name}>
                    <FormattedMessage
                      id="ItemVersement_6"
                      values={{
                        mode: <b style={{ color: 'var(--app-primary-color)' }}><i>{versement.mode ? versement.mode.name : ""}</i></b>,
                      }}
                    />
                  </span>
                  <div style={{ paddingLeft: '5px' }} className={classes.date}>
                    <FormattedMessage
                      id="ItemVersement_7"
                      values={{
                        date: <b><i>{versement.date && versement.date != null ? <PostDate date={versement.date} /> : null}</i></b>,
                      }}
                    />
                  </div>
                </div>
                <div style={{ fontSize: "13px", paddingLeft: "10px" }}>
                  <span>
                    <FormattedMessage
                      id="ItemVersement_5"
                      values={{
                        amount: <b style={{ paddingLeft: '5px' }}>{versement.amount}</b>,
                      }}
                    />
                  </span>
                  <span style={{ fontSize: '12px', fontWeight: '400' }}>
                    &nbsp;&nbsp;<FormattedMessage
                      id="ItemEleveScol_1"
                      values={{
                        date: <PostDate date={versement.created} />,
                        user: (
                          <a
                            style={{
                              paddingLeft: "5px",
                              fontWeight: "500",
                              fontSize: "11px"
                            }}
                            className="no-underline capitalize"
                            title={versement.creator.name}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={userProfileUrl(versement.creator)}
                          >
                            {versement.creator.name}
                          </a>
                        )
                      }}
                    />

                  </span>
                </div>
              </div>


            </div>
            <div className={classes.actions}>
              {!this.props.readOnly && <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
                aria-label="versement"
                onClick={this.doEditForm}>
                <EditIcon fontSize="small" />
              </IconButton>}
              <Button onClick={() => this.handlePrint(appProperties.basepathServer + '/manager/scolarite/print-versement?id=' + versement.id)} title={formatMessage({ id: "ItemVersement_4" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginRight: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
                {formatMessage({ id: "ItemVersement_3" })}
              </Button>

              <Button
                onClick={this.handleClickOpen}
                title={formatMessage({ id: "ItemDossierTypeFrais_2" })}
                style={{
                  textTransform: "none",
                  borderRadius: "0 !important",
                  marginRight: "10px"
                }}
                variant="outlined"
                size="small"
                className={classes.margin}
              >
                {formatMessage({ id: "ItemDossierTypeFrais_2" })}
              </Button>
              {/* <Button component="a" download target="_blank" href={appProperties.basepathServer + '/manager/scolarite/print-versement?id=' + versement.id} title={formatMessage({ id: "ItemVersement_4" })} style={{ textTransform: 'none', borderRadius: '0 !important', marginRight: '10px', color: 'var(--app-primary-color)' }} variant="outlined" size="small" className={classes.margin}>
                {formatMessage({ id: "ItemVersement_3" })}
              </Button> */}

            </div>
          </Grid>


          {this.state.showEditForm && !this.props.readOnly &&
            <main className={classes.main}>
              {this.state.inProgress
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
              {!this.state.controlledVersement
                ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
                : null
              }
              <div className="layout horizontal">
                <div style={{ paddingRight: '10px' }} className="flex layout horizontal center">
                  <TextField
                    id="amount"
                    label={formatMessage({ id: "ItemDossierTypeFrais_10" })}
                    style={{ width: '25%', marginTop: '0 !important' }}
                    value={this.state.amountVersement}
                    placeholder="Ex: 22000"
                    onChange={this.handleChangeVersementAmount}
                    margin="normal"
                    type="number"
                    min="0"
                  />
                  <span className={classes.label}>
                    {formatMessage({ id: "ItemDossierTypeFrais_9" })}
                  </span>
                  <DateTimePicker
                    locale='fr-FR'
                    className={classes.datePicker}
                    value={this.state.dateVersement}
                    onChange={this.handleVersementDateChange}
                  />
                  <div style={{ width: '150px' }} className="layout">
                    <LdSelect
                      style={{ width: '100%' }}
                      label={formatMessage({ id: "DossierEleve_28" })}
                      handleValue={this.handleChangeModeVersement}
                      firstLoad isSearchable
                      defaultValue={
                        {
                          label: this.state.modePaiement && this.state.modePaiement != null ? this.state.modePaiement.name : "",
                          value: this.state.modePaiement && this.state.modePaiement != null ? this.state.modePaiement.id : '',
                          id: this.state.modePaiement && this.state.modePaiement != null ? this.state.modePaiement.id : '',
                        }
                      }
                      baseUrl={appProperties.basepathServer + "/rest-mode-paiement-etablissement/list?limit=0"}  {...deleteClassInProps(this.props)} />
                  </div>
                </div>
                <div className="layout horizontal center wrap">
                  <Button
                    onClick={this.doEditVersement}
                    variant="contained"
                    size="medium"
                    style={{ margin: '5px' }}
                    title={formatMessage({ id: "ItemVersement_2" })}
                    className={classes.buttonPrincipale}>
                    {formatMessage({ id: "ItemAnneeScolaire_Up" })}
                  </Button>
                  <Button
                    onClick={this.resetForm}
                    variant="contained"
                    size="medium"
                    style={{ margin: '5px' }}
                    className={classes.buttonGreyWhite}>
                    {formatMessage({ id: "ItemAnneeScolaire_Ccl" })}
                  </Button>
                </div>
              </div>
            </main>}





        </div>
      </React.Fragment>
    );
  }
}
ItemVersement.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  typeFrais: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemVersement)));
