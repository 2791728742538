import Avatar from "@material-ui/core/Avatar";
import withStyles from "@material-ui/core/styles/withStyles";
// import AddIcon from '@material-ui/icons/Add';
import CancelIcon from "@material-ui/icons/Cancel";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ProgressiveImage from "react-progressive-image";
import {
  appProperties,
  defaultAvatar,
  userProfileUrl,
  isObjectValueEquals,
  deleteClassInProps
} from "../../../components/LdUtils";
import Grid from "@material-ui/core/Grid";
import { injectIntl, intlShape } from "react-intl";
import Chip from '@material-ui/core/Chip';
import AddElementEnseignant from "./AddElementEnseignant";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import LinearProgress from "@material-ui/core/LinearProgress";


import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import LdSelectGroupClasse from '../../../components/LdSelectGroupClasse';
import LdSelect from '../../../components/LdSelect';
import LdSelectEtablissementEnseignant from "../../../components/LdSelectEtablissementEnseignant";

const styles = theme => ({
  root: {
    padding: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  content: {
    display: "flex"
    // "-ms-flex": "1 1 0.000000001px",
    // "-webkit-flex": "1",
    // "flex": "1",
    // "-webkit-flex-basis": "0.000000001px",
    // "flex-basis": "0.000000001px"
  },
  contentImage: {
    marginRight: "10px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none",
    // padding: "0 10px 0 10px"
  },
  email: {
    fontSize: "13px",
    fontWeight: "400",
    // padding: "0 10px 0 10px"
  },
  bigAvatar: {
    backgroundColor: "lightgray",
    border: "2px solid white",
    width: "100px !important",
    height: "100px !important",
    borderRadius: "0 !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  grid: {

  },
  matiereContainer: {
    border: '1px dashed grey',
    padding: '0 5px',
    margin: '5px 5px 0 0',
    borderRadius: '10px',
  },
  chip: {
    borderRadius: '0 !important',
  },
  chip2: {
    borderRadius: '0 !important',
    border: "1px solid var(--paper-amber-500)",
    // backgroundColor:'var(--paper-amber-500)'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },
});

// const PostDate = injectIntl(({ date, intl }) => (
//   <span title={intl.formatDate(date)}>
//     {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
//     <FormattedDate day="numeric" month="long" year="numeric" value={date} />
//   </span>
// ));
class ItemEnseignant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      result: null,
      redirect: false,
      // ids: "",
      datas: [],
      inProgress: false,
      position: this.props.position,
      row: this.props.row,

      openDelete: false,
      deleteItem: null,

      openEdit: false,
      classeEdit: null,
      matiereEdit: null,
      enseignantEdit: null,
      secondaryTeachersEdit: [],

      coefficient: 0,
      quotaHoraire: 0,
      ordre: 0,
      matiereName: "",
      group: null,

      controlledEdit: true,
      inProgressEdit: false,
      openSnackEdit: false
    };


    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.doAddElement = this.doAddElement.bind(this);
    this.onNewElement = this.onNewElement.bind(this);
    this.handleShowEditInfo = this.handleShowEditInfo.bind(this);
    this.handleClickOpenDelete = this.handleClickOpenDelete.bind(this);
    this.handleCloseDelete = this.handleCloseDelete.bind(this);

    this.handleEditItem = this.handleEditItem.bind(this);
    this.handleCloseEdit = this.handleCloseEdit.bind(this);
    this.handleSnackBarEdit = this.handleSnackBarEdit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.handleChangeMatiere = this.handleChangeMatiere.bind(this);
    this.handleValueSecondaryTeachers = this.handleValueSecondaryTeachers.bind(this);

  }
  handleSnackBarEdit = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnackEdit: false });
  };
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };
  handleCloseDelete = () => {
    this.setState({ openDelete: false, deleteItem: null });
  };
  handleClickOpenDelete(item) {
    this.setState({ deleteItem: item, openDelete: true });
  };

  handleChangeGroup(item) {
    this.setState({ group: item ? item : null });
  };
  handleValueSecondaryTeachers(selectedOptions) {

    this.setState({ secondaryTeachersEdit: selectedOptions });
  }
  handleCloseEdit = () => {
    this.setState({ openEdit: false, classeEdit: null, matiereEdit: null, enseignantEdit: null, coefficient: 0, quotaHoraire: 0, matiereName: "" });
  };

  handleChangeMatiere(item) {
    this.setState({ matiereEdit: item ? item : null });
  };
  handleShowEditInfo(classe, index1, enseignant, index2) {
    var secondarys = enseignant.secondaryTeachers.map(option => {
      return Object.assign({ label: option ? option.name : "", value: option ? option.id : "", id: option ? option.id : "" }, option);
    });
    console.log("secondarys");
    console.log(secondarys);
    var data = {
      classeEdit: classe,
      enseignantEdit: enseignant,
      coefficient: enseignant != null ? enseignant.coefficient : 0,
      quotaHoraire: enseignant != null ? enseignant.quotaHoraire : 0,
      ordre: enseignant != null ? enseignant.orderingGroupe : 0,
      matiereName: enseignant != null ? enseignant.matiereName : "",
      matiereEdit: enseignant != null ? enseignant.matiere : "",
      group: enseignant != null ? enseignant.groupeEnseignement : null,
      secondaryTeachersEdit: enseignant != null ? secondarys : [],
      openEdit: true
    };
    this.setState(data);
  }
  componentDidUpdate(prevProps) {
    //var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      //if(JSON.stringify(this.props.row) !==  JSON.stringify(prevProps.row)){
      this.setState({ row: this.props.row }, () => {
        //self.refreshList("");

      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }

  doAddElement(event) {
    this.addElementRef.doOpenDialog();

    //TODO: Affichier le dialog pour choisir la classe, matiere, quota, effectif. 
  }
  onNewElement(item) {
    var enseignants = this.state.row.enseignants || [];
    var output = Object.assign({}, this.state.row);
    enseignants.push(item);
    output['enseignants'] = enseignants;
    this.setState({ row: output }, () => {
    });
  }
  handleEditItem = event => {
    console.log(this.state);
    if (
      this.state.annee == null ||
      this.state.etablissement == null ||
      this.state.classeEdit == null ||
      this.state.enseignantEdit == null ||

      parseFloat(this.state.coefficient) <= 0 ||
      parseInt(this.state.ordre) <= 0 ||
      parseInt(this.state.quotaHoraire) < 0
    ) {
      this.setState({ controlledEdit: false });
      return;
    } else {
      this.setState({ controlledEdit: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("id", this.state.enseignantEdit.id);
    formData.append("etablissement", this.state.etablissement.id);
    formData.append("user", this.state.enseignantEdit.user.id);
    formData.append("classe", this.state.classeEdit.id);
    formData.append("annee", this.state.annee.id);
    if (this.state.matiereEdit != null) formData.append("matiere", this.state.matiereEdit.id);
    if (this.state.group != null) formData.append("group", this.state.group.id);

    formData.append("coefficient", this.state.coefficient);
    formData.append("quotaHoraire", this.state.quotaHoraire);
    formData.append("orderingGroupe", this.state.ordre);
    formData.append("matiereName", this.state.matiereName);
    var secondaryTeachersIds = (this.state.secondaryTeachersEdit || []).reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + "-" + e.id : e.id;
    }, "");

    formData.append("secondary_ids", secondaryTeachersIds);
    let self = this;
    this.setState({ inProgressEdit: true });
    fetch(
      appProperties.basepathServer + "/manager/annee-scolaire/add-enseignant",
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressEdit: false, openEdit: false });
        if (data.result_code === 1) {
          self.setState({ openSnackEdit: true });
          self.updateEnseignant(data.row);

        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgressEdit: false });
        self.setState({ openSnackEdit: false });
        alert("There was an error added.");
      });
  };
  updateEnseignant(item) {
    var self = this;
    if (null == this.state.row) return;
    if (this.state.row.enseignants && Array.isArray(this.state.row.enseignants) && 0 < this.state.row.enseignants.length) {
      var enseignants = this.state.row.enseignants;
      var output = Object.assign({}, this.state.row);
      var results = enseignants.reduce((acc, cur, ind, arr) => {
        return (cur && cur != null) ? (cur.id !== item.id ? acc.concat(cur) : acc.concat(item)) : cur;
      }, []);
      output['enseignants'] = results;
      this.setState({ row: output }, () => {
        self.props.onUpdateItem("update", { position: self.state.position, row: output });
      });
    }
  }
  handleDeleteItem = event => {
    /*console.log("delete iitem");
    console.log(item);*/
    var item = this.state.deleteItem;
    if (item == null) return;
    var self = this;
    //self.removeEnseignant(item.id);
    //return;
    this.handleCloseDelete();
    fetch(appProperties.basepathServer + "/rest-enseignant/delete?id=" + item.id, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.removeEnseignant(item.id);
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          //self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        //alert("There was an error deleted.");
      });
  };
  removeEnseignant(id) {
    var self = this;
    if (null == this.state.row) return;
    if (this.state.row.enseignants && Array.isArray(this.state.row.enseignants) && 0 < this.state.row.enseignants.length) {
      var enseignants = this.state.row.enseignants;
      var output = Object.assign({}, this.state.row);
      var results = enseignants.reduce((acc, cur, ind, arr) => {
        return cur && cur != null && cur.id !== id ? acc.concat(cur) : acc;
      }, []);
      output['enseignants'] = results;
      this.setState({ row: output }, () => {
        if (results.length <= 0) self.props.onUpdateItem("delete", { position: self.state.position });
      });
    }
  }


  formatData(input) {
    if (null == input) return null;
    if (input.enseignants && Array.isArray(input.enseignants) && 0 < input.enseignants.length) {
      var enseignants = input.enseignants;
      var output = Object.assign({}, input);
      delete output.enseignants;
      var exist = false;
      var myReduce = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
        // console.log(accumulator);
        exist = accumulator.reduce((acc, cur, ind, arr) => {
          return acc ? acc : null != cur && null != currentValue.classeEtablissement && cur.id === currentValue.classeEtablissement.id;
        }, false);
        return exist ? accumulator : accumulator.concat(currentValue.classeEtablissement ? currentValue.classeEtablissement : null);
      }, []);
      var contents = [];
      var mat = null;
      // console.log("myReduce ", myReduce);
      var myMap = myReduce.map((classe, index, array) => {
        contents = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
          if (null != classe && null != currentValue.classeEtablissement && classe.id === currentValue.classeEtablissement.id) {
            mat = Object.assign({}, currentValue);
            delete mat.classeEtablissement;
            return accumulator.concat(mat);
          } else return accumulator;
        }, []);
        classe["enseignants"] = contents;
        return classe;
      });
      output["classes"] = myMap;

      return output;
    }
    input["classes"] = [];
    return input;
  }

  render() {
    const { classes, intl } = this.props;
    const { formatMessage } = this.props.intl;
    // console.log("avant");
    // console.log(row);
    var formated = this.formatData(this.state.row);
    // console.log("après");
    // console.log(formated);

    return (
      <React.Fragment>
        <AddElementEnseignant user={formated} annee={this.state.annee} etablissement={this.state.etablissement} onNewElement={this.onNewElement} onRef={ref => (this.addElementRef = ref)} />
        <Dialog
          open={this.state.openDelete}
          onClose={this.handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formatMessage({ id: "ItemEnseignant_1" })}</DialogTitle>
          <DialogContent>

            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemEnseignant_2" })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDelete} color="primary">
              {formatMessage({ id: "ItemEnseignant_3" })}
            </Button>
            <Button onClick={this.handleDeleteItem} color="primary" autoFocus>
              {formatMessage({ id: "ItemEnseignant_4" })}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openEdit}
          onClose={this.handleCloseEdit}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{formatMessage({ id: "ItemEnseignant_5" })}</DialogTitle>
          <DialogContent>
            {this.state.inProgressEdit
              ? <div className={classes.progress}><LinearProgress variant="query" /></div>
              : null
            }
            {!this.state.controlledEdit
              ? <div className={classes.error}>{formatMessage({ id: "ItemAnneeScolaire_Check" })}</div>
              : null
            }
            <div style={{ padding: '10px 0', fontSize: '13px' }}>{formatMessage({ id: "ItemEnseignant_6" })} <b>{this.state.classeEdit != null ? (this.state.classeEdit.name) : null}</b>
              &nbsp;&nbsp;{formatMessage({ id: "ItemEnseignant_7" })} <b>{this.state.enseignantEdit != null ? (this.state.enseignantEdit.matiereName != null && this.state.enseignantEdit.matiereName !== "" ? this.state.enseignantEdit.matiereName + '#' + this.state.enseignantEdit.matiere.name : '#' + this.state.enseignantEdit.matiere.name) : null}</b>
            </div>
            <div style={{ width: '100%' }}>
              <LdSelect
                handleValue={this.handleChangeMatiere}
                firstLoad isSearchable
                label={formatMessage({ id: "AddElementEnseignant_2" })}
                defaultValue={this.state.matiereEdit != null ?
                  {
                    label: this.state.matiereEdit.name,
                    value: this.state.matiereEdit.id,
                    id: this.state.matiereEdit.id
                  }
                  : null}
                baseUrl={appProperties.basepathServer + '/manager/etablissement/matieres?id=' + this.props.etablissement.id + '&limit=-1'}  {...deleteClassInProps(this.props)} />
            </div>
            <FormControl margin="dense" fullWidth>
              <InputLabel htmlFor="matiereName">{formatMessage({ id: "ItemEnseignant_18" })}</InputLabel>
              <Input
                name="matiereName"
                id="matiereName"
                type="text"
                value={this.state.matiereName}
                onChange={this.handleChangeInput("matiereName")}
                autoComplete="matiereName"
              />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="coefficient">{formatMessage({ id: "ItemEnseignant_8" })}</InputLabel>
              <Input
                name="coefficient"
                id="coefficient"
                required
                type="number"
                min="0"
                value={this.state.coefficient}
                onChange={this.handleChangeInput("coefficient")}
                autoComplete="coefficient"

                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </FormControl>
            <FormControl margin="dense" fullWidth>
              <InputLabel htmlFor="quotaHoraire">{formatMessage({ id: "ItemEnseignant_9" })}</InputLabel>
              <Input
                name="quotaHoraire"
                id="quotaHoraire"

                type="number"
                min="0"
                value={this.state.quotaHoraire}
                onChange={this.handleChangeInput("quotaHoraire")}
                autoComplete="quotaHoraire"

                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </FormControl>
            <div style={{ width: '100%' }}>
              <LdSelectGroupClasse
                label={formatMessage({ id: "ItemEnseignant_10" })}
                handleValue={this.handleChangeGroup}
                firstLoad isSearchable
                defaultValue={this.state.group != null ?
                  {
                    label: this.state.group.type ? this.state.group.type.name : this.state.group.ordre,
                    value: this.state.group.id,
                    id: this.state.group.id
                  }
                  : null}
                baseUrl={appProperties.basepathServer + "/manager/groupe-enseignement/list?limit=-1&classe=" + (this.state.classeEdit != null ? this.state.classeEdit.id : '')}  {...deleteClassInProps(this.props)} />
            </div>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="quotaHoraire">{formatMessage({ id: "ItemEnseignant_11" })}</InputLabel>
              <Input
                id="ordre"
                label={formatMessage({ id: "ItemEnseignant_12" })}
                value={this.state.ordre}
                placeholder="Ex: 1"
                onChange={this.handleChangeInput("ordre")}
                margin="normal"
                type="number"
                InputProps={{
                  inputProps: { min: 0 },

                }}
              />
            </FormControl>
            <LdSelectEtablissementEnseignant
              handleValue={this.handleValueSecondaryTeachers}
              defaultValue={this.state.secondaryTeachersEdit != null ? (this.state.secondaryTeachersEdit || []).map(option => {
                return Object.assign(
                  {
                    label: option ? option.label : "",
                    value: option ? option.id : "",
                    id: option ? option.id : ""
                  },
                  option
                );
              }) : []}

              firstLoad
              isSearchable
              isMulti
              label={formatMessage({ id: "ItemEnseignant_19" })}
              baseUrl={
                appProperties.basepathServer +
                "/manager/etablissement/enseignants?id=" +
                this.props.etablissement.id +
                "&limit=-1&name="
              }
              {...deleteClassInProps(this.props)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseEdit} color="primary">
              {formatMessage({ id: "AjouterTypeAlertView_13" })}
            </Button>
            <Button onClick={this.handleEditItem} color="primary" autoFocus>
              {formatMessage({ id: "AjouterTypeAlertView_15" })}
            </Button>
          </DialogActions>
        </Dialog>
        <div>
          <div style={{ padding: '10px 10px 10px 10px' }} className="layout horizontal">
            <div style={{ marginRight: '10px' }} >
              <ProgressiveImage src={this.state.row.photo} placeholder={defaultAvatar}>
                {(src, loading) => (
                  <Avatar alt="" src={src} className={classes.bigAvatar} />
                )}
              </ProgressiveImage>
            </div>
            <div className="flex layout vertical">
              <div style={{ fontSize: "15px", fontWeight: "600" }}>
                <a
                  className="no-underline capitalize"
                  title={this.state.row.name}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={userProfileUrl(this.state.row)}
                >
                  {this.state.row.name}
                </a>
                <span style={{ cursor: 'pointer', color: 'var(--app-primary-color)', marginLeft: '10px', fontSize: '11px', fontWeight: '600' }}
                  title={formatMessage({ id: "ItemEnseignant_13" })}
                  className="no-underline" rel="noopener noreferrer"
                  onClick={this.doAddElement}>{formatMessage({ id: "ItemEnseignant_14" })}</span>
              </div>
              <Grid>
                {formated.classes.map((classe, index1) => (
                  <div key={index1}>
                    <div style={{ display: 'flex', fontSize: "13px", fontWeight: "600", color: 'var(--app-primary-color)' }} className={classes.name}>
                      {classe.name}
                    </div>
                    <div className="layout vertical" style={{ paddingLeft: '10px' }}>
                      {classe.enseignants.map((enseignant, index2) => (
                        <span className="layout horizontal center" key={index2} style={{ padding: '5px 0 0 5px' }}>
                          <Chip
                            data-id={enseignant.id}
                            component="span"
                            key={enseignant.id}
                            title={formatMessage({ id: "ItemEnseignant_15" }) + intl.formatDate(enseignant.created)}
                            label={<div> <span title={'#' + enseignant.matiere.name} style={{ fontWeight: '600' }}>{enseignant.matiereName != null && enseignant.matiereName !== "" ? enseignant.matiereName : '#' + enseignant.matiere.name}</span> <span> &nbsp;&nbsp;Quota.H: <b>{enseignant.quotaHoraire}</b>  &nbsp; &nbsp;Coéf: <b>{enseignant.coefficient}</b> &nbsp; &nbsp; Groupe: <b>{enseignant.groupeEnseignement != null ? (enseignant.groupeEnseignement.type != null ? (enseignant.groupeEnseignement.ordre + ". " + enseignant.groupeEnseignement.type.name) : '') : '--RAS--'}</b>  &nbsp; &nbsp; Ordre: <b>{enseignant.orderingGroupe}</b></span>
                              <IconButton
                                title={formatMessage({ id: "ItemEnseignant_16" })}
                                style={{ MozTransform: 'scale(0.7)', zoom: '70%' }}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.handleShowEditInfo(classe, index1, enseignant, index2)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton></div>}
                            clickable
                            className={(enseignant.secondaryTeachers && enseignant.secondaryTeachers.length > 0) ? classes.chip2 : classes.chip}
                            deleteIcon={<span className="layout horizontal center" title={formatMessage({ id: "ItemRole_del" })} ><CancelIcon /></span>}
                            onDelete={() => this.handleClickOpenDelete(enseignant)}
                            variant="outlined"
                          />
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
              </Grid>
            </div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnackEdit}
          autoHideDuration={6000}
          onClose={this.handleSnackBarEdit}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{formatMessage({ id: "ItemEnseignant_17" })}</span>}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarEdit}
            >
              OK!
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarEdit}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </React.Fragment>
    );
  }
}
ItemEnseignant.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
export default withStyles(styles, { withTheme: true })(
  injectIntl(ItemEnseignant)
);
