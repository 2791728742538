import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-date-picker";
import { FormattedDate, injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { appProperties } from "../../../components/LdUtils";
import ListTrimestreView from "../trimestre/ListTrimestreView";

import {
  isObjectValueEquals,
  deleteClassInProps
} from "../../../components/LdUtils";
import { withSnackbar } from "notistack";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: "10px"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonSimple: {
    textTransform: "none"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none"
  },
  date: {
    fontSize: "12px",
    fontWeight: "400"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  }
});

// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
  </span>
));

class ItemAnneeScolaire extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,
      year: this.props.row.year,
      code: this.props.row.code,
      minDate:
        this.props.row.dateFrom == null || this.props.row.dateFrom.trim() === ""
          ? new Date()
          : new Date(this.props.row.dateFrom),
      maxDate:
        this.props.row.dateTo == null || this.props.row.dateTo.trim() === ""
          ? new Date()
          : new Date(this.props.row.dateTo),
      controlled: true,
      showTrimestre: false,
      etablissement: this.props.etablissement,

      categorieEtablissements: this.props.categorieEtablissements || [],
      categorieEtablissement: null,
      openConfirm: false,
      progressDelete: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSetCurrent = this.handleSetCurrent.bind(this);
    this.handleShowTrimestre = this.handleShowTrimestre.bind(this);
    this.handleChangeCategorieEtablissement = this.handleChangeCategorieEtablissement.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);

  }

  handleDelete = () => {
    this.setState({ openConfirm: true });
  };

  handleClose = () => {
    this.setState({ openConfirm: false });
  };
  handleChangeCategorieEtablissement = (event, value) => {
    this.setState({ categorieEtablissement: value }, (state) => {

    });
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          year: self.props.row.year || "",
          code: self.props.row.code || "",
          minDate:
            self.props.row.dateFrom == null ||
              self.props.row.dateFrom.trim() === ""
              ? new Date()
              : new Date(self.props.row.dateFrom),
          maxDate:
            self.props.row.dateTo == null || self.props.row.dateTo.trim() === ""
              ? new Date()
              : new Date(self.props.row.dateTo)
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    } if (!isObjectValueEquals(this.props.categorieEtablissements, prevProps.categorieEtablissements)) {
      this.setState({ categorieEtablissement: this.props.categorieEtablissements != null && this.props.categorieEtablissements.length > 0 ? this.props.categorieEtablissements[0] : null }, () => { });
    }
  }
  handleShowTrimestre = () => {
    this.setState({ showTrimestre: !this.state.showTrimestre });
  };
  handleEdit = () => {
    this.setState({ modeEdit: true });
  };
  reset = () => {
    this.setState({ modeEdit: false, controlled: true });
  };
  handleStartDateChange(date) {
    console.log("start Date");
    console.log(date);
    this.setState({
      minDate: date
    });
  }

  handleEndDateChange(date) {
    console.log("end Date");
    console.log(date);
    this.setState({
      maxDate: date
    });
  }
  handleChangeYear(event) {
    this.setState({ year: event.target.value });
  }
  handleChangeCode(event) {
    this.setState({ code: event.target.value });
  }
  edit = () => {
    if (
      this.state.year == null ||
      this.state.year.trim() === "" ||
      this.state.minDate == null ||
      this.state.maxDate == null
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("year", this.state.year);
    formData.append(
      "date_from",
      moment(this.state.minDate).format("YYYY-MM-DD")
    );
    formData.append("date_to", moment(this.state.maxDate).format("YYYY-MM-DD"));
    formData.append("code", this.state.code);

    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/rest-etablissement/edit-annee-scolaire?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.reset();
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItemAnnee("update", datas);

          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemAnneeScolaire_5" }), {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        } else {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemAnneeScolaire_6" }), {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        }
        this.setState({ inProgress: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleClickDelete = () => {
    var self = this;
    this.setState({ progressDelete: true });
    fetch(
      appProperties.basepathServer +
      "/rest-etablissement/delete-annee-scolaire?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ progressDelete: false, openConfirm: false });
        if (data.result_code === 1) {
          self.props.onUpdateItemAnnee("delete", {
            position: self.state.position
          });

        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ progressDelete: false });
        alert("There was an error added.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/rest-etablissement/set-status-annee-scolaire?status=1&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItemAnnee("enabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/rest-etablissement/set-status-annee-scolaire?status=0&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItemAnnee("disabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleSetCurrent = () => {
    //TODO: recharger l'object  etablissement en définissant le nouveau currentAnnee
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/rest-etablissement/set-current-annee?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItemAnnee("current-annee", { id: data.id });
          //self.handleClose();
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemAnneeScolaire_5" }), {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        } else {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemAnneeScolaire_8" }), {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };


  render() {
    const { classes, row, isCurrent } = this.props;
    const { formatMessage } = this.props.intl;
    const { categorieEtablissements } = this.props;
    return (
      <React.Fragment>

        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formatMessage({ id: "ItemAnneeScolaire_3" }, { annee: this.props.row != null ? this.props.row.year : '' })}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemAnneeScolaire_4" })}
            </DialogContentText>
            <div style={{ padding: '10px 5px' }}>
              {this.state.progressDelete
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none" }} onClick={this.handleClose} color="primary">
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button style={{ textTransform: "none" }} onClick={this.handleClickDelete} color="primary" autoFocus>
              {formatMessage({ id: "ItemEleve_3" })}
            </Button>
          </DialogActions>
        </Dialog>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>
              {this.state.modeEdit ? (
                <main className={classes.main}>
                  {this.state.inProgress ? (
                    <div className={classes.progress}>
                      <LinearProgress variant="query" />
                    </div>
                  ) : null}
                  {!this.state.controlled ? (
                    <div className={classes.error}>
                      {formatMessage({ id: "ItemAnneeScolaire_Check" })}
                    </div>
                  ) : null}
                  <div className="layout horizontal">
                    <div style={{ paddingRight: "10px" }} className="flex">
                      <div class="layout horizontal center justified">
                        <TextField
                          id="code"
                          label={formatMessage({
                            id: "ItemAnneeScolaire_Code"
                          })}
                          style={{ width: "48%", marginTop: "0 !important" }}
                          value={this.state.code}
                          placeholder="ANN2019"
                          onChange={this.handleChangeCode}
                          margin="normal"
                        />
                        <TextField
                          id="year"
                          label={formatMessage({
                            id: "ItemAnneeScolaire_Titre"
                          })}
                          style={{ width: "48%", marginTop: "0 !important" }}
                          value={this.state.year}
                          placeholder="Ex: 2018-2019"
                          onChange={this.handleChangeYear}
                          margin="normal"
                        />
                      </div>
                      <Grid
                        container
                        className={classes.grid}
                        justify="flex-start"
                        alignItems="center"
                      >
                        <span className={classes.label}>
                          {formatMessage({ id: "ItemAnneeScolaire_Deb" })}
                        </span>
                        <DatePicker
                          // maxDate={this.state.maxDate}
                          locale="fr-FR"
                          className={classes.datePicker}
                          value={this.state.minDate}
                          onChange={this.handleStartDateChange}
                        />

                        <span
                          style={{ marginLeft: "20px" }}
                          className={classes.label}
                        >
                          {formatMessage({ id: "ItemAnneeScolaire_Fin" })}
                        </span>
                        <DatePicker
                          label={formatMessage({ id: "ItemAnneeScolaire_Fin" })}
                          className={classes.datePicker}
                          locale="fr-FR"
                          value={this.state.maxDate}
                          minDate={this.state.minDate}
                          onChange={this.handleEndDateChange}
                        />
                      </Grid>
                    </div>
                    <div className="layout vertical">
                      <span className="flex"> </span>
                      <div className="layout horizontal center wrap">
                        <Button
                          onClick={this.edit}
                          variant="contained"
                          size="medium"
                          style={{ margin: "5px" }}
                          className={classes.buttonPrincipale}
                        >
                          {formatMessage({ id: "ItemAnneeScolaire_Up" })}
                        </Button>
                        <Button
                          onClick={this.reset}
                          variant="contained"
                          size="medium"
                          style={{ margin: "5px" }}
                          className={classes.buttonGreyWhite}
                        >
                          {formatMessage({ id: "ItemAnneeScolaire_Ccl" })}
                        </Button>
                      </div>
                    </div>
                  </div>
                </main>
              ) : (
                  <div>
                    <div className="layout horizontal center">
                      <FormattedMessage
                        id="ItemAnneeScolaire_Descrip"
                        values={{
                          titre: <span className={classes.name}>{row.year}</span>,
                          code: (
                            <span className={classes.name}>
                              {row.code && row.code !== "" ? row.code : "#######"}
                            </span>
                          )
                        }}
                      />
                      {/*Titre: &nbsp; 
                    &nbsp;&nbsp;- Code:&nbsp; */}

                      {isCurrent ? (
                        <span
                          style={{
                            color: "green",
                            fontSize: "15px",
                            paddingLeft: "5px"
                          }}
                        >
                          {formatMessage({ id: "ItemAnneeScolaire_isCurrent" })}
                        </span>
                      ) : null}
                      {/* <a
                        href="/?locale=en-US"
                        title={formatMessage({ id: "app.intro"})}>
                        en-US {formatMessage(messages.enUSDescription)} 
                    </a>*/}
                    </div>
                    <div className={classes.date}>
                      <FormattedMessage
                        id="ItemAnneeScolaire_Period"
                        values={{
                          debut: <PostDate date={row.dateFrom} />,
                          fin: <PostDate date={row.dateTo} />
                        }}
                      />
                    </div>
                    <div className={classes.date}>
                      {!this.state.showTrimestre ? (
                        <Link onClick={this.handleShowTrimestre} to="#">
                          {formatMessage({ id: "ItemAnneeScolaire_Show" })}
                        </Link>
                      ) : (
                          <Link onClick={this.handleShowTrimestre} to="#">
                            {formatMessage({ id: "ItemAnneeScolaire_Hide" })}
                          </Link>
                        )}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className={classes.actions}>
            {!isCurrent ? (
              <Button
                onClick={this.handleSetCurrent}
                size="medium"
                color="primary"
                title={formatMessage({ id: "ItemAnneeScolaire_CurrentTitle" })}
                className={classes.buttonSimple}
              >
                {formatMessage({ id: "ItemAnneeScolaire_Current" })}
              </Button>
            ) : null}
            {/* <IconButton
                component = {Link}
                to={"/page/"+this.state.etablissement.code+"/annee/"+this.props.row.id}
                title="Manager cette année scolaire"
                aria-label="Manager"
                classes={{root: {color:'var(--app-primary-color)'}}}
                onClick={this.handleEdit}>
                <VisibilityIcon fontSize="small" />
              </IconButton> */}
            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
              aria-label={formatMessage({ id: "ItemAnneeScolaire_edit" })}
              onClick={this.handleEdit}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_del" })}
              aria-label={formatMessage({ id: "ItemAnneeScolaire_del" })}
              onClick={this.handleDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
            {!!this.props.row.status ? (
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                classes={{ root: classes.disable }}
                aria-label={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            ) : (
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                  classes={{ root: classes.enable }}
                  aria-label={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                  onClick={this.handleEnabled}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
          </div>
        </div>
        {this.state.showTrimestre ? (
          <div>
            <div style={{ paddingLeft: '45px' }}>
              <AppBar elevation={0} position="static" color="default">
                <Tabs
                  value={this.state.categorieEtablissement}
                  onChange={this.handleChangeCategorieEtablissement}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {/* <Tab label={formatMessage({ id: "ItemAnneeScolaire_1" })} value={null} /> */}
                  {categorieEtablissements.map((row, index) => (
                    <Tab key={index} title={formatMessage({ id: 'ItemAnneeScolaire_2' })} label={row.name} value={row} />
                  ))}
                </Tabs>
              </AppBar>
            </div>
            {/* {this.state.categorieEtablissement == null &&
              <ListTrimestreView
                categorieEtablissement={null}
                anneeScolaire={row}
                {...deleteClassInProps(this.props)}
              />} */}
            {categorieEtablissements.map((categorie, index) => (
              <div key={categorie.id}>
                {
                  this.state.categorieEtablissement != null && this.state.categorieEtablissement.id === categorie.id && <ListTrimestreView
                    categorieEtablissement={categorie}
                    categorieEtablissements={categorieEtablissements}
                    anneeScolaire={row}
                    {...deleteClassInProps(this.props)}
                  />
                }
              </div>
            ))}

          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
ItemAnneeScolaire.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà.
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire))
);
