import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, FormattedTime, injectIntl, intlShape } from 'react-intl';
import ProgressiveImage from 'react-progressive-image';
import ShowMore from 'react-show-more';
import { appProperties, defaultAvatar, defaultClassImage, isObjectValueEquals, userProfileUrl } from "../../../components/LdUtils";



const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',
    paddingTop: '10px',
    borderBottom: '1px solid #EAEAEA',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',
  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "52px !important",
    height: "52px !important",
    borderRadius: '0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  }
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
       <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));

class ItemAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      etablissement: this.props.etablissement,
      controlled: true,
      row: this.props.row
    };

    this.doSendSMS = this.doSendSMS.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
  }
  componentDidUpdate(prevProps) {
    //var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/alert-sms/set-status?id=" +
      this.props.row.id + '&status=1',
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", { position: self.state.position, row: this.props.row });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error enabled.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/alert-sms/set-status?id=" +
      this.props.row.id + '&status=0',
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", { position: self.state.position, row: this.props.row });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error disabled.");
      });
  };



  doSendSMS = () => {
    var self = this;
    this.setState({ inProgressSend: true });
    fetch(
      appProperties.basepathServer +
      "/manager/alert-sms/send?id=" +
      this.props.row.id + '&status=0',
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          var row = this.props.row;
          if (row != null) row.countSent += data.sent;
          self.props.onUpdateItem("update", { position: self.state.position, row: row });

          self.props.enqueueSnackbar("Message envoyés (" + data.sent + ")", { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar("Alerte introuvable", { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar("Vous n'avez pas d'autorisations nécessaire", { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === 0) {
          self.props.enqueueSnackbar("Aucune alerte à envoyer", { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }
        this.setState({ inProgressSend: false });

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressSend: false });
        alert("There was an error added.");
      });
  }


  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;
    var message = "";
    var user = null
    var elevePhoto = "";

    if (row.type && row.type != null) {
      if (row.type.type === 0) {
        message = formatMessage({ id: "ItemAlert_msg1" });
        if (row.subject && row.subject != null && row.subject.sequence && row.subject.sequence != null) message += row.subject.sequence.name;
        if (row.subject && row.subject != null && row.subject.enseignant && row.subject.enseignant != null) {
          message += (' en ' + (row.subject.enseignant.matiereName || '#' + row.subject.enseignant.matiere.name));
          message += (' Classe: ' + row.subject.enseignant.classeEtablissement.name);
        }
      } else if (row.type.type === 1) {
        message = formatMessage({ id: "ItemAlert_msg2" });
        if (row.subject && row.subject != null && row.subject.sequence && row.subject.sequence != null) message += row.subject.sequence.name;
        if (row.subject && row.subject != null && row.subject.enseignant && row.subject.enseignant != null) {
          message += (' en ' + (row.subject.enseignant.matiereName || '#' + row.subject.enseignant.matiere.name));
          message += (' Classe: ' + row.subject.enseignant.classeEtablissement.name);
        }
      } else if (row.type.type === 2) {

        message = formatMessage({ id: "ItemAlert_msg3" });
        if (row.subject && row.subject != null && row.subject.type && row.subject.type != null) message += row.subject.type.name;
        message += formatMessage({ id: "ItemAlert_msg4" });
        if (row.subject && row.subject != null && row.subject.eleve && row.subject.eleve != null) {
          user = row.subject.eleve.user;
          elevePhoto = row.subject.eleve.pathPhoto;
          message += (' ' + (row.subject.eleve.fullname || '@' + row.subject.eleve.user.name) + '(' + row.subject.eleve.matricule + ') ');
          message += (formatMessage({ id: "ItemAlert_msg5" }) + ' ' + row.subject.eleve.classeEtablissement.name);
        }
      } else if (row.type.type === 3) {
        message = formatMessage({ id: "ItemAlert_msg6" });

      } else if (row.type.type === 4) {

        message = formatMessage({ id: "ItemAlert_msg7" });
        if (row.subject && row.subject != null && row.subject.type && row.subject.type != null) message += row.subject.type.name;
        message += formatMessage({ id: "ItemAlert_msg8" });
        if (row.subject && row.subject != null) message += (row.subject.amount + " F CFA ");
        message += formatMessage({ id: "ItemAlert_msg9" });
        if (row.subject && row.subject != null && row.subject.eleve && row.subject.eleve != null) {
          user = row.subject.eleve.user;
          elevePhoto = row.subject.eleve.pathPhoto;
          message += (' ' + (row.subject.eleve.fullname || '@' + row.subject.eleve.user.name) + '(' + row.subject.eleve.matricule + ') ');
          message += (formatMessage({ id: "ItemAlert_msg10" }) + ' ' + row.subject.eleve.classeEtablissement.name);
        }
      }

    }
    return (
      <React.Fragment>



        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>


              <div>
                <Grid container className={classes.grid} wrap="nowrap" justify="flex-start" >
                  <div>
                    {user != null && (

                      <ProgressiveImage src={elevePhoto} placeholder={defaultAvatar}>
                        {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                      </ProgressiveImage>
                    )}
                    {user == null && (
                      <ProgressiveImage placeholder={defaultClassImage}>
                        {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                      </ProgressiveImage>
                    )}
                  </div>
                  <div style={{ paddingLeft: "15px" }}>

                    <div className="layout horizontal">
                      <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>Objet: </span>
                      <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>
                        <ShowMore
                          lines={1}
                          more={formatMessage({ id: "ItemAlert_msg11" })}
                          less={formatMessage({ id: "ItemAlert_msg12" })}
                          anchorClass=''>
                          {message}
                        </ShowMore></span>
                    </div>
                    <div style={{ fontWeight: "400" }} className={classes.date}>{formatMessage({ id: "ItemAlert_msg13" })}
                      <PostDate date={row.created} />
                            {row.creator != null && (<span>
                        &nbsp;{formatMessage({ id: "ItemAlert_msg14" })}
                        <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.creator)}>
                          {row.creator.name}
                        </a>
                      </span>)}
                    </div>
                    <div style={{ fontSize: '12px', marginTop: '5px' }} class="layout horizontal">
                      <div style={{ marginRight: '15px' }}>
                        {/* <span style={{padding:'3px',backgroundColor:'var(--app-primary-color)',border:'1px solid #EAEAEA',color:"white"}}>Terminée</span> */}
                        {row.finished && <span style={{ padding: '3px', backgroundColor: 'var(--paper-green-500)', border: '1px solid #EAEAEA', color: "white" }}>{formatMessage({ id: "ItemAlert_1" })}</span>}
                        {!row.finished && <span style={{ padding: '3px', backgroundColor: '#EAEAEA', border: '1px solid #EAEAEA' }}>{formatMessage({ id: "ItemAlert_2" })}</span>}
                      </div>
                      {/* <div style={{marginRight:'15px'}}>
                                <span style={{padding:'3px',backgroundColor:'var(--paper-green-500)',border:'1px solid #EAEAEA',color:"white"}}>Envoyée?</span>
                                {row.sending &&<span  style={{padding:'3px',backgroundColor:'var(--paper-green-500)',border:'1px solid #EAEAEA',color:"white"}}>Oui</span> }
                                {!row.sending &&<span  style={{padding:'3px',backgroundColor:'#EAEAEA',border:'1px solid #EAEAEA'}}>Non</span> }
                              </div> */}
                      <div style={{ marginRight: '15px' }}>
                        {/* <span style={{padding:'3px',backgroundColor:'var(--paper-red-500)',border:'1px solid #EAEAEA',color:"white"}}>Echouée?</span> */}
                        {row.failed && <span style={{ padding: '3px', backgroundColor: 'var(--paper-red-500)', border: '1px solid #EAEAEA', color: "white" }}>{formatMessage({ id: "ItemAlert_3" })}</span>}
                        {/* {!row.failed &&<span  style={{padding:'3px',backgroundColor:'#EAEAEA',border:'1px solid #EAEAEA'}}>Non</span> } */}
                      </div>
                      <div style={{ marginRight: '15px' }}>
                        <span style={{ padding: '3px', fontWeight: '600' }}>{formatMessage({ id: "ItemAlert_4" })}</span>
                        {/* {row.countSent<=0 &&<span  style={{padding:'3px',backgroundColor:'#EAEAEA',border:'1px solid #EAEAEA'}}>Aucun</span> } */}
                        <span style={{ padding: '3px', color: 'var(--app-primary-color)', fontWeight: '600', fontSize: '13px' }}>{row.countSent}</span>
                      </div>
                      <div style={{ marginRight: '15px' }}>
                        <div style={{ fontWeight: "600" }} className={classes.date}>{formatMessage({ id: "ItemAlert_5" })} &nbsp;<PostDate date={row.dateTime} /></div>
                      </div>



                      <div style={{ marginRight: '15px' }}>
                        {/* <span style={{padding:'3px',backgroundColor:'var(--paper-red-500)',border:'1px solid #EAEAEA',color:"white"}}>Echouée?</span> */}
                        {row.auto && <span title={formatMessage({ id: "ItemAlert_7" })} style={{ padding: '3px', backgroundColor: 'var(--paper-blue-500)', border: '1px solid #EAEAEA', color: "white" }}>{formatMessage({ id: "ItemAlert_6" })}</span>}

                        {/* {!row.auto &&<span title="Mode d'envoi programmé"  style={{padding:'3px',backgroundColor:'var(--paper-purple-500)',border:'1px solid #EAEAEA',color:"white"}}>Non Auto</span> } */}
                        {/* {!row.failed &&<span  style={{padding:'3px',backgroundColor:'#EAEAEA',border:'1px solid #EAEAEA'}}>Non</span> } */}
                      </div>
                    </div>


                  </div>

                </Grid>

              </div>

            </div>
          </div>
          <div className={classes.actions}>
            <Button
              onClick={this.doSendSMS}
              variant="contained"
              size="medium"
              disabled={this.state.inProgressSend}
              className={classes.buttonPrincipale}>

              {this.state.inProgressSend ? formatMessage({ id: "ItemAlert_8" }) : (row.countSent > 0 ? formatMessage({ id: "ItemAlert_9" }) : formatMessage({ id: "ItemAlert_10" }))}
            </Button>
            {!!this.props.row.status ?
              <IconButton
                title={formatMessage({ id: "ItemAlert_11" })}
                classes={{ root: classes.disable }}
                aria-label={formatMessage({ id: "ItemAlert_11" })}
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton> :
              <IconButton
                title={formatMessage({ id: "ItemAlert_12" })}
                classes={{ root: classes.enable }}

                aria-label={formatMessage({ id: "ItemAlert_12" })}
                onClick={this.handleEnabled}
              >
                <ClearIcon fontSize="small" />
              </IconButton>}
          </div>
        </div>

      </React.Fragment>
    );
  }
}
ItemAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemAlert)));
