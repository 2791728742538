import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { appProperties, getFistLetter } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    padding: "0 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none",
    padding: "0 10px 0 10px"
  },
  email: {
    fontSize: "13px",
    fontWeight: "400",
    padding: "0 10px 0 10px"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    fontSize: "x-small",
    fontWeight: "600"
  }
});

class ItemMatiere extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      // etablissements: null,
      options: [],
      selectedOption: null,
      result: null,
      redirect: false,
      // ids: "",
      datas: [],
      dialogOpened: false,
      inProgress: false,
      color: this.props.color,
      position: this.props.position
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/etablissement/remove-matiere?id=" +
      this.props.row.id +
      "&etablissement=" +
      this.props.etablissementId,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error deleted.");
      });
  };

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>
              <div>
                <Avatar
                  style={{ backgroundColor: this.state.color }}
                  className={classes.purpleAvatar}
                >
                  {getFistLetter(row.name.trim(), 4, true)}
                </Avatar>
              </div>
              <div>
                <Typography className={classes.name}>{row.name}</Typography>
                <div style={{ fontSize: "10px", marginLeft: "10px" }} className="layou thorizontal center">
                  <span>{row.cycle != null ? row.cycle.name : ""}</span>
                  <span>&nbsp;&nbsp;-&nbsp;&nbsp; <i>{row.systemeEnseignement != null ? row.systemeEnseignement.name : ""}</i></span>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.actions}>
            <IconButton
              title={formatMessage({ id: "ItemRole_del" })}
              aria-label="Delete"
              onClick={this.handleDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ItemMatiere.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(injectIntl(ItemMatiere));
