import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { appProperties, fireWithObject, deleteClassInProps } from "../../../components/LdUtils";
// import { deleteClassInProps } from "../../../components/LdUtils";
import LdSelect from "../../../components/LdSelect";
import LdSelectStatic from "../../../components/LdSelectStatic";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  root: {
    width: '100%'
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "15px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },
  label: {
    padding: "5px"
  },

  datePicker: {
    height: '35px',
    '& .react-datetime-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
});

class AjouterDerogationView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    const minDate = null;
    const maxDate = null;

    this.state = {
      signature: null,
      documentType: null,
      minDate: minDate,
      maxDate: maxDate,

      etablissementId: this.props.etablissementId,

      inProgress: false,
      controlled: true,
      errors: {},

    };
    this.add = this.add.bind(this);
    this.handleValueDocumentType = this.handleValueDocumentType.bind(this);
    this.handleChangeSignature = this.handleChangeSignature.bind(this);

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);

  }
  handleChangeSignature(signature) {
    this.setState({ signature: signature });
  }
  handleValueDocumentType(documentType) {
    this.setState({ documentType: documentType });
  }

  handleStartDateChange(date) {
    //var self = this;
    this.setState({
      minDate: date,
    }, (state) => {
      // self.setCurrentPage(1);
    });
  }

  handleEndDateChange(date) {
    // var self = this;
    this.setState({
      maxDate: date,
    }, (state) => {
      // self.setCurrentPage(1);
    });
  };
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };


  add() {
    var errors = {};
    this.setState({ errors: errors });

    if (this.state.documentType == null || this.state.documentType === "") errors["documentType"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.signature == null || this.state.signature === "") errors["signature"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.minDate == null || this.state.minDate === "") errors["minDate"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.maxDate == null || this.state.maxDate === "") errors["maxDate"] = this.props.intl.formatMessage({ id: "InputError_1" });


    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    var formData = new FormData(); // Currently empty

    formData.append("etablissement", this.props.etablissementId);
    formData.append("document_type", this.state.documentType.value);
    formData.append("signature_id", this.state.signature.value);
    formData.append("from", moment(this.state.minDate).format('YYYY-MM-DD HH:mm:ss'));
    formData.append("to", moment(this.state.maxDate).format('YYYY-MM-DD HH:mm:ss'));

    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/manager/signature/add-derogation",
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, "new-derogation", { value: data.row });

          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterDerogationSignatureView_9" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSignatureView_8" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterDerogationSignatureView_10" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterDerogationSignatureView_11" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -4) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterDerogationSignatureView_12" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -5) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterDerogationSignatureView_13" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>

        <div>


          <Paper square elevation={0} className={classes.paper}>
            <div className={classes.pageTitle} id="form-dialog-title">
              {formatMessage({ id: "AjouterDerogationSignatureView_1" })}
            </div>
            {this.state.inProgress ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {!this.state.controlled ? (
              <div className={classes.error}>
                {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
              </div>
            ) : null}
            <div className={classes.pageSubtitle}>
              {formatMessage({ id: "AjouterDerogationSignatureView_2" })}
            </div>
            <CssBaseline />

            {/* {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null} */}

            <div className={classes.root}>
              <FormControl margin="dense" required fullWidth>

                <div>
                  <LdSelectStatic
                    options={[
                      {
                        value: "carte_scolaire",
                        label: formatMessage({
                          id: "AjouterDerogationSignatureView_3"
                        })
                      },
                      {
                        value: "bulletin",
                        label: formatMessage({
                          id: "AjouterDerogationSignatureView_4"
                        })
                      }
                    ]}
                    label={formatMessage({
                      id: "AjouterDerogationSignatureView_5"
                    })}
                    handleValue={this.handleValueDocumentType}
                    isSearchable
                    {...deleteClassInProps(this.props)}
                  />
                </div>
                <FormHelperText>{this.state.errors.documentType ? <span className="error_field">{this.state.errors.documentType}</span> : ""}</FormHelperText>

              </FormControl>



              <FormControl margin="dense" required fullWidth>

                <LdSelect
                  label={formatMessage({ id: "AjouterDerogationSignatureView_6" })}
                  handleValue={this.handleChangeSignature}
                  firstLoad isSearchable
                  baseUrl={appProperties.basepathServer + "/manager/signature/list?limit=0&only_published=1&etablissement=" + this.props.etablissement.id}  {...deleteClassInProps(this.props)} />

                <FormHelperText>{this.state.errors.responsable ? <span className="error_field">{this.state.errors.responsable}</span> : ""}</FormHelperText>
              </FormControl>
              <div className="layout horizontal center">

                <FormControl margin="dense" required>

                  <div>
                    <span style={{ paddingRight: '5px' }} className={classes.label}>
                      {formatMessage({ id: 'AjouterDerogationSignatureView_7' })}
                    </span>
                    <DateTimePicker
                      // maxDate={this.state.maxDate}
                      locale='fr-FR'
                      className={classes.datePicker}
                      value={this.state.minDate}
                      onChange={this.handleStartDateChange}
                    />

                  </div>
                  <FormHelperText>{this.state.errors.minDate ? <span className="error_field">{this.state.errors.minDate}</span> : ""}</FormHelperText>
                </FormControl>
                <FormControl margin="dense" required >

                  <div>
                    <span style={{ paddingRight: '5px' }} className={classes.label}>
                      {formatMessage({ id: 'AjouterDerogationSignatureView_8' })}
                    </span>
                    <DateTimePicker
                      minDate={this.state.minDate}
                      locale='fr-FR'
                      className={classes.datePicker}
                      value={this.state.maxDate}
                      onChange={this.handleEndDateChange}
                    />

                  </div>
                  <FormHelperText>{this.state.errors.maxDate ? <span className="error_field">{this.state.errors.maxDate}</span> : ""}</FormHelperText>
                </FormControl>

              </div>

            </div>
            <div className="layout horizontal center end-justified">
              <Button
                onClick={this.handleClose}
                variant="contained"
                size="medium"
                className={classes.buttonGreyWhite}
              >
                {formatMessage({ id: "AjouterTypeAlertView_13" })}
              </Button>

              <Button
                onClick={this.add}
                variant="contained"
                size="medium"
                disabled={this.state.inProgress}
                style={{ marginLeft: "20px" }}
                className={classes.buttonPrincipale}
              >
                {formatMessage({ id: "AjouterTypeAlertView_15" })}
              </Button>
            </div>
          </Paper>

        </div>
      </main>
    );
  }
}
AjouterDerogationView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AjouterDerogationView))
);
