import React, { Component } from "react";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import LinearProgress from '@material-ui/core/LinearProgress';
import { userProfileUrl, defaultAvatar, isObjectValueEquals } from '../../../components/LdUtils';
import { injectIntl } from "react-intl";
import { withSnackbar } from "notistack";
//import ChipInput from 'material-ui-chip-input'

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

import FormHelperText from "@material-ui/core/FormHelperText";
import SendIcon from "@material-ui/icons/Send";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";

import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: 'white',

  },
  root: {
    width: "100%",
    marginTop: '30px',

  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },


  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: '0px'
  },

  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },
  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: "12px",
    fontWeight: "500",
    transform: "translate(14px, -6px) scale(1)"
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: "7.5px 23px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */
});

class AjouterSmsNoteMoyenneView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      numeros: [],

      message: '',
      type: 2,
      eleves: [],
      eleve: "---",
      inProgress: false,
      progressEleve: false,
      openDialogEleve: false,
      controlled: true,
      errors: {},
      smsCounter: { smsLength: "", smsRemaining: "", smsPartCount: "", smsPerMessage: "", smsEncoding: "", smsType: '' },


      classes: [],
      classe: "---", //No change to null. it is used to fix bug in select. 
      enseignant: "---",
      sequences: [],
      sequence: "---",
      trimestres: [],
      trimestre: "---",
      categories: [],
      categorie: "---",
      mode: 2, //1: envoi des notes, 2: envoi des moyennes
      defaultMessageNote: this.props.intl.formatMessage({ id: 'AjouterSmsNoteMoyenneView_5' }),
      defaultMessageMoyenne: this.props.intl.formatMessage({ id: 'AjouterSmsNoteMoyenneView_6' })

    };
    this.add = this.add.bind(this);
    this.handleChangeEleve = this.handleChangeEleve.bind(this);
    this.handleCloseDialogEleve = this.handleCloseDialogEleve.bind(this);
    this.handleOpenDialogEleve = this.handleOpenDialogEleve.bind(this);


    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeEnseignant = this.handleChangeEnseignant.bind(this);
    this.handleChangeSequence = this.handleChangeSequence.bind(this);
    this.handleChangeTrimestre = this.handleChangeTrimestre.bind(this);
    this.handleReset = this.handleReset.bind(this);

  }

  handleReset = () => {
    var self = this;
    this.setState({
      classes: [],
      classe: "---",
      enseignant: "---",
      sequences: [],
      sequence: "---",

      trimestres: [],
      trimestre: "---",
      eleves: [],
      eleve: "---",
    }, () => {
      self.listEnseignants();
      self.listTrimestre();
    });
  };

  componentDidUpdate(prevProps) {
    var self = this;

    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({
        annee: this.props.annee,
      }, () => {
        self.handleReset();
      });
    }

  }
  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value) }, (state) => {
      self.handleReset();
      // self.setCurrentPage(1);
    });
  };

  handleChangeClass = event => {
    //let self = this;
    this.setState({ classe: (event.target.value), enseignant: "---" }, (state) => {
      //self.setCurrentPage(1);
    });
  };
  handleChangeEnseignant = event => {
    //let self = this;
    var mode = event.target.value != null && event.target.value !== "" && event.target.value !== "---" ? 1 : 2;
    this.setState({ enseignant: (event.target.value), mode: mode }, (state) => {
      //self.setCurrentPage(1);

    });
  };
  handleChangeSequence = event => {
    //let self = this;
    this.setState({ sequence: (event.target.value) }, (state) => {
      //self.setCurrentPage(1);
    });
  };
  handleChangeTrimestre = event => {
    let self = this;
    this.setState({ trimestre: (event.target.value), sequences: [], sequence: "---" }, (state) => {
      self.listSequences();
    });
  };

  handleOpenDialogEleve = () => {
    //this.props.onClose(this.props.selectedValue);
    if (this.state.eleves == null || this.state.eleves.length === 0) return;
    this.setState({ openDialogEleve: true });
  };

  handleCloseDialogEleve = () => {
    //this.props.onClose(this.props.selectedValue);
    this.setState({ openDialogEleve: false });
  };

  handleCountSms(text) {
    var data = window.SMSCounter.count(text, true);
    var length = data["length"];
    var remaining = data["remaining"];
    var part_count = data["part_count"];
    //var text = data["text"];
    var per_message = data["per_message"];
    var encoding = data['encoding'];
    var sms_type = "";
    if (encoding === "GSM_7BIT") {
      sms_type = "Normal";
    } else if (encoding === "GSM_7BIT_EX") {
      sms_type = "Extended"; // for 7 bit GSM: ^ { } \ [ ] ~ | €
    } else if (encoding === "GSM_7BIT_EX_TR") {
      sms_type = "Turkish"; // Only for Turkish Characters "Ş ş Ğ ğ ç ı İ" encoding see https://en.wikipedia.org/wiki/GSM_03.38#Turkish_language_.28Latin_script.29
    } else if (encoding === "UTF16") {
      sms_type = "Unicode"; // for other languages "Arabic, Chinese, Russian" see http://en.wikipedia.org/wiki/GSM_03.38#UCS-2_Encoding
    }
    this.setState({ smsCounter: { smsLength: length, smsRemaining: remaining, smsPartCount: part_count, smsPerMessage: per_message, smsEncoding: encoding, smsType: sms_type } });
    // console.log(length);
    // console.log(remaining);
    // console.log(part_count);
    // console.log(per_message);
    // console.log(encoding);
    // console.log(sms_type);

  }
  handleChangeInput = name => e => {
    var val = {};

    val[name] = e.target.value;
    this.setState(val);
    if (name === "message") {
      this.handleCountSms(e.target.value);
      this.handleValidMessage(e.target.value);
    }

  };
  handleValidMessage(message) {
    var errors = {};

    if (this.state.mode === 1) {
      if (message.indexOf("[NOTE]") < 0 || message.indexOf("[NOM_ELEVE]") < 0 || message.indexOf("[CLASSE]") < 0 || message.indexOf("[EXAM]") < 0 || message.indexOf("[MATIERE]") < 0)
        errors["message"] = this.props.intl.formatMessage({ id: "InputError_5" });
    } else if (this.state.mode === 2) {
      if (message.indexOf("[MOYENNE]") < 0 || message.indexOf("[NOM_ELEVE]") < 0 || message.indexOf("[CLASSE]") < 0 || message.indexOf("[EXAM]") < 0)
        errors["message"] = this.props.intl.formatMessage({ id: "InputError_6" });
    }
    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return false;
    } else {
      this.setState({ controlled: true });
      return true;
    }

  }
  componentDidMount() {
    //this.listClasse();
    this.listCategorie();
    this.handleCountSms("");
  }
  handleChangeClass = event => {
    var self = this;
    if (event.target.value != null && event.target.value !== "---") this.setState({ type: 2 });
    this.setState({ classe: event.target.value, eleves: [], eleve: "---" }, () => {
      self.listEleve();
    });
  };
  handleChangeEleve = event => {
    if (event.target.value != null && event.target.value !== "---") this.setState({ type: 1 });
    else this.setState({ type: 2 });
    this.setState({ eleve: event.target.value });
  }
  listTrimestre() {
    let self = this;
    this.setState({ sequences: [], progressTrimestre: 1 });
    var url = appProperties.basepathServer + '/manager/annee-scolaire/trimestres?limit=-1';
    if (this.props.annee != null) url += ("&id=" + this.props.annee.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ trimestre: [], progressTrimestre: 0 });
      return;
    }
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          self.setState({ progressTrimestre: 0, trimestres: data.datas });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ trimestres: [], progressTrimestre: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  listSequences() {
    let self = this;
    this.setState({ sequences: [], progressSequence: 1 });
    var url = appProperties.basepathServer + '/manager/annee-scolaire/sequences?limit=-1';
    if (this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) url += ("&id=" + this.state.trimestre.id);
    else {
      this.setState({ sequences: [], progressSequence: 0 });
      return;
    }
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {


          self.setState({ progressSequence: 0, sequences: data.datas });

        } else {
          self.setState({ sequences: [], progressSequence: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ sequences: [], progressSequence: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  listEnseignants() {

    let self = this;
    this.setState({ classes: [], progressEnseignant: 1 });
    var url = appProperties.basepathServer + "/rest-enseignant/list-grouped?etablissement_id=" + this.props.etablissement.id + "&limit=-1";
    if (this.props.annee != null) url += ("&annee_id=" + this.props.annee.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [], progressEnseignant: 0 });
      return;
    }

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          var enseignants = [];
          (data.datas || []).forEach(e => {
            enseignants = enseignants.concat(e.enseignants);
          });
          self.setState({ progressEnseignant: 0, classes: self.formatData({ enseignants: enseignants }) });

        } else {
          self.setState({ classes: [], progressEnseignant: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ classes: [], progressEnseignant: 3 });

        console.log('There was an error listed type de frais.');
      });
  }

  formatData(input) {
    if (null == input) return [];
    if (input.enseignants && Array.isArray(input.enseignants) && 0 < input.enseignants.length) {
      var enseignants = input.enseignants;
      var output = Object.assign({}, input);
      delete output.enseignants;
      var exist = false;
      var myReduce = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
        // console.log(accumulator);
        exist = accumulator.reduce((acc, cur, ind, arr) => {
          return acc ? acc : null != cur && null != currentValue.classeEtablissement && cur.id === currentValue.classeEtablissement.id;
        }, false);
        return exist ? accumulator : accumulator.concat(currentValue.classeEtablissement ? currentValue.classeEtablissement : null);
      }, []);
      var contents = [];
      var mat = null;
      console.log("myReduce ", myReduce);
      var myMap = myReduce.map((classe, index, array) => {
        contents = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
          if (null != classe && null != currentValue.classeEtablissement && classe.id === currentValue.classeEtablissement.id) {
            mat = Object.assign({}, currentValue);
            delete mat.classeEtablissement;
            return accumulator.concat(mat);
          } else return accumulator;
        }, []);
        classe["enseignants"] = contents;
        return classe;
      });
      //output["classes"] = myMap;

      return myMap;
    }
    //input["classes"] = [];
    return [];
  }


  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }

  add() {


    var errors = {};
    this.setState({ errors: errors });

    if (!this.handleValidMessage(this.state.message)) errors["message"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.type === 1 && (this.state.eleve == null || this.state.eleve === "---")) errors["eleve"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.type === 2 && (this.state.classe == null || this.state.classe === "---")) errors["classe"] = this.props.intl.formatMessage({ id: "InputError_1" });


    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }


    var formData = new FormData(); // Currently empty
    formData.append("type", this.state.type);
    formData.append("message", this.state.message);
    if (this.state.type === 1) formData.append("eleve_id", this.state.eleve.id);
    if (this.state.type === 2) formData.append("classe_etablissement_id", this.state.classe.id);
    formData.append("etablissement_id", this.props.etablissement.id);
    formData.append("annee_id", this.props.annee.id);
    formData.append("trimestre_id", (this.state.trimestre == null || this.state.trimestre === "---") ? "" : this.state.trimestre.id);
    formData.append("sequence_id", (this.state.sequence == null || this.state.sequence === "---") ? "" : this.state.sequence.id);
    formData.append("enseignant_id", (this.state.enseignant == null || this.state.enseignant === "---") ? "" : this.state.enseignant.id);
    formData.append("mode", this.state.mode);
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/alert-sms/add-sms-etablissement", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, 'new-sms', { value: data.row });

          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSmsView_9" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSmsView_10" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSmsView_11" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -4) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterSmsView_13" }),
            {
              variant: "warning",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  // computeUrlEleve(classe) {
  //   if (classe == null || this.props.annee == null) return;
  //   return appProperties.basepathServer + "/rest-eleve/list?etablissement_id=" + this.props.etablissementId + "&limit=-1&mini=1&only_published=1&annee_id=" + this.props.annee.id + "&classe_id=" + this.state.classe.value + "&q=";
  // }
  listClasse() {
    let self = this;
    this.setState({ classes: [] });
    var url =
      appProperties.basepathServer +
      "/manager/annee-scolaire/classes?id=" +
      this.props.annee.id +
      "&limit=-1";
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ classes: data.datas });
        } else {
          self.setState({ classes: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ classes: [] });
      });
  }
  listEleve() {
    let self = this;
    this.setState({ eleves: [], progressEleve: true });
    if (this.state.classe == null || this.state.classe === "---" || this.props.annee == null) {
      this.setState({ progressEleve: false });
      return;
    }
    var url =
      appProperties.basepathServer + "/rest-eleve/list?etablissement_id=" + this.props.etablissementId + "&limit=-1&mini=1&only_published=1&annee_id=" + this.props.annee.id + "&classe_id=" + this.state.classe.id
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ eleves: data.datas, progressEleve: false });
        } else {
          self.setState({ eleves: [], progressEleve: false });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ eleves: [], progressEleve: false });
      });
  }

  render() {
    const { classes, mode, ...other } = this.props;
    const { formatMessage } = this.props.intl;
    const nameClasse = this.state.classe != null && this.state.classe !== "---" ? this.state.classe.name : ""
    return (
      <main className={classes.main}>
        {/* <div className={classes.pageTitle} id="form-dialog-title">{formatMessage({ id: "AjouterSmsView_3" })} </div> */}


        <Dialog onUpdateItem={null} open={this.state.openDialogEleve} onClose={this.handleCloseDialogEleve} aria-labelledby="simple-dialog-title" {...other}>
          <DialogTitle id="simple-dialog-title">{formatMessage({ id: "AjouterSmsView_17" }, { classe: nameClasse })}</DialogTitle>
          <DialogContent style={{ width: '400px' }}>
            <List>
              {this.state.eleves.map((eleve, index) => (
                <ListItem button key={index}>
                  <ListItemAvatar title={eleve.matricule}>
                    <Avatar alt='' src={eleve.pathPhoto || defaultAvatar} className={classes.avatar} />
                  </ListItemAvatar>
                  <ListItemText primary={(eleve.fullname || '@' + eleve.user.name)} secondary={eleve.phone} />
                </ListItem>
              ))}

            </List>
          </DialogContent>
        </Dialog>




        <div className={classes.pageSubtitle}>
          {formatMessage({ id: "AjouterSmsView_18" })} &nbsp;<b>{formatMessage({ id: "AjouterSmsNoteMoyenneView_1" })}</b>
        </div>

        {this.state.inProgress
          ? <div className={classes.progress}><LinearProgress variant="query" /></div>
          : null
        }
        {!this.state.controlled
          ? <div className={classes.error}>{formatMessage({ id: "AjouterClasseEtablissementView_3" })}</div>
          : null
        }
        <div className="layout horizontal end-justified center">
          <div style={{ paddingRight: '10px', paddingTop: '10px' }} className="flex">

            <div className="layout">

              <div className="layout horizontal center end-justified">
                <div style={{ paddingTop: '15px' }}>
                  <FormControl
                    style={{ height: "35px" }}
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      title={formatMessage({
                        id: "ListClasseEtablissementView_4"
                      })}
                      displayEmpty
                      classes={{
                        selectMenu: classes.selectMenu,
                        root: classes.selectRoot
                      }}
                      value={this.state.categorie}
                      onChange={this.handleChangeCategorie}
                      input={
                        <OutlinedInput
                          labelWidth={this.state.labelWidth}
                          name="Choisir une classe"
                          id="outlined-classe-simple"
                          classes={{ input: classes.outlinedInput }}
                        />
                      }
                    >
                      {" "}
                      <MenuItem value={"---"}>
                        {formatMessage({ id: "ListClasseEtablissementView_3" })}
                      </MenuItem>
                      {this.state.categories.map((row, index) => (
                        <MenuItem title={row.description} key={index} value={row}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
                    <Select
                      title={formatMessage({ id: "AjouterClasseEtablissementView_6" })}
                      displayEmpty
                      classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                      value={this.state.classe}
                      onChange={this.handleChangeClass}
                      input={
                        <OutlinedInput
                          labelWidth={this.state.labelWidth}
                          name="Choisir une classe"
                          id="outlined-classe-simple"
                          classes={{ input: classes.outlinedInput }}
                        />
                      }
                    > <MenuItem value={'---'}>{formatMessage({ id: "ListEleveView_1" })}</MenuItem>
                      {this.state.classes.map((row, index) => (
                        <MenuItem key={index} value={row}>{row.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText style={{ marginLeft: '10px' }}>{this.state.errors.classe ? <span className="error_field">{this.state.errors.classe}</span> : ""}</FormHelperText>



                </div>


                <div style={{ paddingLeft: '15px', paddingTop: '15px' }}>

                  <FormControl
                    style={{ height: "35px", }}
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      title={formatMessage({ id: "AjouterSmsView_6" })}
                      displayEmpty
                      classes={{
                        selectMenu: classes.selectMenu,
                        root: classes.selectRoot
                      }}
                      value={this.state.eleve}
                      onChange={this.handleChangeEleve}
                      input={
                        <OutlinedInput
                          labelWidth={this.state.labelWidth}
                          name={formatMessage({ id: "AjouterSmsView_6" })}
                          id="outlined-classe-simple"
                          classes={{ input: classes.outlinedInput }}
                        />
                      }
                    >
                      {" "}
                      <MenuItem value={"---"}>
                        {formatMessage({ id: "AjouterSmsView_14" })}
                      </MenuItem>
                      {this.state.eleves.map((row, index) => (
                        <MenuItem key={index} value={row}>
                          {row.fullname || '@' + row.user.name} - {row.matricule} ({row.phone})
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                  <FormHelperText style={{ marginLeft: '10px' }}>{this.state.errors.eleve ? <span className="error_field">{this.state.errors.eleve}</span> : ""}</FormHelperText>
                </div>

              </div>


              <div className="layout horizontal center end-justified">




                <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
                  <Select
                    title={formatMessage({ id: "DashboardContainer_1" })}
                    displayEmpty
                    classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                    value={this.state.trimestre}
                    onChange={this.handleChangeTrimestre}
                    input={
                      <OutlinedInput
                        labelWidth={this.state.labelWidth}
                        name="Choisir un trimestre"
                        id="outlined-classe-simple"
                        classes={{ input: classes.outlinedInput }}
                      />
                    }
                  > <MenuItem value={'---'}>{formatMessage({ id: "DashboardContainer_4" })}</MenuItem>
                    {this.state.trimestres.map((row, index) => (
                      <MenuItem key={index} value={row}>{row.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>


                <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
                  <Select
                    title={formatMessage({ id: "DashboardContainer_2" })}
                    displayEmpty
                    classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                    value={this.state.sequence}
                    onChange={this.handleChangeSequence}
                    input={
                      <OutlinedInput
                        labelWidth={this.state.labelWidth}
                        name="Choisir une séquence"
                        id="outlined-classe-simple"
                        classes={{ input: classes.outlinedInput }}
                      />
                    }
                  > <MenuItem value={'---'}>{formatMessage({ id: "DashboardContainer_3" })}</MenuItem>
                    {this.state.sequences.map((row, index) => (
                      <MenuItem key={index} value={row}>{row.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
                  <Select
                    title={formatMessage({ id: "ListEnseignantView_1" })}
                    displayEmpty
                    classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                    value={this.state.enseignant}
                    onChange={this.handleChangeEnseignant}
                    input={
                      <OutlinedInput
                        labelWidth={this.state.labelWidth}
                        name="Choisir une matière"
                        id="outlined-matiere-simple"
                        classes={{ input: classes.outlinedInput }}
                      />
                    }
                  > <MenuItem value={'---'}>{formatMessage({ id: "ListEnseignantView_2" })}</MenuItem>
                    {(this.state.classe != null && this.state.classe !== "---" && this.state.classe.enseignants) && this.state.classe.enseignants.map((row, index) => (
                      <MenuItem key={index} value={row}>{row.matiereName || '#' + row.matiere.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </div>







              <div className="layout horizontal center-center" style={{ padding: '10px', margin: '10px', border: '1px dashed #EAEAEA' }}>
                <div style={{ padding: '0 15px' }}>
                  <span>{formatMessage({ id: "ListSmsView_3" })}:</span>
                </div>
                <div>
                  {(this.state.type === 2 && this.state.eleves != null) && <div>
                    {this.state.progressEleve && <span style={{ color: 'var(--paper-amber-500)' }}>{formatMessage({ id: "AjouterSmsView_16" })}</span>}
                    {!this.state.progressEleve && <span style={{ cursor: 'pointer', textDecoration: 'underline', color: 'var(--app-primary-color)' }} onClick={this.handleOpenDialogEleve} >{formatMessage({ id: "AjouterSmsView_15" }, { count: this.state.eleves.length })}</span>}
                  </div>}
                  {(this.state.type === 1 && this.state.eleve != null && this.state.eleve !== "---") && <a rel="noopener noreferrer" target="_blank" href={userProfileUrl(this.state.eleve.user)}>   {this.state.eleve.fullname || '@' + this.state.eleve.user.name} - {this.state.eleve.matricule} ({this.state.eleve.phone})</a>}
                </div>
              </div>
              <div className="layout horizontal center-center" style={{ padding: '10px', margin: '10px', border: '1px dashed #EAEAEA' }}>
                <div style={{ padding: '0 15px' }}>
                  {this.state.classe != null && this.state.classe !== "---" ? (
                    <React.Fragment>
                      {this.state.enseignant != null && this.state.enseignant !== "---" ? (
                        <React.Fragment>
                          {this.state.sequence != null && this.state.sequence !== "---" ? (
                            <span style={{ color: 'var(--paper-deep-orange-400)' }}>{formatMessage({ id: "AjouterSmsNoteMoyenneView_3" }, { matiere: this.state.enseignant.matiereName, exam: this.state.sequence.name, classe: this.state.classe.name })}</span>
                          ) : (
                              <React.Fragment>
                                {this.state.trimestre != null && this.state.trimestre !== "---" ? (
                                  <span style={{ color: 'var(--paper-deep-orange-400)' }}>{formatMessage({ id: "AjouterSmsNoteMoyenneView_3" }, { matiere: this.state.enseignant.matiereName, exam: this.state.trimestre.name, classe: this.state.classe.name })}</span>
                                ) : (
                                    <span style={{ color: 'var(--paper-deep-orange-400)' }}>{formatMessage({ id: "AjouterSmsNoteMoyenneView_3" }, { matiere: this.state.enseignant.matiereName, exam: this.props.annee.year, classe: this.state.classe.name })}</span>
                                  )}
                              </React.Fragment>
                            )}
                          <div style={{ padding: '15px 0' }} className="layout vertical center">
                            <span style={{ fontSize: '13px' }}>{formatMessage({ id: "AjouterSmsNoteMoyenneView_7" })}:</span>
                            <span style={{ fontSize: '14px', marginTop: '15px' }}>{formatMessage({ id: "AjouterSmsNoteMoyenneView_5" })}</span>
                          </div>
                        </React.Fragment>
                      ) : (
                          <React.Fragment>
                            {this.state.sequence != null && this.state.sequence !== "---" ? (
                              <span style={{ color: 'var(--paper-purple-400)' }}>{formatMessage({ id: "AjouterSmsNoteMoyenneView_4" }, { exam: this.state.sequence.name, classe: this.state.classe.name })}</span>
                            ) : (
                                <React.Fragment>
                                  {this.state.trimestre != null && this.state.trimestre !== "---" ? (
                                    <span style={{ color: 'var(--paper-purple-400)' }}>{formatMessage({ id: "AjouterSmsNoteMoyenneView_4" }, { exam: this.state.trimestre.name, classe: this.state.classe.name })}</span>
                                  ) : (
                                      <span style={{ color: 'var(--paper-purple-400)' }}>{formatMessage({ id: "AjouterSmsNoteMoyenneView_4" }, { exam: this.props.annee.year, classe: this.state.classe.name })}</span>
                                    )}
                                </React.Fragment>
                              )}
                            <div style={{ padding: '15px 0' }} className="layout vertical center">
                              <span style={{ fontSize: '13px' }}>{formatMessage({ id: "AjouterSmsNoteMoyenneView_7" })}:</span>
                              <span style={{ fontSize: '14px', marginTop: '15px' }}>{formatMessage({ id: "AjouterSmsNoteMoyenneView_6" })}</span>
                            </div>

                          </React.Fragment>
                        )}
                    </React.Fragment>

                  ) : (
                      <span>{formatMessage({ id: "AjouterSmsNoteMoyenneView_2" })}</span>
                    )}

                </div>

              </div>
              <div style={{ paddingTop: '15px' }} >
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="message">
                    {formatMessage({ id: "AjouterSmsView_2" })}
                  </InputLabel>
                  <Input
                    id="message"
                    required
                    name="message"
                    autoComplete="message"
                    onChange={this.handleChangeInput("message")}
                    multiline
                    rows="6"

                    variant="filled"
                  />

                  <FormHelperText> {formatMessage({ id: "AjouterSmsView_12" }, { size: this.state.smsCounter.smsLength, page: this.state.smsCounter.smsPartCount, encoding: this.state.smsCounter.smsEncoding })}</FormHelperText>
                  <FormHelperText>{this.state.errors.message ? <span className="error_field">{this.state.errors.message}</span> : ""}</FormHelperText>
                </FormControl>
              </div>

            </div>
          </div>

        </div>
        <div style={{ padding: '10px' }} className="layout horizontal end-justified">
          <Button
            onClick={this.add}
            variant="contained"
            size="medium"
            disabled={this.state.inProgress}
            className={classes.buttonPrincipale}>
            {formatMessage({ id: "AjouterSmsView_1" })} &nbsp;&nbsp;
            <SendIcon />
          </Button>
        </div>

      </main>
    );
  }
}
AjouterSmsNoteMoyenneView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(
  injectIntl(AjouterSmsNoteMoyenneView)
));
