import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
// import AddIcon from '@material-ui/icons/Add';
import ListIcon from "@material-ui/icons/List";
import DeleteIcon from "@material-ui/icons/Delete";
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, FormattedTime, injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { appProperties, isObjectValueEquals, userProfileUrl, deleteClassInProps } from "../../../components/LdUtils";
import BonDemande from "./BonDemande";
import moment from 'moment';

import DateTimePicker from 'react-datetime-picker';
import LdSelect from "../../../components/LdSelect";




const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    padding: '10px',
    borderBottom: '1px solid #EAEAEA',
    '&:hover': {
      backgroundColor: '#F1F1F1'
    }
  },


  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "12px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    width: "40px !important",
    height: "40px !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },
  main: {
    width: '100%'
  }
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
       <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));

class ItemDemande extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      inProgressValidate: false,
      inProgressDone: false,
      modeEdit: false,
      position: this.props.position,
      motif: this.props.row.motif,
      pu: this.props.row.amount,
      type: this.props.row.type,
      // fournisseur: this.props.row.fournisseur,
      date: this.props.row.date == null || this.props.row.date.trim() === '' ? new Date() : new Date(this.props.row.date),
      controlled: true,
      etablissement: this.props.etablissement,
      openBonDemande: false,

    };
    this.bonDemandeRef = React.createRef();

    this.handleDevalidate = this.handleDevalidate.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleCloseBonDemande = this.handleCloseBonDemande.bind(this);
    this.handleClickOpenBonDemande = this.handleClickOpenBonDemande.bind(this);
    this.doUpdateDemande = this.doUpdateDemande.bind(this);
  }

  handleClickOpenBonDemande = () => {
    var self = this;
    this.setState({ openBonDemande: true }, () => {
      setTimeout(() => {
        self.bonDemandeRef.handleClickOpenDialog();
      }, 0);
    });
  };

  handleCloseBonDemande = () => {
    this.setState({ openBonDemande: false });
    //this.dossierRef.doCloseDialog();
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          motif: self.props.row.motif || '',
          type: self.props.row.type || null,
          pu: self.props.row.amount || 0,
          date: self.props.row.date == null || self.props.row.date.trim() === '' ? new Date() : new Date(self.props.row.date)
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }
  handleEdit = () => {
    this.setState({ modeEdit: true });
  }
  reset = () => {
    this.setState({ modeEdit: false, controlled: true });
  }
  handleDateChange(date) {
    this.setState({
      date: date,
    });
  }

  handleChangeType(value) {
    this.setState({ type: value });
  }
  handleChangeInput = name => event => {
    var val = {};

    if (name === 'pu' || name === "quantite") {
      var value = event.target.value;

      if (value < 0) {
        value = 0;
        event.target.value = value;
      }
      if (event.target.value === "") value = 0;
      val[name] = parseFloat(value);
      this.setState(val);
    } else {
      val[name] = event.target.value;
      this.setState(val);
    }

  };

  doUpdateDemande(row) {
    let datas = { position: this.state.position, row: row };
    this.props.onUpdateItemDemande('update', datas);
  }
  edit = () => {
    if (this.state.pu == null || this.state.pu === 0 || this.state.motif == null || this.state.motif.trim() === "" || this.state.date == null || this.state.type == null) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty 
    formData.append("motif", this.state.motif);
    formData.append("amount", this.state.pu);
    formData.append("type", this.state.type.id);
    formData.append("id", this.props.row.id);
    // formData.append("fournisseur", this.state.fournisseur);
    // if(this.props.annee && this.props.annee != null && this.props.annee!=='---')formData.append("annee", this.props.annee.id);
    formData.append("date", moment(this.state.date).format('YYYY-MM-DD HH:mm:ss'));

    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/decaissement/edit-demande?id=" + this.props.row.id, {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.reset();
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItemDemande('update', datas);
          self.props.enqueueSnackbar("La demande a été modifiée avec succès", { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else { //TODO: veirfier si au backend c'est bien geré, si l'action a été effectuée
          self.props.enqueueSnackbar("Les données ne sont pas valides", { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }
        this.setState({ inProgress: false });

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  handleDevalidate = () => {
    var self = this;
    this.setState({ inProgressValidate: true });
    fetch(
      appProperties.basepathServer +
      "/manager/decaissement/cancel-demande?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        this.setState({ inProgressValidate: false });

        if (data.result_code === 1) {
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItemDemande('update', datas);
          self.props.enqueueSnackbar("La demande a été annulée", { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar("Vous n'avez pas d'autorisation", { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar("Des décaissement existent sur cette demande", { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressValidate: false });

        alert("There was an error added.");
      });
  };
  handleValidate = () => {
    var self = this;
    this.setState({ inProgressValidate: true });
    fetch(
      appProperties.basepathServer +
      "/manager/decaissement/validate-demande?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        this.setState({ inProgressValidate: false });

        if (data.result_code === 1) {
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItemDemande('update', datas);
          self.props.enqueueSnackbar("La demande a été validée", { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar("Vous n'avez pas d'autorisation", { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar("Element introuvable", { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressValidate: false });

        alert("There was an error added.");
      });
  };
  handleDelete = () => {
    var self = this;
    this.setState({ inProgressDone: true });
    fetch(
      appProperties.basepathServer +
      "/manager/decaissement/delete-demande?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        this.setState({ inProgressDone: false });

        if (data.result_code === 1) {
          let datas = { position: self.state.position };
          self.props.onUpdateItemDemande('delete', datas);
          self.props.enqueueSnackbar("La demande a été supprimée", { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar("Vous n'avez pas d'autorisation", { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar("Element introuvable", { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressDone: false });

        alert("There was an error added.");
      });
  };
  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>

              {this.state.modeEdit ?
                <main className={classes.main}>
                  {this.state.inProgress
                    ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                    : null
                  }
                  {!this.state.controlled
                    ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
                    : null
                  }
                  <div className="layout horizontal">
                    <div style={{ paddingRight: '10px' }} className="flex" >
                      <div className="layout horizontal center justified">
                        <div style={{ width: '49%', marginTop: '0 !important' }}>
                          <LdSelect
                            label={formatMessage({ id: "ListDemande_1" })}
                            handleValue={this.handleChangeType}
                            defaultValue={(row.type && row.type != null) ? { label: row.type.name, value: row.type.id, id: row.type.id } : null}
                            firstLoad isSearchable
                            baseUrl={appProperties.basepathServer + "/manager/type-decaissement/list?limit=0&only_published=1&etablissement=" + this.props.etablissement.id}  {...deleteClassInProps(this.props)} />
                        </div>

                        <TextField
                          id="montant"
                          label={formatMessage({ id: "ListDemande_2" })}
                          style={{ width: '48%', marginTop: '0 !important' }}
                          value={this.state.pu}
                          placeholder="12500"
                          onChange={this.handleChangeInput('pu')}
                          margin="normal"
                          type="number"
                          InputProps={{
                            inputProps: { min: 0 },

                          }}
                        />
                      </div>
                      <div className="layout horizontal center justified">

                        <TextField
                          id="motif"
                          label={formatMessage({ id: "ListDemande_3" })}
                          style={{ width: '68%', marginTop: '0 !important' }}
                          value={this.state.motif}
                          placeholder={formatMessage({ id: "ListDemande_4" })}
                          onChange={this.handleChangeInput('motif')}
                          margin="normal"
                        />
                        <span className={classes.label}>
                          {formatMessage({ id: "ListDemande_5" })}
                        </span>
                        <DateTimePicker
                          // maxDate={this.state.maxDate}
                          locale='fr-FR'
                          className={classes.datePicker}
                          value={this.state.date}
                          onChange={this.handleDateChange}
                        />
                      </div>

                    </div>
                    <div className="layout horizontal center">
                      <Button
                        onClick={this.edit}
                        variant="contained"
                        size="medium"
                        style={{ margin: '5px' }}
                        className={classes.buttonPrincipale}>
                        {formatMessage({ id: "ItemAnneeScolaire_Up" })}
                      </Button>
                      <Button
                        onClick={this.reset}
                        variant="contained"
                        size="medium"
                        style={{ margin: '5px' }}
                        className={classes.buttonGreyWhite}>
                        {formatMessage({ id: "ItemAnneeScolaire_Ccl" })}
                      </Button>
                    </div>
                  </div>
                </main>
                :
                <div>
                  <div className='layout horizontal center'>
                    <span style={{ fontSize: '12px' }}>{formatMessage({ id: "ListDemande_6" })}</span> &nbsp; <span className={classes.name}>{row.motif}</span>
                  </div>
                  <div className='layout horizontal center'>
                    <div>
                      <span style={{ fontSize: '12px' }}>{formatMessage({ id: "ListDemande_7" })}</span> &nbsp; <span style={{ backgroundColor: 'var(--app-primary-color)', padding: '3px', color: 'white' }} className={classes.name}>{row.type && row.type !== null ? row.type.name : ""}</span>
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                      <span style={{ fontSize: '12px' }}>{formatMessage({ id: "ListDemande_8" })}</span>&nbsp; <span className={classes.name}>{row.amount && row.amount !== 0 ? row.amount : "#######"}FCFA</span>
                    </div>
                  </div>
                  <div className='layout horizontal center'>
                    {/* <div>
                      <span style={{fontSize:'12px'}}>Fournisseur: </span> &nbsp; <span  className={classes.name}>{row.fournisseur && row.fournisseur!==""?row.fournisseur: "*******"}</span>
                    </div> */}
                    <div className={classes.date}>{formatMessage({ id: "ListDemande_9" })} <PostDate date={row.date} /></div>

                  </div>
                  <div style={{ fontWeight: "400" }} className={classes.date}>{/*Ajouté le  <PostDate date={row.created}/> par */}
                    <FormattedMessage id="ItemEleve_7" values={{ attrib: <PostDate date={row.created} /> }} />
                    <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.creator)}>
                      {row.creator.name}
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
          {!this.state.modeEdit && (
            <div classNamee="layout vertical">
              <div className={classes.actions}>
                {!this.props.row.validated &&
                  <Button
                    title={formatMessage({ id: "ListDemande_10" })}
                    disabled={this.state.inProgressValidate}
                    onClick={this.handleValidate}
                    variant="contained"
                    size="medium"
                    style={{ margin: '5px' }}
                    className={classes.buttonPrincipale}>
                    {this.state.inProgressValidate ? formatMessage({ id: "ListDemande_12" }) : formatMessage({ id: "ListDemande_10" })}
                  </Button>}
                {!!this.props.row.validated && <Button
                  title={formatMessage({ id: "ListDemande_11" })}
                  disabled={this.state.inProgressValidate}
                  onClick={this.handleDevalidate}
                  variant="outline"
                  size="medium"
                  style={{ margin: '5px' }}>
                  {this.state.inProgressValidate ? formatMessage({ id: "ListDemande_12" }) : formatMessage({ id: "ListDemande_11" })}
                </Button>}
                {/* {this.props.row.status === 1 &&
                    <Button 
                      title="Marquer la commande comme livrée"
                      disabled={this.state.inProgressDone}
                      onClick={this.handleValidate} 
                      variant="contained"
                      size="medium"
                      style={{margin:'5px'}}
                      className = {classes.buttonPrincipale}>
                      {this.state.inProgressDone?"En cours...":"Marquer comme livrée"}
                    </Button>} */}
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
                  aria-label="Editer"
                  onClick={this.handleEdit}>
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  title={formatMessage({ id: "ListDemande_13" })}
                  aria-label="Delete"
                  onClick={this.handleDelete}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
                <IconButton
                  title={formatMessage({ id: "ListDemande_14" })}
                  aria-label="decaissement"
                  onClick={this.handleClickOpenBonDemande}>
                  <ListIcon fontSize="small" />
                </IconButton>
              </div>
              {this.props.row.validated && <div className="layout horizontal end-justified">
                <span style={{ color: 'var(--app-primary-color)', fontWeight: '600', fontSize: '13px', padding: '3px' }}>{formatMessage({ id: "ListDemande_15" })}</span>
              </div>}
            </div>
          )}
          {this.state.openBonDemande && <BonDemande onUpdateDemande={this.doUpdateDemande} expanded='filled' annee={this.props.annee} etablissement={this.props.etablissement} demande={row} onClose={this.handleCloseBonDemande} onRef={ref => (this.bonDemandeRef = ref)} />}

        </div>

      </React.Fragment>
    );
  }
}
ItemDemande.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemDemande)));
