import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import LdSelectUser from '../../../components/LdSelectUser';
import { appProperties, deleteClassInProps, fireWithObject } from "../../../components/LdUtils";



const styles = theme => ({
  main: {
    // width: "400px",
    textAlign:"left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
   root: {
    width: "100%",
    marginTop: '30px',

   },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },


  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding:'0px'
  },
  
  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  },
  dialog:{
    minHeight:"300px"
  }
});


class AjouterManagerView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      reseauId: this.props.reseauId,
      data: null,
      dialogOpened: false,
      inProgress:false,
    };
    this.add = this.add.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValue = this.handleValue.bind(this);

  }

  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };
  componentDidMount() {
  }
 
  handleValue(data) {
    this.setState({ data: data });
  }

  add() {
    var formData = new FormData(); // Currently empty
    formData.append("id", this.state.data.value);
    formData.append("reseau", this.state.reseauId);
   
    //let self = this;
    this.setState({inProgress:true});
    fetch(appProperties.basepathServer + "/web/reseau-etablissement/add-manager?reseau="+this.state.reseauId, {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({inProgress:false});
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          fireWithObject(document,'new-manager',{value:data.row});

        }
      })
      .catch(err => {
        console.log(err);
        this.setState({inProgress:false});
        alert("There was an error added.");
      });
  }
 
  render() {
    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className = {classes.buttonPrincipale}
          onClick={this.handleClickOpen}

        >
          Ajouter un administrateur
        </Button>
        <Dialog
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">Ajouter un Administrateur</DialogTitle>
          <DialogContent className={classes.dialog}>
            { this.state.inProgress
              ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
              : null
            }
            <div  className={classes.pageSubtitle} >Recherchez l'administrateur avec la nom ou le prenom</div>
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>
              {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}
            
              {/* {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null} */}
              
              <div className={classes.root}>

              <LdSelectUser 
                     handleValue = {this.handleValue}
                     isSearchable
                     baseUrl={appProperties.basepathServer + "/rest-user/search?detail=0&limit=-1&name="}
                     {...deleteClassInProps(this.props)} 
                     />
               





                <div style ={{marginTop:'15px',padding:'15px',border:'1px dashed #EEEEEE'}}>
                  <div>
                    Vous avez séléctionné: 
                  </div>
                  {this.state.data != null ? 
                  <p style ={{marginTop:'15px',wordBreak:'break-all'}}><b>{this.state.data.label}</b></p>
                  : 
                      <p style ={{marginTop:'15px',fontSize:'12px'}}>Aucun utilisateur</p>
                  }

                </div>
             
                
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button  onClick={this.handleClose} 
              variant="contained"
              size="medium"
              className = {classes.buttonGreyWhite}>
              Cancel
            </Button>
            <Button 
              disabled = {this.state.data == null}
              onClick={this.add} 
              variant="contained"
              size="medium"
              className = {classes.buttonPrincipale}>
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterManagerView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(
  AjouterManagerView
);
