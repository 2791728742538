import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    main: {
      width: "400px",
      padding:"15px",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
        width: 350,
        marginLeft: "auto",
        marginRight: "auto"
      },
     
    },
    paper: {
      //marginTop: theme.spacing.unit * 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
        .spacing.unit * 3}px`
    },
    avatar: {
      margin: theme.spacing.unit,
      
      display: "inline-block",
      width: "64px",
      height: "64px",
      background: "#ddd",
      // backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing.unit
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
      fontSize:'18px',
      borderRadius:'0 !important',
      textTransform:'lowercase',
      backgroundColor:theme.color.primaryDark
  
    }
  });
class RegisterView extends Component{
    constructor(props) {
        super(props);
        //console.log("User view",this.props)
        this.state ={
          email:null,
          password:null,
          rememberMe:null
        }
    }
    render(){
        const { classes } = this.props;
        return (
            <main className={classes.paper}>
        <CssBaseline />
        <Paper square elevation={0}>
          <div className={classes.form}>
          <FormControl margin="dense" required fullWidth>
              <Input id="nom"  name="nom" autoComplete="nom" autoFocus placeholder="entrez votre nom" />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
            <Input id="prenom"  name="prenom" autoComplete="prenom" autoFocus placeholder="entrez votre prenom" />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
            <Input id="email"  name="email" autoComplete="email" autoFocus placeholder="entrez votre email" />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <Input id="confirmEmail"  name="confirmEmail" autoComplete="confirmEmail" autoFocus  placeholder="cofirmer votre adresse mail"/>
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input id="email"  name="email" autoComplete="email" autoFocus />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input id="email"  name="email" autoComplete="email" autoFocus />
            </FormControl>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember"  color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
             Se connecterrrr
            </Button>
          </div>
        </Paper>
      </main>
        );
    }
}
RegisterView.propTypes = {
    classes: PropTypes.object.isRequired,
    /*theme: PropTypes.object.isRequired,*/

};
export default withStyles(styles/*,{ withTheme: true }*/)(RegisterView);

