import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import LdSelectStatic from "../../../components/LdSelectStatic";
import {
  appProperties,
  deleteClassInProps,
  isObjectValueEquals
} from "../../../components/LdUtils";
import { withSnackbar } from "notistack";
import moment from "moment";
import DatePicker from "react-date-picker";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { injectIntl } from "react-intl";

const styles = theme => ({
  // main: {
  //   width: "400px",
  //   padding: "15px",
  //   display: "block", // Fix IE 11 issue.
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
  //     width: 450,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   }
  // },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "135px",
    padding: "0px !important"
  },
  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  checked: {
    color: "var(--paper-green-500)"
  },
  unChecked: {
    color: "var(--paper-grey-500)"
  },
  contentImage: {
    width: "40px"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  }
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "600",
    fontSize: "15px",
    padding: "4px 7px 4px 7px"
  },
  body: {
    fontSize: 14,
    padding: "4px 7px 4px 7px"
  }
}))(TableCell);

class ItemTypeAlert extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      code: this.props.row.code || "",
      name: this.props.row.name || "",
      description: this.props.row.description || "",
      contentModel: this.props.row.contentModel || "",
      etablissements: null,
      options: [],
      redirect: false,
      ids: "",
      datas: [],
      dialogOpened: false,
      inProgress: false,
      position: this.props.position,
      type: this.props.row.type || 0,
      auto: this.props.row.auto ? 1 : 0,
      date: new Date() //this.props.row.sendDate == null || this.props.row.sendDate.trim() === ''? new Date(): new Date(this.props.row.sendDate),
    };
    this.edit = this.edit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValueType = this.handleValueType.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChangeAuto = this.handleChangeAuto.bind(this);
  }
  handleChangeAuto = event => {
    var value = parseInt(event.target.value);
    this.setState({ auto: value }, () => { });
  };
  handleDateChange(date) {
    this.setState({
      date: date
    });
  }
  handleValueType(option) {
    this.setState({ type: option != null ? option.value : 0 });
  }

  componentDidUpdate(prevProps) {
    var self = this;

    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          code: self.props.row.code || "",
          name: self.props.row.name || "",
          description: self.props.row.description || "",
          contentModel: self.props.row.contentModel || "",
          type: self.props.row.type,
          auto: self.props.row.auto ? 1 : 0,
          date: new Date()
          //date: self.props.row.sendDate == null|| self.props.row.sendDate.trim() === ''? new Date(): new Date(self.props.row.sendDate),
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }

  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };
  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/alert-sms/delete-type?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error deleted.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/alert-sms/set-status-type?id=" +
      this.props.row.id +
      "&status=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error enabled.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/alert-sms/set-status-type?id=" +
      this.props.row.id +
      "&status=0",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error disabled.");
      });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  edit() {
    if (
      this.state.code == null ||
      this.state.code.trim() === "" ||
      this.state.name == null ||
      this.state.name.trim() === "" ||
      this.state.description == null ||
      this.state.description.trim() === "" ||
      (!this.state.auto && this.state.date == null)
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    var formData = new FormData(); // Currently empty
    formData.append("id", this.props.row.id);
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("type", this.state.type);
    formData.append("model", this.state.contentModel);
    formData.append("auto", this.state.auto);
    if (!this.state.auto)
      formData.append(
        "send_date",
        moment(this.state.date).format("YYYY-MM-DD")
      );

    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/alert-sms/edit-type", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.props.onUpdateItem("update", {
            position: self.state.position,
            row: data.row
          });
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemTypeAlert_4" }), {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        } else {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemTypeAlert_5" }), {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        alert("There was an error updated.");
      });
  }
  getDefaultType(type) {
    var array = [
      { value: 0, color: 'var(--paper-indigo-500)', label: this.props.intl.formatMessage({ id: "ItemAlertHistory_9" }) },
      { value: 1, color: 'var(--app-primary-color)', label: this.props.intl.formatMessage({ id: "ItemAlertHistory_10" }) },
      { value: 2, color: 'var(--paper-orange-500)', label: this.props.intl.formatMessage({ id: "ItemAlertHistory_11" }) },
      { value: 3, color: 'var(--paper-yellow-500)', label: this.props.intl.formatMessage({ id: "ItemAlertHistory_12" }) },
      { value: 4, color: 'var(--paper-green-500)', label: this.props.intl.formatMessage({ id: "ItemAlertHistory_13" }) }
    ];
    if (type >= 0 && type < array.length) return array[type];
    return null;
  }

  render() {
    const { classes, row } = this.props;
    const rowType = this.getDefaultType(row.type);
    const typeName = rowType != null ? rowType.label : "";
    const typeColor = rowType != null ? rowType.color : "";
    const { formatMessage } = this.props.intl;
    return (
      <React.Fragment>
        <TableRow className={classes.row} key={row.id}>
          <CustomTableCell component="th" scope="row">
            {row.code}
          </CustomTableCell>
          <CustomTableCell align="left">{row.name}</CustomTableCell>
          <CustomTableCell align="left"><span style={{ backgroundColor: typeColor, color: "white", padding: '5px', whiteSpace: 'nowrap' }}>{typeName}</span></CustomTableCell>
          <CustomTableCell align="left" title={row.description}>
            {row.description}
          </CustomTableCell>
          <CustomTableCell align="left">{row.contentModel}</CustomTableCell>
          <CustomTableCell align="left">
            {!row.auto && (
              <span
                title={formatMessage({ id: "ItemTypeAlert_1" })}
                style={{ fontSize: "12px", color: "var(--paper-orange-500)" }}
              >
                {" "}
                {row.sendDate}{" "}
              </span>
            )}
            {!!row.auto && (
              <span
                title={formatMessage({ id: "ItemTypeAlert_2" })}
                style={{ fontSize: "12px", color: "var(--paper-green-500)" }}
              >
                {formatMessage({ id: "ItemTypeAlert_3" })}
              </span>
            )}
          </CustomTableCell>

          <CustomTableCell align="left">
            <div className={classes.actions}>
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
                color="primary"
                aria-label="Edit"
                onClick={this.handleClickOpen}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_del" })}
                aria-label="Delete"
                onClick={this.handleDelete}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>

              {this.props.row.status ? (
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                  classes={{ root: classes.disable }}
                  aria-label="Edit"
                  onClick={this.handleDisabled}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
              ) : (
                  <IconButton
                    title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                    classes={{ root: classes.enable }}
                    aria-label="Delete"
                    onClick={this.handleEnabled}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
            </div>
          </CustomTableCell>
        </TableRow>

        {this.state.dialogOpened && (
          <Dialog
            open={this.state.dialogOpened}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle className={classes.pageTitle} id="form-dialog-title">
              <div>
                {formatMessage({ id: "ItemTypeAlert_edit" })}
                {row.code}
              </div>
              {this.state.inProgress ? (
                <div className={classes.progress}>
                  <LinearProgress variant="query" />
                </div>
              ) : null}
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterTypeAlertView_form" })}
              </div>

              <CssBaseline />
            </DialogTitle>
            <DialogContent>
              <Paper square elevation={0} className={classes.paper}>
                {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}

                {/* {this.state.result != null ? (
                  <div className="Error-message">
                    {this.state.result.message}
                  </div>
                ) : null} */}

                <div className={classes.root}>
                  <div style={{ padding: "15px 0", width: "100%" }}>
                    <LdSelectStatic
                      defaultValue={this.getDefaultType(
                        row.type
                      )}
                      options={[
                        {
                          value: 0,
                          label: formatMessage({ id: "ItemAlertHistory_9" })
                        },
                        {
                          value: 1,
                          label: formatMessage({ id: "ItemAlertHistory_10" })
                        },
                        {
                          value: 2,
                          label: formatMessage({ id: "ItemAlertHistory_11" })
                        },
                        {
                          value: 3,
                          label: formatMessage({ id: "ItemAlertHistory_12" })
                        },
                        {
                          value: 4,
                          label: formatMessage({ id: "ItemAlertHistory_13" })
                        }
                      ]}
                      label={formatMessage({ id: "AjouterTypeAlertView_cat" })}
                      handleValue={this.handleValueType}
                      {...deleteClassInProps(this.props)}
                    />
                  </div>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="code">
                      {formatMessage({ id: "ListTypeAlertView_Table_1" })}
                    </InputLabel>
                    <Input
                      id="code"
                      required
                      name="code"
                      autoComplete="code"
                      onChange={this.handleChangeInput("code")}
                      defaultValue={row.code}
                      placeholde={formatMessage({
                        id: "AjouterTypeAlertView_1"
                      })}
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="name">
                      {formatMessage({ id: "ListTypeAlertView_Table_2" })}
                    </InputLabel>
                    <Input
                      name="name"
                      type="name"
                      id="name"
                      required
                      defaultValue={row.name}
                      onChange={this.handleChangeInput("name")}
                      autoComplete="current-name"
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="description">
                      {formatMessage({ id: "ListTypeAlertView_Table_3" })}
                    </InputLabel>
                    <Input
                      id="description"
                      required
                      defaultValue={row.description}
                      name="description"
                      autoComplete="description"
                      onChange={this.handleChangeInput("description")}
                      multiline
                      rows="4"
                      variant="filled"
                    />
                  </FormControl>

                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="contentModel">
                      {formatMessage({ id: "AjouterTypeAlertView_2" })}
                    </InputLabel>
                    <Input
                      id="contentModel"
                      required
                      defaultValue={row.contentModel}
                      name="alertMessage"
                      autoComplete="contentModel"
                      placeholder={formatMessage({
                        id: "AjouterTypeAlertView_3"
                      })}
                      onChange={this.handleChangeInput("contentModel")}
                      multiline
                      rows="4"
                      variant="filled"
                    />
                    {this.state.type === 0 && (
                      <FormHelperText id="component-helper-text">
                        {formatMessage({ id: "AjouterTypeAlertView_4" })}
                      </FormHelperText>
                    )}
                    {this.state.type === 1 && (
                      <FormHelperText id="component-helper-text">
                        {formatMessage({ id: "AjouterTypeAlertView_5" })}
                      </FormHelperText>
                    )}
                    {this.state.type === 2 && (
                      <FormHelperText id="component-helper-text">
                        {formatMessage({ id: "AjouterTypeAlertView_6" })}
                      </FormHelperText>
                    )}
                    {this.state.type === 3 && (
                      <FormHelperText id="component-helper-text">
                        {formatMessage({ id: "AjouterTypeAlertView_7" })}
                      </FormHelperText>
                    )}
                    {this.state.type === 4 && (
                      <FormHelperText id="component-helper-text">
                        {formatMessage({ id: "AjouterTypeAlertView_8" })}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <br />
                  <br />
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {formatMessage({ id: "AjouterTypeAlertView_9" })}
                    </FormLabel>
                    <RadioGroup
                      aria-label="position"
                      name="position"
                      value={this.state.auto}
                      onChange={this.handleChangeAuto}
                      row
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label={formatMessage({ id: "AjouterTypeAlertView_10" })}
                        labelPlacement="right"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio color="primary" />}
                        label={formatMessage({ id: "AjouterTypeAlertView_11" })}
                        labelPlacement="right"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div>
                    {this.state.auto === 0 && (
                      <div
                        className="layout horizontal center"
                        style={{ padding: "15px 0" }}
                      >
                        <span className={classes.label}>
                          {formatMessage({ id: "AjouterTypeAlertView_12" })}
                        </span>

                        <DatePicker
                          locale="fr-FR"
                          className={classes.datePicker}
                          value={this.state.date}
                          onChange={this.handleDateChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                variant="contained"
                size="medium"
                className={classes.buttonGreyWhite}
              >
                {formatMessage({ id: "AjouterTypeAlertView_13" })}
              </Button>
              <Button
                onClick={this.edit}
                variant="contained"
                size="medium"
                className={classes.buttonPrincipale}
              >
                {formatMessage({ id: "AjouterTypeAlertView_14" })}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}
ItemTypeAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(ItemTypeAlert))
);
