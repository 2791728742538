import React, { Component } from 'react';
import {  Route,Switch } from "react-router-dom";
import LoginController from './controller/LoginController';
import RegisterController from './controller/RegisterController';
import queryString from 'query-string'

class UserIndex extends Component{
    constructor(props){
        super(props);
        this.state = {};
    }
    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        console.log(this.props);
        console.log(values.filter) // "top"
        console.log(values.origin) // "im"
    }
    render(){
        const values = queryString.parse(this.props.location.search)
        console.log(values.email); // "im"
        var emailUser = values.email;
        return(
                <div  style={{height:"calc(100vh - 64px)"}}  >
                    {/* <button onClick={() => this.props.history.push("/")}>Home</button> */}
                    <Switch>
                        {/* <Route exact path="/user" render={(props)=>(<p>Ceci est la page social indexx</p>)}/> */}
                        <Route exact path="/user/login" render={(props) => <LoginController email={emailUser}  {...props} />}/>
                        <Route exact path="/user/register" component={RegisterController}/>
                        <Route render={(props)=>(<p>Page not found</p>)}/>
                    </Switch>
                </div>    
        )
    }
} 


export default UserIndex 