import React, { Component } from "react";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

import LinearProgress from "@material-ui/core/LinearProgress";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: "white"
  },
  root: {
    width: "100%",
    marginTop: "30px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  }
});

class AjouterTypeGroupeEnseignementView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      etablissementId: this.props.etablissementId,
      description: "",
      nameEn: "",
      name: "",
      code: "",
      inProgress: false,
      controlled: true
    };
    this.add = this.add.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  add() {
    if (
      this.state.code == null ||
      this.state.code.trim() === "" ||
      this.state.name == null ||
      this.state.name.trim() === "" ||
      this.state.nameEn == null ||
      this.state.nameEn.trim() === ""
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("name_en", this.state.nameEn);
    formData.append("description", this.state.description);
    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
        "/manager/groupe-enseignement/add-type?etablissement=" +
        this.state.etablissementId,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, "new-type-groupe-enseignement", {
            value: data.row
          });
          self.props.enqueueSnackbar(
            "Type de groupe d'enseignement enregistré avec succès",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else {
          self.props.enqueueSnackbar("Les données ne sont pas valides", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>
        <div className={classes.pageTitle} id="form-dialog-title">
          {formatMessage({ id: "AjouterTypeGroupeEnseignementView_1" })}{" "}
        </div>

        <div className={classes.pageSubtitle}>
          {formatMessage({ id: "AjouterTypeGroupeEnseignementView_2" })}{" "}
        </div>
        {this.state.inProgress ? (
          <div className={classes.progress}>
            <LinearProgress variant="query" />
          </div>
        ) : null}
        {!this.state.controlled ? (
          <div className={classes.error}>
            {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
          </div>
        ) : null}
        <div className="layout horizontal">
          <div style={{ paddingRight: "10px" }} className="flex">
            <Grid
              container
              className={classes.grid}
              justify="flex-start"
              alignItems="center"
            >
              <TextField
                id="code"
                label={formatMessage({ id: "ItemClasseEtablissement_3" })}
                style={{
                  width: "30%",
                  marginTop: "0 !important",
                  marginRight: "5px"
                }}
                value={this.state.code}
                placeholder="Ex: 2018-2019"
                onChange={this.handleChangeInput("code")}
                margin="normal"
              />
              <TextField
                id="nom"
                label={formatMessage({
                  id: "AjouterTypeGroupeEnseignementView_3"
                })}
                style={{
                  width: "30%",
                  marginTop: "0 !important",
                  marginRight: "5px"
                }}
                value={this.state.name}
                placeholder="Ex: 2018-2019"
                onChange={this.handleChangeInput("name")}
                margin="normal"
              />

              <TextField
                id="nom"
                label={formatMessage({
                  id: "AjouterTypeGroupeEnseignementView_4"
                })}
                style={{ width: "30%", marginTop: "0 !important" }}
                value={this.state.nameEn}
                placeholder="Ex: 2018-2019"
                onChange={this.handleChangeInput("nameEn")}
                margin="normal"
              />
            </Grid>
            <FormControl margin="dense" required fullWidth>
              <InputLabel htmlFor="description">
                {formatMessage({ id: "ItemTypeDecaissement_5" })}
              </InputLabel>
              <Input
                id="description"
                required
                name="description"
                autoComplete="description"
                value={this.state.description}
                onChange={this.handleChangeInput("description")}
                multiline
                rows="4"
                variant="filled"
              />
            </FormControl>
          </div>
          <div className="layout vertical">
            <span className="flex" />
            <Button
              onClick={this.add}
              variant="contained"
              size="medium"
              className={classes.buttonPrincipale}
            >
              {formatMessage({ id: "AjouterRoleView_add" })}
            </Button>
          </div>
        </div>
      </main>
    );
  }
}
AjouterTypeGroupeEnseignementView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(
    injectIntl(AjouterTypeGroupeEnseignementView)
  )
);
