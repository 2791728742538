
import IconButton from "@material-ui/core/IconButton";

import withStyles from "@material-ui/core/styles/withStyles";

import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  isObjectValueEquals,
  userProfileUrl,
  appProperties
} from "../../../components/LdUtils";
import { withSnackbar } from "notistack";
import { injectIntl, FormattedMessage, FormattedDate, FormattedTime } from "react-intl";
import ProgressiveImage from 'react-progressive-image';
import Avatar from "@material-ui/core/Avatar";

const styles = theme => ({
  // main: {
  //   width: "400px",
  //   padding: "15px",
  //   display: "block", // Fix IE 11 issue.
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
  //     width: 450,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   }
  // },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    // minWidth: "135px",
    padding: "0px !important"
  },
  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  checked: {
    color: "var(--paper-green-500)"
  },
  unChecked: {
    color: "var(--paper-grey-500)"
  },
  contentImage: {
    width: "40px"
  },
  bigAvatar: {
    // backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "75px !important",
    height: "75px !important",
    borderRadius: '0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius: "50%",
    // backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  date: {
    fontSize: "12px",
    fontWeight: "400"
  },

});

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
    &nbsp;{intl.formatMessage({ id: "code_a" })} &nbsp;
    <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));

class ItemDerogation extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      // name: this.props.row.name || "",
      // responsable: this.props.row.responsable || "",
      // fonction: this.props.row.fonction || "",
      // photo: this.props.row.photo || "",
      // pathPhoto: this.props.row.pathPhoto || "",

      datas: [],
      dialogOpened: false,
      inProgress: false,
      position: this.props.position,

      errors: {}
    };
    // this.edit = this.edit.bind(this);
    // this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    // this.handleEnabled = this.handleEnabled.bind(this);
    // this.handleDisabled = this.handleDisabled.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    // this.newImageUploaded = this.newImageUploaded.bind(this);


  }


  componentDidUpdate(prevProps) {
    //var self = this;
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        // self.setState({
        //   name: self.props.row.name || "",
        //   responsable: self.props.row.responsable || "",
        //   fonction: self.props.row.fonction || "",
        //   photo: self.props.row.photo || "",
        //   pathPhoto: self.props.row.pathPhoto || "",

        // });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }


  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/signature/delete-derogation?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error deleted.");
      });
  };


  getRowColor(string) {
    var generate = require("string-to-color");
    return generate(string);
  }

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div style={{ padding: '5px 0', borderBottom: '1px solid #EAEAEA' }} className="layout horizontal center justified">
          <div className="layout horizontal center">
            <ProgressiveImage src={row.signature.pathPhoto} placeholder={""}>
              {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
            </ProgressiveImage>

            <div style={{ paddingLeft: "10px" }}>
              <div className="layout horizontal center">
                <span style={{ paddingRight: "10px", fontSize: "13px", }}>
                  {formatMessage({ id: "ItemDerogation_1" })}{": "}
                  <b style={{ color: 'var(--app-primary-color)', fontWeight: '600' }}>{row.signature.name}</b>
                </span>
              </div>

              <div className="layout horizontal center">
                <span style={{ paddingRight: "10px", fontSize: "13px", }}>
                  {formatMessage({ id: "ItemDerogation_2" })}{": "}
                  <b style={{ fontWeight: '600', color: this.getRowColor(row.documentType) }}>
                    {row.documentType === "carte_scolaire" && formatMessage({ id: "AjouterDerogationSignatureView_3" })}
                    {row.documentType === "bulletin" && formatMessage({ id: "AjouterDerogationSignatureView_4" })}
                  </b>
                </span>{" "}{" "}

              </div>
              <div className="layout horizontal center">
                <span style={{ paddingRight: "10px", fontSize: "13px", }}>
                  {formatMessage({ id: "AjouterDerogationSignatureView_7" })}{": "}
                  <b style={{ fontWeight: '600' }}> <PostDate date={row.dateFrom} /></b>
                </span>{" "}{" "}
                <span style={{ paddingRight: "10px", fontSize: "13px", }}>
                  {formatMessage({ id: "AjouterDerogationSignatureView_8" })}{": "}
                  <b style={{ fontWeight: '600' }}> <PostDate date={row.dateTo} /></b>
                </span>{" "}{" "}

              </div>
              <div style={{ fontWeight: "400" }} className={classes.date}>

                <FormattedMessage
                  id="date_user"
                  values={{
                    date: <PostDate date={row.created} />,
                    user: (
                      <a
                        style={{
                          paddingLeft: "5px",
                          fontWeight: "500",
                          fontSize: "11px"
                        }}
                        className="no-underline capitalize"
                        title={row.creator.name}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={userProfileUrl(row.creator)}
                      >
                        {row.creator.name}
                      </a>
                    )
                  }}
                />
              </div>
            </div>
          </div>





          <div className={classes.actions}>

            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_del" })}
              aria-label="Delete"
              onClick={this.handleDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>


          </div>
        </div>

      </React.Fragment>
    );
  }
}
ItemDerogation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(ItemDerogation))
);
