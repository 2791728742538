
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import LdSelect from "../../../components/LdSelect";
import {
  isObjectValueEquals,
  userProfileUrl,
  appProperties, deleteClassInProps
} from "../../../components/LdUtils";
import { withSnackbar } from "notistack";
import { injectIntl, FormattedMessage, FormattedDate } from "react-intl";
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import AjouterDesignView from "../design/AjouterDesignView";


const styles = theme => ({
  // main: {
  //   width: "400px",
  //   padding: "15px",
  //   display: "block", // Fix IE 11 issue.
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
  //     width: 450,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   }
  // },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "135px",
    padding: "0px !important"
  },
  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  checked: {
    color: "var(--paper-green-500)"
  },
  unChecked: {
    color: "var(--paper-grey-500)"
  },
  contentImage: {
    width: "40px"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  date: {
    fontSize: "12px",
    fontWeight: "400"
  },
});

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
  </span>
));

class ItemCategorieEtablissement extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      code: this.props.row.code || "",
      name: this.props.row.name || "",
      description: this.props.row.description || "",


      cycle: this.props.row.cycle != null ? Object.assign({
        id: this.props.row.cycle.id,
        value: this.props.row.cycle.id,
        label: this.props.row.cycle.name,
        option: this.props.row.cycle

      }, this.props.row.cycle) : null,

      groupeEnseignement: this.props.row.groupeEnseignement != null ? Object.assign({
        id: this.props.row.groupeEnseignement.id,
        value: this.props.row.groupeEnseignement.id,
        label: this.props.row.groupeEnseignement.name,
        option: this.props.row.groupeEnseignement

      }, this.props.row.groupeEnseignement) : null,

      systemeEnseignement: this.props.row.systemeEnseignement != null ? Object.assign({
        id: this.props.row.systemeEnseignement.id,
        value: this.props.row.systemeEnseignement.id,
        label: this.props.row.systemeEnseignement.name,
        option: this.props.row.systemeEnseignement

      }, this.props.row.systemeEnseignement) : null,

      datas: [],
      dialogOpened: false,
      inProgress: false,
      position: this.props.position,

      errors: {},
      openDialogDesign: false,

    };
    this.edit = this.edit.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleSystemeEnseignementChange = this.handleSystemeEnseignementChange.bind(this);
    this.handleGroupeEnseignementChange = this.handleGroupeEnseignementChange.bind(this);
    this.handleCycleChange = this.handleCycleChange.bind(this);
    this.handleCloseDesign = this.handleCloseDesign.bind(this);
    this.handleClickOpenDesign = this.handleClickOpenDesign.bind(this);
  }
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          code: self.props.row.code || "",
          name: self.props.row.name || "",
          description: self.props.row.description || "",
          mode: !!self.props.row.mode,
          maxValue: self.props.row.maxValue,
          maxPercent: self.props.row.maxPercent,
          cycle: self.props.row.cycle != null ? Object.assign({
            id: self.props.row.cycle.id,
            value: self.props.row.cycle.id,
            label: self.props.row.cycle.name,
            option: self.props.row.cycle

          }, self.props.row.cycle) : null,
         
              
          groupeEnseignement: self.props.row.groupeEnseignement != null ? Object.assign({
            id: self.props.row.groupeEnseignement.id,
            value: self.props.row.groupeEnseignement.id,
            label: self.props.row.groupeEnseignement.name,
            option: self.props.row.groupeEnseignement

          }, self.props.row.groupeEnseignement) : null,

          systemeEnseignement: self.props.row.systemeEnseignement != null ? Object.assign({
            id: self.props.row.systemeEnseignement.id,
            value: self.props.row.systemeEnseignement.id,
            label: self.props.row.systemeEnseignement.name,
            option: self.props.row.systemeEnseignement

          }, self.props.row.systemeEnseignement) : null,

        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleClickOpenDesign = () => {
    this.setState({ openDialogDesign: true });
  };
  handleCloseDesign = () => {
    this.setState({ openDialogDesign: false });
  };
  handleSystemeEnseignementChange(type) {
    this.setState({
      systemeEnseignement: type
    });
  }
  handleGroupeEnseignementChange(type) {
    this.setState({
      groupeEnseignement: type
    });
  }
  handleCycleChange(type) {
    this.setState({
      cycle: type
    });
  }

  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/delete?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error deleted.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/set-status?status=1&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error enabled.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/set-status?status=0&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error disabled.");
      });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };


  edit() {


    var errors = {};
    this.setState({ errors: errors });


    if (this.state.code == null || this.state.code === "") errors["code"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.name == null || this.state.name === "") errors["name"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.description == null || this.state.description === "") errors["description"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.cycle == null) errors["cycle"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.groupeEnseignement == null) errors["groupeEnseignement"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.systemeEnseignement == null) errors["systemeEnseignement"] = this.props.intl.formatMessage({ id: "InputError_1" });


    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("id", this.props.row.id);
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("systeme_enseignement_id", this.state.systemeEnseignement != null ? this.state.systemeEnseignement.id : "");
    formData.append("groupe_enseignement_id", this.state.groupeEnseignement != null ? this.state.groupeEnseignement.id : "");
    formData.append("cycle_id", this.state.cycle != null ? this.state.cycle.id : "");

    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/rest-categorie-etablissement/edit", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.props.onUpdateItem("update", {
            position: self.state.position,
            row: data.row
          });
          self.handleClose();
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemCategorieEtablissement_2" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemCategorieEtablissement_3" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterCategorieEtablissementView_13" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterCategorieEtablissementView_12" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -4) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterCategorieEtablissementView_11" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error updated.");
      });
  }

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div style={{ padding: '5px 0', borderBottom: '1px solid #EAEAEA' }} className="layout horizontal center justified">
          <div>
            <div className="layout horizontal center">
              <span style={{ paddingRight: "10px", fontSize: "13px" }}>
                {formatMessage({ id: "ItemTypeDecaissement_3" })}{" "}
                <b>{row.code}</b>
              </span>
              <span style={{ paddingRight: "10px", fontSize: "13px", }}>
                {formatMessage({ id: "ItemTypeDecaissement_4" })}{" "}
                <b style={{ color: 'var(--app-primary-color)', fontWeight: '600' }}>{row.name}</b>
              </span>

            </div>
            <div>
              <span style={{ fontSize: "13px" }}>
                {formatMessage({ id: "ItemTypeDecaissement_5" })}&nbsp;
                      {row.description}
              </span>
            </div>
            <div classNAme="layout horizontal center">
              <span style={{ paddingRight: "10px", fontSize: "13px" }}>
                {formatMessage({ id: "AjouterCategorieEtablissementView_5" })}{" "}
                <b>{row.cycle != null ? row.cycle.name : ""}</b>
              </span>
              <span style={{ paddingRight: "10px", fontSize: "13px" }}>
                {formatMessage({ id: "AjouterCategorieEtablissementView_8" })}{" "}
                <b>{row.groupeEnseignement != null ? row.groupeEnseignement.name : ""}</b>
              </span>
              <span style={{ paddingRight: "10px", fontSize: "13px" }}>
                {formatMessage({ id: "AjouterCategorieEtablissementView_7" })}{" "}
                <b>{row.systemeEnseignement != null ? row.systemeEnseignement.name : ""}</b>
              </span>
            </div>
            <div style={{ fontWeight: "400" }} className={classes.date}>

              <FormattedMessage
                id="date_user"
                values={{
                  date: <PostDate date={row.created} />,
                  user: (
                    <a
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "500",
                        fontSize: "11px"
                      }}
                      className="no-underline capitalize"
                      title={row.creator.name}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={userProfileUrl(row.creator)}
                    >
                      {row.creator.name}
                    </a>
                  )
                }}
              />
            </div>
          </div>






          <div className={classes.actions}>
            <IconButton
              title={formatMessage({ id: "LdEtablissementMenu_63" })}
              color="primary"
              aria-label="design"
              onClick={this.handleClickOpenDesign}
            >
              <FormatShapesIcon fontSize="small" />
            </IconButton>
             <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
              color="primary"
              aria-label="Edit"
              onClick={this.handleClickOpen}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_del" })}
              aria-label="Delete"
              onClick={this.handleDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>

            {this.props.row.status ? (
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                classes={{ root: classes.disable }}
                aria-label="Edit"
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            ) : (
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                  classes={{ root: classes.enable }}
                  aria-label="Delete"
                  onClick={this.handleEnabled}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
          </div>
        </div>

          <Dialog
              open={this.state.openDialogDesign}
              onClose={this.handleCloseDesign}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth={'lg'}
            >
              <DialogTitle className={classes.pageTitle} id="form-dialog-title">
                {formatMessage({ id: "ItemCategorieEtablissement_4" },{category:row.name})}
              </DialogTitle>
              <DialogContent>

                <div className={classes.pageSubtitle}>
                  {formatMessage({ id: "AjouterDesignView_1" })}
                </div>

                <CssBaseline />
                <Paper square elevation={0} className={classes.paper}>
                  <AjouterDesignView
                   categorieEtablissement={row}
                   isCategory={true}
                    {...deleteClassInProps(this.props)}
                  />
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleCloseDesign}
                  variant="contained"
                  size="medium"
                  className={classes.buttonGreyWhite}
                >
                  {formatMessage({ id: "AjouterIndisciplineView_10" })}
                </Button>
               
              </DialogActions>
            </Dialog>


        {
          this.state.dialogOpened && (
            <Dialog
              open={this.state.dialogOpened}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle className={classes.pageTitle} id="form-dialog-title">
                {formatMessage({ id: "ItemCategorieEtablissement_1" })} {row.code}
              </DialogTitle>
              <DialogContent>
                {this.state.inProgress ? (
                  <div className={classes.progress}>
                    <LinearProgress variant="query" />
                  </div>
                ) : null}
                <div className={classes.pageSubtitle}>
                  {" "}
                  {formatMessage({ id: "ItemTypeDecaissement_2" })}
                </div>

                <CssBaseline />
                <Paper square elevation={0} className={classes.paper}>


                  <div className={classes.root}>
                    <FormControl margin="dense" required fullWidth>
                      <InputLabel htmlFor="code">
                        {formatMessage({ id: "AjouterCategorieEtablissementView_3" })}
                      </InputLabel>
                      <Input
                        error={this.state.errors.code}
                        defaultValue={this.state.code}
                        id="code"
                        required
                        name="code"
                        autoComplete="code"
                        onChange={this.handleChangeInput("code")}
                        autoFocus
                      />
                      <FormHelperText>{this.state.errors.code ? <span className="error_field">{this.state.errors.code}</span> : ""}</FormHelperText>
                    </FormControl>
                    <FormControl margin="dense" required fullWidth>
                      <InputLabel htmlFor="name">
                        {formatMessage({ id: "AjouterCategorieEtablissementView_4" })}
                      </InputLabel>
                      <Input
                        error={this.state.errors.name}
                        defaultValue={this.state.name}
                        name="name"
                        type="text"
                        id="name"
                        required
                        onChange={this.handleChangeInput("name")}
                        autoComplete="current-name"
                      />
                      <FormHelperText>{this.state.errors.name ? <span className="error_field">{this.state.errors.name}</span> : ""}</FormHelperText>

                    </FormControl>

                    <FormControl margin="dense" required fullWidth>
                      <InputLabel htmlFor="description">
                        {formatMessage({ id: "AjouterCategorieEtablissementView_6" })}
                      </InputLabel>
                      <Input
                        error={this.state.errors.description}
                        id="description"

                        defaultValue={this.state.description}
                        required
                        name="description"
                        autoComplete="description"
                        onChange={this.handleChangeInput("description")}
                        multiline
                        rows="4"
                        variant="filled"
                      />
                      <FormHelperText>{this.state.errors.description ? <span className="error_field">{this.state.errors.description}</span> : ""}</FormHelperText>

                    </FormControl>


                    <br />
                    <div style={{ width: "100%" }}>
                      <LdSelect
                        label={formatMessage({ id: "AjouterCategorieEtablissementView_5" })}
                        handleValue={this.handleCycleChange}
                        firstLoad

                        defaultValue={{
                          id: this.state.cycle && this.state.cycle != null ? this.state.cycle.id : '',
                          value: this.state.cycle && this.state.cycle != null ? this.state.cycle.id : '',
                          label: this.state.cycle && this.state.cycle != null ? this.state.cycle.name : '',
                          option: this.state.cycle
                        }}
                        baseUrl={
                          appProperties.basepathServer +
                          "/rest-cycle/list?" +
                          "&limit=-1"
                        }
                        {...deleteClassInProps(this.props)}
                      />
                      <FormHelperText>{this.state.errors.cycle ? <span className="error_field">{this.state.errors.cycle}</span> : ""}</FormHelperText>
                    </div>
                    <br />

                    <div style={{ width: "100%" }}>
                      <LdSelect
                        label={formatMessage({ id: "AjouterCategorieEtablissementView_8" })}
                        handleValue={this.handleGroupeEnseignementChange}
                        firstLoad
                        isSearchable
                        defaultValue={{
                          id: this.state.groupeEnseignement && this.state.groupeEnseignement != null ? this.state.groupeEnseignement.id : '',
                          value: this.state.groupeEnseignement && this.state.groupeEnseignement != null ? this.state.groupeEnseignement.id : '',
                          label: this.state.groupeEnseignement && this.state.groupeEnseignement != null ? this.state.groupeEnseignement.name : '',
                          option: this.state.groupeEnseignement
                        }}
                        baseUrl={
                          appProperties.basepathServer +
                          "/rest-groupe-enseignement/list?" +
                          "&limit=-1"
                        }
                        {...deleteClassInProps(this.props)}
                      />
                      <FormHelperText>{this.state.errors.groupeEnseignement ? <span className="error_field">{this.state.errors.groupeEnseignement}</span> : ""}</FormHelperText>
                    </div>
                    <br />
                    <div style={{ width: "100%" }}>
                      <LdSelect
                        label={formatMessage({ id: "AjouterCategorieEtablissementView_7" })}
                        handleValue={this.handleSystemeEnseignementChange}
                        firstLoad
                        isSearchable

                        defaultValue={{
                          id: this.state.systemeEnseignement && this.state.systemeEnseignement != null ? this.state.systemeEnseignement.id : '',
                          value: this.state.systemeEnseignement && this.state.systemeEnseignement != null ? this.state.systemeEnseignement.id : '',
                          label: this.state.systemeEnseignement && this.state.systemeEnseignement != null ? this.state.systemeEnseignement.name : '',
                          option: this.state.systemeEnseignement
                        }}
                        baseUrl={
                          appProperties.basepathServer +
                          "/rest-systeme-enseignement/list?" +
                          "&limit=-1"
                        }
                        {...deleteClassInProps(this.props)}
                      />
                      <FormHelperText>{this.state.errors.systemeEnseigngment ? <span className="error_field">{this.state.errors.systemeEnseigngment}</span> : ""}</FormHelperText>
                    </div>









                  </div>
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  variant="contained"
                  size="medium"
                  className={classes.buttonGreyWhite}
                >
                  {formatMessage({ id: "AjouterTypeAlertView_13" })}
                </Button>
                <Button
                  onClick={this.edit}
                  variant="contained"
                  size="medium"
                  className={classes.buttonPrincipale}
                >
                  {formatMessage({ id: "AjouterTypeAlertView_15" })}
                </Button>
              </DialogActions>
            </Dialog>
          )
        }
      </React.Fragment>
    );
  }
}
ItemCategorieEtablissement.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(ItemCategorieEtablissement))
);
