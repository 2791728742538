import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import LdSelectUser from "../../../components/LdSelectUser";
// import LdSelectStatic from "../../../components/LdSelectStatic";
import LdSelect from '../../../components/LdSelect';

import { appProperties, fireWithObject } from "../../../components/LdUtils";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withSnackbar } from "notistack";
import LdImageUploader from "../../../components/LdImageUploader";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  root: {
    width: "100%",
    // marginTop: "30px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  dialog: {
    minHeight: "300px"
  }
});

class AjouterPersonnelView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      selectedOption: null,
      redirect: false,
      etablissementId: this.props.etablissementId,
      datas: [],
      user: null,
      dialogOpened: false,
      fonction: null,
      inProgress: false,
      controlled: true,
      isEnseignant: false,
      isPermanent: false,
      title: "",
      matricule: "",
      photo: "",
      pathPhoto: "",
      errors: {},
    };
    this.add = this.add.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValue = this.handleValue.bind(this);
    this.handleValueFonction = this.handleValueFonction.bind(this);
    this.handeCheck = this.handeCheck.bind(this);
    this.newImageUploaded = this.newImageUploaded.bind(this);

  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };
  newImageUploaded(name, path) {
    // console.log(" new image uplaoded in Ajouter");
    // console.log(name);
    // console.log(path);
    this.setState({ photo: name, pathPhoto: path });
  }
  handleValue(item) {
    this.setState({ user: item });
    if (item && item != null) {
      var pathAvatar = item.photoId != null && item.photoId !== "" ? item.photo : "";
      var avatar = item.photoId;
      this.newImageUploaded(avatar, pathAvatar)
    } else {
      this.newImageUploaded("", "");
    }
  }
  handleValueFonction(fonction) {
    this.setState({ fonction: fonction });
  }
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };
  handeCheck = (name, value) => e => {
    var val = {};
    val[name] = !!value ? false : true;
    this.setState(val);
  };
  add() {
    var errors = {};
    this.setState({ errors: errors });

    if (this.state.matricule == null || this.state.matricule === "") errors["matricule"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.title == null || this.state.title === "") errors["title"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.user == null) errors["user"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.fonction == null) errors["fonction"] = this.props.intl.formatMessage({ id: "InputError_1" });


    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("id", this.state.user != null ? this.state.user.value : "");
    formData.append("fonction", this.state.fonction != null ? this.state.fonction.value : "");
    formData.append("matricule", this.state.matricule);
    formData.append("title", this.state.title);
    formData.append("photo", this.state.photo);
    formData.append("is_enseignant", !!this.state.isEnseignant ? 1 : 0);
    formData.append("is_permanent", !!this.state.isEnseignant && !!this.state.isPermanent ? 1 : 0);
    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/manager/etablissement/add-enseignant?etablissement=" +
      this.state.etablissementId,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //self.handleClose();

          fireWithObject(document, "new-personnel", { value: data.row });
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterPersonnelView_8" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterPersonnelView_9" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterPersonnelView_11" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    console.log(this.props.intl);
    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className={classes.buttonPrincipale}
          onClick={this.handleClickOpen}
        >
          {formatMessage({ id: "AjouterPersonnelView_1" })}
        </Button>
        <Dialog
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">
            {formatMessage({ id: "AjouterPersonnelView_2" })}
          </DialogTitle>
          <DialogContent>
            {this.state.inProgress ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {/* <div  className={classes.pageSubtitle} >Renseignez toutes les informations sur l'enseignat d'un etablissement </div> */}
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>
              {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}




              <div className={classes.root}>
                <FormControl margin="dense" required fullWidth>
                  <LdSelectUser
                    handleValue={this.handleValue}
                    isSearchable
                    label={formatMessage({ id: "AjouterPersonnelView_3" })}
                    baseUrl={
                      appProperties.basepathServer +
                      "/rest-user/search?type=1;2&detail=0&limit=-1&name="
                    }
                    {...deleteClassInProps(this.props)}
                  />
                  <FormHelperText>{this.state.errors.user ? <span className="error_field">{this.state.errors.user}</span> : ""}</FormHelperText>
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="matricule">
                    {formatMessage({ id: "ItemPersonnel_12" })}
                  </InputLabel>
                  <Input
                    error={this.state.errors.matricule}
                    id="matricule"
                    required
                    name="matricule"
                    autoComplete="matricule"
                    onChange={this.handleChangeInput("matricule")}
                    autoFocus
                  />
                  <FormHelperText>{this.state.errors.matricule ? <span className="error_field">{this.state.errors.matricule}</span> : ""}</FormHelperText>
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <div>

                    <LdSelect
                      baseUrl={
                        appProperties.basepathServer +
                        "/manager/etablissement/load-fonction-etablissement?language=fr"
                      }
                      label={formatMessage({
                        id: "AjouterPersonnelView_4"
                      })}
                      handleValue={this.handleValueFonction}
                      firstLoad isSearchable
                      {...deleteClassInProps(this.props)}
                    />
                  </div>
                  <FormHelperText>{this.state.errors.fonction ? <span className="error_field">{this.state.errors.fonction}</span> : ""}</FormHelperText>
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="title">
                    {formatMessage({ id: "AjouterPersonnelView_5" })}
                  </InputLabel>
                  <Input
                    error={this.state.errors.title}
                    id="title"
                    required
                    name="title"
                    autoComplete="title"
                    onChange={this.handleChangeInput("title")}
                    autoFocus
                  />
                  <FormHelperText>{this.state.errors.title ? <span className="error_field">{this.state.errors.title}</span> : ""}</FormHelperText>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={this.state.isEnseignant}
                      ckecked={this.state.isEnseignant}
                      color="primary"
                      onChange={this.handeCheck('isEnseignant', this.state.isEnseignant)}
                    />
                  }
                  label={formatMessage({ id: "AjouterPersonnelView_6" })}
                />
                {this.state.isEnseignant &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={this.state.isPermanent}
                        ckecked={this.state.isPermanent}
                        color="primary"
                        onChange={this.handeCheck('isPermanent', this.state.isPermanent)}
                      />
                    }
                    label={formatMessage({ id: "AjouterPersonnelView_7" })}
                  />}
                <div style={{ marginTop: '30px' }}>
                  <InputLabel htmlFor="title">
                    {formatMessage({ id: "AjouterPersonnelView_10" })}
                  </InputLabel>
                  <div>
                    <LdImageUploader maxSize={500000} name={this.state.photo} hasParent={this.state.photo != null && this.state.photo !== ""} path={this.state.pathPhoto}
                      label={formatMessage({ id: "AjouterEleveView_84" })} onImageUploaded={this.newImageUploaded}  {...deleteClassInProps(this.props)} />
                  </div>
                </div>
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}
            >
              {formatMessage({ id: "AjouterTypeAlertView_13" })}
            </Button>
            <Button
              onClick={this.add}
              variant="contained"
              size="medium"
              disabled={this.state.user == null}
              className={classes.buttonPrincipale}
            >
              {formatMessage({ id: "AjouterTypeAlertView_15" })}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterPersonnelView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(
  injectIntl(AjouterPersonnelView)
));
