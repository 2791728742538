import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  appProperties,
  computeNbMinutes,
  gmDate,
  deleteClassInProps,
  isObjectValueEquals
} from "../../../components/LdUtils";
import PlanningDayClasse from "./PlanningDayClasse";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: "white"
    // width:'100%',
  },
  root: {
    width: "100%"
    // marginTop: '30px',
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
  bigAvatar: {
    backgroundColor: "lightgray",
    border: "2px solid white",
    width: "32px !important",
    height: "32px !important",
    borderRadius: "50% !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,

    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    margin: "2px 5px",
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: "12px",
    fontWeight: "500",
    transform: "translate(14px, -6px) scale(1)"
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: "7.5px 23px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */,
  backButton: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

class AddPlanningClasseView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      classes: [],
      classe: "---",
      inProgress: false,
      controlled: true,

      horaires: [],
      lastHour: "",
      minDuree: -1,
      categorie: this.props.categorie,
    };
    this.handleReset = this.handleReset.bind(this);
    this.handleSelectClass = this.handleSelectClass.bind(this);
    this.doUpdateGradual = this.doUpdateGradual.bind(this);
  }
  doUpdateGradual(lastHour, minDuree, jour) {
    var self = this;
    //setTimeout(()=>{
    if (lastHour >= this.state.lastHour) {
      self.setState({ lastHour: lastHour, minDuree: minDuree }, () => {
        var lasHourMin = computeNbMinutes(lastHour);
        var hourInit = computeNbMinutes("07:30");
        var array = [];
        var newDate = 0;
        do {
          newDate = hourInit + minDuree;
          array.push([gmDate(hourInit * 60), gmDate(newDate * 60)]);
          hourInit = newDate;
        } while (newDate < lasHourMin);
        console.log("array gm date");
        console.log(array);
        self.setState({ horaires: array });
      });
    }
    //},jour*500);
  }

  handleReset = () => {
    this.setState({
      classe: "---",
      classes: []
    });
  };
  // handleSelectClass(value){
  //   this.setState({user:value},()=>{

  //   });
  // }
  handleSelectClass = event => {
    this.setState({ classe: event.target.value }, () => {
      //self.setCurrentPage(1);
    });
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();
        self.listClasses();
        //self.listPeriodes();
      });
    }

    if (!isObjectValueEquals(this.props.categorie, prevProps.categorie)) {
      this.setState({ categorie: this.props.categorie }, () => {
        self.handleReset();
        self.listEnseignants();
        //self.listPeriodes();
      });
    }
  }
  componentDidMount() {
    this.listClasses();
  }

  listClasses() {
    let self = this;
    this.setState({ classes: [], progressClasse: 1 });
    var url =
      appProperties.basepathServer +
      "/rest-enseignant/list-grouped?etablissement_id=" +
      this.props.etablissement.id +
      "&limit=-1";
    if (this.props.annee != null) url += "&annee_id=" + this.props.annee.id;
    if (this.props.categorie && this.props.categorie !== "---" && this.props.categorie != null) url += ("&categorie_etablissement_id=" + this.props.categorie.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          var enseignants = [];
          (data.datas || []).forEach(e => {
            enseignants = enseignants.concat(e.enseignants);
          });
          self.setState({
            progressClasse: 0,
            classes: self.formatData({ enseignants: enseignants })
          });
        } else {
          self.setState({ classes: [], progressClasse: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ classes: [], progressClasse: 3 });

        console.log("There was an error listed type de frais.");
      });
  }

  formatData(input) {
    if (null == input) return [];
    if (
      input.enseignants &&
      Array.isArray(input.enseignants) &&
      0 < input.enseignants.length
    ) {
      var enseignants = input.enseignants;
      var output = Object.assign({}, input);
      delete output.enseignants;
      var exist = false;
      var myReduce = enseignants.reduce(
        (accumulator, currentValue, currentIndex, array) => {
          // console.log(accumulator);
          exist = accumulator.reduce((acc, cur, ind, arr) => {
            return acc
              ? acc
              : null != cur &&
              null != currentValue.classeEtablissement &&
              cur.id === currentValue.classeEtablissement.id;
          }, false);
          return exist
            ? accumulator
            : accumulator.concat(
              currentValue.classeEtablissement
                ? currentValue.classeEtablissement
                : null
            );
        },
        []
      );
      var contents = [];
      var mat = null;
      console.log("myReduce ", myReduce);
      var myMap = myReduce.map((classe, index, array) => {
        contents = enseignants.reduce(
          (accumulator, currentValue, currentIndex, array) => {
            if (
              null != classe &&
              null != currentValue.classeEtablissement &&
              classe.id === currentValue.classeEtablissement.id
            ) {
              mat = Object.assign({}, currentValue);
              delete mat.classeEtablissement;
              return accumulator.concat(mat);
            } else return accumulator;
          },
          []
        );
        classe["enseignants"] = contents;
        return classe;
      });
      //output["classes"] = myMap;

      return myMap;
    }
    //input["classes"] = [];
    return [];
  }

  getJoursLabel(jour, formatMessage) {
    var array = [
      formatMessage({ id: "AjouterBandeView_10" }),
      formatMessage({ id: "AjouterBandeView_11" }),
      formatMessage({ id: "AjouterBandeView_12" }),
      formatMessage({ id: "AjouterBandeView_13" }),
      formatMessage({ id: "AjouterBandeView_14" }),
      formatMessage({ id: "AjouterBandeView_15" }),
      formatMessage({ id: "AjouterBandeView_16" })
    ];
    if (jour >= 1 && jour <= 7) {
      return array[parseInt(jour) - 1];
    } else return "";
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        <div className="layout horizontal center justified">
          <div className={classes.titleBlock}>
            <div className={classes.pageTitle} variant="div" component="div">
              {formatMessage({ id: "AddPlanningClasseView_3" })}
            </div>
            <div className={classes.pageSubtitle} variant="div" component="div">
              {formatMessage({ id: "AddPlanningClasseView_4" })}
            </div>
          </div>
          <div>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={
                  this.state.classe === "---" || this.state.classe == null
                    ? formatMessage({ id: "AddPlanningClasseView_1" })
                    : formatMessage({ id: "ItemClasseAnnee_23" }) +

                    this.state.classe.name
                }
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.classe}
                onChange={this.handleSelectClass}
                input={
                  <OutlinedInput
                    labelWidth={0}
                    name="Selectionner une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "AddPlanningClasseView_2" })}
                </MenuItem>
                {this.state.classes.map((row, index) => (
                  <MenuItem
                    title={row.name}
                    key={index}
                    value={row}
                  >
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <br />
        <div>
          {this.state.classe !== "---" && this.state.classe != null && (
            <div
              style={{
                borderTop: "1px solid #EAEAEA",
                borderRight: "1px solid #EAEAEA"
              }}
              className="layout horizontal"
            >
              <div
                style={{ borderLeft: "1px solid #EAEAEA" }}
                className="layout vertical"
              >
                <div
                  style={{
                    fontWeight: "400",
                    padding: "10px",
                    fontSize: "12px",
                    borderBottom: "1px solid #EAEAEA"
                  }}
                >
                  {formatMessage({ id: "ListBandeView_2" })}{" "}
                </div>
                {this.state.horaires.map((row, index) => (
                  <div
                    key={row.id}
                    className="layout vertical center-center"
                    style={{
                      fontWeight: "400",
                      fontSize: "10px",
                      marginBottom: "-1px",
                      textAlign: "center",
                      height: (this.state.minDuree * 6) / 5,
                      borderBottom: "1px solid #EAEAEA"
                    }}
                  >
                    <span>{row[0] + " - " + row[1]}</span>
                  </div>
                ))}
              </div>
              {[1, 2, 3, 4, 5, 6, 7].map((i, index) => (
                <div
                  key={"jour_" + i}
                  className="flex"
                  style={{ borderLeft: "1px solid #EAEAEA" }}
                >
                  {/* <div className="layout horizontal center-center" style={{height:'35px',borderBottom:'1px solid #EAEAEA',textAlign:'center',}}>
                      <span style={{fontWeight:'100',color:'#b1b1b1'}}>{this.getJoursLabel(i)}</span>
                    </div> */}
                  <div>
                    <PlanningDayClasse
                      onMaxGradual={this.doUpdateGradual}
                      jour={i}
                      classe={this.state.classe}
                      annee={this.props.annee}
                      etablissement={this.props.etablissement}
                      emploiTempsId={this.props.emploiTempsId}
                      {...deleteClassInProps(this.props)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {(this.state.classe === "---" || this.state.classe == null) && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "AddPlanningClasseView_5" })}
              </div>
            </div>
          )}
        </div>
      </main>
    );
  }
}
AddPlanningClasseView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AddPlanningClasseView))
);
