import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AjouterTypeAlertView from '../view/type-alert/AjouterTypeAlertView';
import ListTypeAlertView from '../view/type-alert/ListTypeAlertView';
import { deleteClassInProps} from '../../components/LdUtils';
import { injectIntl } from "react-intl";

const useStyles = theme => ({
    root: {
      display: "block", // Fix IE 11 issue.
      paddingRight:'10px'
    },
    titleBlock:{
      textAlign:'left',
      // paddingLeft:"10px",
    },
    pageTitle:{
      fontSize: '21px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    pageSubtitle:{
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 400,
      color:theme.color.primaryColor
    }
    
  });

class TypeAlertController extends Component{

    constructor(props){
        super(props);
        this.state={
          etablissement: this.props.etablissement

        }

        //this.ajouterReseauEtablissViewRef = React.createRef();
        //this.ListReseauViewRef = React.createRef();

    }
    
    render(){
        const {classes} = this.props;
        const {formatMessage}=this.props.intl;
        return (
            <div className = {classes.root}>
               <div  className={classes.titleBlock}>
                  <div className={classes.pageTitle} variant="div" component="div">
                    {formatMessage({id:"TypeAlertController_Title"})}
                  </div>
                  <div className={classes.pageSubtitle} variant="div" component="div">
                  {formatMessage({id:"TypeAlertController_SubTitle"})}
                  </div>
                  <AjouterTypeAlertView etablissementId={this.state.etablissement.id}  {...deleteClassInProps(this.props)} />
               </div>
               <ListTypeAlertView etablissement={this.state.etablissement}  {...deleteClassInProps(this.props)} />
           
            </div>
        );
    }
    
}

TypeAlertController.propTypes = {
    classes: PropTypes.object.isRequired
 };
  
export default withStyles(useStyles)(injectIntl(TypeAlertController));