import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import LdHomeMenu from "../components/LdHomeMenu";
import ReseauController from "./controller/ReseauController";
import ManagerController from "./controller/ManagerController";
import BulletinController from "./controller/BulletinController";
import EtablissementController from "./controller/EtablissementController";
import HistoriqueRechargeController from "./controller/HistoriqueRechargeController";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Page404 from "../components/Page404";
import { deleteClassInProps} from '../components/LdUtils';
import PageConstruction from '../components/PageConstruction';
import StickyBox from "react-sticky-box";

const useStyles = theme => ({
  rootLogged: {
    flexGrow: 1,
    padding:'15px'
  }
});

class ManagerIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [],
      inProgress: false
    };
  }
  componentDidMount() {
    // const values = queryString.parse(this.props.location.search);
    // console.log(values.filter); // "top"
    // console.log(values.origin); // "im"
  }

  render() {
    const { classes } = this.props;
    // const values = queryString.parse(this.props.location.search);
    // console.log(values.email); // "im"
    // var emailUser = values.email;
    return (
      <div className={classes.rootLogged}>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <StickyBox offsetTop={15} offsetBottom={20}>
              <LdHomeMenu   {...deleteClassInProps(this.props)} />
            </StickyBox>
          </Grid>
          <Grid item xs={9}>
            <Switch>
            <Route
                  exact
                  path="/administration/dashboard"
                  render={props => <PageConstruction message="Tableau de bord en construction"/>}
              />
              <Route
                exact
                path="/administration/bulletins"
                render={props => <BulletinController    />}
              />
              <Route
                exact
                path="/administration/reseaux"
                render={props => <ReseauController    />}
              />
              <Route
                exact
                path="/administration/managers"
                render={props => <ManagerController {...props} />}
              />
              <Route
                exact
                path="/administration/etablissements"
                render={props => <EtablissementController {...props} />}
              />
              
              <Route
                exact
                path="/administration/recharges-sms"
                render={props => <HistoriqueRechargeController {...props} />}
              />  
              <Route
                render={props => {
                  return <Page404 {...props} />;
                }}
              />
            </Switch>
          </Grid>
        </Grid>
      </div>
    );
  }
}
ManagerIndex.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(ManagerIndex);
