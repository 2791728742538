import React, { Component } from "react";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

import PropTypes from "prop-types";

import LinearProgress from "@material-ui/core/LinearProgress";

import Grid from "@material-ui/core/Grid";

import { isObjectValueEquals } from "../../../components/LdUtils";

import FormControl from "@material-ui/core/FormControl";

import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withSnackbar } from "notistack";
import TimePicker from "react-time-picker";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: "white"
  },
  root: {
    width: "100%",
    marginTop: "30px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 130,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  selectMenu: {
    padding: "7.5px 14px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */
});

class AjouterBandeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      //etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      user: null,
      jours: [],
      nbPeriode: 1,
      inProgress: false,
      controlled: true,
      startTime: "07:30",
      endTime: "07:31"
    };
    this.add = this.add.bind(this);
    this.handleChangeJour = this.handleChangeJour.bind(this);
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
    this.handleNbPeriode = this.handleNbPeriode.bind(this);
  }
  handleChangeJour = value => event => {
    var index = this.state.jours.indexOf(value);
    if (index < 0) {
      this.setState(state => {
        state.jours = [value, ...state.jours].sort();
        return state;
      });
    } else {
      var array = [...this.state.jours];
      array.splice(index, 1);
      this.setState(state => {
        state.jours = array.sort();
        return state;
      });
    }
  };

  handleChangeStartTime(time) {
    this.setState({ startTime: time });
  }
  handleChangeEndTime(time) {
    this.setState({ endTime: time });
  }
  handleNbPeriode = event => {
    var value = event.target.value;
    if (value === "" || value < 1) {
      value = 1;
      event.target.value = value;
    }
    if (event.target.value === "") value = 1;
    this.setState({ nbPeriode: value });
  };

  componentDidUpdate(prevProps) {
    //var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        //self.refreshList("");
      });
    }
  }
  componentDidMount() {}

  add() {
    if (
      this.state.nbPeriode === 0 ||
      this.state.startTime === null ||
      this.state.startTime === "" ||
      this.state.endTime === null ||
      this.state.endTime === "" ||
      this.state.jours.length === 0
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    let self = this;
    this.setState({ inProgress: true });
    var url =
      appProperties.basepathServer +
      "/manager/config-emploi-temps/add-bande?nb_periode=" +
      this.state.nbPeriode;
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;
    if (this.state.startTime != null) url += "&from=" + this.state.startTime;
    if (this.state.endTime != null) url += "&to=" + this.state.endTime;
    if (this.state.jours.length > 0)
      url += "&jours=" + this.state.jours.join("-");

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, "new-bande", { value: data.item });
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterBandeView_1" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterBandeView_2" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterBandeView_3" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -4) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterBandeView_4" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -10) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterBandeView_5" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -11) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterBandeView_6" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        <div className={classes.pageTitle} id="form-dialog-title">
          {formatMessage({ id: "AjouterBandeView_7" })}
        </div>

        <div className={classes.pageSubtitle}>
          {formatMessage({ id: "AjouterBandeView_8" })}
        </div>
        {this.state.inProgress ? (
          <div className={classes.progress}>
            <LinearProgress variant="query" />
          </div>
        ) : null}
        {!this.state.controlled ? (
          <div className={classes.error}>
            {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
          </div>
        ) : null}
        <br />
        <div className="layout horizontal center">
          <div style={{ paddingRight: "10px" }} className="flex">
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                {formatMessage({ id: "AjouterBandeView_9" })}
              </FormLabel>
              <FormGroup className="layout horizontal center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("1") > -1}
                      onChange={this.handleChangeJour("1")}
                      value="1"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_10" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("2") > -1}
                      onChange={this.handleChangeJour("2")}
                      value="2"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_11" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("3") > -1}
                      onChange={this.handleChangeJour("3")}
                      value="3"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_12" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("4") > -1}
                      onChange={this.handleChangeJour("4")}
                      value="4"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_13" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("5") > -1}
                      onChange={this.handleChangeJour("5")}
                      value="5"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_14" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("6") > -1}
                      onChange={this.handleChangeJour("6")}
                      value="6"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_15" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("7") > -1}
                      onChange={this.handleChangeJour("7")}
                      value="7"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_16" })}
                />
              </FormGroup>
              {/* <FormHelperText>Be careful</FormHelperText> */}
            </FormControl>
            <br />

            <Grid
              container
              className={classes.grid}
              justify="space-evenly"
              alignItems="center"
            >
              <div
                className="layout horizontal center"
                style={{ paddingLeft: "10px", paddingRight: "20px" }}
              >
                <span style={{ fontSize: "13px", paddingRight: "5px" }}>
                  {formatMessage({ id: "AjouterBandeView_17" })}
                </span>
                <TimePicker
                  onChange={this.handleChangeStartTime}
                  value={this.state.startTime}
                  format="HH:mm"
                  // maxTime={this.state.endTime}
                />
              </div>

              <div
                className="layout horizontal center"
                style={{ paddingRight: "20px" }}
              >
                <span style={{ fontSize: "13px", paddingRight: "5px" }}>
                  {formatMessage({ id: "AjouterBandeView_18" })}
                </span>
                <TimePicker
                  onChange={this.handleChangeEndTime}
                  value={this.state.endTime}
                  format="HH:mm"
                  minTime={this.state.startTime}
                />
              </div>
              <div className="flex">
                <TextField
                  id="nbPeriode"
                  type="number"
                  label={formatMessage({ id: "AjouterBandeView_19" })}
                  style={{ width: "300px", marginLeft: "5%" }}
                  value={this.state.nbPeriode}
                  placeholder="Ex: 2"
                  onChange={this.handleNbPeriode}
                  margin="normal"
                  InputProps={{
                    inputProps: { min: 1, max: 10 }
                  }}
                />
              </div>
            </Grid>
          </div>
        </div>
        <div
          style={{ padding: "10px" }}
          className="layout horizontal end-justified"
        >
          <div />
          <Button
            onClick={this.add}
            variant="contained"
            size="medium"
            className={classes.buttonPrincipale}
          >
            {formatMessage({ id: "AjouterRoleView_add" })}
          </Button>
        </div>
      </main>
    );
  }
}
AjouterBandeView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AjouterBandeView))
);
