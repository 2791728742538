import React, { Component } from "react";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

import PropTypes from "prop-types";

import LinearProgress from "@material-ui/core/LinearProgress";

import Grid from "@material-ui/core/Grid";

import LdSelectUser from "../../../components/LdSelectUser";
import { isObjectValueEquals } from "../../../components/LdUtils";
import { deleteClassInProps } from "../../../components/LdUtils";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: "white"
  },
  root: {
    width: "100%",
    marginTop: "30px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 130,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  selectMenu: {
    padding: "7.5px 14px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */
});

class AjouterPreferenceView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      // etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      user: null,
      jours: [],
      priorite: 0,
      inProgress: false,
      controlled: true
    };
    this.add = this.add.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleChangePriorite = this.handleChangePriorite.bind(this);
    this.handleChangeJour = this.handleChangeJour.bind(this);
  }
  handleChangeJour = value => event => {
    var index = this.state.jours.indexOf(value);
    if (index < 0) {
      this.setState(state => {
        state.jours = [value, ...state.jours];
        return state;
      });
    } else {
      var array = [...this.state.jours];
      array.splice(index, 1);
      this.setState(state => {
        state.jours = array;
        return state;
      });
    }
  };
  handleChangePriorite = event => {
    this.setState({ priorite: event.target.value });
  };
  componentDidUpdate(prevProps) {
    //var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        //self.refreshList("");
      });
    }
  }
  componentDidMount() {}

  handleChangeUser(item) {
    this.setState({ user: item ? item : null });
  }

  add() {
    if (this.state.user == null || this.state.jours.length === 0) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    let self = this;
    this.setState({ inProgress: true });
    var url =
      appProperties.basepathServer +
      "/manager/config-emploi-temps/set-config-enseignant?priorite=" +
      this.state.priorite;
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;
    if (this.state.user != null) url += "&user=" + this.state.user.id;
    if (this.state.jours.length > 0)
      url += "&jours=" + this.state.jours.join("-");

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, "new-preference", { value: data.item });
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterPreferenceView_4" }),
            {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        <div className={classes.pageTitle} id="form-dialog-title">
          {formatMessage({ id: "AjouterPreferenceView_1" })}{" "}
        </div>

        <div className={classes.pageSubtitle}>
          {formatMessage({ id: "AjouterPreferenceView_2" })}{" "}
        </div>
        {this.state.inProgress ? (
          <div className={classes.progress}>
            <LinearProgress variant="query" />
          </div>
        ) : null}
        {!this.state.controlled ? (
          <div className={classes.error}>
            {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
          </div>
        ) : null}
        <div className="layout horizontal center">
          <div style={{ paddingRight: "10px" }} className="flex">
            <Grid
              container
              className={classes.grid}
              justify="space-evenly"
              alignItems="center"
            >
              <div className="flex">
                <LdSelectUser
                  label={formatMessage({ id: "AjouterPreferenceView_3" })}
                  handleValue={this.handleChangeUser}
                  isSearchable
                  baseUrl={
                    appProperties.basepathServer +
                    "/rest-user/search?type=1&detail=0&limit=100&name="
                  }
                  {...deleteClassInProps(this.props)}
                />
              </div>

              <FormControl
                style={{ height: "35px" }}
                variant="outlined"
                className={classes.formControl}
              >
                <Select
                  title={formatMessage({ id: "ItemPreference_3" })}
                  classes={{
                    selectMenu: classes.selectMenu,
                    root: classes.selectRoot
                  }}
                  value={this.state.priorite}
                  onChange={this.handleChangePriorite}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="priorite"
                      id="outlined-annee-simple"
                      classes={{ input: classes.outlinedInput }}
                    />
                  }
                >
                  <MenuItem value={0}>
                    {formatMessage({ id: "ItemPreference_4" })}
                  </MenuItem>
                  <MenuItem value={1}>
                    {formatMessage({ id: "ItemPreference_5" })}
                  </MenuItem>
                  <MenuItem value={2}>
                    {formatMessage({ id: "ItemPreference_6" })}
                  </MenuItem>
                  <MenuItem value={3}>
                    {formatMessage({ id: "ItemPreference_7" })}
                  </MenuItem>
                  <MenuItem value={4}>
                    {formatMessage({ id: "ItemPreference_8" })}
                  </MenuItem>
                  <MenuItem value={5}>
                    {formatMessage({ id: "ItemPreference_9" })}
                  </MenuItem>
                  <MenuItem value={6}>
                    {formatMessage({ id: "ItemPreference_10" })}
                  </MenuItem>
                  <MenuItem value={7}>
                    {formatMessage({ id: "ItemPreference_11" })}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <br />
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">
                {formatMessage({ id: "ItemPreference_2" })}
              </FormLabel>
              <FormGroup className="layout horizontal center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("1") > -1}
                      onChange={this.handleChangeJour("1")}
                      value="1"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_10" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("2") > -1}
                      onChange={this.handleChangeJour("2")}
                      value="2"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_11" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("3") > -1}
                      onChange={this.handleChangeJour("3")}
                      value="3"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_12" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("4") > -1}
                      onChange={this.handleChangeJour("4")}
                      value="4"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_13" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("5") > -1}
                      onChange={this.handleChangeJour("5")}
                      value="5"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_14" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("6") > -1}
                      onChange={this.handleChangeJour("6")}
                      value="6"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_15" })}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.jours.indexOf("7") > -1}
                      onChange={this.handleChangeJour("7")}
                      value="7"
                    />
                  }
                  label={formatMessage({ id: "AjouterBandeView_16" })}
                />
              </FormGroup>
              {/* <FormHelperText>Be careful</FormHelperText> */}
            </FormControl>
          </div>
        </div>
        <div
          style={{ padding: "10px" }}
          className="layout horizontal end-justified"
        >
          <Button
            onClick={this.add}
            variant="contained"
            size="medium"
            className={classes.buttonPrincipale}
          >
            {formatMessage({ id: "AjouterRoleView_add" })}
          </Button>
        </div>
      </main>
    );
  }
}
AjouterPreferenceView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AjouterPreferenceView))
);
