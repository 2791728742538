import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import { deleteClassInProps } from "../../../components/LdUtils";
import LdSelectTypeFrais from "../../../components/LdSelectTypeFrais";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  root: {
    width: '100%'
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },
  label: {
    padding: "5px"
  }
});

class AjouterTypeReductionView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)


    this.state = {
      code: "",
      name: "",
      description: "",
      mode: false,
      year: "",
      maxPercent: "",
      maxValue: "",
      typeFrais: null,

      etablissementId: this.props.etablissementId,
      annee: this.props.annee,

      dialogOpened: false,
      inProgress: false,
      controlled: true,
      errors: {}
    };
    this.add = this.add.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMode = this.handleMode.bind(this);
    this.handleTypeFraisChange = this.handleTypeFraisChange.bind(this);
  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };
  handleTypeFraisChange(type) {
    this.setState({
      typeFrais: type
    });
  }
  handleMode(event) {
    this.setState({
      mode: !this.state.mode
    });
  }



  add() {
    var errors = {};
    this.setState({ errors: errors });

    if (this.state.typeFrais == null) errors["type_frais"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.code == null || this.state.code === "") errors["code"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.name == null || this.state.name === "") errors["name"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.description == null || this.state.description === "") errors["description"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (!!this.state.mode) {
      if (this.state.maxPercent == null || this.state.maxPercent === "" || parseFloat(this.state.maxPercent) > 100) errors["maxPercent"] = this.props.intl.formatMessage({ id: "InputError_2" }, { min: 0, max: 100 });
    } else {
      var amount = this.state.typeFrais != null && this.state.typeFrais.option && this.state.typeFrais.option != null ? this.state.typeFrais.option.amount : 0;
      if (this.state.maxValue == null || this.state.maxValue === "" || parseFloat(this.state.maxValue) > amount) errors["maxValue"] = this.props.intl.formatMessage({ id: "InputError_2" }, { min: 0, max: amount });;
    }

    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    var formData = new FormData(); // Currently empty
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("type_id", this.state.typeFrais != null ? this.state.typeFrais.id : "");
    // formData.append("delay", this.state.delay);
    formData.append("mode", !!this.state.mode ? 1 : 0);
    if (this.state.mode) formData.append("max_percent", parseFloat(this.state.maxPercent));
    if (!this.state.mode) formData.append("max_value", parseFloat(this.state.maxValue));


    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/manager/type-reduction/add?etablissement=" +
      this.props.etablissementId + "&annee=" + this.props.annee.id,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, "new-type-reduction", { value: data.row });

          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterTypeReductionView_12" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterTypeReductionView_10" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterTypeReductionView_11" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className={classes.buttonPrincipale}
          onClick={this.handleClickOpen}
        >
          {formatMessage({ id: "AjouterTypeReductionView_1" })}
        </Button>
        <Dialog
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"md"}
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">
            {formatMessage({ id: "AjouterTypeReductionView_1" })}
          </DialogTitle>
          <DialogContent>
            {this.state.inProgress ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {!this.state.controlled ? (
              <div className={classes.error}>
                {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
              </div>
            ) : null}
            <div className={classes.pageSubtitle}>
              {formatMessage({ id: "AjouterTypeReductionView_2" })}
            </div>
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>


              {/* {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null} */}

              <div className={classes.root}>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="code">
                    {formatMessage({ id: "AjouterTypeReductionView_3" })}
                  </InputLabel>
                  <Input
                    error={this.state.errors.code}
                    id="code"
                    required
                    name="code"
                    autoComplete="code"
                    onChange={this.handleChangeInput("code")}
                    autoFocus
                  />
                  <FormHelperText>{this.state.errors.code ? <span className="error_field">{this.state.errors.code}</span> : ""}</FormHelperText>
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="name">
                    {formatMessage({ id: "AjouterTypeReductionView_4" })}
                  </InputLabel>
                  <Input
                    error={this.state.errors.name}
                    name="name"
                    type="text"
                    id="name"
                    required
                    onChange={this.handleChangeInput("name")}
                    autoComplete="current-name"
                  />
                  <FormHelperText>{this.state.errors.name ? <span className="error_field">{this.state.errors.name}</span> : ""}</FormHelperText>

                </FormControl>
                <br />

                <br />

                <div style={{ width: "100%" }}>
                  <LdSelectTypeFrais
                    label={formatMessage({ id: "AjouterTypeReductionView_5" })}
                    handleValue={this.handleTypeFraisChange}
                    firstLoad
                    isSearchable
                    baseUrl={
                      appProperties.basepathServer +
                      "/manager/type-frais/list?etablissement=" +
                      this.state.etablissementId +
                      "&limit=-1&annee=" + (this.props.annee != null && this.props.annee !== "" ? this.props.annee.id : "")
                    }
                    {...deleteClassInProps(this.props)}
                  />
                  <FormHelperText>{this.state.errors.type_frais ? <span className="error_field">{this.state.errors.type_frais}</span> : ""}</FormHelperText>
                </div>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="description">
                    {formatMessage({ id: "AjouterTypeReductionView_6" })}
                  </InputLabel>
                  <Input
                    error={this.state.errors.description}
                    id="description"
                    required
                    name="description"
                    autoComplete="description"
                    onChange={this.handleChangeInput("description")}
                    multiline
                    rows="4"
                    variant="filled"
                  />
                  <FormHelperText>{this.state.errors.name ? <span className="error_field">{this.state.errors.name}</span> : ""}</FormHelperText>

                </FormControl>


                <br />
                <br />

                <FormControlLabel
                  control={
                    <Checkbox
                      value={this.state.mode}
                      color="primary"
                      onChange={this.handleMode}
                    />
                  }
                  label={formatMessage({ id: "AjouterTypeReductionView_7" })}
                />

                {!this.state.mode ? (
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="maxValue">
                      {formatMessage({ id: "AjouterTypeReductionView_9" })}
                    </InputLabel>
                    <Input

                      error={this.state.errors.maxValue}
                      name="maxValue"
                      type="number"
                      id="maxValue"
                      placeholder="EX: 15000"
                      required
                      value={this.state.maxValue}
                      onChange={this.handleChangeInput("maxValue")}
                      autoComplete="amount"
                    />
                    <FormHelperText>{this.state.errors.maxValue ? <span className="error_field">{this.state.errors.maxValue}</span> : ""}</FormHelperText>

                  </FormControl>
                ) : <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="maxPercent">
                      {formatMessage({ id: "AjouterTypeReductionView_8" })}
                    </InputLabel>
                    <Input
                      error={this.state.errors.maxPercent}
                      name="maxPercent"
                      type="number"
                      id="maxPercent"
                      placeholder="EX: 50"
                      required
                      value={this.state.maxPercent}
                      onChange={this.handleChangeInput("maxPercent")}
                      autoComplete="amount"
                    />
                    <FormHelperText>{this.state.errors.maxPercent ? <span className="error_field">{this.state.errors.maxPercent}</span> : ""}</FormHelperText>

                  </FormControl>}
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}
            >
              {formatMessage({ id: "AjouterTypeAlertView_13" })}
            </Button>
            <Button
              onClick={this.add}
              variant="contained"
              size="medium"
              disabled={this.state.inProgress}

              className={classes.buttonPrincipale}
            >
              {formatMessage({ id: "AjouterTypeAlertView_15" })}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterTypeReductionView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AjouterTypeReductionView))
);
