import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import AutorisationEtablissement from "./AutorisationEtablissement";
import { appProperties, convertAccessToString, defaultCover } from "./LdUtils";
import Collapse from "@material-ui/core/Collapse";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Truncate from "react-truncate-html";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ReactPlayer from "react-player";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  pageTop: {
    backgroundColor: "white",
    backgroundImage:
      "url(//www.gstatic.com/mobilesdk/171006_mobilesdk/project_list_header.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 0",
    // height: "448px",
    height: "400px",
    marginBottom: "-90px",
    textAlign: "left"
  },
  topo: {
    paddingTop: "100px",
    paddingLeft: "200px"
    // width: "530px"
  },
  topoTitle: {
    color: "rgba(0,0,0,0.87)",
    fontSize: "30px",
    fontWeight: "400",
    lineHeight: "40px",
    margin: " 0 0 8px 0",
    paddingLeft: "20px"
  },
  topoSubtitle: {
    color: "rgba(0,0,0,0.54)",
    fontSize: "16px",
    lineHeight: "24px",
    paddingLeft: "20px",
    paddingBottom: "20px"
  },
  topoLink: {
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "8px",
    paddingRight: "20px",
    paddingLeft: "20px",
    position: "relative"
  },
  projectTitle: {
    paddingLeft: "200px",
    width: "53%"
    // width: "530px"
  },
  projectZone: {
    paddingLeft: "220px",
    paddingRight: "50px"
  },
  projectProgress: {
    paddingLeft: "16%",
    paddingRight: "28%",
    marginTop: "45px"
  },
  projectProgressPaper: {
    height: "160px",
    borderRadius: "10px",
    display: "flex"
  },
  fabProgress: {
    color: green[500],
    margin: "auto"
  },
  request: {
    width: "300px",
    height: "200px",
    margin: "5px 10px 5px 0",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F5F5F5",
      textDecoration: "none !important"
    },
    textDecoration: "none !important"
  },

  projectSubtitle: {
    color: "rgba(0,0,0,0.54)",
    fontSize: "16px",
    lineHeight: "24px",
    paddingLeft: "20px"
  },
  addAction: {
    color: "var(--app-primary-color)"
  },
  addText: {
    color: "var(--app-primary-color)",
    fontSize: "17px",
    fontWeight: "500"
  },
  itemName: {
    padding: "15px 15px 0",
    fontSize: "14px",
    fontWeight: "600",
    color: "var(--app-text-color1)",
    textDecoration: "none  !important"
  },
  itemAccess: {
    padding: "10px 15px 0",
    fontSize: "12px",
    fontWeight: "500",
    color: "var(--app-text-color)",
    textDecoration: "none !important"
  },
  bigAvatar: {
    width: "100px !important",
    height: "100px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  titleBarContainer: {
    padding: "15px",
    borderRadius: "0 !important",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  collapsedPaper: {
    padding: "20px",
    borderRadius: "0 !important",
    backgroundColor: "white"
  },
  card: {
    width: "345px",
    height: "293px",
    margin: "5px 10px 5px 0",
    textDecoration: "none"
  },
  media: {
    height: "calc( 100% - 78px)",
    width: "100%",
    backgroundSize: "auto"
  },
  carAction: {
    //height:'40px'
    height: "40px",
    padding: "0 8px !important"
  },
  cardArea: {
    height: "250px"
  },
  cardContent: {
    height: "78px",
    padding: "5px 16px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  dialogRoot: {
    padding: "15px 0 !important"
  },

  buttonOutlined: {
    textTransform: "none",
    margin: "0 5px "
  }
});

class HomeConnected extends Component {
  constructor(props) {
    super(props);
    this.autorisationRef = React.createRef();
    this.state = {
      datas: [],
      progress: false,
      redirect: false,
      collapsed: false,
      openVideo: false
    };
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpenVideo = this.handleOpenVideo.bind(this);
    this.handleCloseVideo = this.handleCloseVideo.bind(this);
  }
  handleOpenVideo(event) {
    this.setState({ openVideo: true });
  }

  handleCloseVideo(event) {
    this.setState({ openVideo: false });
  }
  handleClickOpen = () => {
    this.setState(state => ({ collapsed: !state.collapsed }));
    //console.log(this.autorisationRef);
    //this.autorisationRef.doOpenDialog();
    // this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    // this.setState({ dialogOpened: false });
    //this.autorisationRef.doCloseDialog();
  };

  componentDidMount() {
    this.listEtablissements();
  }
  listEtablissements() {
    let self = this;
    this.setState({ progress: true, datas: [] });
    fetch(
      appProperties.basepathServer + "/manager/etablissement/list-allowed",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.setState({ progress: false, datas: data.datas });
        } else if (data.result_code === -1000) {
          const redirectUrl =
            window.location.pathname + "" + window.location.search;
          if (this.props && this.props.history && this.props.history != null)
            this.props.history.push({
              pathname: "/user/login",
              search: "?redirect_url=" + encodeURIComponent(redirectUrl),
              state: {}
            });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ progress: false, datas: [] });
        console.log("There was an error.");
      });
  }

  render() {
    const { classes } = this.props;
    const { collapsed } = this.state;
    const { formatMessage } = this.props.intl;
    // if (this.state.redirect){ return <Redirect to={"/user/login?redirect_url="+decodeURIComponent(window.location.pathname + window.location.search)}  />}
    return (
      <div>
        <Dialog
          fullWidth
          maxWidth={"lg"}
          open={this.state.openVideo}
          onClose={this.handleCloseVideo}
          aria-labelledby="max-width-dialog-title"
          classes={{ paper: classes.dialogRoot }}
        >
          <DialogContent>
            <ReactPlayer
              width="100%"
              height="460px"
              config={{
                youtube: {
                  playerVars: { showinfo: 1 }
                }
              }}
              controls={true}
              url=""
              playing={this.state.openVideo}
            />
            {/* https://www.youtube.com/watch?v=66mzO0AAsD8&feature=youtu.be */}
          </DialogContent>
        </Dialog>
        <Paper className={classes.pageTop} elevation={0}>
          <div style={{ width: "53%" }}>
            <div className={classes.topo}>
              <Typography
                className={classes.topoTitle}
                variant="h5"
                component="h3"
              >
                {formatMessage({ id: "HomeConnected_Welcome" })}
              </Typography>
              <Typography className={classes.topoSubtitle} component="p">
                {formatMessage({ id: "HomeConnected_Intro" })}
              </Typography>
              {/* <div>
              <Link className={classes.topoLink} to="/?auth=0">
                En savoir plus
              </Link>
              <Link className={classes.topoLink} to="/?auth=0">
                Documentation
              </Link>
              <Link className={classes.topoLink} to="/?auth=0">
                Assisance
              </Link>
            </div> */}
              <div
                style={{ paddingLeft: "15px", marginTop: "20px" }}
                className="layout horizontal center"
              >
                <Button
                  size="large"
                  onClick={this.handleOpenVideo}
                  color="primary"
                  className={classes.buttonOutlined}
                >
                  <PlayCircleFilledIcon
                    style={{ marginRight: "5px" }}
                    fontSize="inherit"
                  />{" "}
                  {formatMessage({ id: "Look_vid" })}
                </Button>
              </div>
            </div>
          </div>
        </Paper>

        {this.state.progress ? (
          <div className={classes.projectProgress}>
            <Paper className={classes.projectProgressPaper} elevation={0}>
              {this.state.progress && (
                <CircularProgress
                  thickness={1}
                  size={130}
                  className={classes.fabProgress}
                />
              )}
            </Paper>
          </div>
        ) : (
          <div>
            <div className={classes.projectTitle}>
              <Typography className={classes.projectSubtitle} component="p">
                {formatMessage({ id: "HomeConnected_Schools" })}
              </Typography>
            </div>
            <div className={classes.projectZone}>
              <div className="layout horizontal center wrap">
                {this.state.datas.map((row, index) => (
                  <Card
                    title={
                      formatMessage({ id: "HomeConnected_Schools_go" }) +
                      row.name
                    }
                    elevation={0}
                    component={Link}
                    to={"/page/" + row.code + "/dashboard"}
                    key={index + 1}
                    className={classes.card}
                  >
                    <CardActionArea classes={{ root: classes.cardArea }}>
                      <CardMedia
                        className={classes.media}
                        image={
                          !row.path_photo || row.path_photo == null
                            ? defaultCover
                            : row.path_photo
                        }
                      />
                      <CardContent classes={{ root: classes.cardContent }}>
                        <div>
                          <Typography
                            style={{ fontSize: "13px", fontWeight: "600" }}
                            gutterBottom
                            variant="h5"
                            component="h4"
                          >
                            {row.name}
                          </Typography>
                          <Typography
                            style={{ fontSize: "11px" }}
                            component="p"
                          >
                            <Truncate
                              lines={2}
                              portrait={4}
                              breakWord={true}
                              responsive={true}
                              dangerouslySetInnerHTML={{
                                __html: convertAccessToString(
                                  row.accesses,
                                  formatMessage
                                )
                              }}
                            />
                          </Typography>
                        </div>
                      </CardContent>
                    </CardActionArea>
                    <CardActions className={classes.carAction}>
                      <Button
                        style={{ textTransform: "none" }}
                        size="small"
                        color="primary"
                      >
                        {formatMessage({ id: "HomeConnected_Schools_goBtn" })}
                      </Button>
                    </CardActions>
                  </Card>
                ))}
              </div>
              <Paper
                style={{ marginTop: "20px", marginBottom: "20px" }}
                elevation={0}
              >
                <Paper
                  className={classes.titleBarContainer}
                  onClick={this.handleClickOpen}
                  elevation={0}
                >
                  <div className="layout horizontal center">
                    <span className={classes.addAction}>
                      <AddIcon style={{ fontSize: 50 }} />
                    </span>
                    <div className={classes.titleBlock}>
                      <div
                        className={classes.pageTitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "HomeConnected_newSch_Manag" })}
                      </div>
                      <div
                        className={classes.pageSubtitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "HomeConnected_newSch_Manag_go" })}
                      </div>
                    </div>
                  </div>
                </Paper>
                <Collapse in={collapsed}>
                  <Paper elevation={0} className={classes.collapsedPaper}>
                    {collapsed ? (
                      <AutorisationEtablissement
                        onRef={ref => (this.autorisationRef = ref)}
                      />
                    ) : null}
                  </Paper>
                </Collapse>
              </Paper>
            </div>
          </div>
        )}
      </div>
    );
  }
}
HomeConnected.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(injectIntl(HomeConnected));
