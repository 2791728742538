  import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { fade } from '@material-ui/core/styles/colorManipulator';
import withStyles from "@material-ui/core/styles/withStyles";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import ProgressiveImage from 'react-progressive-image';
import { Link,withRouter } from "react-router-dom";
import Truncate from 'react-truncate-html';
import { appProperties, defaultAvatar, fireWithObject, isUserAdmin, userProfileUrl } from './LdUtils';
import { injectIntl } from 'react-intl';




const useStyles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: theme.color.primaryColor,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    /*width: '100%',*/
    [theme.breakpoints.up('sm')]: {
      width: 200,
    },
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
    [theme.breakpoints.up('lg')]: {
      width: 500,
    },
    [theme.breakpoints.up('xl')]: {
      width: 600,
    },
    backgroundColor: theme.color.primaryDark,
    borderRadius:"3px"
  },
  inputInput: {
    paddingTop: "11px !important",
    paddingRight: theme.spacing.unit,
    paddingBottom: "11px  !important",
    paddingLeft: theme.spacing.unit *7+"px !important",
    transition: theme.transitions.create('width'),
    /*width: '400px',*/
    color:"#FFF !important"

   
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  bigAvatar: {
    
    width: "33px !important",
    height:"33px !important",
  },
  itemImage:{
    width: "40px !important",
    height:"40px !important",
  },
  largeAvatar:{
    width: "70px !important",
    height:"70px !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding:2,
    borderRadius:"50%",
    backgroundColor:theme.color.primaryDark,
    border:"2px solid #FFF",
    cursor:"pointer"
  },
  button: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    fontSize:'18px',
    borderRadius:'0 !important',
    textTransform:'none',
    color: 'white !important',
   // backgroundColor: "blue !important", 
    backgroundColor: 'none !important',
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "&:active": {
      backgroundColor: theme.color.primaryDark
    }
  }, 
  buttonLabel: {
    textDecoration: 'none !important',
  },
  logo: {
    height: '40px',
    width: '150px'
  },
  menuPaper:{
    width:'300px',
    "& > ul": {
      paddingTop:'0 !important',
      paddingBottom:'0 !important'
    }
   
  },
  itemRoot:{
    borderBottom:'1px solid #EAEAEA',
    height:'auto'
  },
  itemSelected:{
    borderBottom:'1px solid #EAEAEA',
    '&:focus':{
      backgroundColor:'transparent'
    }
  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },
});

class ToolBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      anchorEl: null,
      open:false,
      progressLogout:0,
      lastAdminData:{},

    };
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
    this.logout = this.logout.bind(this);
    this.logout = this.logout.bind(this);
    this.goToLearndia = this.goToLearndia.bind(this);
    this.showAdmin = this.showAdmin.bind(this);

  }
  goToLearndia = event =>{
    this.setState({ anchorEl: null });
    window.open(appProperties.socialUrl,'_blank');

  };
  handleClickMenu = event => {
    this.setState({ anchorEl: event.currentTarget});
  };
  
  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };


  // handleCloseMenu = event => {
  //   console.log("Click close")
  //   var self = this;
  //   this.setState({ anchorEl: null,open:false },(state)=>{
  //      console.log(self.state.anchorEl,self.state.open);
  //   });
  // };
  componentDidMount() {
    document.addEventListener('storage-change',this._handleStorageChange.bind(this));
  }
  componentDidUnMount() {
    document.removeEventListener('storage-change',this._handleStorageChange.bind(this));
  }
  _handleStorageChange(event){
    if(event && event.detail){
      if(event.detail.key === "currentUser") this.setState({currentUser: event.detail.value});
    }
  }
  logout(event){
    let self = this;
    self.setState({progressLogout:1}); 
    fetch(appProperties.basepathServer+ '/rest-user/logout', {
        mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    
    })
    .then((response) => response.json())
    .then((data) => { 
        if(data.result_code === 1){
          self.setState({open:false,progressLogout:2});  
          self.handleCloseMenu();

          localStorage.setItem(appProperties.sessionId, null);
          localStorage.setItem(appProperties.key_user, null);
          fireWithObject(document,'storage-change',{key:appProperties.key_user,value:null});
          this.props.history.push({ pathname: "/user/login", search:"",state: {}});
        }else{
          self.setState({open:false,progressLogout:3});   
        }
        
    })
    .catch((err) => {
      console.log(err);
      self.setState({open:false,progressLogout:4}); 
    });
  }
  convertUrlToRoute(str) {
    var myRoute = {
      pathname: "",
      search: ""
    };
    if (str == null || str.trim() === "") return myRoute;
    try {
      var $split1 = str.split("?");
      if ($split1.length > 0) {
        myRoute.pathname = $split1[0];
        myRoute.search =  $split1.length > 1? decodeURIComponent($split1[1]):'';
      }
    } catch (error) {
      console.log("failed to parse url on reason: " + error);
    }
    return myRoute;
  }
  showAdmin(event){
    if(this.props.location.pathname!=="/administration/dashboard"){ 

      this.setState({lastAdminData:{
        pathname: this.props.location.pathname,
        search: this.props.location.search,
      }});
      this.props.history.push({ pathname: '/administration/dashboard', search: '',state: {}});
    }else{
      if(this.state.lastAdminData == null || this.state.lastAdminData === {}){
        this.props.history.goBack();
      }else{
        this.props.history.push({ pathname: this.state.lastAdminData.pathname, search: this.state.lastAdminData.search,state: {}});
      }
    } 
   
  }

  getButton(currentUser){
    const { classes }= this.props;
    var { anchorEl } = this.state;
    const open = Boolean(anchorEl);


    const {formatMessage} = this.props.intl;
    if(currentUser != null ){
      return(
        <div className={classes.sectionDesktop}>
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit">
            <Badge badgeContent={125} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
             
          {   isUserAdmin(this.props.currentUser) 
            ? 
            <Button  onClick={this.showAdmin} className={classes.buttonLabel} classes={{ root: classes.button}}>
              Admin
            </Button>
            : null
          }
          <div className="layout horizontal center">
            <div   onClick={this.handleClickMenu}  aria-owns={open ? 'fade-menu' : undefined} aria-haspopup="true" className={classes.bigAvatarDiv}>   
              <ProgressiveImage src={currentUser && currentUser!=null?currentUser.photo:''} placeholder={ defaultAvatar }>
                {(src, loading) => (<Avatar  alt={currentUser && currentUser!=null?currentUser.lastname:''} src={src} className={classes.bigAvatar} />)}
              </ProgressiveImage>
                {/* <Avatar alt="Remy Sharp" src={defaultAvatar} className={classes.bigAvatar} /> */}

            </div> 
            <Menu 
                  classes={{paper:classes.menuPaper}}
                  id="fade-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={this.handleCloseMenu}
                  TransitionComponent={Fade}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                >
                  <MenuItem classes={{root:classes.itemRoot,gutters:classes.itemSelected}}>
                    <Grid component="div"  container justify="flex-start" wrap="nowrap" alignItems='center'>
                      <ProgressiveImage src={currentUser && currentUser!=null?currentUser.photo:''} placeholder={ defaultAvatar }>
                        {(src, loading) => (<Avatar  alt={currentUser && currentUser!=null?currentUser.lastname:''} src={src} className={classes.largeAvatar} />)}
                      </ProgressiveImage>
                      <div style={{padding:'0 15px'}}>
                        <div style={{lineHeight:'12px',fontSize:'12px',fontWeight:'600'}}>
                        <Truncate 
                                lines={1} 
                                portrait={4} 
                                breakWord={true} 
                                responsive={true} 
                                dangerouslySetInnerHTML={{
                                  __html: '<span>'+(currentUser && currentUser!=null?currentUser.name:'')+'</span>'
                                }}
                              />
                        
                         </div>
                        <div style={{lineHeight:'11px',fontSize:'11px',fontWeight:'400'}}>
                        <Truncate 
                                lines={1} 
                                portrait={4} 
                                breakWord={true} 
                                responsive={true} 
                                dangerouslySetInnerHTML={{
                                  __html: '<span>'+(currentUser && currentUser!=null?currentUser.email:'')+'</span>'
                                }}
                              />
                        </div>
                        <Grid style={{padding:'10px 0 0'}} component="div"  container justify="flex-start" alignItems='center'>
                          <a style={{textDecoration:'none'}} rel="noopener noreferrer"  target="_blank" href={userProfileUrl(currentUser)}>
                          <Button
                            variant="contained"
                            size="small"
                            className = {classes.buttonPrincipale}
                            >
                            {formatMessage({ id: "ToolBar_profil"})}
                          </Button></a>
                        </Grid>
                      </div>
                    </Grid>
                  </MenuItem>
                  <MenuItem  onClick={this.handleCloseMenu} title="Recharger" classes={{root:classes.itemRoot,gutters:classes.itemSelected}}>
                    <Grid component="div"  container justify="flex-start" alignItems='center'>
                      <Avatar  alt="Ld M"  src="/images/manifest/icon-512x512.png" className={classes.itemImage} />
                      <div style={{padding:'0 15px'}}>
                        <div style={{lineHeight:'15px',fontSize:'15px',fontWeight:'600'}}>Learndia Manager</div>
                        <div style={{lineHeight:'12px',fontSize:'12px',fontWeight:'400',marginTop:'5px'}}>{formatMessage({ id: "ToolBar_Item_1"})}</div>
                       
                      </div>
                    </Grid>
                  </MenuItem>
                  <MenuItem onClick={this.goToLearndia} title="Passer à Learndia Social" classes={{root:classes.itemRoot,gutters:classes.itemSelected}}>
                    <Grid component="div"  container justify="flex-start" alignItems='center'>
                      <Avatar  alt="Leandia"  src="/images/manifest/icon-512x512.png" className={classes.itemImage} />
                      <div style={{padding:'0 15px'}}>
                        <div style={{lineHeight:'15px',fontSize:'15px',fontWeight:'600'}}>Learndia</div>
                        <div style={{lineHeight:'12px',fontSize:'12px',fontWeight:'400',marginTop:'5px'}}>{formatMessage({ id: "ToolBar_Item_2"})}</div>
                      </div>
                    </Grid>
                  </MenuItem>
                  <MenuItem  classes={{root:classes.itemRoot,gutters:classes.itemSelected}}>
                    <Grid component="div"  container justify="flex-end" alignItems='center'>
                      {/* { this.state.progressLogout === 2
                        ? <Redirect
                          to={{
                            pathname: "/user/login",
                            search: "",
                            state: { }
                          }}
                        />
                        : null
                      } */}
                      { this.state.progressLogout === 1
                        ? 
                        <div style={{paddingRight:'15px'}}>
                          <CircularProgress thickness={1} size={25} className={classes.fabProgress} />
                        </div>
                        : null
                      }  
                      <Button onClick={this.logout} style={{textTransform:'none',borderRadius:'0 !important'}} variant="outlined" title={formatMessage({ id: "ToolBar_LogOutGo"})} size="small" className={classes.margin}>
                      {formatMessage({ id: "ToolBar_LogOut"})}
                      </Button>
                    </Grid>
                  </MenuItem>
                </Menu>
          </div> 
          
        </div>

      );
    }else{
      return (
        <div className={classes.sectionDesktop}>
          <Button component={Link} to="/user/login"  className={classes.buttonLabel} classes={{ root: classes.button}}>
          {formatMessage({ id: "ToolBar_LogIn"})}
          </Button>
          <a style={{textDecoration:'none'}} rel="noopener noreferrer"  target="_blank" href={appProperties.socialUrl+'/user/register'}>
            <Button
              className={classes.buttonLabel}
              classes={{ root: classes.button}}
              >
              {formatMessage({ id: "ToolBar_CreateA"})}
            </Button>
          </a>
          {/* <Button   className={classes.button}>
            Demander l'autorisation
          </Button> */}
        </div>
      );
    }
  }

  render(){
   const { classes } = this.props;
   const {formatMessage} = this.props.intl;
   return (

    <div className={classes.root}>
      <AppBar elevation={0} position="fixed">
        <Toolbar  className={classes.toolbar} >
          {/* <IconButton className={classes.menuButton} color="inherit" aria-label="Open drawer">
            <MenuIcon />
          </IconButton> */}
            <Link to="/">
              <Typography className={classes.title} variant="h4" color="inherit" noWrap>
                <img alt="" src="/images/icons/logo_white_gray.png" className={classes.logo}/>
              </Typography>
            </Link>
         
          <div className={classes.grow} />
          { this.props.currentUser !=null && false 
            ? <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={formatMessage({ id: "ToolBar_Search"})}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>
            : null
          }
          
         {this.getButton(this.props.currentUser)}
        </Toolbar>
      </AppBar>
     
    </div>

  );
  }
}
ToolBar.propTypes = {
  classes: PropTypes.object.isRequired,
 
  /*theme: PropTypes.object.isRequired,*/

};

export default withRouter(withSnackbar(withStyles(useStyles)(injectIntl(ToolBar))));
