import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from "@material-ui/core/Paper";
import Select from '@material-ui/core/Select';
import withStyles from "@material-ui/core/styles/withStyles";
import moment from 'moment';
import React, { Component } from "react";
import DateTimePicker from 'react-datetime-picker';
import LdPagination from "../../../components/LdPagination";
import { appProperties, deleteClassInProps, isObjectValueEquals } from '../../../components/LdUtils';
import ItemDemande from "./ItemDemande";
import { injectIntl } from "react-intl";





const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: '15px',
    marginTop: '15px',
    backgroundColor: 'white',
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },





  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */

  datePicker:{
    height:'35px',
    '& .react-datetime-picker__wrapper':{
      border:'1px dashed #AAAAAA',
      borderRadius:'5px'
    }
  },
});

class ListDemandeView extends Component {
  constructor(props) {
    super(props);
    const minDate = null;
    const maxDate = null;
    this.state = {
      datas: [],
      types: [],
      type: "---", //No change to null. it is used to fix bug in select. 
      statut: "",
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,

      labelWidth: 0,
      minDate: minDate,
      maxDate: maxDate,

    }
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this._handleNewDemande = this._handleNewDemande.bind(this);

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);

  }
  
  handleStartDateChange(date){
    var self = this;
    this.setState({
      minDate: date,
    }, (state) => {
      self.setCurrentPage(1);
    });
  }

  handleEndDateChange(date) {
    var self = this;
    this.setState({
      maxDate: date,
    }, (state) => {
      self.setCurrentPage(1);
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.setCurrentPage(1);
      });
    }
  }
  handleChangeType = event => {
    var self = this;
    this.setState({ type: (event.target.value) }, (state) => {
      self.setCurrentPage(1);
    });
  };

  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listDemande(this.state.currentPage);
  };

  _handleNewDemande = event =>{
       this.setState(state => {
             state.datas =  [event.detail.value, ...state.datas] ;
             return state;
        });
  };
  onUpdateItem = (mode, item) => {
    console.log("intercept", mode);
    console.log(item);
    var array = [...this.state.datas];
    if (mode === 'update') {
      array.splice(item.position, 1, item.row);
    } else if (mode === 'delete') {
      array.splice(item.position, 1);

    } else if (mode === 'enabled') {
      item.row.status = true;
      console.log('enabled', item.row.status);
      array.splice(item.position, 1, item.row);

    } else if (mode === 'disabled') {
      item.row.status = false;
      array.splice(item.position, 1, item.row);

    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  componentDidMount() {
    this.listTypes();
    this.listDemande(this.state.currentPage);
   document.addEventListener('new-demande',this._handleNewDemande.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener('new-demande',this._handleNewDemande.bind(this));
  }
  
  listTypes() {
    let self = this;
    this.setState({ types: [] });
    var url = appProperties.basepathServer + "/manager/type-decaissement/list?etablissement=" + this.state.etablissement.id + "&limit=1000&only_published=1";
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ types: data.datas });

        } else {
          self.setState({ types: [] });

        }

      })
      .catch((err) => {
        console.log(err);
        self.setState({ types: [] });
      });
  }

  listDemande(page) {

    let self = this;

    this.setState({ datas: [], progress: 1 });
    var url = appProperties.basepathServer + '/manager/decaissement/list-demandes?etablissement=' + this.state.etablissement.id + '&limit=10&page=' + page;
    if (this.state.type && this.state.type !== "---" && this.state.type != null) url += ("&type=" + this.state.type.id);
    if (this.props.annee && this.props.annee !== "---" && this.props.annee  != null) url += ("&annee=" + this.props.annee.id);
    if (this.state.minDate  && this.state.minDate != null)url += ("&from=" + moment(this.state.minDate).format('YYYY-MM-DD HH:mm:ss'));
    if (this.state.maxDate  && this.state.maxDate != null)url += ("&to=" + moment(this.state.maxDate).format('YYYY-MM-DD HH:mm:ss'));
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ progress: 0, datas: data.datas, count: data.count, hasMore: data.hasMore, pages: data.pages });

        } else {
          self.setState({ datas: [], progress: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <Grid container className={classes.grid} justify="space-between" alignItems='center'>
          <div>
            <div className={classes.pageTitle} variant="div" component="div">
             {formatMessage({ id: "ListDemandeView_Title" })}
            </div>
            <div className={classes.pageSubtitle} variant="div" component="div">
              {formatMessage({ id: "ListDemandeView_subTitle" })}
            </div>
          </div>
          
        </Grid>
        <div className="layout horizontal end-justified center nowrap">
              <span style={{paddingRight:'5px'}} className={classes.label}>
                {formatMessage({ id: "ListAlertHistoryView_deb" })}
               </span>
              <DateTimePicker
                // maxDate={this.state.maxDate}
                locale='fr-FR'
                className={classes.datePicker}
                value={this.state.minDate}
                onChange={this.handleStartDateChange}
              />

              <span style={{ marginLeft: '20px',paddingRight:'5px' }} className={classes.label}>
               {formatMessage({ id: "ListAlertHistoryView_fin" })}
              </span>
              <DateTimePicker
                label={formatMessage({ id: "ListAlertHistoryView_1" })}
                className={classes.datePicker}
                locale='fr-FR'
                value={this.state.maxDate}
                minDate={this.state.minDate}
                onChange={this.handleEndDateChange}
              />
             <FormControl style={{ height: '35px',paddingRight:"10px" }} variant="outlined" className={classes.formControl}>

              <Select
                title={formatMessage({ id: "ListAlertView_1" })}
                displayEmpty
                classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                value={this.state.type}
                onChange={this.handleChangeType}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir un type"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              > <MenuItem value={'---'}>{formatMessage({ id: "ListAlertView_2" })}</MenuItem>
                {this.state.types.map((row, index) => (
                  <MenuItem key={index} value={row}>{row.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        {this.state.progress === 0 && (
          <React.Fragment>
            <Paper elevation={0} className={classes.root}>
              <div style={{ marginTop: '15px' }}>
                {this.state.datas.map((row, index) => (
                  <ItemDemande key={index} onUpdateItemDemande={this.onUpdateItem} etablissement={this.state.etablissement} position={index} row={row}  {...deleteClassInProps(this.props)} />
                ))}
              </div>
            </Paper>
            <LdPagination onShowPage={this.setCurrentPage} pages={this.state.pages} />
          </React.Fragment>)}

        {this.state.progress === 1 && (
          <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
            <div style={{ padding: '15px' }}>
              <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
            </div>
            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
              {formatMessage({ id: "ListAlertView_5" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 || (this.state.progress === 0 && this.state.datas.length === 0)) && (
          <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
              {formatMessage({ id: "ListDemandeView_1" })}
            </div>
          </div>
        )}
        {this.state.progress === 3 && (
          <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
            <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
              {formatMessage({ id: "ListAlertView_7" })}
                </div>
          </div>
        )}

      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(ListDemandeView));
