import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-date-picker";
import ProgressiveImage from "react-progressive-image";
import LdImageUploader from "../../../components/LdImageUploader";
import {
  isObjectValueEquals,
  appProperties,
  defaultAvatar
} from "../../../components/LdUtils";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";
import { deleteClassInProps } from "../../../components/LdUtils";
import LdSelectStatic from "../../../components/LdSelectStatic";
import LdSelectAnneeScolaire from "../../../components/LdSelectAnneeScolaire";

const styles = theme => ({
  // main: {
  //   width: "400px",
  //   padding: "15px",
  //   display: "block", // Fix IE 11 issue.
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
  //     width: 450,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   }
  // },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "135px",
    padding: "0px !important"
  },
  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  checked: {
    color: "var(--paper-green-500)"
  },
  unChecked: {
    color: "var(--paper-grey-500)"
  },
  contentImage: {
    width: "40px"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  }
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "600",
    fontSize: "15px",
    padding: "4px 7px 4px 7px",
  },
  body: {
    fontSize: 'small',
    padding: "4px 0px 4px 3px",

  }
}))(TableCell);

class ItemReseau extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      code: this.props.row.code || "",
      name: this.props.row.name || "",
      description: this.props.row.description || "",
      icone: this.props.row.image || "",
      amount: this.props.row.amount || null,
      // image: this.props.row.image || "",
      delay:
        this.props.row.delay == null || this.props.row.delay.trim() === ""
          ? new Date()
          : new Date(this.props.row.delay),
      alert_date:
        this.props.row.alertDate == null ||
          this.props.row.alertDate.trim() === ""
          ? new Date()
          : new Date(this.props.row.alertDate),
      required: this.props.row.isRequired,
      alert: this.props.row.sendAlert,
      alert_message: this.props.row.alertMessage || "",
      send_msg_when_paid: this.props.row.sendMsgWhenPaid,
      etablissements: null,
      options: [],
      redirect: false,
      datas: [],
      dialogOpened: false,
      inProgress: false,
      position: this.props.position,
      applyFor: this.props.row.applyFor || -1,
      annee: this.props.row.anneeScolaire || null,
    };
    this.edit = this.edit.bind(this);
    this.newImageUploaded = this.newImageUploaded.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
    this.handleRequired = this.handleRequired.bind(this);
    this.handleApplyFor = this.handleApplyFor.bind(this);
    this.handleChangeAnnee = this.handleChangeAnnee.bind(this);
  }

  handleApplyFor(option) {
    this.setState({ applyFor: option != null ? option.value : -1 });
  }
  handleChangeAnnee(item) {
    console.log(item);
    this.setState({ annee: item ? item : null });
  }
  componentDidUpdate(prevProps) {
    var self = this;

    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          code: self.props.row.code || "",
          name: self.props.row.name || "",
          description: self.props.row.description || "",
          icone: self.props.row.image || "",
          amount: self.props.row.amount || null,
          // image: this.props.row.image || "",
          delay:
            self.props.row.delay == null || self.props.row.delay.trim() === ""
              ? new Date()
              : new Date(this.props.row.delay),
          alert_date:
            self.props.row.alertDate == null ||
              self.props.row.alertDate.trim() === ""
              ? new Date()
              : new Date(this.props.row.alertDate),
          required: self.props.row.isRequired,
          alert: self.props.row.sendAlert,
          alert_message: self.props.row.alertMessage || "",
          send_msg_when_paid: self.props.row.sendMsgWhenPaid,
          applyFor: self.props.row.applyFor,
          annee: self.props.row.anneeScolaire || null,
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };
  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/type-frais/delete?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error deleted.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/type-frais/enable?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error enabled.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/type-frais/disable?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error disabled.");
      });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  newImageUploaded(name, path) {
    this.setState({ image: name });
  }

  handleStartDateChange(date) {
    console.log("start Date");
    console.log(date);
    this.setState({
      alert_date: date
    });
  }
  handleEndDateChange(date) {
    console.log("end Date");
    console.log(date);
    this.setState({
      delay: date
    });
  }

  handleRequired(event) {
    console.log(event.target);
    this.setState({
      required: !this.state.required
    });
  }
  handleAlert() {
    this.setState({
      alert: !this.state.alert
    });
  }
  handleSend() {
    console.log(this.state.send_msg_when_paid);
    this.setState({
      send_msg_when_paid: !this.state.send_msg_when_paid
    });
  }
  edit() {
    var formData = new FormData(); // Currently empty
    formData.append("id", this.props.row.id);
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("icone", this.state.icone);
    formData.append("amount", this.state.amount);
    // formData.append("delay", this.state.delay);
    formData.append("required", this.state.required ? 1 : 0);
    formData.append("alert", this.state.alert ? 1 : 0);
    formData.append("annee", this.state.annee != null ? this.state.annee.id : "");

    formData.append("apply_for", this.state.applyFor);
    if (this.state.alert)
      formData.append("alert_message", this.state.alert_message);
    // formData.append("alert_date", this.state.alert_date);
    if (this.state.alert_date == null || this.state.delay == null) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    formData.append(
      "alert_date",
      moment(this.state.alert_date).format("YYYY-MM-DD")
    );
    formData.append("delay", moment(this.state.delay).format("YYYY-MM-DD"));
    formData.append(
      "send_msg_when_paid",
      this.state.send_msg_when_paid ? 1 : 0
    );
    // formData.append("image", this.state.image);
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/type-frais/edit", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.props.onUpdateItem("update", {
            position: self.state.position,
            row: data.row
          });
          self.handleClose();
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemTypeFrais_1" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemTypeFrais_16" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemTypeFrais_2" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error updated.");
      });
  }

  getLabelApplyFor(applyfor) {
    switch (applyfor) {
      case -1: return this.props.intl.formatMessage({ id: 'AjouterTypeFraisView_7' });
      case 0: return this.props.intl.formatMessage({ id: 'AjouterTypeFraisView_8' });
      case 1: return this.props.intl.formatMessage({ id: 'AjouterTypeFraisView_9' });
      default:
        return "";
    }
  }

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <TableRow className={classes.row} key={row.id}>
          <CustomTableCell component="th" scope="row">
            {/* {row.icone} */}
            <div className={classes.contentImage}>
              <ProgressiveImage src={row.icone} placeholder={defaultAvatar}>
                {(src, loading) => (
                  <Avatar alt="" src={src} className={classes.bigAvatar} />
                )}
              </ProgressiveImage>
            </div>
          </CustomTableCell>
          <CustomTableCell component="th" scope="row">
            <span>{row.code}</span>
          </CustomTableCell>
          <CustomTableCell align="left" title={row.description}>
            {row.name}
          </CustomTableCell>
          <CustomTableCell align="left" style={{ width: "64px" }}>
            {row.amount}
          </CustomTableCell>
          <CustomTableCell align="left" style={{ width: "64px" }}>
            {!!this.props.row.sendAlert ? (
              <IconButton
                title={row.alertMessage}
                classes={{ root: classes.checked }}
              >
                <CheckCircleIcon fontSize="small" />
              </IconButton>
            ) : (
                <IconButton
                  title={formatMessage({ id: "ItemTypeFrais_3" })}
                  classes={{ root: classes.unChecked }}
                >
                  <RadioButtonUncheckedIcon fontSize="small" />
                </IconButton>
              )}
          </CustomTableCell>
          <CustomTableCell align="left" style={{ width: "64px" }}>
            {!!this.props.row.isRequired ? (
              <span
                title={formatMessage({ id: "ItemTypeFrais_4" })}
                style={{ fontSize: "14px", color: "var(--paper-orange-500)" }}
              >
                {formatMessage({ id: "ItemTypeFrais_5" })}
              </span>
            ) : (
                <span
                  title={formatMessage({ id: "ItemTypeFrais_7" })}
                  style={{ fontSize: "14px" }}
                >
                  {formatMessage({ id: "ItemTypeFrais_6" })}
                </span>
              )}
          </CustomTableCell>
          <CustomTableCell align="left" style={{ width: "64px" }}>
            {!!this.props.row.sendMsgWhenPaid ? (
              <span
                title={formatMessage({ id: "ItemTypeFrais_8" })}
                style={{ fontSize: "14px", color: "var(--paper-orange-500)" }}
              >
                {formatMessage({ id: "ItemTypeFrais_5" })}
              </span>
            ) : (
                <span
                  title={formatMessage({ id: "ItemTypeFrais_9" })}
                  style={{ fontSize: "14px" }}
                >
                  {formatMessage({ id: "ItemTypeFrais_5" })}
                </span>
              )}
          </CustomTableCell>
          <CustomTableCell align="left">{row.alertDate}</CustomTableCell>
          <CustomTableCell align="left">{row.delay} </CustomTableCell>
          <CustomTableCell align="left">
            <div className="layout vertical center">
              <span>{this.getLabelApplyFor(row.applyFor)} </span>
              <span>
                {row.anneeScolaire != null ? <span style={{ backgroundColor: "#2196f3", color: "white", padding: "2px" }}>{row.anneeScolaire.year} </span> : <span style={{ backgroundColor: "red", color: "white", padding: "2px" }}>{formatMessage({ id: 'ItemTypeFrais_15' })}</span>}
              </span>
            </div>


          </CustomTableCell>

          <CustomTableCell align="left">
            {/* <Button
              variant="outlined"
              color="primary"
              onClick={this.handleClickOpen}
            >
              Modifier
            </Button> */}
            {/* <Button
              variant="outlined"
              color="secondary"
              onClick={this.handleDelete}
            >
              Supprimer
            </Button> */}
            <div className={classes.actions}>
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
                color="primary"
                aria-label="Edit"
                onClick={this.handleClickOpen}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_del" })}
                aria-label="Delete"
                onClick={this.handleDelete}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>

              {this.props.row.status ? (
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                  classes={{ root: classes.disable }}
                  aria-label="Edit"
                  onClick={this.handleDisabled}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
              ) : (
                  <IconButton
                    title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                    classes={{ root: classes.enable }}
                    aria-label="Delete"
                    onClick={this.handleEnabled}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
            </div>
          </CustomTableCell>
        </TableRow>

        {this.state.dialogOpened && (
          <Dialog
            open={this.state.dialogOpened}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle className={classes.pageTitle} id="form-dialog-title">
              {formatMessage({ id: "ItemTypeFrais_10" })} {row.code}
            </DialogTitle>
            <DialogContent>
              {this.state.inProgress ? (
                <div className={classes.progress}>
                  <LinearProgress variant="query" />
                </div>
              ) : null}
              <div className={classes.pageSubtitle}>
                {" "}
                {formatMessage({ id: "ItemTypeDecaissement_2" })}
              </div>

              <CssBaseline />
              <Paper square elevation={0} className={classes.paper}>
                {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}

                {/* {this.state.result != null ? (
                  <div className="Error-message">
                    {this.state.result.message}
                  </div>
                ) : null} */}

                <div className={classes.root}>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="code">
                      {formatMessage({ id: "ItemTypeDecaissement_3" })}
                    </InputLabel>
                    <Input
                      id="code"
                      required
                      name="code"
                      autoComplete="code"
                      onChange={this.handleChangeInput("code")}
                      defaultValue={row.code}
                      placeholder={formatMessage({
                        id: "AjouterTypeAlertView_1"
                      })}
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="name">
                      {formatMessage({ id: "ItemTypeDecaissement_4" })}
                    </InputLabel>
                    <Input
                      name="name"
                      type="name"
                      id="name"
                      required
                      defaultValue={row.name}
                      onChange={this.handleChangeInput("name")}
                      autoComplete="current-name"
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="description">
                      {formatMessage({ id: "ItemTypeDecaissement_5" })}
                    </InputLabel>
                    <Input
                      id="description"
                      required
                      defaultValue={row.description}
                      name="description"
                      autoComplete="description"
                      onChange={this.handleChangeInput("description")}
                      multiline
                      rows="4"
                      variant="filled"
                    />
                  </FormControl>
                  <div>
                    <LdImageUploader
                      maxSize={500000}
                      hasParent
                      name={row.icone}
                      // path={row.path_photo}
                      onImageUploaded={this.newImageUploaded}
                    />
                  </div>

                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="amount">
                      {formatMessage({ id: "ListDemande_8" })}
                    </InputLabel>
                    <Input
                      name="amount"
                      type="number"
                      id="amount"
                      placeholder="150000"
                      required
                      defaultValue={row.amount}
                      onChange={this.handleChangeInput("amount")}
                      autoComplete="amount"
                    />
                  </FormControl>
                  <br />
                  <br />

                  <div container className={classes.grid}>
                    <span className={classes.label}>
                      {formatMessage({ id: "ListTypeFraisView_9" })}
                    </span>
                    <DatePicker
                      locale="fr-FR"
                      className={classes.datePicker}
                      value={this.state.alert_date}
                      onChange={this.handleStartDateChange}
                    // maxDate={this.state.delay}
                    />
                    <br />
                    <br />
                    <div style={{ width: "100%" }}>
                      <LdSelectAnneeScolaire
                        label={formatMessage({ id: "AjouterTypeFraisView_10" })}
                        placeholder={formatMessage({ id: "AjouterTypeFraisView_10" })}
                        handleValue={this.handleChangeAnnee}
                        firstLoad
                        isSearchable
                        defaultValue={
                          this.state.annee != null ? {
                            label: this.state.annee.year,
                            value: this.state.annee.id,
                            id: this.state.annee.id
                          } : null
                        }
                        baseUrl={
                          appProperties.basepathServer +
                          "/rest-etablissement/list-enabled-annee-scolaires?id=" +
                          this.props.etablissement.id +
                          "&limit=1000"
                        }
                        {...deleteClassInProps(this.props)}
                      />
                    </div>

                    <br />
                    <br />
                    <div style={{ padding: "15px 0", width: "100%" }}>
                      <LdSelectStatic
                        defaultValue={{ value: row.applyFor, label: this.getLabelApplyFor(row.applyFor) }}
                        options={[
                          {
                            value: -1,
                            label: formatMessage({ id: "AjouterTypeFraisView_7" })
                          },
                          {
                            value: 0,
                            label: formatMessage({ id: "AjouterTypeFraisView_8" })
                          },
                          {
                            value: 1,
                            label: formatMessage({ id: "AjouterTypeFraisView_9" })
                          },

                        ]}
                        label={formatMessage({ id: "AjouterTypeFraisView_6" })}
                        handleValue={this.handleApplyFor}
                        {...deleteClassInProps(this.props)}
                      />
                    </div>
                    <br />
                    <br />
                    {/* <TimePicker
                    margin="normal"
                    label="Time picker"
                    value={start}
                    onChange={this.handleDateChange}
                  /> */}
                    <span className={classes.label}>
                      {formatMessage({ id: "ItemTypeFrais_11" })}
                    </span>
                    <DatePicker
                      label="Delay"
                      className={classes.datePicker}
                      locale="fr-FR"
                      minDate={this.state.alert_date}
                      value={this.state.delay}
                      onChange={this.handleEndDateChange}
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={this.state.required}
                        color="primary"
                        onChange={this.handleRequired}
                        checked={this.state.required}
                      />
                    }
                    label={formatMessage({ id: "ItemTypeFrais_12" })}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={this.state.send_msg_when_paid}
                        color="primary"
                        onChange={this.handleSend}
                        checked={this.state.send_msg_when_paid}
                      />
                    }
                    label={formatMessage({ id: "ItemTypeFrais_13" })}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onChange={this.handleAlert}
                        checked={this.state.alert}
                      />
                    }
                    label={formatMessage({ id: "ItemTypeFrais_14" })}
                  />
                  {this.state.alert ? (
                    <FormControl margin="dense" required fullWidth>
                      <InputLabel htmlFor="alert_message">
                        {formatMessage({ id: "AjouterTypeIndisciplineView_2" })}
                      </InputLabel>
                      <Input
                        id="alert_message"
                        required
                        name="alert_message"
                        autoComplete="alert_message"
                        placeholder={formatMessage({
                          id: "AjouterTypeIndisciplineView_1"
                        })}
                        defaultValue={row.alertMessage}
                        onChange={this.handleChangeInput("alert_message")}
                        multiline
                        rows="4"
                        variant="filled"
                      />
                    </FormControl>
                  ) : null}

                  {/* <LdSelect 
                     handleValue = {this.handleValue}
                     isMulti
                     defaultValue={
                      row.etablissements
                        ? row.etablissements.map(option => {
                            return { label: option.name, value: option.id, id:option.id };
                          })
                        : []
                     }
                     baseUrl={appProperties.basepathServer + "/rest-etablissement/list?published=1&info=0&limit=-1&query="}/>

                 
                  <div>
                    <LdImageUploader
                      maxSize={500000}
                      hasParent
                      name={row.image}
                      path={row.path_photo}
                      onImageUploaded={this.newImageUploaded}
                    />
                  </div> */}
                </div>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleClose}
                variant="contained"
                size="medium"
                className={classes.buttonGreyWhite}
              >
                {formatMessage({ id: "AjouterTypeAlertView_13" })}
              </Button>
              <Button
                onClick={this.edit}
                variant="contained"
                size="medium"
                className={classes.buttonPrincipale}
              >
                {formatMessage({ id: "AjouterTypeAlertView_15" })}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}
ItemReseau.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(ItemReseau))
);
