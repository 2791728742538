import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { appProperties } from "../../../components/LdUtils";
import ItemTypeDecaissement from "./ItemTypeDecaissement";
import LdPagination from "../../../components/LdPagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  }
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: "600",
    fontSize: "15px",
    padding: "4px 7px 4px 7px"
  },
  body: {
    fontSize: 14,
    padding: "4px 7px 4px 7px"
  }
}))(TableCell);

class ListTypeDecaissementView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      currentPage: 1,
      etablissement: this.props.etablissement,
      progress: 1
    };
    this._handleNewTypeDecaissement = this._handleNewTypeDecaissement.bind(
      this
    );
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }
  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listTypeMateriel(page);
  };
  _handleNewTypeDecaissement = event => {
    this.setState(state => {
      state.datas = [event.detail.value, ...state.datas];
      return state;
    });
  };
  onUpdateItem = (mode, item) => {
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  componentDidMount() {
    this.listTypeMateriel(this.state.currentPage);
    document.addEventListener(
      "new-type-decaissement",
      this._handleNewTypeDecaissement.bind(this)
    );
  }
  componentWillUnmount() {
    document.removeEventListener(
      "new-type-decaissement",
      this._handleNewTypeDecaissement.bind(this)
    );
  }
  listTypeMateriel(page) {
    let self = this;

    this.setState({ datas: [], progress: 1 });

    fetch(
      appProperties.basepathServer +
        "/manager/type-decaissement/list?etablissement=" +
        this.state.etablissement.id +
        "&limit=5&page=" +
        page +
        "&only_published=0&type=-1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log("There was an error listed type de frais.");
      });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <React.Fragment>
        <Paper elevation={0} className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>
                  {formatMessage({ id: "ListTypeAlertView_Table_1" })}
                </CustomTableCell>
                <CustomTableCell align="left">
                  {formatMessage({ id: "ListTypeAlertView_Table_2" })}
                </CustomTableCell>
                <CustomTableCell align="left">
                  {formatMessage({ id: "ListTypeAlertView_Table_3" })}
                </CustomTableCell>
                <CustomTableCell align="left">
                  {formatMessage({ id: "ListTypeAlertView_Table_6" })}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            {this.state.progress === 0 && (
              <TableBody>
                {this.state.datas.map((row, index) => (
                  <ItemTypeDecaissement
                    key={index}
                    onUpdateItem={this.onUpdateItem}
                    position={index}
                    row={row}
                    {...deleteClassInProps(this.props)}
                  />
                ))}
              </TableBody>
            )}
          </Table>
          {this.state.progress === 1 && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              <div style={{ padding: "15px" }}>
                <CircularProgress
                  thickness={1}
                  size={75}
                  className={classes.fabProgress}
                />
              </div>
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "ListRoleView_Load" })}
              </div>
            </div>
          )}
          {(this.state.progress === 2 ||
            (this.state.progress === 0 && this.state.datas.length === 0)) && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "ListTypeDecaissementView_1" })}
              </div>
            </div>
          )}
          {this.state.progress === 3 && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              <div style={{ padding: "15px" }}>
                {formatMessage({ id: "ListClasseAnneeView_4" })}
              </div>
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "ListRoleView_LoadError" })}
              </div>
            </div>
          )}
        </Paper>
        {this.state.progress === 0 && (
          <LdPagination
            onShowPage={this.setCurrentPage}
            pages={this.state.pages}
          />
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(injectIntl(ListTypeDecaissementView));
