import React, { Component } from "react";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

import LinearProgress from '@material-ui/core/LinearProgress';

import Grid from '@material-ui/core/Grid';

import LdSelect from '../../../components/LdSelect';
// import LdSelectStatic from '../../../components/LdSelectStatic';

import { deleteClassInProps } from '../../../components/LdUtils';
import { injectIntl } from "react-intl";
import { withSnackbar } from "notistack";


const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: 'white',

  },
  root: {
    width: "100%",
    marginTop: '30px',

  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },


  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: '0px'
  },

  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  }
});

class AjouterClasseEtablissementView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      datas: [],
      name: '',
      cycleId: '---',
      classId: '',
      locationId: '',
      systemeId: '---',
      codeMatricule: '',
      nameEn: '',
      inProgress: false,
      controlled: true,
      campus: '---',
      categorieEtablissement: '---',
      errors: {}
    };
    this.add = this.add.bind(this);
    this.handleChangeCycle = this.handleChangeCycle.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeSysteme = this.handleChangeSysteme.bind(this);
    this.handleCampus = this.handleCampus.bind(this);
    this.handleCategorieEtablissement = this.handleCategorieEtablissement.bind(this);

  }

  handleChangeInput = name => e => {
    var val = {};

    val[name] = e.target.value;
    this.setState(val);
  };
  componentDidMount() {
    var locationId = this.getLocationId(this.state.etablissement);
    this.setState({ locationId: locationId });
  }
  handleChangeClass(item) {
    this.setState({ classId: item != null ? item.id : '' });
  };
  handleChangeCycle(item) {
    console.log("handle change cycle");
    console.log(item);
    this.setState({ cycleId: item != null ? item.id : '' });
  }
  handleChangeSysteme(item) {
    this.setState({ systemeId: item != null ? item.id : '' });
  }

  handleCampus(item) {
    this.setState({ campus: item });
  }
  handleCategorieEtablissement(item) {
    this.setState({ categorieEtablissement: item });
  }

  add() {


    var errors = {};
    this.setState({ errors: errors });

    if (this.state.name == null || this.state.name === "") errors["name"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.classId == null || this.state.classId === "") errors["classe"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.campus == null) errors["campus"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.categorieEtablissement == null) errors["categorieEtablissement"] = this.props.intl.formatMessage({ id: "InputError_1" });


    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    // if (this.state.name == null || this.state.name.trim() === "" || this.state.classId === "") {
    //   this.setState({ controlled: false });
    //   return;
    // } else {
    //   this.setState({ controlled: true });
    // }
    var formData = new FormData(); // Currently empty
    formData.append("name", this.state.name);
    formData.append("name_en", this.state.nameEn);
    formData.append("idClasse", this.state.classId);
    formData.append("code_matricule", this.state.codeMatricule);
    formData.append("campus", this.state.campus.id);
    formData.append("categorie_etablissement", this.state.categorieEtablissement.id);
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/rest-etablissement/add-classe?idEtablissement=" + this.props.etablissementId, {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, 'new-classe', { value: data.row });

          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterClasseEtablissementView_17" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }
  computeUrlListClasse(current_cycle_id, systemeId) {
    if (current_cycle_id === "" && systemeId === "") return;
    // return appProperties.basepathServer + "/rest-classe/list-by-location-and-cycle?limit=1000&cycle_id=" + current_cycle_id + "&location_id=" + this.state.locationId;
    return appProperties.basepathServer + "/rest-classe/list-published?limit=1000&cycle_id=" + current_cycle_id + "&systeme_enseignement_id=" + systemeId + "&location_id=" + this.state.locationId;

  }

  computeUrlClasse(categorieEtablissementent) {
    if (categorieEtablissementent == null) return;

    // return appProperties.basepathServer + "/rest-classe/list-by-location-and-cycle?limit=1000&cycle_id=" + current_cycle_id + "&location_id=" + this.state.locationId;
    return appProperties.basepathServer + "/rest-classe/list-by-categorie?limit=-1&categorie_id=" + categorieEtablissementent.id;
    //return appProperties.basepathServer + "/rest-classe/list-by-categorie?limit=1000&cycle_id=" + categorieEtablissementent.cycle.id + "&systeme_enseignement_id=" + categorieEtablissementent.systemeEnseignement.id + "&groupe_enseignement_id=" + categorieEtablissementent.groupeEnseignement.id+ "&location_id=" + this.state.locationId;

  }
  getLocationId(etablissement) {
    if (etablissement.location && etablissement.location != null) {
      // var location = etablissement.location;
      if (etablissement.location.niveau === 1) {
        return etablissement.location.id;
      } else {
        return etablissement.location.location_parent_id;
      }
    } else {
      return "";
    }
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>
        <div className={classes.pageTitle} id="form-dialog-title">{formatMessage({ id: "AjouterClasseEtablissementView_1" })} </div>

        <div className={classes.pageSubtitle} >{formatMessage({ id: "AjouterClasseEtablissementView_2" })} </div>
        {this.state.inProgress
          ? <div className={classes.progress}><LinearProgress variant="query" /></div>
          : null
        }
        {!this.state.controlled
          ? <div className={classes.error}>{formatMessage({ id: "AjouterClasseEtablissementView_3" })}</div>
          : null
        }
        <div className="layout horizontal center">
          <div style={{ paddingRight: '10px' }} className="flex">

            <Grid container className={classes.grid} justify="flex-start" alignItems='center'>
              {/* <span className={classes.label}>
                      Choisir un cycle
                  </span> */}
              <div style={{ width: '32%' }}>
                <LdSelect
                  label={formatMessage({ id: "AjouterClasseEtablissementView_18" })}
                  handleValue={this.handleCategorieEtablissement}
                  firstLoad isSearchable
                  baseUrl={appProperties.basepathServer + "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissementId + "&limit=-1&only_published=1"}  {...deleteClassInProps(this.props)} />

                {/* <LdSelectStatic
                  options={
                    (this.props.etablissement.systemesEnseignements || []).map(option => {
                      return {
                        label: option.name ? option.name : '',
                        value: option.id ? option.id : '',
                        id: option.id ? option.id : '',
                      };
                    })
                  }

                  label={formatMessage({ id: "AjouterClasseEtablissementView_14" })}
                  handleValue={this.handleChangeSysteme}

                  isSearchable
                  {...deleteClassInProps(this.props)}
                /> */}
              </div>

              {/* <div style={{ paddingLeft: '1%', width: '30%' }}>
                <LdSelect
                  label={formatMessage({ id: "AjouterClasseEtablissementView_5" })}
                  handleValue={this.handleChangeCycle}
                  firstLoad isSearchable
                  baseUrl={appProperties.basepathServer + "/rest-cycle/list?limit=0"}  {...deleteClassInProps(this.props)} />
              </div> */}


              {/* <span style={{marginLeft:'20px'}} className={classes.label}>
                     Choisir une classe
                  </span> */}
              <div style={{ paddingLeft: '1%', width: '32%' }}>
                {/* <LdSelect
                  label={formatMessage({ id: "AjouterClasseEtablissementView_6" })}
                  handleValue={this.handleChangeClass}
                  firstLoad isSearchable
                  baseUrl={this.computeUrlListClasse(this.state.cycleId, this.state.systemeId)}  {...deleteClassInProps(this.props)} /> */}
                <LdSelect
                  label={formatMessage({ id: "AjouterClasseEtablissementView_6" })}
                  handleValue={this.handleChangeClass}
                  firstLoad isSearchable
                  baseUrl={this.computeUrlClasse(this.state.categorieEtablissement)}  {...deleteClassInProps(this.props)} />
              </div>
              <div style={{ paddingLeft: '1%', width: '32%' }}>
                <LdSelect
                  label={formatMessage({ id: "AjouterClasseEtablissementView_19" })}
                  handleValue={this.handleCampus}
                  firstLoad isSearchable
                  baseUrl={appProperties.basepathServer + "/manager/campus/list?etablissement=" + this.props.etablissementId + "&limit=-1&only_published=1"}  {...deleteClassInProps(this.props)} />
              </div>
            </Grid>
            <div className="layout horizontal center justified">
              <TextField
                id="name"
                label={formatMessage({ id: "AjouterClasseEtablissementView_4" })}
                style={{ width: '30%', marginTop: '0 !important' }}
                value={this.state.name}
                placeholder={formatMessage({ id: "AjouterClasseEtablissementView_10" })}
                onChange={this.handleChangeInput('name')}
                margin="normal"
              />
              <TextField
                id="nameEn"
                label={formatMessage({ id: "AjouterClasseEtablissementView_9" })}
                style={{ width: '30%', marginTop: '0 !important', marginLeft: '10px' }}
                value={this.state.nameEn}
                placeholder={formatMessage({ id: "AjouterClasseEtablissementView_8" })}
                onChange={this.handleChangeInput('nameEn')}
                margin="normal"
              />
              <TextField
                id="codeMatricule"

                label={formatMessage({ id: "AjouterClasseEtablissementView_11" })}
                helperText={formatMessage({ id: "AjouterClasseEtablissementView_12" })}
                style={{ width: '30%', marginTop: '0 !important', marginLeft: '10px' }}
                value={this.state.codeMatricule}
                onChange={this.handleChangeInput('codeMatricule')}
                margin="normal"
              />
            </div>
          </div>

        </div>
        <div style={{ padding: '10px' }} className="layout horizontal end-justified">
          <Button
            onClick={this.add}
            variant="contained"
            size="medium"
            className={classes.buttonPrincipale}>
            {formatMessage({ id: "AjouterEleveView_9" })}
          </Button>
        </div>

      </main>
    );
  }
}
AjouterClasseEtablissementView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(
  injectIntl(AjouterClasseEtablissementView)
));
