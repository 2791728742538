import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import BuildIcon from "@material-ui/icons/Build";
import ClearIcon from "@material-ui/icons/Clear";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import DeleteIcon from "@material-ui/icons/Delete";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PropTypes from "prop-types";
import React, { Component } from "react";
// import DateTimePicker from 'react-datetime-picker';
import {
  FormattedDate,
  FormattedTime,
  injectIntl,
  intlShape
} from "react-intl";
import LinesEllipsis from "react-lines-ellipsis";
import {
  appProperties,
  isObjectValueEquals,
  userProfileUrl,
  getRound2,
  isUserAdmin
} from "../../../components/LdUtils";

import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import FicheSaisie from "./FicheSaisie";
import { withSnackbar } from "notistack";
import LdSelectStatic from '../../../components/LdSelectStatic';


const styles = theme => ({
  root: {
    // display: "flex",
    // justifyContent : "flex-start",
    // alignItems: "center",
    padding: "6px",
    width: "260px",
    margin: "6px"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonSimple: {
    textTransform: "none"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none"
  },
  date: {
    fontSize: "11px",
    fontWeight: "400"
  },
  bigAvatar: {
    backgroundColor: "lightgray",
    border: "2px solid white",
    width: "100px !important",
    height: "100px !important",
    borderRadius: "0 !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },
  marginIcon: {
    margin: "0 3px",
    backgroundColor: "#F1F1F1",
    zoom: "0.70",
    "-moz-transform": "scale(0.70)"
  }
});

// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
    <FormattedTime hour="numeric" minute="numeric" value={date} />
  </span>
));

class ItemSaisie extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem(appProperties.key_user) || null;
    this.state = {
      currentUser: JSON.parse(user),

      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      position: this.props.position,
      etablissement: this.props.etablissement,
      classe: null,
      controlled: true,
      inProgressValidate: false,
      openSnackValidated: false,
      inProgressDelete: false,
      showDialog: false,

      controlledEdit: true,
      inProgressEdit: false,
      row: this.props.row,
      showDialogEdit: false,
      alert: !!this.props.row.sendAlerts,
      // date: new Date(this.props.row.date || ""),
      bareme: this.props.row.bareme + "",
      countAnalysedNote: 0,
      inProgressAnalysed: false,
      openSnackAnalysed: false,
      openFiche: false,
      //sequence: null,
    };
    this.ficheRef = React.createRef();
    this.doCloseEdit = this.doCloseEdit.bind(this);
    this.doShowEdit = this.doShowEdit.bind(this);
    // this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.doAnalyse = this.doAnalyse.bind(this);

    this.edit = this.edit.bind(this);
    this.handleSnackBarCloseValidate = this.handleSnackBarCloseValidate.bind(
      this
    );

    this.doValidate = this.doValidate.bind(this);
    this.doDeValidate = this.doDeValidate.bind(this);
    this.doDelete = this.doDelete.bind(this);

    this.handleSnackBarCloseAnalyse = this.handleSnackBarCloseAnalyse.bind(
      this
    );
    this.handleCloseFiche = this.handleCloseFiche.bind(this);
    this.handleClickOpenFiche = this.handleClickOpenFiche.bind(this);
    //this.handleChangeSequence = this.handleChangeSequence.bind(this);
  }
  /* handleChangeSequence(value) {
     //let self = this;
     this.setState({ sequence: value }, (state) => {
 
     });
   };*/

  handleClickOpenFiche = () => {
    // console.log('autorisation',this.refs.auth);
    console.log(this.ficheRef);
    //this.dossierRef.doOpenDialog();
    var self = this;
    this.setState({ openFiche: true }, () => {
      setTimeout(() => {
        self.ficheRef.handleClickOpenDialog();
      }, 0);
    });
  };

  handleCloseFiche = () => {
    this.setState({ openFiche: false });
    //this.dossierRef.doCloseDialog();
  };

  doCloseEdit(event) {
    this.setState({ showDialogEdit: false });
  }
  doShowEdit(event) {
    this.setState({ showDialogEdit: true });
  }

  // handleChangeDate(date) {
  //   this.setState({
  //     date: date,
  //   });
  // };
  handleSnackBarCloseAnalyse = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnackAnalysed: false });
  };
  handleSnackBarCloseValidate = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnackValidated: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  handleChangeValue = name => event => {
    this.setState({ [name]: event.target.value });
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => { });
    }
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        //TODO: remplacer la ligne en bas
        self.setState({
          /*message:this.props.row.alertMessageModel,*/

          alert: !!this.props.row.sendAlerts,
          // date: new Date(this.props.row.date || ""),
          bareme: this.props.row.bareme + ""
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }

  edit = () => {
    if (
      this.state.bareme == null ||
      this.state.bareme.trim() === "" ||
      parseInt(this.state.bareme) <= 0
    ) {
      this.setState({ controlledEdit: false });
      return;
    } else {
      this.setState({ controlledEdit: true, inProgressEdit: true });
    }
    var formData = new FormData(); // Currently empty
    // if(this.state.date !=null)formData.append("date", moment(this.state.date).format('YYYY-MM-DD hh:mm:ss'));
    formData.append("bareme", this.state.bareme);
    formData.append("alert", this.state.alert ? 1 : 0);
    formData.append("id", this.props.row ? this.props.row.id : "");
    //formData.append("sequence", this.state.sequence ? this.state.sequence.id : "");
    let self = this;
    fetch(appProperties.basepathServer + "/manager/note/edit-saisie", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItem("update", datas);
        }
        self.doCloseEdit();
        self.setState({ inProgressEdit: false });
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgressEdit: false });
        alert("There was an error added.");
      });
  };
  doAnalyse = () => {
    this.setState({ inProgressAnalysed: true });
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/note/compute-saisie?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressAnalysed: false });
        if (data.result_code === 1) {
          self.setState({
            countAnalysedNote: data.count,
            openSnackAnalysed: true
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressAnalysed: false });
        alert("There was an error disabled.");
      });
  };
  //Quarterly  quarter
  doValidate = () => {
    var formData = new FormData();
    formData.append("id", this.props.row ? this.props.row.id : "");
    let self = this;
    this.setState({ inProgressValidate: true });
    fetch(appProperties.basepathServer + "/manager/note/validate-saisie", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressValidate: false });
        if (data.result_code === 1) {
          this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ItemSaisie_1" }), { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "left" } });

          //self.setState({ openSnackValidated: true });
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItem("update", datas);
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgressValidate: false });
        alert("There was an error added.");
      });
  };
  doDeValidate = () => {
    var formData = new FormData();
    formData.append("id", this.props.row ? this.props.row.id : "");
    let self = this;
    this.setState({ inProgressValidate: true });
    fetch(appProperties.basepathServer + "/manager/note/devalidate-saisie", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressValidate: false });
        if (data.result_code === 1) {
          //self.setState({ openSnackValidated: true });
          this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ItemSaisie_12" }), { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "left" } });
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItem("update", datas);

        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgressValidate: false });
        alert("There was an error added.");
      });
  };

  doDelete = () => {
    var formData = new FormData();
    formData.append("id", this.props.row ? this.props.row.id : "");
    let self = this;
    this.setState({ inProgressDelete: true });
    fetch(appProperties.basepathServer + "/manager/note/delete-saisie", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgressDelete: false });
        if (data.result_code === 1) {
          //self.setState({ openSnackValidated: true });
          this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ItemSaisie_16" }), { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "left" } });
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItem("delete", datas);

        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgressDelete: false });
        alert("There was an error added.");
      });
  };
  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <Dialog
          open={this.state.showDialogEdit}
          onClose={this.doCloseEdit}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">
            {formatMessage({ id: "ItemSaisie_10" })}
          </DialogTitle>
          <DialogContent>
            {this.state.inProgressEdit ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {!this.state.controlledEdit ? (
              <div className={classes.error}>
                {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
              </div>
            ) : null}
            <Paper square elevation={0} className={classes.paper}>
              <div className="layout vertical">
                {/* <div style={{paddingRight:'10px'}}>
                    <span className={classes.label}>
                        Choisir la date
                    </span>
                    <DateTimePicker
                      locale='fr-FR'
                      className={classes.datePicker}
                      value={this.state.date}
                      onChange={this.handleChangeDate}
                    />
                </div> */}
                {/* <div style={{ width: '100%' }}>

                  <LdSelectStatic
                    options={
                      (this.props.sequences || []).map(option => {
                        return {
                          label: option ? ((option.trimestre.categorieEtablissement ? option.trimestre.categorieEtablissement.name + '-' : '') + option.trimestre.name + "-" + option.name) : '',
                          value: option.id ? option.id : '',
                          id: option.id ? option.id : '',
                        };
                      })
                    }

                    label={formatMessage({ id: "ItemSaisie_13" })}
                    handleValue={this.handleChangeSequence}

                    isSearchable
                    {...deleteClassInProps(this.props)}
                  />
                </div> */}

                <div>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="motif">
                      {formatMessage({ id: "AjouterSaisieEnseignantView_16" })}
                    </InputLabel>
                    <Input
                      id="bareme"
                      required
                      name="bareme"
                      type="number"
                      min="1"
                      placeholder="Ex: 20"
                      defaultValue={this.state.bareme}
                      autoComplete="bareme"
                      onChange={this.handleChangeValue("bareme")}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="layout vertical">
                <div style={{ paddingRight: "10px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.alert}
                        onChange={this.handleChange("alert")}
                        value="alert"
                        color="primary"
                      />
                    }
                    label={formatMessage({
                      id: "AjouterSaisieEnseignantView_17"
                    })}
                  />
                </div>
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.doCloseEdit}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}
            >
              {formatMessage({ id: "AjouterIndisciplineView_10" })}
            </Button>
            <Button
              onClick={this.edit}
              variant="contained"
              size="medium"
              className={classes.buttonPrincipale}
            >
              {formatMessage({ id: "ItemAnneeScolaire_Up" })}
            </Button>
          </DialogActions>
        </Dialog>

        <Paper className={classes.root} elevation={1}>
          <div className="layout vertical" style={{ height: "100%" }}>
            <div className="flex">
              <div style={{ fontSize: "14px", fontWeight: "600" }}>
                <LinesEllipsis
                  text={row.enseignant.matiereName || '#' + row.enseignant.matiere.name}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "var(--app-primary-color)"
                }}
              >
                <LinesEllipsis
                  text={
                    row.enseignant.classeEtablissement.name
                  }
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </div>
              <div style={{ fontSize: "13px", fontWeight: "400" }}>
                <LinesEllipsis
                  component="span"
                  text={row.sequence.name}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                  style={row.sequenceUpdated ? { backgroundColor: 'var(--app-primary-color)', color: 'white', padding: '0 5px' } : {}}

                />
              </div>
              <div style={{ fontWeight: "400" }} className={classes.date}>
                {formatMessage({ id: "ItemAlert_msg13" })}{" "}
                <PostDate date={row.created} />
                {row.creator != null && (
                  <span>
                    &nbsp; {formatMessage({ id: "ItemAlert_msg14" })}
                    <a
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "500",
                        fontSize: "12px"
                      }}
                      className="no-underline capitalize"
                      title={row.creator.name}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={userProfileUrl(row.creator)}
                    >
                      <LinesEllipsis
                        text={row.creator.name}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </a>
                  </span>
                )}
              </div>
              {row.enseignant.secondaryTeachers != null ?
                <div
                  style={{ padding: "10px 0 0" }}
                  className="layout horizontal justified"
                >

                  <span title={formatMessage({ id: "ItemSaisie_14" })} style={{ color: "var(--paper-amber-500)", fontSize: "11px" }}>
                    {'['} {(row.enseignant.secondaryTeachers || []).map((teacher, pos) => (
                      <span>{teacher.name}</span>
                    ))}  {']'}
                  </span>
                </div> : ''}
              {row.status && row.date != null && (
                <div style={{ fontWeight: "400" }} className={classes.date}>
                  {formatMessage({ id: "ItemSaisie_9" })}{" "}
                  <PostDate date={row.date} />
                </div>
              )}
            </div>
            <div
              style={{ padding: "10px 0 0" }}
              className="layout horizontal justified"
            >

              <span title={formatMessage({ id: "ItemSaisie_20" })} style={{ fontWeight: "600", fontSize: "12px" }}>
                {formatMessage({ id: "ItemSaisie_17" })} {row.countNotes}
              </span>

              <span title={formatMessage({ id: "FicheSaisie_20" })} style={{ fontWeight: "600", fontSize: "12px" }}>
                {formatMessage({ id: "FicheSaisie_14" })} {row.bareme}
              </span>
            </div>
            <div
              style={{ padding: "10px 0 0" }}
              className="layout horizontal justified"
            >
              <span title={formatMessage({ id: "ItemSaisie_20" })} style={{ fontWeight: "600", fontSize: "12px" }}>
                {formatMessage({ id: "ItemSaisie_18" })} {row.totalNotes}
              </span>
              <span title={formatMessage({ id: "ItemSaisie_20" })} style={{ fontWeight: "600", fontSize: "12px" }}>
                {formatMessage({ id: "ItemSaisie_19" })} {getRound2(row.average)}
              </span>
            </div>
            <div
              style={{ padding: "3px" }}
              className="layout horizontal center"
            >
              <div
                className="flex"
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
              >
                <span style={{ color: "var(--paper-yellow-500)" }}>
                  {row.sendAlerts && (
                    <span title={formatMessage({ id: "ItemSaisie_8" })}>
                      <NotificationsActiveIcon fontSize="small" />
                    </span>
                  )}
                </span>
                {this.state.inProgressAnalysed && (
                  <CircularProgress
                    thickness={1.5}
                    size={20}
                    className={classes.fabProgress}
                  />
                )}
              </div>
              {this.state.openFiche && (
                <FicheSaisie
                  expanded="filled"
                  annee={this.props.annee}
                  etablissement={this.props.etablissement}
                  saisie={row}
                  onClose={this.handleCloseFiche}
                  onRef={ref => (this.ficheRef = ref)}
                />
              )}

              <IconButton
                aria-label="Saisir"
                onClick={this.handleClickOpenFiche}
                title={formatMessage({ id: "ItemSaisie_7" })}
                className={classes.marginIcon}
              >
                <ListIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="ordered"
                onClick={this.doAnalyse}
                title={formatMessage({ id: "ItemSaisie_6" })}
                className={classes.marginIcon}
              >
                <BuildIcon fontSize="small" />
              </IconButton>
              {!!row.status ? (

                <span>
                  {
                    this.props.isAdmin ? <IconButton
                      style={{ color: "var(--paper-green-500)" }}
                      title={formatMessage({ id: "ItemSaisie_11" })}
                      aria-label="Saisie validée"
                      onClick={this.doDeValidate}
                      className={classes.marginIcon}
                    >
                      <DoneAllIcon fontSize="small" />
                    </IconButton> : <IconButton
                      style={{ color: "var(--paper-green-500)" }}
                      title={formatMessage({ id: "ItemSaisie_5" })}
                      aria-label="Saisie validée"
                      onClick={this.doDeValidate}
                      className={classes.marginIcon}
                    >
                      <DoneAllIcon fontSize="small" />
                    </IconButton>
                  }
                </span>



              ) : (
                <IconButton
                  style={{ color: "var(--paper-red-500)" }}
                  onClick={this.doValidate}
                  title={formatMessage({ id: "ItemSaisie_4" })}
                  aria-label="Modifier"
                  className={classes.marginIcon}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}

              <IconButton
                onClick={this.doShowEdit}
                title={formatMessage({ id: "ItemSaisie_3" })}
                aria-label="Modifier"
                className={classes.marginIcon}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              {(isUserAdmin(this.state.currentUser)) && <IconButton
                onClick={this.doDelete}
                title={formatMessage({ id: "ItemSaisie_15" })}
                aria-label="Supprimer"
                className={classes.marginIcon}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>}
            </div>
          </div>
        </Paper>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnackAnalysed}
          autoHideDuration={6000}
          onClose={this.handleSnackBarCloseAnalyse}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={
            <span id="message-id">
              {this.state.countAnalysedNote}&nbsp;{" "}
              {formatMessage({ id: "ItemSaisie_2" })}{" "}
            </span>
          }
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarCloseAnalyse}
            >
              {formatMessage({ id: "FicheSaisie_1" })}
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarCloseAnalyse}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnackValidated}
          autoHideDuration={6000}
          onClose={this.handleSnackBarCloseValidate}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={
            <span id="message-id">{formatMessage({ id: "ItemSaisie_1" })}</span>
          }
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarCloseValidate}
            >
              {formatMessage({ id: "FicheSaisie_1" })}
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarCloseValidate}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </React.Fragment>
    );
  }
}
ItemSaisie.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà.
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemSaisie)));
