import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import LdSelectStatic from "../../../components/LdSelectStatic";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import { deleteClassInProps } from "../../../components/LdUtils";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import { withSnackbar } from "notistack";

import DatePicker from "react-date-picker";
import moment from "moment";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },
  label: {
    padding: "5px"
  }
});

class AjouterTypeAlertView extends Component {
  constructor(props) {
    super(props);

    const date = new Date();

    this.state = {
      code: "",
      name: "",
      description: "",
      contentModel: "",
      etablissementId: this.props.etablissementId,
      dialogOpened: false,
      inProgress: false,
      controlled: true,
      type: "",
      auto: 1,
      date: date
    };
    this.add = this.add.bind(this);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValueType = this.handleValueType.bind(this);
    this.handleChangeAuto = this.handleChangeAuto.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(date) {
    this.setState({
      date: date
    });
  }
  handleChangeAuto = event => {
    var value = parseInt(event.target.value);
    this.setState({ auto: value }, () => {});
  };

  handleValueType(option) {
    this.setState({ type: option != null ? option.value : 0 });
  }

  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  add() {
    if (
      this.state.code == null ||
      this.state.code.trim() === "" ||
      this.state.name == null ||
      this.state.name.trim() === "" ||
      this.state.description == null ||
      this.state.description.trim() === "" ||
      (!this.state.auto && this.state.date == null)
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    var formData = new FormData(); // Currently empty
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("type", this.state.type);
    formData.append("model", this.state.contentModel);
    formData.append("auto", this.state.auto);
    if (!this.state.auto)
      formData.append(
        "send_date",
        moment(this.state.date).format("YYYY-MM-DD")
      );

    this.setState({ inProgress: true });
    var self = this;
    fetch(
      appProperties.basepathServer +
        "/manager/alert-sms/add-type?etablissement=" +
        this.state.etablissementId,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          fireWithObject(document, "new-type-alert", { value: data.row });
          self.props.enqueueSnackbar("Type d'alerte enregistrée avec succès", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        } else {
          self.props.enqueueSnackbar("Les données ne sont pas valides", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className={classes.buttonPrincipale}
          onClick={this.handleClickOpen}
        >
          {/*Ajouter un type d'alerte*/}
          {formatMessage({ id: "AjouterTypeAlertView_btn" })}
        </Button>
        <Dialog
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">
            {/*Ajouter un type d'alerte*/}
            {formatMessage({ id: "AjouterTypeAlertView_btn" })}
          </DialogTitle>
          <DialogContent>
            {this.state.inProgress ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {!this.state.controlled ? (
              <div className={classes.error}>
                {formatMessage({ id: "AlertSolde_check" })}
              </div>
            ) : null}
            <div className={classes.pageSubtitle}>
              Renseignez toutes les informations
              {/*formatMessage({ id: "AjouterTypeAlertView_form" })*/}{" "}
            </div>
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>
              {this.state.redirect ? (
                <Redirect
                  to={{
                    pathname: "/",
                    search: "",
                    state: {}
                  }}
                />
              ) : null}

              {/* {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null} */}

              <div className={classes.root}>
                <div style={{ padding: "15px 0", width: "100%" }}>
                  <LdSelectStatic
                    options={[
                      {
                        value: 0,
                        label: formatMessage({ id: "ItemAlertHistory_9" })
                      },
                      {
                        value: 1,
                        label: formatMessage({ id: "ItemAlertHistory_10" })
                      },
                      {
                        value: 2,
                        label: formatMessage({ id: "ItemAlertHistory_11" })
                      },
                      {
                        value: 3,
                        label: formatMessage({ id: "ItemAlertHistory_12" })
                      },
                      {
                        value: 4,
                        label: formatMessage({ id: "ItemAlertHistory_13" })
                      }
                    ]}
                    label={formatMessage({ id: "AjouterTypeAlertView_cat" })}
                    handleValue={this.handleValueType}
                    isSearchable
                    {...deleteClassInProps(this.props)}
                  />
                </div>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="code">
                    {formatMessage({ id: "ListTypeAlertView_Table_1" })}
                  </InputLabel>
                  <Input
                    id="code"
                    required
                    name="code"
                    autoComplete="code"
                    onChange={this.handleChangeInput("code")}
                    autoFocus
                    placeholde={formatMessage({ id: "AjouterTypeAlertView_1" })}
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="name">
                    {formatMessage({ id: "ListTypeAlertView_Table_2" })}
                  </InputLabel>
                  <Input
                    name="name"
                    type="text"
                    id="name"
                    required
                    onChange={this.handleChangeInput("name")}
                    autoComplete="current-name"
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="description">
                    {formatMessage({ id: "ListTypeAlertView_Table_3" })}
                  </InputLabel>
                  <Input
                    id="description"
                    required
                    name="description"
                    autoComplete="description"
                    onChange={this.handleChangeInput("description")}
                    multiline
                    rows="4"
                    variant="filled"
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="contentModel">
                    {formatMessage({ id: "AjouterTypeAlertView_2" })}
                  </InputLabel>
                  <Input
                    id="contentModel"
                    required
                    name="alertMessage"
                    autoComplete="contentModel"
                    placeholder={formatMessage({
                      id: "AjouterTypeAlertView_3"
                    })}
                    onChange={this.handleChangeInput("contentModel")}
                    multiline
                    rows="4"
                    variant="filled"
                  />
                  {this.state.type === 0 && (
                    <FormHelperText id="component-helper-text">
                      {formatMessage({ id: "AjouterTypeAlertView_4" })}
                    </FormHelperText>
                  )}
                  {this.state.type === 1 && (
                    <FormHelperText id="component-helper-text">
                      {formatMessage({ id: "AjouterTypeAlertView_5" })}
                    </FormHelperText>
                  )}
                  {this.state.type === 2 && (
                    <FormHelperText id="component-helper-text">
                      {formatMessage({ id: "AjouterTypeAlertView_6" })}
                    </FormHelperText>
                  )}
                  {this.state.type === 3 && (
                    <FormHelperText id="component-helper-text">
                      {formatMessage({ id: "AjouterTypeAlertView_7" })}
                    </FormHelperText>
                  )}
                  {this.state.type === 4 && (
                    <FormHelperText id="component-helper-text">
                      {formatMessage({ id: "AjouterTypeAlertView_8" })}
                    </FormHelperText>
                  )}
                </FormControl>
                <br />
                <br />
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    {formatMessage({ id: "AjouterTypeAlertView_9" })}
                  </FormLabel>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={this.state.auto}
                    onChange={this.handleChangeAuto}
                    row
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio color="primary" />}
                      label={formatMessage({ id: "AjouterTypeAlertView_10" })}
                      labelPlacement="right"
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio color="primary" />}
                      label={formatMessage({ id: "AjouterTypeAlertView_11" })}
                      labelPlacement="right"
                    />
                  </RadioGroup>
                </FormControl>
                <div>
                  {this.state.auto === 0 && (
                    <div
                      className="layout horizontal center"
                      style={{ padding: "15px 0" }}
                    >
                      <span className={classes.label}>
                        {formatMessage({ id: "AjouterTypeAlertView_12" })}
                      </span>

                      <DatePicker
                        locale="fr-FR"
                        className={classes.datePicker}
                        value={this.state.date}
                        onChange={this.handleDateChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}
            >
              {formatMessage({ id: "AjouterTypeAlertView_13" })}
            </Button>

            <Button
              onClick={this.add}
              variant="contained"
              size="medium"
              disabled={this.state.inProgress}
              className={classes.buttonPrincipale}
            >
              {this.state.inProgress
                ? formatMessage({ id: "AjouterTypeAlertView_14" })
                : formatMessage({ id: "AjouterTypeAlertView_15" })}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterTypeAlertView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AjouterTypeAlertView))
);
