import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from '@material-ui/icons/Add';
import DoneAllIcon from "@material-ui/icons/DoneAll";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from "@material-ui/icons/People";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import ProgressiveImage from 'react-progressive-image';
import { appProperties, isObjectValueEquals, defaultAvatar, defaultClassImage } from "../../../components/LdUtils";
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import MoneyIcon from '@material-ui/icons/Money';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Dialog from '@material-ui/core/Dialog';
import TextField from "@material-ui/core/TextField";





const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    width: "80px !important",
    height: "80px !important",
    borderRadius: '0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },

  chip: {
    margin: '2px 8px',
  },
  chipAvatar: {
    width: '30px !important',
    height: '30px !important',
    borderRadius: '50% !important',
  },
  fraisZone: {
    padding: ' 10px',
    border: '1px solid #EAEAEA',
    marginTop: '5px'
  },
  itemFrais: {
    border: '1px dashed #EAEAEA',
    borderRadius: '5px',
    padding: '5px 7px',
    fontSize: '14px',
    fontWeight: '500',
    margin: '2px 5px 0 0'
  }
});



// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

// const PostDate = injectIntl(({date, intl}) => (
//   <span title={intl.formatDate(date)}>
//       {/* <FormattedRelative value={date}/> */}
//       <FormattedDate
//        day="numeric"
//        month="long"
//        year="numeric"
//        value={date}/>
//   </span>
// ));

class ItemClasseAnnee extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)

    // var types = this.props.row.classeAnnee && (this.props.row.classeAnnee !=null) ?this.props.row.classeAnnee.typesFrais :[];
    // var array = []; /*types.map((item,index)=>{
    //   return  item!=null? item.id:"";
    // });*/

    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,
      annee: this.props.annee,
      etablissement: this.props.etablissement,
      chef: null, // objet eleve
      eleves: [],
      titulaire: null, //objet enseignant
      enseignants: [],
      progressListFrais: false, //objet enseignant
      listTypesFrais: [],
      //indexClass: this.props.row.name, //TODO: remove 
      controlled: true,
      copy: false,
      progressTitulaire: false,
      progressEleve: false,
      openDialogEleve: false,
      openDialogTitulaire: false,
      openEffectif: false,
      showFrais: false,
      openDialogFrais: false,
      progressFrais: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEditChef = this.handleEditChef.bind(this);
    this.handleEditTitulaire = this.handleEditTitulaire.bind(this);
    this.handleCloseEffectif = this.handleCloseEffectif.bind(this);
    this.handleOpenEffectif = this.handleOpenEffectif.bind(this);
    this.handleSaveEffectif = this.handleSaveEffectif.bind(this);
    this.handleChangeEffectifMax = this.handleChangeEffectifMax.bind(this);
    this.doDefine = this.doDefine.bind(this);
    this.doToggleFrais = this.doToggleFrais.bind(this);
    this.doOpenDialogFrais = this.doOpenDialogFrais.bind(this);
    this.doCloseDialogFrais = this.doCloseDialogFrais.bind(this);
  }

  componentDidUpdate(prevProps) {
    //var self = this;
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        //self.setCurrentPage(1);
      });
    }
    console.log("did update");
    console.log(this.props.row);
  }
  doToggleFrais = () => {//TODO:
    //alert("Edit  le titulaire");
    this.setState({ showFrais: !this.state.showFrais });
  };
  doOpenDialogFrais = () => {//TODO:
    //alert("Edit  le titulaire");
    this.listTypesFrais();



    this.setState({ openDialogFrais: true });
  };
  doCloseDialogFrais = () => {//TODO:
    //alert("Edit  le titulaire");
    this.setState({ openDialogFrais: false });
  };
  doDefine = () => {
    var formData = new FormData();
    formData.append("classe_etablissement_id", this.props.row ? this.props.row.id : '');
    this.edit(formData);
  };
  handleEditChef = () => { //TODO:
    //alert("Edit Chef de classe");
    this.listEleve();
    this.setState({ openDialogEleve: true });
  };
  handleEditTitulaire = () => {//TODO:
    this.listEnseignants();
    //alert("Edit  le titulaire");
    this.setState({ openDialogTitulaire: true });
  };
  handleOpenEffectif = () => {//TODO:
    //alert("Edit  le titulaire");
    this.setState({ effectifMax: (this.props.row.classeAnnee != null ? this.props.row.classeAnnee.effectifMax : 0), openEffectif: true });
  };
  handleCloseEffectif = () => {//TODO:
    //alert("Edit  le titulaire");
    this.setState({ openEffectif: false });
  };
  handleChangeEffectifMax = event => {//TODO:
    //alert("Edit  le titulaire");
    this.setState({ effectifMax: event.target.value });
  };
  edit = (formData) => {
    // if (this.state.indexClass == null || this.state.indexClass.trim() === "") {
    //   this.setState({ controlled: false });
    //   return;
    // } else {
    //   this.setState({ controlled: true });
    // }
    // var formData = new FormData(); // Currently empty
    // formData.append("name", this.state.indexClass);
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/annee-scolaire/add-classe?id=" + (this.props.annee != null ? this.props.annee.id : ''), {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          var row = this.props.row;
          row['classeAnnee'] = data.row;
          let datas = { position: self.state.position, row: row };
          self.props.onUpdateItem('update', datas);
        }
        this.setState({ inProgress: false });

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }
  handleDelete = () => {
    var self = this;
    var url = appProperties.basepathServer + "/manager/annee-scolaire/delete-classe?classe_etablissement_id=" + this.props.row.id;
    if (this.props.annee != null) url += ('&annee=' + this.props.annee.id)
    this.setState({ inProgress: true });
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });

        if (data.result_code === 1) {
          var row = this.props.row;
          row['classeAnnee'] = null;
          self.props.onUpdateItem("update", { position: self.state.position, row: row });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
      });
  };
  listEleve() {

    let self = this;

    this.setState({ eleves: [], progressEleve: 1 });
    var url = appProperties.basepathServer + '/rest-eleve/list?etablissement_id=' + this.state.etablissement.id + '&limit=-1';
    if (this.state.annee != null) url += ("&annee_id=" + this.state.annee.id);
    if (this.props.row && this.props.row != null) url += ("&classe_id=" + this.props.row.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({ progressEleve: 0, eleves: data.datas });

        } else {
          self.setState({ eleves: [], progressEleve: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ eleves: [], progressEleve: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  listEnseignants() {

    let self = this;

    this.setState({ enseignants: [], progressTitulaire: 1 });
    var url = appProperties.basepathServer + '/rest-enseignant/list?etablissement_id=' + this.state.etablissement.id + '&limit=-1';
    if (this.state.annee != null) url += ("&annee_id=" + this.state.annee.id);
    if (this.props.row && this.props.row != null) url += ("&classe_id=" + this.props.row.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({ progressTitulaire: 0, enseignants: data.datas });

        } else {
          self.setState({ enseignants: [], progressTitulaire: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ enseignants: [], progressTitulaire: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  listTypesFrais() {

    let self = this;

    this.setState({ listTypesFrais: [], progressListFrais: 1 });
    var url = appProperties.basepathServer + '/manager/type-frais/list?etablissement=' + this.state.etablissement.id + '&limit=-1&only_published=1';
    if (this.state.annee != null) url += ("&annee=" + this.state.annee.id);

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({ progressListFrais: 0, listTypesFrais: data.datas }, (state) => {
            console.log("Type frais list");
            console.log(self.state.listTypesFrais);
          });

        } else {
          self.setState({ listTypesFrais: [], progressListFrais: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ listTypesFrais: [], progressListFrais: 3 });

        console.log('There was an error listed enseignant.');
      });
  }

  handleCloseDialogChef = () => {
    //this.props.onClose(this.props.selectedValue);
    this.setState({ openDialogEleve: false });
  };

  handleListItemClickChef = value => {
    console.log("Eleve selectionné: ");
    console.log(value);
    this.setState({ openDialogEleve: false });
    var formData = new FormData();
    formData.append("classe_etablissement_id", this.props.row ? this.props.row.id : '');
    formData.append("chef_id", value != null ? value.id : '');
    var enseignantId = this.props.row.classeAnnee != null && this.props.row.classeAnnee.enseignant != null ? this.props.row.classeAnnee.enseignant.id : '';
    formData.append("enseignant_id", enseignantId || '');
    formData.append("effectif_max", this.props.row.classeAnnee != null ? this.props.row.classeAnnee.effectifMax : 10);

    this.edit(formData);
  };
  handleCloseDialogTitulaire = () => {
    //this.props.onClose(this.props.selectedValue);
    this.setState({ openDialogTitulaire: false });
  };

  handleListItemClickTitulaire = value => {
    console.log("Enseignant selectionné: ");
    console.log(value);
    this.setState({ openDialogTitulaire: false });
    var formData = new FormData();
    formData.append("classe_etablissement_id", this.props.row ? this.props.row.id : '');
    formData.append("enseignant_id", value != null ? value.id : '');
    var chefId = this.props.row.classeAnnee != null && this.props.row.classeAnnee.eleve != null ? this.props.row.classeAnnee.eleve.id : '';
    formData.append("chef_id", chefId || '');
    formData.append("effectif_max", this.props.row.classeAnnee != null ? this.props.row.classeAnnee.effectifMax : 10);

    this.edit(formData);
  };
  handleSaveEffectif = value => {
    console.log("Enseignant selectionné: ");
    console.log(value);
    this.handleCloseEffectif();
    var formData = new FormData();
    formData.append("effectif_max", this.state.effectifMax);
    formData.append("classe_etablissement_id", this.props.row ? this.props.row.id : '');
    var enseignantId = this.props.row.classeAnnee != null && this.props.row.classeAnnee.enseignant != null ? this.props.row.classeAnnee.enseignant.id : '';
    formData.append("enseignant_id", enseignantId || '');
    var chefId = this.props.row.classeAnnee != null && this.props.row.classeAnnee.eleve != null ? this.props.row.classeAnnee.eleve.id : '';
    formData.append("chef_id", chefId || '');
    this.edit(formData);
  };
  handleAddTypeFrais = value => {
    let self = this;
    this.setState({ progressFrais: 1 });
    var url = appProperties.basepathServer + '/manager/annee-scolaire/add-frais-classe?frais=' + value.id;
    if (this.props.row.classeAnnee && this.props.row.classeAnnee != null) url += ("&id=" + this.props.row.classeAnnee.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          self.setState({ progressFrais: 0 });
          if (this.props.row.classeAnnee && this.props.row.classeAnnee != null) {
            var row = this.props.row;
            var array = [value, ...this.props.row.classeAnnee.typesFrais];
            row.classeAnnee['typesFrais'] = array;
            let datas = { position: self.state.position, row: row };
            self.props.onUpdateItem('update', datas);
          }
        } else {
          self.setState({ progressFrais: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ progressFrais: 3 });

        console.log('There was an error listed.');
      });

  };
  handleRemoveTypeFrais = value => {
    //TODO: faire une requete au backend pour retirer

    let self = this;
    this.setState({ progressFrais: 1 });
    var url = appProperties.basepathServer + '/manager/annee-scolaire/remove-frais-classe?frais=' + value.id;
    if (this.props.row.classeAnnee && this.props.row.classeAnnee != null) url += ("&id=" + this.props.row.classeAnnee.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          self.setState({ progressFrais: 0 });
          if (this.props.row.classeAnnee && this.props.row.classeAnnee != null) {
            var row = this.props.row;
            var array = [...this.props.row.classeAnnee.typesFrais];
            var arrayIds = array.map((item, key) => {
              return item.id;
            });
            var index = arrayIds.indexOf(value.id);
            if (index > -1) {
              array.splice(index, 1);
            }
            row.classeAnnee['typesFrais'] = array;
            let datas = { position: self.state.position, row: row };
            self.props.onUpdateItem('update', datas);
          }
        } else {
          self.setState({ progressFrais: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ progressFrais: 3 });

        console.log('There was an error listed.');
      });
  };
  render() {
    const { classes, row, ...other } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <React.Fragment>

        <Dialog onUpdateItem={null} open={this.state.openDialogFrais} onClose={this.doCloseDialogFrais} aria-labelledby="simple-dialog-title" {...other}>
          <DialogTitle id="simple-dialog-title">{formatMessage({ id: "ItemClasseAnnee_1" })}</DialogTitle>
          <DialogContent>
            <List>
              {this.state.listTypesFrais.map((typeFrais, index) => (
                <ListItem title={formatMessage({ id: "ItemClasseAnnee_37" }, { date: typeFrais.delay })} button key={index}>
                  <ListItemAvatar>
                    <Avatar>
                      <MoneyIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={typeFrais.name} secondary={formatMessage({ id: "ItemClasseAnnee_2" }) + typeFrais.amount + 'F CFA'} />
                  {(row.classeAnnee ? row.classeAnnee.typesFrais : []).reduce((acc, cur, ind, arr) => {
                    return acc ? acc : null != cur && null != typeFrais && cur.id === typeFrais.id;
                  }, false) ?
                    <ListItemAvatar title={formatMessage({ id: "ItemClasseAnnee_3" })} onClick={() => this.handleRemoveTypeFrais(typeFrais)}>
                      <Avatar style={{ color: 'var(--paper-green-500)' }}>
                        <RemoveCircleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    :
                    <ListItemAvatar title={formatMessage({ id: "ItemClasseAnnee_4" })} onClick={() => this.handleAddTypeFrais(typeFrais)}>
                      <Avatar>
                        <AddIcon />
                      </Avatar>
                    </ListItemAvatar>
                  }


                </ListItem>
              ))}

            </List>
          </DialogContent>
        </Dialog>

        <Dialog
          onUpdateItem={null}
          open={this.state.openEffectif}
          onClose={this.handleCloseEffectif}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{formatMessage({ id: "ItemClasseAnnee_5" })}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage id="ItemClasseAnnee_6" values={{
                effectif: <b>{row.name}</b>
              }} />

            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              defaultValue={this.state.effectifMax}
              id="effectif_max"
              label={formatMessage({ id: "ItemClasseAnnee_7" })}
              type="number"
              placeholder="Ex: 50"
              min={0}
              onChange={this.handleChangeEffectifMax}
              fullWidth
            />
          </DialogContent>
          <DialogActions>

            <Button
              onClick={this.handleCloseEffectif}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}>
              {formatMessage({ id: "ItemClasseAnnee_8" })}
            </Button>
            <Button
              onClick={this.handleSaveEffectif}
              variant="contained"
              size="medium"
              className={classes.buttonPrincipale}>
              {formatMessage({ id: "ItemClasseAnnee_9" })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog onUpdateItem={null} open={this.state.openDialogTitulaire} onClose={this.handleCloseDialogTitulaire} aria-labelledby="simple-dialog-title" {...other}>
          <DialogTitle id="simple-dialog-title">{formatMessage({ id: "ItemClasseAnnee_10" })}</DialogTitle>
          <DialogContent>
            <List>
              {this.state.enseignants.map((enseignant, index) => (
                <ListItem title={formatMessage({ id: "ItemClasseAnnee_11" })} button onClick={() => this.handleListItemClickTitulaire(enseignant)} key={index}>
                  <ListItemAvatar title={'email: ' + enseignant.user.email}>
                    <Avatar alt='' src={enseignant.user.photo || defaultAvatar} className={classes.avatar} />
                  </ListItemAvatar>
                  <ListItemText primary={enseignant.user.name} secondary={formatMessage({ id: "ItemClasseAnnee_12" }) + (enseignant.matiereName || '#' + enseignant.matiere.name)} />
                </ListItem>
              ))}
              <ListItem button onClick={() => this.handleListItemClickTitulaire(null)}>
                <ListItemAvatar>
                  <Avatar>
                    <RemoveCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={formatMessage({ id: "ItemClasseAnnee_13" })} />
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>

        <Dialog onUpdateItem={null} open={this.state.openDialogEleve} onClose={this.handleCloseDialogChef} aria-labelledby="simple-dialog-title" {...other}>
          <DialogTitle id="simple-dialog-title">{formatMessage({ id: "ItemClasseAnnee_14" })}</DialogTitle>
          <DialogContent>
            <List>
              {this.state.eleves.map((eleve, index) => (
                <ListItem title={formatMessage({ id: "ItemClasseAnnee_11" })} button onClick={() => this.handleListItemClickChef(eleve)} key={index}>
                  <ListItemAvatar title={'email: ' + eleve.user.email}>
                    <Avatar alt='' src={eleve.pathPhoto || defaultAvatar} className={classes.avatar} />
                  </ListItemAvatar>
                  <ListItemText primary={eleve.fullname || '@' + eleve.user.name} secondary={formatMessage({ id: "ItemClasseAnnee_15" }) + eleve.matricule} />
                </ListItem>
              ))}
              <ListItem button onClick={() => this.handleListItemClickChef(null)}>
                <ListItemAvatar>
                  <Avatar>
                    <RemoveCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={formatMessage({ id: "ItemClasseAnnee_16" })} />
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>

        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>
              <div>
                <Grid container className={classes.grid} justify="flex-start" alignItems='center'>
                  {this.state.inProgress ?
                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                      <CircularProgress thickness={1} size={46} className={classes.fabProgress} />
                    </div>
                    :
                    (row.classeAnnee != null ?
                      <IconButton
                        title={formatMessage({ id: "ItemClasseAnnee_17" })}
                        aria-label="classe"
                        style={{ color: 'var(--paper-green-500)', margin: '0 10px', border: '1px solid var(--paper-green-500)' }}>
                        <DoneAllIcon fontSize="small" />
                      </IconButton>
                      :
                      <IconButton
                        title={formatMessage({ id: "ItemClasseAnnee_18" })}
                        aria-label="add"
                        style={{ color: 'var(--paper-grey-500)', margin: '0 10px', border: '1px solid #CCCCCC' }}>
                        <RadioButtonUncheckedIcon fontSize="small" />
                      </IconButton>
                    )
                  }

                  <div>
                    <ProgressiveImage placeholder={defaultClassImage}>
                      {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                    </ProgressiveImage>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>
                    <div className='layout horizontal center'>
                      <span className={classes.name}> {row.name}</span>
                      {row.classeAnnee != null && <div style={{ marginLeft: '5px' }}>
                        <Chip
                          icon={<PeopleIcon />}
                          title={formatMessage({ id: "ItemClasseAnnee_7" })}
                          label={formatMessage({ id: "ItemClasseAnnee_19" }) + (row.classeAnnee.effectifMax || formatMessage({ id: "ItemClasseAnnee_20" })) + ")"}
                          className={classes.chip}
                          onDelete={this.handleOpenEffectif}
                          deleteIcon={<span className="layout horizontal center" title={formatMessage({ id: "ItemClasseAnnee_21" })} ><EditIcon style={{ width: '20px', height: '20px' }} /></span>}
                        />
                      </div>
                      }
                    </div>
                    {row.classeAnnee != null ?
                      <React.Fragment>
                        <div className="layout horizontal center">
                          <div title={formatMessage({ id: "ItemClasseAnnee_22" })} style={{ fontSize: "12px", fontWeight: "600" }}>{formatMessage({ id: "ItemClasseAnnee_22" })}</div>
                          {row.classeAnnee.eleve && row.classeAnnee.eleve != null ?
                            <Chip avatar={<ProgressiveImage placeholder={defaultAvatar}>
                              {(src, loading) => (<Avatar alt='' src={row.classeAnnee.eleve.pathPhoto} className={classes.chipAvatar} />)}
                            </ProgressiveImage>}
                              label={(row.classeAnnee.eleve.fullname || '@' + row.classeAnnee.eleve.user.name) + '(' + (row.classeAnnee.eleve.matricule || "##########") + ')'}
                              onDelete={this.handleEditChef}
                              deleteIcon={<EditIcon />}
                              title={formatMessage({ id: "ItemClasseAnnee_23" }) + row.classeAnnee.eleve.user.email}
                              className={classes.chip}
                              variant="outlined"
                            />
                            :
                            <Chip
                              icon={<FaceIcon />}
                              label={formatMessage({ id: "ItemClasseAnnee_20" })}
                              className={classes.chip}
                              onDelete={this.handleEditChef}
                              deleteIcon={<span className="layout horizontal center" title={formatMessage({ id: "ItemClasseAnnee_24" })} ><EditIcon style={{ width: '20px', height: '20px' }} /></span>}
                            />
                          }
                        </div>
                        <div className="layout horizontal center">
                          <div title={formatMessage({ id: "ItemClasseAnnee_25" })} style={{ fontSize: "12px", fontWeight: "600" }}>{formatMessage({ id: "ItemClasseAnnee_36" })}</div>
                          {row.classeAnnee.enseignant && row.classeAnnee.enseignant != null ?
                            <Chip
                              avatar={<ProgressiveImage placeholder={defaultAvatar}>
                                {(src, loading) => (<Avatar alt='' src={row.classeAnnee.enseignant.user.photo} className={classes.chipAvatar} />)}
                              </ProgressiveImage>}
                              label={row.classeAnnee.enseignant.user.name + '(' + (row.classeAnnee.enseignant.matiereName || '#' + row.classeAnnee.enseignant.matiere.name) + ')'}
                              onDelete={this.handleEditTitulaire}
                              title={'Email: ' + row.classeAnnee.enseignant.user.email}
                              deleteIcon={<EditIcon />}
                              className={classes.chip}
                              variant="outlined"
                            />
                            :

                            <Chip
                              icon={<FaceIcon />}
                              label={formatMessage({ id: "ItemClasseAnnee_20" })}
                              className={classes.chip}
                              onDelete={this.handleEditTitulaire}
                              deleteIcon={<span className="layout horizontal center" title={formatMessage({ id: "ItemClasseAnnee_26" })} ><EditIcon style={{ width: '20px', height: '20px' }} /></span>}
                            />
                          }
                        </div>
                      </React.Fragment>
                      : null}

                    {/* <div className={classes.date}>Ajouté le  <PostDate date={row.created}/></div> */}
                  </div>

                </Grid>

              </div>
            </div>
          </div>
          <div className="layout horizontal center">
            <Grid container justify='flex-start' alignItems="center" className={classes.actions}>


              {row.classeAnnee == null ?
                <Button
                  onClick={this.doDefine}
                  variant="outlined"
                  size="medium"
                  title={formatMessage({ id: "ItemClasseAnnee_27" })}
                  className={classes.buttonPrincipale}>
                  {formatMessage({ id: "ItemClasseAnnee_28" })}
                </Button> :
                <div>
                  {/* <Button
                  onClick={this.edit}
                  variant="outlined"
                  size="medium"

                  className = {classes.buttonPrincipale}>
                  Configurer
                </Button> */}
                  <div className="layout vertical">
                    <Button
                      onClick={this.handleDelete}
                      variant="outlined"
                      size="medium"
                      title={formatMessage({ id: "ItemClasseAnnee_29" })}
                      className={classes.buttonGreyWhite}>
                      {formatMessage({ id: "ItemClasseAnnee_30" })}
                    </Button>
                    <div className="layout horizontal center">
                      <span style={{ marginTop: "10px", color: "var(--app-primary-color)", cursor: 'pointer', fontSize: '12px', fontWeigth: '600' }} onClick={this.doToggleFrais}>
                        {this.state.showFrais ? "-" : "+"}
                        {formatMessage({ id: "ItemClasseAnnee_31" })}
                        {row.classeAnnee && row.classeAnnee != null && row.classeAnnee.typesFrais ? '(' + row.classeAnnee.typesFrais.length + ')' : ''}
                      </span>
                    </div>
                  </div>
                </div>}
            </Grid>
          </div>
        </div>
        {this.state.showFrais &&
          <div className={classes.fraisZone}>
            <div style={{ fontSize: '13px', padding: '10px 0' }}>{formatMessage({ id: "ItemClasseAnnee_32" })}</div>
            {row.classeAnnee && row.classeAnnee != null && row.classeAnnee.typesFrais && row.classeAnnee.typesFrais.length > 0 ?
              <div className="layout horizontal center wrap">
                {row.classeAnnee.typesFrais.map((frais, index) => (
                  <div className={classes.itemFrais} key={index}>{frais.name} - Mont: {frais.amount}FCFA - Delai: {frais.delay}</div>
                ))}
                <div>
                  <span style={{ marginLeft: "10px", color: "var(--app-primary-color)", cursor: 'pointer', fontSize: '12px', fontWeigth: '600' }} onClick={this.doOpenDialogFrais}>
                    {formatMessage({ id: "ItemClasseAnnee_33" })}
                  </span>
                </div>
              </div>
              :
              <div className="layout horizontal center">
                <span style={{ fontSize: '15px', fontWeight: '500' }}>{formatMessage({ id: "ItemClasseAnnee_34" })}</span>
                <span style={{ marginLeft: "10px", color: "var(--app-primary-color)", cursor: 'pointer', fontSize: '12px', fontWeigth: '600' }} onClick={this.doOpenDialogFrais}>
                  {formatMessage({ id: "ItemClasseAnnee_35" })}
                </span>
              </div>
            }
          </div>
        }

      </React.Fragment>
    );
  }
}
ItemClasseAnnee.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà.
export default withStyles(styles, { withTheme: true })(injectIntl(ItemClasseAnnee));
