import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AjouterSmsView from '../view/sms/AjouterSmsView';
import AjouterSmsNoteMoyenneView from '../view/sms/AjouterSmsNoteMoyenneView';
import ListSmsView from '../view/sms/ListSmsView';
import { deleteClassInProps } from '../../components/LdUtils';
import { injectIntl } from "react-intl";
import AlertSolde from "../view/AlertSolde";

const useStyles = theme => ({
  root: {
    display: "block", // Fix IE 11 issue.
    //paddingRight: '10px'
  },
  titleBlock: {
    textAlign: 'left',
    // paddingLeft:"10px",
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  }

});

class SmsController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      etablissement: this.props.etablissement

    }

    //this.ajouterReseauEtablissViewRef = React.createRef();
    //this.ListReseauViewRef = React.createRef();

  }

  render() {
    const { classes, viewMode } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.root}>
        <div className={classes.titleBlock}>
          <div style={{ marginBottom: "15px" }} className="layout horizontal center justified">
            <div>
              <div className={classes.pageTitle} variant="div" component="div">
                {formatMessage({ id: "SmsController_Title" })}
              </div>
              <div className={classes.pageSubtitle} variant="div" component="div">
                {viewMode === "classe" && formatMessage({ id: "SmsController_SubTitle_classe" })}
                {viewMode === "numero" && formatMessage({ id: "SmsController_SubTitle_numero" })}
                {viewMode === "note-average" && formatMessage({ id: "SmsController_SubTitle_note_moyenne" })}
              </div>
            </div>
            <div style={{ backgroundColor: 'white', padding: '0 10px', marginLeft: '15px' }} className="flex">
              <AlertSolde
                etablissement={this.props.etablissement}
                {...deleteClassInProps(this.props)}
              />
            </div>
          </div>
          {viewMode === "classe" && <AjouterSmsView viewMode={this.props.viewMode} etablissementId={this.state.etablissement.id} annee={this.props.annee}  {...deleteClassInProps(this.props)} />}
          {viewMode === "numero" && <AjouterSmsView viewMode={this.props.viewMode} etablissementId={this.state.etablissement.id} annee={this.props.annee}  {...deleteClassInProps(this.props)} />}
          {viewMode === "note-average" && <AjouterSmsNoteMoyenneView viewMode={this.props.viewMode} etablissementId={this.state.etablissement.id} annee={this.props.annee}  {...deleteClassInProps(this.props)} />}

        </div>
        <br />
        <ListSmsView viewMode={this.props.viewMode} etablissement={this.state.etablissement} annee={this.props.annee}  {...deleteClassInProps(this.props)} />

      </div>
    );
  }

}

SmsController.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(useStyles)(injectIntl(SmsController));