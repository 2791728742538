import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import { appProperties } from "../../../components/LdUtils";
import ItemEmploiTemps from "./ItemEmploiTemps";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  deleteClassInProps,
  isObjectValueEquals
} from "../../../components/LdUtils";
import AjouterEmploiTempsView from "./AjouterEmploiTempsView";
import AddPlanningView from "./AddPlanningView";
import AddPlanningClasseView from "./AddPlanningClasseView";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import { injectIntl } from "react-intl";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  root: {
    backgroundColor: "white",
    padding: "15px"
    // marginTop: theme.spacing.unit * 3,
  },
  main: {
    // padding:'15px',
    marginTop: "15px"
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400,
    paddingBottom: "15px"
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: "12px",
    fontWeight: "500",
    transform: "translate(14px, -6px) scale(1)"
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: "7.5px 23px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */,
});

class ListEmploiTempsView extends Component {
  constructor(props) {
    super(props);

    var currentId =
      this.props.annee != null && this.props.annee.currentEmploiTempsId
        ? this.props.annee.currentEmploiTempsId
        : "";
    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      currentEmploiTempsId: currentId,
      progress: 1,
      selectedView: 0,

      categories: [],
      categorie: "---",
    };
    this._handleNewEmploiTemps = this._handleNewEmploiTemps.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);

  }

  handleChangeCategorie = event => {
    //let self = this;
    this.setState({ categorie: (event.target.value) }, (state) => {
      //self.listEnseignants();
    });
  };
  handleChangeView = (event, value) => {
    this.setState({ selectedView: value }, state => { });
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => { });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.setState({
          currentEmploiTempsId:
            self.props.annee != null && self.props.annee.currentEmploiTempsId
              ? self.props.annee.currentEmploiTempsId
              : ""
        });
      });
    }
  }

  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listEmploiTemps(page);
  };
  _handleNewEmploiTemps = event => {
    this.setState(state => {
      state.datas = [event.detail.value, ...state.datas];
      return state;
    });
  };
  onUpdateItem = (mode, item) => {
    console.log("intercept", mode);
    console.log(item);
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    } else if (mode === "current-emploi-temps") {
      this.setState({ currentEmploiTempsId: item.id });
      return;
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  componentDidMount() {
    this.listCategorie();
    this.listEmploiTemps(this.state.currentPage);
    document.addEventListener(
      "new-emploi-temps",
      this._handleNewEmploiTemps.bind(this)
    );
  }
  componentWillUnmount() {
    document.removeEventListener(
      "new-emploi-temps",
      this._handleNewEmploiTemps.bind(this)
    );
  }
  listEmploiTemps(page) {
    let self = this;
    this.setState({ datas: [], progress: 1 });
    // fetch(appProperties.basepathServer+ '/manager/modele-bulletin/list?limit=5&page='+page, {
    fetch(
      appProperties.basepathServer +
      "/manager/emploi-temps/list?annee=" +
      this.props.annee.id +
      "&limit=5&page=" +
      page,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });
        console.log("There was an error listed enseignant.");
      });
  }
  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <div
          style={{ backgroundColor: "white", padding: "15px" }}
          className="layout horizontal justified"
        >
          <div>
            <div className={classes.pageTitle} variant="div" component="div">
              {formatMessage({ id: "ListEmploiTempsView_4" })}
            </div>
            <div className={classes.pageSubtitle}>
              {formatMessage({ id: "ListEmploiTempsView_5" })}{" "}
            </div>
          </div>
          <div>
            <AjouterEmploiTempsView
              annee={this.state.annee}
              etablissement={this.state.etablissement}
              etablissementId={this.state.etablissement.id}
              {...deleteClassInProps(this.props)}
            />
          </div>
        </div>

        <br />
        {this.state.progress === 0 && (
          <React.Fragment>
            <Paper elevation={0} className={classes.root}>
              <div>
                {this.state.datas.map((row, index) => (
                  <ItemEmploiTemps
                    etablissement={this.state.etablissement}
                    isCurrent={row.id === this.state.currentEmploiTempsId}
                    key={index}
                    onUpdateItemEmploiTemps={this.onUpdateItem}
                    position={index}
                    row={row}
                    {...deleteClassInProps(this.props)}
                  />
                ))}
              </div>
            </Paper>
            <LdPagination
              onShowPage={this.setCurrentPage}
              pages={this.state.pages}
            />
            <Paper elevation={0} className={classes.root}>
              <AppBar elevation={0} position="static" color="default" className="layout horizontal justified">
                <Tabs
                  value={this.state.selectedView}
                  onChange={this.handleChangeView}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab
                    label={formatMessage({ id: "ListEmploiTempsView_3" })}
                    value={0}
                  />
                  <Tab
                    label={formatMessage({ id: "ListEmploiTempsView_2" })}
                    value={1}
                  />
                </Tabs>
                <div className="layout horizontal end-justified">
                  <FormControl
                    style={{ height: "35px" }}
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      title={formatMessage({
                        id: "ListClasseEtablissementView_4"
                      })}
                      displayEmpty
                      classes={{
                        selectMenu: classes.selectMenu,
                        root: classes.selectRoot
                      }}
                      value={this.state.categorie}
                      onChange={this.handleChangeCategorie}
                      input={
                        <OutlinedInput
                          labelWidth={this.state.labelWidth}
                          name="Choisir une classe"
                          id="outlined-classe-simple"
                          classes={{ input: classes.outlinedInput }}
                        />
                      }
                    >
                      {" "}
                      <MenuItem value={"---"}>
                        {formatMessage({ id: "ListClasseEtablissementView_3" })}
                      </MenuItem>
                      {this.state.categories.map((row, index) => (
                        <MenuItem title={row.description} key={index} value={row}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

              </AppBar>
              {(this.state.categorie != null && this.state.categorie !== "---" && this.state.selectedView === 0 && this.state.currentEmploiTempsId != null) && (
                <div>
                  <AddPlanningView
                    annee={this.props.annee}
                    etablissement={this.props.etablissement}
                    categorie={this.state.categorie}
                    emploiTempsId={this.state.currentEmploiTempsId}
                  />
                </div>
              )}
              {(this.state.categorie != null && this.state.categorie !== "---" && this.state.selectedView === 1 && this.state.currentEmploiTempsId != null) && (
                <div>
                  <AddPlanningClasseView
                    annee={this.props.annee}
                    etablissement={this.props.etablissement}
                    categorie={this.state.categorie}
                    emploiTempsId={this.state.currentEmploiTempsId}
                  />
                </div>
              )}
              {(this.state.categorie == null || this.state.categorie === "---") && (
                <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                  {/* <div style={{ padding: '15px' }}>
                    <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                  </div> */}
                  <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                    {formatMessage({ id: "ListCahierView_14" })}
                  </div>
                </div>
              )}
            </Paper>
          </React.Fragment>
        )}

        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 0 0 0",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}
        {(this.state.progress === 2 ||
          (this.state.progress === 0 && this.state.datas.length === 0)) && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 0 0 0",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "ListEmploiTempsView_1" })}
              </div>
            </div>
          )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 0 0 0",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              {formatMessage({ id: "ListClasseAnneeView_4" })}
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(ListEmploiTempsView));
