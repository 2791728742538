import Avatar from "@material-ui/core/Avatar";
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from "@material-ui/core/LinearProgress";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from "@material-ui/core/styles/withStyles";
import ImageIcon from "@material-ui/icons/Image";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { withSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { appProperties, isObjectValueEquals } from "../../../components/LdUtils";






const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    marginTop: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: "white",
    width: "100%"
  },
  root: {
    width: "100%",
    marginTop: "30px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },

  listRoot: {

  },
  listItem: {
    padding: '5px !important',
    // marginBottom: '-1px'
  },
  listItemText: {
    // fontSize: '13px !important',
    fontSize: 'small !important',
    color: '#424242',
  },
  listItemTextRoot: {
    fontSize: '13px !important',
    padding: '0 10px !important',
    borderBottom: '0.5px solid #e4e1e1',

  },
  listIcon: {
    color: 'var(--app-primary-color)'
  },
});

class AjouterDesignView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      description: "",
      etat_frais: "",
      etats_frais: [],
      payment: "",
      payments: [],
      versement: "",
      versements: [],
      list_versement: "",
      list_versements: [],
      list_student: "",
      list_students: [],
      list_insolvable: "",
      list_insolvables: [],
      list_enseignant: "",
      list_enseignants: [],
      card_eleve: "",
      card_eleves: [],
      bulletin: "",
      bulletins: [],
      bordereau_note: "",
      bordereau_notes: [],
      bordereau_complete: "",
      bordereau_completes: [],
      card_enseignant: "",
      card_enseignants: [],
      certificat_scolarite: "",
      certificat_scolarites: [],
      etat_indiscipline: "",
      etat_indisciplines: [],
      tableau_honneur: "",
      tableau_honneurs: [],
      livret_scolaire: "",
      livret_scolaires: [],
      inProgress: false,
      controlled: true,
      datas: {},
    };
    this.add = this.add.bind(this);
    this.handleSelectEtatFrais = this.handleSelectEtatFrais.bind(this);
    this.handleSelectPayment = this.handleSelectPayment.bind(this);
    this.handleSelectVersement = this.handleSelectVersement.bind(this);
    this.handleSelectListStudents = this.handleSelectListStudents.bind(this);
    this.handleSelectListInsolvables = this.handleSelectListInsolvables.bind(this);
    this.handleSelectListVersements = this.handleSelectListVersements.bind(this);
    this.handleSelectListEnseignants = this.handleSelectListEnseignants.bind(this);
    this.handleSelectCardEleves = this.handleSelectCardEleves.bind(this);
    this.handleSelectBulletins = this.handleSelectBulletins.bind(this);
    this.handleSelectBordereauNotes = this.handleSelectBordereauNotes.bind(this);
    this.handleSelectBordereauCompletes = this.handleSelectBordereauCompletes.bind(this);
    this.handleSelectCardEnseignants = this.handleSelectCardEnseignants.bind(this);
    this.handleSelectCertificatScolarite = this.handleSelectCertificatScolarite.bind(this);
    this.handleSelectEtatIndiscipline = this.handleSelectEtatIndiscipline.bind(this);
    this.handleSelectTableauHonneur = this.handleSelectTableauHonneur.bind(this);
    this.handleSelectLivretScolaire = this.handleSelectLivretScolaire.bind(this);

  }

  handleSelectLivretScolaire(item, position) {
    if (item != null) {
      var array = [...this.state.livret_scolaires];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.livret_scolaires = array;
        state.livret_scolaire = item.code;
        return state;
      });
      var formData = new FormData(); // Currently empty
      formData.append("livret_scolaire", item.code);
      this.add(formData);
    }
  }
  handleSelectTableauHonneur(item, position) {
    if (item != null) {
      var array = [...this.state.tableau_honneurs];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.tableau_honneurs = array;
        state.tableau_honneur = item.code;
        return state;
      });
      var formData = new FormData(); // Currently empty
      formData.append("tableau_honneur", item.code);
      this.add(formData);
    }
  }

  handleSelectCertificatScolarite(item, position) {
    if (item != null) {
      var array = [...this.state.certificat_scolarites];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.certificat_scolarites = array;
        state.certificat_scolarite = item.code;
        return state;
      });
      var formData = new FormData(); // Currently empty
      formData.append("certificat_scolarite", item.code);
      this.add(formData);
    }
  }
  handleSelectEtatFrais(item, position) {
    if (item != null) {
      var array = [...this.state.etats_frais];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.etats_frais = array;
        state.etat_frais = item.code;
        return state;
      });
      var formData = new FormData(); // Currently empty
      formData.append("etat_frais", item.code);
      this.add(formData);
    }
  }
  handleSelectPayment(item, position) {
    if (item != null) {
      var array = [...this.state.payments];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.payments = array;
        state.payment = item.code;
        return state;
      });
      var formData = new FormData(); // Currently empty
      formData.append("payment", item.code);
      this.add(formData);
    }
  }

  handleSelectVersement(item, position) {
    if (item != null) {
      var array = [...this.state.versements];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.versements = array;
        state.versement = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("versement", item.code);
      this.add(formData);
    }
  }
  handleSelectListStudents(item, position) {
    if (item != null) {
      var array = [...this.state.list_students];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.list_students = array;
        state.list_student = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("list_student", item.code);
      this.add(formData);
    }
  }

  handleSelectListInsolvables(item, position) {
    if (item != null) {
      var array = [...this.state.list_insolvables];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.list_insolvables = array;
        state.list_insolvable = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("list_insolvable", item.code);
      this.add(formData);
    }
  }
  handleSelectListVersements(item, position) {
    if (item != null) {
      var array = [...this.state.list_versements];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.list_versements = array;
        state.list_versement = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("list_versement", item.code);
      this.add(formData);
    }
  }
  handleSelectListEnseignants(item, position) {
    if (item != null) {
      var array = [...this.state.list_enseignants];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.list_enseignants = array;
        state.list_enseignant = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("list_enseignant", item.code);
      this.add(formData);
    }
  }
  handleSelectCardEleves(item, position) {
    if (item != null) {
      var array = [...this.state.card_eleves];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.card_eleves = array;
        state.card_eleve = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("card_eleve", item.code);
      this.add(formData);
    }
  }

  handleSelectBulletins(item, position) {
    if (item != null) {
      var array = [...this.state.bulletins];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.bulletins = array;
        state.bulletin = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("bulletin", item.code);
      this.add(formData);
    }
  }
  handleSelectBordereauNotes(item, position) {
    if (item != null) {
      var array = [...this.state.bordereau_notes];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.bordereau_notes = array;
        state.bordereau_note = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("bordereau_note", item.code);
      this.add(formData);
    }
  }
  handleSelectBordereauCompletes(item, position) {
    if (item != null) {
      var array = [...this.state.bordereau_completes];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.bordereau_completes = array;
        state.bordereau_complete = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("bordereau_complete", item.code);
      this.add(formData);
    }
  }
  handleSelectCardEnseignants(item, position) {
    if (item != null) {
      var array = [...this.state.card_enseignants];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.card_enseignants = array;
        state.card_enseignant = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("card_enseignant", item.code);
      this.add(formData);
    }
  }
  handleSelectEtatIndiscipline(item, position) {
    if (item != null) {
      var array = [...this.state.etat_indisciplines];
      array.map((row) => {
        row.selected = false;
        return row;
      });
      item.selected = true;
      array.splice(position, 1, item);
      this.setState(state => {
        state.etat_indisciplines = array;
        state.etat_indiscipline = item.code;
        return state;
      });

      var formData = new FormData(); // Currently empty
      formData.append("etat_indiscipline", item.code);
      this.add(formData);
    }
  }
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.categorieEtablissement, prevProps.categorieEtablissement)) {
      //this.setState({ categorieEtablissement: this.props.categorieEtablissement }, () => { });
      self.loadDesign();
    }
  }
  componentDidMount() {
    this.loadDesign();
  }

  loadDesign() {
    let self = this;
    var url = "";
    if (this.props.isCategory) {
      url = appProperties.basepathServer + "/rest-categorie-etablissement/load-design-by-categorie-etablissement?categorie_etablissement_id=" + this.props.categorieEtablissement.id
    } else {
      url = appProperties.basepathServer + "/manager/etablissement/load-design-by-etablissement?etablissement=" + this.props.etablissement.id
    }
    this.setState({ datas: {}, progress: 1 });
    fetch(url,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {

          self.setState({
            progress: 0,
            datas: data.datas,
            payments: data.datas.payment || [],
            versements: data.datas.versement || [],
            list_versements: data.datas.list_versement || [],
            list_students: data.datas.list_student || [],
            list_insolvables: data.datas.list_insolvable || [],
            list_enseignants: data.datas.list_enseignant || [],
            card_eleves: data.datas.card_eleve || [],
            bulletins: data.datas.bulletin || [],
            bordereau_notes: data.datas.bordereau_note || [],
            bordereau_completes: data.datas.bordereau_complete || [],
            card_enseignants: data.datas.card_enseignant || [],
            etats_frais: data.datas.etat_frais || [],
            certificat_scolarites: data.datas.certificat_scolarite || [],
            etat_indisciplines: data.datas.etat_indiscipline || [],
            tableau_honneurs: data.datas.tableau_honneur || [],
            livret_scolaires: data.datas.livret_scolaire || [],
          });
        } else {
          self.setState({ datas: {}, progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ datas: {}, progress: 3 });

        console.log("There was an error listed matieres.");
      });
  }
  add(formData) {

    // var formData = new FormData(); // Currently empty
    // formData.append("code", this.state.code);
    // formData.append("name", this.state.name);
    // formData.append("name_en", this.state.nameEn);
    // formData.append("description", this.state.description);
    var url = "";
    if (this.props.isCategory) {
      url = appProperties.basepathServer + "/rest-categorie-etablissement/set-design-categorie-etablissement?categorie_etablissement_id=" + this.props.categorieEtablissement.id
    } else {
      url = appProperties.basepathServer + "/manager/etablissement/set-design-etablissement?etablissement=" + this.props.etablissement.id
    }
    let self = this;
    this.setState({ inProgress: true });
    fetch(url,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {

          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: 'AjouterDesignView_12' }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: 'AlertSolde_2' }), {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        } else {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: 'DossierEleve_25' }), {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>

        {!this.props.isCategory ? <div className={classes.pageTitle} id="form-dialog-title">
          {formatMessage({ id: "AjouterDesignView_1" })}{" "}
        </div> : ""}


        {this.state.inProgress ? (
          <div className={classes.progress}>
            <LinearProgress variant="query" />
          </div>
        ) : null}
        {!this.state.controlled ? (
          <div className={classes.error}>
            {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
          </div>
        ) : null}

        {this.state.datas != null && this.state.datas !== {} && (
          <div className="layout vertical">
            {/* Etat des frais */}
            {!this.props.isCategory ? <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_16" })}{" "}
              </div>
              {this.state.etats_frais.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.etats_frais.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.etat_frais === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectEtatFrais(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div> : null}
            {/* reçu de paiement */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_2" })}{" "}
              </div>
              {this.state.payments.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.payments.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.payment === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectPayment(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>

            {/* reçu de versement */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_3" })}{" "}
              </div>
              {this.state.versements.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.versements.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.versement === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectVersement(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>


            {/* cartes d'identite d'élève */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_4" })}{" "}
              </div>
              {this.state.card_eleves.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.card_eleves.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.card_eleve === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectCardEleves(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>


            {/* certificat de scolarité d'élève */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_17" })}{" "}
              </div>
              {this.state.certificat_scolarites.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.certificat_scolarites.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.certificat_scolarite === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectCertificatScolarite(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>

            {/* cartes professionelle d'enseignant */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_15" })}{" "}
              </div>
              {this.state.card_enseignants.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.card_enseignants.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.card_enseignant === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectCardEnseignants(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>


            {/* Liste insolvables*/}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_5" })}{" "}
              </div>
              {this.state.list_insolvables.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.list_insolvables.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.list_insolvable === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectListInsolvables(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>

            {/* Liste de versement*/}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_7" })}{" "}
              </div>
              {this.state.list_insolvables.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.list_versements.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.list_versement === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectListVersements(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>


            {/* Liste enseignant*/}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_6" })}{" "}
              </div>
              {this.state.list_enseignants.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.list_enseignants.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.list_enseignant === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectListEnseignants(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>


            {/* Liste  d'élève */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_8" })}{" "}
              </div>
              {this.state.list_students.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.list_students.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.list_student === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectListStudents(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>

            {/* Bordereau de notes */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_13" })}{" "}
              </div>
              {this.state.bordereau_notes.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.bordereau_notes.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.bordereau_note === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectBordereauNotes(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>


            {/* Bordereau de notes completes */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_14" })}{" "}
              </div>
              {this.state.bordereau_completes.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.bordereau_completes.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.bordereau_complete === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectBordereauCompletes(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>

            {/* bulletin */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_9" })}{" "}
              </div>
              {this.state.bulletins.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.bulletins.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.bulletin === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectBulletins(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>

            {/* Etat des indisciplines */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_18" })}{" "}
              </div>
              {this.state.etat_indisciplines.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.etat_indisciplines.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.etat_indiscipline === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectEtatIndiscipline(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>

            {/* Tableau d'honneur */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_19" })}{" "}
              </div>
              {this.state.tableau_honneurs.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.tableau_honneurs.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.tableau_honneur === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectTableauHonneur(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>


            {/* Livret Scolaire */}
            <div style={{ paddingTop: "20px" }}>
              <div className={classes.pageSubtitle}>
                {formatMessage({ id: "AjouterDesignView_20" })}{" "}
              </div>
              {this.state.livret_scolaires.length > 0 && (
                <List classes={{ root: classes.listItem }}>
                  {this.state.livret_scolaires.map((row, index) => (
                    <ListItem key={row.code} button classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }}>
                      <ListItemAvatar>
                        <Avatar>
                          <ImageIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={row.name} secondary={row.size + ' ' + row.orientation} />
                      {(this.state.livret_scolaire === row.id || row.selected) ?
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_11" })} >
                          <IconButton style={{ color: 'var(--paper-green-500)' }}>
                            <RadioButtonCheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        :
                        <ListItemSecondaryAction title={formatMessage({ id: "AjouterDesignView_10" })} onClick={() => this.handleSelectLivretScolaire(row, index)}>
                          <IconButton>
                            <RadioButtonUncheckedIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      }
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
            {/* 
            <div className="layout vertical">
              <span className="flex" />
              <Button
                onClick={this.add}
                variant="contained"
                size="medium"
                className={classes.buttonPrincipale}
              >
                {formatMessage({ id: "AjouterRoleView_add" })}
              </Button>
            </div> */}
          </div>
        )}
      </main>
    );
  }
}
AjouterDesignView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(
    injectIntl(AjouterDesignView)
  )
);
