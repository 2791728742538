import Avatar from '@material-ui/core/Avatar';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import { withSnackbar } from 'notistack';
import React, { Component } from "react";
import { Chart } from 'react-google-charts';
import { appProperties, cancelEventPropagation, getRound2, isObjectValueEquals, userProfileUrl } from '../../../components/LdUtils';

import { injectIntl } from "react-intl";
import printJS from 'print-js'

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";





const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: '15px',
    marginTop: '15px',
    backgroundColor: 'white',
    // marginLeft: '10px',
  },

  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  }  /** Filter end */
  ,
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  buttonPrincipale: {
    margin: "0 3px",
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  primaryAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--app-primary-color)',
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: '#ff9800',
  },
  blueAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-blue-900)',
  },
  progress: {
    width: "100%",
    flexGrow: 1,
  },
});

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: '600',
    fontSize: '15px',
    padding: '4px 7px 4px 7px'
  },
  body: {
    fontSize: 14,
    padding: '4px 7px 4px 7px'
  }
}))(TableCell);

class ItemMoyenne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      mode: this.props.mode || "",
      classe: this.props.classe || "---",
      sequence: this.props.sequence || "---",
      trimestre: this.props.trimestre || "---",
      count: 0,
      hasMore: false,
      pages: [],
      currentPage: 1,
      progress: 1,
      inProgressGenerate: false,
      etablissement: this.props.etablissement,
      position: this.props.position,
      annee: this.props.annee,
      //react-dom.development.js:24489 Uncaught Invariant Violation: Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate.
      // React limits the number of nested updates to prevent infinite loops.
      // row: this.props.row,

    };
    this.handleGenerateBulletin = this.handleGenerateBulletin.bind(this);
    //this.handlePrintBulletin = this.handlePrintBulletin.bind(this);


  }
  componentDidUpdate(prevProps) {
    var self = this;
    /*if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {

      });
    }*/
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
    if (!isObjectValueEquals(this.props.mode, prevProps.mode)) {
      this.setState({ mode: this.props.mode }, () => { });
    }
  }
  componentDidMount() {
    //this.listMoyenne();
  }

  handleGenerateBulletin(event) {
    this.setState({ inProgressGenerate: true });
    var self = this;
    var url = appProperties.basepathServer + '/manager/note/generate-bulletin?etablissement=' + this.state.etablissement.id;
    if (this.state.annee != null) url += ("&annee=" + this.state.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null) url += ("&classe=" + this.state.classe.id);
    else return;
    if (this.props.mode === "sequence" && this.props.sequence && this.props.sequence !== "---" && this.props.sequence != null) {
      console.log("handleGenerateBulletin");
      console.log(this.props.sequence);
      url += ("&sequence=" + this.props.sequence.id);
    }
    url += ("&eleve=" + this.props.row.eleve.id);
    if (this.props.mode === "trimestre" && this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) url += ("&trimestre=" + this.state.trimestre.id);
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_29" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });

    fetch(
      url,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        self.props.closeSnackbar(key);
        self.setState({ inProgressGenerate: false });
        if (data.result_code === 1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_27" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
          //self.setState({countAnalysedNote:data.count,openSnackAnalysed:true});
          let row = this.props.row;
          row.rank = data.bulletin.rank;
          row.moyenne = data.bulletin.moyenne;
          row.success = data.bulletin.success;
          row.bulletin = data.bulletin.bulletin;
          row.canHonnor = data.bulletin.canHonnor || false;
          row.bulletinDate = data.bulletin.bulletinDate;
          let datas = { position: self.props.position, row: row };
          self.props.onUpdateItem("update", datas);

        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListMoyenne_25" }), { variant: "warning", anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });

        }
      })
      .catch(err => {
        self.props.closeSnackbar(key);
        console.log(err);
        self.setState({ inProgressGenerate: false });
        alert("There was an error disabled.");
      });

  }








  handlePrint = (url) => {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    // var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -1000) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });


              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {

        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }



      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }


  handlePrintTH = (url) => {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    // var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -1000) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1001) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemMoyenne_9" }), { variant: "warning", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {

        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }



      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }


  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }


  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;
    var printBaseUrl = appProperties.basepathServer + '/manager/note/print-bulletins?etablissement=' + this.props.etablissement.id;
    if (this.props.annee != null) printBaseUrl += ("&annee=" + this.props.annee.id);
    if (this.props.classe && this.props.classe !== "---" && this.props.classe != null) printBaseUrl += ("&classe=" + this.props.classe.id);
    else return;
    if (this.props.mode === "sequence" && this.props.sequence && this.props.sequence !== "---" && this.props.sequence != null) {
      printBaseUrl += ("&sequence=" + this.props.sequence.id);
    }
    if (this.props.mode === "trimestre" && this.props.trimestre && this.props.trimestre !== "---" && this.props.trimestre != null) printBaseUrl += ("&trimestre=" + this.props.trimestre.id);
    printBaseUrl += '&eleve=' + row.eleve.id;




    var printBaseTHUrl = appProperties.basepathServer + '/manager/note/print-tableau-honneur?etablissement=' + this.props.etablissement.id;
    if (this.props.annee != null) printBaseTHUrl += ("&annee=" + this.props.annee.id);
    if (this.props.classe && this.props.classe !== "---" && this.props.classe != null) printBaseTHUrl += ("&classe=" + this.props.classe.id);
    else return;
    if (this.props.mode === "sequence" && this.props.sequence && this.props.sequence !== "---" && this.props.sequence != null) {
      printBaseTHUrl += ("&sequence=" + this.props.sequence.id);
    }
    if (this.props.mode === "trimestre" && this.props.trimestre && this.props.trimestre !== "---" && this.props.trimestre != null) printBaseTHUrl += ("&trimestre=" + this.props.trimestre.id);
    printBaseTHUrl += '&eleve=' + row.eleve.id;

    return (
      <React.Fragment>




        <TableRow className={classes.row} key={row.id}>
          <CustomTableCell component="th" scope="row">
            <div>
              <div>
                <a style={{ fontWeight: "600", fontSize: '11px' }} className="no-underline capitalize" title={row.eleve.fullname || '@' + row.eleve.user.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.eleve.user)}>
                  {row.eleve.fullname || '@' + row.eleve.user.name}
                </a>
              </div>
              <div style={{ fontSize: '11px' }}>{row.eleve.user.email}</div>
            </div>
          </CustomTableCell>
          <CustomTableCell component="th" scope="row">
            <div className="layout horizontal center justified">

              <span style={{ fontSize: '11px' }}>{getRound2(row.moyenne)}</span>
              {row.canHonnor &&
                <Button color="primary" title={formatMessage({ id: "ItemMoyenne_1" })}
                  onClick={() => this.handlePrintTH(printBaseTHUrl)}
                  style={{ fontWeight: "600", fontSize: '10px', textTransform: 'none', minWidth: '40px' }}>
                  {formatMessage({ id: "ItemMoyenne_8" })}
                </Button>
              }

            </div>
          </CustomTableCell>
          <CustomTableCell component="th" scope="row">
            <div className="layout vertical">
              <span style={{ fontWeight: "600", fontSize: '11px' }}>{row.rank}</span>
            </div>
          </CustomTableCell>
          <CustomTableCell component="th" scope="row">
            {row.success ?
              <span style={{ fontWeight: "600", fontSize: '13px', color: 'var(--paper-green-500)' }}>{formatMessage({ id: "ListMoyenne_6" })}</span>
              : <span style={{ fontWeight: "600", fontSize: '13px', color: 'var(--paper-red-500)' }}>{formatMessage({ id: "ListMoyenne_5" })}</span>}

          </CustomTableCell>

          <CustomTableCell component="th" scope="row">
            <div className="layout vertical flex">
              {row.bulletin != null &&
                <React.Fragment>
                  <div className="layout horizontal center">
                    <Button variant="outlined" color="primary" title={formatMessage({ id: "ItemMoyenne_2" })}
                      onClick={() => this.handlePrint(printBaseUrl)}
                      style={{ fontWeight: "600", fontSize: '10px', textTransform: 'none' }}>
                      {formatMessage({ id: "ListMoyenne_4" })}&nbsp;
                                {this.props.mode === "sequence" && this.props.sequence != null && (this.props.sequence.name)}
                      {this.props.mode === "trimestre" && this.props.trimestre != null && (this.props.trimestre.name)}
                      {this.props.mode === "annuelle" && this.props.annee != null && (this.props.annee.name)}
                                  &nbsp;-&nbsp;{row.bulletinDate}
                    </Button>
                    <div className="flex" style={{ marginLeft: '10px' }}>
                      {this.state.inProgressGenerate
                        ? <div className={classes.progress}>
                          <CircularProgress title={formatMessage({ id: "ItemMoyenne_3" })} thickness={1} size={20} className={classes.fabProgress} />
                        </div>
                        : <Button variant="outlined" color="primary" title={formatMessage({ id: "ItemMoyenne_6" })}
                          onClick={() => this.handleGenerateBulletin()}
                          style={{ fontWeight: "600", fontSize: '10px', textTransform: 'none', }}>
                          {formatMessage({ id: "ItemMoyenne_4" })}
                        </Button>
                      }
                    </div>

                  </div>
                </React.Fragment>
              }
              {row.bulletin == null &&
                <React.Fragment>
                  <div className="layout horizontal center">
                    <span style={{ fontSize: '11px' }}> {formatMessage({ id: "ListMoyenne_3" })} </span>
                    <div className="flex" style={{ marginLeft: '10px' }}>
                      {this.state.inProgressGenerate
                        ? <div className={classes.progress}>
                          <CircularProgress title={formatMessage({ id: "ItemMoyenne_3" })} thickness={1} size={20} className={classes.fabProgress} />
                        </div>
                        : <Button variant="outlined" color="primary" title={formatMessage({ id: "ItemMoyenne_6" })}
                          onClick={() => this.handleGenerateBulletin()}
                          style={{ fontWeight: "600", fontSize: '10px', textTransform: 'none', }}>
                          {formatMessage({ id: "ItemMoyenne_5" })}
                        </Button>
                      }
                    </div>
                  </div>
                </React.Fragment>
              }
            </div>
          </CustomTableCell>
        </TableRow>



      </React.Fragment>
    );
  }
}
export default withSnackbar(withStyles(styles)(injectIntl(ItemMoyenne)));
