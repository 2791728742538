import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import { appProperties, isObjectValueEquals } from "../../../components/LdUtils";
import ItemEleve from "../../../annee/view/eleve/ItemEleve";
import CircularProgress from "@material-ui/core/CircularProgress";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";
import Button from '@material-ui/core/Button';
import printJS from 'print-js'
import { withSnackbar } from "notistack";

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
import Tooltip from '@material-ui/core/Tooltip';
import TextField from "@material-ui/core/TextField";

const styles = theme => ({
  root: {
    width: "100%"
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: "15px",
    marginTop: "15px",
    backgroundColor: "white"
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: "12px",
    fontWeight: "500",
    transform: "translate(14px, -6px) scale(1)"
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: "7.5px 23px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  }, /** Filter end */

  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
});



class ListEleveView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      classes: [],
      classe: "---", //No change to null. it is used to fix bug in select.
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,

      labelWidth: 0,

      categories: [],
      categorie: "---",
      searchValue: "",
    };
    this._handleNewEleve = this._handleNewEleve.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handlePrintCard = this.handlePrintCard.bind(this);
    this.handlePrintCertificatScolarite = this.handlePrintCertificatScolarite.bind(this);
    this.handlePrintBordereauNote = this.handlePrintBordereauNote.bind(this);
    this.handlePrintEleves = this.handlePrintEleves.bind(this);
    this.printRef = React.createRef();
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);


  }

  handleSearchInputChange = event => {
    if (event.keyCode === 13) {
      //this.refreshList(event.target.value);
      this.setCurrentPage(1);
    }
  };
  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value), classe: "---", classes: [] }, (state) => {
      self.listClasses();
      self.setCurrentPage(1);
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.setCurrentPage(1);
      });
    }
  }
  handleChangeClass = event => {
    console.log("change classe");
    console.log(event.target.value);
    let self = this;
    this.setState({ classe: event.target.value }, state => {
      self.setCurrentPage(1);
    });
  };
  // handleChangeClass(item) {
  //   let self = this;
  //   this.setState({ classe: (item?item:null) },(state)=>{
  //     self.setCurrentPage(1);
  //   });

  // };
  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listEleve(page);
  };
  _handleNewEleve = event => {
    this.setState(state => {
      state.datas = [event.detail.value, ...state.datas];
      return state;
    });
  };
  onUpdateItem = (mode, item) => {
    console.log("intercept", mode);
    console.log(item);
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  componentDidMount() {
    this.listCategorie();
    //this.listClasses();
    this.listEleve(this.state.currentPage);
    document.addEventListener("new-eleve", this._handleNewEleve.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener("new-eleve", this._handleNewEleve.bind(this));
  }
  listClasses() {
    let self = this;
    this.setState({ classes: [] });
    //var url = appProperties.basepathServer + "/rest-etablissement/list-classes?id="+this.state.etablissement.id+"&limit=1000";
    var url =
      appProperties.basepathServer +
      "/manager/annee-scolaire/classes?id=" +
      this.props.annee.id +
      "&limit=100";
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [] });
      return;
    }
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ classes: data.datas });
        } else {
          self.setState({ classes: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ classes: [] });
      });
  }

  listEleve(page) {
    let self = this;

    this.setState({ datas: [], progress: 1 });
    var url =
      appProperties.basepathServer +
      "/manager/inscription/list-eleves?etablissement=" +
      this.state.etablissement.id +
      "&limit=10&page=" +
      page;
    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;
    //if(this.state.classe && this.state.classe !== "---" && this.state.classe!=null) url+=("&classe="+this.state.classe.id);
    if (
      this.state.classe &&
      this.state.classe !== "---" &&
      this.state.classe != null &&
      this.state.classe.classeEtablissement != null
    )
      url += "&classe=" + this.state.classe.classeEtablissement.id;
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    if (this.state.searchValue != null && this.state.searchValue.trim() !== "") url += "&search=" + this.state.searchValue.trim();


    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log("There was an error listed enseignant.");
      });
  }

  handlePrintBordereauNote(event) {
    if (this.state.classe == null || this.state.classe === "---") return;
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/note/print-bordereau-note?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe_etablissement=' + this.state.classe.classeEtablissement.id);
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -2) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView__4" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }
  handlePrintCard(event) {
    if (this.state.classe == null || this.state.classe === "---") return;
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }

  handlePrintEleves(event) {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    var url = appProperties.basepathServer +
      "/manager/inscription/print-eleves?etablissement=" +
      this.state.etablissement.id +
      "&limit=-1";

    if (this.state.annee != null) url += "&annee=" + this.state.annee.id;
    else {
      return;
    }
    //if(this.state.classe && this.state.classe !== "---" && this.state.classe!=null) url+=("&classe="+this.state.classe.id);
    if (
      this.state.classe &&
      this.state.classe !== "---" &&
      this.state.classe != null &&
      this.state.classe.classeEtablissement != null
    )
      url += "&classe=" + this.state.classe.classeEtablissement.id;
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -1000) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }

  handlePrintCertificatScolarite(event) {
    if (this.state.classe == null || this.state.classe === "---") return;
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    var url = this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-certificat-scolarite?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id);
    if (this.state.searchValue != null && this.state.searchValue.trim() !== "") url += "&search=" + this.state.searchValue.trim();

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });

              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }


  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      persist: true,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }


  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>

        <div
          className={classes.grid}
        >
          <div>

            <div className={classes.pageTitle} variant="div" component="div">
              {formatMessage({ id: "LdEtablissementMenu_24" })}
            </div>
            <div className={classes.pageSubtitle} variant="div" component="div">
              {formatMessage({ id: "ListEleveView__1" })}
            </div>
          </div>

          <div className="layout horizontal center">


            {/* <Button
              title={(this.state.classe == null || this.state.classe === "---") ? formatMessage({ id: "ListEleveView__4" }) : formatMessage({ id: "ListEleveView__3" })}
              style={{ textTransform: "none", borderRadius: "0 !important", margin: '0 15px' }}
              size="small"
              disabled={this.state.classe == null || this.state.classe === "---"}
              className={classes.buttonPrincipale}
              component="a" download target="_blank"
              href={this.state.classe == null || this.state.classe === '---' ? "#" : (appProperties.basepathServer + '/manager/inscription/print-cards-eleves?limit=-1&etablissement=' + this.props.etablissement.id + '&annee=' + this.props.annee.id + '&classe=' + this.state.classe.classeEtablissement.id)}
            >
              {formatMessage({ id: "ListEleveView__2" })}
            </Button> */}
            <div className="flex">
              <TextField
                id="searchValue"
                label={formatMessage({ id: "_search_student_label" })}
                style={{
                  width: "98%",
                  marginTop: "0 !important",
                  marginRight: "5px"
                }}
                value={this.state.searchValue}
                placeholder={formatMessage({ id: "_search_student_placeholder" })}
                onChange={(event) => this.setState({ searchValue: event.target.value })}
                onKeyDown={this.handleSearchInputChange}

                margin="normal"
              />
            </div>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "ListClasseEtablissementView_4"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.categorie}
                onChange={this.handleChangeCategorie}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListClasseEtablissementView_3" })}
                </MenuItem>
                {this.state.categories.map((row, index) => (
                  <MenuItem title={row.description} key={index} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "AjouterClasseEtablissementView_6"
                })}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.classe}
                onChange={this.handleChangeClass}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListEleveView_1" })}
                </MenuItem>
                {this.state.classes.map((row, index) => (
                  <MenuItem key={index} value={row}>
                    {row.classeEtablissement.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          </div>
        </div>
        <div className="layout horizontal center end-justified">
          <Button
            onClick={this.handlePrintCertificatScolarite}
            title={(this.state.classe == null || this.state.classe === "---") ? formatMessage({ id: "ListEleveView_19" }) : formatMessage({ id: "ListEleveView_18" })}
            style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }}
            variant="outlined" size="small" className={classes.margin}>

            {formatMessage({ id: "ListEleveView_18" })}
          </Button>
          <Button
            onClick={this.handlePrintEleves}
            title={(this.state.classe == null || this.state.classe === "---") ? formatMessage({ id: "ListEleveView_14" }) : formatMessage({ id: "ListEleveView_13" })}
            style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }}
            variant="outlined" size="small" className={classes.margin}>

            {formatMessage({ id: "ListEleveView_12" })}
          </Button>
          <Tooltip title={(this.state.classe == null || this.state.classe === "---") ? formatMessage({ id: "ListEleveView__4" }) : formatMessage({ id: "ListEleveView__3" })} placement="bottom-end">
            <Button
              onClick={this.handlePrintCard}

              style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }}
              variant="outlined" size="small" className={classes.margin}

            >{formatMessage({ id: "ListEleveView__2" })}
            </Button>
          </Tooltip>

          <Tooltip title={(this.state.classe == null || this.state.classe === "---") ? formatMessage({ id: "ListEleveView__4" }) : formatMessage({ id: "ListEleveView_17" })} placement="bottom-end">
            <Button
              onClick={this.handlePrintBordereauNote}

              style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }}
              variant="outlined" size="small" className={classes.margin}

            >{formatMessage({ id: "ListEleveView_16" })}
            </Button>
          </Tooltip>
        </div>
        {this.state.progress === 0 && (
          <React.Fragment>
            <Paper elevation={0} className={classes.root}>
              <div style={{ marginTop: "15px" }}>
                {this.state.datas.map((row, index) => (
                  <ItemEleve
                    key={index}
                    onUpdateItem={this.onUpdateItem}
                    etablissement={this.state.etablissement}
                    position={index}
                    row={row}
                    {...deleteClassInProps(this.props)}
                  />
                ))}
              </div>
            </Paper>
            <LdPagination
              onShowPage={this.setCurrentPage}
              pages={this.state.pages}
            />
          </React.Fragment>
        )}

        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 ||
          (this.state.progress === 0 && this.state.datas.length === 0)) && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "ListEleveView_2" })}
              </div>
            </div>
          )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              {formatMessage({ id: "ListClasseAnneeView_4" })}
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withSnackbar(withStyles(styles)(injectIntl(ListEleveView)));
