import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import { appProperties,isObjectValueEquals } from '../../../components/LdUtils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';



import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ItemIndiscipline from "../../../discipline/view/indiscipline/ItemIndiscipline";
import { deleteClassInProps} from '../../../components/LdUtils';
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main:{
    padding:'15px',
    marginTop:'15px',
    backgroundColor:'white',
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },




  
  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset':{
      borderColor:'transparent !important',
    }
  },
  selectRoot:{
    height:'35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize:'12px',
    fontWeight:'500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu:{
    padding:'7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color:'white',
    '&:focus':{
      backgroundColor: 'var(--app-primary-color)',
      color:'white',
    },
    borderRadius:'4px !important'
  },
  outlinedInput:{
    backgroundColor: 'var(--app-primary-color)',
    color:'white',
    borderRadius:'4px'
  }  /** Filter end */
});

class ListIndisciplineView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas :[],
      classes :[],
      classe: "---", //No change to null. it is used to fix bug in select. 
      count:0,
      hasMore:false,
      pages:[],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage:1,
      progress: 1,
     
      labelWidth: 0,
      

      categories: [],
      categorie: "---",


    }
    this._handleNewIndiscipline = this._handleNewIndiscipline.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);

  }

  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value), classe: "---",classes:[] }, (state) => {
      self.listClasses();
      self.setCurrentPage(1);
    });
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if(!isObjectValueEquals(this.props.etablissement,prevProps.etablissement)){
      this.setState({etablissement:this.props.etablissement},()=>{
        //self.refreshList("");
      });
    }
    if(!isObjectValueEquals(this.props.annee,prevProps.annee)){
      this.setState({annee:this.props.annee},()=>{
        self.setCurrentPage(1);
      });
    }
  }
  handleChangeClass =  event =>{
    console.log("change classe");
    console.log(event.target.value);
    let self = this;
    this.setState({ classe: (event.target.value) },(state)=>{
         self.setCurrentPage(1);
    });
  };
  // handleChangeClass(item) {
  //   let self = this;
  //   this.setState({ classe: (item?item:null) },(state)=>{
  //     self.setCurrentPage(1);
  //   });
   
  // };
  setCurrentPage = page=>{
    this.setState({currentPage:page});
    this.listIndiscipline(page);
  };
  _handleNewIndiscipline = event =>{
       this.setState(state => {
             state.datas =  [event.detail.value, ...state.datas] ;
             return state;
        });
  };
  onUpdateItem = (mode,item) =>{
      console.log("intercept",mode);
      console.log(item);
      var array = [...this.state.datas];
      if(mode === 'update'){
          array.splice(item.position,1,item.row);
      }else if (mode === 'delete'){
        array.splice(item.position,1);

      }else if (mode === 'enabled'){
        item.row.status = true;
        console.log('enabled', item.row.status);
        array.splice(item.position,1,item.row);

      }else if (mode === 'disabled'){
        item.row.status = false;
        array.splice(item.position,1,item.row);

      }
      
       this.setState(state => {
             state.datas =  array ;
             return state;
      });
  };
  componentDidMount() {  
    this.listCategorie();
    //this.listClasses();
    this.listIndiscipline(this.state.currentPage);
    document.addEventListener('new-indiscipline',this._handleNewIndiscipline.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener('new-indiscipline',this._handleNewIndiscipline.bind(this));
  }
  listClasses(){
    let self = this;
    this.setState({classes: []});
    var url = appProperties.basepathServer + "/rest-etablissement/list-classes-teacher?annee_id="+this.props.annee.id;
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [] });
      return;
    }
    fetch(url, {
        mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    
    })
    .then((response) => response.json())
    .then((data) => { 
        //var array = [];
        if(data.result_code === 1){
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({classes:data.datas});  
          
        }else{
          self.setState({classes:[]});   

        }
        
    })
    .catch((err) => {
      console.log(err);
      self.setState({classes:[]}); 
    });
  }

  listIndiscipline(page){
       
    let self = this;

    this.setState({datas: [],progress:1});
    var url = appProperties.basepathServer+ '/web/indiscipline/list?etablissement='+this.state.etablissement.id +'&limit=10&page='+page;
    if(this.state.annee!=null) url+=("&annee="+this.state.annee.id);
    if(this.state.classe && this.state.classe !== "---" && this.state.classe!=null) url+=("&classe="+this.state.classe.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);

    fetch(url, {
        mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    
    })
    .then((response) => response.json())
    .then((data) => { 
        //var array = [];
        if(data.result_code === 1){
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({progress:0,datas:data.datas,count:data.count,hasMore:data.hasMore,pages:data.pages});  
          
        }else{
          self.setState({datas:[],progress:2});   

        }
        
    })
    .catch((err) => {
      console.log(err);
      //self.setState({datas: []});
      self.setState({datas:[],progress:3}); 

      console.log('There was an error listed enseignant.');
    });
  }
  
  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
        <div className={classes.main}>
          <Grid container className={classes.grid} justify="space-between" alignItems='center'>
            <div>
              <div className={classes.pageTitle} variant="div" component="div">
                {formatMessage({ id: "LdEtablissementMenu_8" })}
              </div>
              <div className={classes.pageSubtitle} variant="div" component="div">
                {formatMessage({ id: "ListIndisciplineView_1" })}
              </div> 
            </div>
            <div>
            
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "ListClasseEtablissementView_4"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.categorie}
                onChange={this.handleChangeCategorie}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListClasseEtablissementView_3" })}
                </MenuItem>
                {this.state.categories.map((row, index) => (
                  <MenuItem title={row.description} key={index} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
              <FormControl style={{height:'35px'}} variant="outlined" className={classes.formControl}>
               
                <Select
                  title={formatMessage({ id: "AjouterClasseEtablissementView_6" })}
                  displayEmpty
                  classes={{selectMenu:classes.selectMenu,root:classes.selectRoot}}
                  value={this.state.classe}
                  onChange={this.handleChangeClass}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="Choisir une classe"
                      id="outlined-classe-simple"
                      classes={{ input: classes.outlinedInput }}
                    />
                  }
                > <MenuItem value={'---'}>{formatMessage({ id: "ListEleveView_1" })}</MenuItem>  
                  {this.state.classes.map((row,index) => (
                    <MenuItem key={index} value={row}>{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

             

             
            </div>
          </Grid>
          { this.state.progress === 0 && (
            <React.Fragment>
              <Paper elevation={0}  className={classes.root}>
                <div style={{marginTop:'15px'}}>
                    {this.state.datas.map((row,index) => (
                      <ItemIndiscipline  key={index} onUpdateItem = {this.onUpdateItem}  etablissement={this.state.etablissement} position = {index} row = {row}  {...deleteClassInProps(this.props)} />
                    ))}
                </div>
              </Paper>
              <LdPagination onShowPage={this.setCurrentPage} pages={this.state.pages} />
          </React.Fragment>)}

          { this.state.progress === 1 && (
              <div style={{backgroundColor: 'white',margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                <div style={{padding:'15px'}}>
                  <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                </div>
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                {formatMessage({ id: "ListRoleView_Load" })}
                </div>
              </div>
            )}
            
            {  (this.state.progress === 2 || (this.state.progress === 0 && this.state.datas.length === 0)) && (
              <div style={{backgroundColor: 'white',margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                  {formatMessage({ id: "RapportIndisciplineType_1" })}
                </div>
              </div>
            )}
            { this.state.progress === 3 && (
              <div style={{backgroundColor: 'white',margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                <div style={{padding:'15px'}}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                {formatMessage({ id: "ListRoleView_LoadError" })}
                </div>
              </div>
            )}

        </div>
    );
  }
}
export default withStyles(styles)(injectIntl(ListIndisciplineView));
