import AppBar from '@material-ui/core/AppBar';
import Avatar from "@material-ui/core/Avatar";
import Badge from '@material-ui/core/Badge';
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Slide from '@material-ui/core/Slide';
import withStyles from "@material-ui/core/styles/withStyles";
// import ItemDossierTypeFrais from './ItemDossierTypeFrais';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotesIcon from '@material-ui/icons/Notes';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, FormattedTime, injectIntl, intlShape, FormattedMessage } from 'react-intl';
import LdSelect from "../../../components/LdSelect";
import { appProperties, deleteClassInProps, isObjectValueEquals, userProfileUrl } from "../../../components/LdUtils";



const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    backgroundColor: theme.color.primaryDark,
    border: '2px solid white',
    width: "100px !important",
    height: "100px !important",
    borderRadius: '0 !important',
    color: 'white'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },


  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },





  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  toolbar: {
    height: '64px',
    backgroundColor: 'var(--app-primary-color);',
    padding: ' 0 10px',

  },
  toolbarTitle: {
    fontSize: '16px',
    color: 'white',
    fontWeight: '600',
    display: 'inline'
  },
  toolbarSubTitle: {
    fontSize: '12px',
    // fontWeight:'600',
    color: '#FEFEFE'
  },
  avatarTitle: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "40px !important",
    height: "40px !important",
    borderRadius: '50% !important'
  },
  avatarDivtitle: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: '50% !important',
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  dossierZone: {
    padding: '10px 0',
    margin: '2px 0',
  },
  dossierTexte: {

    color: 'white',
    padding: '5px',
    backgroundColor: 'var(--app-primary-color)',
    fontsize: '17px',
    fontWeight: '600',
  },
  dialogRoot: {
    top: '64px !important',
    left: '25% !important',
    right: '0 !important',
    bottom: '0 !important',
    // z-index: 1300;
    // position: fixed;
    paddingTop: "15px !important",
    paddingLeft: '18px !important',
    paddingRight: '45px !important'
  },
  backdrop: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 600
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  indisciplineAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-orange-500)',
  },
  scolariteAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-orange-500)',
  },
  scolariteGreen: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-green-500)',
  },
  scolariteYellow: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-yellow-500)',
  },
  panelRoot: {
    borderRadius: '0 !important',
  },
  panelSummary: {
    alignItems: 'center',
    margin: '0 !important',
    borderBottom: '1px dashed #EAEAEA'
  },
  summaryRoot: {
    padding: '0',

  },
  summaryExpanded: {
    margin: '0 !important',

  },
  fraisBadge: {
    top: '15px',
    right: '15px'
  },

});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });


const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })} &nbsp;
       <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: '600',
    fontSize: '15px',
    padding: '4px 7px 4px 7px'
  },
  body: {
    fontSize: 14,
    padding: '4px 7px 4px 7px'
  }
}))(TableCell);
class BonCommande extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      position: this.props.position,
      etablissement: this.props.etablissement,
      controlled: true,
      openDialog: false,
      progress: 1,
      expanded: this.props.expanded || 'filled',
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      currentPage: 1,
      commande: this.props.commande,
      data: [],
      caracteristique: '',
      pu: 0,
      quantite: 0,
      type: null,
    };//          


    this.handleClickOpenDialog = this.handleClickOpenDialog.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);



    this.handleAdd = this.handleAdd.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this._handleNewCommande = this._handleNewCommande.bind(this);

  }
  handleChangeType(value) {
    this.setState({ type: value });
  }
  handleChangeInput = name => event => {
    var val = {};

    if (name === 'pu' || name === "quantite") {
      var value = event.target.value;

      if (value < 0) {
        value = 0;
        event.target.value = value;
      }
      if (event.target.value === "") value = 0;
      val[name] = parseFloat(value);
      this.setState(val);
    } else {
      val[name] = event.target.value;
      this.setState(val);
    }

  };
  onUpdateItem(mode, item) {
    var array = [...this.state.datas];
    if (mode === 'update') {
      array.splice(item.position, 1, item.row);
    } else if (mode === 'delete') {
      array.splice(item.position, 1);
    }
    this.setState(state => {
      state.datas = array;
      return state;
    });
  }
  _handleNewCommande(value) {
    this.setState(state => {
      state.datas = [value, ...state.datas];
      return state;
    });
  }


  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.loadCommande();
  };


  handleClickOpenDialog = () => {
    this.setState({ openDialog: true });
    this.loadCommande();

  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  handleCloseDialog = () => {
    var self = this;
    this.setState({ openDialog: false }, () => {
      self.props.onClose(this);
    });

  };
  componentDidMount() {
    this.props.onRef(this);
    this.loadCommande();
  }
  componentWillUnmount() {
    this.props.onRef(null);
  }
  componentDidUpdate(prevProps) {

    if (!isObjectValueEquals(this.props.commande, prevProps.commande)) {
      this.setState({ commande: this.props.commande }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }


  loadCommande() {

    let self = this;

    this.setState({ datas: [], progress: 1 });
    var url = appProperties.basepathServer + '/manager/commande-materiel/load?id=' + this.props.commande.id;
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        console.log(data);
        if (data.result_code === 1) {
          var w = data.row.items || [];
          self.setState({ progress: 0, datas: w, count: data.count, hasMore: data.hasMore, pages: data.pages });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log('There was an error listed enseignant.');
      });
  }

  handleAdd(event) {
    if (this.state.quantite <= 0 || this.state.pu <= 0 || this.state.type == null) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("pu", this.state.pu);
    formData.append("quantite", this.state.quantite);
    formData.append("caracteristique", this.state.caracteristique);
    formData.append("type", this.state.type.id);

    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/commande-materiel/add-item?id=" + this.props.commande.id, {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false });
        if (data.result_code === 1) {
          self._handleNewCommande(data.item);
          self.props.onUpdateCommande(data.row);
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: 'ItemBonElement_14' }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: 'BonCommande_1' }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -4) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: 'BonCommande_2' }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: 'ItemBonElement_18' }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }
  handleDelete(index, row) { //TODO: demander de considerer au backend le role de gestionnaire de stock pour cette action 
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/commande-materiel/remove-item?id=" + row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.onUpdateItem("delete", { position: index });
          self.props.onUpdateCommande(data.row);
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: 'BonCommande_4' }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: 'BonCommande_3' }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: 'ItemBonElement_17' }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }
      })
      .catch(err => {
        console.log(err);
        alert("There was an error deleted.");
      });
  };


  render() {
    const { classes, commande } = this.props;
    const { formatMessage } = this.props.intl;
    const { expanded, datas } = this.state;

    return (
      <React.Fragment>
        <div>

          <Dialog
            fullScreen
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
            TransitionComponent={Transition}
            classes={{ root: classes.dialogRoot }}
            BackdropProps={{
              classes: {
                root: classes.backdrop
              }
            }}
          >
            <AppBar className={classes.appBar}>
              <Grid container className={classes.toolbar} justify="space-between" alignItems='center'>
                <div className={classes.avatarDivtitle}>
                  <Avatar className={classes.avatarTitle}>
                    <NotesIcon />
                  </Avatar>
                </div>
                <div className="layout vertical flex">
                  <div>
                    <Typography rel="noopener noreferrer" target="_blank" color="inherit" className={classes.toolbarTitle}>
                      <FormattedMessage
                        id="BonAchat_10"
                        values={{
                          ref: <b>  {!commande.ref && commande.ref == null && commande.ref === "" ? "#######" : commande.ref}  </b>,
                          date: <b><PostDate date={commande.date} /></b>
                        }}
                      />
                      <span style={{ marginLeft: '10px' }}>{commande.status === 1 ? formatMessage({ id: 'BonCommande_13' }) : ''}</span>
                      <span style={{ marginLeft: '10px' }}>{commande.status === 2 ? formatMessage({ id: 'BonCommande_14' }) : ''}</span>
                    </Typography>
                  </div>
                  <Typography color="inherit" className={classes.toolbarSubTitle}>
                    <b>{commande.notes}</b>
                  </Typography>
                </div>

                <Button title={formatMessage({ id: 'BonDemande_13' })} style={{ color: 'white', textTransform: "none" }} onClick={this.handleCloseDialog}>
                  <CloseIcon />
                  {formatMessage({ id: 'BonDemande_12' })}
                </Button>
              </Grid>

            </AppBar>
            <div style={{ padding: '15px', overflowY: 'auto' }}>
              {commande.status === 0 &&
                (<Paper elevation={1} style={{ padding: '10px' }} className="layout vertical">
                  <div>
                    <Typography className={classes.heading}>{formatMessage({ id: 'BonCommande_8' })}</Typography>
                  </div>
                  <br />
                  {this.state.inProgress
                    ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                    : null
                  }
                  {!this.state.controlled
                    ? <div className={classes.error}>{formatMessage({ id: 'ItemBonElement_10' })}</div>
                    : null
                  }
                  <div className="layout horizontal center justified">
                    <div style={{ width: '30%', marginTop: '0 !important' }}>
                      <LdSelect
                        label={formatMessage({ id: 'BonCommande_9' })}
                        handleValue={this.handleChangeType}
                        firstLoad isSearchable
                        baseUrl={appProperties.basepathServer + "/manager/type-materiel/list?limit=0&only_published=1&etablissement=" + this.props.etablissement.id}  {...deleteClassInProps(this.props)} />
                    </div>

                    <TextField
                      id="prix_unitaire"
                      label={formatMessage({ id: 'BonAchat_6' })}
                      style={{ width: '30%', marginTop: '0 !important' }}
                      value={this.state.pu}
                      placeholder={formatMessage({ id: 'BonCommande_10' })}
                      onChange={this.handleChangeInput('pu')}
                      margin="normal"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },

                      }}
                    />
                    <TextField
                      id="quantite"
                      label={formatMessage({ id: 'BonAchat_5' })}
                      style={{ width: '30%', marginTop: '0 !important' }}
                      value={this.state.quantite}
                      placeholder={formatMessage({ id: 'BonCommande_11' })}
                      onChange={this.handleChangeInput('quantite')}
                      margin="normal"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0 },

                      }}
                    />
                  </div>
                  <div className="layout horizontal center">
                    <TextField
                      id="Caractéristique"
                      label={formatMessage({ id: 'BonAchat_8' })}
                      required
                      name="caractéristique"
                      placeholder={formatMessage({ id: 'BonCommande_12' })}
                      value={this.state.caracteristique}
                      autoComplete="motif"
                      className="flex"
                      onChange={this.handleChangeInput('caracteristique')}
                      multiline
                      rows="3"
                    />
                    <div style={{ marginLeft: '10px' }} className="layout vertical">
                      <span className="flex"></span>
                      <Button
                        onClick={this.handleAdd}
                        variant="contained"
                        size="medium"
                        className={classes.buttonPrincipale}>
                        {formatMessage({ id: 'AjouterRoleView_add' })}
                      </Button>
                    </div>
                  </div>

                </Paper>)}
              <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'filled'} onChange={this.handleChange('filled')}>
                <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
                  <div style={{ position: 'relative' }}>
                    <Badge classes={{ badge: classes.fraisBadge }} badgeContent={datas ? datas.length : 0} color="primary">
                      <Avatar className={classes.scolariteGreen}>
                        <ThumbUpAltIcon />
                      </Avatar>
                    </Badge>
                    {this.state.progress === 1 &&
                      <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
                    }
                  </div>

                  <div>
                    <Typography className={classes.heading}>{formatMessage({ id: 'BonCommande_6' })}</Typography>
                    <Typography className={classes.secondaryHeading}></Typography>
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: '10px 0 10px 20px' }} className="layout vertical">
                  {(this.state.progress === 0 && datas.length > 0 &&
                    <React.Fragment>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <CustomTableCell style={{ cursor: 'pointer' }}><div className="layout horizontal center"><span>{formatMessage({ id: 'BonAchat_4' })}</span></div></CustomTableCell>
                            <CustomTableCell style={{ cursor: 'pointer' }}><div className="layout horizontal center"><span>{formatMessage({ id: 'BonAchat_5' })}</span></div></CustomTableCell>
                            <CustomTableCell style={{ cursor: 'pointer' }} align="left"><div className="layout horizontal center"><span>{formatMessage({ id: 'BonAchat_6' })}</span></div></CustomTableCell>
                            <CustomTableCell style={{ cursor: 'pointer' }} align="left"><div className="layout horizontal center"><span>{formatMessage({ id: 'BonAchat_7' })}</span></div></CustomTableCell>
                            <CustomTableCell style={{ cursor: 'pointer' }} align="left"><div className="layout horizontal center"><span>{formatMessage({ id: 'BonAchat_8' })}</span></div></CustomTableCell>
                            {commande.status === 0 && <CustomTableCell style={{ cursor: 'pointer' }} align="left"><div className="layout horizontal center" ><span>{formatMessage({ id: 'BonAchat_9' })}</span></div></CustomTableCell>}

                          </TableRow>
                        </TableHead>
                        {((datas && datas.length > 0) &&
                          <TableBody>
                            {datas.map((row, index) => (
                              <TableRow className={classes.row} key={row.id}>
                                <CustomTableCell component="th" scope="row">
                                  <div>
                                    <div>
                                      <b>{row.materiel && row.materiel != null ? row.materiel.name : ''}</b>
                                    </div>
                                    <div style={{ fontWeight: "400" }} className={classes.date}>
                                      <FormattedMessage
                                        id="date_user"
                                        values={{
                                          date: <PostDate date={row.created} />,
                                          user: <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.creator)}>{row.creator.name}</a>
                                        }} />
                                    </div>
                                  </div>

                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  {row.quantite}
                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  {formatMessage({ id: 'Money_cfa' }, { value: row.pu })}
                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  {formatMessage({ id: 'Money_cfa' }, { value: row.pu * row.quantite })}
                                </CustomTableCell>
                                <CustomTableCell component="th" scope="row">
                                  {row.caracteristique}
                                </CustomTableCell>
                                {commande.status === 0 && <CustomTableCell component="th" scope="row">
                                  <IconButton
                                    title={formatMessage({ id: 'BonCommande_5' })}
                                    aria-label="Delete"
                                    onClick={() => this.handleDelete(index, row)}>
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </CustomTableCell>}
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                    </React.Fragment>
                  )}
                  {this.state.progress === 1 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>
                        <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                      </div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: 'ListRoleView_Load' })}
                      </div>
                    </div>
                  )}

                  {((this.state.progress === 2 || (this.state.progress === 0 && datas.length === 0)) &&
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: 'BonCommande_15' })}
                      </div>
                    </div>
                  )}
                  {this.state.progress === 3 && (
                    <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                      <div style={{ padding: '15px' }}>{formatMessage({ id: 'ListClasseAnneeView_4' })}</div>
                      <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                        {formatMessage({ id: 'ListRoleView_LoadError' })}
                      </div>
                    </div>
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          </Dialog>
        </div>

      </React.Fragment>
    );
  }
}
BonCommande.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  commande: PropTypes.object.isRequired,
  etablissement: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(BonCommande)));
