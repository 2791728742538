import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from "@material-ui/core/Paper";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import LdFileUploader from "../../../components/LdFileUploader";
import LdImageUploader from "../../../components/LdImageUploader";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import { deleteClassInProps} from '../../../components/LdUtils';


const styles = theme => ({
  main: {
    // width: "400px",
    textAlign:"left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding:'0px'
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },

  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paperMenu: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  }
});



class AjouterBulletinView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      code: "",
      name: "",
      description: "",
      image: "",
      simpleFile: "",
      template: "",
      options: [],
      selectedOption: null,
      result: null,
      redirect: false,
      ids: "",
      datas: [],
      dialogOpened: false,
      inProgress:false,
    };
    this.add = this.add.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.newImageUploaded = this.newImageUploaded.bind(this);
    this.newFileUploaded = this.newFileUploaded.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };
  componentDidMount() {
    this.listEtablissement(1);
    //this.handleChange([]);
  }
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  handleChange(selectedOptions) {
    var ids = selectedOptions.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + "-" + e.value : e.value;
    }, "");
    this.setState({ ids: ids });
  }
  newImageUploaded(name, path) {
    // console.log(" new image uplaoded in Ajouter");
    // console.log(name);
    // console.log(path);
    this.setState({ image: name });
  }
  newFileUploaded(file) {
    // console.log(" new image uplaoded in Ajouter");
    // console.log(name);
    // console.log(path);
    this.setState({ simpleFile: file });
    console.log(file);
  }
  add() {
    var formData = new FormData(); // Currently empty
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    // formData.append("ids", this.state.ids);
    formData.append("image", this.state.image);
    formData.append("simpleFile", this.state.simpleFile);
    formData.append("template", this.state.template);
    let self = this;
    this.setState({inProgress:true});
    fetch(appProperties.basepathServer + "/manager/modele-bulletin/add", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({inProgress:false});
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          fireWithObject(document,'new-bulletin',{value:data.row});
          self.handleClose();

        }
      })
      .catch(err => {
        console.log(err);
        this.setState({inProgress:false});
        alert("There was an error added.");
      });
  }
  listEtablissement(page) {
    let self = this;

    this.setState({ options: [] });

    fetch(
      appProperties.basepathServer +
        "/rest-etablissement/list?info=0&limit=-1&page=" +
        page +
        "&published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        // if (data.result_code == 1) {
        //   if (data.datas && data.datas.length > 0) {
        //     data.datas.forEach(e => {
        //       array.push({ value: e.id, label: e.name });
        //     });
        //   }
        // }
        self.setState({ options: data.datas });
      })
      .catch(err => {
        console.log(err);
        self.setState({ options: [] });
        console.log("There was an error listed etablissement.");
      });
  }
  render() {
    const { classes } = this.props;


    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className = {classes.buttonPrincipale}
          onClick={this.handleClickOpen}

        >
          Ajouter un bulletin
        </Button>
        <Dialog
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">Ajouter un Modele de Bulletin</DialogTitle>
          <DialogContent>
            { this.state.inProgress
              ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
              : null
            }
            <div  className={classes.pageSubtitle} >Renseignez toutes les informations sur le bulletin </div>
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>
              {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}
              {this.state.redirect ? (
                <Redirect
                  to={{
                    pathname: "/",
                    search: "",
                    state: {}
                  }}
                />
              ) : null}

              {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null}

              <div className={classes.root}>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="code">Code :</InputLabel>
                  <Input
                    id="code"
                    required
                    name="code"
                    autoComplete="code"
                    onChange={this.handleChangeInput("code")}
                    autoFocus
                    placeholde="entrez votre code"
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="name">name :</InputLabel>
                  <Input
                    name="name"
                    type="name"
                    id="name"
                    required
                    onChange={this.handleChangeInput("name")}
                    autoComplete="current-name"
                  />
                </FormControl>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="description">Description :</InputLabel>
                  <Input
                    id="description"
                    required
                    name="description"
                    autoComplete="description"
                    onChange={this.handleChangeInput("description")}
                    multiline
                      rows="4"
                      variant="filled"
                  />
                </FormControl>

               
                <div>
                  <LdImageUploader maxSize={500000} onImageUploaded={this.newImageUploaded}  {...deleteClassInProps(this.props)} />
                </div>
                <div>
                  <LdFileUploader onFileUploaded={this.newFileUploaded}   {...deleteClassInProps(this.props)} />
                </div>
                <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="template">Template :</InputLabel>
                  <Input
                    name="template"
                    type="template"
                    id="template"
                    required
                    onChange={this.handleChangeInput("template")}
                    autoComplete="current-template"
                  />
                </FormControl>
                
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button  onClick={this.handleClose} 
              variant="contained"
              size="medium"
              className = {classes.buttonGreyWhite}>
              Cancel
            </Button>
            <Button 
              onClick={this.add} 
              variant="contained"
              size="medium"
              className = {classes.buttonPrincipale}>
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterBulletinView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(
  AjouterBulletinView
);
