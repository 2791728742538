import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import {
  appProperties,
  fireWithObject,
  isObjectValueEquals
} from "../../components/LdUtils";
import { injectIntl } from "react-intl";
import IconButton from "@material-ui/core/IconButton";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: "12px",
    fontWeight: "500",
    transform: "translate(14px, -6px) scale(1)"
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: "7.5px 23px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */,

  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  }
});

class AlertSolde extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      // annee: this.props.annee,
      progress: 1,
      open: false,
      solde: 0,
      inProgress: false,
      controlled: true
    };
    this.handleClose = this.handleClose.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.doReload = this.doReload.bind(this);
  }
  openDialog(event) {
    this.setState({ open: true });
  }
  handleClose(event) {
    this.setState({ open: false });
  }

  handleChangeInput = name => event => {
    var val = {};

    var value = event.target.value;

    if (value < 0) {
      value = 0;
      event.target.value = value;
    }
    if (event.target.value === "") value = 0;
    val[name] = parseFloat(value);
    this.setState(val);
  };
  componentDidUpdate(prevProps) {
    //var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    // if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
    //   this.setState({ annee: this.props.annee }, () => {
    //     self.setCurrentPage(1);
    //   });
    // }
  }

  componentDidMount() {
    this.doReload(null);
  }
  doReload(event) {
    var code =
      this.props.etablissement && this.props.etablissement !== null
        ? this.props.etablissement.code
        : "";
    this.getEtablissementByCode(code);
  }
  getEtablissementByCode(code) {
    let self = this;
    this.setState({ progress: 1 });
    fetch(
      appProperties.basepathServer + "/rest-etablissement/load?code=" + code,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x- www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.setState({ progress: 2, etablissement: data.item });
          fireWithObject(document, "update-etablissement", {
            etablissement: data.item
          });
        } else if (data.result_code === 0) {
          self.setState({ progress: 3 });
        } else if (data.result_code === -1000) {
          if (this.props && this.props.history && this.props.history != null)
            this.props.history.push({
              pathname: "/user/login",
              search:
                "?redirect_url=" +
                encodeURIComponent(
                  this.props.location.pathname + "" + this.props.location.search
                ),
              state: {}
            });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ progress: 4 });
      });
  }

  handleAdd(event) {
    if (this.state.solde === 0) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    this.setState({ inProgress: true });
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/rest-etablissement/demande-recharge?etablissement=" +
      this.props.etablissement.id +
      "&nb_sms=" +
      this.state.solde,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x- www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.handleClose();
          self.props.enqueueSnackbar(
            "Votre demande a bien été transmise a l'équipe Learndia",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar("Vous n'avez pas d'autorisation", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            "Le nombre que vous avez saisi est invalide",
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else {
          self.props.enqueueSnackbar("Les données ne sont pas valides", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    const { etablissement } = this.state;
    return (
      <div
        style={{ border: "1px solid #EAEAEA", margin: "10px 0" }}
        className={classes.main}
      >
        {/* <div style={{padding:'10px 10px 0'}}>Le solde SMS est ne nombre d'sms qu'il  nombre d'sms qui est encore possible d'être envoyé par l'établissement</div> */}
        <Grid
          container
          className={classes.grid}
          justify="space-between"
          alignItems="center"
        >
          <div
            className="layout horizontal center"
            style={{
              margin: "10px 10px",
              backgroundColor: "var(--app-primary-color)",
              padding: "5px"
            }}
          >
            <div
              style={{ color: "white" }}
              className={classes.pageTitle}
              variant="div"
              component="div"
            >
              {formatMessage({ id: "AlertSolde_sold" })}
            </div>
            <div
              style={{ color: "white", padding: "0 10px" }}
              className={classes.pageTitle}
              variant="div"
              component="div"
            >
              {etablissement != null ? etablissement.soldeSms : 0}
            </div>
          </div>

          {this.state.progress === 1 && <CircularProgress thickness={1.5} size={25} className={classes.fabProgress} />}
          {this.state.progress !== 1 && <IconButton
            title={formatMessage({ id: "AlertSolde_6" })}
            aria-label="save"
            style={{
              color: "var(--app-primary-color)",
            }}
            onClick={this.doReload}
          >
            <AutorenewIcon fontSize="small" />
          </IconButton>}
          <Button
            onClick={this.openDialog}
            variant="contained"
            size="medium"
            style={{ marginRight: '10px' }}
            className={classes.buttonPrincipale}
          >
            {formatMessage({ id: "AlertSolde_CreditSold" })}
          </Button>
        </Grid>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {formatMessage({ id: "AlertSolde_dmde" })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {formatMessage({ id: "AlertSolde_nbSms" })}
            </DialogContentText>
            {this.state.inProgress ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {!this.state.controlled ? (
              <div className={classes.error}>
                {formatMessage({ id: "AlertSolde_check" })}
              </div>
            ) : null}
            <TextField
              id="value"
              label={formatMessage({ id: "AlertSolde_input" })}
              value={this.state.solde}
              placeholder="12500"
              onChange={this.handleChangeInput("solde")}
              margin="normal"
              type="number"
              fullWidth
              InputProps={{
                inputProps: { min: 0 }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ textTransform: "none" }}
              onClick={this.handleClose}
              color="primary"
            >
              {formatMessage({ id: "AlertSolde_cancl" })}
            </Button>
            <Button
              style={{ textTransform: "none" }}
              disabled={this.state.inProgress}
              onClick={this.handleAdd}
              color="primary"
            >
              {formatMessage({ id: "AlertSolde_send" })}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withSnackbar(withStyles(styles)(injectIntl(AlertSolde)));
