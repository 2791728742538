import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import AddIcon from "@material-ui/icons/Add";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import ClassIcon from "@material-ui/icons/Class";
// import CloseIcon from "@material-ui/icons/Close";
// import IconButton from "@material-ui/core/IconButton";

// import Snackbar from "@material-ui/core/Snackbar";

import DoneAllIcon from "@material-ui/icons/DoneAll";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { appProperties, fireWithObject, isObjectValueEquals, cancelEventPropagation } from "../../../components/LdUtils";
import FormControl from '@material-ui/core/FormControl';
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { injectIntl } from "react-intl";
import { withSnackbar } from "notistack";


import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";




const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: 'white',

  },
  root: {
    width: "100%",
    // marginTop: '30px',

  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },


  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: '0px'
  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "32px !important",
    height: "32px !important",
    borderRadius: "50% !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,

    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale: {
    margin: '2px 5px',
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },



  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  detailRootPanel: {
    padding: '0 !important'
  }
});

class AjouterSaisieEnseignantView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      classes: [],
      classe: null,
      enseignant: null,
      sequences: [],
      sequence: null,
      bareme: '',
      alert: '',
      inProgress: false,
      controlled: true,
      // openSnack: false,
      activeStep: 0,
      skipped: new Set(),

      categories: [],
      categorie: "---",
    };
    this.add = this.add.bind(this);
    // this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleNext = this.handleNext.bind(this);
    //this.isStepOptional = this.isStepOptional.bind(this);
    this.handleSelectClass = this.handleSelectClass.bind(this);
    this.handleSelectEnseignant = this.handleSelectEnseignant.bind(this);
    this.handleSelectSequence = this.handleSelectSequence.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);

  }

  //isStepOptional = step => step === 1;

  handleChangeCategorie = event => {
    cancelEventPropagation(event);
    let self = this;
    this.setState({ categorie: (event.target.value) }, (state) => {
      self.handleReset();

    });
  };
  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    if (activeStep === 3) {
      this.add();
    } else {
      this.setState({
        activeStep: activeStep + 1,
        skipped,
      });
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    /*if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }*/

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });


  };

  handleReset = () => {
    var self = this;

    this.setState({
      activeStep: 0,
      classe: null,
      enseignant: null,
      date: new Date(),
      sequences: [],
      sequence: null
    }, () => {
      self.listEnseignants();
      self.listSequences();
    });
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }
  getSteps() {
    return [
      this.props.intl.formatMessage({ id: "AjouterSaisieEnseignantView_24" }), 
      this.props.intl.formatMessage({ id: "AjouterSaisieEnseignantView_21" }), 
      this.props.intl.formatMessage({ id: "AjouterSaisieEnseignantView_22" }), 
      this.props.intl.formatMessage({ id: "AjouterSaisieEnseignantView_23" }),
    ];
  }

  getStepContent(step, state, formatMessage) {
    switch (step) {
      case 0:
        return (state && state.classe != null) ? (state.classe.name) : formatMessage({ id: "AjouterSaisieEnseignantView_1" });
      case 1:
        return (state && state.enseignant != null) ? (state.enseignant.matiereName || '#' + state.enseignant.matiere.name) : formatMessage({ id: "AjouterSaisieEnseignantView_2" });
      case 2:
        return (state && state.sequence != null) ? (state.sequence.trimestre.name + " " + state.sequence.name) : formatMessage({ id: "AjouterSaisieEnseignantView_3" });
      case 3:
        return formatMessage({ id: "AjouterSaisieEnseignantView_4" });
      default:
        return formatMessage({ id: "AjouterSaisieEnseignantView_5" });
    }
  }
  handleSelectClass(value) {
    this.setState({ classe: value });
  }
  handleSelectEnseignant(value) {
    this.setState({ enseignant: value });
  }
  handleSelectSequence(value) {
    this.setState({ sequence: value });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  handleChangeValue = name => event => {
    this.setState({ [name]: event.target.value });
  };

  // handleSnackBarClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   this.setState({ openSnack: false });
  // };

  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();
        // self.listEnseignants();
        // self.listSequences();
      });
    }
  }
  componentDidMount() {
    this.listCategorie();
    // this.listEnseignants();
    // this.listSequences();
  }



  add() {
    if (this.state.enseignant == null || this.state.sequence == null ||
      this.state.bareme == null || this.state.bareme.trim() === "" || parseInt(this.state.bareme) <= 0) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("alert", this.state.alert ? 1 : 0);
    //if(this.state.alert !=null)formData.append("message", this.state.message);
    formData.append("bareme", this.state.bareme);
    formData.append("enseignant", this.state.enseignant.id);
    formData.append("sequence", this.state.sequence.id);
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/note/add-saisie", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false });
        if (data.result_code === 1) {
          //self.setState({ openSnack: true });
          fireWithObject(document, 'new-saisie', { value: data.row });
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AjouterSaisieEnseignantView_19" }), { variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "left" } });

        } else if (data.result_code === 0) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AjouterSaisieEnseignantView_20" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "left" } });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  listSequences() {
    let self = this;
    this.setState({ sequences: [], progressSequence: 1 });
    var url = appProperties.basepathServer + '/manager/annee-scolaire/trimestres?limit=-1&load_sequences=1';
    if (this.state.annee != null) url += ("&id=" + this.state.annee.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ sequences: [], progressSequence: 0 });
      return;
    }
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          var sequences = [];
          var trimestre = null;
          (data.datas || []).forEach(e => {
            trimestre = Object.assign({}, e);
            delete trimestre.sequences;
            (e.sequences || []).forEach(seq => {
              seq['trimestre'] = trimestre
              sequences.push(seq);
            });
          });

          self.setState({ progressSequence: 0, sequences: sequences });

        } else {
          self.setState({ sequences: [], progressSequence: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ sequences: [], progressSequence: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  listEnseignants() {

    let self = this;
    this.setState({ classes: [], progressEnseignant: 1 });
    var url = appProperties.basepathServer + "/rest-enseignant/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1";
    if (this.props.annee != null) url += ("&annee_id=" + this.props.annee.id);
    if (this.props.userId != null) url += ("&user_id=" + this.props.userId);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [], progressEnseignant: 0 });
      return;
    }

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // var enseignants = [];
          // (data.datas || []).forEach(e=>{
          //   enseignants = enseignants.concat(e.enseignants);
          // });
          self.setState({ progressEnseignant: 0, classes: self.formatData({ enseignants: data.datas }) });

        } else {
          self.setState({ classes: [], progressEnseignant: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ classes: [], progressEnseignant: 3 });

        console.log('There was an error listed type de frais.');
      });
  }

  formatData(input) {
    if (null == input) return null;
    if (input.enseignants && Array.isArray(input.enseignants) && 0 < input.enseignants.length) {
      var enseignants = input.enseignants;
      var output = Object.assign({}, input);
      delete output.enseignants;
      var exist = false;
      var myReduce = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
        // console.log(accumulator);
        exist = accumulator.reduce((acc, cur, ind, arr) => {
          return acc ? acc : null != cur && null != currentValue.classeEtablissement && cur.id === currentValue.classeEtablissement.id;
        }, false);
        return exist ? accumulator : accumulator.concat(currentValue.classeEtablissement ? currentValue.classeEtablissement : null);
      }, []);
      var contents = [];
      var mat = null;
      console.log("myReduce ", myReduce);
      var myMap = myReduce.map((classe, index, array) => {
        contents = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
          if (null != classe && null != currentValue.classeEtablissement && classe.id === currentValue.classeEtablissement.id) {
            mat = Object.assign({}, currentValue);
            delete mat.classeEtablissement;
            return accumulator.concat(mat);
          } else return accumulator;
        }, []);
        classe["enseignants"] = contents;
        return classe;
      });
      output["classes"] = myMap;

      return output;
    }
    input["classes"] = [];
    return input;
  }

  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        <ExpansionPanel elevation={0}>
          <ExpansionPanelSummary classes={{ root: classes.detailRootPanel }} expandIcon={<ExpandMoreIcon />}>
            <div style={{ width: '100%' }} className="layout horizontal justified">
              <div className="layout vertical">
                <div className={classes.pageTitle} variant="div" component="div">
                  {formatMessage({ id: "AjouterSaisieEnseignantView_6" })}
                </div>
                <div className={classes.pageSubtitle} variant="div" component="div">
                  {formatMessage({ id: "AjouterSaisieEnseignantView_7" })}
                </div>
              </div>
              <div>
                <FormControl
                  style={{ height: "35px" }}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Select
                    title={formatMessage({
                      id: "ListClasseEtablissementView_4"
                    })}
                    displayEmpty
                    classes={{
                      selectMenu: classes.selectMenu,
                      root: classes.selectRoot
                    }}
                    value={this.state.categorie}
                    onChange={this.handleChangeCategorie}
                    input={
                      <OutlinedInput
                        labelWidth={this.state.labelWidth}
                        name="Choisir une classe"
                        id="outlined-classe-simple"
                        classes={{ input: classes.outlinedInput }}
                      />
                    }
                  >
                    {" "}
                    <MenuItem value={"---"}>
                      {formatMessage({ id: "ListClasseEtablissementView_3" })}
                    </MenuItem>
                    {this.state.categories.map((row, index) => (
                      <MenuItem title={row.description} key={index} value={row}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.detailRootPanel }} className="layout vertical">
            {this.state.categorie !== "---" && (<React.Fragment>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const props = {};
                  const labelProps = {};
                  //if (this.isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">{this.getStepContent(index, this.state, formatMessage)}</Typography>;
                  //}
                  if (this.isStepSkipped(index)) {
                    props.completed = false;
                  }
                  return (
                    <Step key={label} {...props}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div>
                {(activeStep === 0) && (
                  <div>
                    {this.state.progressEnseignant === 0 && (


                      <List>
                        {this.state.classes.classes.map((row, index) => (
                          <ListItem title={row.name} button key={index} onClick={() => this.handleSelectClass(row)}>
                            <ListItemAvatar>
                              <Avatar>
                                <ClassIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<span style={{ color: (this.state.classe != null && this.state.classe.id === row.id) ? 'var(--paper-green-500)' : 'var(--paper-grey-700)' }}>{row.name}</span>} />
                            {(this.state.classe != null && this.state.classe.id === row.id) ?
                              <ListItemAvatar title={formatMessage({ id: "AjouterSaisieEnseignantView_8" })} >
                                <Avatar style={{ color: 'var(--paper-green-500)' }}>
                                  <DoneAllIcon />
                                </Avatar>
                              </ListItemAvatar>
                              :
                              <ListItemAvatar title={formatMessage({ id: "AjouterSaisieEnseignantView_9" })}>
                                <Avatar>
                                  <AddIcon />
                                </Avatar>
                              </ListItemAvatar>
                            }


                          </ListItem>
                        ))}

                      </List>
                    )}

                    {this.state.progressEnseignant === 1 && (
                      <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                        <div style={{ padding: '15px' }}>
                          <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                        </div>
                        <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                          {formatMessage({ id: "ListRoleView_Load" })}
                        </div>
                      </div>
                    )}
                    {(this.state.progressEnseignant === 2 || (this.state.progressEnseignant === 0 && (!this.state.classes.classes || this.state.classes.classes.length === 0))) && (
                      <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                        {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                        <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                          {formatMessage({ id: "ListClasseAnneeView_3" })}
                        </div>
                      </div>
                    )}
                    {this.state.progressEnseignant === 3 && (
                      <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                        <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                        <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                          {formatMessage({ id: "ListRoleView_LoadError" })}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {(activeStep === 1) && (
                  <div>
                    {(this.state.classe != null || this.state.classe.enseignants.length > 0) && (
                      <List>
                        {this.state.classe.enseignants.map((row, index) => (
                          <ListItem title={row.matiereName || '#' + row.matiere.name} button key={index} onClick={() => this.handleSelectEnseignant(row)}>
                            <ListItemAvatar>
                              <Avatar>
                                <AssignmentIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<span style={{ color: (this.state.enseignant != null && this.state.enseignant.id === row.id) ? 'var(--paper-green-500)' : 'var(--paper-grey-700)' }}>{row.matiereName || '#' + row.matiere.name}</span>} secondary={this.state.classe.name} />
                            {(this.state.enseignant != null && this.state.enseignant.id === row.id) ?
                              <ListItemAvatar title={formatMessage({ id: "AjouterSaisieEnseignantView_10" })} >
                                <Avatar style={{ color: 'var(--paper-green-500)' }}>
                                  <DoneAllIcon />
                                </Avatar>
                              </ListItemAvatar>
                              :
                              <ListItemAvatar title={formatMessage({ id: "AjouterSaisieEnseignantView_11" })}>
                                <Avatar>
                                  <AddIcon />
                                </Avatar>
                              </ListItemAvatar>
                            }


                          </ListItem>
                        ))}

                      </List>
                    )}
                    {(this.state.classe == null || this.state.classe.enseignants.length === 0) && (
                      <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                        {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                        <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                          {formatMessage({ id: "AjouterSaisieEnseignantView_12" })}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {(activeStep === 2) && (
                  <div>
                    {this.state.progressSequence === 0 && (


                      <List>
                        {this.state.sequences.map((row, index) => (
                          <ListItem title={row.name} button key={index} onClick={() => this.handleSelectSequence(row)}>
                            <ListItemAvatar>
                              <Avatar>
                                <CalendarViewDayIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<span style={{ color: (this.state.sequence != null && this.state.sequence.id === row.id) ? 'var(--paper-green-500)' : 'var(--paper-grey-700)' }}>{row.name}</span>} secondary={row.trimestre.name} />
                            {(this.state.sequence != null && this.state.sequence.id === row.id) ?
                              <ListItemAvatar title={formatMessage({ id: "AjouterSaisieEnseignantView_13" })} >
                                <Avatar style={{ color: 'var(--paper-green-500)' }}>
                                  <DoneAllIcon />
                                </Avatar>
                              </ListItemAvatar>
                              :
                              <ListItemAvatar title={formatMessage({ id: "AjouterSaisieEnseignantView_14" })}>
                                <Avatar>
                                  <AddIcon />
                                </Avatar>
                              </ListItemAvatar>
                            }


                          </ListItem>
                        ))}

                      </List>
                    )}

                    {this.state.progressSequence === 1 && (
                      <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                        <div style={{ padding: '15px' }}>
                          <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                        </div>
                        <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                          {formatMessage({ id: "ListRoleView_Load" })}
                        </div>
                      </div>
                    )}
                    {(this.state.progressSequence === 2 || (this.state.progressSequence === 0 && (this.state.sequences.length === 0))) && (
                      <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                        {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                        <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                          {formatMessage({ id: "AjouterSaisieEnseignantView_15" })}
                        </div>
                      </div>
                    )}
                    {this.state.progressSequence === 3 && (
                      <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
                        <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                        <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                          {formatMessage({ id: "ListRoleView_LoadError" })}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {(activeStep === 3) && (
                  <div style={{ padding: '20px' }}>
                    {this.state.inProgress
                      ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                      : null
                    }
                    {!this.state.controlled
                      ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
                      : null
                    }
                    <div className="layout horizontal center">

                      <div className="flex">
                        <FormControl margin="dense" required fullWidth>
                          <InputLabel htmlFor="motif">{formatMessage({ id: "AjouterSaisieEnseignantView_16" })}</InputLabel>
                          <Input
                            id="bareme"
                            required
                            name="bareme"
                            type="number"
                            min="1"
                            placeholder="Ex: 20"
                            defaultValue={this.state.bareme}
                            autoComplete="bareme"
                            onChange={this.handleChangeValue('bareme')}
                            InputProps={{
                              inputProps: { min: 0 },
                            }}

                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="layout horizontal center">
                      <div className="flex" style={{ paddingRight: '10px' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.alert}
                              onChange={this.handleChange('alert')}
                              value="alert"
                              color="primary"
                            />
                          }
                          label={formatMessage({ id: "AjouterSaisieEnseignantView_17" })}
                        />
                      </div>

                    </div>
                  </div>
                )}



                <div className="layout horizontal center justified">
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    variant="contained"
                    className={classes.buttonGreyWhite}
                  >
                    {formatMessage({ id: "AjouterIndisciplineView_9" })}
                  </Button>

                  <Button
                    disabled={(activeStep === 0 && this.state.classe == null) || (activeStep === 1 && this.state.enseignant == null) || (activeStep === 2 && this.state.sequence == null)}
                    variant="contained"
                    onClick={this.handleNext}
                    className={classes.buttonPrincipale}
                  >
                    {activeStep === steps.length - 1 ? formatMessage({ id: "AjouterTypeAlertView_15" }) : formatMessage({ id: "AjouterSaisieEnseignantView_18" })}
                  </Button>
                </div>
              </div>
            </React.Fragment>)}
            {this.state.categorie === "---" && (
              <div
                style={{
                  backgroundColor: "white",
                  margin: "50px 50px 0 50px",
                  padding: "50px"
                }}
                className="layout vertical center-center"
              >
                {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                <div
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    width: "75%",
                    color: "var(--paper-grey-900)"
                  }}
                  className="layout horizontal center-center"
                >
                  {formatMessage({ id: "ListCahierView_14" })}
                </div>
              </div>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {/* <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{formatMessage({ id: "AjouterSaisieEnseignantView_19" })}</span>}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarClose}
            >
              OK!
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        /> */}

      </main>
    );
  }
}
AjouterSaisieEnseignantView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(
  injectIntl(AjouterSaisieEnseignantView)
));
