import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slide from '@material-ui/core/Slide';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// import CloseIcon from "@material-ui/icons/Close";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// import Snackbar from "@material-ui/core/Snackbar";
import { withSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, FormattedMessage, FormattedTime, injectIntl, intlShape } from 'react-intl';
import ProgressiveImage from 'react-progressive-image';
import LdImageUploader from "../../../components/LdImageUploader";
import LdSelect from "../../../components/LdSelect";
import LdSelectAnneeScolaire from "../../../components/LdSelectAnneeScolaire";
import LdSelectClasseAnnee from "../../../components/LdSelectClasseAnnee";
import StatsFraisEleve from "../../../components/StatsFraisEleve";
import LdSelectEleve from "../../../components/LdSelectEleve";
import { appProperties, defaultAvatar, deleteClassInProps, isObjectValueEquals, userProfileUrl } from "../../../components/LdUtils";
import DossierEleve from "../../../scolarite/view/eleve/DossierEleve";


const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "100px !important",
    height: "100px !important",
    borderRadius: '0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  dialogRoot: {
    top: '64px !important',
    left: '25% !important',
    right: '0 !important',
    bottom: '0 !important',
    // z-index: 1300;
    // position: fixed;
    paddingTop: "15px !important",
    paddingLeft: '18px !important',
    paddingRight: '45px !important'
  },
  backdrop: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "hidden"
  },

  fieldset: {
    marginTop: '20px',
    border: '1px solid #DDDDDD',
    borderRadius: '3px'
  },
  legend: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    padding: '5px',
  },
  addAction: {
    color: "var(--app-primary-color)"
  },

  titleBarContainer: {
    padding: "10px",
    borderRadius: "0 !important",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  collapsedPaper: {
    padding: "20px",
    borderRadius: "0 !important",
    backgroundColor: "white"
  },
  avatarIcon: {
    width: '40px',
    height: '40px',
    margin: "0 15px 0 5px",
    color: '#fff',
    backgroundColor: 'var(--app-primary-color)',
  },
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
       &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
    <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class ItemEleve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,

      etablissement: this.props.etablissement,
      annee: this.props.annee,

      fullname: this.props.row.fullname || "",
      matricule: this.props.row.matricule || '',
      firstname: this.props.row.firstname || '',
      lastname: this.props.row.lastname || '',
      phone: this.props.row.phone || '',
      phone2: this.props.row.phone2 || '',
      quartier: this.props.row.quartier || '',
      parentName: this.props.row.parentName || '',
      motherName: this.props.row.motherName || '',
      parentAddress: this.props.row.parentAddress || '',
      nationalite: this.props.row.nationalite || '',
      derniereClasse: this.props.row.derniereClasse || '',
      derniereMoyenne: this.props.row.derniereMoyenne || 0,
      sexe: this.props.row.sexe + '',
      moyenEntree: this.props.row.moyenEntree + '' || -1,
      dossierMedical: !!(this.props.row.dossierMedical === -1 ? 0 : this.props.row.dossierMedical),
      handicape: !!(this.props.row.handicape === -1 ? 0 : this.props.row.handicape),
      apteSport: !!(this.props.row.apteSport === -1 ? 0 : this.props.row.apteSport),
      acceptReglement: !!(this.props.row.acceptReglement === -1 ? 0 : this.props.row.acceptReglement),
      dateConcours: this.props.row.dateConcours || '',
      deuxiemeLangue: this.props.row.deuxiemeLangue || '',
      pathologieParticuliere: this.props.row.pathologieParticuliere || '',
      frequencePerteConnaissance: this.props.row.frequencePerteConnaissance || '',
      tutorName: this.props.row.tutorName || '',
      tutorAddress: this.props.row.tutorAddress || '',
      tutorPhone: this.props.row.tutorPhone || '',
      dernierEtablissement: this.props.row.dernierEtablissement || "",
      redouble: !!(this.props.row.redouble === -1 ? 0 : this.props.row.redouble),
      birthplace: this.props.row.birthplace || "",
      birthdate: this.props.row.birthdate || "",
      avatar: this.props.row.photo || "",
      pathAvatar: this.props.row.pathPhoto || "",
      isOldStudent: !!(this.props.row.isOldStudent ? 1 : 0),
      isInside: !!(this.props.row.isInside),

      canUpdateMatricule: 0,


      classe: this.props.row.classeEtablissement != null ? Object.assign({
        id: this.props.row.classeEtablissement.id,
        value: this.props.row.classeEtablissement.id,
        label: this.props.row.classeEtablissement.name,
        option: this.props.row.classeEtablissement

      }, this.props.row.classeEtablissement) : null,
      controlled: true,
      openConfirm: false,
      progressDelete: false,
      row: this.props.row,
      // openSnack: false,
      showMore: false,
      openDossier: false,

      collapsed1: true,
      collapsed2: false,
      collapsed3: false,
      collapsed4: false,
      collapsed5: false,
      lastEleve: this.props.row.lastEleve || null,

      oldAnnee: null,
      oldCategorieEtablissement: null,
      oldClasseAnnee: null,
      //oldEleve: null,
      searchMode: "0"
    };

    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    // this.handleMatriculeChange = this.handleMatriculeChange.bind(this);
    // this.handlePhoneChange = this.handlePhoneChange.bind(this);
    // this.handleChangeClass = this.handleChangeClass.bind(this);
    // this.handleFullnameChange = this.handleFullnameChange.bind(this);
    this.handleClickOpenDialog = this.handleClickOpenDialog.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    // this.handleSnackBarDelete = this.handleSnackBarDelete.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);

    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handeCheck = this.handeCheck.bind(this);

    this.dossierRef = React.createRef();
    this.handleClickOpenDossier = this.handleClickOpenDossier.bind(this);
    this.handleCloseDossier = this.handleCloseDossier.bind(this);

    this.newImageUploaded = this.newImageUploaded.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);

    this.handleChangeOldAnnee = this.handleChangeOldAnnee.bind(this);
    this.handleChangeOldCategorieEtablissement = this.handleChangeOldCategorieEtablissement.bind(this);
    this.handleChangeOldClasse = this.handleChangeOldClasse.bind(this);
    this.handleChangeOldEleve = this.handleChangeOldEleve.bind(this);

  }

  handleChangeOldAnnee(item) {
    this.setState({ oldAnnee: item ? item : null, oldClasseAnnee: null, lastEleve: null });
  }

  handleChangeOldCategorieEtablissement(item) {
    this.setState({ oldCategorieEtablissement: item ? item : null, oldClasseAnnee: null, lastEleve: null });
  }

  handleChangeOldClasse(item) {
    this.setState({ oldClasseAnnee: item ? item : null, lastEleve: null });
  }
  handleChangeOldEleve(item) {
    if (item != null && item.id === this.props.row.id) {
      //(this.props.intl.formatMessage({ id: "ItemEleve_19" }));
      this.props.enqueueSnackbar(
        this.props.intl.formatMessage({ id: "ItemEleve_19" }),
        {
          variant: "error ",
          anchorOrigin: { vertical: "bottom", horizontal: "left" }
        }
      )
    }
    else
      this.setState({ lastEleve: item ? item : null });
  }

  handleClickOpen = name => e => {
    var val = {};
    val[name] = !this.state[name];
    this.setState(val);

    //this.setState(state => ({ name: !state.collapsed }));
  };
  handleClickOpenDossier = () => {
    // console.log('autorisation',this.refs.auth);
    console.log(this.dossierRef);
    //this.dossierRef.doOpenDialog();
    var self = this;
    this.setState({ openDossier: true }, () => {
      setTimeout(() => {
        self.dossierRef.handleClickOpenDialog();
      }, 0);
    });
  };

  handleCloseDossier = () => {
    this.setState({ openDossier: false });
    //this.dossierRef.doCloseDialog();
  };
  newImageUploaded(name, path) {
    // console.log(" new image uplaoded in Ajouter");
    // console.log(name);
    // console.log(path);
    this.setState({ avatar: name, pathAvatar: path });
  }
  handleShowMore(event) {
    this.setState({ showMore: !this.state.showMore });
  }
  // handleSnackBarDelete = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   this.setState({ openSnack: false });
  // };

  handleClickOpenDialog = () => {
    this.setState({ openConfirm: true });
  };

  handleClose = () => {
    this.setState({ openConfirm: false });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({

          fullname: self.props.row.fullname || "",
          matricule: self.props.row.matricule || '',
          firstname: self.props.row.firstname || '',
          lastname: self.props.row.lastname || '',
          phone: self.props.row.phone || '',
          phone2: self.props.row.phone2 || '',
          quartier: self.props.row.quartier || '',
          parentName: self.props.row.parentName || '',
          motherName: self.props.row.motherName || '',
          parentAddress: self.props.row.parentAddress || '',
          nationalite: self.props.row.nationalite || '',
          derniereClasse: self.props.row.derniereClasse || '',
          derniereMoyenne: self.props.row.derniereMoyenne || 0,
          sexe: self.props.row.sexe + '' || -1,
          moyenEntree: self.props.row.moyenEntree + '' || -1,
          dossierMedical: !!(self.props.row.dossierMedical === -1 ? 0 : self.props.row.dossierMedical),
          handicape: !!(self.props.row.handicape === -1 ? 0 : self.props.row.handicape),
          apteSport: !!(self.props.row.apteSport === -1 ? 0 : self.props.row.apteSport),
          acceptReglement: !!(self.props.row.acceptReglement === -1 ? 0 : self.props.row.acceptReglement),
          dateConcours: self.props.row.dateConcours || '',
          deuxiemeLangue: self.props.row.deuxiemeLangue || '',
          pathologieParticuliere: self.props.row.pathologieParticuliere || '',
          frequencePerteConnaissance: self.props.row.frequencePerteConnaissance || '',
          tutorName: self.props.row.tutorName || '',
          tutorAddress: self.props.row.tutorAddress || '',
          tutorPhone: self.props.row.tutorPhone || '',
          dernierEtablissement: self.props.row.dernierEtablissement || "",
          redouble: !!(self.props.row.redouble === -1 ? 0 : self.props.row.redouble),
          birthplace: self.props.row.birthplace || "",
          birthdate: self.props.row.birthdate || "",
          //classe: self.props.row.classeEtablissement || null,
          avatar: self.props.row.photo || "",
          pathAvatar: self.props.row.pathPhoto || "",
          canUpdateMatricule: 0,
          lastEleve: self.props.row.lastEleve || null,
          isOldStudent: !!(self.props.row.isOldStudent ? 1 : 0),
          isInside: !!(self.props.row.isInside),

          classe: self.props.row.classeEtablissement != null ? Object.assign({
            id: self.props.row.classeEtablissement.id,
            value: self.props.row.classeEtablissement.id,
            label: self.props.row.classeEtablissement.name,
            option: self.props.row.classeEtablissement

          }, self.props.row.classeEtablissement) : null,

        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }
  handleFullnameChange(event) {
    this.setState({ fullname: event.target.value });
  }

  handleEdit = () => {
    this.setState({ modeEdit: true });
  }

  reset = () => {
    this.setState({ modeEdit: false, controlled: true });
  }


  handlePhoneChange(event) {
    this.setState({ phone: event.target.value });
  }
  handleMatriculeChange(event) {
    this.setState({ matricule: event.target.value });
  }
  handleChangeClass(item) {
    console.log('new item');
    console.log(item);
    this.setState({ classe: item ? item.classeEtablissement : null });
  };

  handleChangeInput = name => e => {
    var val = {};

    val[name] = e.target.value;
    this.setState(val);
  };
  handeCheck = (name, value) => e => {
    var val = {};
    val[name] = !!value ? false : true;
    this.setState(val);
  };
  edit = () => {
    if (this.state.lastname == null || this.state.lastname.trim() === "") {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var classId =
      this.state.classe &&
        this.state.classe !== "---" &&
        this.state.classe != null
        ? this.state.classe.id
        : "";
    var formData = new FormData(); // Currently empty
    formData.append("photo", this.state.avatar);
    formData.append("acceptReglement", this.state.acceptReglement ? 1 : 0);
    formData.append("apteSport", this.state.apteSport ? 1 : 0);
    formData.append("handicape", this.state.handicape ? 1 : 0);
    formData.append("dossierMedical", this.state.dossierMedical ? 1 : 0);
    formData.append("frequencePerteConnaissance", this.state.frequencePerteConnaissance);
    formData.append("pathologieParticuliere", this.state.pathologieParticuliere);
    formData.append("deuxiemeLangue", this.state.deuxiemeLangue);
    formData.append("dateConcours", this.state.dateConcours);
    formData.append("moyenEntree", this.state.moyenEntree);
    formData.append("sexe", this.state.sexe);
    formData.append("phone2", this.state.phone2);
    formData.append("tutorAddress", this.state.tutorAddress);
    formData.append("tutorName", this.state.tutorName);
    formData.append("parentAddress", this.state.parentAddress);
    formData.append("tutorPhone", this.state.tutorPhone);
    formData.append("motherName", this.state.motherName);
    formData.append("parentName", this.state.parentName);
    formData.append("derniereMoyenne", this.state.derniereMoyenne);
    formData.append("derniereClasse", this.state.derniereClasse);
    formData.append("dernierEtablissement", this.state.dernierEtablissement);
    formData.append("quartier", this.state.quartier);
    formData.append("nationalite", this.state.nationalite);
    formData.append("phone", this.state.phone);
    formData.append("matricule", this.state.matricule);
    formData.append("lastname", this.state.lastname);
    formData.append("firstname", this.state.firstname);
    formData.append("redouble", this.state.redouble ? 1 : 0);
    formData.append("birthplace", this.state.birthplace);
    formData.append("birthdate", this.state.birthdate);
    formData.append("classe_id", classId);
    formData.append("can_update_matricule", this.state.canUpdateMatricule);
    formData.append("last_eleve_id", this.state.lastEleve != null ? this.state.lastEleve.id : "");
    formData.append("is_old_student", this.state.isOldStudent ? 1 : 0);
    formData.append("is_inside", this.state.isInside ? 1 : 0);

    // formData.append("classe_id", this.state.classe ? this.state.classe.id : '');
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/rest-eleve/edit?id=" + this.props.row.id, {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.reset();
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItem('update', datas);
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEleve_20" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );

        } else if (data.result_code === -5) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterEleveView__9" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
        this.setState({ inProgress: false });

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }
  handleClickDelete = () => {
    var self = this;
    this.setState({ progressDelete: true });
    fetch(
      appProperties.basepathServer + "/rest-eleve/delete?id=" + this.props.row.id, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ progressDelete: false });
        if (data.result_code === 1) {
          self.props.onUpdateItem("delete", { position: self.state.position });
          self.handleClose();
          // self.setState({ openSnack: true });
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemEleve_8" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ progressDelete: false });
        alert("There was an error added.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/rest-eleve/set-status?status=1&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", { position: self.state.position, row: this.props.row });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer + "/rest-eleve/set-status?status=0&id=" + this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    ).then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });  
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", { position: self.state.position, row: this.props.row });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };

  render() {
    const { classes, row } = this.props;
    const { collapsed1, collapsed2, collapsed3, collapsed4, collapsed5 } = this.state;

    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>




        <Dialog
          fullScreen
          TransitionComponent={Transition}
          classes={{ root: classes.dialogRoot }}
          BackdropProps={{
            classes: {
              root: classes.backdrop
            }
          }}
          open={this.state.modeEdit}
          onClose={this.reset}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Editer les informations de l'élève: "
            + (this.props.row != null ? (this.props.row.fullname || '@' + this.props.row.user.name) : '')}</DialogTitle>
          <DialogContent style={{ backgroundColor: "#EAEAEA" }}>
            {/* <DialogContentText id="alert-dialog-description">
              Completez tous les champs
            </DialogContentText> */}
            <div style={{ padding: '5px 5px' }}>
              {this.state.inProgress
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }

              {!this.state.controlled
                ? <div className={classes.error}>{formatMessage({ id: "AjouterClasseEtablissementView_3" })}</div>
                : null
              }
            </div>
            <div>














              {/* Informations générales */}
              <Paper
                style={{ marginTop: "20px", marginBottom: "20px" }}
                elevation={0}
              >
                <Paper
                  className={classes.titleBarContainer}
                  onClick={this.handleClickOpen('collapsed1')}
                  elevation={0}
                >
                  <div className="layout horizontal center">
                    {/* <span className={classes.addAction}>
                    <AddIcon style={{ fontSize: 50 }} />
                  </span> */}
                    <Avatar className={classes.avatarIcon}>
                      1
                  </Avatar>
                    <div className={classes.titleBlock}>
                      <div
                        className={classes.pageTitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "AjouterEleveView_86" })}
                      </div>
                      <div
                        className={classes.pageSubtitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "AjouterEleveView_91" })}
                      </div>
                    </div>
                  </div>
                </Paper>
                <Collapse in={collapsed1}>
                  {collapsed1 ? (<Paper elevation={0} className={classes.collapsedPaper}>




                    {this.state.lastEleve != null && <div style={{ marginTop: '5px', marginBottom: '25px', backgroundColor: "#F1F1F1", padding: '10px', border: '1px dashed #EAEAEA' }}>
                      <div className={classes.pageSubtitle} component="legend">{formatMessage({ id: "ItemEleve_12" })}</div>

                      <Grid container className={classes.grid} justify="flex-start">
                        <div>
                          <ProgressiveImage src={this.state.lastEleve.pathPhoto} placeholder={defaultAvatar}>
                            {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                          </ProgressiveImage>
                        </div>

                        <div className="flex" style={{ paddingLeft: "15px" }}>
                          <div style={{ fontSize: "15px", fontWeight: "600" }}>
                            {this.state.lastEleve.fullname != null && this.state.lastEleve.fullname !== "" ? this.state.lastEleve.fullname : '***********'}

                          </div>
                          <div className="layout horizontal center">
                            <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}> {formatMessage({ id: "ItemEleve_9" })}</span>
                            <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>
                              <a className="no-underline capitalize" title={'@' + this.state.lastEleve.user.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(this.state.lastEleve.user)}>
                                @{this.state.lastEleve.user.name}
                              </a>
                            </span>
                          </div>
                          <div style={{ fontSize: "15px", fontWeight: "600" }} className='layout horizontal center'>
                            <span className={classes.name}> {this.state.lastEleve.classeEtablissement.name}</span>
                          </div>
                          <div>
                            <span title={formatMessage({ id: "AjouterEleveView_5" })} style={{ fontSize: "small", backgroundColor: 'var(--app-primary-color)', padding: '0 5px', color: 'white' }}>  {this.state.lastEleve.matricule}</span>
                          </div>
                          <div className="layout horizontal center">
                            <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "ItemEleve_6" })} </span>
                            <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{this.state.lastEleve.phone != null && this.state.lastEleve.phone !== "" ? this.state.lastEleve.phone : '###########'}</span>
                          </div>

                        </div>
                        <div className="layout horizontal center-center">
                          <IconButton
                            title={formatMessage({ id: "ItemEleve_13" })}
                            classes={{ root: classes.disable }}
                            aria-label={formatMessage({ id: "ItemEleve_13" })}
                            onClick={() => {
                              this.setState({ lastEleve: null });
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </Grid>
                      <StatsFraisEleve eleveId={this.state.lastEleve.id} />

                    </div>}

                    {this.state.lastEleve == null && <div style={{ marginTop: '5px', marginBottom: '25px', backgroundColor: "#F1F1F1", padding: '10px', border: '1px dashed #EAEAEA' }}>
                      <div className={classes.pageSubtitle} component="legend">{formatMessage({ id: "ItemEleve_14" })}</div>
                      <FormControl
                        style={{ width: "100%", marginTop: '15px' }}
                        component="fieldset" className="layout horizontal center">
                        <FormLabel className={classes.pageSubtitle} component="legend">{formatMessage({ id: "AjouterOldEleveView_6" })}</FormLabel>
                        <RadioGroup
                          aria-label="searchMode"
                          name="searchMode"
                          className="layout horizontal center"
                          value={this.state.searchMode}
                          defaultValue={this.state.searchMode}
                          onChange={this.handleChangeInput('searchMode')}
                        >
                          <FormControlLabel value="0" labelPlacement="end" control={<Radio />} label={formatMessage({ id: "AjouterOldEleveView_7" })} />
                          <FormControlLabel value="1" labelPlacement="end" control={<Radio />} label={formatMessage({ id: "AjouterOldEleveView_1" })} />

                        </RadioGroup>
                      </FormControl>
                      {parseInt(this.state.searchMode) === 0 && <div>
                        <LdSelectEleve
                          label={formatMessage({ id: "AjouterOldEleveView_8" })}
                          placeholder={formatMessage({ id: "AjouterOldEleveView_9" })}
                          handleValue={this.handleChangeOldEleve}
                          defaultValue={this.state.lastEleve}
                          onlySearch
                          isSearchable

                          baseUrl={
                            appProperties.basepathServer +
                            "/rest-eleve/search?etablissement_id=" + this.props.etablissement.id +
                            "&limit=-1&only_published=1&search="}

                          {...deleteClassInProps(this.props)}
                        />
                      </div>}
                      {parseInt(this.state.searchMode) === 1 && <div>
                        <div style={{ width: "100%" }}>
                          <LdSelectAnneeScolaire
                            label={formatMessage({ id: "AjouterOldEleveView_2" })}
                            placeholder={formatMessage({ id: "AjouterOldEleveView_2" })}
                            handleValue={this.handleChangeOldAnnee}

                            isSearchable

                            baseUrl={
                              appProperties.basepathServer +
                              "/rest-etablissement/list-enabled-annee-scolaires?id=" +
                              this.props.etablissement.id +
                              "&limit=-1"
                            }
                            {...deleteClassInProps(this.props)}
                          />
                        </div>
                        <br />

                        <div style={{ width: "100%" }}>
                          <LdSelect
                            label={formatMessage({ id: "AjouterOldEleveView_3" })}
                            placeholder={formatMessage({ id: "AjouterOldEleveView_3" })}
                            handleValue={this.handleChangeOldCategorieEtablissement}
                            firstLoad
                            isSearchable

                            baseUrl={
                              appProperties.basepathServer +
                              "/rest-categorie-etablissement/list?only_published=1&etablissement_id=" +
                              this.props.etablissement.id +
                              "&limit=-1"
                            }
                            {...deleteClassInProps(this.props)}
                          />
                        </div>
                        <br />
                        <div style={{ width: "100%" }}>
                          <LdSelectClasseAnnee
                            label={formatMessage({ id: "AjouterOldEleveView_4" })}
                            placeholder={formatMessage({ id: "AjouterOldEleveView_4" })}
                            handleValue={this.handleChangeOldClasse}
                            defaultValue={this.state.oldClasseAnnee}
                            firstLoad
                            isSearchable

                            baseUrl={(this.state.oldAnnee != null && this.state.oldCategorieEtablissement != null) ?
                              appProperties.basepathServer +
                              "/manager/annee-scolaire/classes?id=" + this.state.oldAnnee.id +
                              "&categorie_etablissement_id=" + this.state.oldCategorieEtablissement.id +
                              "&limit=-1" : ""}

                            {...deleteClassInProps(this.props)}
                          />
                        </div>
                        <br />
                        <div style={{ width: "100%" }}>
                          <LdSelectEleve
                            label={formatMessage({ id: "AjouterOldEleveView_5" })}
                            placeholder={formatMessage({ id: "AjouterOldEleveView_5" })}
                            handleValue={this.handleChangeOldEleve}
                            defaultValue={this.state.lastEleve}
                            firstLoad
                            isSearchable

                            baseUrl={(this.state.oldAnnee != null && this.state.oldClasseAnnee != null) ?
                              appProperties.basepathServer +
                              "/rest-eleve/list?annee_id=" + this.state.oldAnnee.id +
                              "&etablissement_id=" + this.props.etablissement.id +
                              "&classe_id=" + this.state.oldClasseAnnee.classeEtablissement.id +
                              "&limit=-1&only_published=1" : ""}

                            {...deleteClassInProps(this.props)}
                          />
                        </div>
                      </div>}
                    </div>}
































                    <div style={{ width: "100%" }}>
                      <LdSelectClasseAnnee
                        label={formatMessage({ id: "AjouterEleveView_4" })}
                        handleValue={this.handleChangeClass}
                        firstLoad
                        isSearchable
                        defaultValue={
                          {
                            label: this.state.classe != null ? (this.state.classe.name) : "",
                            value: this.state.classe != null ? this.state.classe.id : '',
                            id: this.state.classe != null ? this.state.classe.id : '',
                          }
                        }
                        baseUrl={
                          appProperties.basepathServer +
                          "/manager/annee-scolaire/classes?id=" +
                          this.props.annee.id +
                          "&limit=-1"
                        }
                        {...deleteClassInProps(this.props)}
                      />
                    </div>
                    <div className="layout horizontal center" style={{ width: "100%", }}>
                      <TextField
                        id="matricule"
                        label={formatMessage({ id: "AjouterEleveView_5" })}
                        fullWidth
                        value={this.state.matricule}
                        placeholder={formatMessage({ id: "AjouterEleveView_7" })}
                        onChange={this.handleChangeInput('matricule')}
                        margin="normal"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={this.state.canUpdateMatricule}
                            ckecked={this.state.canUpdateMatricule}
                            color="primary"
                            onChange={this.handeCheck('canUpdateMatricule', this.state.canUpdateMatricule)}
                          />
                        }
                        label={formatMessage({ id: "AjouterEleveView_92" })}
                      />
                    </div>


                    <TextField
                      id="lastname"
                      label={formatMessage({ id: "AjouterEleveView_2" })}
                      value={this.state.lastname}
                      placeholder=""
                      fullWidth
                      onChange={this.handleChangeInput('lastname')}
                      helperText={formatMessage({ id: "AjouterEleveView_3" })}
                      margin="normal"
                    />
                    <TextField
                      id="firstname"
                      label={formatMessage({ id: "AjouterEleveView_10" })}
                      value={this.state.firstname}
                      placeholder=""
                      fullWidth

                      onChange={this.handleChangeInput('firstname')}
                      helperText={formatMessage({ id: "AjouterEleveView_11" })}
                      margin="normal"
                    />
                    <div className="layout horizontal center" style={{ width: "100%", }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={this.state.isOldStudent}
                            ckecked={this.state.isOldStudent}
                            defaultChecked={this.state.isOldStudent}

                            color="primary"
                            onChange={this.handeCheck('isOldStudent', this.state.isOldStudent)}
                          />
                        }
                        label={formatMessage({ id: "AjouterEleveView_93" })}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={this.state.redouble}
                            ckecked={this.state.redouble}
                            defaultChecked={this.state.redouble}
                            color="primary"
                            onChange={this.handeCheck('redouble', this.state.redouble)}
                          />
                        }
                        label={formatMessage({ id: "AjouterEleveView_12" })}
                      />
                    </div>

                    <TextField
                      id="birthdate"
                      label={formatMessage({ id: "AjouterEleveView_25" })}
                      value={this.state.birthdate}
                      type="date"
                      fullWidth
                      helperText={formatMessage({ id: "AjouterEleveView_26" })}
                      onChange={this.handleChangeInput('birthdate')}
                      margin="normal"
                    />
                    <TextField
                      id="birthplace"
                      label={formatMessage({ id: "AjouterEleveView_13" })}
                      value={this.state.birthplace}
                      fullWidth
                      helperText={formatMessage({ id: "AjouterEleveView_14" })}
                      onChange={this.handleChangeInput('birthplace')}
                      margin="normal"
                    />
                    <FormControl
                      style={{ width: "100%", marginTop: '15px' }}
                      component="fieldset" className="layout horizontal center">
                      <FormLabel component="legend">{formatMessage({ id: "AjouterEleveView_27" })}</FormLabel>
                      <RadioGroup
                        aria-label="sexe"
                        name="sexe"
                        className="layout horizontal center"
                        value={this.state.sexe}
                        defaultValue={this.state.sexe}
                        onChange={this.handleChangeInput('sexe')}
                      >
                        <FormControlLabel value="1" labelPlacement="end" control={<Radio />} label={formatMessage({ id: "AjouterEleveView_28" })} />
                        <FormControlLabel value="2" labelPlacement="end" control={<Radio />} label={formatMessage({ id: "AjouterEleveView_29" })} />

                      </RadioGroup>
                    </FormControl>

                    <TextField
                      id="nationalite"
                      label={formatMessage({ id: "AjouterEleveView_30" })}
                      value={this.state.nationalite}
                      fullWidth
                      placeholder={formatMessage({ id: "AjouterEleveView_31" })}
                      onChange={this.handleChangeInput('nationalite')}
                      margin="normal"
                    />
                    <TextField
                      id="quartier"
                      label={formatMessage({ id: "AjouterEleveView_32" })}
                      value={this.state.quartier}
                      fullWidth
                      placeholder={formatMessage({ id: "AjouterEleveView_33" })}
                      onChange={this.handleChangeInput('quartier')}
                      margin="normal"
                    />

                  </Paper>) : null}
                </Collapse>
              </Paper>















              <Paper
                style={{ marginTop: "20px", marginBottom: "20px" }}
                elevation={0}
              >
                <Paper
                  className={classes.titleBarContainer}
                  onClick={this.handleClickOpen('collapsed2')}
                  elevation={0}
                >
                  <div className="layout horizontal center">
                    {/* <span className={classes.addAction}>
                    <AddIcon style={{ fontSize: 50 }} />
                  </span> */}
                    <Avatar className={classes.avatarIcon}>
                      2
                  </Avatar>
                    <div className={classes.titleBlock}>
                      <div
                        className={classes.pageTitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "AjouterEleveView_87" })}
                      </div>
                      <div
                        className={classes.pageSubtitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "AjouterEleveView_91" })}
                      </div>
                    </div>
                  </div>
                </Paper>
                <Collapse in={collapsed2}>
                  {collapsed2 ? (<Paper elevation={0} className={classes.collapsedPaper}>
                    {/* Parents ou tuteurs */}

                    <TextField
                      id="parentName"
                      label={formatMessage({ id: "AjouterEleveView_34" })}
                      value={this.state.parentName}
                      placeholder=""
                      fullWidth
                      onChange={this.handleChangeInput('parentName')}
                      helperText={formatMessage({ id: "AjouterEleveView_35" })}
                      margin="normal"
                    />

                    <TextField
                      id="motherName"
                      label={formatMessage({ id: "AjouterEleveView_95" })}
                      value={this.state.motherName}
                      placeholder=""
                      fullWidth
                      onChange={this.handleChangeInput('motherName')}
                      helperText={formatMessage({ id: "AjouterEleveView_96" })}
                      margin="normal"
                    />
                    <TextField
                      id="parentAddress"
                      label={formatMessage({ id: "AjouterEleveView_36" })}
                      value={this.state.parentAddress}
                      placeholder=""
                      fullWidth
                      onChange={this.handleChangeInput('parentAddress')}
                      helperText={formatMessage({ id: "AjouterEleveView_37" })}
                      margin="normal"
                    />
                    <TextField
                      id="phone"
                      type="tel"
                      label={formatMessage({ id: "AjouterEleveView_6" })}
                      fullWidth
                      value={this.state.phone}
                      placeholder={formatMessage({ id: "AjouterEleveView_8" })}
                      onChange={this.handleChangeInput('phone')}
                      helperText={formatMessage({ id: "AjouterEleveView_64" })}

                      margin="normal"
                    />

                    <TextField
                      id="phone2"
                      label={formatMessage({ id: "AjouterEleveView_39" })}
                      value={this.state.phone2}
                      placeholder=""
                      onChange={this.handleChangeInput('phone2')}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      id="tutorName"
                      label={formatMessage({ id: "AjouterEleveView_21" })}
                      value={this.state.tutorName}
                      placeholder=""
                      onChange={this.handleChangeInput('tutorName')}
                      fullWidth
                      helperText={formatMessage({ id: "AjouterEleveView_22" })}
                      margin="normal"
                    />
                    <TextField
                      id="tutorAddress"
                      label={formatMessage({ id: "AjouterEleveView_38" })}
                      value={this.state.tutorAddress}
                      placeholder=""
                      fullWidth
                      onChange={this.handleChangeInput('tutorAddress')}
                      helperText={formatMessage({ id: "AjouterEleveView_38" })}
                      margin="normal"
                    />
                    <TextField
                      id="tutorPhone"
                      label={formatMessage({ id: "AjouterEleveView_97" })}
                      value={this.state.tutorPhone}
                      placeholder=""
                      onChange={this.handleChangeInput('tutorPhone')}
                      fullWidth
                      margin="normal"
                    />


                  </Paper>) : null}
                </Collapse>
              </Paper>



















              <Paper
                style={{ marginTop: "20px", marginBottom: "20px" }}
                elevation={0}
              >
                <Paper
                  className={classes.titleBarContainer}
                  onClick={this.handleClickOpen('collapsed3')}
                  elevation={0}
                >
                  <div className="layout horizontal center">
                    {/* <span className={classes.addAction}>
                    <AddIcon style={{ fontSize: 50 }} />
                  </span> */}
                    <Avatar className={classes.avatarIcon}>
                      3
                  </Avatar>
                    <div className={classes.titleBlock}>
                      <div
                        className={classes.pageTitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "AjouterEleveView_88" })}
                      </div>
                      <div
                        className={classes.pageSubtitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "AjouterEleveView_91" })}
                      </div>
                    </div>
                  </div>
                </Paper>
                <Collapse in={collapsed3}>
                  {collapsed3 ? (<Paper elevation={0} className={classes.collapsedPaper}>
                    {/* Provenance */}

                    <TextField
                      id="dernierEtablissement"
                      label={formatMessage({ id: "AjouterEleveView_17" })}
                      value={this.state.dernierEtablissement}
                      placeholder=""
                      fullWidth
                      onChange={this.handleChangeInput('dernierEtablissement')}
                      helperText={formatMessage({ id: "AjouterEleveView_18" })}
                      margin="normal"
                    />
                    <TextField
                      id="derniereClasse"
                      label={formatMessage({ id: "AjouterEleveView_40" })}
                      value={this.state.derniereClasse}
                      placeholder=""
                      fullWidth
                      onChange={this.handleChangeInput('derniereClasse')}
                      helperText={formatMessage({ id: "AjouterEleveView_41" })}
                      margin="normal"
                    />

                    <TextField
                      id="derniereMoyenne"
                      label={formatMessage({ id: "AjouterEleveView_42" })}
                      value={this.state.derniereMoyenne}
                      placeholder=""
                      fullWidth
                      type="number"
                      onChange={this.handleChangeInput('derniereMoyenne')}
                      helperText={formatMessage({ id: "AjouterEleveView_43" })}
                      margin="normal"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                    />

                    <FormControl
                      style={{ width: "100%", marginTop: '15px' }}
                      component="fieldset" className="layout horizontal center">
                      <FormLabel component="legend">{formatMessage({ id: "AjouterEleveView_44" })}</FormLabel>
                      <RadioGroup
                        aria-label="moyenEntree"
                        name="moyenEntree"
                        className="layout horizontal center"
                        value={this.state.moyenEntree}
                        defaultValue={this.state.moyenEntree}
                        onChange={this.handleChangeInput('moyenEntree')}
                      >
                        <FormControlLabel value="1" labelPlacement="end" control={<Radio />} label={formatMessage({ id: "AjouterEleveView_45" })} />
                        <FormControlLabel value="2" labelPlacement="end" control={<Radio />} label={formatMessage({ id: "AjouterEleveView_46" })} />

                      </RadioGroup>
                    </FormControl>
                    <TextField
                      id="dateConcours"
                      label={formatMessage({ id: "AjouterEleveView_47" })}
                      value={this.state.dateConcours}
                      type="date"
                      fullWidth
                      helperText={formatMessage({ id: "AjouterEleveView_48" })}
                      onChange={this.handleChangeInput('dateConcours')}
                      margin="normal"
                    />
                    <TextField
                      id="deuxiemeLangue"
                      label={formatMessage({ id: "AjouterEleveView_49" })}
                      value={this.state.deuxiemeLangue}
                      fullWidth
                      helperText={formatMessage({ id: "AjouterEleveView_50" })}
                      onChange={this.handleChangeInput('deuxiemeLangue')}
                      margin="normal"
                    />

                    <div style={{ width: "100%", }}>

                      <FormControlLabel
                        control={
                          <Checkbox
                            value={this.state.isInside}
                            ckecked={this.state.isInside}
                            defaultChecked={this.state.isInside}
                            color="primary"
                            onChange={this.handeCheck('isInside', this.state.isInside)}
                          />
                        }
                        label={formatMessage({ id: "AjouterEleveView_94" })}
                      />
                    </div>
                  </Paper>) : null}
                </Collapse>
              </Paper>



















              <Paper
                style={{ marginTop: "20px", marginBottom: "20px" }}
                elevation={0}
              >
                <Paper
                  className={classes.titleBarContainer}
                  onClick={this.handleClickOpen('collapsed4')}
                  elevation={0}
                >
                  <div className="layout horizontal center">
                    {/* <span className={classes.addAction}>
                    <AddIcon style={{ fontSize: 50 }} />
                  </span> */}
                    <Avatar className={classes.avatarIcon}>
                      4
                  </Avatar>
                    <div className={classes.titleBlock}>
                      <div
                        className={classes.pageTitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "AjouterEleveView_89" })}
                      </div>
                      <div
                        className={classes.pageSubtitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "AjouterEleveView_91" })}
                      </div>
                    </div>
                  </div>
                </Paper>
                <Collapse in={collapsed4}>
                  {collapsed4 ? (<Paper elevation={0} className={classes.collapsedPaper}>
                    {/* Informations médicales     */}

                    <TextField
                      id="pathologieParticuliere"
                      label={formatMessage({ id: "AjouterEleveView_51" })}
                      value={this.state.pathologieParticuliere}
                      fullWidth
                      helperText={formatMessage({ id: "AjouterEleveView_52" })}
                      onChange={this.handleChangeInput('pathologieParticuliere')}
                      margin="normal"
                    />
                    <TextField
                      id="frequencePerteConnaissance"
                      label={formatMessage({ id: "AjouterEleveView_53" })}
                      value={this.state.frequencePerteConnaissance}
                      placeholder=""
                      fullWidth
                      onChange={this.handleChangeInput('frequencePerteConnaissance')}
                      helperText={formatMessage({ id: "AjouterEleveView_54" })}
                      margin="normal"

                    />

                    <div style={{ width: "100%", }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={this.state.dossierMedical}
                            ckecked={this.state.dossierMedical}
                            defaultChecked={this.state.dossierMedical}
                            color="primary"
                            onChange={this.handeCheck('dossierMedical', this.state.dossierMedical)}
                          />
                        }
                        label={formatMessage({ id: "AjouterEleveView_55" })}
                      />
                    </div>
                    <div style={{ width: "100%", }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={this.state.handicape}
                            ckecked={this.state.handicape}
                            defaultChecked={this.state.handicape}
                            color="primary"
                            onChange={this.handeCheck('handicape', this.state.handicape)}
                          />
                        }
                        label={formatMessage({ id: "AjouterEleveView_56" })}
                      />
                    </div>

                    <div style={{ width: "100%", }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={this.state.apteSport}
                            ckecked={this.state.apteSport}
                            defaultChecked={this.state.apteSport}
                            color="primary"
                            onChange={this.handeCheck('apteSport', this.state.apteSport)}
                          />
                        }
                        label={formatMessage({ id: "AjouterEleveView_57" })}
                      />
                    </div>


                  </Paper>) : null}
                </Collapse>
              </Paper>











              <Paper
                style={{ marginTop: "20px", marginBottom: "20px" }}
                elevation={0}
              >
                <Paper
                  className={classes.titleBarContainer}
                  onClick={this.handleClickOpen('collapsed5')}
                  elevation={0}
                >
                  <div className="layout horizontal center">
                    {/* <span className={classes.addAction}>
                    <AddIcon style={{ fontSize: 50 }} />
                  </span> */}
                    <Avatar className={classes.avatarIcon}>
                      5
                  </Avatar>
                    <div className={classes.titleBlock}>
                      <div
                        className={classes.pageTitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "AjouterEleveView_90" })}
                      </div>
                      <div
                        className={classes.pageSubtitle}
                        variant="div"
                        component="div"
                      >
                        {formatMessage({ id: "AjouterEleveView_91" })}
                      </div>
                    </div>
                  </div>
                </Paper>
                <Collapse in={collapsed5}>
                  {collapsed5 ? (<Paper elevation={0} className={classes.collapsedPaper}>

                    <Grid
                      container
                      className={classes.grid}
                      justify="space-between"
                      alignItems="center"
                    >
                      <div style={{ width: "100%", padding: "0 0 0" }}>
                        <LdImageUploader maxSize={500000} name={this.state.avatar} hasParent={this.state.avatar != null && this.state.avatar !== ""} path={this.state.pathAvatar}
                          label={formatMessage({ id: "AjouterEleveView_84" })} onImageUploaded={this.newImageUploaded}  {...deleteClassInProps(this.props)} />
                      </div>



                    </Grid>

                  </Paper>) : null}
                </Collapse>
              </Paper>

              <div style={{ width: "100%", }}>
                <FormControlLabel
                  style={{ marginLeft: "30px" }}
                  control={
                    <Checkbox
                      value={this.state.acceptReglement}
                      ckecked={this.state.acceptReglement}
                      defaultChecked={this.state.acceptReglement}
                      color="primary"
                      onChange={this.handeCheck('acceptReglement', this.state.acceptReglement)}
                    />
                  }
                  label={formatMessage({ id: "AjouterEleveView_85" })}
                />
              </div>














            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none" }} onClick={this.reset} color="primary">
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button style={{ textTransform: "none" }} onClick={this.edit} color="primary" autoFocus>
              {this.state.inProgress ? formatMessage({ id: "AjouterEleveView_59" }) : formatMessage({ id: "ItemEleve_4" })}

            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"> {formatMessage({ id: "ItemEleve_10" }, { eleve: this.props.row != null ? this.props.row.user.name : '' })} </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemEleve_1" })}
            </DialogContentText>
            <div style={{ padding: '10px 5px' }}>
              {this.state.progressDelete
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none" }} onClick={this.handleClose} color="primary">
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button style={{ textTransform: "none" }} onClick={this.handleClickDelete} color="primary" autoFocus>
              {formatMessage({ id: "ItemEleve_3" })}
            </Button>
          </DialogActions>
        </Dialog>

        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>

              <div>
                <Grid container className={classes.grid} justify="flex-start">
                  <div>
                    <ProgressiveImage src={row.pathPhoto} placeholder={defaultAvatar}>
                      {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                    </ProgressiveImage>
                  </div>

                  <div style={{ paddingLeft: "15px" }}>
                    <div style={{ fontSize: "15px", fontWeight: "600" }}>
                      {row.fullname != null && row.fullname !== "" ? row.fullname : '***********'}

                    </div>
                    <div className="layout horizontal center">
                      <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}> {formatMessage({ id: "ItemEleve_9" })}</span>
                      <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>
                        <a className="no-underline capitalize" title={'@' + row.user.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.user)}>
                          @{row.user.name}
                        </a>
                      </span>
                    </div>
                    <div style={{ fontSize: "15px", fontWeight: "600" }} className='layout horizontal center'>
                      <span className={classes.name}> {row.classeEtablissement.name}</span>
                    </div>
                    <div className="layout horizontal center">
                      <span title={formatMessage({ id: "AjouterEleveView_5" })} style={{ fontSize: "small", backgroundColor: 'var(--app-primary-color)', padding: '0 5px', margin: '0 10px 0 0', color: 'white' }}>  {row.matricule}</span>
                      {row.isOldStudent && <span title={formatMessage({ id: "ItemEleve_17" })} style={{ fontSize: "small", backgroundColor: 'var(--paper-amber-500)', padding: '0 5px', margin: '0 10px', color: 'white' }}>  {formatMessage({ id: "ItemEleve_15" })}</span>}
                      {row.isInside && <span title={formatMessage({ id: "ItemEleve_18" })} style={{ fontSize: "small", backgroundColor: 'var(--paper-teal-500)', padding: '0 5px', margin: '0 10px', color: 'white' }}>  {formatMessage({ id: "ItemEleve_16" })}</span>}
                    </div>
                    <div className="layout horizontal center">
                      <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "ItemEleve_6" })} </span>
                      <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}><span title={formatMessage({ id: "AjouterEleveView_6" })}>{row.phone != null && row.phone !== "" ? row.phone : '###########'}</span>&nbsp;/&nbsp;<span title={formatMessage({ id: "AjouterEleveView_39" })}>{row.phone2 != null && row.phone2 !== "" ? row.phone2 : '###########'}</span></span>
                    </div>
                    <div style={{ fontWeight: "400" }} className={classes.date}>{/*Ajouté le  <PostDate date={row.created}/> par */}
                      <FormattedMessage id="ItemEleve_7" values={{ attrib: <PostDate date={row.created} /> }} />
                      <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.creator)}>
                        {row.creator.name}
                      </a>
                    </div>
                    {row.modificator != null && <div style={{ fontWeight: "400" }} className={classes.date}>{/*Ajouté le  <PostDate date={row.created}/> par */}
                      <FormattedMessage id="ItemEleve_21" values={{ attrib: <PostDate date={row.modified} /> }} />
                      <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={row.modificator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.modificator)}>
                        {row.modificator.name}
                      </a>
                    </div>}
                    {this.state.showMore &&
                      <React.Fragment>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}> {formatMessage({ id: "ListTypeAlertView_Table_2" })} </span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.lastname != null && row.lastname !== "" ? row.lastname : '###########'}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}> {formatMessage({ id: "AjouterEleveView_60" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.firstname != null && row.firstname !== "" ? row.firstname : '###########'}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_61" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.birthdate != null && row.birthdate !== "" ? row.birthdate : '###########'}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}> {formatMessage({ id: "AjouterEleveView_62" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.birthplace != null && row.birthplace !== "" ? row.birthplace : '###########'}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_63" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.sexe === -1 ? "######" : (row.sexe === 1 ? formatMessage({ id: "AjouterEleveView_28" }) : formatMessage({ id: "AjouterEleveView_29" }))}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}> {formatMessage({ id: "RapportIndisciplineEleve_3" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.matricule != null && row.matricule !== "" ? row.matricule : '###########'}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}> {formatMessage({ id: "AjouterEleveView_65" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.classeEtablissement.name}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}> {formatMessage({ id: "AjouterEleveView_66" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.redoublant === -1 ? "######" : (row.redoublant === 1 ? formatMessage({ id: "AjouterEleveView_67" }) : formatMessage({ id: "AjouterEleveView_68" }))}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_69" })} </span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.dernierEtablissement != null && row.dernierEtablissement !== "" ? row.dernierEtablissement : '###########'}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_40" })} </span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.derniereClasse != null && row.derniereClasse !== "" ? row.derniereClasse : '###########'}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_70" })} </span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.parentName != null && row.parentName !== "" ? row.parentName : '###########'}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_71" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.parentAddress != null && row.parentAddress !== "" ? row.parentAddress : '###########'}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_6" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.phone != null && row.phone !== "" ? row.phone : '###########'}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_39" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.phone2 != null && row.phone2 !== "" ? row.phone2 : '###########'}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_74" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.tutorName != null && row.tutorName !== "" ? row.tutorName : '###########'}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_75" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.tutorAddress != null && row.tutorAddress !== "" ? row.tutorAddress : '###########'}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_97" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.tutorPhone != null && row.tutorPhone !== "" ? row.tutorPhone : '###########'}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_76" })} </span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.moyenEntree === -1 ? "######" : (row.moyenEntree === 1 ? 'Etude de dossiers' : "Concour")}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_77" })} </span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.dateConcours != null && row.dateConcours !== "" ? row.dateConcours : '###########'}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_78" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.deuxiemeLangue != null && row.deuxiemeLangue !== "" ? row.deuxiemeLangue : '###########'}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}> {formatMessage({ id: "AjouterEleveView_79" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.pathologieParticuliere != null && row.pathologieParticuliere !== "" ? row.pathologieParticuliere : '###########'}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_80" })} </span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.frequencePerteConnaissance != null && row.frequencePerteConnaissance !== "" ? row.frequencePerteConnaissance : '###########'}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_81" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.dossierMedical === -1 ? "######" : (row.dossierMedical === 0 ? formatMessage({ id: "AjouterEleveView_68" }) : formatMessage({ id: "AjouterEleveView_67" }))}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_82" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.handicape === -1 ? "######" : (row.handicape === 0 ? formatMessage({ id: "AjouterEleveView_68" }) : formatMessage({ id: "AjouterEleveView_67" }))}</span>
                        </div>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_83" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.apteSport === -1 ? "######" : (row.apteSport === 0 ? formatMessage({ id: "AjouterEleveView_68" }) : formatMessage({ id: "AjouterEleveView_67" }))}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_94" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.isInside ? formatMessage({ id: "ItemTypeFrais_5" }) : formatMessage({ id: "ItemTypeFrais_6" })}</span>
                        </div>
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "AjouterEleveView_85" })}</span>
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{row.acceptReglement === -1 ? "######" : (row.acceptReglement === 0 ? formatMessage({ id: "AjouterEleveView_68" }) : formatMessage({ id: "AjouterEleveView_67" }))} </span>
                        </div>


                      </React.Fragment>}
                  </div>

                </Grid>

              </div>

            </div>
          </div>
          <div className={classes.actions}>
            {this.state.openDossier && (
              <DossierEleve
                expanded="scolarite"
                annee={this.props.annee}
                etablissement={this.props.etablissement}
                access="inscription"
                eleve={row}
                onClose={this.handleCloseDossier}
                onRef={ref => (this.dossierRef = ref)}
              />
            )}

            <Button
              onClick={this.handleClickOpenDossier}
              title={formatMessage({ id: "ItemEleveScol_4" })}
              style={{ textTransform: "none", borderRadius: "0 !important" }}
              variant="outlined"
              size="small"
              className={classes.margin}
            >
              {formatMessage({ id: "ItemEleveScol_5" })}
            </Button>
            <Button
              key="undo"
              color="primary"
              size="small"
              style={{ textTransform: 'none' }}
              onClick={this.handleShowMore}
            >
              {this.state.showMore ? "Voir moins" : 'Voir plus'}
            </Button>
            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
              aria-label="Editer"
              onClick={this.handleEdit}>
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_del" })}
              aria-label="Delete"
              onClick={this.handleClickOpenDialog}>
              <DeleteIcon fontSize="small" />
            </IconButton>
            {!!this.props.row.status ?
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                classes={{ root: classes.disable }}
                aria-label="desactiver"
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton> :
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                classes={{ root: classes.enable }}

                aria-label="activer"
                onClick={this.handleEnabled}
              >
                <ClearIcon fontSize="small" />
              </IconButton>}
          </div>
        </div>

        {/* <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleSnackBarDelete}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{formatMessage({ id: "ItemEleve_8" })}</span>}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarDelete}
            >
              OK!
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarDelete}
            >
              <CloseIcon />
            </IconButton>
          ]}
        /> */}
      </React.Fragment >
    );
  }
}
ItemEleve.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemEleve)));
