import ButtonBase from "@material-ui/core/ButtonBase";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  appProperties,
  computeNbMinutes,
  isObjectValueEquals
} from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "0 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: "white"
  },
  root: {
    width: "100%"
    // marginTop: '30px',
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
  bigAvatar: {
    backgroundColor: "lightgray",
    border: "2px solid white",
    width: "32px !important",
    height: "32px !important",
    borderRadius: "50% !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,

    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    margin: "2px 5px",
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: "12px",
    fontWeight: "500",
    transform: "translate(14px, -6px) scale(1)"
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: "7.5px 23px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */,
  backButton: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

class BandeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      periodes: [],
      periode: null,
      progressPeriode: 1,
      jour: this.props.jour
    };
  }

  handleReset = () => {
    this.setState({
      periodes: [],
      periode: null
    });
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();
        self.listPeriodes();
        //self.listPeriodes();
      });
    }
    if (!isObjectValueEquals(this.props.jour, prevProps.jour)) {
      this.setState({ jour: this.props.jour }, () => {
        self.handleReset();
        self.listPeriodes();
        //self.listPeriodes();
      });
    }
  }
  componentDidMount() {
    this.listPeriodes();
  }

  sortPeriode = periodes => {
    var array = periodes || [];
    return array.sort((item1, item2) => {
      return item1.heureDebut === item2.heureDebut
        ? 0
        : item1.heureDebut < item2.heureDebut
        ? -1
        : 1;
    });
  };

  listPeriodes() {
    let self = this;
    this.setState({ periodes: [], progressPeriode: 1 });
    var url =
      appProperties.basepathServer +
      "/manager/config-emploi-temps/list-periodes?limit=-1&jours=" +
      this.props.jour;
    if (this.props.annee != null) url += "&annee=" + this.props.annee.id;
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          var periodes = self.sortPeriode(data.datas);
          if (periodes.length > 0) {
            var minPeriode = periodes.reduce((acc, cur, ind, arr) => {
              return acc == null ? cur : acc.duree < cur.duree ? acc : cur;
            }, null);
            self.props.onMaxGradual(
              periodes[periodes.length - 1].heureFin,
              minPeriode != null ? minPeriode.duree : 0,
              this.props.jour
            );
          }
          self.setState({ progressPeriode: 0, periodes: periodes });
        } else {
          self.setState({ periodes: [], progressPeriode: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ periodes: [], progressPeriode: 3 });

        console.log("There was an error listed enseignant.");
      });
  }

  getJoursLabel(jour, formatMessage) {
    var array = [
      formatMessage({ id: "AjouterBandeView_10" }),
      formatMessage({ id: "AjouterBandeView_11" }),
      formatMessage({ id: "AjouterBandeView_12" }),
      formatMessage({ id: "AjouterBandeView_13" }),
      formatMessage({ id: "AjouterBandeView_14" }),
      formatMessage({ id: "AjouterBandeView_15" }),
      formatMessage({ id: "AjouterBandeView_16" })
    ];
    if (jour >= 1 && jour <= 7) {
      return array[parseInt(jour) - 1];
    } else return "";
  }
  getRowColor(string) {
    var generate = require("string-to-color");
    return generate(string);
  }
  computedMarginTop(index) {
    if (this.state.periodes && this.state.periodes.length > index) {
      var periode;
      if (index === 0) {
        periode = this.state.periodes[index];
        return (
          ((computeNbMinutes(periode.heureDebut) - computeNbMinutes("07:30")) *
            4) /
          5
        );
      } else if (index > 0) {
        var periodeLast = this.state.periodes[index - 1];
        periode = this.state.periodes[index];
        return (
          ((computeNbMinutes(periode.heureDebut) -
            computeNbMinutes(periodeLast.heureFin)) *
            4) /
          5
        );
      }
    }
    return 0;
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        <div>
          <div
            className="layout horizontal center-center"
            style={{
              height: "35px",
              borderBottom: "1px solid #EAEAEA",
              textAlign: "center"
            }}
          >
            <span style={{ fontWeight: "100", color: "#b1b1b1" }}>
              {this.getJoursLabel(this.props.jour, formatMessage)}
            </span>
          </div>
        </div>
        <div style={{ position: "relative" }} className="layout vertical">
          {this.state.periodes.map((periode, index) => (
            <ButtonBase
              key={periode.id}
              component="div"
              style={{
                marginBottom: "-2px",
                backgroundColor: this.getRowColor(
                  periode.bandeHoraire != null ? periode.bandeHoraire.id : ""
                ),
                fontSize: "9px",
                borderBottom: "1px solid #EAEAEA",
                borderTop: "1px solid #EAEAEA",
                marginTop: this.computedMarginTop(index),
                height: (periode.duree * 4) / 5
              }}
            >
              <div
                style={{ color: "white" }}
                className="layout horizontal center-center"
              >
                <b>
                  {formatMessage({ id: "ListNoteEnseignantView_13" })}{" "}
                  {periode.heureDebut} - {periode.heureFin}
                </b>
              </div>
            </ButtonBase>
          ))}
        </div>
        {/* TODO:: Genrer les saisies qui existent deja resultcode 0 */}
      </main>
    );
  }
}
BandeView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(BandeView))
);
