import { Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  appProperties,
  deleteClassInProps,
  isObjectValueEquals
} from "../components/LdUtils";
import DashboardContainer from "./DashboardContainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { injectIntl } from "react-intl";

const useStyles = theme => ({
  root: {
    display: "block", // Fix IE 11 issue.
    // paddingRight:'10px'
    padding: "15px"
  },
  titleBlock: {
    textAlign: "left",
    backgroundColor: "white",
    padding: "10px"
    // paddingLeft:"10px",
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  toolbar: {
    backgroundColor: "white",
    padding: "15px",
    marginTop: "-15px",
    marginBottom: "15px",
    height: "65px"
  },

  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 130,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  selectMenu: {
    padding: "7.5px 14px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */
});

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    var currentAnnee = this.props.etablissement
      ? this.props.etablissement.anneeScolaire || null
      : null;
    var anneeId = currentAnnee != null ? currentAnnee.id : "";
    this.state = {
      annee: currentAnnee,
      anneeId: anneeId,
      annees: [],
      etablissement: this.props.etablissement,
      progress: 1,
      labelWidth: 0
    };
    this.handleChangeAnnee = this.handleChangeAnnee.bind(this);
  }
  componentDidUpdate(prevProps) {
    //var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        //self.refreshList("");
      });
    }
  }
  handleChangeAnnee = event => {
    this.setState({ annee: event.target.value });
  };
  componentDidMount() {
    this.listAnneeScolaires();
  }

  listAnneeScolaires() {
    let self = this;
    this.setState({ annee: null, progress: 1 });
    fetch(
      appProperties.basepathServer +
        "/rest-etablissement/list-enabled-annee-scolaires?id=" +
        this.state.etablissement.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          var anneeEtab = this.state.etablissement.anneeScolaire || null;
          if (anneeEtab != null)
            for (var i in data.datas) {
              if (data.datas[i] != null && data.datas[i].id === anneeEtab.id) {
                self.setState({ annee: data.datas[i] });
                break;
              }
            }
          self.setState({ annees: data.datas, progress: 0 }, state => {
            self.setState({
              //labelWidth: ReactDOM.findDOMNode(self.InputLabelRef).offsetWidth,
            });
            setTimeout(() => {}, 1000);
          });
        } else if (data.result_code === 0) {
          self.setState({ annees: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ annees: [], progress: 3 });
        console.log("There was an error.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.root}>
        <Grid
          container
          className={classes.toolbar}
          justify="flex-end"
          alignItems="center"
        >
          <span>{formatMessage({ id: "DashboardComponent_SchYear" })}</span>
          <FormControl
            style={{ height: "35px" }}
            variant="outlined"
            className={classes.formControl}
          >
            <Select
              title={formatMessage({ id: "DashboardComponent_ChxYear" })}
              classes={{
                selectMenu: classes.selectMenu,
                root: classes.selectRoot
              }}
              value={this.state.annee}
              onChange={this.handleChangeAnnee}
              input={
                <OutlinedInput
                  labelWidth={this.state.labelWidth}
                  name={formatMessage({ id: "DashboardComponent_Year" })}
                  id="outlined-annee-simple"
                  classes={{ input: classes.outlinedInput }}
                />
              }
            >
              <MenuItem value={"---"}>
                {formatMessage({ id: "DashboardComponent_AllYear" })}
              </MenuItem>
              {this.state.annees.map((row, index) => (
                <MenuItem key={index} value={row}>
                  {row.year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {this.state.progress === 0 && (
          <div style={{ margin: "10px 0" }} className="layout horizontal wrap">
            <DashboardContainer
              etablissement={this.state.etablissement}
              annee={this.state.annee}
              {...deleteClassInProps(this.props)}
            />
          </div>
        )}
        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "DashboardComponent_Wait" })}
            </div>
          </div>
        )}
        {this.state.progress === 2 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>Oups!!</div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "DashboardComponent_Schl_noYear" })}
            </div>
          </div>
        )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>Erreur!!</div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "DashboardComponent_NetworkError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

DashboardComponent.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(injectIntl(DashboardComponent));
