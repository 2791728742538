import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";



const styles = theme => ({
  // main: {
  //   width: "400px",
  //   padding: "15px",
  //   display: "block", // Fix IE 11 issue.
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
  //     width: 450,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   }
  // },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
 
  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    },
     padding:' 10px 0'
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "135px",
    padding: "0px !important",
  },
  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  }
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight:'600',
    fontSize:'15px',
    padding:'10px 0 10px 15px;'
  },
  body: {
    fontSize: 14,
    padding:'10px 0 10px 15px;'
  }
}))(TableCell);



class ItemRecharge extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      options: [],
      redirect: false,
      dialogOpened: false,
      position: this.props.position,
      solde:0,
      inProgress:false,
      controlled:true,
    };
    

  }
 
  
  

  render() {
    const { classes, row } = this.props;


    return (
      <React.Fragment>
        <TableRow className={classes.row} key={row.id}>
          <CustomTableCell component="th" scope="row">
            {row.etablissement  && row.etablissement!=null?row.etablissement.name:"#####"}
          </CustomTableCell>
          <CustomTableCell align="left">{row.nbSms}</CustomTableCell>
          <CustomTableCell align="left">{row.created}</CustomTableCell>
          <CustomTableCell align="left">{row.created}</CustomTableCell>
        </TableRow>

      </React.Fragment>
    );
  }
}
ItemRecharge.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(ItemRecharge));
