import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AjouterReseauEtablissView from '../view/reseau/AjouterReseauEtablissView';
import ListReseauView from '../view/reseau/ListReseauView';
import { deleteClassInProps} from '../../components/LdUtils';

const useStyles = theme => ({
    root: {
      display: "block", // Fix IE 11 issue.
      paddingRight:'10px'
    },
    titleBlock:{
      textAlign:'left',
      // paddingLeft:"10px",
    },
    pageTitle:{
      fontSize: '21px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    pageSubtitle:{
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 400,
      color:theme.color.primaryColor
    }
    
  });

class ReseauController extends Component{

    constructor(props){
        super(props);
        this.state = {};
        //this.ajouterReseauEtablissViewRef = React.createRef();
        //this.ListReseauViewRef = React.createRef();

    }
    
    render(){
        const {classes} = this.props;
        return (
            <div className = {classes.root}>
               <div  className={classes.titleBlock}>
                  <div className={classes.pageTitle} variant="div" component="div">
                    Gestion des réseaux d'établissement
                  </div>
                  <div className={classes.pageSubtitle} variant="div" component="div">
                    Vous pouvez créer les reseaux d'établissements qui peuvent etre administrés par une personne
                  </div>
                  <br/>

                  <AjouterReseauEtablissView {...deleteClassInProps(this.props)}/>
               </div>
               <ListReseauView {...deleteClassInProps(this.props)}/>
           
            </div>
        );
    }
    
}

ReseauController.propTypes = {
    classes: PropTypes.object.isRequired
 };
  
export default withStyles(useStyles)(ReseauController);