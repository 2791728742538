import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from '@material-ui/core/FormLabel';
// import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { withSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import LdImageUploader from "../../../components/LdImageUploader";
import LdSelectClasseAnnee from "../../../components/LdSelectClasseAnnee";
import LdSelectUser from "../../../components/LdSelectUser";
import { appProperties, deleteClassInProps, fireWithObject, isObjectValueEquals } from "../../../components/LdUtils";



// import AddIcon from "@material-ui/icons/Add";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    //backgroundColor: "white"
  },

  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
  root: {
    width: "100%",
    marginTop: "30px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },
  fieldset: {
    marginTop: '20px',
    border: '1px solid #DDDDDD',
    borderRadius: '3px'
  },
  legend: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    padding: '5px',
  },

  addAction: {
    color: "var(--app-primary-color)"
  },

  titleBarContainer: {
    padding: "10px",
    borderRadius: "0 !important",
    cursor: "pointer",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F5F5F5"
    }
  },
  collapsedPaper: {
    padding: "20px",
    borderRadius: "0 !important",
    backgroundColor: "white"
  },
  avatarIcon: {
    width: '40px',
    height: '40px',
    margin: "0 15px 0 5px",
    color: '#fff',
    backgroundColor: 'var(--app-primary-color)',
  },
});

class AjouterEleveView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      // etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      user: null,
      classe: null,

      matricule: "",
      firstname: "",
      lastname: "",
      phone: "",
      phone2: "",
      quartier: "",
      parentName: "",
      motherName: "",
      parentAddress: "",
      nationalite: "",
      derniereClasse: "",
      derniereMoyenne: 0,
      sexe: -1,
      moyenEntree: -1,
      dossierMedical: false,
      handicape: false,
      apteSport: false,
      acceptReglement: false,
      dateConcours: "",
      deuxiemeLangue: "",
      pathologieParticuliere: "",
      frequencePerteConnaissance: "",
      tutorName: "",
      tutorAddress: "",
      tutorPhone: "",
      dernierEtablissement: "",
      redouble: false,
      isOldStudent: false,
      isInside: false,
      birthplace: "",
      birthdate: "",
      avatar: "",
      pathAvatar: "",
      inProgress: false,
      controlled: true,
      collapsed1: true,
      collapsed2: false,
      collapsed3: false,
      collapsed4: false,
      collapsed5: false,
    };
    this.add = this.add.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handeCheck = this.handeCheck.bind(this);
    this.newImageUploaded = this.newImageUploaded.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);

  }
  handleClickOpen = name => e => {
    var val = {};
    val[name] = !this.state[name];
    this.setState(val);

    //this.setState(state => ({ name: !state.collapsed }));
  };
  componentDidUpdate(prevProps) {
    //var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        //self.refreshList("");
      });
    }
  }
  newImageUploaded(name, path) {
    // console.log(" new image uplaoded in Ajouter");
    // console.log(name);
    // console.log(path);
    this.setState({ avatar: name, pathAvatar: path });
  }
  componentDidMount() { }


  handleChangeClass(item) {
    this.setState({ classe: item ? item : null });
  }
  handleChangeUser(item) {

    this.setState({ user: item ? item : null });
    if (item && item != null) {
      var pathAvatar = item.photoId != null && item.photoId !== "" ? item.photo : "";
      var avatar = item.photoId;
      this.newImageUploaded(avatar, pathAvatar)
    } else {
      this.newImageUploaded("", "");
    }
  }
  handleChangeInput = name => e => {
    var val = {};

    val[name] = e.target.value;
    this.setState(val);
  };
  handeCheck = (name, value) => e => {
    var val = {};
    val[name] = !!value ? false : true;
    this.setState(val);
  };

  add() {
    if (
      this.state.classe == null ||
      this.state.user == null

    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var classId =
      this.state.classe &&
        this.state.classe !== "---" &&
        this.state.classe != null &&
        this.state.classe.classeEtablissement != null
        ? this.state.classe.classeEtablissement.id
        : "";

    var formData = new FormData(); // Currently empty
    formData.append("photo", this.state.avatar);
    formData.append("acceptReglement", this.state.acceptReglement ? 1 : 0);
    formData.append("apteSport", this.state.apteSport ? 1 : 0);
    formData.append("handicape", this.state.handicape ? 1 : 0);
    formData.append("dossierMedical", this.state.dossierMedical ? 1 : 0);
    formData.append("frequencePerteConnaissance", this.state.frequencePerteConnaissance);
    formData.append("pathologieParticuliere", this.state.pathologieParticuliere);
    formData.append("deuxiemeLangue", this.state.deuxiemeLangue);
    formData.append("dateConcours", this.state.dateConcours);
    formData.append("moyenEntree", this.state.moyenEntree);
    formData.append("sexe", this.state.sexe);
    formData.append("phone2", this.state.phone2);
    formData.append("tutorAddress", this.state.tutorAddress);
    formData.append("tutorName", this.state.tutorName);
    formData.append("parentAddress", this.state.parentAddress);
    formData.append("tutorPhone", this.state.tutorPhone);
    formData.append("motherName", this.state.motherName);
    formData.append("parentName", this.state.parentName);
    formData.append("derniereMoyenne", this.state.derniereMoyenne);
    formData.append("derniereClasse", this.state.derniereClasse);
    formData.append("dernierEtablissement", this.state.dernierEtablissement);
    formData.append("quartier", this.state.quartier);
    formData.append("nationalite", this.state.nationalite);
    formData.append("phone", this.state.phone);
    formData.append("matricule", this.state.matricule);
    formData.append("lastname", this.state.lastname);
    formData.append("firstname", this.state.firstname);
    formData.append("redouble", this.state.redouble ? 1 : 0);
    formData.append("birthplace", this.state.birthplace);
    formData.append("birthdate", this.state.birthdate);

    formData.append("etablissement_id", this.state.etablissement.id);
    formData.append("user_id", this.state.user.id);
    formData.append("classe_id", classId);
    formData.append("annee_id", this.state.annee.id);
    formData.append("is_old_student", this.state.isOldStudent ? 1 : 0);
    formData.append("is_inside", this.state.isInside ? 1 : 0);

    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/rest-eleve/add", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, "new-eleve", { value: data.row });
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterEleveView__1" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === 0) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterEleveView__2" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterEleveView__3" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterEleveView__4" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -2) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterEleveView__5" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -4) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterEleveView__6" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -5) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "AjouterEleveView__9" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    const { collapsed1, collapsed2, collapsed3, collapsed4, collapsed5 } = this.state;

    return (
      <main className={classes.main}>
        {/* <div className={classes.pageTitle} id="form-dialog-title">
          {" "}
          {formatMessage({ id: "LdEtablissementMenu_24" })}
        </div>

        <div className={classes.pageSubtitle}>
          {formatMessage({ id: "AjouterEleveView__7" })}
        </div> */}
        {this.state.inProgress ? (
          <div className={classes.progress}>
            <LinearProgress variant="query" />
          </div>
        ) : null}
        {!this.state.controlled ? (
          <div className={classes.error}>
            {formatMessage({ id: "ItemAnneeScolaire_Check" })}
          </div>
        ) : null}
        <div className="layout horizontal center">
          <div className="flex">










            {/* Informations générales */}
            <Paper
              style={{ marginTop: "20px", marginBottom: "20px" }}
              elevation={0}
            >
              <Paper
                className={classes.titleBarContainer}
                onClick={this.handleClickOpen('collapsed1')}
                elevation={0}
              >
                <div className="layout horizontal center">
                  {/* <span className={classes.addAction}>
                    <AddIcon style={{ fontSize: 50 }} />
                  </span> */}
                  <Avatar className={classes.avatarIcon}>
                    1
                  </Avatar>
                  <div className={classes.titleBlock}>
                    <div
                      className={classes.pageTitle}
                      variant="div"
                      component="div"
                    >
                      {formatMessage({ id: "AjouterEleveView_86" })}
                    </div>
                    <div
                      className={classes.pageSubtitle}
                      variant="div"
                      component="div"
                    >
                      {formatMessage({ id: "AjouterEleveView_91" })}
                    </div>
                  </div>
                </div>
              </Paper>
              <Collapse in={collapsed1}>
                {collapsed1 ? (<Paper elevation={0} className={classes.collapsedPaper}>

                  <div style={{ width: "100%" }}>
                    <LdSelectUser
                      label={formatMessage({ id: "AjouterEleveView_1" })}
                      handleValue={this.handleChangeUser}
                      isSearchable
                      baseUrl={
                        appProperties.basepathServer +
                        "/rest-user/search?type=0&detail=0&limit=100&name="
                      }
                      {...deleteClassInProps(this.props)}
                    />
                  </div>
                  <div style={{ width: "100%", marginTop: '25px' }}>
                    <LdSelectClasseAnnee
                      label={formatMessage({ id: "AjouterEleveView_4" })}
                      handleValue={this.handleChangeClass}
                      firstLoad
                      isSearchable
                      baseUrl={
                        appProperties.basepathServer +
                        "/manager/annee-scolaire/classes?id=" +
                        this.props.annee.id +
                        "&limit=-1"
                      }
                      {...deleteClassInProps(this.props)}
                    />
                  </div>
                  <TextField
                    id="matricule"
                    label={formatMessage({ id: "AjouterEleveView_5" })}
                    fullWidth
                    value={this.state.matricule}
                    placeholder={formatMessage({ id: "AjouterEleveView_7" })}
                    onChange={this.handleChangeInput('matricule')}
                    margin="normal"
                  />

                  <TextField
                    id="lastname"
                    label={formatMessage({ id: "AjouterEleveView_2" })}
                    value={this.state.lastname}
                    placeholder=""
                    fullWidth
                    onChange={this.handleChangeInput('lastname')}
                    helperText={formatMessage({ id: "AjouterEleveView_3" })}
                    margin="normal"
                  />
                  <TextField
                    id="firstname"
                    label={formatMessage({ id: "AjouterEleveView_10" })}
                    value={this.state.firstname}
                    placeholder=""
                    fullWidth

                    onChange={this.handleChangeInput('firstname')}
                    helperText={formatMessage({ id: "AjouterEleveView_11" })}
                    margin="normal"
                  />
                  <div className="layout horizontal center" style={{ width: "100%", }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={this.state.isOldStudent}
                          ckecked={this.state.isOldStudent}
                          color="primary"
                          onChange={this.handeCheck('isOldStudent', this.state.isOldStudent)}
                        />
                      }
                      label={formatMessage({ id: "AjouterEleveView_93" })}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={this.state.redouble}
                          ckecked={this.state.redouble}
                          color="primary"
                          onChange={this.handeCheck('redouble', this.state.redouble)}
                        />
                      }
                      label={formatMessage({ id: "AjouterEleveView_12" })}
                    />
                  </div>

                  <TextField
                    id="birthdate"
                    label={formatMessage({ id: "AjouterEleveView_25" })}
                    value={this.state.birthdate}
                    type="date"
                    fullWidth
                    helperText={formatMessage({ id: "AjouterEleveView_26" })}
                    onChange={this.handleChangeInput('birthdate')}
                    margin="normal"
                  />
                  <TextField
                    id="birthplace"
                    label={formatMessage({ id: "AjouterEleveView_13" })}
                    value={this.state.birthplace}
                    fullWidth
                    helperText={formatMessage({ id: "AjouterEleveView_14" })}
                    onChange={this.handleChangeInput('birthplace')}
                    margin="normal"
                  />
                  <FormControl
                    style={{ marginTop: '15px' }}
                    component="fieldset" className="layout horizontal center">
                    <FormLabel component="legend">{formatMessage({ id: "AjouterEleveView_27" })}</FormLabel>
                    <RadioGroup
                      aria-label="sexe"
                      name="sexe"
                      className="layout horizontal center"
                      value={this.state.sexe}
                      onChange={this.handleChangeInput('sexe')}
                    >
                      <FormControlLabel value="1" labelPlacement="end" control={<Radio />} label={formatMessage({ id: "AjouterEleveView_28" })} />
                      <FormControlLabel value="2" labelPlacement="end" control={<Radio />} label={formatMessage({ id: "AjouterEleveView_29" })} />

                    </RadioGroup>
                  </FormControl>

                  <TextField
                    id="nationalite"
                    label={formatMessage({ id: "AjouterEleveView_30" })}
                    value={this.state.nationalite}
                    fullWidth
                    placeholder={formatMessage({ id: "AjouterEleveView_31" })}
                    onChange={this.handleChangeInput('nationalite')}
                    margin="normal"
                  />
                  <TextField
                    id="quartier"
                    label={formatMessage({ id: "AjouterEleveView_32" })}
                    value={this.state.quartier}
                    fullWidth
                    placeholder={formatMessage({ id: "AjouterEleveView_33" })}
                    onChange={this.handleChangeInput('quartier')}
                    margin="normal"
                  />


                </Paper>) : null}
              </Collapse>
            </Paper>


















            <Paper
              style={{ marginTop: "20px", marginBottom: "20px" }}
              elevation={0}
            >
              <Paper
                className={classes.titleBarContainer}
                onClick={this.handleClickOpen('collapsed2')}
                elevation={0}
              >
                <div className="layout horizontal center">
                  {/* <span className={classes.addAction}>
                    <AddIcon style={{ fontSize: 50 }} />
                  </span> */}
                  <Avatar className={classes.avatarIcon}>
                    2
                  </Avatar>
                  <div className={classes.titleBlock}>
                    <div
                      className={classes.pageTitle}
                      variant="div"
                      component="div"
                    >
                      {formatMessage({ id: "AjouterEleveView_87" })}
                    </div>
                    <div
                      className={classes.pageSubtitle}
                      variant="div"
                      component="div"
                    >
                      {formatMessage({ id: "AjouterEleveView_91" })}
                    </div>
                  </div>
                </div>
              </Paper>
              <Collapse in={collapsed2}>
                {collapsed2 ? (<Paper elevation={0} className={classes.collapsedPaper}>
                  {/* Parents ou tuteurs */}

                  <TextField
                    id="parentName"
                    label={formatMessage({ id: "AjouterEleveView_34" })}
                    value={this.state.parentName}
                    placeholder=""
                    fullWidth
                    onChange={this.handleChangeInput('parentName')}
                    helperText={formatMessage({ id: "AjouterEleveView_35" })}
                    margin="normal"
                  />
                  <TextField
                    id="motherName"
                    label={formatMessage({ id: "AjouterEleveView_95" })}
                    value={this.state.motherName}
                    placeholder=""
                    fullWidth
                    onChange={this.handleChangeInput('motherName')}
                    helperText={formatMessage({ id: "AjouterEleveView_96" })}
                    margin="normal"
                  />
                  <TextField
                    id="parentAddress"
                    label={formatMessage({ id: "AjouterEleveView_36" })}
                    value={this.state.parentAddress}
                    placeholder=""
                    fullWidth
                    onChange={this.handleChangeInput('parentAddress')}
                    helperText={formatMessage({ id: "AjouterEleveView_37" })}
                    margin="normal"
                  />
                  <TextField
                    id="phone"
                    type="tel"
                    label={formatMessage({ id: "AjouterEleveView_6" })}
                    fullWidth
                    value={this.state.phone}
                    placeholder={formatMessage({ id: "AjouterEleveView_8" })}
                    onChange={this.handleChangeInput('phone')}
                    helperText={formatMessage({ id: "AjouterEleveView_64" })}

                    margin="normal"
                  />


                  <TextField
                    id="phone2"
                    label={formatMessage({ id: "AjouterEleveView_39" })}
                    value={this.state.phone2}
                    placeholder=""
                    onChange={this.handleChangeInput('phone2')}
                    fullWidth
                    margin="normal"
                  />

                  <TextField
                    id="tutorName"
                    label={formatMessage({ id: "AjouterEleveView_21" })}
                    value={this.state.tutorName}
                    placeholder=""
                    onChange={this.handleChangeInput('tutorName')}
                    fullWidth
                    helperText={formatMessage({ id: "AjouterEleveView_22" })}
                    margin="normal"
                  />
                  <TextField
                    id="tutorAddress"
                    label={formatMessage({ id: "AjouterEleveView_38" })}
                    value={this.state.tutorAddress}
                    placeholder=""
                    fullWidth
                    onChange={this.handleChangeInput('tutorAddress')}
                    helperText={formatMessage({ id: "AjouterEleveView_38" })}
                    margin="normal"
                  />
                  <TextField
                    id="tutorPhone"
                    label={formatMessage({ id: "AjouterEleveView_97" })}
                    value={this.state.tutorPhone}
                    placeholder=""
                    onChange={this.handleChangeInput('tutorPhone')}
                    fullWidth
                    margin="normal"
                  />


                </Paper>) : null}
              </Collapse>
            </Paper>



















            <Paper
              style={{ marginTop: "20px", marginBottom: "20px" }}
              elevation={0}
            >
              <Paper
                className={classes.titleBarContainer}
                onClick={this.handleClickOpen('collapsed3')}
                elevation={0}
              >
                <div className="layout horizontal center">
                  {/* <span className={classes.addAction}>
                    <AddIcon style={{ fontSize: 50 }} />
                  </span> */}
                  <Avatar className={classes.avatarIcon}>
                    3
                  </Avatar>
                  <div className={classes.titleBlock}>
                    <div
                      className={classes.pageTitle}
                      variant="div"
                      component="div"
                    >
                      {formatMessage({ id: "AjouterEleveView_88" })}
                    </div>
                    <div
                      className={classes.pageSubtitle}
                      variant="div"
                      component="div"
                    >
                      {formatMessage({ id: "AjouterEleveView_91" })}
                    </div>
                  </div>
                </div>
              </Paper>
              <Collapse in={collapsed3}>
                {collapsed3 ? (<Paper elevation={0} className={classes.collapsedPaper}>
                  {/* Provenance */}

                  <TextField
                    id="dernierEtablissement"
                    label={formatMessage({ id: "AjouterEleveView_17" })}
                    value={this.state.dernierEtablissement}
                    placeholder=""
                    fullWidth
                    onChange={this.handleChangeInput('dernierEtablissement')}
                    helperText={formatMessage({ id: "AjouterEleveView_18" })}
                    margin="normal"
                  />
                  <TextField
                    id="derniereClasse"
                    label={formatMessage({ id: "AjouterEleveView_40" })}
                    value={this.state.derniereClasse}
                    placeholder=""
                    fullWidth
                    onChange={this.handleChangeInput('derniereClasse')}
                    helperText={formatMessage({ id: "AjouterEleveView_41" })}
                    margin="normal"
                  />

                  <TextField
                    id="derniereMoyenne"
                    label={formatMessage({ id: "AjouterEleveView_42" })}
                    value={this.state.derniereMoyenne}
                    placeholder=""
                    fullWidth
                    type="number"
                    onChange={this.handleChangeInput('derniereMoyenne')}
                    helperText={formatMessage({ id: "AjouterEleveView_43" })}
                    margin="normal"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />


                  <FormControl
                    style={{ marginTop: '15px' }}
                    component="fieldset" className="layout horizontal center">
                    <FormLabel component="legend">{formatMessage({ id: "AjouterEleveView_44" })}</FormLabel>
                    <RadioGroup
                      aria-label="moyenEntree"
                      name="moyenEntree"
                      className="layout horizontal center"
                      value={this.state.moyenEntree}
                      onChange={this.handleChangeInput('moyenEntree')}
                    >
                      <FormControlLabel value="1" labelPlacement="end" control={<Radio />} label={formatMessage({ id: "AjouterEleveView_45" })} />
                      <FormControlLabel value="2" labelPlacement="end" control={<Radio />} label={formatMessage({ id: "AjouterEleveView_46" })} />

                    </RadioGroup>
                  </FormControl>
                  <TextField
                    id="dateConcours"
                    label={formatMessage({ id: "AjouterEleveView_47" })}
                    value={this.state.dateConcours}
                    type="date"
                    fullWidth
                    helperText={formatMessage({ id: "AjouterEleveView_48" })}
                    onChange={this.handleChangeInput('dateConcours')}
                    margin="normal"
                  />
                  <TextField
                    id="deuxiemeLangue"
                    label={formatMessage({ id: "AjouterEleveView_49" })}
                    value={this.state.deuxiemeLangue}
                    fullWidth
                    helperText={formatMessage({ id: "AjouterEleveView_50" })}
                    onChange={this.handleChangeInput('deuxiemeLangue')}
                    margin="normal"
                  />
                  <div style={{ width: "100%", }}>

                    <FormControlLabel
                      control={
                        <Checkbox
                          value={this.state.isInside}
                          ckecked={this.state.isInside}
                          color="primary"
                          onChange={this.handeCheck('isInside', this.state.isInside)}
                        />
                      }
                      label={formatMessage({ id: "AjouterEleveView_94" })}
                    />
                  </div>
                </Paper>) : null}
              </Collapse>
            </Paper>





















            <Paper
              style={{ marginTop: "20px", marginBottom: "20px" }}
              elevation={0}
            >
              <Paper
                className={classes.titleBarContainer}
                onClick={this.handleClickOpen('collapsed4')}
                elevation={0}
              >
                <div className="layout horizontal center">
                  {/* <span className={classes.addAction}>
                    <AddIcon style={{ fontSize: 50 }} />
                  </span> */}
                  <Avatar className={classes.avatarIcon}>
                    4
                  </Avatar>
                  <div className={classes.titleBlock}>
                    <div
                      className={classes.pageTitle}
                      variant="div"
                      component="div"
                    >
                      {formatMessage({ id: "AjouterEleveView_89" })}
                    </div>
                    <div
                      className={classes.pageSubtitle}
                      variant="div"
                      component="div"
                    >
                      {formatMessage({ id: "AjouterEleveView_91" })}
                    </div>
                  </div>
                </div>
              </Paper>
              <Collapse in={collapsed4}>
                {collapsed4 ? (<Paper elevation={0} className={classes.collapsedPaper}>
                  {/* Informations médicales     */}

                  <TextField
                    id="pathologieParticuliere"
                    label={formatMessage({ id: "AjouterEleveView_51" })}
                    value={this.state.pathologieParticuliere}
                    fullWidth
                    helperText={formatMessage({ id: "AjouterEleveView_52" })}
                    onChange={this.handleChangeInput('pathologieParticuliere')}
                    margin="normal"
                  />
                  <TextField
                    id="frequencePerteConnaissance"
                    label={formatMessage({ id: "AjouterEleveView_53" })}
                    value={this.state.frequencePerteConnaissance}
                    placeholder=""
                    fullWidth
                    onChange={this.handleChangeInput('frequencePerteConnaissance')}
                    helperText={formatMessage({ id: "AjouterEleveView_54" })}
                    margin="normal"

                  />

                  <div style={{ width: "100%", }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={this.state.dossierMedical}
                          ckecked={this.state.dossierMedical}
                          color="primary"
                          onChange={this.handeCheck('dossierMedical', this.state.dossierMedical)}
                        />
                      }
                      label={formatMessage({ id: "AjouterEleveView_55" })}
                    />
                  </div>
                  <div style={{ width: "100%", }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={this.state.handicape}
                          ckecked={this.state.handicape}
                          color="primary"
                          onChange={this.handeCheck('handicape', this.state.handicape)}
                        />
                      }
                      label={formatMessage({ id: "AjouterEleveView_56" })}
                    />
                  </div>

                  <div style={{ width: "100%", }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={this.state.apteSport}
                          ckecked={this.state.apteSport}
                          color="primary"
                          onChange={this.handeCheck('apteSport', this.state.apteSport)}
                        />
                      }
                      label={formatMessage({ id: "AjouterEleveView_57" })}
                    />
                  </div>


                </Paper>) : null}
              </Collapse>
            </Paper>




























            <Paper
              style={{ marginTop: "20px", marginBottom: "20px" }}
              elevation={0}
            >
              <Paper
                className={classes.titleBarContainer}
                onClick={this.handleClickOpen('collapsed5')}
                elevation={0}
              >
                <div className="layout horizontal center">
                  {/* <span className={classes.addAction}>
                    <AddIcon style={{ fontSize: 50 }} />
                  </span> */}
                  <Avatar className={classes.avatarIcon}>
                    5
                  </Avatar>
                  <div className={classes.titleBlock}>
                    <div
                      className={classes.pageTitle}
                      variant="div"
                      component="div"
                    >
                      {formatMessage({ id: "AjouterEleveView_90" })}
                    </div>
                    <div
                      className={classes.pageSubtitle}
                      variant="div"
                      component="div"
                    >
                      {formatMessage({ id: "AjouterEleveView_91" })}
                    </div>
                  </div>
                </div>
              </Paper>
              <Collapse in={collapsed5}>
                {collapsed5 ? (<Paper elevation={0} className={classes.collapsedPaper}>


                  <div style={{ width: "100%", }}>
                    <LdImageUploader maxSize={500000} name={this.state.avatar} hasParent={this.state.avatar != null && this.state.avatar !== ""} path={this.state.pathAvatar}
                      label={formatMessage({ id: "AjouterEleveView_84" })} onImageUploaded={this.newImageUploaded}  {...deleteClassInProps(this.props)} />

                  </div>


                </Paper>) : null}
              </Collapse>
            </Paper>
            <div style={{ width: "100%", }}>
              <FormControlLabel
                style={{ marginLeft: "30px" }}
                control={
                  <Checkbox
                    value={this.state.acceptReglement}
                    ckecked={this.state.acceptReglement}
                    color="primary"
                    onChange={this.handeCheck('acceptReglement', this.state.acceptReglement)}
                  />
                }
                label={formatMessage({ id: "AjouterEleveView_85" })}
              />
            </div>





          </div>
        </div>
        <div
          style={{ padding: "10px" }}
          className="layout horizontal end-justified">
          <Button
            onClick={this.add}
            variant="contained"
            disabled={this.state.inProgress}
            size="medium"
            className={classes.buttonPrincipale}>
            {this.state.inProgress ? formatMessage({ id: "AjouterEleveView_58" }) : formatMessage({ id: "AjouterEleveView_9" })}
          </Button>
        </div>
      </main>
    );
  }
}
AjouterEleveView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AjouterEleveView))
);
