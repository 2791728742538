import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ListCahierView from '../view/list-cahier/ListCahierView';
import { appProperties } from '../../components/LdUtils';
import { deleteClassInProps} from '../../components/LdUtils';

const useStyles = theme => ({
    root: {
      display: "block", // Fix IE 11 issue.
    },
    titleBlock:{
      textAlign:'left',
      // paddingLeft:"10px",
    },
    pageTitle:{
      fontSize: '21px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    pageSubtitle:{
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 400,
      color:theme.color.primaryColor
    }
    
  });

class ListCahierController extends Component{

    constructor(props){
        super(props);
        let user =  localStorage.getItem(appProperties.key_user) || null;

        this.state={
          etablissement: this.props.etablissement,
          currentUser : JSON.parse(user)

        }

        //this.ajouterReseauEtablissViewRef = React.createRef();
        //this.ListReseauViewRef = React.createRef();

    }
    
    render(){
        const {classes} = this.props;
        return (
            <div className = {classes.root}>
               <div  className={classes.titleBlock}>
                 
               </div>
               <ListCahierView   etablissement={this.props.etablissement} annee={this.props.annee}  {...deleteClassInProps(this.props)} />
           
            </div>
        );
    }
    
}

ListCahierController.propTypes = {
    classes: PropTypes.object.isRequired
};
  
export default withStyles(useStyles)(ListCahierController);