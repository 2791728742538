import { Paper } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from "@material-ui/core/styles/withStyles";
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ClassIcon from '@material-ui/icons/Class';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import ProgressiveImage from 'react-progressive-image';
import Avatar from "@material-ui/core/Avatar";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { hasAccessKey, convertAccessToString, pageProfileUrl } from "./LdUtils";


import BuildIcon from '@material-ui/icons/Build';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MoneyIcon from '@material-ui/icons/Money';
import NotesIcon from '@material-ui/icons/Notes';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import PersonIcon from '@material-ui/icons/Person';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DomainIcon from '@material-ui/icons/Domain';
import AddIcon from '@material-ui/icons/Add';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import WarningIcon from '@material-ui/icons/Warning';
import ViewListIcon from '@material-ui/icons/ViewList';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ViewArrayIcon from '@material-ui/icons/ViewArray';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import FunctionsIcon from '@material-ui/icons/Functions';
import OpacityIcon from '@material-ui/icons/Opacity';
import TextsmsIcon from '@material-ui/icons/Textsms';
import HistoryIcon from '@material-ui/icons/History';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1';
import MinimizeIcon from '@material-ui/icons/Minimize';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import GroupIcon from '@material-ui/icons/Group';
import GestureIcon from '@material-ui/icons/Gesture';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CreateIcon from '@material-ui/icons/Create';
import ScoreIcon from '@material-ui/icons/Score';
import { injectIntl } from "react-intl";



const useStyles = theme => ({
  root: {
    padding: '0 ' + theme.spacing.unit + "px",
  },
  list: {
    // padding: theme.spacing.unit,
    padding: '0 !important',
    backgroundColor: 'white !important'

  },
  paper: {
    padding: '0 ' + theme.spacing.unit + "px",
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: 'none !important'
  },
  nested: {
    // paddingLeft: theme.spacing.unit * 4,
    padding: '5px !important',
    border: '0.5px solid #e4e1e1',
    marginBottom: '-1px'
  },
  listItem: {
    padding: '5px !important',
    border: '0.5px solid #e4e1e1',
    marginBottom: '-1px'
  },
  listItemText: {
    // fontSize: '13px !important',
    fontSize: 'small !important',
    color: '#424242',
  },
  listItemTextRoot: {
    fontSize: '13px !important',
    padding: '0 !important'
  },
  listIcon: {
    color: 'var(--app-primary-color)'
  },
  subHeader: {
    color: 'black',
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'left',
    fontWeight: '600',
    marginLeft: '10px'

  },
  subTitle: {
    fontSize: '11px',
    lineHeight: '13px',
    textAlign: 'left',
    fontWeight: '600',
    marginLeft: '10px',
    marginTop: '5px',
  },
  contentImage: {
    width: "40px",
  },
  bigAvatar: {

    width: "40px !important",
    height: "40px !important",
  },
  grid: {
    backgroundColor: 'white !important',
    padding: '10px',
    marginBottom: '10px'
  }
});

class LdEtablissementMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etablissement: this.props.etablissement,
      accesses: this.props.accesses,
      openGeneral: false,
      openAnnee: false,
      openInscription: false,
      openScolarite: false,
      openNote: false,
      openDiscipline: false,
      openEnseignant: false,
      openCahier: false,
      openChef: false,
      openPlanning: false,
      openAlert: false,
      openStock: false,
      openCompta: true,
      openCoordonateur: true,
    };
    this.handleClickGeneral = this.handleClickGeneral.bind(this);
    this.handleClickAnnee = this.handleClickAnnee.bind(this);
    this.handleClickInscription = this.handleClickInscription.bind(this);
    this.handleClickScolarite = this.handleClickScolarite.bind(this);
    this.handleClickNote = this.handleClickNote.bind(this);
    this.handleClickDiscipline = this.handleClickDiscipline.bind(this);
    this.handleClickEnseignant = this.handleClickEnseignant.bind(this);
    this.handleClickCahier = this.handleClickCahier.bind(this);
    this.handleClickChef = this.handleClickChef.bind(this);
    this.handleClickPlanning = this.handleClickPlanning.bind(this);
    this.handleClickAlert = this.handleClickAlert.bind(this);
    this.handleClickStock = this.handleClickStock.bind(this);
    this.handleClickCompta = this.handleClickCompta.bind(this);
    this.handleClickCoordonateur = this.handleClickCoordonateur.bind(this);
  }


  handleClickGeneral = () => {
    this.setState(state => ({ openGeneral: !state.openGeneral }));
  };


  handleClickAnnee = () => {
    this.setState(state => ({ openAnnee: !state.openAnnee }));
  };
  handleClickInscription = () => {
    this.setState(state => ({ openInscription: !state.openInscription }));
  };
  handleClickScolarite = () => {
    this.setState(state => ({ openScolarite: !state.openScolarite }));
  };
  handleClickNote = () => {
    this.setState(state => ({ openNote: !state.openNote }));
  };
  handleClickDiscipline = () => {
    this.setState(state => ({ openDiscipline: !state.openDiscipline }));
  };
  handleClickEnseignant = () => {
    this.setState(state => ({ openEnseignant: !state.openEnseignant }));
  };
  handleClickCahier = () => {
    this.setState(state => ({ openCahier: !state.openCahier }));
  };
  handleClickChef = () => {
    this.setState(state => ({ openChef: !state.openChef }));
  };
  handleClickPlanning = () => {
    this.setState(state => ({ openPlanning: !state.openPlanning }));
  };
  handleClickAlert = () => {
    this.setState(state => ({ openAlert: !state.openAlert }));
  };
  handleClickStock = () => {
    this.setState(state => ({ openStock: !state.openStock }));
  };
  handleClickCompta = () => {
    this.setState(state => ({ openCompta: !state.openCompta }));
  };
  handleClickCoordonateur = () => {
    this.setState(state => ({ openCoordonateur: !state.openCoordonateur }));
  };
  isSelected(url) {
    return this.props.location && this.props.location.pathname && this.props.location.pathname !== null && this.props.location.pathname.indexOf(url) > -1;
  }

  isSelected2(url, array) {
    for (var i in array) {
      if (this.isSelected([url, array[i]].join(''))) return true;
    } return false;
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.root}>
        <Paper elevation={0} className={classes.paper}>
          <Grid wrap='nowrap' container className={classes.grid} justify="flex-start" alignItems='center'>
            <div className={classes.contentImage}>
              <ProgressiveImage src={this.state.etablissement.path_photo} placeholder='/images/icons/school.png'>
                {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
              </ProgressiveImage>
            </div>
            <Grid direction='column' container >
              <div className={classes.subHeader}>
                <a className="no-underline capitalize" title={formatMessage({ id: "LdEtablissementMenu_01" })} rel="noopener noreferrer" target="_blank" href={pageProfileUrl(this.state.etablissement)}>
                  {this.state.etablissement.name}
                </a>
              </div>
              <div className={classes.subTitle}>
                {convertAccessToString(this.state.accesses, formatMessage)}
              </div>
            </Grid>
          </Grid>

          <List component="nav" className={classes.list}>
            {this.state.accesses != null && this.state.accesses !== {} &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/dashboard")} component={Link} to={"/page/" + this.state.etablissement.code + "/dashboard"} classes={{ root: classes.listItem }} button>
                  <ListItemIcon className={classes.listIcon}>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_1" })} />
                </ListItem>
              </React.Fragment>
            }
            {hasAccessKey(this.state.accesses, 'enseignant') &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/enseignant")} classes={{ root: classes.listItem }} button onClick={this.handleClickEnseignant}>
                  <ListItemIcon className={classes.listIcon}>
                    <DirectionsWalkIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_2" })} />
                  {this.state.openEnseignant ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openEnseignant} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/enseignant/eleves")} component={Link} to={"/page/" + this.state.etablissement.code + "/enseignant/eleves"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <PersonOutlineIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtanlissementMenu" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/enseignant/cahier_de_texte")} component={Link} to={"/page/" + this.state.etablissement.code + "/enseignant/cahier_de_texte"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <BookmarksIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_3" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/enseignant/saisies")} component={Link} to={"/page/" + this.state.etablissement.code + "/enseignant/saisies"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ViewArrayIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_4" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/enseignant/sequences")} component={Link} to={"/page/" + this.state.etablissement.code + "/enseignant/sequences"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <NotesIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_5" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/enseignant/trimestres")} component={Link} to={"/page/" + this.state.etablissement.code + "/enseignant/trimestres"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <NotesIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_6" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/enseignant/annuelles")} component={Link} to={"/page/" + this.state.etablissement.code + "/enseignant/annuelles"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <NotesIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_7" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/enseignant/indiscipline")} component={Link} to={"/page/" + this.state.etablissement.code + "/enseignant/indiscipline"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <WarningIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_8" })} />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            }
            {hasAccessKey(this.state.accesses, 'admin') &&
              <React.Fragment>

                <ListItem selected={this.isSelected2("/page/" + this.state.etablissement.code + "/", ['annees', 'roles', 'teachers', 'matieres', /*'type-frais', */ 'classes', 'type-groupe-enseignements'])} classes={{ root: classes.listItem }} button onClick={this.handleClickGeneral}>
                  <ListItemIcon className={classes.listIcon}>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_9" })} />
                  {this.state.openGeneral ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openGeneral} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>

                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/categorie-etablisssement")} component={Link} to={"/page/" + this.state.etablissement.code + "/categorie-etablisssement"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ViewCompactIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_66" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/annees")} component={Link} to={"/page/" + this.state.etablissement.code + "/annees"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ViewDayIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_10" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/roles")} component={Link} to={"/page/" + this.state.etablissement.code + "/roles"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <SupervisorAccountIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_11" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/campus")} component={Link} to={"/page/" + this.state.etablissement.code + "/campus"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ViewComfyIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_67" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/personnels")} component={Link} to={"/page/" + this.state.etablissement.code + "/personnels"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <DirectionsWalkIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_12" })} />
                    </ListItem>

                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/matieres")} component={Link} to={"/page/" + this.state.etablissement.code + "/matieres"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ViewModuleIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_13" })} />
                    </ListItem>


                    {/* <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/type-frais")} component={Link} to={"/page/" + this.state.etablissement.code + "/type-frais"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <MoneyIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_14" })} />
                    </ListItem> */}
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/type-groupe-enseignements")} component={Link} to={"/page/" + this.state.etablissement.code + "/type-groupe-enseignements"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <DomainIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_15" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/classes")} component={Link} to={"/page/" + this.state.etablissement.code + "/classes"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ClassIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_16" })} />
                    </ListItem>

                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/designs")} component={Link} to={"/page/" + this.state.etablissement.code + "/designs"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <FormatShapesIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_63" })} />
                    </ListItem>


                  </List>
                </Collapse>

                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/config")} classes={{ root: classes.listItem }} button onClick={this.handleClickAnnee}>
                  <ListItemIcon className={classes.listIcon}>
                    <ViewDayIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_17" })} />
                  {this.state.openAnnee ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openAnnee} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>
                    {/* <ListItem  selected={this.isSelected("/page/"+this.state.etablissement.code+"/config/eleves")}component={Link} to={"/page/"+this.state.etablissement.code+"/config/eleves"} classes={{root:classes.nested}} button>
                      <ListItemIcon className={classes.listIcon}>
                        <PersonOutlineIcon />
                      </ListItemIcon>
                      <ListItemText  classes={{root:classes.listItemTextRoot,primary:classes.listItemText}}  inset primary="Les eleves" />
                    </ListItem> */}
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/config/teachers")} component={Link} to={"/page/" + this.state.etablissement.code + "/config/teachers"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <DirectionsWalkIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_18" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/config/classes")} component={Link} to={"/page/" + this.state.etablissement.code + "/config/classes"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ClassIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_19" })} />
                    </ListItem>

                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/config/type-frais")} component={Link} to={"/page/" + this.state.etablissement.code + "/config/type-frais"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <MoneyIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_14" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/config/type-reduction")} component={Link} to={"/page/" + this.state.etablissement.code + "/config/type-reduction"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <MinimizeIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_64" })} />
                    </ListItem>

                  </List>
                </Collapse>
              </React.Fragment>
            }
            {hasAccessKey(this.state.accesses, 'coordo') &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/signature")} classes={{ root: classes.listItem }} button onClick={this.handleClickCoordonateur}>
                  <ListItemIcon className={classes.listIcon}>
                    <GestureIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_70" })} />
                  {this.state.openCoordonateur ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openCoordonateur} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/signature/create")} component={Link} to={"/page/" + this.state.etablissement.code + "/signature/create"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <CreateIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_71" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/signature/derogation")} component={Link} to={"/page/" + this.state.etablissement.code + "/signature/derogation"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <AttachmentIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_72" })} />
                    </ListItem>


                  </List>
                </Collapse>
              </React.Fragment>
            }
            {hasAccessKey(this.state.accesses, 'inscription') &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/inscription")} classes={{ root: classes.listItem }} button onClick={this.handleClickInscription}>
                  <ListItemIcon className={classes.listIcon}>
                    <PersonAddIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_20" })} />
                  {this.state.openInscription ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openInscription} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/inscription/register")} component={Link} to={"/page/" + this.state.etablissement.code + "/inscription/register"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_21" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/inscription/users")} component={Link} to={"/page/" + this.state.etablissement.code + "/inscription/users"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_22" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/inscription/add")} component={Link} to={"/page/" + this.state.etablissement.code + "/inscription/add"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_23" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/inscription/eleves")} component={Link} to={"/page/" + this.state.etablissement.code + "/inscription/eleves"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <PersonOutlineIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_24" })} />
                    </ListItem>

                  </List>
                </Collapse>
              </React.Fragment>
            }

            {hasAccessKey(this.state.accesses, 'scolarite') &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/scolarite")} classes={{ root: classes.listItem }} button onClick={this.handleClickScolarite}>
                  <ListItemIcon className={classes.listIcon}>
                    <MoneyIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_25" })} />
                  {this.state.openScolarite ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openScolarite} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>

                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/scolarite/eleves")} component={Link} to={"/page/" + this.state.etablissement.code + "/scolarite/eleves"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <MoneyIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_26" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/scolarite/payments")} component={Link} to={"/page/" + this.state.etablissement.code + "/scolarite/payments"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <AttachMoneyIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_27" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/scolarite/versements")} component={Link} to={"/page/" + this.state.etablissement.code + "/scolarite/versements"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <AttachMoneyIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_62" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/scolarite/reductions")} component={Link} to={"/page/" + this.state.etablissement.code + "/scolarite/reductions"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ExposureNeg1Icon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_65" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/scolarite/insolvables")} component={Link} to={"/page/" + this.state.etablissement.code + "/scolarite/insolvables"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <MoneyOffIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_28" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/scolarite/etats")} component={Link} to={"/page/" + this.state.etablissement.code + "/scolarite/etats"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ScoreIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_74" })} />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            }

            {hasAccessKey(this.state.accesses, 'note') &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/note")} classes={{ root: classes.listItem }} button onClick={this.handleClickNote}>
                  <ListItemIcon className={classes.listIcon}>
                    <NotesIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_29" })} />
                  {this.state.openNote ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openNote} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>

                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/note/add-saisie")} component={Link} to={"/page/" + this.state.etablissement.code + "/note/add-saisie"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <NoteAddIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_30" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/note/list-saisie")} component={Link} to={"/page/" + this.state.etablissement.code + "/note/list-saisie"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ViewArrayIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_31" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/note/sequences")} component={Link} to={"/page/" + this.state.etablissement.code + "/note/sequences"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <NotesIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_32" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/note/trimestres")} component={Link} to={"/page/" + this.state.etablissement.code + "/note/trimestres"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <NotesIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_33" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/note/annuelles")} component={Link} to={"/page/" + this.state.etablissement.code + "/note/annuelles"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <NotesIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_34" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/note/bulletin-sequenciel")} component={Link} to={"/page/" + this.state.etablissement.code + "/note/bulletin-sequenciel"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ReceiptIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_35" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/note/bulletin-trimestriel")} component={Link} to={"/page/" + this.state.etablissement.code + "/note/bulletin-trimestriel"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ReceiptIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_36" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/note/bulletin-annuel")} component={Link} to={"/page/" + this.state.etablissement.code + "/note/bulletin-annuel"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ReceiptIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_37" })} />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            }

            {hasAccessKey(this.state.accesses, 'discipline') &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/discipline")} classes={{ root: classes.listItem }} button onClick={this.handleClickDiscipline}>
                  <ListItemIcon className={classes.listIcon}>
                    <AccessibilityIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_38" })} />
                  {this.state.openDiscipline ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openDiscipline} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/discipline/types")} component={Link} to={"/page/" + this.state.etablissement.code + "/discipline/types"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ViewListIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_39" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/discipline/indiscipline")} component={Link} to={"/page/" + this.state.etablissement.code + "/discipline/indiscipline"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <WarningIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_40" })} />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            }


            {hasAccessKey(this.state.accesses, 'cahiertexte') &&
              <React.Fragment>

                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/cahier/list")} component={Link} to={"/page/" + this.state.etablissement.code + "/cahier/list"} classes={{ root: classes.listItem }} button >
                  <ListItemIcon className={classes.listIcon}>
                    <ClassIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_41" })} />
                  {/* {this.state.openCahier ? <ExpandLess /> : <ExpandMore />} */}
                </ListItem>
                {/* <ListItem selected={this.isSelected("/page/"+this.state.etablissement.code+"/")} classes={{root:classes.listItem}} button onClick={this.handleClickCahier}>
                    <ListItemIcon className={classes.listIcon}>
                      <ClassIcon />
                    </ListItemIcon>
                    <ListItemText classes={{root:classes.listItemTextRoot,primary:classes.listItemText}}  inset primary="Gestion du cahier de texte" />
                    {this.state.openCahier ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={this.state.openCahier} timeout="auto" unmountOnExit>
                    <List style={{paddingLeft:'35px'}} component="div" disablePadding>
                      <ListItem selected={this.isSelected("/page/"+this.state.etablissement.code+"/")}component={Link} to={"/page/"+this.state.etablissement.code+"/cahier/list"} classes={{root:classes.nested}} button>
                        <ListItemIcon className={classes.listIcon}>
                          <BookmarksIcon />
                        </ListItemIcon>
                        <ListItemText  classes={{root:classes.listItemTextRoot,primary:classes.listItemText}}  inset primary="Cahiers de texte"/>
                      </ListItem>
                      
                    </List>
                  </Collapse> */}
              </React.Fragment>
            }
            {hasAccessKey(this.state.accesses, 'chef') &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/chef")} classes={{ root: classes.listItem }} button onClick={this.handleClickChef}>
                  <ListItemIcon className={classes.listIcon}>
                    <PersonOutlineIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_42" })} />
                  {this.state.openChef ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openChef} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/chef/cahier")} component={Link} to={"/page/" + this.state.etablissement.code + "/chef/cahier"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <BookmarksIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_43" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/chef/camarades")} component={Link} to={"/page/" + this.state.etablissement.code + "/chef/camarades"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <SupervisorAccountIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_44" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/chef/enseignants")} component={Link} to={"/page/" + this.state.etablissement.code + "/chef/enseignants"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <DirectionsWalkIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_45" })} />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            }
            {hasAccessKey(this.state.accesses, 'planning') &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/planning")} classes={{ root: classes.listItem }} button onClick={this.handleClickPlanning}>
                  <ListItemIcon className={classes.listIcon}>
                    <CalendarTodayIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_46" })} />
                  {this.state.openPlanning ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openPlanning} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>
                    {/* <ListItem selected={this.isSelected("/page/"+this.state.etablissement.code+"/planning/preferences")}component={Link} to={"/page/"+this.state.etablissement.code+"/planning/preferences"} classes={{root:classes.nested}} button>
                        <ListItemIcon className={classes.listIcon}>
                          <BookmarksIcon />
                        </ListItemIcon>
                        <ListItemText  classes={{root:classes.listItemTextRoot,primary:classes.listItemText}}  inset primary={formatMessage({ id: "LdEtablissementMenu_47" })}/>
                      </ListItem> */}

                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/planning/teachers")} component={Link} to={"/page/" + this.state.etablissement.code + "/planning/teachers"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <DirectionsWalkIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_18" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/planning/bandes")} component={Link} to={"/page/" + this.state.etablissement.code + "/planning/bandes"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <SupervisorAccountIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_48" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/planning/emploi-temps")} component={Link} to={"/page/" + this.state.etablissement.code + "/planning/emploi-temps"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <DirectionsWalkIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_49" })} />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            }
            {hasAccessKey(this.state.accesses, 'admin') &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/alert")} classes={{ root: classes.listItem }} button onClick={this.handleClickAlert}>
                  <ListItemIcon className={classes.listIcon}>
                    <TextsmsIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_50" })} />
                  {this.state.openAlert ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openAlert} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/alert/types")} component={Link} to={"/page/" + this.state.etablissement.code + "/alert/types"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ViewListIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_51" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/alert/list")} component={Link} to={"/page/" + this.state.etablissement.code + "/alert/list"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <NotificationsNoneIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_52" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/alert/history")} component={Link} to={"/page/" + this.state.etablissement.code + "/alert/history"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <HistoryIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_53" })} />
                    </ListItem>


                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/alert/sms-numero")} component={Link} to={"/page/" + this.state.etablissement.code + "/alert/sms-numero"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_68" })} />
                    </ListItem>

                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/alert/sms-classe")} component={Link} to={"/page/" + this.state.etablissement.code + "/alert/sms-classe"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ListAltIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_69" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/alert/sms-note-average")} component={Link} to={"/page/" + this.state.etablissement.code + "/alert/sms-note-average"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <NotesIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_73" })} />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            }
            {(hasAccessKey(this.state.accesses, 'stock') || hasAccessKey(this.state.accesses, 'stockR')) &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/stock")} classes={{ root: classes.listItem }} button onClick={this.handleClickStock}>
                  <ListItemIcon className={classes.listIcon}>
                    <OpacityIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_54" })} />
                  {this.state.openStock ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openStock} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/stock/types-materiels")} component={Link} to={"/page/" + this.state.etablissement.code + "/stock/types-materiels"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ViewListIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_55" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/stock/commandes")} component={Link} to={"/page/" + this.state.etablissement.code + "/stock/commandes"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ListAltIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_56" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/stock/achats")} component={Link} to={"/page/" + this.state.etablissement.code + "/stock/achats"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <LocalGroceryStoreIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_57" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/stock/stocks")} component={Link} to={"/page/" + this.state.etablissement.code + "/stock/sorties"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ImportExportIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_58" })} />
                    </ListItem>
                  </List>
                </Collapse>
              </React.Fragment>
            }
            {(hasAccessKey(this.state.accesses, 'comptable') || hasAccessKey(this.state.accesses, 'chefComptable')) &&
              <React.Fragment>
                <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/comptabilite")} classes={{ root: classes.listItem }} button onClick={this.handleClickCompta}>
                  <ListItemIcon className={classes.listIcon}>
                    <FunctionsIcon />
                  </ListItemIcon>
                  <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_59" })} />
                  {this.state.openCompta ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.openCompta} timeout="auto" unmountOnExit>
                  <List style={{ paddingLeft: '35px' }} component="div" disablePadding>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/comptabilite/type-decaissements")} component={Link} to={"/page/" + this.state.etablissement.code + "/comptabilite/type-decaissements"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <ViewListIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_60" })} />
                    </ListItem>
                    <ListItem selected={this.isSelected("/page/" + this.state.etablissement.code + "/comptabilite/demandes-decaissements")} component={Link} to={"/page/" + this.state.etablissement.code + "/comptabilite/demandes-decaissements"} classes={{ root: classes.nested }} button>
                      <ListItemIcon className={classes.listIcon}>
                        <LocalOfferIcon />
                      </ListItemIcon>
                      <ListItemText classes={{ root: classes.listItemTextRoot, primary: classes.listItemText }} inset primary={formatMessage({ id: "LdEtablissementMenu_61" })} />
                    </ListItem>

                  </List>
                </Collapse>
              </React.Fragment>
            }
            {/* {hasAccessKey(this.state.accesses,'admin') &&
                  <React.Fragment>
                   
                    
                    <ListItem selected={this.isSelected("/page/"+this.state.etablissement.code+"/comptabilite")} component={Link} to={"/page/"+this.state.etablissement.code+"/comptabilite"} classes={{root:classes.listItem}} button>
                        <ListItemIcon className={classes.listIcon}>
                          <FunctionsIcon />
                        </ListItemIcon>
                        <ListItemText classes={{root:classes.listItemTextRoot,primary:classes.listItemText}} inset primary="Comptabilité" />
                    </ListItem> 
                  </React.Fragment>
                } */}

          </List>
        </Paper>
      </div>
    );
  }
}
LdEtablissementMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  etablissement: PropTypes.object.isRequired,
  accesses: PropTypes.object.isRequired
  /*theme: PropTypes.object.isRequired,*/

};

export default withRouter(withStyles(useStyles)(injectIntl(LdEtablissementMenu)));
