import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import {
  appProperties,
  isObjectValueEquals
} from "../../../components/LdUtils";
import ItemUser from "./ItemUser";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    width: "100%"
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: "15px",
    marginTop: "15px",
    backgroundColor: "white"
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  }
});

class ListMyUsersView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1
    };
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }
  componentDidUpdate(prevProps) {
    //var self = this;
    if (
      !isObjectValueEquals(this.props.etablissement, prevProps.etablissement)
    ) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        //self.setCurrentPage(1);
      });
    }
  }

  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listEleve(page);
  };

  componentDidMount() {
    this.listEleve(this.state.currentPage);
  }
  componentWillUnmount() {}

  listEleve(page) {
    let self = this;

    this.setState({ datas: [], progress: 1 });
    var url =
      appProperties.basepathServer +
      "/manager/inscription/list-created-users?limit=10&page=" +
      page;
    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log("There was an error .");
      });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <Grid
          container
          className={classes.grid}
          justify="space-between"
          alignItems="center"
        >
          <div>
            <div className={classes.pageTitle} variant="div" component="div">
              {formatMessage({ id: "ListMyUsersView_6" })}
            </div>
            <div className={classes.pageSubtitle} variant="div" component="div">
              {formatMessage({ id: "ListMyUsersView_5" })}
            </div>
          </div>
        </Grid>
        {this.state.progress === 0 && (
          <React.Fragment>
            <Paper elevation={0} className={classes.root}>
              <div style={{ marginTop: "15px" }}>
                {this.state.datas.map((row, index) => (
                  <ItemUser
                    key={index}
                    onUpdateItem={this.onUpdateItem}
                    etablissement={this.state.etablissement}
                    position={index}
                    row={row}
                    {...deleteClassInProps(this.props)}
                  />
                ))}
              </div>
            </Paper>
            <LdPagination
              onShowPage={this.setCurrentPage}
              pages={this.state.pages}
            />
          </React.Fragment>
        )}

        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 ||
          (this.state.progress === 0 && this.state.datas.length === 0)) && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Any_User" })}
            </div>
          </div>
        )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              {formatMessage({ id: "ListClasseAnneeView_4" })}
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(ListMyUsersView));
