import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import IntlProviderWrapper from './IntlProviderWrapper';
import { SnackbarProvider } from 'notistack';


const styles = {
  success: { backgroundColor: '#43a047' },
  error: { backgroundColor: '#d32f2f' },
  warning: { backgroundColor: '#ffa000' },
  info: { backgroundColor: '#2196f3' },
};

ReactDOM.render(  
  <SnackbarProvider
    classes={{
        variantSuccess: styles.success,
        variantError: styles.error,
        variantWarning: styles.warning,
        variantInfo: styles.info,
    }} maxSnack={3}>
      <IntlProviderWrapper>
            <App />
      </IntlProviderWrapper>
  </SnackbarProvider>,
 document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
