import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import { appProperties, isObjectValueEquals } from '../components/LdUtils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import { Bar } from 'react-chartjs-2';

// import { Chart } from 'react-google-charts';
import Avatar from "@material-ui/core/Avatar";
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';


import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { cancelEventPropagation } from '../components/LdUtils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    width: '100%',
    // padding:'15px',
    // marginTop:'15px',
    // backgroundColor:'white',
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },





  /** Filter start */
  formControl: {
    // margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
    maxWidth: '200px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */
  paperCard: {
    width: '400px',
    padding: '5px',
    margin: '5px',
  },

  scolariteGreen: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-indigo-500)',
  },
  panelRoot: {
    borderRadius: '0 !important',
  },
  panelSummary: {
    alignItems: 'center',
    margin: '0 !important',
    borderBottom: '1px dashed #EAEAEA'
  },
  summaryRoot: {
    padding: '0',

  },
  summaryExpanded: {
    margin: '0 !important',

  },
});

class HistoEnseignement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      classe: "---",
      matiere: "---",
      matieres: [],
      trimestre: "---",
      sequence: "---",
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,
      progressMatiere: 1,
      labelWidth: 0,
      data: null,
      expanded: 'filled'
    };
    this.rapportRef = React.createRef();
    this.handleChangeMatiere = this.handleChangeMatiere.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.stopPropagation = this.stopPropagation.bind(this);
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleReset = () => {
    var self = this;
    this.setState({
      matiere: "---",
      matieres: [],
    }, () => {
      self.listMatiere();
      self.loadTaux();
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();

      });
    }
    if (!isObjectValueEquals(this.props.classe, prevProps.classe)) {
      this.setState({ classe: this.props.classe }, () => {
        self.handleReset();

      });
    }
    if (!isObjectValueEquals(this.props.trimestre, prevProps.trimestre)) {
      this.setState({ trimestre: this.props.trimestre }, () => {
        self.loadTaux();
      });
    }

    if (!isObjectValueEquals(this.props.sequence, prevProps.sequence)) {
      this.setState({ sequence: this.props.sequence }, () => {
        self.loadTaux();

      });
    }
  }

  handleChangeMatiere = event => {
    cancelEventPropagation(event);
    let self = this;
    this.setState({ matiere: (event.target.value) }, (state) => {
      self.loadTaux();
    });
  };
  stopPropagation = event => {
    cancelEventPropagation(event);
  };
  componentDidMount() {
    this.loadTaux();
    this.listMatiere();
  }
  componentWillUnmount() {
  }

  computedEnseignementBar(datas) {
    var bar = {};
    var labels = [];
    var codes = [];
    var dataset1 = {
      label: 'Volume prévu',
      backgroundColor: '#039be5',
      borderColor: '#03a9f4',
      borderWidth: 1,
      hoverBackgroundColor: '#29b6f6',
      hoverBorderColor: '#03a9f4',
      data: []
    };

    var dataset2 = {
      label: 'Volume effectué',
      backgroundColor: '#ff9800',
      borderColor: '#ffa726',
      borderWidth: 1,
      hoverBackgroundColor: '#ffb74d',
      hoverBorderColor: '#ffa726',
      data: []
    };
    if (datas != null && datas.length > 0) {
      datas.forEach((item) => {
        codes.push(item.matiere.code);
        labels.push(item.matiereName || '#' + item.matiere.name);
        dataset1.data.push(item.volume_prevu);
        dataset2.data.push(item.volume_effectue || 0);
      })
    }
    bar['codes'] = codes;
    bar['labels'] = labels;
    bar['datasets'] = [dataset1, dataset2];
    return bar;
  }
  listMatiere() {
    this.setState({ matieres: [] });
    let self = this;
    var url = appProperties.basepathServer + '/rest-enseignant/list?limit=-1';
    if (this.props.etablissement != null) url += ("&etablissement_id=" + this.props.etablissement.id);
    if (this.props.annee != null && this.props.annee !== "---") url += ("&annee_id=" + this.props.annee.id);
    if (this.props.classe != null && this.props.classe !== "---") url += ("&classe_id=" + this.props.classe.id);
    else return;
    this.setState({ matieres: [], matiere: '---', progressMatiere: 1 });


    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          self.setState({ progressMatiere: 0, matieres: data.datas });
        } else {
          self.setState({ matieres: [], progressMatiere: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ matieres: [], progressMatiere: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  loadTaux() {
    let self = this;
    this.setState({ data: null, progress: 1 });

    var url = appProperties.basepathServer + "/manager/statistics/volumes-horaires-enseignants?etablissement=" + this.props.etablissement.id + "&limit=-1";
    if (this.props.annee != null && this.props.annee !== '---') url += ("&annee=" + this.props.annee.id);
    if (this.props.trimestre != null && this.props.trimestre !== "---") url += ("&trimestre=" + this.props.trimestre.id);
    if (this.props.sequence != null && this.props.sequence !== "---") url += ("&sequence=" + this.props.sequence.id);
    if (this.props.classe != null && this.props.classe !== "---") url += ("&classe=" + this.props.classe.id);
    else {
      this.setState({ progress: 0 });
      return;
    }
    if (this.state.matiere != null && this.state.matiere !== "---") url += ("&enseignant=" + this.state.matiere.id); //TODO: utiliser matiere si necessaire

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({ progress: 0, data: self.computedEnseignementBar(data.datas) });

        } else {
          self.setState({ data: null, progress: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ data: null, progress: 3 });

        console.log('There was an error listed type de frais.');
      });
  }

  render() {
    const { classes } = this.props;
    const { expanded, data } = this.state;
    const { formatMessage } = this.props.intl;
    // var matieres = this.props.classe && this.props.classe!=null && this.props.classe!=="---"?this.props.classe.enseignants:[];
    return (
      <div className={classes.main}>


        <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'filled'} onChange={this.handleChange('filled')}>
          <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
            <div style={{ position: 'relative' }}>
              <Avatar className={classes.scolariteGreen}>
                <DirectionsWalkIcon />
              </Avatar>
              {this.state.progress === 1 &&
                <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
              }
            </div>
            <Grid container className={classes.grid} justify="space-between" alignItems='center'>
              <div className={classes.titleBlock}>
                <div className={classes.pageSubTitle} variant="div" component="div">{formatMessage({ id: "HistoEnseignements_4" })} </div>
              </div>
              <div>
                <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
                  <Select
                    title={formatMessage({ id: "ListEnseignantView_1" })}
                    displayEmpty
                    classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                    value={this.state.matiere}
                    onOpen={this.stopPropagation}
                    onChange={this.handleChangeMatiere}
                    input={
                      <OutlinedInput
                        labelWidth={this.state.labelWidth}
                        name={formatMessage({ id: "ListEnseignantView_1" })}
                        id="outlined-sequence-simple"
                        classes={{ input: classes.outlinedInput }}
                      />
                    }
                  > <MenuItem value={'---'}>{formatMessage({ id: "HistoEnseignements_3" })}</MenuItem>
                    {this.state.matieres.map((row, index) => (
                      <MenuItem key={index} value={row}>{row.matiereName || '#' + row.matiere.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>

          </ExpansionPanelSummary>

          <ExpansionPanelDetails style={{ padding: '10px 10px 10px 10px' }} className="layout vertical">

            {this.state.progress !== 1 && (
              <div className="layout vertical">
                <div className="layout horizontal center-justified">
                  {data != null && (<Grid container justify="space-between" alignItems='center'>
                    <div>
                      <div>{formatMessage({ id: "HistoEnseignements_2" })}</div>
                      <Bar
                        data={data}
                        width={400}
                        height={300}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          tooltips: {
                            callbacks: {
                              title: function (tooltipItem) {
                                return this._data.labels[tooltipItem[0].index];
                              }
                            }
                          },
                          scales: {
                            xAxes: [{
                              ticks: {
                                autoSkip: false,
                                callback: function (tick) {
                                  var characterLimit = 10;
                                  if (tick.length >= characterLimit) {
                                    return tick.slice(0, tick.length).substring(0, characterLimit - 1).trim() + '...';;
                                  }
                                  return tick;
                                }
                              }
                            }]
                          }
                        }}
                      />
                    </div>
                  </Grid>)}
                  {(this.state.data == null) && (
                    <div className="layout vertical center-center">
                      <Typography variant="h5" component="h3">
                        {formatMessage({ id: "HistoEnseignements_1" })}
                      </Typography>
                      <Typography component="p">
                        {formatMessage({ id: "HistoDepenseType_4" })}
                      </Typography>
                    </div>
                  )}
                </div>
                <div className="flex"></div>

              </div>
            )}
            {this.state.progress === 1 && (
              <div className="layout horizontal center-center" style={{ padding: '100px 50px' }}>
                <CircularProgress thickness={1} size={75} className={classes.fabProgress} />

              </div>
            )}

          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(HistoEnseignement));
