import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, FormattedTime, injectIntl, intlShape } from 'react-intl';
import ProgressiveImage from 'react-progressive-image';
import { appProperties, defaultAvatar, userProfileUrl, isObjectValueEquals } from "../../../components/LdUtils";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import ShowMore from 'react-show-more';


const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "100px !important",
    height: "100px !important",
    borderRadius: '0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  }
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
       <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));

class ItemIndiscipline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,
      motif: this.props.row.motif,
      valeur: this.props.row.valeur,
      etablissement: this.props.etablissement,
      classe: null,
      controlled: true,
      controlledJustification: true,
      inProgressJustification: false,
      showDialog: false,
      justificatif: this.props.row.justificationComment,
      row: this.props.row
    };

    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChangeMotif = this.handleChangeMotif.bind(this);
    this.doShowJustification = this.doShowJustification.bind(this);
    this.doCloseJustification = this.doCloseJustification.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.doSendJustify = this.doSendJustify.bind(this);
    this.handleChangeValeur = this.handleChangeValeur.bind(this);

  }
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      self.setState({ etablissement: self.props.etablissement }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      self.setState({ row: this.props.row }, () => {
        self.setState({ motif: self.props.row.motif, justificatif: self.props.row.justificationComment, valeur: self.props.row.valeur });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      self.setState({ position: self.props.position }, () => {
      });
    }
  }


  handleEdit = () => {
    this.setState({ modeEdit: true });
  }

  reset = () => {
    this.setState({ modeEdit: false, controlled: true });
  }

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };
  handleChangeMotif(event) {
    this.setState({ motif: event.target.value });
  }

  handleChangeValeur(event) {
    this.setState({ valeur: event.target.value });
  }

  doCloseJustification(event) {
    this.setState({ showDialog: false });
  }
  doShowJustification(event) {
    this.setState({ showDialog: true });
  }
  edit = () => {
    if (this.state.motif == null || this.state.motif.trim() === "") {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("motif", this.state.motif);
    formData.append("valeur", this.state.valeur);
    formData.append("id", this.props.row ? this.props.row.id : '');
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/indiscipline/edit", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.reset();
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItem('update', datas);

        }
        this.setState({ inProgress: false });

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  doSendJustify = () => {
    if (this.state.justificatif == null || this.state.justificatif.trim() === "") {
      this.setState({ controlledJustificatif: false });
      return;
    } else {
      this.setState({ controlledJustificatif: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("motif", this.state.justificatif);
    formData.append("id", this.props.row ? this.props.row.id : '');
    let self = this;
    this.setState({ inProgressJustification: true });
    fetch(appProperties.basepathServer + "/manager/indiscipline/justify", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.doCloseJustification();
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItem('update', datas);

        }
        this.setState({ inProgressJustification: false });

      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgressJustification: false });
        alert("There was an error added.");
      });
  }


  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>

        <Dialog
          open={this.state.showDialog}
          onClose={this.doCloseJustification}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title"> {formatMessage({ id: "ItemIndiscipline_1" })}<b>#{row.eleve.fullname || '@' + row.eleve.user.name}</b></DialogTitle>
          <DialogContent>
            {this.state.inProgressJustification
              ? <div className={classes.progress}><LinearProgress variant="query" /></div>
              : null
            }
            {!this.state.controlledJustification
              ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
              : null
            }
            <Paper square elevation={0} className={classes.paper}>



              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="justificatif">{formatMessage({ id: "ItemIndiscipline_2" })}</InputLabel>
                <Input
                  id="justificatif"
                  required
                  name="justificatif"
                  autoComplete="justificatif"
                  placeholder={formatMessage({ id: "ItemIndiscipline_3" })}
                  onChange={this.handleChangeInput("justificatif")}
                  multiline
                  rows="4"
                  variant="filled"
                />
              </FormControl>

            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.doCloseJustification}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}>
              {formatMessage({ id: "AjouterIndisciplineView_10" })}
            </Button>
            <Button
              onClick={this.doSendJustify}
              variant="contained"
              size="medium"
              disabled={this.state.justificatif == null || this.state.justificatif.trim() === ""}
              className={classes.buttonPrincipale}>
              {formatMessage({ id: "AutorisationEtablissement_Ok" })}
            </Button>
          </DialogActions>
        </Dialog>


        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>

              {this.state.modeEdit ?
                <main style={{ width: "100%" }} className={classes.main}>
                  {this.state.inProgress
                    ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                    : null
                  }
                  {!this.state.controlled
                    ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
                    : null
                  }
                  <div className="layout horizontal">
                    <div className="flex">
                      <TextField
                        fullWidth
                        id="valeur"
                        required
                        name="valeur"
                        label={
                          this.props.row.type != null ?
                            (this.props.row.type.counter === 0) ? formatMessage({ id: "AjouterIndisciplineView_19" }) :
                              ((this.props.row.type.counter === 1) ? formatMessage({ id: "AjouterIndisciplineView_18" }) : "")
                            : ""
                        }
                        defaultValue={this.state.valeur}
                        autoComplete="valeur"
                        onChange={this.handleChangeValeur}
                        multiline
                        rows="4"
                        variant="filled"
                      />
                    </div>
                    <div style={{ marginLeft: "15px" }} className="flex">
                      <TextField
                        fullWidth
                        id="motif"
                        required
                        name="motif"
                        label={formatMessage({ id: "AjouterIndisciplineView_8" })}
                        defaultValue={this.state.motif}
                        autoComplete="motif"
                        onChange={this.handleChangeMotif}
                        multiline
                        rows="4"
                        variant="filled"
                      />
                    </div>
                    <div className="layout vertical">
                      <span className="flex"> </span>
                      <div className="layout vertical">
                        <Button
                          onClick={this.edit}
                          variant="contained"
                          size="medium"
                          style={{ margin: '5px' }}
                          className={classes.buttonPrincipale}>
                          {formatMessage({ id: "ItemAnneeScolaire_Up" })}
                        </Button>
                        <Button
                          onClick={this.reset}
                          variant="contained"
                          size="medium"
                          style={{ margin: '5px' }}
                          className={classes.buttonGreyWhite}>
                          {formatMessage({ id: "ItemAnneeScolaire_Ccl" })}
                        </Button>
                      </div>
                    </div>
                  </div>
                </main>
                :
                <div>
                  <Grid container className={classes.grid} wrap="nowrap" justify="flex-start" >
                    <div>
                      <ProgressiveImage src={row.eleve.pathPhoto} placeholder={defaultAvatar}>
                        {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                      </ProgressiveImage>
                    </div>
                    <div style={{ paddingLeft: "15px" }}>
                      <div style={{ fontSize: "15px", fontWeight: "600" }}>
                        <a className="no-underline capitalize" title={row.eleve.fullname || '@' + row.eleve.user.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.eleve.user)}>
                          {row.eleve.fullname || '@' + row.eleve.user.name}
                        </a>
                      </div>
                      <div style={{ fontSize: "15px", fontWeight: "600" }} className='layout horizontal center'>
                        <span className={classes.name}> {row.eleve.classeEtablissement.name}</span>
                        <div>
                          <span title="Matricule de l'élève" style={{ fontSize: "small", backgroundColor: 'var(--app-primary-color)', padding: '0 5px', color: 'white', margin: '0 5px' }}>  {row.eleve.matricule}</span>
                        </div>
                      </div>

                      <div className="layout horizontal center">
                        <span tiytle={row.type.description} style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}> {row.type.name} </span>
                        <span style={{ fontWeight: "400", paddingRight: '10px', fontSize: '12px' }}>   {formatMessage({ id: "ItemDossierIndiscipline_1" })} <PostDate date={row.date} /></span>
                      </div>
                      <div className="layout horizontal">
                        <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "ListDemande_6" })} </span>
                        <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px' }}>
                          <ShowMore

                            lines={1}
                            more={formatMessage({ id: "ItemAlert_msg11" })}
                            less={formatMessage({ id: "ItemAlert_msg12" })}
                            anchorClass=''
                          >
                            {row.motif}
                          </ShowMore></span>
                      </div>
                      <div style={{ fontWeight: "400" }} className={classes.date}>{formatMessage({ id: "ItemAlert_msg13" })}  <PostDate date={row.created} />
                        {row.creator != null && (<span>
                          &nbsp;{formatMessage({ id: "ItemAlert_msg14" })}
                          <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.creator)}>
                            {row.creator.name}
                          </a>
                        </span>)}
                      </div>
                      <div className="layout horizontal center">
                        <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>
                          {row.type.counter === 0 && formatMessage({ id: "ItemDossierIndiscipline_7" })}
                          {row.type.counter === 1 && formatMessage({ id: "ItemDossierIndiscipline_6" })}:

                           </span>

                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px', color: 'var(--paper-orange-500)' }}>
                            {row.valeur}
                          </span>
                        </div>
                      </div>
                      {!row.justified && (
                        <div className="layout horizontal center">
                          <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "ItemDossierIndiscipline_2" })} </span>

                          <div className="layout horizontal center">
                            <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px', color: 'var(--paper-orange-500)' }}>Aucune</span>
                          </div>
                        </div>
                      )}
                      {row.justified && (
                        <div style={{ border: '1px dashed green', padding: "5px", marginTop: '5px' }}>
                          <div className="layout horizontal">
                            <span style={{ fontWeight: "600", paddingRight: '10px', fontSize: '12px' }}>{formatMessage({ id: "ItemDossierIndiscipline_2" })} </span>
                            <span style={{ fontWeight: "500", paddingRight: '10px', fontSize: '12px', color: 'var(--paper-green-500)' }}>
                              <ShowMore

                                lines={1}
                                more={formatMessage({ id: "ItemAlert_msg11" })}
                                less={formatMessage({ id: "ItemAlert_msg12" })}
                                anchorClass=''
                              >
                                {row.justificationComment}
                              </ShowMore>

                            </span>
                          </div>
                          <div style={{ fontWeight: "400" }} className={classes.date}> {formatMessage({ id: "ItemDossierIndiscipline_3" })} <PostDate date={row.justificationDate} />
                            {row.justifiedBy != null && (<span>
                              &nbsp;{formatMessage({ id: "ItemAlert_msg14" })}
                              <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '11px' }} className="no-underline capitalize" title={row.justifiedBy.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.justifiedBy)}>
                                {row.justifiedBy.name}
                              </a>
                            </span>)}
                          </div>
                        </div>
                      )}
                    </div>

                  </Grid>

                </div>
              }
            </div>
          </div>
          <div className={classes.actions}>
            {!row.justified && row.canJustify && (
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
                aria-label="Editer"
                onClick={this.handleEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
            {!row.justified && row.canJustify && (
              <Button
                title={formatMessage({ id: "ItemIndiscipline_5" })}
                onClick={this.doShowJustification}
                variant="contained"
                size="medium"
                style={{ margin: '5px' }}
                className={classes.buttonPrincipale}>
                {formatMessage({ id: "ItemIndiscipline_4" })}
              </Button>
            )}
            {/* {!!this.props.row.status ?
                  <IconButton
                  title="desactiver"
                  classes={{root: classes.disable}}
                  aria-label="desactiver"
                  onClick={this.handleDisabled}
                >
                 <CheckIcon fontSize="small" />
                </IconButton> :
                 <IconButton
                  title="activer"
                  classes={{root: classes.enable}}

                  aria-label="activer"
                  onClick={this.handleEnabled}
                >
                 <ClearIcon fontSize="small" />
              </IconButton>} */}
          </div>
        </div>

      </React.Fragment>
    );
  }
}
ItemIndiscipline.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withStyles(styles, { withTheme: true })(injectIntl(ItemIndiscipline));
