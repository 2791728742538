import Avatar from "@material-ui/core/Avatar";
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoneyIcon from '@material-ui/icons/Money';
import React, { Component } from "react";
import { Bar } from 'react-chartjs-2';
// import { Chart } from 'react-google-charts';
import { appProperties, cancelEventPropagation, isObjectValueEquals } from '../components/LdUtils';
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    width: "100%",
  },
  main: {
    width: '100%',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },
  /** Filter start */
  formControl: {
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
    maxWidth: '200px',
  },
  cssLabel: {
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */
  paperCard: {
    width: '400px',
    padding: '5px',
    margin: '5px',
  },
  scolariteGreen: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-yellow-500)',
  },
  panelRoot: {
    borderRadius: '0 !important',
  },
  panelSummary: {
    alignItems: 'center',
    margin: '0 !important',
    borderBottom: '1px dashed #EAEAEA'
  },
  summaryRoot: {
    padding: '0',
  },
  summaryExpanded: {
    margin: '0 !important',
  },
});
class HistoPaiementType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      classe: "---",
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,
      progressTypeFrais: 1,
      labelWidth: 0,
      paid: 0,
      total: 0,
      openRapport: false,
      expanded: 'filled'
    };
    this.rapportRef = React.createRef();
    this.handleReset = this.handleReset.bind(this);
    this.stopPropagation = this.stopPropagation.bind(this);
  }
  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  handleReset = () => {
    var self = this;
    self.loadHistoPaiement();
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();
      });
    }
    if (!isObjectValueEquals(this.props.classe, prevProps.classe)) {
      this.setState({ classe: this.props.classe }, () => {
        self.handleReset();
      });
    }
  }
  stopPropagation = event => {
    cancelEventPropagation(event);
  };
  componentDidMount() {
    this.loadHistoPaiement();
  }
  componentWillUnmount() {
  }
  computedFraisBar(datas) {
    var bar = {};
    var labels = [];
    var codes = [];
    var dataset1 = {
      label: 'Montant attendu',
      backgroundColor: '#039be5',
      borderColor: '#03a9f4',
      borderWidth: 1,
      hoverBackgroundColor: '#29b6f6',
      hoverBorderColor: '#03a9f4',
      data: []
    };
    var dataset2 = {
      label: 'Montant versé',
      backgroundColor: '#ff9800',
      borderColor: '#ffa726',
      borderWidth: 1,
      hoverBackgroundColor: '#ffb74d',
      hoverBorderColor: '#ffa726',
      data: []
    };
    if (datas != null && datas.length > 0) {
      datas.forEach((item) => {
        codes.push(item.code);
        labels.push(item.name);
        dataset2.data.push(item.paid);
        dataset1.data.push(item.total || 0);
      })
    }
    bar['codes'] = codes;
    bar['labels'] = labels;
    bar['datasets'] = [dataset1, dataset2];
    return bar; //this.setState({ data: bar });
  }
  loadHistoPaiement() {
    let self = this;
    this.setState({ data: null, progress: 1 });
    var url = appProperties.basepathServer + "/manager/statistics/paiements-by-type?etablissement=" + this.props.etablissement.id + "&limit=-1";
    if (this.props.annee != null && this.props.annee !== '---') url += ("&annee=" + this.props.annee.id);
    if (this.props.classe != null && this.props.classe !== "---") url += ("&classe=" + this.props.classe.id);
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        Accept: 'application/json'
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result_code === 1) {
          self.setState({ progress: 0, data: self.computedFraisBar(data.datas) });
        } else {
          self.setState({ data: null, progress: 2 });
        }
      })
      .catch((err) => {
        console.log(err);
        self.setState({ data: null, progress: 3 });
        console.log('There was an error listed type de frais.');
      });
  }
  render() {
    const { classes } = this.props;
    const { expanded, data } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        <ExpansionPanel classes={{ root: classes.panelRoot }} expanded={expanded === 'filled'} onChange={this.handleChange('filled')}>
          <ExpansionPanelSummary classes={{ content: classes.panelSummary, root: classes.summaryRoot, expanded: classes.summaryExpanded }} expandIcon={<ExpandMoreIcon />}>
            <div style={{ position: 'relative' }}>
              <Avatar className={classes.scolariteGreen}>
                <MoneyIcon />
              </Avatar>
              {this.state.progress === 1 &&
                <CircularProgress style={{ position: 'absolute', left: '8px', top: '8px' }} thickness={1} size={44} className={classes.fabProgress} />
              }
            </div>
            <Grid container className={classes.grid} justify="space-between" alignItems='center'>
              <div className={classes.titleBlock}>
                <div className={classes.pageSubTitle} variant="div" component="div">{formatMessage({ id: "HistoPaiementType_1" })} </div>
              </div>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ padding: '10px 10px 10px 10px' }} className="layout vertical">
            {this.state.progress === 0 && (
              <div className="layout vertical">
                <div className="layout horizontal center-justified">
                  {data != null && (<Grid container justify="space-between" alignItems='center'>
                    <div>
                      <div>{formatMessage({ id: "HistoPaiementType_2" })}</div>
                      <Bar
                        data={data}
                        width={400}
                        height={300}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          tooltips: {
                            callbacks: {
                              title: function (tooltipItem) {
                                return this._data.labels[tooltipItem[0].index];
                              }
                            }
                          },
                          scales: {
                            xAxes: [{
                              ticks: {
                                autoSkip: false,
                                callback: function (tick) {
                                  var characterLimit = 10;
                                  if (tick.length >= characterLimit) {
                                    return tick.slice(0, tick.length).substring(0, characterLimit - 1).trim() + '...';;
                                  }
                                  return tick;
                                }
                              }
                            }]
                          }
                        }}
                      />
                    </div>
                  </Grid>)}
                  {data != null && (
                    <Typography component="p">
                      {/* Montant total: <b>{this.state.total} FCFA</b> */}
                    </Typography>
                  )}
                  {data == null && (
                    <div className="layout vertical center-center">
                      <Typography variant="h5" component="h3">
                        {formatMessage({ id: "HistoPaiementType_1" })}
                      </Typography>
                      <Typography component="p">
                        {formatMessage({ id: "HistoDepenseType_4" })}
                      </Typography>
                    </div>
                  )}
                </div>
                <div className="flex"></div>
                {/* { this.state.total >0 && this.props.classe!=null && this.props.classe!=="---" ? (
                    <div className="layout horizontal center end-justified">
                      <Button onClick={this.handleClickOpenRapport}  title="Afficher le rapport de paiement par l'élève"  style={{textTransform:'none',borderRadius:'0 !important'}} variant="outlined" size="small" className={classes.margin}>
                          Voir le rapport
                      </Button>       
                    </div>
                  ):null} */}
              </div>
            )}
            {this.state.progress === 1 && (
              <div className="layout horizontal center-center" style={{ padding: '100px 50px' }}>
                <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
              </div>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(HistoPaiementType));
