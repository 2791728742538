
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AjouterEleveView from '../view/eleve/AjouterEleveView';
import AjouterOldEleveView from '../view/eleve/AjouterOldEleveView';
import ListEleveView from '../view/eleve/ListEleveView';
import { isObjectValueEquals } from '../../components/LdUtils';
import { deleteClassInProps } from '../../components/LdUtils';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import { injectIntl } from "react-intl";


const useStyles = theme => ({
  root: {
    display: "block", // Fix IE 11 issue.
    // paddingRight:'10px'
  },
  titleBlock: {
    textAlign: 'left',
    // paddingLeft:"10px",
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    marginBottom: "15px",
    color: theme.color.primaryColor
  }

});

class AddEleveController extends Component {

  constructor(props) {
    super(props);
    //this.ajouterReseauEtablissViewRef = React.createRef();
    //this.ListReseauViewRef = React.createRef();
    this.state = {
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      mode: 0
    };
    this.handleChangeMode = this.handleChangeMode.bind(this);

  }
  componentDidUpdate(prevProps) {
    //var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        //self.refreshList("");
      });
    }
  }


  handleChangeMode = (event, value) => {
    this.setState({ mode: value }, (state) => {

    });
  };
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={classes.root}>
        <div className={classes.titleBlock}>
          {/* TODO: Afficher le dashboard ici.  */}
          {/* <div className={classes.pageTitle} variant="div" component="div">
                    Les élèves
                  </div>
                  <div className={classes.pageSubtitle} variant="div" component="div">
                    Tous les élèves inscrits au cours de l'année scolaire
                  </div>  */}
          <div className={classes.pageTitle} id="form-dialog-title">
            {" "}
            {formatMessage({ id: "LdEtablissementMenu_24" })}
          </div>

          <div className={classes.pageSubtitle}>
            {formatMessage({ id: "AjouterEleveView__7" })}
          </div>
          <AppBar elevation={0} position="static" color="default">
            <Tabs
              value={this.state.mode}
              onChange={this.handleChangeMode}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label={formatMessage({ id: "AddEleveController_1" })} value={0} />
              <Tab label={formatMessage({ id: "AddEleveController_2" })} value={1} />

            </Tabs>
          </AppBar>
          {this.state.mode === 0 && <AjouterEleveView annee={this.state.annee} etablissement={this.state.etablissement} etablissementId={this.state.etablissement.id}  {...deleteClassInProps(this.props)} />}
          {this.state.mode === 1 && <AjouterOldEleveView annee={this.state.annee} etablissement={this.state.etablissement} etablissementId={this.state.etablissement.id}  {...deleteClassInProps(this.props)} />}

        </div>
        <ListEleveView annee={this.state.annee} etablissement={this.state.etablissement}  {...deleteClassInProps(this.props)} />

      </div>
    );
  }

}

AddEleveController.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(useStyles)(injectIntl(AddEleveController));