import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedDate, injectIntl, intlShape } from 'react-intl';
import { appProperties,  isObjectValueEquals, userProfileUrl ,computeNbMinutes, gmDate, } from "../../../components/LdUtils";
import FormControl from '@material-ui/core/FormControl';

import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withSnackbar } from 'notistack';
import FormHelperText from '@material-ui/core/FormHelperText';
import TimePicker from 'react-time-picker';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const styles = theme => ({
  root:{
    display: "flex",
    justifyContent : "flex-start",
    // alignItems: "center",
    paddingBottom: '10px',

  },
  content:{
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage:{
    width:"40px",
  },
  contentFlex:{
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent : "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
  
  progress: {
    width: "100%",
    flexGrow: 1
  },
 

  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  }, 
  buttonSimple:{
 
    textTransform:'none',
 
  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  name:{
    fontSize:"15px",
    fontWeight:'600',
    textDecoration:'none',
  },
  date:{
    fontSize:"12px",
    fontWeight:'400',
   
  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "64px !important",
    height:"64px !important",
    borderRadius:'0 !important'
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding:2,
    // borderRadius:"50%",
    backgroundColor:theme.color.primaryDark,
    border:"2px solid #FFF",
    cursor:"pointer"
  },
  
  datePicker:{
    height:'40px',
    '& .react-date-picker__wrapper':{
      border:'1px dashed #AAAAAA',
      borderRadius:'5px'
    }
  },
  label:{
    fontSize:'15px',
    fontWeight:'500',
    padding:'0 10px 0 0'
  },
  error:{
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop:"15px"

  },
  
  
  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 130,
    '&  fieldset':{
      borderColor:'transparent !important',
    }
  },
  selectRoot:{
    height:'35px',
    
  },
  selectMenu:{
    padding:'7.5px 14px',
    backgroundColor: 'var(--app-primary-color)',
    color:'white',
    '&:focus':{
      backgroundColor: 'var(--app-primary-color)',
      color:'white',
    },
    borderRadius:'4px !important'
    
  },
  outlinedInput:{
    backgroundColor: 'var(--app-primary-color)',
    color:'white',
    borderRadius:'4px'
  }  /** Filter end */
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({date, intl}) => (
  <span  title={intl.formatDate(date)}>
      {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
      <FormattedDate 
       day="numeric"
       month="long"
       year="numeric"
       value={date}/>
  </span>
));

class ItemBande extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,
      jours: this.props.row.jours!=null?this.props.row.jours.sort():[],
      nbPeriode: this.props.row.periode,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      startTime: this.props.row.heureDebut,
      endTime: this.props.row.heureFin,
      classe: null,
      controlled:true,
      row:this.props.row, 

      openConfirm:false,
      progressDelete:false, 
    };
    
    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleNbPeriode = this.handleNbPeriode.bind(this);
    this.handleChangeJour = this.handleChangeJour.bind(this);
    
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
  } 

   handleClickOpen = ()  => {
    this.setState({ openConfirm: true });
  };

  handleClose = () => {
    this.setState({ openConfirm: false });
  };

  handleClickDelete = () => {
    var self = this;
    this.setState({ progressDelete: true });
    fetch(
      appProperties.basepathServer +"/manager/config-emploi-temps/delete-bande?id=" +  this.props.row.id, {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ progressDelete: false });
        if (data.result_code === 1) {
          self.props.onUpdateItem("delete", { position: self.state.position });
          self.handleClose();
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_1" }), { variant:"success", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }else if (data.result_code === -1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_2" }), { variant:"error", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }else if (data.result_code === -12) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_3" }), { variant:"error", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ progressDelete: false });
        alert("There was an error added.");
      });
  };
  handleChangeJour = value =>  event =>{
    var index = this.state.jours.indexOf(value);
    if(index<0){
      this.setState(state => {
            state.jours =  [value, ...state.jours].sort() ;
            return state;
      });
    }else{
      var array = [...this.state.jours];
      array.splice(index,1);
      this.setState(state => {
        state.jours =  array.sort() ;
        return state;
      });
    }
  };
  handleChangeStartTime(time){
    this.setState({startTime:time});
  }
  handleChangeEndTime(time){
    this.setState({endTime:time});
  };
  handleNbPeriode =  event =>{
    var value = event.target.value;
    if(value==="" || value<1){
      value = 1;
      event.target.value = value;
    }
    if(event.target.value==="")value=1;
    this.setState({nbPeriode:value});
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if(!isObjectValueEquals(this.props.etablissement,prevProps.etablissement)){
      this.setState({etablissement:this.props.etablissement},()=>{
      });
    } 
    if(!isObjectValueEquals(this.props.annee,prevProps.annee)){
      this.setState({annee:this.props.annee},()=>{
      });
    }
    if(!isObjectValueEquals(this.props.row,prevProps.row)){
      this.setState({row:this.props.row},()=>{
        self.setState({
          jours: self.props.row.jours!=null?self.props.row.jours.sort():[],
          nbPeriode: self.props.row.periode,
          startTime: self.props.row.heureDebut,
          endTime: self.props.row.heureFin,
        });
      });
    } 
    if(!isObjectValueEquals(this.props.position,prevProps.position)){
      this.setState({position:this.props.position},()=>{
      });
    }
  }
 
  handleEdit = () => {
    this.setState({modeEdit:true});
  }

  reset = () => {
    this.setState({modeEdit:false,controlled:true});
  }


  
  edit() {
     if(this.state.nbPeriode === 0 ||
      this.state.startTime === null ||this.state.startTime === ""||
      this.state.endTime === null ||this.state.endTime === ""||
       this.state.jours.length ===0){
      this.setState({controlled:false});
      return;
    }else{
      this.setState({controlled:true});
    }
   
    let self = this;
    this.setState({inProgress:true});
    var url = appProperties.basepathServer + "/manager/config-emploi-temps/edit-bande?nb_periode="+this.state.nbPeriode;
    if(this.props.row!=null) url+=("&id="+this.props.row.id);
    if(this.state.annee!=null) url+=("&annee="+this.state.annee.id);
    if(this.state.startTime!=null) url+=("&from="+this.state.startTime);
    if(this.state.endTime!=null) url+=("&to="+this.state.endTime);
    if(this.state.jours.length>0) url+=("&jours="+this.state.jours.join('-'));

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    
    })
      .then(response => response.json())
      .then(data => {
        this.setState({inProgress:false});
        if (data.result_code === 1) {
          self.reset();
          let datas = {position:self.state.position,row:data.item};
          self.props.onUpdateItem('update',datas);
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_4" }), { variant:"success", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }else if (data.result_code === -1) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_2" }), { variant:"error", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }else if (data.result_code === -2) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_5" }), { variant:"error", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }else if (data.result_code === -3) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_6" }), { variant:"error", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }else if (data.result_code === -4) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_7" }), { variant:"error", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }else if (data.result_code === -10) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_8" }), { variant:"error", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }else if (data.result_code === -11) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_9" }), { variant:"error", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }else if (data.result_code === -12) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBande_10" }), { variant:"error", anchorOrigin: { vertical: 'bottom', horizontal: 'left',}, });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({inProgress:false});
        alert("There was an error added.");
      });
  }
  getJoursLabel(jour,formatMessage){
    var array =[
      formatMessage({ id: "AjouterBandeView_10" }),
      formatMessage({ id: "AjouterBandeView_11" }),
      formatMessage({ id: "AjouterBandeView_12" }),
      formatMessage({ id: "AjouterBandeView_13" }),
      formatMessage({ id: "AjouterBandeView_14" }),
      formatMessage({ id: "AjouterBandeView_15" }),
      formatMessage({ id: "AjouterBandeView_16" })];
    if(jour>=1 && jour<=7){
      return array[parseInt(jour)-1];
    }else return "";
   
  }
  getGradual(lastHour,init,minDuree){
    var array =[];
    if(lastHour>=init){
      var lasHourMin = computeNbMinutes(lastHour);
      var hourInit = computeNbMinutes(init);
      var newDate =0;
      do{
        newDate = hourInit+minDuree;
        array.push([gmDate(hourInit*60),gmDate(newDate*60)]);
        hourInit = newDate;
      }while(newDate<lasHourMin);
      
    }
    return array;
  }

  render() {
    const { classes, row } = this.props;
    const {formatMessage} = this.props.intl;
    const jours = row.jours;


    return (
      <React.Fragment>

       <Dialog 
          open={this.state.openConfirm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formatMessage({id:"ItemBande_11"})}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({id:"ItemBande_12"})}
            </DialogContentText>
            <div style={{padding:'10px 5px'}}>
            { this.state.progressDelete
              ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
              : null
            }
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{textTransform:"none"}} onClick={this.handleClose} color="primary">
              {formatMessage({id:"AutorisationEtablissement_Ccl"})}
            </Button>
            <Button style={{textTransform:"none"}} onClick={this.handleClickDelete} color="primary" autoFocus>
              {formatMessage({id:"ItemEleve_3"})}
            </Button>
          </DialogActions>
        </Dialog>
        <div style={{backgroundColor:(this.state.position%2===0?'#FFFFFF':'#F5F5F5')}} className={classes.root}>  
            <div className={classes.content}>
              <div className={classes.contentFlex}>
             
                {this.state.modeEdit?
                  <main style={{width:"100%",border:'1px dashed #EEEEEE',padding:'10px'}} className={classes.main}>
                    { this.state.inProgress
                      ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
                      : null
                    }
                    { !this.state.controlled
                      ? <div className={classes.error}>{formatMessage({id:"AjouterAnneeScolaire_Check"})}</div> 
                      : null
                    }
                    <div className="layout vertical">
                      <div className="layout vertical">

                        <FormControl component="fieldset" className={classes.formControl}>
                          <FormLabel component="legend">{formatMessage({id:"AjouterBandeView_9"})}</FormLabel>
                          <FormGroup className="layout horizontal center">
                            <FormControlLabel
                              control={<Checkbox checked={this.state.jours.indexOf('1')>-1} onChange={this.handleChangeJour('1')} value="1" />}
                              label={formatMessage({id:"AjouterBandeView_10"})}
                            />
                            <FormControlLabel
                              control={<Checkbox checked={this.state.jours.indexOf('2')>-1} onChange={this.handleChangeJour('2')} value="2" />}
                              label={formatMessage({id:"AjouterBandeView_11"})}
                            />
                            <FormControlLabel
                              control={ <Checkbox checked={this.state.jours.indexOf('3')>-1} onChange={this.handleChangeJour('3')} value="3" /> }
                              label={formatMessage({id:"AjouterBandeView_12"})}
                            />
                            <FormControlLabel
                              control={ <Checkbox checked={this.state.jours.indexOf('4')>-1} onChange={this.handleChangeJour('4')} value="4" /> }
                              label={formatMessage({id:"AjouterBandeView_13"})}
                            />
                            <FormControlLabel
                              control={ <Checkbox checked={this.state.jours.indexOf('5')>-1} onChange={this.handleChangeJour('5')} value="5" /> }
                              label={formatMessage({id:"AjouterBandeView_14"})}
                            />
                            <FormControlLabel
                              control={ <Checkbox checked={this.state.jours.indexOf('6')>-1} onChange={this.handleChangeJour('6')} value="6" /> }
                              label={formatMessage({id:"AjouterBandeView_15"})}
                            />
                            <FormControlLabel
                              control={ <Checkbox checked={this.state.jours.indexOf('7')>-1} onChange={this.handleChangeJour('7')} value="7" /> }
                              label={formatMessage({id:"AjouterBandeView_16"})}
                            />
                          </FormGroup>
                          <FormHelperText>{formatMessage({id:"ItemBande_13"})}</FormHelperText>
                        </FormControl>
                        <Grid container className={classes.grid} justify="space-evenly" alignItems='center'>
                            <div className="layout horizontal center " style={{paddingLeft:'10px',paddingRight:'20px'}}>
                              <span style={{fontSize:'13px',paddingRight:'5px'}}>{formatMessage({id:"AjouterBandeView_17"})}</span>
                              <TimePicker
                                onChange={this.handleChangeStartTime}
                                value={this.state.startTime}
                                format="HH:mm"
                                // maxTime={this.state.endTime}
                              />
                            </div> 
                            <div className="layout horizontal center " style={{paddingRight:'20px'}}>
                              <span style={{fontSize:'13px',paddingRight:'5px'}}>{formatMessage({id:"AjouterBandeView_18"})}</span>
                              <TimePicker
                                onChange={this.handleChangeEndTime}
                                value={this.state.endTime}
                                format="HH:mm"
                                minTime={this.state.startTime}
                              />
                            </div> 
                            <div  className="flex">
                              <TextField
                                  id="nbPeriode"
                                  type="number"
                                  label={formatMessage({id:"AjouterBandeView_19"})}
                                  style={{width:'300px',marginLeft:'5%'}}
                                  value={this.state.nbPeriode}
                                  placeholder="Ex: 2"
                                  onChange={this.handleNbPeriode}
                                  margin="normal"
                                  InputProps={{
                                    inputProps: { min: 1, max: 10 },      
                                  }}
                              />
                            </div>
                            
                        </Grid>
                      </div>
                      
                      <div className="layout horizontal center end-justified">
                        <Button 
                          onClick={this.edit} 
                          variant="contained"
                          size="medium"
                          style={{margin:'5px'}}
                          className = {classes.buttonPrincipale}>
                          {formatMessage({id:"ItemAnneeScolaire_Up"})}
                        </Button>
                        <Button 
                          onClick={this.reset} 
                          variant="contained"
                          size="medium"
                          style={{margin:'5px'}}
                          className = {classes.buttonGreyWhite}>
                          {formatMessage({id:"ItemAnneeScolaire_Ccl"})}
                        </Button>
                      </div>
                    </div> 
                  </main>
                :
                <div>
                    <Grid container className={classes.grid} justify="flex-start" alignItems='center'>
                       
                        <div style={{paddingLeft:"15px",paddingTop:"15px"}}>    
                                                                                                                                                                                                              
                          <div className="layout horizontal center">   
                              <span style={{fontWeight:"500",paddingRight:'10px',fontSize:'12px'}}>{formatMessage({id:"AjouterBandeView_17"})} </span>
                              <span style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}>{row.heureDebut}</span>
                          </div>  
                          <div className="layout horizontal center">   
                              <span style={{fontWeight:"500",paddingRight:'10px',fontSize:'12px'}}>{formatMessage({id:"AjouterBandeView_18"})} </span>
                              <span style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}>{row.heureFin}</span>
                          </div>                                                                                                                                                                                  
                          <div className="layout horizontal center">   
                              <span style={{fontWeight:"500",paddingRight:'10px',fontSize:'12px'}}>{formatMessage({id:"ItemBande_14"})} </span>
                              <span style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}>{row.periode}</span>
                          </div>
                          <div className="layout horizontal center">   
                              <span style={{fontWeight:"500",paddingRight:'10px',fontSize:'12px'}}>{formatMessage({id:"ItemBande_15"})} </span>
                              <span className="layout horizontal center" style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}>
                                {this.getGradual(row.heureFin,row.heureDebut,row.duree/(row.periode>0?row.periode:1)).map((gradual,index)=> (
                                  <span key={"gradual_"+index} style={{marginRight:'10px'}}>{gradual[0]+" - "+gradual[1]}</span>
                                ))}
                              </span>
                          </div>    


                          <div className="layout horizontal center">   
                              <span style={{fontWeight:"500",paddingRight:'10px',fontSize:'12px'}}>{formatMessage({id:"ItemBande_16"})} </span>
                              <span className="layout horizontal center" style={{fontWeight:"600",paddingRight:'10px',fontSize:'12px'}}>
                                {jours.map((jour,index)=> (
                                  <span key={index} style={{marginRight:'5px'}}>{this.getJoursLabel(jour,formatMessage)}</span>
                                ))}
                              </span>
                          </div>
                          <div  style={{fontWeight:"400"}} className={classes.date}> {formatMessage({id:"ItemAlert_msg13"})} <PostDate date={row.created}/>  {formatMessage({id:"ItemAlert_msg14"})}
                              <a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'11px'}} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.creator)}>
                                {row.creator.name}
                              </a> 
                          </div>
                        </div>
                        
                    </Grid>
                 
                </div>
                }
              </div>
            </div>
            <div className="layout horizontal center-center">
              
              <IconButton
                title={formatMessage({id:"ItemAnneeScolaire_edit"})}
                aria-label="Editer"
                onClick={this.handleEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
              
              <IconButton
                title={formatMessage({id:"ItemAnneeScolaire_del"})}
                aria-label="Delete"
                onClick={this.handleClickOpen}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            
            </div>
        </div>
      
     </React.Fragment>    
    );
  }
}
ItemBande.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
  export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemBande)));
