import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ListSaisieEnseignantView from '../view/list-saisie/ListSaisieEnseignantView';
import AjouterSaisieEnseignantView from '../view/ajouter-saisie/AjouterSaisieEnseignantView';
import { appProperties } from '../../components/LdUtils';
import { deleteClassInProps} from '../../components/LdUtils';

const useStyles = theme => ({
    root: {
      display: "block", // Fix IE 11 issue.
    },
    titleBlock:{
      textAlign:'left',
      // paddingLeft:"10px",
    },
    pageTitle:{
      fontSize: '21px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    pageSubtitle:{
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 400,
      color:theme.color.primaryColor
    }
    
  });

class ListSaisieEnseignantController extends Component{

    constructor(props){
        super(props);
        let user =  localStorage.getItem(appProperties.key_user) || null;

        this.state={
          etablissement: this.props.etablissement,
          currentUser : JSON.parse(user)

        }

        //this.ajouterReseauEtablissViewRef = React.createRef();
        //this.ListReseauViewRef = React.createRef();

    }
    
    render(){
        const {classes} = this.props;
        return (
            <div className = {classes.root}>
               <div  className={classes.titleBlock}>
                  {/* <div className={classes.pageTitle} variant="div" component="div">
                    Gestion des saisies de notes
                  </div>
                  <div className={classes.pageSubtitle} variant="div" component="div">
                    Vous pouvez créer des types d'indisciplines. Ces types ne dépendent  pas de l'année scolaire.
                  </div> */}
                   <AjouterSaisieEnseignantView userId ={this.state.currentUser!=null?this.state.currentUser.id:null} etablissement={this.props.etablissement}   annee={this.props.annee}  {...deleteClassInProps(this.props)} /> 
               </div>
               <ListSaisieEnseignantView userId ={this.state.currentUser!=null?this.state.currentUser.id:null}  etablissement={this.props.etablissement} annee={this.props.annee}  {...deleteClassInProps(this.props)} />
           
            </div>
        );
    }
    
}

ListSaisieEnseignantController.propTypes = {
    classes: PropTypes.object.isRequired
};
  
export default withStyles(useStyles)(ListSaisieEnseignantController);