import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import DateTimePicker from 'react-datetime-picker';
import { FormattedDate, FormattedTime, injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { appProperties, isObjectValueEquals, userProfileUrl } from "../../../components/LdUtils";




const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    padding: '10px',
    borderBottom: '1px solid #EAEAEA',
    '&:hover': {
      backgroundColor: '#F1F1F1'
    }
  },


  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px",
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    "flex": "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },


  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonSimple: {

    textTransform: 'none',

  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 600
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  name: {
    fontSize: "12px",
    fontWeight: '600',
    textDecoration: 'none',
  },
  date: {
    fontSize: "12px",
    fontWeight: '400',

  },
  bigAvatar: {

    width: "40px !important",
    height: "40px !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: '40px',
    '& .react-datetime-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },
  main: {
    width: '100%'
  },
  blockAchat: {

    margin: '5px 5px 5px 30px',
  },
  itemBody: {
    fontSize: 13,
    padding: '4px 7px 4px 7px',
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-orange-500)',
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
});


// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate
      day="numeric"
      month="long"
      year="numeric"
      value={date} />
    &nbsp;{intl.formatMessage({ id: "code_a" })} &nbsp;
    <FormattedTime
      hour='numeric'
      minute='numeric'
      value={date} />
  </span>
));

class ItemBonElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      inProgressValidate: false,
      modeEdit: false,
      position: this.props.position,
      controlled: true,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      openAddAchat: false,
      datas: this.props.row.achats || [],
      caracteristique: '',
      fournisseur: "",
      pu: 0,
      quantite: 0,
      date: new Date(),

    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleAddAchat = this.handleAddAchat.bind(this);

    this.handleAdd = this.handleAdd.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this._handleNewAchat = this._handleNewAchat.bind(this);
  }

  onUpdateItem(mode, item) {
    var array = [...this.state.datas];
    if (mode === 'update') {
      array.splice(item.position, 1, item.row);
    } else if (mode === 'delete') {
      array.splice(item.position, 1);
    }
    this.setState(state => {
      state.datas = array;
      return state;
    });
  }
  _handleNewAchat(value) {
    this.setState(state => {
      state.datas = [value, ...state.datas];
      return state;
    });
  };

  handleAddAchat = () => {
    this.setState(state => {
      state.openAddAchat = !state.openAddAchat;
      return state;
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          datas: self.props.row.achats || [],
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }
  handleEdit = () => {
    this.setState({ modeEdit: true });
  }
  reset = () => {
    this.setState({ modeEdit: false, controlled: true });
  }
  handleDateChange(date) {
    this.setState({
      date: date,
    });
  }

  handleChangeInput = name => event => {
    var val = {};

    if (name === 'pu' || name === "quantite") {
      var value = event.target.value;

      if (value < 0) {
        value = 0;
        event.target.value = value;
      }
      if (event.target.value === "") value = 0;
      val[name] = parseFloat(value);
      this.setState(val);
    } else {
      val[name] = event.target.value;
      this.setState(val);
    }

  };
  handleAdd(event) {
    if (this.state.quantite <= 0 || this.state.pu <= 0 || this.state.date == null) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("pu", this.state.pu);
    formData.append("quantite", this.state.quantite);
    formData.append("caracteristique", this.state.caracteristique);
    formData.append("fournisseur", this.state.fournisseur);
    formData.append("date", moment(this.state.date).format('YYYY-MM-DD HH:mm:ss'));

    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/commande-materiel/add-achat?id=" + this.props.row.id, {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false });
        if (data.result_code === 1) {
          self._handleNewAchat(data.item);
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBonElement_14" }), { variant: "success", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -3) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBonElement_15" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -4) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBonElement_16" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else if (data.result_code === -100) {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBonElement_17" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        } else {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemBonElement_18" }), { variant: "error", anchorOrigin: { vertical: 'bottom', horizontal: 'left', }, });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes, row } = this.props;
    const { datas } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className="layout horizontal center">
          <div className={classes.itemBody} style={{ width: '30%', padding: '5px' }}>
            <div>
              <div>
                <b>{row.materiel && row.materiel != null ? row.materiel.name : ''}</b>
              </div>
              <div style={{ fontWeight: "400" }} className={classes.date}>
                <FormattedMessage
                  id="date_user"
                  values={{
                    date: <PostDate date={row.created} />,
                    user: (
                      <a
                        style={{
                          paddingLeft: "5px",
                          fontWeight: "500",
                          fontSize: "11px"
                        }}
                        className="no-underline capitalize"
                        title={row.creator.name}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={userProfileUrl(row.creator)}
                      >
                        {row.creator.name}
                      </a>
                    )
                  }} />
              </div>
            </div>
          </div>
          <div className={classes.itemBody} style={{ width: '10%', padding: '5px' }}>
            {row.quantite}
          </div>
          <div className={classes.itemBody} style={{ width: '10%', padding: '5px' }}>
            {formatMessage({ id: "Money_cfa" }, { value: row.pu })}
          </div>
          <div className={classes.itemBody} s tyle={{ width: '10%', padding: '5px' }}>
            {formatMessage({ id: "Money_cfa" }, { value: row.pu * row.quantite })}
          </div>
          <div className={classes.itemBody} style={{ width: '30%', padding: '5px' }}>
            {row.caracteristique}
          </div>
          <div className={classes.itemBody} style={{ width: '10%', padding: '5px' }}>
            <IconButton
              title={formatMessage({ id: 'ItemBonElement_12' })}
              aria-label="Delete"
              style={{ backgroundColor: 'var(--app-primary-color)', color: 'white' }}
              onClick={this.handleAddAchat}>
              <AddIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
        <div className={classes.blockAchat}>
          {this.state.openAddAchat && <div style={{ padding: '10px', border: '1px solid #EAEAEA' }} >
            <Paper elevation={0} style={{ padding: '10px ' }} className="layout vertical">
              <div>
                <Typography className={classes.heading}>
                  <FormattedMessage
                    id="ItemBonElement_11"
                    values={{
                      material: <b>"{row.materiel && row.materiel != null ? row.materiel.name : ''}".</b>
                    }}
                  />
                </Typography>
              </div>
              <br />
              {this.state.inProgress
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
              {!this.state.controlled
                ? <div className={classes.error}>{formatMessage({ id: 'ItemBonElement_10' })}</div>
                : null
              }
              <div className="layout horizontal center justified">

                <TextField
                  id="quantite"
                  label={formatMessage({ id: 'ItemBonElement_8' })}
                  style={{ width: '30%', marginTop: '0 !important' }}
                  value={this.state.quantite}
                  placeholder={formatMessage({ id: 'ItemBonElement_9' })}
                  onChange={this.handleChangeInput('quantite')}
                  margin="normal"
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },

                  }}
                />
                <TextField
                  id="prix_unitaire"
                  label={formatMessage({ id: 'BonAchat_6' })}
                  style={{ width: '30%', marginTop: '0 !important' }}
                  value={this.state.pu}
                  placeholder="12500"
                  onChange={this.handleChangeInput('pu')}
                  margin="normal"
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },

                  }}
                />
                <div style={{ width: '30%', marginTop: '0 !important' }} className="layout horizontal center">
                  <span className={classes.label}>
                    {formatMessage({ id: "ItemBonElement_7" })}
                  </span>
                  <DateTimePicker
                    // maxDate={this.state.maxDate}
                    locale='fr-FR'
                    className={classes.datePicker}
                    value={this.state.date}
                    onChange={this.handleDateChange}
                  />
                </div>
              </div>
              <div className="layout horizontal center">
                <TextField
                  id="caracteristique"
                  label={formatMessage({ id: 'BonAchat_8' })}
                  required
                  name="caracteristique"
                  placeholder={formatMessage({ id: 'ItemBonElement_6' })}
                  value={this.state.caracteristique}
                  autoComplete="caracteristique"
                  className="flex"
                  style={{ marginRight: '10px !important' }}
                  onChange={this.handleChangeInput('caracteristique')}
                  multiline
                  rows="3"
                />
                <TextField
                  id="fournisseur"
                  label={formatMessage({ id: 'ItemBonElement_4' })}
                  required
                  name="fournisseur"
                  placeholder={formatMessage({ id: 'ItemBonElement_5' })}
                  value={this.state.fournisseur}
                  autoComplete="fournisseur"
                  className="flex"
                  onChange={this.handleChangeInput('fournisseur')}
                  multiline
                  rows="3"
                />
                <div style={{ marginLeft: '10px' }} className="layout vertical">
                  <span className="flex"></span>
                  <Button
                    onClick={this.handleAdd}
                    variant="contained"
                    size="medium"
                    className={classes.buttonPrincipale}>
                    {formatMessage({ id: 'AjouterRoleView_add' })}
                  </Button>
                </div>
              </div>

            </Paper>
          </div>}
          {(datas.length > 0 &&
            <React.Fragment>
              <p className={classes.pageSubtitle}>{formatMessage({ id: 'ItemBonElement_3' })}</p>

              {((datas && datas.length > 0) &&
                <div>
                  {datas.map((item, index) => (
                    <div className="layout horizontal center" style={{ padding: '10px 0', borderBottom: '1px dashed #EAEAEA' }} key={item.id}>
                      <Avatar className={classes.orangeAvatar}>
                        {index + 1}
                      </Avatar>
                      <div>

                        <div style={{ lineHeight: 1 }} className='layout horizontal center'>

                          <div>
                            <span style={{ fontSize: '12px' }}>{formatMessage({ id: 'BonAchat_5' })}: </span>&nbsp; <span className={classes.name}>{item.quantite}</span>
                          </div>
                          <div style={{ marginLeft: '10px' }}>
                            <span style={{ fontSize: '12px' }}>{formatMessage({ id: 'BonAchat_6' })}: </span>&nbsp; <span className={classes.name}>{formatMessage({ id: 'Money_cfa' }, { value: item.pu })} </span>
                          </div>
                          <div style={{ marginLeft: '10px' }}>
                            <span style={{ fontSize: '12px' }}>{formatMessage({ id: 'BonAchat_7' })}: </span>&nbsp; <span className={classes.name}>{formatMessage({ id: 'Money_cfa' }, { value: item.quantite * item.pu })}</span>
                          </div>
                        </div>
                        <div style={{ lineHeight: 1 }} className='layout horizontal center'>
                          <span style={{ fontSize: '12px' }}>{formatMessage({ id: 'BonAchat_8' })}: </span> &nbsp; <span className={classes.name}>{row.caracteristique && row.caracteristique !== "" ? row.caracteristique : "#######"}</span>
                        </div>

                        <div style={{ lineHeight: 1 }} className='layout horizontal center'>
                          <span style={{ fontSize: '12px' }}>{formatMessage({ id: 'ItemBonElement_2' })} </span> &nbsp; <span className={classes.name}>{row.fournisseur && row.fournisseur !== "" ? row.fournisseur : "*******"}</span>
                        </div>
                        <div className="layout horizontal center">
                          <div style={{ marginRight: '15px' }} className='layout horizontal center'>
                            <div className={classes.date}>
                              <FormattedMessage
                                id="ItemBonElement_13"
                                values={{
                                  value: <PostDate date={item.date} />,
                                }} />
                            </div>
                          </div>
                          <div style={{ fontWeight: "400" }} className={classes.date}>
                            <FormattedMessage
                              id="date_user"
                              values={{
                                date: <PostDate date={row.created} />,
                                user: (
                                  <a
                                    style={{
                                      paddingLeft: "5px",
                                      fontWeight: "500",
                                      fontSize: "11px"
                                    }}
                                    className="no-underline capitalize"
                                    title={row.creator.name}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={userProfileUrl(row.creator)}
                                  >
                                    {row.creator.name}
                                  </a>
                                )
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          )}
          {(datas.length <= 0 &&
            <div style={{ padding: '15px', color: 'var(--paper-yellow-900)', fontWeight: 500, fontSize: '14px', textAlign: 'center' }}>{formatMessage({ id: 'ItemBonElement_1' })}</div>
          )}
        </div>
        <hr />
      </React.Fragment>
    );
  }
}
ItemBonElement.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà. 
export default withSnackbar(withStyles(styles, { withTheme: true })(injectIntl(ItemBonElement)));
