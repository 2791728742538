import React, { Component } from "react";
import LoginView from '../views/LoginView';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {deleteClassInProps} from "../../components/LdUtils";
class LoginController extends Component{
    state={
        nom:"",
        prenom:"",
    }
    constructor(props){
        super(props);
        console.log(this.props)
        /*const { match } = this.props // coming from React Router.
        console.log(match.path) // /topics/:topicId/:subId
        console.log(match.url) // /topics/react-router/url-parameters
        this.setState({
            nom:"tanda",
            prenom:"cedric"
        })*/
    }
    
    componentDidMount() {
        /*const values = queryString.parse(this.props.location.search)
        console.log(values.filter) // "top"

        console.log(values.origin) // "im"*/
    }
    render(){
       // const { classes  } = this.props;
        // var props2 = Object.assign({}, this.props);
        // if(props2.hasOwnProperty("classes"))delete props2.classes;
        return (
            <div style={{height:"100%"}} className ="layout vertical center-center">
                <LoginView   email = {this.props.email}  {...deleteClassInProps(this.props)}  />
            </div>
        );
    }
}


const styles = theme => ({
    element: {
      display: "block", // Fix IE 11 issue.
      height:"100% "
    },
    
  });
LoginController.propTypes = {
    classes: PropTypes.object.isRequired,
    /*theme: PropTypes.object.isRequired,*/

};
export default withStyles(styles/*,{ withTheme: true }*/)(LoginController);

