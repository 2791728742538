import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import LdSelect from "../../../components/LdSelect";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";
// import LdSelectStatic from '../../../components/LdSelectStatic';

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },
  root: {
    width: "100%",
    marginTop: "30px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    "&>h6": {
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 600
    }
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  }
});

class AjouterMatiereView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      selectedOption: null,
      redirect: false,
      matiereId: this.props.matiereId,
      etablissementId: this.props.etablissementId,
      ids: "",
      datas: [],
      data: null,
      dialogOpened: false,
      inProgress: false,
      cycleId: '---',
      systemeId: '---'
    };
    this.add = this.add.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValue = this.handleValue.bind(this);
    this.handleChangeSysteme = this.handleChangeSysteme.bind(this);
    this.handleChangeCycle = this.handleChangeCycle.bind(this);

  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleChangeCycle(item) {
    console.log("handle change cycle");
    console.log(item);
    this.setState({ cycleId: item != null ? item.id : '' });
  }
  handleChangeSysteme(item) {
    this.setState({ systemeId: item != null ? item.id : '' });
  }
  handleValue(selectedOptions) {
    var ids = selectedOptions.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + "-" + e.value : e.value;
    }, "");
    this.setState({ ids: ids });
  }

  add() {
    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/manager/etablissement/add-matieres?etablissement=" +
      this.state.etablissementId +
      "&ids=" +
      this.state.ids,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
        // body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          fireWithObject(document, "new-matiere", { value: data.matieres });
          self.handleClose();
          console.log("etablissement", this.state.ids);
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  computeUrlListMatiere(current_cycle_id, systemeId) {
    if (current_cycle_id === "" && systemeId === "") return;
    return appProperties.basepathServer + "/rest-matiere/list-published?limit=1000&cycle_id=" + current_cycle_id + "&systeme_enseignement_id=" + systemeId;

  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>
        <Button
          variant="contained"
          size="medium"
          className={classes.buttonPrincipale}
          onClick={this.handleClickOpen}
        >
          {formatMessage({ id: "AjouterMatiereView_2" })}
        </Button>
        <Dialog
          open={this.state.dialogOpened}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={"md"}
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">
            {formatMessage({ id: "AjouterMatiereView_1" })}{" "}
          </DialogTitle>
          <DialogContent style={{ height: "500px" }}>
            {this.state.inProgress ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {/* <div  className={classes.pageSubtitle} >Renseignez toutes les informations sur les matieres d'un etablissement </div> */}
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>
              {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}
              {this.state.redirect ? (
                <Redirect
                  to={{
                    pathname: "/",
                    search: "",
                    state: {}
                  }}
                />
              ) : null}

              {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null}

              <div className={classes.root}>

                {/* <LdSelectStatic
                  options={
                    (this.props.etablissement.systemesEnseignements || []).map(option => {
                      return {
                        label: option.name ? option.name : '',
                        value: option.id ? option.id : '',
                        id: option.id ? option.id : '',
                      };
                    })
                  }

                  label={formatMessage({ id: "AjouterClasseEtablissementView_14" })}
                  handleValue={this.handleChangeSysteme}

                  isSearchable
                  {...deleteClassInProps(this.props)}
                /> */}
                <LdSelect
                  label={formatMessage({ id: "AjouterClasseEtablissementView_14" })}
                  handleValue={this.handleChangeSysteme}
                  firstLoad isSearchable
                  baseUrl={appProperties.basepathServer + "/rest-systeme-enseignement/list?limit=0"}  {...deleteClassInProps(this.props)} />

                <LdSelect
                  label={formatMessage({ id: "AjouterClasseEtablissementView_5" })}
                  handleValue={this.handleChangeCycle}
                  firstLoad isSearchable
                  baseUrl={appProperties.basepathServer + "/rest-cycle/list?limit=0"}  {...deleteClassInProps(this.props)} />
                <LdSelect
                  handleValue={this.handleValue}
                  //  isSearchable
                  isMulti
                  firstLoad
                  isSearchable
                  baseUrl={this.computeUrlListMatiere(this.state.cycleId, this.state.systemeId)}

                  {...deleteClassInProps(this.props)}
                />
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}
            >
              {formatMessage({ id: "AjouterTypeAlertView_13" })}
            </Button>
            <Button
              onClick={this.add}
              variant="contained"
              size="medium"
              className={classes.buttonPrincipale}
            >
              {formatMessage({ id: "AjouterTypeAlertView_15" })}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}
AjouterMatiereView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(
  injectIntl(AjouterMatiereView)
);
