import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";

import withStyles from "@material-ui/core/styles/withStyles";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import ProgressiveImage from "react-progressive-image";
import {
  defaultAvatar,
  userProfileUrl,
  isObjectValueEquals
} from "../../../components/LdUtils";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: "10px"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonSimple: {
    textTransform: "none"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none"
  },
  date: {
    fontSize: "12px",
    fontWeight: "400"
  },
  bigAvatar: {
    backgroundColor: "lightgray",
    border: "2px solid white",
    width: "100px !important",
    height: "100px !important",
    borderRadius: "0 !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    // borderRadius:"50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  }
});

// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

// const PostDate = injectIntl(({date, intl}) => (
//   <span  title={intl.formatDate(date)}>
//       {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
//       <FormattedDate
//        day="numeric"
//        month="long"
//        year="numeric"
//        value={date}/>
//   </span>
// ));

class ItemUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,

      classe: null,
      controlled: true,
      row: this.props.row
    };
  }
  componentDidUpdate(prevProps) {
    //var self = this;

    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {});
    }
  }

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>
              <div>
                <Grid
                  container
                  className={classes.grid}
                  justify="flex-start"
                  alignItems="center"
                >
                  <div>
                    <ProgressiveImage
                      src={row.photo}
                      placeholder={defaultAvatar}
                    >
                      {(src, loading) => (
                        <Avatar
                          alt=""
                          src={src}
                          className={classes.bigAvatar}
                        />
                      )}
                    </ProgressiveImage>
                  </div>
                  <div style={{ paddingLeft: "15px" }}>
                    <div style={{ fontSize: "15px", fontWeight: "600" }}>
                      <a
                        className="no-underline capitalize"
                        title={row.name}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={userProfileUrl(row)}
                      >
                        {row.name}
                      </a>
                    </div>
                    <div
                      style={{ fontSize: "15px", fontWeight: "600" }}
                      className="layout horizontal center"
                    >
                      <span className={classes.name}>{row.email}</span>
                    </div>
                    <div>
                      <span
                        title={formatMessage({ id: "ItemUser_1" })}
                        style={{ fontSize: "small" }}
                      >
                        {" "}
                        {formatMessage({ id: "ItemUser_1" })}{" "}
                        {!row.sexe ? "Masculin" : "Feminin"}
                      </span>
                    </div>
                    <div className="layout horizontal center">
                      <span
                        style={{
                          fontWeight: "500",
                          paddingRight: "10px",
                          fontSize: "12px"
                        }}
                      >
                        {formatMessage({ id: "ItemUser_2" })}{" "}
                      </span>
                      <span
                        style={{
                          fontWeight: "600",
                          paddingRight: "10px",
                          fontSize: "12px"
                        }}
                      >
                        {formatMessage({ id: "ItemUser_3" })}
                        {row.mobile +
                          formatMessage({ id: "ItemUser_4" }) +
                          row.phone}
                      </span>
                    </div>
                    {/*<div  style={{fontWeight:"400"}} className={classes.date}>Ajouté le 
                           <PostDate date={row.created}/> par 
                              <a style={{paddingLeft:'5px',fontWeight:"500",fontSize:'11px'}} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer"  target="_blank"   href={userProfileUrl(row.creator)}>
                                {row.creator.name}
                              </a>  
                           </div>*/}
                  </div>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ItemUser.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà.
export default withStyles(styles, { withTheme: true })(injectIntl(ItemUser));
