import { Button } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { appProperties, isObjectValueEquals } from "../../../components/LdUtils";
import LdSelect from '../../../components/LdSelect';
import LdSelectGroupClasse from '../../../components/LdSelectGroupClasse';
import LdSelectClasseEtablissement from '../../../components/LdSelectClasseEtablissement';
import { deleteClassInProps } from '../../../components/LdUtils';
import { injectIntl } from "react-intl";
import LdSelectEtablissementEnseignant from "../../../components/LdSelectEtablissementEnseignant";

const styles = theme => ({
  main: {
    width: "400px",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      width: 450,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px 0 ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    display: "inline-block",
    width: "64px",
    height: "64px",
    background: "#ddd"
    // backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    fontSize: "18px",
    borderRadius: "0 !important",
    textTransform: "lowercase",
    backgroundColor: theme.color.primaryDark,
    "&:hover": {
      backgroundColor: "red !important"
    }
  },
  progress: {
    width: "100%",
    flexGrow: 1
  },
  close: {
    padding: theme.spacing.unit / 2
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
});

class AddElementEnseignant extends Component {
  constructor(props) {
    super(props);
    //let user = localStorage.getItem(appProperties.key_user) || null;
    //let currentUser = JSON.parse(user);
    this.state = {
      dialogOpened: false,
      //currentUser: currentUser,
      controlled: true,
      matiere: null,
      classe: null,
      user: this.props.user,
      etablissement: this.props.etablissement,

      annee: this.props.annee,
      coefficient: 0,
      quotaHoraire: 0,
      ordre: 0,
      matiereName: "",
      openSnack: false,
      secondaryTeachersEdit: [],

      // editorState: EditorState.createEmpty()
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.doCloseDialog = this.doCloseDialog.bind(this);
    this.doAdd = this.doAdd.bind(this);
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    this.handleChangeMatiere = this.handleChangeMatiere.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeGroup = this.handleChangeGroup.bind(this);
    this.handleValueSecondaryTeachers = this.handleValueSecondaryTeachers.bind(this);

  }
  componentDidUpdate(prevProps) {
    //var self = this;
    //if(JSON.stringify(this.props.user) !==  JSON.stringify(prevProps.user)){
    if (!isObjectValueEquals(this.props.user, prevProps.user)) {
      this.setState({ user: this.props.user }, () => {

      });
    }
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
      });
    }

  }
  doOpenDialog() {
    /*this.setState({
      email: this.state.currentUser != null ? this.state.currentUser.email : ""
    });*/
    this.setState({ dialogOpened: true });
  }

  doCloseDialog() {
    this.setState({ dialogOpened: false });
  }

  handleChangeClass(item) {
    this.setState({ classe: item ? item : null });
  };
  handleChangeMatiere(item) {
    this.setState({ matiere: item ? item : null });
  };
  handleChangeGroup(item) {
    this.setState({ group: item ? item : null });
  };
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };
  handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnack: false });
  };


  handleValueSecondaryTeachers(selectedOptions) {

    this.setState({ secondaryTeachersEdit: selectedOptions });
  }
  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(null);
  }
  doAdd() {
    console.log(this.state);
    if (
      this.state.annee == null ||
      this.state.etablissement == null ||
      this.state.user == null ||
      this.state.classe == null ||
      this.state.matiere == null ||
      this.state.group == null ||
      parseFloat(this.state.coefficient) <= 0 ||
      parseInt(this.state.ordre) <= 0 ||
      parseInt(this.state.quotaHoraire) < 0
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("etablissement", this.state.etablissement.id);
    formData.append("user", this.state.user.id);
    formData.append("classe", this.state.classe.id);
    formData.append("annee", this.state.annee.id);
    formData.append("matiere", this.state.matiere.id);
    formData.append("group", this.state.group.id);

    formData.append("coefficient", this.state.coefficient);
    formData.append("quotaHoraire", this.state.quotaHoraire);
    formData.append("orderingGroupe", this.state.ordre);
    formData.append("matiereName", this.state.matiereName);
    var secondaryTeachersIds = (this.state.secondaryTeachersEdit || []).reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + "-" + e.id : e.id;
    }, "");
    formData.append("secondary_ids", secondaryTeachersIds);

    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer + "/manager/annee-scolaire/add-enseignant",
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.setState({ openSnack: true });
          self.props.onNewElement(data.row);
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          // fireWithObject(document,'new-autorisation',{value:data.row});
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        self.setState({ openSnack: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      //  <p>bonjour tout le monde</p>
      <div>
        <Dialog
          open={this.state.dialogOpened}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.pageTitle} id="form-dialog-title">
            {formatMessage({ id: "AddElementEnseignant_1" })}{this.state.user.name}
          </DialogTitle>
          <DialogContent>
            {this.state.inProgress ? (
              <div className={classes.progress}>
                <LinearProgress variant="query" />
              </div>
            ) : null}
            {/* <div  className={classes.pageSubtitle} >Renseignez toutes les informations sur l'enseignat d'un etablissement </div> */}
            <CssBaseline />
            <Paper square elevation={0} className={classes.paper}>
              {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}
              {this.state.redirect ? (
                <Redirect
                  to={{
                    pathname: "/",
                    search: "",
                    state: {}
                  }}
                />
              ) : null}

              {this.state.result != null ? (
                <div className="Error-message">{this.state.result.message}</div>
              ) : null}
              {!this.state.controlled
                ? <div className={classes.error}>{formatMessage({ id: "ItemAnneeScolaire_Check" })}</div>
                : null
              }
              <div style={{ width: '100%', marginBottom: "15px" }}>
                <LdSelectClasseEtablissement
                  handleValue={this.handleChangeClass}
                  label={formatMessage({ id: "AjouterEleveView_4" })}
                  firstLoad isSearchable
                  baseUrl={appProperties.basepathServer + "/rest-etablissement/list-classes?id=" + this.state.etablissement.id + "&limit=1000"}  {...deleteClassInProps(this.props)} />
              </div>



              <div style={{ width: '100%' }}>
                <LdSelect
                  handleValue={this.handleChangeMatiere}
                  firstLoad isSearchable
                  label={formatMessage({ id: "AddElementEnseignant_2" })}
                  baseUrl={appProperties.basepathServer + '/manager/etablissement/matieres?id=' + this.state.etablissement.id + '&limit=-1'}  {...deleteClassInProps(this.props)} />
              </div>
              <FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="matiereName">{formatMessage({ id: "ItemEnseignant_18" })}</InputLabel>
                <Input
                  name="matiereName"
                  id="matiereName"
                  type="text"
                  onChange={this.handleChangeInput("matiereName")}
                  autoComplete="matiereName"
                />
              </FormControl>
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="coefficient">{formatMessage({ id: "ItemEnseignant_8" })}</InputLabel>
                <Input
                  name="coefficient"
                  id="coefficient"
                  required
                  type="number"
                  min="0"
                  onChange={this.handleChangeInput("coefficient")}
                  autoComplete="coefficient"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </FormControl>
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="quotaHoraire">{formatMessage({ id: "ItemEnseignant_9" })}</InputLabel>
                <Input
                  name="quotaHoraire"
                  id="quotaHoraire"
                  required
                  type="number"
                  min="0"
                  onChange={this.handleChangeInput("quotaHoraire")}
                  autoComplete="quotaHoraire"
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </FormControl>
              <div style={{ width: '100%' }}>
                <LdSelectGroupClasse
                  label={formatMessage({ id: "ItemEnseignant_10" })}
                  handleValue={this.handleChangeGroup}
                  isSearchable
                  baseUrl={appProperties.basepathServer + "/manager/groupe-enseignement/list?limit=-1&classe=" + (this.state.classe != null ? this.state.classe.id : '')}  {...deleteClassInProps(this.props)} />
              </div>
              <FormControl margin="dense" required fullWidth>
                <InputLabel htmlFor="quotaHoraire">{formatMessage({ id: "ItemEnseignant_11" })}</InputLabel>
                <Input
                  id="ordre"
                  label={formatMessage({ id: "ItemEnseignant_12" })}
                  value={this.state.ordre}
                  placeholder="Ex: 1"
                  onChange={this.handleChangeInput("ordre")}
                  margin="normal"
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },

                  }}
                />
              </FormControl>
              <div style={{ width: '100%' }}>
                <LdSelectEtablissementEnseignant
                  handleValue={this.handleValueSecondaryTeachers}
                  firstLoad
                  isSearchable
                  isMulti
                  label={formatMessage({ id: "ItemEnseignant_19" })}
                  baseUrl={
                    appProperties.basepathServer +
                    "/manager/etablissement/enseignants?id=" +
                    this.props.etablissement.id +
                    "&limit=-1&name="
                  }
                  {...deleteClassInProps(this.props)}
                />
              </div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.doCloseDialog}
              variant="contained"
              size="medium"
              className={classes.buttonGreyWhite}
            >
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button
              onClick={this.doAdd}
              variant="contained"
              size="medium"
              className={classes.buttonPrincipale}
            >
              {formatMessage({ id: "AddElementEnseignant_3" })}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{formatMessage({ id: "AddElementEnseignant_4" })}</span>}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarClose}
            >
              OK!
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}
AddElementEnseignant.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(injectIntl(AddElementEnseignant));
