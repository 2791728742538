import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import { appProperties, isObjectValueEquals } from '../../../components/LdUtils';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import PropTypes from 'prop-types';


import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListCahierMatiere from './ListCahierMatiere'
import { deleteClassInProps } from '../../../components/LdUtils';
import { injectIntl } from "react-intl";

import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: '15px',
    marginTop: '15px',
    backgroundColor: 'white',
    // marginLeft: '10px',
  },
  main2: {
    padding: '15px',
    marginTop: '15px',
    backgroundColor: 'white',
    // marginLeft: '10px',
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },




  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },


  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  }  /** Filter end */
});


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ListCahierView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      classes: [],
      classe: null, //No change to null. it is used to fix bug in select. 
      enseignant: null,

      etablissement: this.props.etablissement,
      annee: this.props.annee,
      progress: 1,
      step: 0,
      progressEnseignant: 0,

      categories: [],
      categorie: "---",
    }
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeEnseignant = this.handleChangeEnseignant.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.closeItem = this.closeItem.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);

  }


  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value) }, (state) => {
      self.listEnseignants();
    });
  };

  handleReset = () => {
    var self = this;
    this.setState({
      classes: [],
      step: 0,
      classe: null,
      enseignant: null,
    }, () => {
      self.listEnseignants();

    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();

      });
    }
  }
  handleChangeClass = value => event => {
    //this.setState({ step:1 , classe: value, enseignant:(value.enseignants && value.enseignants.length>0?value.enseignants[0]:null)},(state)=>{
    this.setState({ step: 1, classe: value, enseignant: null }, (state) => {

    });
  };
  closeItem = event => {
    this.setState({ step: 0, classe: null, enseignant: null }, (state) => {

    });
  };


  handleChangeEnseignant = (event, value) => {
    this.setState({ enseignant: value }, (state) => {

    });
  };

  componentDidMount() {
    this.listCategorie();

    //this.listEnseignants();
  }
  componentWillUnmount() {
  }


  listEnseignants() {

    let self = this;
    this.setState({ classes: [], progressEnseignant: 1 });
    var url = appProperties.basepathServer + "/rest-enseignant/list-grouped?etablissement_id=" + this.props.etablissement.id + "&limit=-1";
    if (this.props.annee != null) url += ("&annee_id=" + this.props.annee.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          var enseignants = [];
          (data.datas || []).forEach(e => {
            enseignants = enseignants.concat(e.enseignants);
          });
          self.setState({ progressEnseignant: 0, classes: self.formatData({ enseignants: enseignants }) });

        } else {
          self.setState({ classes: [], progressEnseignant: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ classes: [], progressEnseignant: 3 });

        console.log('There was an error listed type de frais.');
      });
  }
  formatData(input) {
    if (null == input) return [];
    if (input.enseignants && Array.isArray(input.enseignants) && 0 < input.enseignants.length) {
      var enseignants = input.enseignants;
      var output = Object.assign({}, input);
      delete output.enseignants;
      var exist = false;
      var myReduce = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
        // console.log(accumulator);
        exist = accumulator.reduce((acc, cur, ind, arr) => {
          return acc ? acc : null != cur && null != currentValue.classeEtablissement && cur.id === currentValue.classeEtablissement.id;
        }, false);
        return exist ? accumulator : accumulator.concat(currentValue.classeEtablissement ? currentValue.classeEtablissement : null);
      }, []);
      var contents = [];
      var mat = null;
      console.log("myReduce ", myReduce);
      var myMap = myReduce.map((classe, index, array) => {
        contents = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
          if (null != classe && null != currentValue.classeEtablissement && classe.id === currentValue.classeEtablissement.id) {
            mat = Object.assign({}, currentValue);
            delete mat.classeEtablissement;
            return accumulator.concat(mat);
          } else return accumulator;
        }, []);
        classe["enseignants"] = contents;
        return classe;
      });
      //output["classes"] = myMap;

      return myMap;
    }
    //input["classes"] = [];
    return [];
  }


  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }


  render() {
    const { classes } = this.props;
    const { step, classe, enseignant } = this.state;
    const { formatMessage } = this.props.intl;

    if (step === 0) return (
      <div className={classes.main}>
        <Grid container className={classes.grid} justify="space-between" alignItems='center'>
          <div>
            <div className={classes.pageTitle} variant="div" component="div">
              {formatMessage({ id: "ListCahierView_Title" })}
            </div>
            <div className={classes.pageSubtitle} variant="div" component="div">
              {formatMessage({ id: "ListCahierView_subTitle" })}
            </div>
          </div>
          <div>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "ListClasseEtablissementView_4"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.categorie}
                onChange={this.handleChangeCategorie}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListClasseEtablissementView_3" })}
                </MenuItem>
                {this.state.categories.map((row, index) => (
                  <MenuItem title={row.description} key={index} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

        </Grid>
        <div>
          <div className="layout horizontal wrap">


            {this.state.classes.map((row, index) => (
              <Card onClick={this.handleChangeClass(row)} elevation={2} key={index} style={{ maxWidth: 190, maxHeight: 250, margin: '5px 10px' }} className="layout vertical ">
                <CardActionArea className="flex">
                  <CardMedia
                    component="img"
                    alt="Notebook"
                    className={classes.media}
                    height="250"
                    image='/images/icons/notebook.png'

                  />
                  <CardContent title={formatMessage({ id: "ListCahierView_1" })} className="layout horizontal" style={{ padding: '5px', position: 'absolute', top: '0', bottom: 0, left: 0, right: 0 }}>
                    <div className="flex" style={{ paddingLeft: '33px' }}>
                      <div className="layout horizontal center-justified" style={{ fontWeight: '600', fontSize: '12px', padding: '14px 5px 5px', color: 'white', textTransform: 'uppercase' }}>
                        <span>{formatMessage({ id: "LdEtablissementMenu_43" })}</span>
                      </div>
                      <div className="layout vertical center" style={{ paddingTop: '90px' }}>
                        <div style={{ fontWeight: '600', fontSize: '15px', textAlign: 'center', color: 'white', height: '40px', marginBottom: '20px' }}>
                          {row.name}
                        </div>
                        <div style={{ fontWeight: '400', fontsize: '13px', padding: '0 5px', color: '#2f4f4f' }}>
                          {(row != null && row !== "---" && row.enseignants && row.enseignants.length > 0) ? row.enseignants.length + formatMessage({ id: "ListCahierView_2" }) : formatMessage({ id: "ListCahierView_3" })}
                        </div>
                        <div style={{ fontWeight: '500', fontSize: '12px', padding: '3px 5px', textAlign: 'center', color: 'white' }}>
                          <span style={{ fontWeight: '600' }}>{this.props.annee.year}</span>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </CardActionArea>

              </Card>
            ))}
          </div>

          {(this.state.progressEnseignant === 1) && (
            <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
              <div style={{ padding: '15px' }}>
                <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
              </div>
              <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                {formatMessage({ id: "ListRoleView_Load" })}
              </div>
            </div>
          )}
          {(this.state.progressEnseignant === 2 || (this.state.progressEnseignant === 0 && this.state.classes.length === 0)) && (
            <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                {this.state.categorie == null || this.state.categorie === "---" ? formatMessage({ id: "ListCahierView_14" }) : formatMessage({ id: "ListCahierView_4" })}
              </div>
            </div>
          )}
          {this.state.progressEnseignant === 3 && (
            <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
              <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
              <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                {formatMessage({ id: "ListRoleView_LoadError" })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
    if (step === 1 && classe != null) return (
      <div className={classes.main2}>
        <Grid container className={classes.grid} justify="flex-start" alignItems='center'>
          <IconButton onClick={this.closeItem} title={formatMessage({ id: "ListCahierView_5" })} aria-label="return" className={classes.margin}>
            <KeyboardBackspaceIcon fontSize="small" />
          </IconButton>
          <div>
            <div className={classes.pageTitle} variant="div" component="div">
              {formatMessage({ id: "ListCahierView_6" })}{classe.name}
            </div>
            <div className={classes.pageSubtitle} variant="div" component="div">
              {formatMessage({ id: "ListEnseignantView_2" })}
            </div>
          </div>
        </Grid>
        <div>

          <div style={{ marginTop: '10px' }} className={classes.root}>
            <AppBar elevation={0} position="static" color="default">
              <Tabs
                value={enseignant}
                onChange={this.handleChangeEnseignant}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label={formatMessage({ id: "ListCahierView_13" })} value={null} />
                {(classe != null && classe !== "---" && classe.enseignants) && classe.enseignants.map((row, index) => (
                  <Tab key={index} label={row.matiereName || '#' + row.matiere.name} value={row} />
                ))}
              </Tabs>
            </AppBar>
            {enseignant === null &&
              <div style={{ padding: '50px' }} className="layout horizontal center-justified">
                <div style={{ border: '1px solid var(--app-primary-color)', borderRadius: '20px', padding: '50px' }} className="layout vertical center">
                  <span style={{ color: 'white', padding: '5px 40px', textAlign: 'center', fontSize: '20px', fontWeight: '600', backgroundColor: 'var(--app-primary-color)', marginTop: '-67px' }}>{formatMessage({ id: "ListCahierView_12" })}</span>
                  <div style={{ marginTop: '20px', lineHeight: '2em' }}>
                    {formatMessage({ id: "ListCahierView_7" })}
                    <br />
                    {formatMessage({ id: "ListCahierView_8" })}  <br />
                    <ul>
                      <li>{formatMessage({ id: "ListCahierView_9" })} </li>
                      <li> {formatMessage({ id: "ListCahierView_10" })}</li>
                      <li>{formatMessage({ id: "ListCahierView_11" })}</li>
                    </ul>
                  </div>
                </div>
              </div>}
            {(classe != null && classe !== "---" && classe.enseignants) && classe.enseignants.map((row, index) => (
              <div key={index}>{enseignant != null && enseignant.id === row.id && <ListCahierMatiere mode="manager" etablissement={this.props.etablissement} annee={this.props.annee} classe={classe} enseignant={row}  {...deleteClassInProps(this.props)} />}</div>
            ))}
          </div>
        </div>
      </div>
    );
    return (<span>{formatMessage({ id: "ListCahierEnseignantView_1" })}</span>)
  }
}
export default withStyles(styles)(injectIntl(ListCahierView));
