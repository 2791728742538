import Button from "@material-ui/core/Button";
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from 'moment';

import DateTimePicker from 'react-datetime-picker';
import { appProperties, deleteClassInProps, fireWithObject } from "../../../components/LdUtils";
import LdSelect from "../../../components/LdSelect";
import { injectIntl } from 'react-intl';




const styles = theme => ({
  main: {
    // width: "400px",
    textAlign:"left",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor:'white',
  
  },
   root: {
    width: "100%",
    marginTop: '30px',

   },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },


  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding:'0px'
  },
  
  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  },


  label:{
    fontSize:'15px',
    fontWeight:'500',
    padding:'0 10px 0 0'
  },
  error:{
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop:"15px"

  },
  
  datePicker:{
    height:'35px',
    '& .react-datetime-picker__wrapper':{
      border:'1px dashed #AAAAAA',
      borderRadius:'5px'
    }
  },
});

class AjouterDemandeView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      redirect: false,
      etablissementId: this.props.etablissementId,
      datas: [],
      motif: '',
      // code: '',
      type:null,
      pu: 0,
      date: new Date(),
      inProgress:false,
      controlled:true,
    };
    this.add = this.add.bind(this);
    // this.handleChangeNotes = this.handleChangeNotes.bind(this);
    // this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);

  }
  
  handleChangeType(value){
    this.setState({type:value});
  }
  handleChangeInput = name => event => {
    var val = {};

    if(name==='pu'|| name==="quantite"){
      var value = event.target.value;
     
      if(value<0){
        value = 0;
        event.target.value = value;
      }
      if(event.target.value==="")value=0;
      val[name]= parseFloat(value);
      this.setState(val);
    }else{
      val[name] = event.target.value;
      this.setState(val);
    }
  
  };

  handleDateChange(date){
    
    this.setState({
      date: date,
    });
  }

  // handleChangeNotes(event){
  //   this.setState({motif:event.target.value});
  // }
  // handleChangeCode(event){
  //   this.setState({code:event.target.value});
  // }
 
 
  add() {
    if(this.state.pu == null  ||this.state.motif == null ||this.state.motif.trim() === "" || this.state.date == null || this.state.type == null){
      this.setState({controlled:false});
      return;
    }else{
      this.setState({controlled:true});
    }
    var formData = new FormData(); // Currently empty 
    formData.append("motif", this.state.motif);
    formData.append("amount", this.state.pu);
    formData.append("type", this.state.type.id);
    // formData.append("code", this.state.code);
    // formData.append("fournisseur", this.state.fournisseur);
    if(this.props.annee && this.props.annee != null && this.props.annee!=='---')formData.append("annee", this.props.annee.id);
    formData.append("date", moment(this.state.date).format('YYYY-MM-DD HH:mm:ss'));
    let self = this;
    this.setState({inProgress:true});
    fetch(appProperties.basepathServer + "/manager/decaissement/add-demande?etablissement="+this.props.etablissement.id, {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({inProgress:false});
        if (data.result_code === 1) {
          fireWithObject(document,'new-demande',{value:data.row});

          self.props.enqueueSnackbar("Demande entregistrée créée avec succès", { variant:"success", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else if(data.result_code === -1){
          self.props.enqueueSnackbar("Etablissement introuvable", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else if(data.result_code === -2){
          self.props.enqueueSnackbar("Année introuvable", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else if(data.result_code === -3){
          self.props.enqueueSnackbar("Motif obligatoire", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else if(data.result_code === -4){
          self.props.enqueueSnackbar("Le montant est incorrect.", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({inProgress:false});
        alert("There was an error added.");
      });
  }
  
  render() {
    const { classes } = this.props;
    const{formatMessage}=this.props.intl;
    return (
      <main className={classes.main}>
        <div className={classes.pageTitle} id="form-dialog-title">{formatMessage({ id: "AjouterDemandeView_Title" })}</div>
       
        <div  className={classes.pageSubtitle} >{formatMessage({ id: "AjouterDemandeView_subTitle" })} </div>
        { this.state.inProgress
          ? <div className={classes.progress}><LinearProgress variant="query"/></div> 
          : null
        }
        { !this.state.controlled
          ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div> 
          : null
        }
        <div className="layout horizontal">
            <div style={{paddingRight:'10px'}} className="flex" >
            
              <div className="layout horizontal center justified">
                  <div style={{width:'49%',marginTop:'0 !important'}}>
                    <LdSelect 
                     label={formatMessage({ id: "ListDemande_1" })}
                     handleValue = {this.handleChangeType}
                     firstLoad isSearchable
                     baseUrl={appProperties.basepathServer + "/manager/type-decaissement/list?limit=0&only_published=1&etablissement="+this.props.etablissement.id}  {...deleteClassInProps(this.props)} />
                  </div>
                 
                  <TextField
                    id="montant"
                    label={formatMessage({ id: "ListDemande_2" })}
                    style={{width:'48%',marginTop:'0 !important'}}
                    value={this.state.pu}
                    placeholder="12500"
                    onChange={this.handleChangeInput('pu')}
                    margin="normal"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0 },
                     
                    }}
                  />
                </div>
                <div className="layout horizontal center justified">
                 
                  <TextField
                    id="motif"
                    label={formatMessage({ id: "ListDemande_3" })}
                    style={{width:'68%',marginTop:'0 !important'}}
                    value={this.state.motif}
                    placeholder={formatMessage({ id: "ListDemande_4" })}
                    onChange={this.handleChangeInput('motif')}
                    margin="normal"
                  />
                  <span className={classes.label}>
                        {formatMessage({ id: "ListDemande_5" })}
                    </span>
                    <DateTimePicker
                      // maxDate={this.state.maxDate}
                      locale='fr-FR'
                      className={classes.datePicker}
                      value={this.state.date}
                      onChange={this.handleDateChange}
                    />
                </div>
            
            </div>
            <div className="layout horizontal center">
              {/* <span className="flex"></span> */}
              <Button 
                onClick={this.add} 
                variant="contained"
                size="medium"
                className = {classes.buttonPrincipale}>
                {formatMessage({ id: "AjouterEleveView_9" })}
              </Button>
            </div>
        </div> 
    
      </main>
    );
  }
}
AjouterDemandeView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(
  injectIntl(AjouterDemandeView)
));
