import React, { Component } from "react";
import { appProperties } from "../../../components/LdUtils";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import LdSelectUser from "../../../components/LdSelectUser";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px 0 15px 0",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    }
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1,
    padding: "10px 0"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },

  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  select: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  }
});

class AjouterRoleView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      access: this.props.access || null,
      etablissement: this.props.etablissement || null,
      result: null,
      item: null,
      redirect: false,
      inProgress: false
    };
    this.add = this.add.bind(this);
    this.handleValue = this.handleValue.bind(this);
  }

  handleValue(item) {
    console.log("Nouvelle value");
    console.log(item);
    this.setState({ item: item });
  }

  add() {
    if (this.state.item == null) return;
    // var formData = new FormData(); // Currently empty
    // formData.append("idEtablissement", this.state.idEtablissement);
    // formData.append("access", this.state.access);
    // formData.append("idUser", this.state.item.id);

    let self = this;
    this.setState({ inProgress: true });

    var url =
      appProperties.basepathServer +
      "/rest-etablissement/add-access-admin?idEtablissement=" +
      this.state.etablissement.id;
    if (this.state.access != null) url += "&access=" + this.state.access;
    if (this.state.item.value != null)
      url += "&idUser=" + this.state.item.value;

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include"
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          //fireWithObject(document,'new-admin',{value:data.row});
          self.props.onNewAdmin(data.row);
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert(self.props.intl.formatMessage({ id: "AjouterRoleView_Erroradd" }));
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        {this.state.inProgress ? (
          <div className={classes.progress}>
            <LinearProgress variant="query" />
          </div>
        ) : null}
        <div className="layout horizontal center">
          <div className={classes.select}>
            <LdSelectUser
              handleValue={this.handleValue}
              isSearchable
              baseUrl={
                appProperties.basepathServer +
                "/rest-user/search?detail=0&limit=-1&name="
              }
              {...deleteClassInProps(this.props)}
            />
          </div>
          <Button
            variant="contained"
            className={classes.buttonPrincipale}
            onClick={this.add}
          >
            {formatMessage({ id: "AjouterRoleView_add" })}
          </Button>
        </div>
      </main>
    );
  }
}
AjouterRoleView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  access: PropTypes.string.isRequired
};
export default withStyles(styles, { withTheme: true })(
  injectIntl(AjouterRoleView)
);
