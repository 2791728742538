import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from "@material-ui/core/Paper";
import Select from '@material-ui/core/Select';
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import { appProperties, deleteClassInProps, isObjectValueEquals } from '../components/LdUtils';
import TauxPaiement from './TauxPaiement';
import TauxReussite from './TauxReussite';
import TauxIndisciplineJustified from './TauxIndisciplineJustified';
import TauxIndisciplineEleve from './TauxIndisciplineEleve';
import TauxDepense from './TauxDepense';
import TauxStock from './TauxStock';


import HistoPaiementType from './HistoPaiementType';
import HistoIndisciplineType from './HistoIndisciplineType';
import HistoStockType from './HistoStockType';
import HistoDepenseType from './HistoDepenseType';
import HistoEnseignement from './HistoEnseignement';
import HistoRegisteredEleves from './HistoRegisteredEleves';
import { injectIntl } from "react-intl";




const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    width: '100%',
    // padding:'15px',
    // marginTop:'15px',
    // backgroundColor:'white',
    // marginLeft: '10px',
  },
  grid: {
    backgroundColor: 'white',
    padding: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },





  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  formControl1: {
    margin: theme.spacing.unit,
    minWidth: 150,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */
  paperCard: {
    // width:'400px',
    padding: '5px',
    margin: '5px',
  }
});

class DashboardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      classes: [],
      classe: "---",
      enseignant: "---",
      sequences: [],
      sequence: "---",
      trimestres: [],
      trimestre: "---",
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,

      labelWidth: 0,

      categories: [],
      categorie: "---",


    }
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeEnseignant = this.handleChangeEnseignant.bind(this);
    this.handleChangeSequence = this.handleChangeSequence.bind(this);
    this.handleChangeTrimestre = this.handleChangeTrimestre.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);

  }

  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value) }, (state) => {
      self.handleReset();
    });
  };

  handleReset = () => {
    var self = this;
    this.setState({
      classes: [],
      classe: "---",
      enseignant: "---",
      sequences: [],
      sequence: "---",
      trimestres: [],
      trimestre: "---"
    }, () => {
      self.listTrimestres();
      self.listEnseignants();
    });
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();

      });
    }
  }
  handleChangeClass = event => {
    this.setState({ classe: (event.target.value), enseignant: "---" }, (state) => {
    });
  };
  handleChangeEnseignant = event => {
    this.setState({ enseignant: (event.target.value) }, (state) => {

    });
  };
  handleChangeTrimestre = event => {
    let self = this;
    this.setState({ trimestre: (event.target.value) }, (state) => {
      self.setState({ sequence: '---', sequences: (event.target.value && event.target.value != null && event.target.value !== '---') ? event.target.value.sequences : [] })
    });
  };
  handleChangeSequence = event => {
    this.setState({ sequence: (event.target.value) }, (state) => {
    });
  };
  componentDidMount() {
    this.listCategorie();

    //this.listEnseignants();

    //this.listTrimestres();
  }
  componentWillUnmount() {
  }

  listTrimestres() {
    let self = this;
    this.setState({ trimestres: [], progressSequence: 1 });
    var url = appProperties.basepathServer + '/manager/annee-scolaire/trimestres?limit=-1&load_sequences=1';
    if (this.props.annee != null && this.props.annee !== "---") url += ("&id=" + this.props.annee.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ trimestres: [], progressSequence: 0 });
      return;
    }
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          // var sequences = []; 
          // var trimestre = null;
          // (data.datas || []).forEach(e=>{
          //   trimestre = Object.assign({}, e);
          //   delete trimestre.sequences; 
          //   (e.sequences || []).forEach(seq=>{
          //     seq['trimestre'] = trimestre
          //     sequences.push(seq);
          //   });
          // });

          self.setState({ progressSequence: 0, trimestres: data.datas });
        } else {
          self.setState({ trimestres: [], progressSequence: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ sequences: [], progressSequence: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  listEnseignants() {

    let self = this;
    this.setState({ classes: [], progressEnseignant: 1 });
    var url = appProperties.basepathServer + "/rest-enseignant/list-grouped?etablissement_id=" + this.props.etablissement.id + "&limit=-1";
    if (this.props.annee != null) url += ("&annee_id=" + this.props.annee.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [], progressEnseignant: 0 });
      return;
    }
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          var enseignants = [];
          (data.datas || []).forEach(e => {
            enseignants = enseignants.concat(e.enseignants);
          });
          self.setState({ progressEnseignant: 0, classes: self.formatData({ enseignants: enseignants }) });

        } else {
          self.setState({ classes: [], progressEnseignant: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ classes: [], progressEnseignant: 3 });

        console.log('There was an error listed type de frais.');
      });
  }
  formatData(input) {
    if (null == input) return [];
    if (input.enseignants && Array.isArray(input.enseignants) && 0 < input.enseignants.length) {
      var enseignants = input.enseignants;
      var output = Object.assign({}, input);
      delete output.enseignants;
      var exist = false;
      var myReduce = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
        // console.log(accumulator);
        exist = accumulator.reduce((acc, cur, ind, arr) => {
          return acc ? acc : null != cur && null != currentValue.classeEtablissement && cur.id === currentValue.classeEtablissement.id;
        }, false);
        return exist ? accumulator : accumulator.concat(currentValue.classeEtablissement ? currentValue.classeEtablissement : null);
      }, []);
      var contents = [];
      var mat = null;
      console.log("myReduce ", myReduce);
      var myMap = myReduce.map((classe, index, array) => {
        contents = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
          if (null != classe && null != currentValue.classeEtablissement && classe.id === currentValue.classeEtablissement.id) {
            mat = Object.assign({}, currentValue);
            delete mat.classeEtablissement;
            return accumulator.concat(mat);
          } else return accumulator;
        }, []);
        classe["enseignants"] = contents;
        return classe;
      });
      //output["classes"] = myMap;

      return myMap;
    }
    //input["classes"] = [];
    return [];
  }

  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    // const sequences = (this.state.trimeste!=null && this.state.trimestre!=="---")?this.state.trimestre.sequences:[];
    return (
      <div className={classes.main}>
        <Grid container className={classes.grid} justify="space-between" alignItems='center'>
          <div className={classes.titleBlock}>

            {/* TODO: Afficher le dashboard ici.  */}
            <div className={classes.pageTitle} variant="div" component="div"> {formatMessage({ id: "LdHomeMenu_1" })}</div>
            {/* <div className={classes.pageSubtitle} variant="div" component="div">
                Suivez l'évolution de toutes les opérations
              </div> */}
          </div>
          <div>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "ListClasseEtablissementView_4"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.categorie}
                onChange={this.handleChangeCategorie}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListClasseEtablissementView_3" })}
                </MenuItem>
                {this.state.categories.map((row, index) => (
                  <MenuItem title={row.description} key={index} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
              <Select
                title={formatMessage({ id: "AjouterClasseEtablissementView_6" })}
                displayEmpty
                classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                value={this.state.classe}
                onChange={this.handleChangeClass}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name={formatMessage({ id: "AjouterClasseEtablissementView_6" })}
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              > <MenuItem value={'---'}>{formatMessage({ id: "ListEleveView_1" })}</MenuItem>
                {this.state.classes.map((row, index) => (
                  <MenuItem key={index} value={row}>{row.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl1}>
              <Select
                title={formatMessage({ id: "DashboardContainer_1" })}
                displayEmpty
                classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                value={this.state.trimestre}
                onChange={this.handleChangeTrimestre}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name={formatMessage({ id: "DashboardContainer_1" })}
                    id="outlined-trimestre-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              > <MenuItem value={'---'}>{formatMessage({ id: "DashboardContainer_4" })}</MenuItem>
                {this.state.trimestres.map((row, index) => (
                  <MenuItem key={index} value={row}>{row.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl1}>
              <Select
                title={formatMessage({ id: "DashboardContainer_2" })}
                displayEmpty
                classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                value={this.state.sequence}
                onChange={this.handleChangeSequence}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name={formatMessage({ id: "DashboardContainer_2" })}
                    id="outlined-sequence-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              > <MenuItem value={'---'}>{formatMessage({ id: "DashboardContainer_3" })}</MenuItem>
                {this.state.sequences.map((row, index) => (
                  <MenuItem key={index} value={row}>{row.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Grid>
        <br />
        <div>
          <Paper elevation={0} style={{ padding: '0', margin: '5px' }} className="flex">
            <HistoRegisteredEleves dataTestId="3" categorie={this.state.categorie} etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />
          </Paper>
          <div className="layout horizontal">
            <div className="layout vertical flex">
              <Paper elevation={0} style={{ padding: '0', margin: '5px', display: 'table' }} className="flex">
                <TauxPaiement dataTestId="1" categorie={this.state.categorie} trimestre={this.state.trimestre} sequence={this.state.sequence} classe={this.state.classe} etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />
                {this.state.classe != null && this.state.classe !== "---" && <HistoPaiementType dataTestId="1" classe={this.state.classe} etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />}
              </Paper>
              <Paper elevation={0} style={{ padding: '0', margin: '5px', display: 'table' }} className="flex">
                <TauxReussite dataTestId="2" trimestre={this.state.trimestre} sequence={this.state.sequence} classe={this.state.classe} etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />
              </Paper>
              <Paper elevation={0} style={{ padding: '0', margin: '5px', display: 'table' }} className="flex">
                <HistoEnseignement dataTestId="1" trimestre={this.state.trimestre} sequence={this.state.sequence} classe={this.state.classe} etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />
              </Paper>
              <Paper elevation={0} style={{ padding: '0', margin: '5px', display: 'table' }} className="flex">
                <TauxStock etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />
                <HistoStockType dataTestId="1" etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />
              </Paper>

            </div>
            <div className="layout vertical flex">
              <Paper elevation={0} style={{ padding: '0', margin: '5px', display: 'table' }} className="flex">
                <TauxIndisciplineJustified dataTestId="3" categorie={this.state.categorie} trimestre={this.state.trimestre} sequence={this.state.sequence} classe={this.state.classe} etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />
                <TauxIndisciplineEleve dataTestId="4" categorie={this.state.categorie} trimestre={this.state.trimestre} sequence={this.state.sequence} classe={this.state.classe} etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />
                {this.state.classe != null && this.state.classe !== "---" && <HistoIndisciplineType dataTestId="1" classe={this.state.classe} etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />}

              </Paper>
              <Paper elevation={0} style={{ padding: '0', margin: '5px', display: 'table' }} className="flex">
                <TauxDepense etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />
                <HistoDepenseType dataTestId="1" etablissement={this.state.etablissement} annee={this.state.annee} {...deleteClassInProps(this.props)} />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(DashboardContainer));
