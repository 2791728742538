import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SortIcon from '@material-ui/icons/Sort';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from "react";
import { Chart } from 'react-google-charts';
import { FormattedDate, FormattedTime, injectIntl } from "react-intl";
import LdPagination from "../../../components/LdPagination";
import { appProperties, getRound2, isObjectValueEquals, userProfileUrl } from '../../../components/LdUtils';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';


const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3 ,
  },
  main: {
    padding: '15px',
    marginTop: '15px',
    backgroundColor: 'white',
    // marginLeft: '10px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
    // paddingBottom:'15px'
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },

  date: {
    fontSize: "9px",
    fontWeight: "400"
  },



  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */
  primaryAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--app-primary-color)',
  },
  orangeAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: '#ff9800',
  },
  blueAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: 'var(--paper-blue-900)',
  },
  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale: {
    margin: '2px 5px',
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },

  consideration0: {
    fontWeight: "600",
    fontSize: '12px',
    marginLeft: '15px',
    backgroundColor: '#2196f3',
    color: 'white',
    padding: '2px 5px',
    borderRadius: '2px'
  },
  consideration1: {
    fontWeight: "600",
    fontSize: '12px',
    marginLeft: '15px',
    backgroundColor: "red",
    color: 'white',
    padding: '2px 5px',
    borderRadius: '2px',
    textDecoration: "line-through"
  }

});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: '600',
    fontSize: '15px',
    padding: '4px 7px 4px 7px'
  },
  body: {
    fontSize: 14,
    padding: '4px 7px 4px 7px'
  }
}))(TableCell);
const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/>   intl.formatDate(date)*/}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
    &nbsp;{intl.formatMessage({ id: 'code_a' })}  &nbsp;
    <FormattedTime hour="numeric" minute="numeric" value={date} />
  </span>
));
class ListNoteEnseignantView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      classes: [],
      classe: "---", //No change to null. it is used to fix bug in select. 
      enseignant: "---",
      sequences: [],
      sequence: "---",
      trimestres: [],
      trimestre: "---",
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      currentPage: 1,
      progress: 1,

      labelWidth: 0,
      loadChart: false,
      chart: null,


      categories: [],
      categorie: "---",

    }

    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeEnseignant = this.handleChangeEnseignant.bind(this);
    this.handleChangeSequence = this.handleChangeSequence.bind(this);
    this.handleChangeTrimestre = this.handleChangeTrimestre.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.doOrderByName1 = this.doOrderByName1.bind(this);
    this.doOrderByMatricule1 = this.doOrderByMatricule1.bind(this);
    this.doOrderByNote1 = this.doOrderByNote1.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);
    this.handleGenerate = this.handleGenerate.bind(this);

    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);

  }

  handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnackGenerate: false });
  };


  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value) }, (state) => {
      self.handleReset();
      // self.setCurrentPage(1);
    });
  };

  doOrderByName1 = (event) => {
    var array = this.state.datas;
    array.sort((item1, item2) => {
      return (item1.eleve != null && item1.eleve.user != null && (item1.eleve.fullname || item1.eleve.user.name) != null &&
        item2.eleve != null && item2.eleve.user != null && (item2.eleve.fullname || item2.eleve.user.name) != null) ?
        (item1.eleve.fullname || item1.eleve.user.name).localeCompare(item2.eleve.fullname || item2.eleve.user.name) : 0;
    });
    this.setState({ datas: array });

  };

  doOrderByMatricule1 = (event) => {
    var array = this.state.datas;
    array.sort((item1, item2) => {
      return (item1.eleve != null && item1.eleve.matricule != null && item2.eleve != null && item2.eleve.matricule != null) ?
        item1.eleve.matricule.localeCompare(item2.eleve.matricule) : 0;
    });
    this.setState({ datas: array });

  };
  doOrderByNote1 = (event) => {
    var array = this.state.datas;
    array.sort((item1, item2) => {
      return (parseFloat(item1.exactValue) === parseFloat(item2.exactValue)) ? 0 :
        (parseFloat(item1.exactValue) < parseFloat(item2.exactValue) ? 1 : -1);
    });
    this.setState({ datas: array });

  };
  handleReset = () => {
    var self = this;
    this.setState({
      classes: [],
      classe: "---",
      enseignant: "---",
      sequences: [],
      sequence: "---",

      trimestres: [],
      trimestre: "---"
    }, () => {
      self.listEnseignants();
      if (this.props.mode !== "annuelle") {
        if (this.props.mode === "trimestre") this.listSequences(0);
        if (this.props.mode === "sequence") this.listSequences(1);
      }
      self.setCurrentPage(1);
    });
  };

  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.handleReset();

      });
    }
    if (!isObjectValueEquals(this.props.mode, prevProps.mode)) {
      this.setState({ mode: this.props.mode }, () => {
        self.handleReset();

      });
    }
  }
  handleChangeClass = event => {
    let self = this;
    this.setState({ classe: (event.target.value), enseignant: "---" }, (state) => {
      self.setCurrentPage(1);
    });
  };
  handleChangeEnseignant = event => {
    let self = this;
    this.setState({ enseignant: (event.target.value) }, (state) => {
      self.setCurrentPage(1);
    });
  };
  handleChangeSequence = event => {
    let self = this;
    this.setState({ sequence: (event.target.value) }, (state) => {
      self.setCurrentPage(1);
    });
  };
  handleChangeTrimestre = event => {
    let self = this;
    this.setState({ trimestre: (event.target.value) }, (state) => {
      self.setCurrentPage(1);
    });
  };
  // handleChangeClass(item) {
  //   let self = this;
  //   this.setState({ classe: (item?item:null) },(state)=>{
  //     self.setCurrentPage(1);
  //   });

  // };
  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listNote(page);
  };

  componentDidMount() {
    this.listCategorie();
    // this.listEnseignants();
    // if (this.props.mode !== "annuelle") {
    //   if (this.props.mode === "trimestre") this.listSequences(0);
    //   if (this.props.mode === "sequence") this.listSequences(1);
    // }
    // this.listNote(this.state.currentPage);
  }
  componentWillUnmount() {
  }

  listSequences(loadSequence) {
    let self = this;
    this.setState({ sequences: [], progressSequence: 1 });
    var url = appProperties.basepathServer + '/manager/annee-scolaire/trimestres?limit=-1&load_sequences=' + loadSequence;
    if (this.props.annee != null) url += ("&id=" + this.props.annee.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ sequences: [], progressSequence: 0 });
      return;
    }
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          if (loadSequence === 0) {
            self.setState({ progressSequence: 0, trimestres: data.datas });
          } else {
            var sequences = [];
            var trimestre = null;
            (data.datas || []).forEach(e => {
              trimestre = Object.assign({}, e);
              delete trimestre.sequences;
              (e.sequences || []).forEach(seq => {
                seq['trimestre'] = trimestre
                sequences.push(seq);
              });
            });

            self.setState({ progressSequence: 0, sequences: sequences });
          }
        } else {
          self.setState({ sequences: [], progressSequence: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ sequences: [], progressSequence: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  listEnseignants() {

    let self = this;
    this.setState({ classes: [], progressEnseignant: 1 });
    var url = appProperties.basepathServer + "/rest-enseignant/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1";
    if (this.props.annee != null) url += ("&annee_id=" + this.props.annee.id);
    if (this.props.userId != null) url += ("&user_id=" + this.props.userId);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [], progressEnseignant: 0 });
      return;
    }

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          self.setState({ progressEnseignant: 0, classes: self.formatData({ enseignants: data.datas }) });
        } else {
          self.setState({ classes: [], progressEnseignant: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ classes: [], progressEnseignant: 3 });

        console.log('There was an error listed type de frais.');
      });
  }
  formatData(input) {
    if (null == input) return [];
    if (input.enseignants && Array.isArray(input.enseignants) && 0 < input.enseignants.length) {
      var enseignants = input.enseignants;
      var output = Object.assign({}, input);
      delete output.enseignants;
      var exist = false;
      var myReduce = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
        // console.log(accumulator);
        exist = accumulator.reduce((acc, cur, ind, arr) => {
          return acc ? acc : null != cur && null != currentValue.classeEtablissement && cur.id === currentValue.classeEtablissement.id;
        }, false);
        return exist ? accumulator : accumulator.concat(currentValue.classeEtablissement ? currentValue.classeEtablissement : null);
      }, []);
      var contents = [];
      var mat = null;
      console.log("myReduce ", myReduce);
      var myMap = myReduce.map((classe, index, array) => {
        contents = enseignants.reduce((accumulator, currentValue, currentIndex, array) => {
          if (null != classe && null != currentValue.classeEtablissement && classe.id === currentValue.classeEtablissement.id) {
            mat = Object.assign({}, currentValue);
            delete mat.classeEtablissement;
            return accumulator.concat(mat);
          } else return accumulator;
        }, []);
        classe["enseignants"] = contents;
        return classe;
      });
      //output["classes"] = myMap;

      return myMap;
    }
    //input["classes"] = [];
    return [];
  }
  listNote(page) {

    let self = this;

    this.setState({ datas: [], progress: 1, loadChart: false, chart: null });
    var url = appProperties.basepathServer + '/web/note/list?etablissement=' + this.state.etablissement.id + '&limit=-1&page=' + page;
    if (this.state.annee != null) url += ("&annee=" + this.state.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null) url += ("&classe=" + this.state.classe.id);
    else return;
    if (this.state.enseignant && this.state.enseignant !== "---" && this.state.enseignant != null) url += ("&enseignant=" + this.state.enseignant.id);
    else return;
    if (this.props.mode === "sequence") {
      if (this.state.sequence && this.state.sequence !== "---" && this.state.sequence != null) url += ("&sequence=" + this.state.sequence.id);
      else return;
    }
    if (this.props.mode === "trimestre") {
      if (this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) url += ("&trimestre=" + this.state.trimestre.id);
      else return;
    }

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {

          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ progress: 0, datas: data.datas, count: data.count, hasMore: data.hasMore, pages: data.pages });
          if (data.datas.length > 0) self.buildChart(data.datas)
        } else {
          self.setState({ datas: [], progress: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  buildChart(datas) {
    var countSucess = 0;
    var array = datas || [];
    array.sort((item1, item2) => {
      return (parseFloat(item1.exactValue) === parseFloat(item2.exactValue)) ? 0 :
        (parseFloat(item1.exactValue) < parseFloat(item2.exactValue) ? 1 : -1);
    });
    var somme = 0;
    array.forEach(e => {
      somme += parseFloat(e.exactValue);
      if (parseFloat(e.exactValue) >= parseFloat(e.saisieNote.bareme) / 2) countSucess++;
    });
    var countEchec = array.length - countSucess;
    var moyenne = array.length > 0 ? getRound2(somme / array.length) : 0;
    this.setState({ chart: { moyenne: moyenne, sucess: countSucess, echec: countEchec, major: array.length > 0 ? array[0] : null, minor: array.length > 0 ? array[array.length - 1] : null }, loadChart: true })
  }

  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }

  handleGenerate() {

    let self = this;
    this.setState({ progressGenerate: true });
    var url = appProperties.basepathServer + "/manager/note/generate-notes?is_teacher=1&etablissement=" + this.props.etablissement.id;
    if (this.props.annee != null) url += ("&annee=" + this.props.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null) url += ("&classe=" + this.state.classe.id);
    else return;
    if (this.state.enseignant && this.state.enseignant !== "---" && this.state.enseignant != null) url += ("&matiere=" + this.state.enseignant.matiere.id);
    else return;
    if (this.props.mode === "trimestre") {
      if (this.state.trimestre && this.state.trimestre !== "---" && this.state.trimestre != null) url += ("&trimestre=" + this.state.trimestre.id);
      else return;
    }


    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          self.setCurrentPage(1);
        }
        self.setState({ progressGenerate: false, openSnackGenerate: true, resultGenerate: data.result_code });


      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ progressGenerate: false });

        console.log('There was an error listed type de frais.');
      });
  }





  render() {
    const { classes } = this.props;
    const { chart } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>
        {this.state.loadChart && (
          <div className="layout horizontal center center-justified">
            <div>
              <Chart
                width={'450px'}
                height={'300px'}
                chartType="PieChart"
                loader={<div style={{ padding: '15px', fontSize: '12px' }}>{formatMessage({ id: "TauxIndisciplineEleve_3" })}</div>}
                data={[
                  ['Status', 'Ëffectif'],
                  [formatMessage({ id: "ListNoteEnseignantView_2" }), chart.sucess],
                  [formatMessage({ id: "ListNoteEnseignantView_1" }), chart.echec],

                ]}
                options={{
                  title: 'Bilan des notes de ' + (this.state.enseignant != null && this.state.enseignant !== "---" ? (this.state.enseignant.matiereName || '#' + this.state.enseignant.matiere.name) : 'null'),
                  // Just add this option
                  is3D: true,
                  colors: ['#2196f3', '#ff9800'],
                }}
                rootProps={{ 'data-testid': '2' }}
              />
            </div>
            <div style={{ padding: '0 10px' }} className="layout vertical">
              <div className="layout horizontal center">
                <Avatar className={classes.primaryAvatar}>P</Avatar>
                <div style={{ padding: '0 10px' }} className="layout vertical">
                  <div className="layout horizontal center">
                    <span style={{ fontSize: '16px', color: 'var(--app-primary-color)', fontWeight: '500' }} >{formatMessage({ id: "ListNoteEnseignantView_3" })}</span>
                    <span style={{ padding: '0 10px', fontSize: '20px', color: 'var(--app-primary-color)', fontWeight: '600' }} >{chart.major != null ? chart.major.exactValue : "---"}</span>
                  </div>
                  <div className="layout horizontal center">
                    <span style={{ fontSize: '12px', fontWeight: '500' }} >{chart.major != null ? (chart.major.eleve.fullname || '@' + chart.major.eleve.user.name) : "---"}</span>
                    <span style={{ padding: '0 10px', fontSize: '12px', fontWeight: '600' }} >({chart.major != null ? chart.major.eleve.matricule : "---"})</span>
                  </div>
                </div>
              </div>
              <div className="layout horizontal center">
                <Avatar className={classes.orangeAvatar}>D</Avatar>
                <div style={{ padding: '0 10px' }} className="layout vertical">
                  <div className="layout horizontal center">
                    <span style={{ fontSize: '16px', color: '#ff9800', fontWeight: '500' }} >{formatMessage({ id: "ListNoteEnseignantView_4" })}</span>
                    <span style={{ padding: '0 10px', fontSize: '20px', color: '#ff9800', fontWeight: '600' }} >{chart.minor != null ? chart.minor.exactValue : "---"}</span>
                  </div>
                  <div className="layout horizontal center">
                    <span style={{ fontSize: '12px', fontWeight: '500' }} >{chart.minor != null ? (chart.minor.eleve.fullname || '@' + chart.minor.eleve.user.name) : "---"}</span>
                    <span style={{ padding: '0 10px', fontSize: '12px', fontWeight: '600' }} >({chart.minor != null ? chart.minor.eleve.matricule : "---"})</span>
                  </div>
                </div>
              </div>
              <div className="layout horizontal center">
                <Avatar className={classes.blueAvatar}>M</Avatar>
                <div style={{ padding: '0 10px' }} className="layout vertical">
                  <div className="layout horizontal center">
                    <span style={{ fontSize: '16px', color: 'var(--paper-blue-900)', fontWeight: '500' }} >{formatMessage({ id: "ListNoteEnseignantView_5" })}</span>
                    <span style={{ padding: '0 10px', fontSize: '20px', color: 'var(--paper-blue-900)', fontWeight: '600' }} >{chart.moyenne}</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        )}

        <Grid container className={classes.grid} justify="space-between" alignItems='center'>
          <div>
            <div className={classes.pageTitle} variant="div" component="div">
              {this.props.mode === "sequence" && <span>{formatMessage({ id: "ListNoteEnseignantView_6" })}</span>}
              {this.props.mode === "trimestre" && <span>{formatMessage({ id: "ListNoteEnseignantView_7" })}</span>}
              {this.props.mode === "annuelle" && <span>{formatMessage({ id: "ListNoteEnseignantView_8" })}</span>}
            </div>
            <div className={classes.pageSubtitle} variant="div" component="div">
              {formatMessage({ id: "ListNoteEnseignantView_9" })}
            </div>
          </div>
          <div>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "ListClasseEtablissementView_4"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.categorie}
                onChange={this.handleChangeCategorie}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListClasseEtablissementView_3" })}
                </MenuItem>
                {this.state.categories.map((row, index) => (
                  <MenuItem title={row.description} key={index} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
              <Select
                title={formatMessage({ id: "AjouterClasseEtablissementView_6" })}
                displayEmpty
                classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                value={this.state.classe}
                onChange={this.handleChangeClass}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              > <MenuItem value={'---'}>{formatMessage({ id: "ListEleveView_1" })}</MenuItem>
                {this.state.classes.map((row, index) => (
                  <MenuItem key={index} value={row}>{row.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
              <Select
                title={formatMessage({ id: "ListEnseignantView_1" })}
                displayEmpty
                classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                value={this.state.enseignant}
                onChange={this.handleChangeEnseignant}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une matière"
                    id="outlined-matiere-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              > <MenuItem value={'---'}>{formatMessage({ id: "ListEnseignantView_2" })}</MenuItem>
                {(this.state.classe != null && this.state.classe !== "---" && this.state.classe.enseignants) && this.state.classe.enseignants.map((row, index) => (
                  <MenuItem key={index} value={row}>{row.matiereName || '#' + row.matiere.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

            {this.props.mode === "sequence" &&
              <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
                <Select
                  title={formatMessage({ id: "DashboardContainer_2" })}
                  displayEmpty
                  classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                  value={this.state.sequence}
                  onChange={this.handleChangeSequence}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="Choisir une séquence"
                      id="outlined-classe-simple"
                      classes={{ input: classes.outlinedInput }}
                    />
                  }
                > <MenuItem value={'---'}>{formatMessage({ id: "DashboardContainer_3" })}</MenuItem>
                  {this.state.sequences.map((row, index) => (
                    <MenuItem key={index} value={row}>{row.trimestre.name + " " + row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>}

            {this.props.mode === "trimestre" &&
              <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>
                <Select
                  title={formatMessage({ id: "DashboardContainer_1" })}
                  displayEmpty
                  classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                  value={this.state.trimestre}
                  onChange={this.handleChangeTrimestre}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="Choisir un trimestre"
                      id="outlined-classe-simple"
                      classes={{ input: classes.outlinedInput }}
                    />
                  }
                > <MenuItem value={'---'}>{formatMessage({ id: "DashboardContainer_4" })}</MenuItem>
                  {this.state.trimestres.map((row, index) => (
                    <MenuItem key={index} value={row}>{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>}




          </div>
        </Grid>


        {(this.state.classe !== "---" && this.state.enseignant !== "---" && ((this.props.mode === "annuelle") || (this.state.trimestre !== "---" && this.props.mode === "trimestre"))) &&
          (<div style={{ padding: '5px 5px 5px 5px', border: '1px solid #EAEAEA', margin: '15px 0' }} className="layout horizontal center justified">
            {this.props.mode === "trimestre" && (<div style={{ fontSize: '14px' }} className="flex">{formatMessage({ id: "ListNoteView_9" })} </div>)}
            {this.props.mode === "annuelle" && (<div style={{ fontSize: '14px' }} className="flex">{formatMessage({ id: "ListNoteView_10" })}</div>)}
            <div className="layout horizontal center">
              {this.state.progressGenerate && <span style={{ padding: ' 0 10px' }} > <CircularProgress thickness={1} size={32} className={classes.fabProgress} /></span>}
              <div>
                <Button
                  variant="contained"
                  title={formatMessage({ id: "ListNoteView_8" })}
                  onClick={this.handleGenerate}
                  className={classes.buttonPrincipale}>
                  {formatMessage({ id: "ListNoteView_7" })}
                </Button>
              </div>
            </div>
          </div>)}
        {this.state.progress === 0 && (
          <React.Fragment>
            {/* <Paper elevation={0}  className={classes.root}>
                <div className="layout horizontal  wrap" style={{marginTop:'15px'}}>
                    {this.state.datas.map((row,index) => (
                      <ItemNote  key={index} onUpdateItem = {this.onUpdateItem}  etablissement={this.state.etablissement} position = {index} row = {row}/>
                    ))}
                </div>
              </Paper> */}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell onClick={this.doOrderByName1} title={formatMessage({ id: "ListNoteEnseignantView_10" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "RegisterStudent_10" })}</span></div></CustomTableCell>
                  <CustomTableCell onClick={this.doOrderByMatricule1} title={formatMessage({ id: "ListNoteEnseignantView_11" })} style={{ cursor: 'pointer' }}><div className="layout horizontal center"><SortIcon /><span>{formatMessage({ id: "RapportIndisciplineEleve_3" })}</span></div></CustomTableCell>
                  <CustomTableCell title="Catégorie" style={{ cursor: 'pointer' }}><div className="layout horizontal center"><span>{formatMessage({ id: "ListNoteEnseignantView_14" })}</span></div></CustomTableCell>
                  <CustomTableCell title="Periode" style={{ cursor: 'pointer' }}><div className="layout horizontal center"><span>{formatMessage({ id: "ListNoteEnseignantView_13" })}</span></div></CustomTableCell>
                  <CustomTableCell onClick={this.doOrderByNote1} title={formatMessage({ id: "ListNoteEnseignantView_12" })} style={{ cursor: 'pointer' }} align="left"><div className="layout horizontal center" ><SortIcon /><span>{formatMessage({ id: "ListNoteEnseignantView_15" })}</span></div></CustomTableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.datas.map((row, index) => (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row">
                      <div>
                        <div>

                          <a style={{ fontWeight: "600", fontSize: '11px' }} className="no-underline capitalize" title={row.eleve.fullname || '@' + row.eleve.user.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.eleve.user)}>
                            {row.eleve.fullname || '@' + row.eleve.user.name}
                          </a>
                        </div>
                        <div style={{ fontSize: '11px' }}>{row.eleve.user.email}</div>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      <span style={{ fontSize: '11px' }}>{row.eleve.matricule}</span>
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      <div className="layout vertical">
                        <span style={{ fontWeight: "600", fontSize: '11px' }}>{row.enseignant.classeEtablissement.name}</span>
                        <div>
                          <span style={{ padding: '2px 3px', fontWeight: "600", fontSize: '11px', color: 'white', backgroundColor: 'var(--app-primary-color)' }}>{row.enseignant.matiereName || '#' + row.enseignant.matiere.name}</span>
                        </div>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      <span style={{ fontWeight: "600", fontSize: '11px' }}>
                        {this.props.mode === "sequence" && row.sequence.name}
                        {this.props.mode === "trimestre" && row.trimestre.name}
                        {this.props.mode === "annuelle" && this.props.annee.year}
                      </span>
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      <div className="layout vertical flex">
                        <div style={{ fontSize: '15px', fontWeight: '600', color: ((chart != null && parseFloat(chart.moyenne) > parseFloat(row.exactValue)) ? '#ff9800' : 'none') }}>{getRound2(row.exactValue)}
                          {row.saisieNote != null && (<span style={{ fontWeight: "600", fontSize: '9px' }}>/{row.saisieNote.bareme}</span>)}
                          {row.consideration === 0 && (<span title={formatMessage({ id: "ListNoteView_12" })} className={classes.consideration0}>BU</span>)}
                          {row.consideration === 1 && (<span title={formatMessage({ id: "ListNoteView_13" })} className={classes.consideration1}>BU</span>)}

                        </div>

                        <div style={{ fontWeight: "400" }} className={classes.date}>
                          {formatMessage({ id: "ItemAlert_msg13" })}  <PostDate date={row.created} />
                          {row.creator != null && (<span>
                            &nbsp;{formatMessage({ id: "ItemAlert_msg14" })}
                            <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '9px' }} className="no-underline capitalize" title={row.creator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.creator)}>
                              {row.creator.name}

                            </a>
                          </span>)}
                        </div>
                        {row.modified != null && (
                          <div style={{ fontWeight: "400" }} className={classes.date}>{formatMessage({ id: "ListCahierMatiere_22" })}  <PostDate date={row.modified} />
                            {row.modificator != null && (<span>
                              &nbsp;{formatMessage({ id: "ItemAlert_msg14" })}
                              <a style={{ paddingLeft: '5px', fontWeight: "500", fontSize: '9px' }} className="no-underline capitalize" title={row.modificator.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.modificator)}>
                                {row.modificator.name}

                              </a>
                            </span>)}
                          </div>)}
                      </div>
                    </CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <LdPagination onShowPage={this.setCurrentPage} pages={this.state.pages} />
          </React.Fragment>)}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnackGenerate}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">
            {this.state.resultGenerate === 1 ? formatMessage({ id: "ListNoteView_6" }) : null}
            {this.state.resultGenerate === -1 ? formatMessage({ id: "ListNoteView_5" }) : null}
            {this.state.resultGenerate === -2 ? formatMessage({ id: "ListNoteView_4" }) : null}
            {this.state.resultGenerate === -3 ? formatMessage({ id: "ListNoteView_3" }) : null}
            {this.state.resultGenerate === -4 ? formatMessage({ id: "ListNoteView_2" }) : null}
            {this.state.resultGenerate === -100 ? formatMessage({ id: "ListNoteView_1" }) : null}
          </span>}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarClose}
            >
              OK!
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

        {(this.state.progress === 1) && (this.state.classe === "---" || this.state.enseignant === "---" || (this.state.sequence === "---" && this.props.mode === "sequence") || (this.state.trimestre === "---" && this.props.mode === "trimestre")) && (
          <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
            {/* <div style={{padding:'15px'}}>
                  <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                </div> */}
            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)', textAlign: 'center' }} className="layout horizontal center-center">

              {this.props.mode === "sequence" && <span> {formatMessage({ id: "ListNoteEnseignantView_16" })}</span>}
              {this.props.mode === "trimestre" && <span>{formatMessage({ id: "ListNoteEnseignantView_17" })}</span>}
              {this.props.mode === "annuelle" && <span> {formatMessage({ id: "ListNoteEnseignantView_18" })}</span>}
            </div>
          </div>
        )}
        {(this.state.progress === 1) &&
          ((this.state.classe !== "---" && this.state.enseignant !== "---" && (this.props.mode === "sequence" && this.state.sequence !== "---")) ||
            (this.state.classe !== "---" && this.state.enseignant !== "---" && (this.props.mode === "trimestre" && this.state.trimestre !== "---")) ||
            (this.state.classe !== "---" && this.state.enseignant !== "---" && (this.props.mode === "annuelle"))) && (
            <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
              <div style={{ padding: '15px' }}>
                <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
              </div>
              <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                {formatMessage({ id: "ListRoleView_Load" })}
              </div>
            </div>
          )}
        {(this.state.progress === 2 || (this.state.progress === 0 && this.state.datas.length === 0)) && (
          <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
              {formatMessage({ id: "ListNoteEnseignantView_19" })}
            </div>
          </div>
        )}
        {this.state.progress === 3 && (
          <div style={{ backgroundColor: 'white', margin: '50px 50px 0 50px', padding: '50px' }} className="layout vertical center-center">
            <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
            <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}

      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(ListNoteEnseignantView));
