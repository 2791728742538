import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import  {appProperties} from '../../../components/LdUtils';
import ItemEtablissement from './ItemEtablissement';
import LdPagination from "../../../components/LdPagination";
import CircularProgress from '@material-ui/core/CircularProgress';
import { deleteClassInProps} from '../../../components/LdUtils';


const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight:'600',
    fontSize:'15px',
    padding:'4px 0 4px 15px;'
  },
  body: {
    fontSize: 14,
    padding:'4px 0 4px 15px;'
  }
}))(TableCell);



class ListEtablissementView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas :[],
      count:0,
      hasMore:false,
      pages:[],
      currentPage:1,
      progress: 1

    }
    this._handleNewReseau = this._handleNewReseau.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);

  }
  setCurrentPage = page=>{
    this.setState({currentPage:page});
    this.listDatas(page);
  };
  _handleNewReseau = event =>{
       this.setState(state => {
             state.datas =  [event.detail.value, ...state.datas] ;
             return state;
        });
  };
  onUpdateItem = (mode,item) =>{
      var array = [...this.state.datas];
      if(mode === 'update'){
          array.splice(item.position,1,item.row);
      }else if (mode === 'delete'){
        array.splice(item.position,1);

      }else if (mode === 'enabled'){
        item.row.status = true;
        console.log('enabled', item.row.status);
        array.splice(item.position,1,item.row);

      }else if (mode === 'disabled'){
        item.row.status = false;
        array.splice(item.position,1,item.row);

      }
      
       this.setState(state => {
             state.datas =  array ;
             return state;
      });
  };
  componentDidMount() {  
    this.listDatas(this.state.currentPage);
    // document.addEventListener('new-reseau',this._handleNewReseau.bind(this));
  }
  componentWillUnmount() {
    // document.removeEventListener('new-reseau',this._handleNewReseau.bind(this));
  }
  listDatas(page){
    let self = this;
    this.setState({datas: [],progress:1});
    fetch(appProperties.basepathServer+ '/rest-etablissement/list?limit=10&page='+page, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    
    })
    .then((response) => response.json())
    .then((data) => { 
        //var array = [];
        if(data.result_code === 1){
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({progress:0,datas:data.datas,count:data.count,hasMore:data.hasMore,pages:data.pages});  
          
        } else{
          self.setState({datas:[],progress:2});   
        }
        
    })
    .catch((err) => {
      console.log(err);
      //self.setState({datas: []});
      self.setState({datas:[],progress:3}); 
      console.log('There was an error listed etablissement.');
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Paper elevation={0} className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Code</CustomTableCell>
                <CustomTableCell align="left">Name</CustomTableCell>
                <CustomTableCell align="left">Solde SMS</CustomTableCell> 
                <CustomTableCell align="left">Nbre de rappel</CustomTableCell>
                <CustomTableCell align="left">Created</CustomTableCell>
                <CustomTableCell align="left">Actions</CustomTableCell>
              </TableRow>
            </TableHead>
            { this.state.progress === 0 && (
              <TableBody>
                {this.state.datas.map((row,index) => (
                  <ItemEtablissement key={index} onUpdateItem = {this.onUpdateItem}  position = {index} row = {row}  {...deleteClassInProps(this.props)} />
                ))}
              </TableBody>
            )}
          </Table>
          { this.state.progress === 1 && (
              <div style={{backgroundColor: 'white',margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                <div style={{padding:'15px'}}>
                  <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                </div>
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                  Chargement en cours...
                </div>
              </div>
            )}
            {  (this.state.progress === 2 || (this.state.progress === 0 && this.state.datas.length === 0))&& (
              <div style={{backgroundColor: 'white',margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                  Aucun établissement trouvé
                </div>
              </div>
            )}
            { this.state.progress === 3 && (
              <div style={{backgroundColor: 'white',margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                <div style={{padding:'15px'}}>Erreur!!</div>
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                  Erreur de chargement. 
                </div>
              </div>
            )}
        </Paper>
        { this.state.progress === 0 && (
          <LdPagination onShowPage={this.setCurrentPage} pages={this.state.pages} />
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ListEtablissementView);
