import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import { appProperties } from "../../../components/LdUtils";
import ItemPersonnel from "./ItemPersonnel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";
import printJS from 'print-js'
import { withSnackbar } from "notistack";

import SaveIcon from "@material-ui/icons/Save";
import LocalPrintshopIcon from "@material-ui/icons/LocalPrintshop";
const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  }
});

class ListPersonnelView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      currentPage: 1,
      progress: 1
    };
    this._handleNewPesonnel = this._handleNewPesonnel.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.handlePrintCard = this.handlePrintCard.bind(this);
  }
  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listPesonnel(page);
  };
  _handleNewPesonnel = event => {
    this.setState(state => {
      state.datas = [event.detail.value, ...state.datas];
      return state;
    });
  };
  onUpdateItem = (mode, item) => {
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  componentDidMount() {
    this.listPesonnel(this.state.currentPage);
    document.addEventListener(
      "new-personnel",
      this._handleNewPesonnel.bind(this)
    );
  }
  componentWillUnmount() {
    document.removeEventListener(
      "new-personnel",
      this._handleNewPesonnel.bind(this)
    );
  }
  listPesonnel(page) {
    let self = this;

    this.setState({ datas: [], progress: 1 });

    // fetch(appProperties.basepathServer+ '/manager/modele-bulletin/list?limit=5&page='+page, {
    fetch(
      appProperties.basepathServer +
      "/manager/etablissement/enseignants?id=" +
      this.state.etablissement.id +
      "&limit=10&page=" +
      page,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log("There was an error listed enseignant.");
      });
  }


  handlePrintCard(event) {
    let self = this;
    const key = this.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_3" }), { variant: "info", persist: true, anchorOrigin: { vertical: "bottom", horizontal: "right" } });
    var url = appProperties.basepathServer + "/manager/etablissement/print-cards-enseignant?etablissement=" + this.state.etablissement.id + "&limit=-1";

    fetch(url, {
      mode: "cors",
      method: "GET",
      headers: {
        'Content-Type': 'application/pdf',
        //Accept: "application/json"
      },
      credentials: "include"
    })
      .then(
        function (response) {
          var contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then(function (data) {
              // traitement du JSON
              if (data.result_code === -100) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "AlertSolde_2" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1) {
              } else if (data.result_code === -10) {
                self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ListEleveView_15" }), { variant: "error", anchorOrigin: { vertical: "bottom", horizontal: "right" } });
              } else if (data.result_code === -1000) {
              } else {
              }
            });
          } else if (contentType && (contentType.indexOf("application/pdf") !== -1 || contentType.indexOf("application/octet-stream") !== -1)) {
            //var disposition = response.headers/*.get("Content-Disposition")*/;
            //console.log(disposition);
            return response.blob();
          } else {
            console.log("Oops, nous n'avons pas du JSON!");
          }
        }
      )
      .then(blob => {
        try {
          var pdfUrl = URL.createObjectURL(blob);
          self.printFile(pdfUrl);
        } catch (er) {

        } finally {
          self.props.closeSnackbar(key);
        }

      })
      .catch(err => {
        console.log(err);
        self.props.closeSnackbar(key);
        self.props.enqueueSnackbar(
          self.props.intl.formatMessage({ id: "ListEleveView_4" }),
          {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "right" }
          }
        );
      });
  }

  printFile(url) {
    var self = this;
    const action = (key) => (
      <React.Fragment>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_9" })} onClick={() => this.printer(key, url)}>
          <LocalPrintshopIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_8" })} */}
        </Button>
        <Button title={self.props.intl.formatMessage({ id: "ListEleveView_11" })} onClick={() => this.download(key, url)}>
          <SaveIcon />{/* {self.props.intl.formatMessage({ id: "ListEleveView_10" })} */}
        </Button>
      </React.Fragment>
    )
    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "ListEleveView_5" }), {
      variant: "success",
      persist: true,
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action
    });
  }
  printer(key, url) {
    this.props.closeSnackbar(key);
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage: this.props.intl.formatMessage({ id: "ListEleveView_6" }),
      onError: function () {
        alert(this.props.intl.formatMessage({ id: "ListEleveView_7" }));
      },
      onLoadingEnd: function () {
        //alert("Load end");
      }
    });
  }
  download(key, url) {
    this.props.closeSnackbar(key);
    const a = document.createElement("a");
    a.href = url;
    var now = new Date();
    a.download = 'Impression_' + now.getTime() + '_pdf.pdf';
    a.click();
  }


  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div>

        <div style={{ padding: '0 15px 15px' }} className="layout horizontal center end-justified">
          <Button
            onClick={this.handlePrintCard}
            title={formatMessage({ id: "ListPersonnelView_3" })}
            style={{ textTransform: 'none', borderRadius: '0 !important', marginLeft: '10px', color: 'var(--app-primary-color)' }}
            variant="outlined" size="small" className={classes.margin}

          >{formatMessage({ id: "ListPersonnelView_2" })}
          </Button>
        </div>
        {this.state.progress === 0 && (
          <React.Fragment>
            <Paper elevation={0} className={classes.root}>
              <div>
                {this.state.datas.map((row, index) => (
                  <ItemPersonnel
                    key={index}
                    onUpdateItem={this.onUpdateItem}
                    position={index}
                    row={row}
                    {...deleteClassInProps(this.props)}
                  />
                ))}
              </div>
            </Paper>
            <LdPagination
              onShowPage={this.setCurrentPage}
              pages={this.state.pages}
            />
          </React.Fragment>
        )}
        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 ||
          (this.state.progress === 0 && this.state.datas.length === 0)) && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "ListPersonnelView_1" })}
              </div>
            </div>
          )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              {formatMessage({ id: "ListClasseAnneeView_4" })}
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withSnackbar(withStyles(styles)(injectIntl(ListPersonnelView)));
