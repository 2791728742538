import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import { appProperties, randomColor } from '../../../components/LdUtils';
import AjouterTrimestreView from "./AjouterTrimestreView";
import ItemTrimestre from "./ItemTrimestre";
import { deleteClassInProps } from '../../../components/LdUtils';
import { injectIntl } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";


const styles = theme => ({
  root: {
    width: "100%",
    background: 'none',
    // marginTop: theme.spacing.unit * 3,
  },
  main: {
    padding: '0 15px',
    // marginTop:'15px',
    backgroundColor: '#FAFAFA',
    marginLeft: '45px',
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  pageTitle: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 500,
    marginTop: '15px',
    marginBottom: '15px'
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
});

class ListTrimestreView extends Component {
  constructor(props) {
    super(props);


    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      anneeScolaire: this.props.anneeScolaire,
      currentPage: 1,
      progress: 1,
      categorieEtablissement: null,

    }
    this._handleNewTrimestre = this._handleNewTrimestre.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);

  }

  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listTrimestre(page);
  };
  _handleNewTrimestre = value => {
    if (value != null && value.categorieEtablissement != null && this.props.categorieEtablissement != null && value.categorieEtablissement.id === this.props.categorieEtablissement.id) {
      this.setState(state => {
        state.datas = [value, ...state.datas];
        return state;
      });
    }
  };
  onUpdateItem = (mode, item) => {
    console.log("interceptttt", mode);
    console.log(item);

    var array = [...this.state.datas];
    if (mode === 'update') {
      if (item.row != null && item.row.categorieEtablissement != null && this.state.categorieEtablissement != null && item.row.categorieEtablissement.id === this.state.categorieEtablissement.id) {
        array.splice(item.position, 1, item.row);
      } else {
        array.splice(item.position, 1);
      }
    } else if (mode === 'delete') {
      array.splice(item.position, 1);

    } else if (mode === 'enabled') {
      item.row.status = true;
      console.log('enabled', item.row.status);
      array.splice(item.position, 1, item.row);

    } else if (mode === 'disabled') {
      item.row.status = false;
      array.splice(item.position, 1, item.row);

    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
    var self = this;
    setTimeout(() => {
      console.log("New Trimestre value");
      console.log(self.state.datas);
    }, 2000);

  };
  componentDidMount() {
    this.listTrimestre(this.state.currentPage);
  }

  listTrimestre(page) {

    let self = this;

    this.setState({ datas: [], progress: 1 });

    var url = appProperties.basepathServer + '/manager/annee-scolaire/trimestres?id=' + this.state.anneeScolaire.id + '&limit=-1&page=' + page;
    if (this.props.categorieEtablissement && this.props.categorieEtablissement != null) {
      url += ("&categorie_etablissement_id=" + this.props.categorieEtablissement.id);
    }
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        var array = [];
        if (data.result_code === 1) {
          if (data.datas && data.datas.length > 0) {

            data.datas.forEach(e => {
              if (self.props.categorieEtablissement != null) {
                if (e.categorieEtablissement != null && e.categorieEtablissement.id === self.props.categorieEtablissement.id) array.push(e);
              } else {
                if (e.categorieEtablissement == null) array.push(e);
              }
            });
          }
          self.setState({ progress: 0, datas: array, count: data.count, hasMore: data.hasMore, pages: data.pages });

        }

      })
      .catch((err) => {
        console.log(err);
        self.setState({ datas: [], progress: 2 });
        console.log('There was an error listed enseignant.');
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div className={classes.main}>

        {
          this.props.categorieEtablissement != null && <div>
            <AjouterTrimestreView categorieEtablissement={this.props.categorieEtablissement} addNewTrimestre={this._handleNewTrimestre} anneeId={this.state.anneeScolaire.id}  {...deleteClassInProps(this.props)} />
          </div>
        }

        <Paper elevation={0} className={classes.root}>
          <div className={classes.pageTitle} variant="div" component="div">
            {formatMessage({ id: "ListTrimestreView_1" }, { categorie: this.props.categorieEtablissement != null ? this.props.categorieEtablissement.name : "" })}
          </div>
          {this.state.progress === 0 && (
            <div>
              {this.state.datas.map((row, index) => (
                <ItemTrimestre categorieEtablissements={this.props.categorieEtablissements} color={randomColor()} title={row.name} key={index} onUpdateItemTrimestre={this.onUpdateItem}    {...deleteClassInProps(this.props)} position={index} row={row} />
              ))}
            </div>
          )}
          {this.state.progress === 1 && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              <div style={{ padding: "15px" }}>
                <CircularProgress
                  thickness={1}
                  size={75}
                  className={classes.fabProgress}
                />
              </div>
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "ListRoleView_Load" })}
              </div>
            </div>
          )}
          {(this.state.progress === 2 ||
            (this.state.progress === 0 && this.state.datas.length === 0)) && (
              <div
                style={{
                  backgroundColor: "white",
                  margin: "50px 50px 0 50px",
                  padding: "50px"
                }}
                className="layout vertical center-center"
              >
                {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                <div
                  style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    width: "75%",
                    color: "var(--paper-grey-900)"
                  }}
                  className="layout horizontal center-center"
                >
                  {formatMessage({ id: "ListTrimestreView_2" }, { categorie: this.props.categorieEtablissement != null ? this.props.categorieEtablissement.name : "" })}
                </div>
              </div>
            )}
          {this.state.progress === 3 && (
            <div
              style={{
                backgroundColor: "white",
                margin: "50px 50px 0 50px",
                padding: "50px"
              }}
              className="layout vertical center-center"
            >
              <div style={{ padding: "15px" }}>
                {formatMessage({ id: "ListClasseAnneeView_4" })}
              </div>
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                  width: "75%",
                  color: "var(--paper-grey-900)"
                }}
                className="layout horizontal center-center"
              >
                {formatMessage({ id: "ListRoleView_LoadError" })}
              </div>
            </div>
          )}
        </Paper>
        <LdPagination onShowPage={this.setCurrentPage} pages={this.state.pages} />
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(ListTrimestreView));
