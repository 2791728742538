import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ProgressiveImage from 'react-progressive-image';
import LdFileUploader from "../../../components/LdFileUploader";
import LdImageUploader from "../../../components/LdImageUploader";
import { appProperties, deleteClassInProps } from "../../../components/LdUtils";

const styles = theme => ({
  // main: {
  //   width: "400px",
  //   padding: "15px",
  //   display: "block", // Fix IE 11 issue.
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
  //     width: 450,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   }
  // },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },

  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paperMenu: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "135px",
    padding: "0px !important",
  },
  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  },
  contentImage:{
    width:"40px",
  },
  bigAvatar: {
    borderRadius:'0 !important',
    width: "40px !important",
    height:"40px !important",
    border:"2px solid #EAEAEA"
  },
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding:'4px 0 4px 15px;'
  },
  body: {
    fontSize: 14,
    padding:'4px 0 4px 15px;'
  }
}))(TableCell);




class ItemBulletin extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      code: this.props.row.code || '',
      name: this.props.row.name || '',
      description: this.props.row.description || '',
      image: this.props.row.image || "",
      simpleFile: this.props.row.simpleFile || "",
      template: this.props.row.template || "",
      // etablissements: null,
      options: [],
      selectedOption: null,
      result: null,
      redirect: false,
      // ids: "",
      datas: [],
      dialogOpened: false,
      inProgress: false,
      position: this.props.position
    };
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.newImageUploaded = this.newImageUploaded.bind(this);
    this.newFileUploaded = this.newFileUploaded.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.handleClose = this.handleClose.bind(this);
  
  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
    // this.listEtablissement(1);
  };
  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
        "/manager/modele-bulletin/delete?id=" +
        this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
        "/manager/modele-bulletin/enable?id=" +
        this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", { position: self.state.position, row: this.props.row});
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
        "/manager/modele-bulletin/disable?id=" +
        this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", { position: self.state.position,  row: this.props.row});
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };
  componentDidMount() {
    //this.handleChange([]);
    var data =  this.props.row.etablissements
    ? this.props.row.etablissements.map(option => {
        return { label: option.name, value: option.id };
      })
    : [];
    this.handleChange(data);
  }
  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };

  handleChange(selectedOptions) {
    var ids = selectedOptions.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + "-" + e.value : e.value;
    }, "");
    this.setState({ ids: ids });
  }
  newImageUploaded(name, path) {
    // console.log(" new image uplaoded in Ajouter");
    // console.log(name);
    // console.log(path);
    this.setState({ image: name });
  }
  newFileUploaded(file) {
    // console.log(" new image uplaoded in Ajouter");
    // console.log(name);
    // console.log(path);
    console.log(file);
    this.setState({simpleFile: file });
  }
  edit() {
    var formData = new FormData(); // Currently empty
    formData.append("id", this.props.row.id);
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    // formData.append("ids", this.state.ids);
    formData.append("image", this.state.image);
    formData.append("simpleFile", this.state.simpleFile);
    formData.append("template", this.state.template);
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/modele-bulletin/edit", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("update", {
            position: self.state.position,
            row: data.row
          });
          self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error edited.");
      });
  }
  
  render() {
    const { classes, row } = this.props;

   

    return (
      <React.Fragment>
        <TableRow className={classes.row} key={row.id}>
          <CustomTableCell  component="th" scope="row">
            <div className={classes.contentImage}>   
                <ProgressiveImage src={row.image_path} placeholder={''}>
                  {(src, loading) => (<Avatar alt={row.name} src={src} className={classes.bigAvatar} />)}
                </ProgressiveImage>
            </div>
          </CustomTableCell> 
          <CustomTableCell align="left">
            {row.code}
          </CustomTableCell>
          <CustomTableCell align="left">{row.name}</CustomTableCell>
          <CustomTableCell align="left">{row.description}</CustomTableCell>
          <CustomTableCell align="left">{row.created}</CustomTableCell>
          <CustomTableCell align="left">{row.template}</CustomTableCell>
          {/* <CustomTableCell align="left">
            {row.etablissements ? row.etablissements.length : "0"}Etabs
          </CustomTableCell> */}
          <CustomTableCell align="left">
            {/* <Button
              variant="outlined"
              color="primary"
              onClick={this.handleClickOpen}
            >
              Modifier
            </Button> */}
            {/* <Button
              variant="outlined"
              color="secondary"
              onClick={this.handleDelete}
            >
              Supprimer
            </Button> */}
            <div className={classes.actions}>
              <IconButton
                title="Editer"
                color="primary"
                aria-label="Edit"
                onClick={this.handleClickOpen}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                title="Delete"
                aria-label="Delete"
                onClick={this.handleDelete}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>

              {this.props.row.status ?
                  <IconButton
                  title="desactiver"
                  classes={{root: classes.disable}}
                  aria-label="Edit"
                  onClick={this.handleDisabled}
                >
                 <CheckIcon fontSize="small" />
                </IconButton> :
                 <IconButton
                title="activer"
                classes={{root: classes.enable}}

                aria-label="Delete"
                onClick={this.handleEnabled}
              >
                 <ClearIcon fontSize="small" />
              </IconButton>}
            
            </div>
          </CustomTableCell>
        </TableRow>

        {this.state.dialogOpened && (
          <Dialog
            open={this.state.dialogOpened}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle  className={classes.pageTitle}  id="form-dialog-title">
              Edit le bulletin # {row.code}
            </DialogTitle>
            <DialogContent>
              {this.state.inProgress ? (
                <div className={classes.progress}>
                  <LinearProgress variant="query" />
                </div>
              ) : null}
              <div  className={classes.pageSubtitle} >Renseignez toutes les informations sur le modele de bulletin </div>

              <CssBaseline />
              <Paper square elevation={0} className={classes.paper}>
                {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}

                {this.state.result != null ? (
                  <div className="Error-message">
                    {this.state.result.message}
                  </div>
                ) : null}

                <div className={classes.root}>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="code">Code :</InputLabel>
                    <Input
                      id="code"
                      required
                      name="code"
                      autoComplete="code"
                      onChange={this.handleChangeInput("code")}
                      defaultValue={row.code}
                      placeholde="entrez votre code"
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="name">name :</InputLabel>
                    <Input
                      name="name"
                      type="name"
                      id="name"
                      required
                      defaultValue={row.name}
                      onChange={this.handleChangeInput("name")}
                      autoComplete="current-name"
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="description">Description :</InputLabel>
                    <Input
                      id="description"
                      required
                      defaultValue={row.description}
                      name="description"
                      autoComplete="description"
                      onChange={this.handleChangeInput("description")}
                      multiline
                      rows="4"
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="template">Template :</InputLabel>
                    <Input
                      name="template"
                      type="template"
                      id="template"
                      required
                      defaultValue={row.template}
                      onChange={this.handleChangeInput("template")}
                      autoComplete="current-template"
                    />
                    </FormControl>

                 
                  <div>
                    <LdImageUploader
                      maxSize={500000}
                      hasParent
                      name={row.image}
                      path={row.path_photo}
                      onImageUploaded={this.newImageUploaded}
                      {...deleteClassInProps(this.props)} 
                    />
                  </div>
                  <div>
                    <LdFileUploader
                      hasParent
                      name={row.simpleFile}
                      path={row.path_file}
                      onFileUploaded={this.newFileUploaded}
                      {...deleteClassInProps(this.props)} 
                    />
                  </div>
                </div>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} 
              variant="contained"
              size="medium"
              className = {classes.buttonGreyWhite}>
                Cancel
              </Button>
              <Button onClick={this.edit} 
                variant="contained"
                size="medium"
                className = {classes.buttonPrincipale}>
                Enregistrer
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}
ItemBulletin.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(ItemBulletin);
