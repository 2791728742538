import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import  {appProperties} from '../../../components/LdUtils';
import ItemRecharge from './ItemRecharge';
import LdPagination from "../../../components/LdPagination";
import CircularProgress from '@material-ui/core/CircularProgress';
import { deleteClassInProps} from '../../../components/LdUtils';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  fabProgress: {
    color: 'var(--app-primary-color)',
    margin: 'auto'
  },
  
  datePicker:{
    height:'35px',
    '& .react-datetime-picker__wrapper':{
      border:'1px dashed #AAAAAA',
      borderRadius:'5px'
    }
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    maxWidth: 300,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  },  /** Filter end */
    pageTitle:{
      fontSize: '21px',
      lineHeight: '22px',
      fontWeight: 400,
    },
    pageSubtitle:{
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 400,
      color:theme.color.primaryColor
    }
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight:'600',
    fontSize:'15px',
    padding:'4px 0 4px 15px;'
  },
  body: {
    fontSize: 14,
    padding:'4px 0 4px 15px;'
  }
}))(TableCell);



class ListRechargeView extends Component {
  constructor(props) {
    super(props);
    const minDate = null;
    const maxDate = null;
    this.state = {
      etablissements :[],
      datas :[],
      count:0,
      hasMore:false,
      pages:[],
      currentPage:1,
      progress: 1,
      minDate: minDate,
      maxDate: maxDate,
      etablissement: '---',


    }
    this._handleNewReseau = this._handleNewReseau.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleChangeEtablissement = this.handleChangeEtablissement.bind(this);

  }
  handleChangeEtablissement = event => {
    var self = this;
    this.setState({ etablissement: (event.target.value) }, (state) => {
      self.setCurrentPage(1);
    });
  };
  setCurrentPage = page=>{
    this.setState({currentPage:page});
    this.listDatas(page);
  };
  _handleNewReseau = event =>{
       this.setState(state => {
             state.datas =  [event.detail.value, ...state.datas] ;
             return state;
        });
  };
  onUpdateItem = (mode,item) =>{
      var array = [...this.state.datas];
      if(mode === 'update'){
          array.splice(item.position,1,item.row);
      }else if (mode === 'delete'){
        array.splice(item.position,1);

      }else if (mode === 'enabled'){
        item.row.status = true;
        console.log('enabled', item.row.status);
        array.splice(item.position,1,item.row);

      }else if (mode === 'disabled'){
        item.row.status = false;
        array.splice(item.position,1,item.row);

      }
      
       this.setState(state => {
             state.datas =  array ;
             return state;
      });
  };
  handleStartDateChange(date){
    var self = this;
    this.setState({
      minDate: date,
    }, (state) => {
      self.setCurrentPage(1);
    });
  }

  handleEndDateChange(date) {
    var self = this;
    this.setState({
      maxDate: date,
    }, (state) => {
      self.setCurrentPage(1);
    });
  };
  componentDidMount() {  
    this.listEtablissement();     
    this.listDatas(this.state.currentPage);
    // document.addEventListener('new-reseau',this._handleNewReseau.bind(this));
  }
  componentWillUnmount() {
    // document.removeEventListener('new-reseau',this._handleNewReseau.bind(this));
  }
  listDatas(page){
    let self = this;
    this.setState({datas: [],progress:1});
    var url = appProperties.basepathServer + '/rest-etablissement/list-recharges?limit=10&page='+page;
    if (this.state.minDate  && this.state.minDate != null)url += ("&from=" + moment(this.state.minDate).format('YYYY-MM-DD HH:mm:ss'));
    if (this.state.maxDate  && this.state.maxDate != null)url += ("&to=" + moment(this.state.maxDate).format('YYYY-MM-DD HH:mm:ss'));
    if (this.state.etablissement && this.state.etablissement !== "---" && this.state.etablissement  != null) url += ("&etablissement=" + this.state.etablissement.id);

    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    
    })
    .then((response) => response.json())
    .then((data) => { 
        //var array = [];
        if(data.result_code === 1){
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({progress:0,datas:data.datas,count:data.count,hasMore:data.hasMore,pages:data.pages});  
          
        } else{
          self.setState({datas:[],progress:2});   
        }
        
    })
    .catch((err) => {
      console.log(err);
      //self.setState({datas: []});
      self.setState({datas:[],progress:3}); 
      console.log('There was an error listed etablissement.');
    });
  }
  
  listEtablissement(){
    let self = this;
    this.setState({etablissements: []});
    fetch(appProperties.basepathServer+ '/rest-etablissement/list?limit=-1', {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
    
    })
    .then((response) => response.json())
    .then((data) => { 
        //var array = [];
        if(data.result_code === 1){
          self.setState({etablissements:data.datas});  
          
        } else{
          self.setState({etablissements:[]});   
        }
        
    })
    .catch((err) => {
      console.log(err);
      self.setState({etablissements:[]}); 
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
      
        <Paper elevation={0} className={classes.root}>
          <div style={{padding:'5px 15px'}} className="layout horizontal end-justified center nowrap">
              
             <FormControl style={{ height: '35px',paddingRight:"10px" }} variant="outlined" className={classes.formControl}>
                <Select
                  title="Choisir un établissement"
                  displayEmpty
                  classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                  value={this.state.etablissement}
                  onChange={this.handleChangeEtablissement}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="Choisir un établissement"
                      id="outlined-classe-simple"
                      classes={{ input: classes.outlinedInput }}
                    />
                  }
                > <MenuItem value={'---'}>N'importe quel établissement</MenuItem>
                  {this.state.etablissements.map((row, index) => (
                    <MenuItem key={index} value={row}>{row.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span style={{paddingRight:'5px'}} className={classes.label}>
                Entre
               </span>
              <DateTimePicker
                // maxDate={this.state.maxDate}
                locale='fr-FR'
                className={classes.datePicker}
                value={this.state.minDate}
                onChange={this.handleStartDateChange}
              />

              <span style={{ marginLeft: '20px',paddingRight:'5px' }} className={classes.label}>
               Et 
              </span>
              <DateTimePicker
                label="Date de fin"
                className={classes.datePicker}
                locale='fr-FR'
                value={this.state.maxDate}
                minDate={this.state.minDate}
                onChange={this.handleEndDateChange}
              />
          </div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Etablissement</CustomTableCell>
                <CustomTableCell align="left">Solde ajouté</CustomTableCell> 
                <CustomTableCell align="left">Date</CustomTableCell>
                <CustomTableCell align="left">Effectué par</CustomTableCell>
              </TableRow>
            </TableHead>
            { this.state.progress === 0 && (
              <TableBody>
                {this.state.datas.map((row,index) => (
                  <ItemRecharge key={index} onUpdateItem = {this.onUpdateItem}  position = {index} row = {row}  {...deleteClassInProps(this.props)} />
                ))}
              </TableBody>
            )}
          </Table>
          { this.state.progress === 1 && (
              <div style={{backgroundColor: 'white',margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                <div style={{padding:'15px'}}>
                  <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                </div>
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                  Chargement en cours...
                </div>
              </div>
            )}
            {  (this.state.progress === 2 || (this.state.progress === 0 && this.state.datas.length === 0))&& (
              <div style={{backgroundColor: 'white',margin: '50px 50px 0 50px',padding: '50px'}} className="layout vertical center-center">
                {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                  Aucune recharge trouvée
                </div>
              </div>
            )}
            { this.state.progress === 3 && (
              <div style={{backgroundColor: 'white',margin: '50px 50px 0 50px',padding: '70px'}} className="layout vertical center-center">
                <div style={{padding:'15px'}}>Erreur!!</div>
                <div style={{fontSize:'17px',fontWeight:'600',width:'75%',color:'var(--paper-grey-900)'}} className="layout horizontal center-center">
                  Erreur de chargement. 
                </div>
              </div>
            )}
        </Paper>
        { this.state.progress === 0 && (
          <LdPagination onShowPage={this.setCurrentPage} pages={this.state.pages} />
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(ListRechargeView);
