import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import PropTypes from "prop-types";
import React, { Component } from "react";
import LdSelectStatic from "../../../components/LdSelectStatic";

import { isObjectValueEquals, appProperties } from "../../../components/LdUtils";
import { deleteClassInProps } from '../../../components/LdUtils';
import { injectIntl } from "react-intl";







const styles = theme => ({
  // main: {
  //   width: "400px",
  //   padding: "15px",
  //   display: "block", // Fix IE 11 issue.
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
  //     width: 450,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   }
  // },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "135px",
    padding: "0px !important",
  },
  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonPrincipale: {
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  checked: {
    color: 'var(--paper-green-500)'
  },
  unChecked: {
    color: 'var(--paper-grey-500)'

  },
  contentImage: {
    width: "40px",
  },
  bigAvatar: {

    width: "40px !important",
    height: "40px !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: '600',
    fontSize: '15px',
    padding: '4px 7px 4px 7px'
  },
  body: {
    fontSize: 14,
    padding: '4px 7px 4px 7px'
  }
}))(TableCell);



class ItemTypeIndiscipline extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      code: this.props.row.code || '',
      name: this.props.row.name || '',
      description: this.props.row.description || '',
      allowedDeclaration: this.props.row.allowedDeclaration,
      alert: this.props.row.alertParent,
      alertMessage: this.props.row.alertMessage || "",
      allowedJustification: this.props.row.allowedJustification,
      counter: this.props.row.counter || -1,
      etablissements: null,
      options: [],
      redirect: false,
      ids: "",
      datas: [],
      dialogOpened: false,
      inProgress: false,
      position: this.props.position,
      declarations: '',
      justifications: '',
    };
    this.edit = this.edit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // this.handleAllowedJustification = this.handleAllowedJustification.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
    // this.handleAllowedDeclaration = this.handleAllowedDeclaration.bind(this);


    this.handleValueDeclarations = this.handleValueDeclarations.bind(this);
    this.handleValueJustifications = this.handleValueJustifications.bind(this);
    this.handleValueCounter = this.handleValueCounter.bind(this);


  }
  convertMyStrToArray(str) {
    if (str == null || str === "") return [];
    var arr = str.split(';');
    var self = this;
    return arr.map((row, index) => {
      return { value: row, label: self.getItemLabel(row) };
    });

  }

  getItemLabel(item) {
    switch (item) {
      case 'chefclasse': return this.props.intl.formatMessage({ id: "AjouterTypeIndisciplineView_5" });
      case 'enseignant': return this.props.intl.formatMessage({ id: "AjouterTypeIndisciplineView_6" });
      case 'gestionnaire': return this.props.intl.formatMessage({ id: "AjouterTypeIndisciplineView_7" });
      default: return ''
    }
  }

  geItemCounter(id) {
    switch (id) {
      case 0: return this.props.intl.formatMessage({ id: "AjouterTypeIndisciplineView_12" });
      case 1: return this.props.intl.formatMessage({ id: "AjouterTypeIndisciplineView_13" });
      case 2: return this.props.intl.formatMessage({ id: "AjouterTypeIndisciplineView_16" });
      default: return "";
    }
  }

  convertConterToObject(id) {

    return { value: id, label: this.geItemCounter(id) };
  }
  handleValueDeclarations(selectedOptions) {
    var ids = selectedOptions.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + ";" + e.value : e.value;
    }, "");
    this.setState({ allowedDeclaration: ids });
  }
  handleValueJustifications(selectedOptions) {
    var ids = selectedOptions.reduce((acc, e, i, arr) => {
      return acc !== "" ? acc + ";" + e.value : e.value;
    }, "");
    this.setState({ allowedJustification: ids });
  }

  handleValueCounter(e) {
    this.setState({ counter: e != null ? e.value : -1 });
  }
  componentDidUpdate(prevProps) {
    var self = this;

    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          code: self.props.row.code || '',
          name: self.props.row.name || '',
          description: self.props.row.description || '',
          allowedDeclaration: self.props.row.allowedDeclaration,
          alert: self.props.row.alertParent,
          alertMessage: self.props.row.alertMessage || "",
          allowedJustification: self.props.row.allowedJustification,
          counter: self.props.row.counter
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
      });
    }
  }

  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };
  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/indiscipline/delete-type?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error deleted.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/indiscipline/set-status-type?id=" +
      this.props.row.id + '&status=1',
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", { position: self.state.position, row: this.props.row });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error enabled.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/indiscipline/set-status-type?id=" +
      this.props.row.id + '&status=0',
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", { position: self.state.position, row: this.props.row });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error disabled.");
      });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };

  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };


  // handleAllowedDeclaration(event){
  //   console.log(event.target);
  //   this.setState({
  //     allowedDeclaration: !this.state.allowedDeclaration,

  //   });
  // }
  handleAlert() {
    this.setState({
      alert: !this.state.alert,

    });
  }
  // handleAllowedJustification(){
  //   console.log(this.state.allowedJustification);  
  //   this.setState({
  //     allowedJustification: !this.state.allowedJustification

  //   });
  // }
  edit() {

    if (this.state.code == null
      || this.state.code.trim() === "" || this.state.name == null
      || this.state.name.trim() === "" || this.state.description == null
      || this.state.description.trim() === ""

    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }

    var formData = new FormData(); // Currently empty
    formData.append("id", this.props.row.id);
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("allowed_declaration", this.state.allowedDeclaration || "");
    formData.append("alert", this.state.alert ? 1 : 0);
    if (this.state.alert) formData.append("alertMessage", this.state.alertMessage);

    formData.append("allowed_justification", this.state.allowedJustification || "");
    formData.append("counter", this.state.counter);
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/indiscipline/edit-type", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false });
        if (data.result_code === 1) {

          self.props.onUpdateItem("update", {
            position: self.state.position,
            row: data.row
          });
          self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        alert("There was an error updated.");
      });
  }

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <TableRow className={classes.row} key={row.id}>

          <CustomTableCell component="th" scope="row">
            {row.code}
          </CustomTableCell>
          <CustomTableCell align="left">{row.name}</CustomTableCell>
          <CustomTableCell align="left" title={row.description}>{row.description}</CustomTableCell>
          <CustomTableCell align="left" title>{this.geItemCounter(this.props.row.counter)}</CustomTableCell>
          <CustomTableCell align="left" style={{ width: '64px' }}>{!!(row.alertParent) ?
            <IconButton
              title={row.alertMessage}
              classes={{ root: classes.checked }}
            >
              <CheckCircleIcon fontSize="small" />
            </IconButton>
            :
            <IconButton
              title={formatMessage({ id: "ItemTypeIndiscipline_1" })}
              classes={{ root: classes.unChecked }}
            >

              <RadioButtonUncheckedIcon fontSize="small" />
            </IconButton>
          }
          </CustomTableCell>
          <CustomTableCell align="left" style={{ width: '100px' }}>

            <span
              title={formatMessage({ id: "ItemTypeIndiscipline_2" })}
              style={{ fontSize: '12px', color: 'var(--paper-orange-500)' }}>
              {this.convertMyStrToArray(this.props.row.allowedJustification).map((item, index) => (

                <span key={index}>{index > 0 ? ', ' : ''}{item.label}</span>
              ))}

            </span>

          </CustomTableCell>
          <CustomTableCell align="left" style={{ width: '120px' }}>
            <span
              title={formatMessage({ id: "ItemTypeIndiscipline_3" })}
              style={{ fontSize: '12px', color: 'var(--paper-orange-500)' }}>
              {this.convertMyStrToArray(this.props.row.allowedDeclaration).map((item, index) => (
                <span key={index}>{index > 0 ? ', ' : ''}{item.label}</span>
              ))}

            </span>
          </CustomTableCell>

          <CustomTableCell align="left">

            <div className={classes.actions}>

              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
                color="primary"
                aria-label="Edit"
                onClick={this.handleClickOpen}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_del" })}
                aria-label="Delete"
                onClick={this.handleDelete}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>

              {this.props.row.status ?
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                  classes={{ root: classes.disable }}
                  aria-label="Edit"
                  onClick={this.handleDisabled}
                >
                  <CheckIcon fontSize="small" />
                </IconButton> :
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                  classes={{ root: classes.enable }}

                  aria-label="Delete"
                  onClick={this.handleEnabled}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>}

            </div>
          </CustomTableCell>
        </TableRow>

        {this.state.dialogOpened && (
          <Dialog
            open={this.state.dialogOpened}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle className={classes.pageTitle} id="form-dialog-title">
              {formatMessage({ id: "ItemTypeIndiscipline_4" })} # {row.code}
            </DialogTitle>
            <DialogContent>
              {this.state.inProgress ? (
                <div className={classes.progress}>
                  <LinearProgress variant="query" />
                </div>
              ) : null}
              <div className={classes.pageSubtitle} > {formatMessage({ id: "ItemTypeDecaissement_2" })}</div>

              <CssBaseline />
              <Paper square elevation={0} className={classes.paper}>
                {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}

                {/* {this.state.result != null ? (
                  <div className="Error-message">
                    {this.state.result.message}
                  </div>
                ) : null} */}

                <div className={classes.root}>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="code">{formatMessage({ id: "ItemTypeDecaissement_3" })}</InputLabel>
                    <Input
                      id="code"
                      required
                      name="code"
                      autoComplete="code"
                      onChange={this.handleChangeInput("code")}
                      defaultValue={row.code}
                      placeholde={formatMessage({ id: "AjouterTypeAlertView_1" })}
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="name">{formatMessage({ id: "ItemTypeDecaissement_4" })}</InputLabel>
                    <Input
                      name="name"
                      type="name"
                      id="name"
                      required
                      defaultValue={row.name}
                      onChange={this.handleChangeInput("name")}
                      autoComplete="current-name"
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="description">{formatMessage({ id: "ItemTypeDecaissement_5" })}</InputLabel>
                    <Input
                      id="description"
                      required
                      defaultValue={row.description}
                      name="description"
                      autoComplete="description"
                      onChange={this.handleChangeInput("description")}
                      multiline
                      rows="4"
                      variant="filled"
                    />
                  </FormControl>
                  <div>
                    <LdSelectStatic
                      defaultValue={this.convertMyStrToArray(this.props.row.allowedDeclaration)}
                      options={[
                        { value: 'chefclasse', label: formatMessage({ id: "AjouterTypeIndisciplineView_5" }) },
                        { value: 'enseignant', label: formatMessage({ id: "AjouterTypeIndisciplineView_6" }) },
                        { value: 'gestionnaire', label: formatMessage({ id: "AjouterTypeIndisciplineView_7" }) },
                      ]}
                      label={formatMessage({ id: "AjouterTypeIndisciplineView_8" })}
                      handleValue={this.handleValueDeclarations}
                      isMulti
                      isSearchable
                      {...deleteClassInProps(this.props)}
                    />
                  </div>
                  <p><br /></p>
                  <div>
                    <LdSelectStatic
                      defaultValue={this.convertMyStrToArray(this.props.row.allowedJustification)}
                      options={[
                        { value: 'chefclasse', label: formatMessage({ id: "AjouterTypeIndisciplineView_5" }) },
                        { value: 'enseignant', label: formatMessage({ id: "AjouterTypeIndisciplineView_6" }) },
                        { value: 'gestionnaire', label: formatMessage({ id: "AjouterTypeIndisciplineView_7" }) },
                      ]}
                      label={formatMessage({ id: "AjouterTypeIndisciplineView_4" })}
                      handleValue={this.handleValueJustifications}
                      isMulti
                      isSearchable
                      {...deleteClassInProps(this.props)}
                    />
                  </div>
                  <p><br /></p>
                  <div>
                    <LdSelectStatic
                      defaultValue={this.convertConterToObject(this.props.row.counter)}
                      options={[
                        { value: 0, label: formatMessage({ id: "AjouterTypeIndisciplineView_12" }) },
                        { value: 1, label: formatMessage({ id: "AjouterTypeIndisciplineView_13" }) },
                        { value: 2, label: formatMessage({ id: "AjouterTypeIndisciplineView_16" }) },
                      ]}
                      label={formatMessage({ id: "AjouterTypeIndisciplineView_11" })}
                      handleValue={this.handleValueCounter}
                      {...deleteClassInProps(this.props)}
                    />
                  </div>

                  {/* <FormControlLabel
                control={<Checkbox value={this.state.allowedDeclaration}  
                color="primary" onChange={this.handleAllowedDeclaration} checked={this.state.allowedDeclaration}
                />}
                label="ceci est un frais obligatoire"
              />      
              <FormControlLabel
                control={<Checkbox value={this.state.allowedJustification} 
                 color="primary" onChange={this.handleAllowedJustification} checked={this.state.allowedJustification}
                 />}
                label="Peut etre justifié"
              /> */}

                  <FormControlLabel
                    control={<Checkbox color="primary"
                      onChange={this.handleAlert} checked={this.state.alert} />}
                    label={formatMessage({ id: "AjouterTypeIndisciplineView_3" })}
                  />
                  {
                    this.state.alert ?
                      <FormControl margin="dense" required fullWidth>
                        <InputLabel htmlFor="alertMessage">{formatMessage({ id: "AjouterTypeIndisciplineView_2" })}</InputLabel>
                        <Input
                          id="alertMessage"
                          required
                          name="alertMessage"
                          autoComplete="alertMessage"
                          placeholder={formatMessage({ id: "AjouterTypeIndisciplineView_1" })}
                          defaultValue={row.alertMessage}
                          onChange={this.handleChangeInput("alertMessage")}
                          multiline
                          rows="4"
                          variant="filled"
                        />
                      </FormControl>
                      :
                      null
                  }


                </div>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}
                variant="contained"
                size="medium"
                className={classes.buttonGreyWhite}>
                {formatMessage({ id: "AjouterTypeAlertView_13" })}
              </Button>
              <Button onClick={this.edit}
                variant="contained"
                size="medium"
                className={classes.buttonPrincipale}>
                {formatMessage({ id: "AjouterTypeAlertView_15" })}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}
ItemTypeIndiscipline.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(injectIntl(ItemTypeIndiscipline));
