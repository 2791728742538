
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  isObjectValueEquals,
  userProfileUrl,
  appProperties
} from "../../../components/LdUtils";
import { withSnackbar } from "notistack";
import { injectIntl, FormattedMessage, FormattedDate } from "react-intl";

const styles = theme => ({
  // main: {
  //   width: "400px",
  //   padding: "15px",
  //   display: "block", // Fix IE 11 issue.
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
  //     width: 450,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   }
  // },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "135px",
    padding: "0px !important"
  },
  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },
  checked: {
    color: "var(--paper-green-500)"
  },
  unChecked: {
    color: "var(--paper-grey-500)"
  },
  contentImage: {
    width: "40px"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  date: {
    fontSize: "12px",
    fontWeight: "400"
  },
});

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
  </span>
));

class ItemCampus extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      name: this.props.row.name || "",
      responsable: this.props.row.responsable || "",
      address: this.props.row.address || "",
      contact: this.props.row.contact || "",


      datas: [],
      dialogOpened: false,
      inProgress: false,
      position: this.props.position,

      errors: {}
    };
    this.edit = this.edit.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.handleClose = this.handleClose.bind(this);


  }
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          name: self.props.row.name || "",
          responsable: self.props.row.responsable || "",
          address: self.props.row.address || "",
          contact: self.props.row.contact || "",

        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };


  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/campus/delete?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error deleted.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/campus/enable?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error enabled.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/campus/disable?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error disabled.");
      });
  };

  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };


  edit() {


    var errors = {};
    this.setState({ errors: errors });


    if (this.state.name == null || this.state.name === "") errors["name"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.responsable == null || this.state.responsable === "") errors["responsable"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.address == null || this.state.address === "") errors["address"] = this.props.intl.formatMessage({ id: "InputError_1" });
    if (this.state.contact == null || this.state.contact === "") errors["contact"] = this.props.intl.formatMessage({ id: "InputError_1" });


    this.setState({ errors: errors });
    if (!(errors == null || Object.keys(errors).length === 0)) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("id", this.props.row.id);
    formData.append("responsable", this.state.responsable);
    formData.append("name", this.state.name);
    formData.append("address", this.state.address);
    formData.append("contact", this.state.contact);


    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/campus/edit", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.props.onUpdateItem("update", {
            position: self.state.position,
            row: data.row
          });
          self.handleClose();
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemCampus_5" }),
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        } else if (data.result_code === -1) {
          self.props.enqueueSnackbar(
            self.props.intl.formatMessage({ id: "ItemCampus_6" }),
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "left" }
            }
          );
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error updated.");
      });
  }

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div style={{ padding: '5px 0', borderBottom: '1px solid #EAEAEA' }} className="layout horizontal center justified">
          <div>
            <div className="layout horizontal center">
              <span style={{ paddingRight: "10px", fontSize: "13px", }}>
                {formatMessage({ id: "ItemTypeDecaissement_4" })}{" "}
                <b style={{ color: 'var(--app-primary-color)', fontWeight: '600' }}>{row.name}</b>
              </span>

            </div>
            <div>
              <span style={{ paddingRight: "10px", fontSize: "13px", }}>
                {formatMessage({ id: "ItemCampus_2" })}{" "}
                <b style={{ fontWeight: '600' }}>{row.responsable}</b>
              </span>
            </div>
            <div className="layout horizontal center">
              <span style={{ paddingRight: "10px", fontSize: "13px", }}>
                {formatMessage({ id: "ItemCampus_3" })}{" "}
                <b style={{ fontWeight: '600' }}>{row.address}</b>
              </span>{" "}{" "}
              <span style={{ paddingRight: "10px", fontSize: "13px", }}>
                {formatMessage({ id: "ItemCampus_4" })}{" "}
                <b style={{ fontWeight: '600' }}>{row.contact}</b>
              </span>
            </div>
            <div style={{ fontWeight: "400" }} className={classes.date}>

              <FormattedMessage
                id="date_user"
                values={{
                  date: <PostDate date={row.created} />,
                  user: (
                    <a
                      style={{
                        paddingLeft: "5px",
                        fontWeight: "500",
                        fontSize: "11px"
                      }}
                      className="no-underline capitalize"
                      title={row.creator.name}
                      rel="noopener noreferrer"
                      target="_blank"
                      href={userProfileUrl(row.creator)}
                    >
                      {row.creator.name}
                    </a>
                  )
                }}
              />
            </div>
          </div>






          <div className={classes.actions}>
            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
              color="primary"
              aria-label="Edit"
              onClick={this.handleClickOpen}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_del" })}
              aria-label="Delete"
              onClick={this.handleDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>

            {this.props.row.status ? (
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                classes={{ root: classes.disable }}
                aria-label="Edit"
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            ) : (
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                  classes={{ root: classes.enable }}
                  aria-label="Delete"
                  onClick={this.handleEnabled}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
          </div>
        </div>





        {
          this.state.dialogOpened && (
            <Dialog
              open={this.state.dialogOpened}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle className={classes.pageTitle} id="form-dialog-title">
                {formatMessage({ id: "ItemCampus_1" })} {row.name}
              </DialogTitle>
              <DialogContent>
                {this.state.inProgress ? (
                  <div className={classes.progress}>
                    <LinearProgress variant="query" />
                  </div>
                ) : null}
                <div className={classes.pageSubtitle}>
                  {" "}
                  {formatMessage({ id: "ItemTypeDecaissement_2" })}
                </div>

                <CssBaseline />
                <Paper square elevation={0} className={classes.paper}>


                  <div className={classes.root}>

                    <FormControl margin="dense" required fullWidth>
                      <InputLabel htmlFor="name">
                        {formatMessage({ id: "AjouterCampusView_3" })}
                      </InputLabel>
                      <Input
                        error={this.state.errors.name}
                        defaultValue={this.state.name}
                        name="name"
                        type="text"
                        id="name"
                        required
                        onChange={this.handleChangeInput("name")}
                        autoComplete="current-name"
                      />
                      <FormHelperText>{this.state.errors.name ? <span className="error_field">{this.state.errors.name}</span> : ""}</FormHelperText>

                    </FormControl>

                    <FormControl margin="dense" required fullWidth>
                      <InputLabel htmlFor="responsable">
                        {formatMessage({ id: "AjouterCampusView_4" })}
                      </InputLabel>
                      <Input
                        error={this.state.errors.responsable}
                        defaultValue={this.state.responsable}
                        name="responsable"
                        type="text"
                        id="responsable"
                        required
                        onChange={this.handleChangeInput("responsable")}
                        autoComplete="current-name"
                      />
                      <FormHelperText>{this.state.errors.responsable ? <span className="error_field">{this.state.errors.responsable}</span> : ""}</FormHelperText>

                    </FormControl>



                    <FormControl margin="dense" required fullWidth>
                      <InputLabel htmlFor="responsable">
                        {formatMessage({ id: "AjouterCampusView_5" })}
                      </InputLabel>
                      <Input
                        error={this.state.errors.address}
                        defaultValue={this.state.address}
                        name="address"
                        type="text"
                        id="address"
                        required
                        onChange={this.handleChangeInput("address")}
                        autoComplete="current-address"
                      />
                      <FormHelperText>{this.state.errors.responsable ? <span className="error_field">{this.state.errors.responsable}</span> : ""}</FormHelperText>

                    </FormControl>



                    <FormControl margin="dense" required fullWidth>
                      <InputLabel htmlFor="contact">
                        {formatMessage({ id: "AjouterCampusView_6" })}
                      </InputLabel>
                      <Input
                        error={this.state.errors.contact}
                        defaultValue={this.state.contact}
                        name="address"
                        type="text"
                        id="contact"
                        required
                        onChange={this.handleChangeInput("contact")}
                        autoComplete="current-address"
                      />
                      <FormHelperText>{this.state.errors.contact ? <span className="error_field">{this.state.errors.contact}</span> : ""}</FormHelperText>

                    </FormControl>










                  </div>
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleClose}
                  variant="contained"
                  size="medium"
                  className={classes.buttonGreyWhite}
                >
                  {formatMessage({ id: "AjouterTypeAlertView_13" })}
                </Button>
                <Button
                  onClick={this.edit}
                  variant="contained"
                  size="medium"
                  className={classes.buttonPrincipale}
                >
                  {formatMessage({ id: "AjouterTypeAlertView_15" })}
                </Button>
              </DialogActions>
            </Dialog>
          )
        }
      </React.Fragment>
    );
  }
}
ItemCampus.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(ItemCampus))
);
