import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";





const styles = theme => ({
  root: {
    // paddingTop: theme.spacing.unit * 2,
    // paddingBottom: theme.spacing.unit * 2

    // backgroundColor: "#EEEEEE",
    borderTop:'1px solid #dcdcdc',
  },
  image:{
      width:'100%',
    
  }
  


});
class Page404 extends Component{

    render(){
        const { classes } = this.props;

        return(
            <div className="layout vertical"> 
                <img alt="Page 404" className={classes.image} src="/images/placeholder/404.jpg" />
                {/* <div>
                    <span>Cette page est introuvable</span>
                </div> */}
               
            </div>
        )
    }
} 
Page404.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Page404);
