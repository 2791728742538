import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { appProperties, fireWithObject } from "./LdUtils";
import { injectIntl, FormattedMessage } from "react-intl";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  root: {
    // paddingTop: theme.spacing.unit * 2,
    // paddingBottom: theme.spacing.unit * 2

    // backgroundColor: "#EEEEEE",
    borderTop: "1px solid #dcdcdc"
  },

  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 130,
    "&  fieldset": {
      borderColor: "transparent !important"
    }
  },
  selectRoot: {
    height: "35px"
  },
  selectMenu: {
    padding: "7.5px 14px",
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    "&:focus": {
      backgroundColor: "var(--app-primary-color)",
      color: "white"
    },
    borderRadius: "4px !important"
  },
  outlinedInput: {
    backgroundColor: "var(--app-primary-color)",
    color: "white",
    borderRadius: "4px"
  } /** Filter end */
});

const languages = [
  {
    key: "fr",
    label: "Français",
    icon: "/images/flags/fr.svg"
  },
  {
    key: "en",
    label: "English",
    icon: "/images/flags/gb.svg"
  }
];
class AppFooter extends Component {
  constructor(props) {
    super(props);
    var locale = localStorage.getItem(appProperties.userLocale);

    this.state = { locale: locale };
    this.changeLocale = this.changeLocale.bind(this);
  }
  changeLocale(event) {
    const locale = event.target.value;
    this.setState({ locale: locale });
    fireWithObject(document, "language-change", { value: locale });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={classes.root}>
        <div
          style={{ minHeight: "62px", fontWeight: "400", padding: "0 20px" }}
          id="footer_element"
          className="flex layout horizontal center-justified wrap"
        >
          <div className="layout horizontal around-justified center wrap">
            <div
              className="layout horizontal center"
              style={{ fontSize: this.props.fontSize || "14px" }}
            >
              <span
                className="layout horizontal center"
                style={{ fontSize: "26px", marginTop: "8px" }}
              >
                &copy;
              </span>
              <span>
                <FormattedMessage
                  id="AppFooter_droits"
                  values={{
                    mail: (
                      <a
                        className="normal_link no-underline"
                        rel="noopener noreferrer"
                        href="mailto:contact@learndia.com"
                      >
                        contact@learndia.com
                      </a>
                    )
                  }}
                />
              </span>
            </div>
          </div>
          <div
            style={{ paddingLeft: "10px" }}
            className=" footer_contact layout horizontal center"
          >
            <span className="flex" />
            <a
              style={{ marginRight: "10px", fontSize:this.props.fontSize ||  "14px" }}
              target="_blank"
              className="normal_link no-underline"
              rel="noopener noreferrer"
              href={appProperties.socialUrl + "/support/general/manager"}
            >
              {formatMessage({ id: "AppFooter_field_1" })}{" "}
            </a>
            <a
              style={{ marginRight: "10px", fontSize: this.props.fontSize || "14px" }}
              target="_blank"
              className="normal_link no-underline"
              rel="noopener noreferrer"
              href={
                appProperties.socialUrl +
                "/support/general/user-agreements/manager"
              }
            >
              {formatMessage({ id: "AppFooter_field_2" })}
            </a>
            <span className="flex" />
          </div>
          <div className="layout horizontal center">
            {/* {formatMessage({ id: "app.f_key"},{nom:'Tanda', prenom:'Cedric'})} */}
            {/* {formatMessage({ id: "app.f_key_html"},{fullname:<b>Tanda cedric00</b>, age:25})} 
            <FormattedMessage id="app.f_key_html" values={{
                fullname: <a href="https://www.google.com">Tanda cedric00</a>,
                age: 25 }} />*/}

            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({ id: "app.select_language" })}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.locale}
                onChange={this.changeLocale}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name={formatMessage({ id: "app.select_language" })}
                    id="outlined-annee-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {languages.map((row, index) => (
                  <MenuItem key={index} value={row.key}>
                    <div className="layout horizontal center">
                      <img
                        style={{ width: "20px", height: "20px" }}
                        alt=""
                        src={row.icon}
                      />
                      <span
                        style={{ marginLeft: "3px", lineHeight: "initial" }}
                      >
                        {row.label}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    );
  }
}
AppFooter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(injectIntl(AppFooter));
