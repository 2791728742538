import green from "@material-ui/core/colors/green";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { appProperties } from "./LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    //width: "20px",
    display: "flex"
  },
  paper: {
    display: "inline-flex",
    borderRadius: "50%",
    width: "45px",
    height: "45px",
    margin: "0 5px"
  },

  thumb: {
    display: "inline-flex",
    borderRadius: "2px",
    border: "1px solid #eaeaea",
    marginBottom: "4px",
    marginTop: "4px",
    marginRigth: "4px",
    marginLeft: "4px",
    width: "100px",
    height: "100px",
    padding: "4px",
    boxSizing: "border-box",
    position: "relative"
  },
  thumbInner: {
    display: "flex",
    minWidth: "0px",
    overflow: "hidden"
  },
  img: {
    display: "block",
    width: "auto",
    height: "100%"
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",

    backgroundColor: "#f7f5f57d"
  },
  section: {
    border: "1px dashed #EEEEEE",
    borderRadius: "5px",
    margin: "5px 0"
  },
  drop: {
    outline: "none",
    cursor: "pointer",
    "& p": {
      color: "grey",
      padding: "10px 0",
      margin: "0 !important"
    },
    "&:hover": {
      backgroundColor: "#FAFAFA"
    },
    "&:hover p": {
      color: "#000000"
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1
  },
  deleteButton: {
    color: "#252525",
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
    cursor: "pointer",
    fontSize: "16px"
  }
});

class LdFileUploader extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    this.state = {
      files: [],
      progress: false,
      simpleFile: this.props.simpleFile ? this.props.simpleFile : null,
      hasParent: this.props.hasParent
    };
    this.deleteFile = this.deleteFile.bind(this);
    this.deleteFileDefault = this.deleteFileDefault.bind(this);
  }
  deleteFileDefault() {
    this.setState({ hasParent: false, simpleFile: null });
    this.props.onFileUploaded(null);
  }
  onDrop(files) {
    this.setState({ files });
    console.log(files);
    if (files && Array.isArray(files) && files.length > 0) {
      this.upload(files[0]);
      if (this.state.hasParent) {
        /*this.setState({hasParent:false,name:'',path:''});
             this.props.onImageUploaded('','');*/
        this.deleteFileDefault();
      }
    }
  }
  deleteFile = event => {
    console.log("delete image");
    console.log(event);
    var index = event.target.getAttribute("data-index");
    console.log(index);
    var arr = this.state.files.splice(index, 1);
    this.setState(state => {
      state.datas = arr;
      state.name = "";
      state.path = "";
      return state;
    });
  };
  upload(file) {
    var formData = new FormData(); // Currently empty
    formData.append("filename", "file");
    formData.append("file", file);
    let self = this;
    this.setState({ progress: true });
    fetch(appProperties.basepathServer + "/rest-upload-file/add", {
      //   mode: 'cors',
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.setState(state => {
            //state.datas = state.datas.concat([data.row]);
            state.progress = false;
            state.simpleFile = data.item;
            return state;
          });
          self.props.onFileUploaded(data.item);
        }
      })
      .catch(err => {
        console.log(err);
        self.setState(state => {
          state.progress = false;

          return state;
        });
      });
  }

  render() {
    const { classes /*, pages*/ } = this.props;
    const { formatMessage } = this.props.intl;
    // const files = this.state.files.map(file => (
    //   <li key={file.name}>
    //     {file.name} - {file.size} bytes
    //   </li>
    // ));
    const thumbs = this.state.files.map((file, index) => (
      <Paper className={classes.thumb} key={file.name}>
        <Paper elevation={0} className={classes.thumbInner}>
          {/* <img
            src={URL.createObjectURL(file)}
            className={classes.img}
          /> */}
          <span>{formatMessage({ id: "LdFileUploader_file1" })}</span>
        </Paper>
        {this.state.progress && <LinearProgress variant="query" />}
        {/* <IconButton color="secondary"  aria-label="Delete image">
        </IconButton> */}
        <DeleteIcon
          data-index={index}
          onClick={this.deleteFile}
          title="Delete file"
          small
          className={classes.deleteButton}
        />
      </Paper>
    ));
    const thumbDefault = (
      <Paper className={classes.thumb}>
        <Paper elevation={0} className={classes.thumbInner}>
          <span>{formatMessage({ id: "LdFileUploader_file2" })}</span>
        </Paper>
        {this.state.progress && <LinearProgress variant="query" />}
        {/* <IconButton color="secondary"  aria-label="Delete image">
          </IconButton> */}
        <DeleteIcon
          onClick={this.deleteFileDefault}
          title="Delete file"
          className={classes.deleteButton}
        />
      </Paper>
    );
    return (
      <Dropzone multiple={false} onDrop={this.onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className={classes.section}>
            <div className={classes.drop} {...getRootProps()}>
              <input {...getInputProps()} />
              <p>{formatMessage({ id: "LdFileUploader_file_drag_drop" })}</p>
            </div>
            {/* <aside>
              <h4>Files</h4>
              <ul>{files}</ul>
            </aside> */}
            <aside className={classes.thumbsContainer}>
              {thumbs}
              {(thumbs != null || thumbs.length === 0) &&
                this.state.hasParent &&
                thumbDefault}
            </aside>
          </section>
        )}
      </Dropzone>
    );
  }
}
LdFileUploader.propTypes = {
  classes: PropTypes.object.isRequired
  //pages: PropTypes.array.isRequired
};
export default withStyles(styles)(injectIntl(LdFileUploader));
