import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

import PropTypes from "prop-types";

import LinearProgress from '@material-ui/core/LinearProgress';

import Grid from '@material-ui/core/Grid';

import { appProperties, fireWithObject, defaultAvatar, userProfileUrl, isObjectValueEquals } from "../../../components/LdUtils";
import CircularProgress from '@material-ui/core/CircularProgress';
import LdPagination from "../../../components/LdPagination";
import Paper from "@material-ui/core/Paper";
import ProgressiveImage from 'react-progressive-image';
import Avatar from "@material-ui/core/Avatar";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import AddIcon from "@material-ui/icons/Add";
import WarningIcon from "@material-ui/icons/Warning";


import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from "@material-ui/core/DialogContent";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from '@material-ui/core/Dialog';
import Truncate from 'react-truncate-html';
import moment from 'moment';
import 'moment/locale/fr';
import DateTimePicker from 'react-datetime-picker';
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";



const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: 'white',

  },
  root: {
    width: "100%",

    // marginTop: '30px',

  },
  eleveItem: {
    '&:hover': {
      backgroundColor: 'var(--paper-grey-200)'
    }
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },


  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: '0px'
  },
  bigAvatar: {

    backgroundColor: 'lightgray',
    border: '2px solid white',
    width: "32px !important",
    height: "32px !important",
    borderRadius: "50% !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,

    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },
  progress: {
    width: "100%",
    flexGrow: 1,
  },
  buttonPrincipale: {
    margin: '2px 5px',
    backgroundColor: 'var(--app-primary-dark-color)',
    color: 'white',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--app-accent-color)'
    }
  },
  buttonGreyWhite: {
    backgroundColor: 'var(--paper-grey-200)',
    color: 'var(--app-text-normal-color)',
    borderRadius: '0 !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--paper-grey-300)'
    }
  },
  pageTitle: {
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle: {
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: '40px',
    '& .react-date-picker__wrapper': {
      border: '1px dashed #AAAAAA',
      borderRadius: '5px'
    }
  },
  label: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 10px 0 0'
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: '13px',
    marginTop: "15px"

  },



  /** Filter start */
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 190,
    '&  fieldset': {
      borderColor: 'transparent !important',
    }
  },
  selectRoot: {
    height: '35px',
  },
  cssLabel: {
    // color: 'yellow',
    // transform: 'translate(14px, 9px) scale(1)',
    fontSize: '12px',
    fontWeight: '500',
    transform: 'translate(14px, -6px) scale(1)',
    // '&$cssFocused': {
    //   color: 'red',
    // },
  },
  cssFocused: {},
  selectMenu: {
    padding: '7.5px 23px',
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    '&:focus': {
      backgroundColor: 'var(--app-primary-color)',
      color: 'white',
    },
    borderRadius: '4px !important'
  },
  outlinedInput: {
    backgroundColor: 'var(--app-primary-color)',
    color: 'white',
    borderRadius: '4px'
  }  /** Filter end */
});

class AjouterIndisciplineView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      annee: this.props.annee,
      classes: [],
      classe: '---',
      motif: '',
      valeur: 0,
      inProgress: false,
      controlled: true,
      openDialog: false,
      step: 0,
      count: 0,
      hasMore: false,
      pages: [],
      eleves: [],
      eleve: null,
      types: [],
      type: null,
      date: new Date(),
      openSnack: false,
      progress: 0,
      progressClasse: 1,
      progressType: 0,

      categories: [],
      categorie: "---",
      searchValue: "",

    };
    this.add = this.add.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleChangeClass = this.handleChangeClass.bind(this);
    this.handleChangeMotif = this.handleChangeMotif.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.doOpenDialog = this.doOpenDialog.bind(this);
    this.doCloseDialog = this.doCloseDialog.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleSelectType = this.handleSelectType.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    this.handleChangeCategorie = this.handleChangeCategorie.bind(this);
    this.handleChangeValeur = this.handleChangeValeur.bind(this);

  }

  handleSearchInputChange = event => {
    if (event.keyCode === 13) {
      //this.refreshList(event.target.value);
      this.setCurrentPage(1);
    }
  };
  handleChangeCategorie = event => {
    let self = this;
    this.setState({ categorie: (event.target.value), classe: "---", classes: [], eleves: [] }, (state) => {
      self.listClasses();
      self.setCurrentPage(1);
    });
  };

  handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openSnack: false });
  };

  handleChangeDate(date) {
    this.setState({
      date: date,
    });
  };
  handleClose = value => {
    this.setState({ openDialog: false });
  };
  doSelectEleve(value) {
    this.setState({ openDialog: true, eleve: value, step: 1 });
  }
  doSelectType(value) {
    this.setState({ type: value, step: 2 });
  }
  setCurrentPage = page => {
    this.setState({ currentPage: page, eleves: [] });
    this.listEleve(page);
  };
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.etablissement, prevProps.etablissement)) {
      this.setState({ etablissement: this.props.etablissement }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.annee, prevProps.annee)) {
      this.setState({ annee: this.props.annee }, () => {
        self.setCurrentPage(1);
      });
    }
  }
  componentDidMount() {
    this.listCategorie();

    //this.listClasses();
    this.listTypeIndiscipline();
    this.listEleve(this.state.currentPage);

  }
  handleChangeMotif(event) {
    this.setState({ motif: event.target.value });
  }
  handleChangeValeur(event) {
    this.setState({ valeur: event.target.value });
  }

  handleChangeClass = event => {
    // console.log("change classe");
    // console.log(event.target.value);
    let self = this;
    this.setState({ classe: (event.target.value), eleves: [] }, (state) => {
      self.setCurrentPage(1);
    });
  };
  handleChangeUser(item) {
    this.setState({ user: item ? item : null });
  }
  doOpenDialog(value) {
    this.setState({ eleve: value, step: 1, openDialog: true });
  }
  doCloseDialog(event) {
    this.setState({ eleve: null, type: null, step: 0, openDialog: false });
  }
  handleSelectType(value) {
    this.setState({ type: value, step: 2 });
  }
  handlePrev(event) {
    this.setState({ step: 1 });
  }

  add() {
    if (this.state.eleve == null || this.state.type == null ||
      this.state.motif == null || this.state.motif.trim() === "") {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("date", moment(this.state.date).locale('fr').format('YYYY-MM-DD HH:mm:ss'));
    formData.append("motif", this.state.motif);
    formData.append("valeur", this.state.valeur);
    formData.append("eleve", this.state.eleve.id);
    formData.append("type", this.state.type.id);
    let self = this;
    this.setState({ inProgress: true });
    fetch(appProperties.basepathServer + "/manager/indiscipline/add", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false });
        if (data.result_code === 1) {
          self.doCloseDialog();
          self.setState({ openSnack: true });
          fireWithObject(document, 'new-indiscipline', { value: data.row });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  listClasses() {
    let self = this;
    this.setState({ classes: [], progressClasse: 1 });
    //var url = appProperties.basepathServer + "/rest-etablissement/list-classes?id="+this.state.etablissement.id+"&limit=1000";
    var url =
      appProperties.basepathServer +
      "/manager/annee-scolaire/classes?id=" +
      this.props.annee.id +
      "&limit=100";
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);
    else {
      this.setState({ classes: [], progressClasse: 0 });
      return;
    }
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ classes: data.datas, progressClasse: 0 });

        } else {
          self.setState({ classes: [], progressClasse: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        self.setState({ classes: [], progressClasse: 3 });
      });
  }
  listEleve(page) {

    let self = this;

    this.setState({ eleves: [], progress: 1 });
    var url = appProperties.basepathServer + "/manager/inscription/list-eleves?etablissement=" + this.state.etablissement.id + '&limit=10&page=' + page;
    if (this.state.annee != null) url += ("&annee=" + this.state.annee.id);
    if (this.state.classe && this.state.classe !== "---" && this.state.classe != null) url += ("&classe=" + this.state.classe.classeEtablissement.id);
    if (this.state.categorie && this.state.categorie !== "---" && this.state.categorie != null) url += ("&categorie_etablissement_id=" + this.state.categorie.id);

    if (this.state.searchValue != null && this.state.searchValue.trim() !== "") url += "&search=" + this.state.searchValue.trim();

    /*else {
      this.setState({ progress: 0 });
      return;
    }*/
    fetch(url, {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        console.log(data);
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }

          self.setState({ progress: 0, eleves: data.datas, count: data.count, hasMore: data.hasMore, pages: data.pages });

        } else {
          self.setState({ eleves: [], progress: 2 });

        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ eleves: [], progress: 3 });

        console.log('There was an error listed enseignant.');
      });
  }
  listTypeIndiscipline() {

    let self = this;

    this.setState({ types: [], progressType: 1 });


    fetch(appProperties.basepathServer + "/manager/indiscipline/list-types?etablissement=" + this.state.etablissement.id + "&limit=-1&only_published=1&type=0", {
      mode: 'cors',
      method: 'GET',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',

    })
      .then((response) => response.json())
      .then((data) => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({ progressType: 0, types: data.datas });

        } else {
          self.setState({ types: [], progressType: 2 });
        }

      })
      .catch((err) => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ types: [], progressType: 3 });

        console.log('There was an error listed type de frais.');
      });
  }

  listCategorie() {
    let self = this;

    this.setState({ categories: [] });

    fetch(
      appProperties.basepathServer +
      "/rest-categorie-etablissement/list?etablissement_id=" + this.props.etablissement.id + "&limit=-1&only_published=1",
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {

          self.setState({
            categories: data.datas,
          });
        } else {
          self.setState({ categories: [] });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ categories: [] });

        console.log("There was an error listed enseignant.");
      });
  }


  render() {
    const { classes, ...other } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        <Dialog onUpdateItem={null} open={this.state.openDialog} onClose={this.doCloseDialog} aria-labelledby="simple-dialog-title" {...other}>
          {this.state.step === 1 && (
            <DialogTitle component="h6" id="simple-dialog-title"> {formatMessage({ id: "AjouterIndisciplineView_1" })}<b>#{this.state.eleve != null ? (this.state.eleve.fullname || '@' + this.state.eleve.user.name) : ''}</b> </DialogTitle>
          )}
          {this.state.step === 2 && (
            <div>

              <DialogTitle component="h6" id="simple-dialog-title"> {formatMessage({ id: "AjouterIndisciplineView_2" })} <b>#{this.state.eleve != null ? (this.state.eleve.fullname || '@' + this.state.eleve.user.name) : ''}</b>
                <br />
                <div className="pageSubTitle">{formatMessage({ id: "AjouterIndisciplineView_3" })}# <b>{this.state.type != null ? this.state.type.name : ''}</b></div>
              </DialogTitle>
            </div>
          )}

          <DialogContent>
            {this.state.step === 1 && (
              <List>
                {this.state.types.map((row, index) => (
                  <ListItem title={row.description} button key={index}>
                    <ListItemAvatar>
                      <Avatar>
                        <WarningIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={row.name} secondary={<Truncate
                      lines={1}
                      portrait={4}
                      breakWord={true}
                      responsive={true}
                      dangerouslySetInnerHTML={{
                        __html: row.description
                      }}
                    />} />
                    {(this.state.type != null && this.state.type.id === row.id) ?
                      <ListItemAvatar title={formatMessage({ id: "AjouterIndisciplineView_4" })} onClick={() => this.handleSelectType(row)}>
                        <Avatar style={{ color: 'var(--paper-green-500)' }}>
                          <DoneAllIcon />
                        </Avatar>
                      </ListItemAvatar>
                      :
                      <ListItemAvatar title={formatMessage({ id: "AjouterIndisciplineView_5" })} onClick={() => this.handleSelectType(row)}>
                        <Avatar>
                          <AddIcon />
                        </Avatar>
                      </ListItemAvatar>
                    }


                  </ListItem>
                ))}

              </List>)}
            {this.state.step === 2 && (
              <div style={{ padding: '15px 0' }}>
                {this.state.inProgress
                  ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                  : null
                }
                {!this.state.controlled
                  ? <div className={classes.error}>{formatMessage({ id: "AjouterAnneeScolaire_Check" })}</div>
                  : null
                }
                <div className={classes.pageSubtitle} > {formatMessage({ id: "AjouterTypeAlertView_form" })}</div>
                <div className="layout horizontal center" style={{ padding: '15px 0' }}>
                  <span className={classes.label}>
                    {formatMessage({ id: "AjouterIndisciplineView_6" })}
                  </span>
                  <DateTimePicker
                    locale='fr-FR'
                    className={classes.datePicker}
                    value={this.state.date}
                    onChange={this.handleChangeDate}
                  /><br /><br />
                </div>
                <div style={{ padding: '15px 0' }}>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="motif">{formatMessage({ id: "AjouterIndisciplineView_17" })}</InputLabel>
                    <Input
                      id="valeur"
                      required
                      name="valeur"
                      placeholder={
                        this.state.type != null ?
                          (this.state.type.counter === 0) ? formatMessage({ id: "AjouterIndisciplineView_19" }) :
                            ((this.state.type.counter === 1) ? formatMessage({ id: "AjouterIndisciplineView_18" }) : "")
                          : ""
                      }
                      defaultValue={this.state.valeur}
                      autoComplete="valeur"
                      onChange={this.handleChangeValeur}

                      variant="filled"
                    />
                  </FormControl>
                </div>
                <div style={{ padding: '15px 0' }}>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="motif">{formatMessage({ id: "AjouterIndisciplineView_7" })}</InputLabel>
                    <Input
                      id="motif"
                      required
                      name="motif"
                      placeholder={formatMessage({ id: "AjouterIndisciplineView_8" })}
                      defaultValue={this.state.motif}
                      autoComplete="motif"
                      onChange={this.handleChangeMotif}
                      multiline
                      rows="4"
                      variant="filled"
                    />
                  </FormControl>
                </div>
                <div className="layout horizontal center justified">
                  <div>
                    <Button onClick={this.handlePrev}
                      variant="contained"
                      size="medium"
                      className={classes.buttonPrincipale}>
                      {formatMessage({ id: "AjouterIndisciplineView_9" })}
                    </Button>
                  </div>
                  <div>
                    <Button onClick={this.handleClose}
                      variant="contained"
                      size="medium"
                      className={classes.buttonGreyWhite}>
                      {formatMessage({ id: "AjouterIndisciplineView_10" })}
                    </Button>
                    <Button
                      onClick={this.add}
                      variant="contained"
                      size="medium"
                      className={classes.buttonPrincipale}>
                      {formatMessage({ id: "AjouterTypeAlertView_15" })}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{formatMessage({ id: "AjouterIndisciplineView_11" })}</span>}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleSnackBarClose}
            >
              OK!
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />

        <div>
          <div>
            <div className={classes.pageTitle} > {formatMessage({ id: "AjouterIndisciplineView_12" })}</div>
            <div className={classes.pageSubtitle} > {formatMessage({ id: "AjouterIndisciplineView_13" })}</div>
          </div>
          <div className="layout horizontal center">
            <div className="flex">
              <TextField
                id="searchValue"
                label={formatMessage({ id: "_search_student_label" })}
                style={{
                  width: "98%",
                  marginTop: "0 !important",
                  marginRight: "5px"
                }}
                value={this.state.searchValue}
                placeholder={formatMessage({ id: "_search_student_placeholder" })}
                onChange={(event) => this.setState({ searchValue: event.target.value })}
                onKeyDown={this.handleSearchInputChange}

                margin="normal"
              />
            </div>
            <FormControl
              style={{ height: "35px" }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                title={formatMessage({
                  id: "ListClasseEtablissementView_4"
                })}
                displayEmpty
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot
                }}
                value={this.state.categorie}
                onChange={this.handleChangeCategorie}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name="Choisir une classe"
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              >
                {" "}
                <MenuItem value={"---"}>
                  {formatMessage({ id: "ListClasseEtablissementView_3" })}
                </MenuItem>
                {this.state.categories.map((row, index) => (
                  <MenuItem title={row.description} key={index} value={row}>
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ height: '35px' }} variant="outlined" className={classes.formControl}>

              <Select
                title={formatMessage({ id: "AjouterEleveView_4" })}
                classes={{ selectMenu: classes.selectMenu, root: classes.selectRoot }}
                value={this.state.classe}
                onChange={this.handleChangeClass}
                input={
                  <OutlinedInput
                    labelWidth={this.state.labelWidth}
                    name={formatMessage({ id: "AjouterEleveView_4" })}
                    id="outlined-classe-simple"
                    classes={{ input: classes.outlinedInput }}
                  />
                }
              > <MenuItem value={"---"}>{formatMessage({ id: "AjouterEleveView_4" })}</MenuItem>
                {this.state.classes.map((row, index) => (
                  <MenuItem key={index} value={row}>{row.classeEtablissement.name}</MenuItem>
                ))}
              </Select>
            </FormControl>

          </div>
        </div>

        <div>

          {(this.state.progress === 0 && this.state.eleves.length > 0) && (
            <React.Fragment>
              <Paper elevation={0} className={classes.root}>
                <div style={{ margin: '15px 0' }}>
                  {this.state.eleves.map((row, index) => (
                    <Grid className={classes.eleveItem} style={{ padding: '5px' }} key={index} container justify="space-between" alignItems="center">
                      <div>
                        <ProgressiveImage src={row.pathPhoto} placeholder={defaultAvatar}>
                          {(src, loading) => (<Avatar alt='' src={src} className={classes.bigAvatar} />)}
                        </ProgressiveImage>
                      </div>
                      <div style={{ paddingLeft: '5px' }} className="flex">
                        <div style={{ fontSize: "14px", fontWeight: "600" }}>
                          <a className="no-underline capitalize" title={row.user.name} rel="noopener noreferrer" target="_blank" href={userProfileUrl(row.user)}>
                            {row.fullname || '@' + row.user.name}
                          </a>
                        </div>
                        <div className="layout horizontal center" style={{ fontSize: "13px", fontWeight: "600" }}>
                          <span className={classes.name}> {row.classeEtablissement.name}</span>
                          <span style={{ fontSize: "small", backgroundColor: 'var(--app-primary-color)', padding: '0 5px', marginLeft: '10px', color: 'white' }}> {row.matricule}</span>
                        </div>
                      </div>
                      <div>
                        <Button
                          title={formatMessage({ id: "AjouterIndisciplineView_14" })} style={{ textTransform: 'none', borderRadius: '0 !important' }} variant="outlined" size="small"
                          onClick={() => this.doOpenDialog(row)}>
                          {formatMessage({ id: "AjouterIndisciplineView_12" })}
                        </Button>
                      </div>
                    </Grid>
                  ))}
                </div>
              </Paper>
              <LdPagination onShowPage={this.setCurrentPage} pages={this.state.pages} />
            </React.Fragment>)}



          <React.Fragment>
            {this.state.progress === 1 && (
              <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                <div style={{ padding: '15px' }}>
                  <CircularProgress thickness={1} size={75} className={classes.fabProgress} />
                </div>
                <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                  {formatMessage({ id: "ListRoleView_Load" })}
                </div>
              </div>
            )}

            {(this.state.progress === 2 || (this.state.progress === 0 && this.state.eleves.length === 0)) && (
              <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
                <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                  {formatMessage({ id: "AjouterIndisciplineView_16" })}
                </div>
              </div>
            )}
            {/* {(this.state.progress === 0 && this.state.eleves.length === 0) && (this.state.classe == null || this.state.classe === '---') && (this.state.searchValue == null || this.state.searchValue.trim() === '') && (
              <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                  {formatMessage({ id: "AjouterIndisciplineView_15" })}
                </div>
              </div>
            )} */}
            {this.state.progress === 3 && (
              <div style={{ backgroundColor: 'white', padding: '50px' }} className="layout vertical center-center">
                <div style={{ padding: '15px' }}>{formatMessage({ id: "ListClasseAnneeView_4" })}</div>
                <div style={{ fontSize: '17px', fontWeight: '600', width: '75%', color: 'var(--paper-grey-900)' }} className="layout horizontal center-center">
                  {formatMessage({ id: "ListRoleView_LoadError" })}
                </div>
              </div>
            )}

          </React.Fragment>


        </div>

      </main>
    );
  }
}
AjouterIndisciplineView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(
  injectIntl(AjouterIndisciplineView)
);
