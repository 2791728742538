import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import {
  appProperties,
  defaultAvatar,
  isObjectValueEquals
} from "../../../components/LdUtils";

import withStyles from "@material-ui/core/styles/withStyles";

import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import ProgressiveImage from "react-progressive-image";

import IconButton from "@material-ui/core/IconButton";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { injectIntl } from "react-intl";
const styles = theme => ({
  root: {
    padding: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none",
    padding: "0 10px 0 10px"
  },
  email: {
    fontSize: "13px",
    fontWeight: "400",
    padding: "0 10px 0 10px"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  }
});

class ItemRole extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      inProgress: false,
      position: this.props.position,
      access: this.props.access
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
  }
  componentDidUpdate(prevProps) {
    //var self = this;
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => {
        //self.refreshList("");
      });
    }
    if (!isObjectValueEquals(this.props.access, prevProps.access)) {
      this.setState({ access: this.props.access }, () => {});
    }
  }

  handleDelete = () => {
    //TODO: faire que cette action enleve juste le droit d'accès correspondant
    var self = this;
    fetch(
      appProperties.basepathServer +
        "/rest-etablissement/remove-access-admin?idUser=" +
        this.props.row.user.id +
        "&idEtablissement=" +
        this.props.row.etablissementId +
        "&access=" +
        this.props.access,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
        "/rest-etablissement/set-status-admin?status=1&id=" +
        this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
        "/rest-etablissement/set-status-admin?status=0&id=" +
        this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classes.content}>
            {/* <div className={classes.contentImage}>
              
              </div> */}
            <div className={classes.contentImage}>
              <ProgressiveImage
                src={row.user.photo}
                placeholder={defaultAvatar}
              >
                {(src, loading) => (
                  <Avatar alt="" src={src} className={classes.bigAvatar} />
                )}
              </ProgressiveImage>
            </div>
            <div className={classes.contentFlex}>
              <div>
                <Typography
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    appProperties.socialUrl + "/profile/" + row.user.user_id
                  }
                  className={classes.name}
                >
                  {row.user.name}
                </Typography>
                <div className={classes.email}>{row.user.email}</div>
              </div>
            </div>
          </div>
          <div className={classes.actions}>
            <IconButton
              title={formatMessage({ id: "ItemRole_del" })}
              aria-label="Delete"
              onClick={this.handleDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>

            {this.props.row.status ? (
              <IconButton
                title={formatMessage({ id: "ItemRole_desact" })}
                classes={{ root: classes.disable }}
                aria-label="desactiver"
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                title={formatMessage({ id: "ItemRole_activ" })}
                classes={{ root: classes.enable }}
                aria-label="activer"
                onClick={this.handleEnabled}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ItemRole.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(injectIntl(ItemRole));
