const appProperties = {

  // basepath: "http://127.0.0.1:3000",
  // backendPath: "http://learndia.com:1080/learndiatest/public",
  // basepathServer: "http://learndia.com:1080/learndiatest/public",
  // socialUrl: "http://127.0.0.1.8000",


  //basepath: "https://www.manager.learndia.com",
  //backendPath: "https://www.learndia.com/public",
  //basepathServer: "https://www.learndia.com/public",
  //socialUrl: "https://www.learndia.com",

  // basepath: "http://learndia.com:29001",
  // backendPath: "http://learndia.com:1080/learndiatest/public",
  // basepathServer: "http://learndia.com:1080/learndiatest/public",
  // socialUrl: "http://learndia.com:29001",
  
  basepath: "https://www.manager.learndia.com",
  backendPath: "https://api.learndia.com/public",
  basepathServer: "https://api.learndia.com/public",
  socialUrl: "https://www.learndia.com",

  // basepath: process.env.REACT_APP_BASEPATH,
  // backendPath: process.env.REACT_APP_BACKEND_BASEPATH,
  // basepathServer: process.env.REACT_APP_BASEPATH_SERVER,
  // socialUrl: process.env.REACT_APP_SOCIAL_URL,

  isNodeApp: 0,
  currentUser: null,
  key_user: "USER",
  email: "EMAIL",
  password: "PASSWORD",
  sessionId: "SESSION_ID",
  client: "manager",
  userLocale: "LOCALE"
};
var defaultAvatar = "/images/icons/avatar_2x.png";
var defaultCover = "/images/icons/default_cover.png";
var defaultClassImage = "/images/icons/default_classe.png";
var defaultStudents = "/images/icons/students.jpg";

function replaceAll(str, find, strReplace) {
  return str.replace(
    new RegExp(find.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&"), "g"),
    strReplace
  );
}
function isPositive(val) {
  return val && parseInt(val) >= 0;
}

/**
 * 
 *  fireCustomEvent: function(type, detail) {
    console.log('CustomEventMixin.fireCustomEvent', type, detail);
    var el = React.findDOMNode(this);
    var event = new CustomEvent(type, {bubbles: true, detail: detail});
    el.dispatchEvent(event);
  },
 */
//http://jsfiddle.net/gcao/20ga1aLn/
function fire(name, datas) {
  this.dispatchEvent(
    new CustomEvent(name, { detail: datas, bubbles: true, composed: true })
  );
}
function fireWithObject(object, name, datas) {
  object.dispatchEvent(
    new CustomEvent(name, { detail: datas, bubbles: true, composed: true })
  );
}
function hasAccessKey(accesses, code) {
  //https://stackoverflow.com/questions/6396046/unlimited-arguments-in-a-javascript-function
  if (accesses == null || accesses === {}) return false;
  return accesses.hasOwnProperty(code);
}

function isUserAdmin(user) {
  return (
    user &&
    user != null &&
    user.role != null &&
    user.role.toLowerCase() === "admin"
  );
}
function isLogged(user) {
  return user && user != null;
}

function getFistLetter(str, max, capitalize) {

  if (str == null) return "";
  str = str.split(" ");
  var res = [];
  var current = "";
  for (let i = 0, x = str.length; i < x && i < max; i++) {
    //str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    current = str[i] && str[i] != null ? str[i][0] : "";
    current = (current || "").trim();
    res.push(capitalize && current !== "" ? current.toUpperCase() : current);
  }
  return res.join("");
}

function convertAccessToString(access, fm) {
  if (!access || access == null || access === "") return "";
  return Object.entries(access).reduce((acc, e, i, arr) => {
    return acc !== ""
      ? acc + " - " + getAccessLabel(e[0], fm)
      : getAccessLabel(e[0], fm);
  }, "");
}
function getAccessLabel(name, formatMessage) {
  switch (name) {
    case "admin":
      return formatMessage({ id: "LdUtils_Role_1" });
    case "manager":
      return formatMessage({ id: "LdUtils_Role_2" });
    case "inscription":
      return formatMessage({ id: "LdUtils_Role_3" });
    case "scolarite":
      return formatMessage({ id: "LdUtils_Role_4" });
    case "note":
      return formatMessage({ id: "LdUtils_Role_5" });
    case "discipline":
      return formatMessage({ id: "LdUtils_Role_6" });
    case "planning":
      return formatMessage({ id: "LdUtils_Role_7" });
    case "cahiertexte":
      return formatMessage({ id: "LdUtils_Role_8" });
    case "enseignant":
      return formatMessage({ id: "LdUtils_Role_9" });
    case "chef":
      return formatMessage({ id: "LdUtils_Role_10" });
    case "stock":
      return formatMessage({ id: "LdUtils_Role_11" });
    case "stockR":
      return formatMessage({ id: "LdUtils_Role_12" });
    case "comptable":
      return formatMessage({ id: "LdUtils_Role_13" });
    case "chefComptable":
      return formatMessage({ id: "LdUtils_Role_14" });
    case "coordo":
      return formatMessage({ id: "LdUtils_Role_15" });
    default:
      return "";
  }
}
function randomColor() {
  return (
    "#" +
    Math.random()
      .toString(16)
      .slice(2, 8)
  );
}
function userProfileUrl(user) {
  if (user == null) return "#";
  return appProperties.socialUrl + "/profile/" + user.user_id;
}
function pageProfileUrl(etablissement) {
  if (etablissement == null) return "#";
  return appProperties.socialUrl + "/page/" + etablissement.code;
}
function copyClipboard(str) {
  const el = document.createElement("textarea"); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute("readonly", ""); // Make it readonly to be tamper-proof
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
}

function isObjectValueEquals(obj1, obj2) {
  //Loop through properties in object 1
  obj1 = obj1 || null;
  obj2 = obj2 || null;
  if (obj1 == null || obj2 == null) {
    return obj1 == null && obj2 == null;
  }
  for (var p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    if (obj1[p] === null && obj2[p] !== null) return false;
    if (obj2[p] === null && obj1[p] !== null) return false;

    switch (typeof obj1[p]) {
      //Deep compare objects
      case "object":
        if (Array.isArray(obj1[p])) {
          return Array.isArray(obj2[p]) && obj1[p].length === obj2[p].length;
        }
        if (!isObjectValueEquals(obj1[p], obj2[p])) return false;
        break;
      //Compare function code
      case "function":
        // eslint-disable-next-line
        if (
          typeof obj2[p] == "undefined" ||
          (p !== "compare" && obj1[p].toString() !== obj2[p].toString())
        )
          return false;
        break;
      //Compare values
      default:
        if (obj1[p] === "" && obj2[p] !== "") return false;
        if (obj2[p] === "" && obj1[p] !== "") return false; // eslint-disable-next-line
        if (obj1[p] != obj2[p]) return false;
    }
  }

  //Check object 2 for any extra properties
  for (var a in obj2) {
    if (typeof obj1[a] == "undefined") return false;
  }
  return true;
}
function cancelEventPropagation(event) {
  if (event == null) return;
  event.preventDefault();
  if (event.stopPropagation) {
    event.stopPropagation();
  } else if (window.event) {
    window.event.cancelBubble = true;
  }
}

function getRound2(value) {
  if (parseFloat(value) === 0) return 0;
  return Math.round(value * 100) / 100;
}
function formatDurationHms(seconds) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;
  var time = "";
  if (hours > 0) {
    time = hours + "h ";
  }
  if (minutes > 0) {
    minutes =
      minutes < 10 && time !== "" ? ["0", minutes].join("") : String(minutes);
    time += minutes + "m ";
  }

  if (seconds > 0) {
    seconds =
      seconds < 10 && time !== "" ? ["0", seconds].join("") : String(seconds);
    time += seconds + "s ";
  }

  return time;
}

function deleteClassInProps(props) {
  var props2 = Object.assign({}, props);
  if (props2.hasOwnProperty("classes")) delete props2.classes;
  return props2;
}

function computeNbMinutes($hourMinuteStr) {
  if ($hourMinuteStr === "") return 0;
  var $array = $hourMinuteStr.split(":");
  if ($array == null || $array.length !== 2) return 0;
  return 60 * parseInt($array[0]) + parseInt($array[1]);
}
function gmDate(seconds) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;
  var time = "";
  if (hours > 0) {
    time = hours + ":";
  }
  if (minutes > 0) {
    minutes =
      minutes < 10 && time !== "" ? ["0", minutes].join("") : String(minutes);
    time += minutes;
    if (seconds > 0) {
      time += ":";
      seconds =
        seconds < 10 && time !== "" ? ["0", seconds].join("") : String(seconds);
      time += seconds + "";
    }
  } else time += "00";

  return time;
}

export {
  defaultAvatar,
  defaultStudents,
  appProperties,
  fireWithObject,
  isUserAdmin,
  isLogged,
  getAccessLabel,
  hasAccessKey,
  convertAccessToString,
  getFistLetter,
  randomColor,
  defaultClassImage,
  defaultCover,
  copyClipboard,
  replaceAll,
  isPositive,
  fire,
  userProfileUrl,
  isObjectValueEquals,
  cancelEventPropagation,
  getRound2,
  formatDurationHms,
  deleteClassInProps,
  computeNbMinutes,
  pageProfileUrl,
  gmDate
};
