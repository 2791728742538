import Button from "@material-ui/core/Button";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import LdSelectEtablissementEnseignant from "../../../components/LdSelectEtablissementEnseignant";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    marginBottom: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: "white"
  },
  root: {
    // width: "100%",
    // marginTop: '30px',
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },

  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  }
});

class AjouterEnseignantView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      etablissementId: this.props.etablissementId,
      etablissement: this.props.etablissement,
      user: null
    };
    this.handleValue = this.handleValue.bind(this);
    this.doAdd = this.doAdd.bind(this);
  }

  doAdd(event) {
    if (this.state.user == null) return;
    fireWithObject(document, "new-enseignant", { value: this.state.user });
  }

  handleValue(user) {
    this.setState({ user: user });
    //fireWithObject(document,'new-enseignant',{value:user});
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <main className={classes.main}>
        <div className={classes.titleBlock}>
          <div className={classes.pageTitle} variant="div" component="div">
            {formatMessage({ id: "AjouterEnseignantView_Title" })}
          </div>
          <div className={classes.pageSubtitle} variant="div" component="div">
            {formatMessage({ id: "AjouterEnseignantView_subTitle" })}
          </div>
        </div>
        <br />
        <div className="layout horizontal center">
          <div className="flex">
            <LdSelectEtablissementEnseignant
              handleValue={this.handleValue}
              firstLoad
              isSearchable
              label={formatMessage({ id: "AjouterEnseignant_ff" })}
              baseUrl={
                appProperties.basepathServer +
                "/manager/etablissement/enseignants?id=" +
                this.state.etablissement.id +
                "&limit=-1&name="
              }
              {...deleteClassInProps(this.props)}
            />
          </div>
          <Button
            variant="contained"
            size="medium"
            className={classes.buttonPrincipale}
            onClick={this.doAdd}
          >
            {formatMessage({ id: "AjouterEnseignantView_1" })}
          </Button>
        </div>
      </main>
    );
  }
}
AjouterEnseignantView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(
  injectIntl(AjouterEnseignantView)
);
