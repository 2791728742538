import React, { Component } from "react";
import { appProperties, fireWithObject } from "../../../components/LdUtils";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";

import PropTypes from "prop-types";

import LinearProgress from "@material-ui/core/LinearProgress";

import Grid from "@material-ui/core/Grid";
import DatePicker from "react-date-picker";
import moment from "moment";
import { withSnackbar } from "notistack";
import { injectIntl } from "react-intl";

const styles = theme => ({
  main: {
    // width: "400px",
    textAlign: "left",
    padding: "15px",
    display: "block", // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      // width: 450,
      // marginLeft: "auto",
      // marginRight: "auto"
    },
    backgroundColor: "white"
  },
  root: {
    width: "100%",
    marginTop: "30px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },

  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  pageTitle: {
    fontSize: "21px",
    lineHeight: "22px",
    fontWeight: 400
  },
  pageSubtitle: {
    fontSize: "15px",
    lineHeight: "22px",
    fontWeight: 400,
    color: theme.color.primaryColor
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  }
});

class AjouterAnneeScolaireView extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    const minDate = new Date();
    const maxDate = null;
    // minDate.setFullYear(minDate.getFullYear() - 1);
    // minDate.setHours(0, 0, 0, 0);
    // maxDate.setFullYear(maxDate.getFullYear() + 1);
    // maxDate.setHours(0, 0, 0, 0);
    this.state = {
      redirect: false,
      etablissementId: this.props.etablissementId,
      datas: [],
      year: "",
      code: "",
      minDate: minDate,
      maxDate: maxDate,
      inProgress: false,
      controlled: true
    };
    this.add = this.add.bind(this);
    this.handleChangeYear = this.handleChangeYear.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }
  handleStartDateChange(date) {
    this.setState({
      minDate: date
    });
  }

  handleEndDateChange(date) {
    this.setState({
      maxDate: date
    });
  }
  handleChangeYear(event) {
    this.setState({ year: event.target.value });
  }
  handleChangeCode(event) {
    this.setState({ code: event.target.value });
  }

  add() {
    if (
      this.state.year == null ||
      this.state.year.trim() === "" ||
      this.state.minDate == null ||
      this.state.maxDate == null
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("year", this.state.year);
    formData.append("code", this.state.code);
    formData.append(
      "date_from",
      moment(this.state.minDate).format("YYYY-MM-DD")
    );
    formData.append("date_to", moment(this.state.maxDate).format("YYYY-MM-DD"));
    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/rest-etablissement/add-annee-scolaire?idEtablissement=" +
      this.state.etablissementId,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        this.setState({ inProgress: false });
        if (data.result_code === 1) {
          fireWithObject(document, "new-annee", { value: data.row });

          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemAnneeScolaire_9" }), {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        } else {
          self.props.enqueueSnackbar(self.props.intl.formatMessage({ id: "ItemAnneeScolaire_8" }), {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" }
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.main}>
        <div className={classes.pageTitle} id="form-dialog-title">
          {" "}
          {formatMessage({ id: "AjouterAnneeScolaire_Title" })}
        </div>

        <div className={classes.pageSubtitle}>
          {formatMessage({ id: "AjouterAnneeScolaire_SubTitle" })}{" "}
        </div>
        {this.state.inProgress ? (
          <div className={classes.progress}>
            <LinearProgress variant="query" />
          </div>
        ) : null}
        {!this.state.controlled ? (
          <div className={classes.error}>
            {formatMessage({ id: "AjouterAnneeScolaire_Check" })}{" "}
          </div>
        ) : null}
        <div className="layout horizontal">
          <div style={{ paddingRight: "10px" }} className="flex">
            <div class="layout horizontal center justified">
              <TextField
                id="code"
                label={formatMessage({ id: "AjouterAnneeScolaire_Code" })}
                style={{ width: "48%", marginTop: "0 !important" }}
                value={this.state.code}
                placeholder="ANN2019"
                onChange={this.handleChangeCode}
                margin="normal"
              />
              <TextField
                id="year"
                label={formatMessage({ id: "AjouterAnneeScolaire_titre" })}
                style={{ width: "48%", marginTop: "0 !important" }}
                value={this.state.year}
                placeholder="Ex: 2018-2019"
                onChange={this.handleChangeYear}
                margin="normal"
              />
            </div>

            <Grid
              container
              className={classes.grid}
              justify="flex-start"
              alignItems="center"
            >
              <span className={classes.label}>
                {formatMessage({ id: "AjouterAnneeScolaire_debut" })}
              </span>
              <DatePicker
                // maxDate={this.state.maxDate}
                locale="fr-FR"
                className={classes.datePicker}
                value={this.state.minDate}
                onChange={this.handleStartDateChange}
              />
              {/* <TimePicker
                    margin="normal"
                    label="Time picker"
                    value={start}
                    onChange={this.handleDateChange}
                  /> */}
              <span style={{ marginLeft: "20px" }} className={classes.label}>
                {formatMessage({ id: "AjouterAnneeScolaire_fin" })}
              </span>
              <DatePicker
                label="Date de fin"
                className={classes.datePicker}
                locale="fr-FR"
                value={this.state.maxDate}
                minDate={this.state.minDate}
                onChange={this.handleEndDateChange}
              />
            </Grid>
          </div>
          <div className="layout vertical">
            <span className="flex" />
            <Button
              onClick={this.add}
              variant="contained"
              size="medium"
              className={classes.buttonPrincipale}
            >
              {formatMessage({ id: "AjouterAnneeScolaire_Add" })}
            </Button>
          </div>
        </div>
      </main>
    );
  }
}
AjouterAnneeScolaireView.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withSnackbar(
  withStyles(styles, { withTheme: true })(injectIntl(AjouterAnneeScolaireView))
);
