import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import React, { Component } from "react";
import LdPagination from "../../../components/LdPagination";
import { appProperties, randomColor } from "../../../components/LdUtils";
import ItemMatiere from "./ItemMatiere";
import CircularProgress from "@material-ui/core/CircularProgress";
import { deleteClassInProps } from "../../../components/LdUtils";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  fabProgress: {
    color: "var(--app-primary-color)",
    margin: "auto"
  }
});

class ListMatiereView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      count: 0,
      hasMore: false,
      pages: [],
      etablissement: this.props.etablissement,
      currentPage: 1,
      progress: 1
    };
    this._handleNewMatiere = this._handleNewMatiere.bind(this);
    this.onUpdateItem = this.onUpdateItem.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
  }
  setCurrentPage = page => {
    this.setState({ currentPage: page });
    this.listMatiere(page);
  };
  _handleNewMatiere = event => {
    var matieres = event.detail.value;
    if (Array.isArray(matieres)) {
      matieres = matieres.reverse();

      var self = this;
      matieres.forEach(matiere => {
        self.setState(state => {
          state.datas = [matiere, ...state.datas];
          return state;
        });
      });
    }
  };
  onUpdateItem = (mode, item) => {
    var array = [...this.state.datas];
    if (mode === "update") {
      array.splice(item.position, 1, item.row);
    } else if (mode === "delete") {
      array.splice(item.position, 1);
    } else if (mode === "enabled") {
      item.row.status = true;
      console.log("enabled", item.row.status);
      array.splice(item.position, 1, item.row);
    } else if (mode === "disabled") {
      item.row.status = false;
      array.splice(item.position, 1, item.row);
    }

    this.setState(state => {
      state.datas = array;
      return state;
    });
  };
  componentDidMount() {
    this.listMatiere(this.state.currentPage);
    document.addEventListener("new-matiere", this._handleNewMatiere.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener(
      "new-matiere",
      this._handleNewMatiere.bind(this)
    );
  }
  listMatiere(page) {
    let self = this;

    this.setState({ datas: [], progress: 1 });
    // fetch(appProperties.basepathServer+ '/manager/etablissement/enseignants?id='+this.state.etablissement.id +'&limit=5&page='+page, {
    fetch(
      appProperties.basepathServer +
        "/manager/etablissement/matieres?id=" +
        this.state.etablissement.id +
        "&limit=10&page=" +
        page,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        if (data.result_code === 1) {
          // if(data.datas && data.datas.length > 0){
          //   data.datas.forEach( e => {
          //       array.push({value:e.id,label:e.name});
          //   });
          // }
          self.setState({
            progress: 0,
            datas: data.datas,
            count: data.count,
            hasMore: data.hasMore,
            pages: data.pages
          });
        } else {
          self.setState({ datas: [], progress: 2 });
        }
      })
      .catch(err => {
        console.log(err);
        //self.setState({datas: []});
        self.setState({ datas: [], progress: 3 });

        console.log("There was an error listed matieres.");
      });
  }
  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {this.state.progress === 0 && (
          <React.Fragment>
            <Paper elevation={0} className={classes.root}>
              <div>
                {this.state.datas.map((row, index) => (
                  <ItemMatiere
                    color={randomColor()}
                    key={index}
                    onUpdateItem={this.onUpdateItem}
                    etablissementId={this.state.etablissement.id}
                    position={index}
                    row={row}
                    {...deleteClassInProps(this.props)}
                  />
                ))}
              </div>
            </Paper>
            <LdPagination
              onShowPage={this.setCurrentPage}
              pages={this.state.pages}
            />
          </React.Fragment>
        )}
        {this.state.progress === 1 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              <CircularProgress
                thickness={1}
                size={75}
                className={classes.fabProgress}
              />
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_Load" })}
            </div>
          </div>
        )}

        {(this.state.progress === 2 ||
          (this.state.progress === 0 && this.state.datas.length === 0)) && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            {/* <div style={{padding:'15px'}}>Erreur!!</div> */}
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListMatiereView_1" })}
            </div>
          </div>
        )}
        {this.state.progress === 3 && (
          <div
            style={{
              backgroundColor: "white",
              margin: "50px 50px 0 50px",
              padding: "50px"
            }}
            className="layout vertical center-center"
          >
            <div style={{ padding: "15px" }}>
              {formatMessage({ id: "ListClasseAnneeView_4" })}
            </div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: "600",
                width: "75%",
                color: "var(--paper-grey-900)"
              }}
              className="layout horizontal center-center"
            >
              {formatMessage({ id: "ListRoleView_LoadError" })}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withStyles(styles)(injectIntl(ListMatiereView));
