import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-date-picker";
import { FormattedDate, injectIntl, intlShape, FormattedMessage } from "react-intl";
import {
  isObjectValueEquals,
  appProperties
} from "../../../components/LdUtils";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// import ListTrimestreView from '../trimestre/ListTrimestreView';
const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "center",
    paddingBottom: "10px"
  },
  content: {
    display: "flex",
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px"
  },
  contentImage: {
    width: "40px"
  },
  contentFlex: {
    "-ms-flex": "1 1 0.000000001px",
    "-webkit-flex": "1",
    flex: "1",
    "-webkit-flex-basis": "0.000000001px",
    "flex-basis": "0.000000001px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },

  progress: {
    width: "100%",
    flexGrow: 1
  },

  enable: {
    color: "var(--paper-red-500)"
  },
  disable: {
    color: "var(--paper-green-500)"
  },
  finished: {
    color: "var(--paper-orange-500)"
  },
  buttonSimple: {
    textTransform: "none"
  },
  buttonPrincipale: {
    backgroundColor: "var(--app-primary-dark-color)",
    color: "white",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--app-accent-color)"
    }
  },
  buttonGreyWhite: {
    backgroundColor: "var(--paper-grey-200)",
    color: "var(--app-text-normal-color)",
    borderRadius: "0 !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "var(--paper-grey-300)"
    }
  },
  name: {
    fontSize: "15px",
    fontWeight: "600",
    textDecoration: "none"
  },
  date: {
    fontSize: "12px",
    fontWeight: "400"
  },
  bigAvatar: {
    width: "40px !important",
    height: "40px !important"
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding: 2,
    borderRadius: "50%",
    backgroundColor: theme.color.primaryDark,
    border: "2px solid #FFF",
    cursor: "pointer"
  },

  datePicker: {
    height: "40px",
    "& .react-date-picker__wrapper": {
      border: "1px dashed #AAAAAA",
      borderRadius: "5px"
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    padding: "0 10px 0 0"
  },
  error: {
    margin: "3px",
    color: "red",
    /* font-weight: bold; */
    fontSize: "13px",
    marginTop: "15px"
  },
  purpleAvatar: {
    margin: "10px 10px 10px 0",
    color: "#fff",

    fontSize: "medium",
    fontWeight: "600"
  },

});

// const messages = defineMessages({
//   enUSDescription: {
//       id: 'menu.item_en_us_description',
//       defaultMessage: 'The default locale of this example app.',
//       en:'This is the english version',
//       fr:'CEci est la version française'
//   },
//   enUPPERDescription: {
//       id: 'menu.item_en_upper_description',
//       defaultMessage: 'The fake, all uppercase "locale" for this example app.',
//   },
// });

const PostDate = injectIntl(({ date, intl }) => (
  <span title={intl.formatDate(date)}>
    {/* <FormattedRelative value={date}/> */}
    <FormattedDate day="numeric" month="long" year="numeric" value={date} />
  </span>
));

class ItemSequence extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      // etablissements: null,
      result: null,
      redirect: false,
      // ids: "",
      inProgress: false,
      modeEdit: false,
      position: this.props.position,
      color: this.props.color,
      name: this.props.row.name,
      code: this.props.row.code,
      percent: this.props.row.percent,
      ordering: this.props.row.ordering,
      minDate:
        this.props.row.dateFrom == null || this.props.row.dateFrom.trim() === ""
          ? new Date()
          : new Date(this.props.row.dateFrom),
      maxDate:
        this.props.row.dateTo == null || this.props.row.dateTo.trim() === ""
          ? new Date()
          : new Date(this.props.row.dateTo),
      controlled: true,
      openConfirm: false,
      progressDelete: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.reset = this.reset.bind(this);
    this.edit = this.edit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleChangePercent = this.handleChangePercent.bind(this);
    this.handleChangeOrdering = this.handleChangeOrdering.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleNotFinished = this.handleNotFinished.bind(this);
    this.handleFinished = this.handleFinished.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);

  }
  componentDidUpdate(prevProps) {
    var self = this;
    if (!isObjectValueEquals(this.props.row, prevProps.row)) {
      this.setState({ row: this.props.row }, () => {
        self.setState({
          code: self.props.row.code || "",
          name: self.props.row.name || "",
          ordering: self.props.row.ordering || "",
          percent: self.props.row.percent,
          minDate:
            self.props.row.dateFrom == null ||
              self.props.row.dateFrom.trim() === ""
              ? new Date()
              : new Date(self.props.row.dateFrom),
          maxDate:
            self.props.row.dateTo == null || self.props.row.dateTo.trim() === ""
              ? new Date()
              : new Date(self.props.row.dateTo)
        });
      });
    }
    if (!isObjectValueEquals(this.props.position, prevProps.position)) {
      this.setState({ position: this.props.position }, () => { });
    }
  }
  handleDelete = () => {
    this.setState({ openConfirm: true });
  };

  handleClose = () => {
    this.setState({ openConfirm: false });
  };
  handleEdit = () => {
    this.setState({ modeEdit: true });
  };
  reset = () => {
    this.setState({ modeEdit: false, controlled: true });
  };
  handleStartDateChange(date) {
    console.log("start Date");
    console.log(date);
    this.setState({
      minDate: date
    });
  }

  handleEndDateChange(date) {
    console.log("end Date");
    console.log(date);
    this.setState({
      maxDate: date
    });
  }
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }
  handleChangeCode(event) {
    this.setState({ code: event.target.value });
  }
  handleChangeOrdering(event) {
    this.setState({ ordering: event.target.value });
  }

  handleChangePercent(event) {
    this.setState({ percent: event.target.value });
  }
  edit = () => {
    if (
      this.state.percent == null || parseFloat(this.state.percent) <= 0 || parseFloat(this.state.percent) > 100 ||

      this.state.code == null ||
      this.state.code.trim() === "" ||
      this.state.name == null ||
      this.state.name.trim() === "" ||
      this.state.minDate == null ||
      this.state.maxDate == null
    ) {
      this.setState({ controlled: false });
      return;
    } else {
      this.setState({ controlled: true });
    }
    var formData = new FormData(); // Currently empty
    formData.append("name", this.state.name);
    formData.append("code", this.state.code);
    formData.append("ordering", this.state.ordering);
    formData.append("percent", this.state.percent);
    formData.append("from", moment(this.state.minDate).format("YYYY-MM-DD"));
    formData.append("to", moment(this.state.maxDate).format("YYYY-MM-DD"));
    let self = this;
    this.setState({ inProgress: true });
    fetch(
      appProperties.basepathServer +
      "/manager/annee-scolaire/edit-sequence?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "POST",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include",
        body: formData
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.result_code === 1) {
          self.reset();
          let datas = { position: self.state.position, row: data.row };
          self.props.onUpdateItemSequence("update", datas);
        }
        this.setState({ inProgress: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleClickDelete = () => {
    var self = this;
    this.setState({ progressDelete: true });
    fetch(
      appProperties.basepathServer +
      "/manager/annee-scolaire/remove-sequence?id=" +
      this.props.row.id,

      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        self.setState({ progressDelete: false,openConfirm:false });
        if (data.result_code === 1) {
          self.props.onUpdateItemSequence("delete", {
            position: self.state.position
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ progressDelete: false });
        alert("There was an error added.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/annee-scolaire/set-status-sequence?field=status&value=1&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItemSequence("enabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/annee-scolaire/set-status-sequence?field=status&value=0&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItemSequence("disabled", {
            position: self.state.position,
            row: this.props.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleFinished = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/annee-scolaire/set-status-sequence?field=finished&value=1&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItemSequence("update", {
            position: self.state.position,
            row: data.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
  handleNotFinished = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/annee-scolaire/set-status-sequence?field=finished&value=0&id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItemSequence("update", {
            position: self.state.position,
            row: data.row
          });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };

  render() {
    const { classes, row } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>

        <Dialog
          open={this.state.openConfirm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{formatMessage({ id: "ItemSequence_3" }, { sequence: this.props.row != null ? this.props.row.name : '' })}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {formatMessage({ id: "ItemSequence_4" })}
            </DialogContentText>
            <div style={{ padding: '10px 5px' }}>
              {this.state.progressDelete
                ? <div className={classes.progress}><LinearProgress variant="query" /></div>
                : null
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button style={{ textTransform: "none" }} onClick={this.handleClose} color="primary">
              {formatMessage({ id: "ItemEleve_2" })}
            </Button>
            <Button style={{ textTransform: "none" }} onClick={this.handleClickDelete} color="primary" autoFocus>
              {formatMessage({ id: "ItemEleve_3" })}
            </Button>
          </DialogActions>
        </Dialog>


        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.contentFlex}>
              {this.state.modeEdit ? (
                <main className={classes.main}>
                  {this.state.inProgress ? (
                    <div className={classes.progress}>
                      <LinearProgress variant="query" />
                    </div>
                  ) : null}
                  {!this.state.controlled ? (
                    <div className={classes.error}>
                      {formatMessage({ id: "AjouterAnneeScolaire_Check" })}
                    </div>
                  ) : null}
                  <div className="layout vertical">
                    <div style={{ paddingRight: "10px" }} className="flex">
                      <Grid
                        container
                        className={classes.grid}
                        justify="flex-start"
                        alignItems="center"
                      >
                        <TextField
                          id="name"
                          label={formatMessage({ id: "AjouterSequenceView_3" })}
                          style={{
                            width: "24%",
                            marginTop: "0 !important",
                            marginRight: "5px"
                          }}
                          value={this.state.code}
                          placeholder="Ex: Seq1"
                          onChange={this.handleChangeCode}
                          margin="normal"
                        />
                        <TextField
                          id="name"
                          label={formatMessage({ id: "AjouterSequenceView_2" })}
                          style={{ width: "24%", marginTop: "0 !important" }}
                          value={this.state.name}
                          placeholder={formatMessage({
                            id: "AjouterSequenceView_1"
                          })}
                          onChange={this.handleChangeName}
                          margin="normal"
                        />
                        <TextField
                          id="ordering"
                          label={formatMessage({
                            id: "ItemClasseEtablissement_10"
                          })}
                          style={{
                            width: "24%",
                            marginTop: "0 !important",
                            marginLeft: "5px"
                          }}
                          value={this.state.ordering}
                          placeholder="Ex: 2"
                          onChange={this.handleChangeOrdering}
                          type="number"
                          min="1"
                          margin="normal"
                        />
                        <TextField
                          id="percent"
                          label={formatMessage({ id: "AjouterSequenceView_5" })}
                          style={{
                            width: "24%",
                            marginTop: "0 !important",
                            marginLeft: "5px"
                          }}
                          value={this.state.percent}
                          placeholder="Ex: 25"
                          onChange={this.handleChangePercent}
                          type="number"
                          min="1"
                          margin="normal"
                          InputProps={{
                            inputProps: { min: 0, max: 100 },
                          }}

                        />
                      </Grid>
                      <Grid
                        container
                        className={classes.grid}
                        justify="flex-start"
                        alignItems="center"
                      >
                        <span className={classes.label}>
                          {formatMessage({ id: "AjouterAnneeScolaire_debut" })}
                        </span>
                        <DatePicker
                          // maxDate={this.state.maxDate}
                          locale="fr-FR"
                          className={classes.datePicker}
                          value={this.state.minDate}
                          onChange={this.handleStartDateChange}
                        />

                        <span
                          style={{ marginLeft: "20px" }}
                          className={classes.label}
                        >
                          {formatMessage({ id: "AjouterAnneeScolaire_fin" })}
                        </span>
                        <DatePicker
                          label={formatMessage({
                            id: "AjouterAnneeScolaire_fin"
                          })}
                          className={classes.datePicker}
                          locale="fr-FR"
                          value={this.state.maxDate}
                          minDate={this.state.minDate}
                          onChange={this.handleEndDateChange}
                        />
                      </Grid>
                    </div>
                    <div className="layout vertical">
                      <span className="flex"> </span>
                      <div className="layout horizontal center wrap">
                        <Button
                          onClick={this.edit}
                          variant="contained"
                          size="medium"
                          style={{ margin: "5px" }}
                          className={classes.buttonPrincipale}
                        >
                          {formatMessage({ id: "ItemAnneeScolaire_Up" })}
                        </Button>
                        <Button
                          onClick={this.reset}
                          variant="contained"
                          size="medium"
                          style={{ margin: "5px" }}
                          className={classes.buttonGreyWhite}
                        >
                          {formatMessage({ id: "ItemAnneeScolaire_Ccl" })}
                        </Button>
                      </div>
                    </div>
                  </div>
                </main>
              ) : (
                  <React.Fragment>
                    <div>
                      <Avatar
                        style={{ backgroundColor: this.state.color }}
                        className={classes.purpleAvatar}
                      >
                        S{row.ordering}
                      </Avatar>
                    </div>
                    <div>
                      <div className="layout horizontal center">
                        <span className={classes.name}>
                          {row.name} &nbsp;-&nbsp;{" "}
                          {row.code !== "" ? row.code : "###"}
                          &nbsp;-&nbsp;{" "}
                          <span title={formatMessage({ id: "AjouterSequenceView_6" })}>{row.percent}%</span>
                        </span>
                      </div>
                      {/*ItemAnneeScolaire_Period*/}
                      <div className={classes.date}>
                        <FormattedMessage
                          id="ItemAnneeScolaire_Period"
                          values={{
                            debut: <PostDate date={row.dateFrom} />,
                            fin: <PostDate date={row.dateTo} />
                          }}
                        />
                        {/*Du <PostDate date={row.dateFrom} /> au{" "}
                      <PostDate date={row.dateTo} />*/}
                      </div>
                    </div>
                  </React.Fragment>
                )}
            </div>
          </div>
          <div className={classes.actions}>
            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_edit" })}
              aria-label="Editer"
              onClick={this.handleEdit}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              title={formatMessage({ id: "ItemAnneeScolaire_del" })}
              aria-label="Delete"
              onClick={this.handleDelete}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>

            {!!this.props.row.finished ? (
              <IconButton
                title={formatMessage({ id: "ItemSequence_1" })}
                aria-label={formatMessage({ id: "ItemSequence_1" })}
                classes={{ root: classes.disable }}
                onClick={this.handleNotFinished}
              >
                <CheckCircleIcon fontSize="small" />
              </IconButton>
            ) : (
                <IconButton
                  title={formatMessage({ id: "ItemSequence_2" })}
                  aria-label={formatMessage({ id: "ItemSequence_2" })}
                  classes={{ root: classes.finished }}
                  onClick={this.handleFinished}
                >
                  <RadioButtonUncheckedIcon fontSize="small" />
                </IconButton>
              )}
            {!!this.props.row.status ? (
              <IconButton
                title={formatMessage({ id: "ItemAnneeScolaire_desact" })}
                classes={{ root: classes.disable }}
                aria-label="desactiver"
                onClick={this.handleDisabled}
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            ) : (
                <IconButton
                  title={formatMessage({ id: "ItemAnneeScolaire_activ" })}
                  classes={{ root: classes.enable }}
                  aria-label="activer"
                  onClick={this.handleEnabled}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ItemSequence.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};
//export default withStyles(styles, { withTheme: true })(injectIntl(ItemAnneeScolaire));
//Ceci est une façon d'envoyer les messages comme du intl.
// mais en realité on a pas besoin d'injecter celà.
export default withStyles(styles, { withTheme: true })(
  injectIntl(ItemSequence)
);
