import React, { Component } from "react";

import {
  appProperties,
 
} from "../../../components/LdUtils";
import withStyles from "@material-ui/core/styles/withStyles";

import PropTypes from "prop-types";


import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
 
  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "135px",
    padding: "0px !important",
  },
  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },

});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14
  }
}))(TableCell);




class ItemManager extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
     
      inProgress: false,
      position: this.props.position
    };
    this.handleDelete = this.handleDelete.bind(this);
  
  
  }

  handleDelete = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
        "/web/reseau-etablissement/remove-manager?id=" + this.props.row.user.id+"&reseau="+this.props.row.reseauEtablissementId,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error added.");
      });
  };
 




  render() {
    const { classes, row } = this.props;


    return (
      <React.Fragment>
        <TableRow className={classes.row} key={row.id}>
        
          <CustomTableCell align="left">{row.user.name}</CustomTableCell>
          <CustomTableCell align="left">{row.user.email}</CustomTableCell>
          <CustomTableCell align="left">{row.created}</CustomTableCell>
       
          <CustomTableCell align="left">
           
            <div className={classes.actions}>
           
              <IconButton
                title="Delete"
                aria-label="Delete"
                onClick={this.handleDelete}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          </CustomTableCell>
        </TableRow>

       
      </React.Fragment>
    );
  }
}
ItemManager.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(ItemManager);
