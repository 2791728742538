import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { withSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { appProperties, isObjectValueEquals } from "../../../components/LdUtils";







const styles = theme => ({
  // main: {
  //   width: "400px",
  //   padding: "15px",
  //   display: "block", // Fix IE 11 issue.
  //   marginLeft: theme.spacing.unit * 3,
  //   marginRight: theme.spacing.unit * 3,
  //   [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
  //     width: 450,
  //     marginLeft: "auto",
  //     marginRight: "auto"
  //   }
  // },
  // root: {
  //   padding: "15px"
  // },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit
  },
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: "0px"
  },
 
  progress: {
    width: "100%",
    flexGrow: 1
  },
  table: {
    minWidth: 700
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "135px",
    padding: "0px !important",
  },
  enable: {
    color: 'var(--paper-red-500)'
  },
  disable: {
    color: 'var(--paper-green-500)'

  },
  buttonPrincipale:{
    backgroundColor:'var(--app-primary-dark-color)',
    color:'white',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--app-accent-color)'
    }
  },
  buttonGreyWhite:{
    backgroundColor:'var(--paper-grey-200)',
    color:'var(--app-text-normal-color)',
    borderRadius:'0 !important',
    textTransform:'none',
    '&:hover':{
      backgroundColor:'var(--paper-grey-300)'
    }
  },
  pageTitle:{
    fontSize: '21px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  pageSubtitle:{
    fontSize: '15px',
    lineHeight: '22px',
    fontWeight: 400,
    color:theme.color.primaryColor
  },
  checked: {
    color: 'var(--paper-green-500)'
  },
  unChecked: {
    color: 'var(--paper-grey-500)'

  },
  contentImage:{
    width:"40px",
  },
  bigAvatar: {
    
    width: "40px !important",
    height:"40px !important",
  },
  bigAvatarDiv: {
    marginLeft: 10,
    marginRight: 10,
    padding:2,
    borderRadius:"50%",
    backgroundColor:theme.color.primaryDark,
    border:"2px solid #FFF",
    cursor:"pointer"
  },
});
const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight:'600',
    fontSize:'15px',
    padding: '4px 7px 4px 7px'
  },
  body: {
    fontSize: 14,
    padding: '4px 7px 4px 7px'
  }
}))(TableCell);



class ItemTypeMateriel extends Component {
  constructor(props) {
    super(props);
    //console.log("User view",this.props)
    this.state = {
      code: this.props.row.code || '',
      name: this.props.row.name || '',
      description: this.props.row.description || '',
      contentModel: this.props.row.contentModel||'',
      pu:this.props.row.pu||0,
      stock:this.props.row.stock||0,
      etablissements: null,
      options: [],
      redirect: false,
      ids: "",
      datas: [],
      dialogOpened: false,
      inProgress: false,
      position: this.props.position,

    };
    this.edit = this.edit.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    // this.handleDelete = this.handleDelete.bind(this);
    this.handleEnabled = this.handleEnabled.bind(this);
    this.handleDisabled = this.handleDisabled.bind(this);
    this.handleClose = this.handleClose.bind(this);

   
  }
  
 
 
 
 
  componentDidUpdate(prevProps) {
    var self = this;
  
    if(!isObjectValueEquals(this.props.row,prevProps.row)){
      this.setState({row:this.props.row},()=>{
        self.setState({ code: self.props.row.code || '',
        name: self.props.row.name || '',
        description: self.props.row.description || '',
        pu: self.props.row.pu||0,
        stock: self.props.row.stock||0,

       });
      });
    } 
    if(!isObjectValueEquals(this.props.position,prevProps.position)){
      this.setState({position:this.props.position},()=>{
      });
    }
  }
 
  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };
  handleDelete = () => { //TODO: demander de considerer au backend le role de gestionnaire de stock pour cette action 
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/type-materiel/delete?id=" +
      this.props.row.id,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("delete", { position: self.state.position });
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error deleted.");
      });
  };
  handleEnabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
        "/manager/type-materiel/enable?id=" +
        this.props.row.id+'&status=1',
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("enabled", { position: self.state.position, row: this.props.row});
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error enabled.");
      });
  };
  handleDisabled = () => {
    var self = this;
    fetch(
      appProperties.basepathServer +
      "/manager/type-materiel/disable?id=" +
      this.props.row.id+'&status=0',
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //this.setState({ inProgress: false });
        if (data.result_code === 1) {
          // self.setState(state => {
          //   state.datas = state.datas.concat([data.row]);
          //   return state;
          // });
          //fireWithObject(document, "new-reseau", { value: data.row });
          self.props.onUpdateItem("disabled", { position: self.state.position,  row: this.props.row});
          //self.handleClose();
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ inProgress: false });
        alert("There was an error disabled.");
      });
  };
  handleClose = () => {
    this.setState({ dialogOpened: false });
  };

  handleClickOpen = () => {
    this.setState({ dialogOpened: true });
  };


  handleChangeInput = name => e => {
    var val = {};
    val[name] = e.target.value;
    this.setState(val);
  };
  

  handleChangeInputNumber = name => e => {
    var val = {};
    var value =  e.target.value;
    if(value<0){
      value = 0;
      e.target.value = value;
    }
    if(e.target.value==="")value=0;
   
    val[name] = parseFloat(value);
    this.setState(val);
  };

  edit() {
   
    if(this.state.code == null  
      || this.state.code.trim() === "" || this.state.name == null  
      || this.state.name.trim() === ""|| this.state.description == null  
      || this.state.description.trim() === ""){
      this.setState({controlled:false});
      return;
    }else{
      this.setState({controlled:true});
    }
    
    var formData = new FormData(); // Currently empty
    formData.append("id", this.props.row.id);
    formData.append("code", this.state.code);
    formData.append("name", this.state.name);
    formData.append("description", this.state.description);
    formData.append("pu", this.state.pu);
    // formData.append("stock", this.state.stock); //TODO: demander qu'on gere ce stock au backend avec le type float

    
    let self = this;
    this.setState({inProgress:true});
    fetch(appProperties.basepathServer + "/manager/type-materiel/edit", {
      mode: "cors",
      method: "POST",
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: "application/json"
      },
      credentials: "include",
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        self.setState({ inProgress: false });
        self.handleClose();
        if (data.result_code === 1) {
          self.props.onUpdateItem("update",{position: self.state.position, row: data.row});
          self.props.enqueueSnackbar("Type de matériel modifié avec succès", { variant:"success", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }else{
          self.props.enqueueSnackbar("Les données ne sont pas valides", { variant:"error", anchorOrigin: {vertical: 'bottom', horizontal: 'left', }, });
        }
      })
      .catch(err => {
        console.log(err);
        self.setState({ inProgress: false });
        alert("There was an error updated.");
      });
  }

  render() {
    const { classes, row } = this.props;

    return (
      <React.Fragment>
        <TableRow className={classes.row} key={row.id}>
       
          <CustomTableCell component="th" scope="row">
            {row.code}
          </CustomTableCell>
          <CustomTableCell align="left">{row.name}</CustomTableCell>
          <CustomTableCell align="left" title={row.description}>{row.description}</CustomTableCell>
          <CustomTableCell align="left">{row.pu}F CFA</CustomTableCell>
          <CustomTableCell align="left">
            {row.stock}  
          </CustomTableCell>
       
          <CustomTableCell align="left">
           
            <div className={classes.actions}>

               <IconButton
                title="Editer"
                color="primary"
                aria-label="Edit"
                onClick={this.handleClickOpen}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                title="Delete"
                aria-label="Delete"
                onClick={this.handleDelete}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>

              {this.props.row.status ?
                  <IconButton
                  title="desactiver"
                  classes={{root: classes.disable}}
                  aria-label="Edit"
                  onClick={this.handleDisabled}
                >
                 <CheckIcon fontSize="small" />
                </IconButton> :
                 <IconButton
                title="activer"
                classes={{root: classes.enable}}

                aria-label="Delete"
                onClick={this.handleEnabled}
              >
                 <ClearIcon fontSize="small" />
              </IconButton>}
            
            </div>
          </CustomTableCell>
        </TableRow>

        {this.state.dialogOpened && (
          <Dialog
            open={this.state.dialogOpened}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle  className={classes.pageTitle}  id="form-dialog-title">
              <div>
                Editer le type de materiel # {row.code}
              </div>
              {this.state.inProgress ? (
                <div className={classes.progress}>
                  <LinearProgress variant="query" />
                </div>
              ) : null}
              <div  className={classes.pageSubtitle} >Renseignez toutes les informations sur le réseau </div>

              <CssBaseline />
            </DialogTitle>
            <DialogContent>
              
              <Paper square elevation={0} className={classes.paper}>
                {/* <Avatar alt="Default Image" src={defaultAvatar} className={classes.avatar} />*/}

                {/* {this.state.result != null ? (
                  <div className="Error-message">
                    {this.state.result.message}
                  </div>
                ) : null} */}

                <div className={classes.root}>
                 
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="code">Code :</InputLabel>
                    <Input
                      id="code"
                      required
                      name="code"
                      autoComplete="code"
                      onChange={this.handleChangeInput("code")}
                      defaultValue={row.code}
                      placeholde="entrez votre code"
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="name">name :</InputLabel>
                    <Input
                      name="name"
                      type="name"
                      id="name"
                      required
                      defaultValue={row.name}
                      onChange={this.handleChangeInput("name")}
                      autoComplete="current-name"
                    />
                  </FormControl>
                  <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="description">Description :</InputLabel>
                    <Input
                      id="description"
                      required
                      defaultValue={row.description}
                      name="description"
                      autoComplete="description"
                      onChange={this.handleChangeInput("description")}
                      multiline
                      rows="4"
                      variant="filled"
                    />
                  </FormControl>
               
                  <FormControl margin="dense" required fullWidth>
                  <InputLabel htmlFor="pu">Prix unitaire(En F CFA) :</InputLabel>
                    <Input
                      id="pu"
                      required
                      name="pu"
                      defaultValue={row.pu}
                      type="number"
                      autoComplete="pu"
                      onChange={this.handleChangeInputNumber("pu")}
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      variant="filled"
                    />
                  </FormControl>
                
                  {/* <FormControl margin="dense" required fullWidth>
                    <InputLabel htmlFor="stock">Stock initial :</InputLabel>
                    <Input
                      id="stock"
                      required
                      name="stock"
                      type="number"
                      defaultValue={row.stock}
                      autoComplete="stock"
                      onChange={this.handleChangeInputNumber("stock")}
                      InputProps={{
                        inputProps: { min: 0 }
                      }}
                      variant="filled"
                    />
                  </FormControl> */}
                </div>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} 
              variant="contained"
              size="medium"
              className = {classes.buttonGreyWhite}>
                Cancel
              </Button>
              <Button onClick={this.edit} 
                variant="contained"
                size="medium"
                className = {classes.buttonPrincipale}>
                Enregistrer
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}
ItemTypeMateriel.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};
export default withSnackbar(withStyles(styles, { withTheme: true })(ItemTypeMateriel));
