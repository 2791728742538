import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import { injectIntl } from "react-intl";

const styles = theme => ({
  root: {},
  paper: {
    //marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
    //   .spacing.unit * 3}px`
    padding: '0px'
  },
  input: {
    display: "flex",
    padding: 0,
    height: 'auto  !important'
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },

  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paperMenu: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  },
});
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}
function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paperMenu}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}
function MultiValue(props) {//TODO: Use this in select multiple
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}
const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  ValueContainer
};
class LdSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      baseUrl: props.baseUrl,
      options: [],
      selectedOption: null,
      result: null,
      datas: [],
      inProgress: false,
      firstLoad: false,
      isSearchable: props.isSearchable || false,
      isMulti: props.isMulti || false,
      label: props.label || 'Saisissez un terme à rechercher',
      defaultValue: props.defaultValue || ((props.isMulti) ? [] : null),
      value: props.defaultValue || ((props.isMulti) ? [] : null),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectInputChange = this.handleSelectInputChange.bind(this);
    this.selectRef = React.createRef();


  }

  componentDidMount() {
    this.refreshList("");
  }
  componentDidUpdate(prevProps) {
    var self = this;
    if (this.props.baseUrl !== prevProps.baseUrl) {
      this.setState({ baseUrl: this.props.baseUrl }, () => { //TODO: dans les autre composants, mettre ceci pour resoudre le problème de l'update
        self.setState({ options: [], value: self.state.isMulti ? [] : null });
        self.handleChange(self.state.isMulti ? [] : null);
        self.refreshList("");
      });
    }
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({
        defaultValue: this.props.defaultValue || (this.props.isMulti ? [] : null),
        value: this.props.defaultValue || (this.props.isMulti ? [] : null)
      }, () => {
      });
    }

  }
  handleSelectInputChange = (event) => {
    this.setState({ search: event.target.value, firstLoad: true });
    if (!this.state.firstLoad) this.refreshList(event.target.value);
  };
  handleChange(item) {
    this.setState({ value: item });
    this.props.handleValue(item);
  }
  refreshList(query) {
    let self = this;
    // this.setState({ options: [], value: this.state.isMulti ? [] : null });
    // this.handleChange(this.state.isMulti ? [] : null);
    fetch(this.state.baseUrl + query,
      {
        mode: "cors",
        method: "GET",
        headers: {
          //'Content-Type': 'application/x-www-form-urlencoded',
          Accept: "application/json"
        },
        credentials: "include"
      }
    )
      .then(response => response.json())
      .then(data => {
        //var array = [];
        // if (data.result_code == 1) {
        //   if (data.datas && data.datas.length > 0) {
        //     data.datas.forEach(e => {
        //       array.push({ value: e.id, label: e.name });
        //     });
        //   }
        // }
        self.setState({ options: data.datas ? data.datas : [], firstLoad: true });
      })
      .catch(err => {
        console.log(err);
        self.setState({ options: [], firstLoad: true });
        console.log("There was an error.");
      });
  }
  render() {
    const { classes, theme } = this.props;
    const { formatMessage } = this.props.intl;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      })
    };

    return (
      <div className={classes.root}>
        <Select
          ref={this.selectRef}
          placeholder={this.state.label}
          defaultValue={this.state.defaultValue}
          value={this.state.value}
          onChange={this.handleChange}
          onKeyDown={this.handleSelectInputChange}
          options={this.state.options.map(option => {
            return {
              label: option.name ? option.name : '',
              value: option.id ? option.id : '',
              id: option.id ? option.id : '',
              option: option
            };
          })}
          isSearchable={this.state.isSearchable}
          isMulti={this.props.isMulti}
          components={components}
          classes={classes}
          styles={selectStyles}
          textFieldProps={{
            label: formatMessage({ id: "LdSelectUser_1_Search" }),
            InputLabelProps: {
              shrink: true
            }
          }}
          isClearable
        />
      </div>
    );
  }
}
LdSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};
export default withStyles(styles, { withTheme: true })(injectIntl(LdSelect));
