import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from "prop-types";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { appProperties, isObjectValueEquals } from './LdUtils';
import { injectIntl } from "react-intl";


const styles = theme => ({
  root: {
    //width: "20px",
    display: "flex"
  },
  paper: {
    display: "inline-flex",
    borderRadius: "50%",
    width: "45px",
    height: "45px",
    margin: "0 5px"
  },

  thumb: {
    display: "inline-flex",
    borderRadius: "2px",
    border: "1px solid #eaeaea",
    marginBottom: "4px",
    marginTop: "4px",
    marginRigth: "4px",
    marginLeft: "4px",
    width: "100px",
    height: "100px",
    padding: "4px",
    boxSizing: "border-box",
    position: 'relative'
  },
  thumbInner: {
    display: "flex",
    minWidth: "0px",
    overflow: "hidden"
  },
  img: {
    display: "block",
    width: "auto",
    height: "100%"
  },
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",

    backgroundColor: "#f7f5f57d"
  },
  section: {
    border: "1px dashed #EEEEEE",
    borderRadius: '5px',
    margin: "5px 0",
  },
  drop: {
    padding: '10px',
    outline: 'none',
    cursor: "pointer",
    fontSize: '11px',
    "& p": {
      color: "grey",
      padding: "10px 0",
      margin: '0 !important'
    },
    "&:hover": {
      backgroundColor: '#FAFAFA'
    },
    "&:hover p": {
      color: "#000000"
    }
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  deleteButton: {
    color: "red",
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    cursor: 'pointer',
    fontSize: '16px',
    backgroundColor: 'white',
    padding: '5px',
  },
});

class LdImageUploader extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    this.state = {
      files: [],
      progress: false,
      name: this.props.name ? this.props.name : '',
      path: this.props.path ? this.props.path : '',
      hasParent: this.props.hasParent,
      maxSize: this.props.maxSize? this.props.maxSize: 500000
    };
    this.deleteImage = this.deleteImage.bind(this);
    this.deleteImageDefault = this.deleteImageDefault.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!isObjectValueEquals(this.props.name, prevProps.name)) {
      this.setState({ name: this.props.name }, () => { });
    }
    if (!isObjectValueEquals(this.props.path, prevProps.path)) {
      this.setState({ path: this.props.path }, () => { });
    }
    if (!isObjectValueEquals(this.props.hasParent, prevProps.hasParent)) {
      this.setState({ hasParent: this.props.hasParent }, () => { });
    }
  }
  deleteImageDefault() {
    this.setState({ hasParent: false, name: '', path: '' });
    this.props.onImageUploaded('', '');
  }
  onDrop(files) {
    this.setState({ files });
    console.log(files);
    if (files && Array.isArray(files) && files.length > 0) {
      this.upload(files[0]);
      if (this.state.hasParent) {
        /*this.setState({hasParent:false,name:'',path:''});
        this.props.onImageUploaded('','');*/
        this.deleteImageDefault();
      }

    }
  }
  deleteImage = event => {
    console.log("delete image");
    console.log(event);
    var index = event.target.getAttribute('data-index');
    console.log(index);
    var arr = this.state.files.splice(index, 1);
    this.setState((state) => {
      state.datas = arr;
      state.name = "";
      state.path = "";
      return state;
    });
  };
  upload(file) {
    var formData = new FormData(); // Currently empty
    formData.append('save', 0);
    formData.append('photo', file);
    let self = this;
    this.setState({ progress: true });
    fetch(appProperties.basepathServer + '/rest-user/upload-photo', {
      //   mode: 'cors',
      method: 'POST',
      headers: {
        //'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
      },
      credentials: 'include',
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.result_code === 1) {
          self.setState((state) => {
            //state.datas = state.datas.concat([data.row]);
            state.progress = false;
            state.name = data.name;
            state.path = data.path;
            state.files = [];
            return state;
          })
          self.props.onImageUploaded(data.name, data.path);

        }
      })
      .catch((err) => {
        console.log(err);
        self.setState((state) => {
          state.progress = false;

          return state;
        });
      });
  }

  render() {
    const { classes /*, pages*/ } = this.props;
    const { formatMessage } = this.props.intl;
    // const files = this.state.files.map(file => (
    //   <li key={file.name}>
    //     {file.name} - {file.size} bytes
    //   </li>
    // ));
    const thumbs = this.state.files.map((file, index) => (
      <Paper className={classes.thumb} key={file.name}>
        <Paper elevation={0} className={classes.thumbInner}>
          <img alt=""
            src={URL.createObjectURL(file)}
            className={classes.img}
          />
        </Paper>
        {this.state.progress && <CircularProgress thickness={1.5} size={100} className={classes.fabProgress} />}
        {/* <IconButton color="secondary"  aria-label="Delete image">
        </IconButton> */}
        <DeleteIcon data-index={index} onClick={this.deleteImage} title={formatMessage({ id: "LdImageUploader_del" })} small className={classes.deleteButton} />
      </Paper>
    ));
    const thumbDefault = (
      <Paper className={classes.thumb}>
        <Paper elevation={0} className={classes.thumbInner}>
          <img
            alt=""
            src={this.state.path}
            className={classes.img}
          />
        </Paper>
        {this.state.progress && <CircularProgress thickness={1.5} size={100} className={classes.fabProgress} />}
        {/* <IconButton color="secondary"  aria-label="Delete image">
          </IconButton> */}
        <DeleteIcon onClick={this.deleteImageDefault} title={formatMessage({ id: "LdImageUploader_del" })} className={classes.deleteButton} />
      </Paper>
    );
    return (
      <Dropzone multiple={false} onDrop={this.onDrop} maxSize={this.state.maxSize}>
        {({ getRootProps, getInputProps }) => (
          <section className={classes.section}>
            <div className={classes.drop}  {...getRootProps()}>
              <input style={{ height: "100%" }} {...getInputProps()} />
              <p>{this.props.label || formatMessage({ id: "LdImageUploader_notice" })}</p>
            </div>
            {/* <aside>
              <h4>Files</h4>
              <ul>{file   s}</ul>
            </aside> */}
            <aside className={classes.thumbsContainer}>
              {thumbs}
              {(thumbs != null || thumbs.length === 0) && this.state.hasParent && thumbDefault}
            </aside>
          </section>
        )}
      </Dropzone>
    );
  }
}
LdImageUploader.propTypes = {
  classes: PropTypes.object.isRequired,
  //pages: PropTypes.array.isRequired
};
export default withStyles(styles)(injectIntl(LdImageUploader));
